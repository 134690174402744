import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import './neb-era-report-data';
import '../../controllers/charges/neb-charges-controller';
import '../../../../packages/neb-lit-components/src/components/neb-table-readonly';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../tables/era-eob/neb-table-era-eob-management-payment-details';
import '../../tables/era-eob/neb-table-era-eob-management-details';
import { getClaimPaymentsWithAllocation } from '../../../../packages/neb-api-client/src/allocation-api-client';
import { fetchPdf } from '../../../../packages/neb-api-client/src/ledger-superbill-api-client';
import {
  formatPayment,
  getPaymentDetail,
} from '../../../../packages/neb-api-client/src/payments-api-client';
import {
  openError,
  openWarning,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import {
  toFormatDate,
  MONTH_DAY_YEAR,
  MONTH_DAY_YEAR_TIME,
} from '../../../../packages/neb-input/nebFormatUtils';
import {
  getClaimsToChangeStatus,
  getNewClaimStatusEffectiveDate,
} from '../../../../packages/neb-lit-components/src/components/claims/utils';
import Form, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { TABS } from '../../../../packages/neb-lit-components/src/components/forms/neb-form-allocation-charges';
import PayerInfoLedgerTable from '../../../../packages/neb-lit-components/src/components/tables/neb-table-ledger-payer-info';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_1,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../packages/neb-styles/neb-variables';
import { CLAIM_STATUS } from '../../../../packages/neb-utils/claims';
import { BILLING_NOTE_TYPES } from '../../../../packages/neb-utils/constants';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../packages/neb-utils/feature-util';
import {
  centsToCurrency,
  decimalToCents,
  formatDollarAmount,
} from '../../../../packages/neb-utils/formatters';
import {
  isPaymentRefunded,
  isPaymentRefundedOrVoided,
} from '../../../../packages/neb-utils/neb-payment-util';
import { printPdf } from '../../../../packages/neb-utils/neb-pdf-print-util';
import * as eobApi from '../../../api-clients/eob';
import * as eraApi from '../../../api-clients/era';
import * as eraEobApi from '../../../api-clients/era-eob';
import matchClaim from '../../../api-clients/services/era/match-claim';
import updateEraReportData from '../../../api-clients/services/era/update-era-report-data';
import { ERA_EOB_STATUS, getEraEobStatuses } from '../../../utils/era';
import { ERA_ACTIONS } from '../../../utils/era-eob';
import performAction from '../../../utils/era-eob/action-handler';
import getActionBar from '../../../utils/era-eob/get-action-bar';
import { CHARGES_ORIGIN, EDIT_MODE } from '../../../utils/neb-charges-util';
import { openPayment } from '../../../utils/payment-util';
import { shouldCreateSecondaryClaims } from '../../../utils/secondary-claims/secondary-claims';
import {
  ERA_BANNER_SUCCESS,
  ERA_BANNER_ERROR,
  EOB_BANNER_SUCCESS,
  EOB_BANNER_ERROR,
  DELETE_CHARGES_SUCCESSFUL,
  DELETE_CHARGES_ERROR,
  SECONDARY_CLAIMS_CREATION_SUCCESSFUL,
  SECONDARY_CLAIMS_CREATION_ERROR,
  ERA_EOB_CLAIM_STATUS_UPDATE_SUCCESS,
  ERA_EOB_CLAIM_STATUS_UPDATE_ERROR,
  ERA_REPROCESSED_SUCCESS_MESSAGE,
  ERA_REPROCESSED_ERROR_MESSAGE,
  SECONDARY_CLAIMS_CREATION_WARNING,
  MARK_CLAIM_AS_DENIED_CONFIRMATION,
  MANUAL_POST_CLAIM_DENIED_CONFIRMATION,
  REVERSE_UNMATCHED_CLAIM_WARNING,
  REVERSE_CLAIM_NO_PREVIOUS_ALLOCATIONS_WARNING,
} from '../../../utils/user-message';
import { ABANDONED_ERA_RESPONSE } from '../../overlays/abandoned-response/neb-overlay-abandoned-era';
import { EditChargeTable } from '../../tables/charges/neb-table-charges-edit';

import {
  editProviderAdjustment,
  getReportData,
} from './neb-form-era-eob-management/neb-form-era-eob-management-utils';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  header: {
    id: 'header',
  },
  chargesController: {
    id: 'charges-controller',
  },
  buttonBar: {
    id: 'button-bar',
  },
  payerDetail: {
    id: 'payer-detail',
  },
  adjustmentDetail: {
    id: 'adjustment-detail',
  },
  iconChevron: {
    id: 'icon-chevron',
  },
  paymentDetail: {
    id: 'payment-detail',
  },
  paymentButtonBar: {
    id: 'payment-button-bar',
  },
  authEFT: {
    id: 'authEFT',
  },
  payer: {
    id: 'payer',
  },
  form: { id: 'charges-form-management' },
  statusDropdown: {
    id: 'status-dropdown',
  },
  voidedOrRefundedTable: {
    id: 'voided-or-refunded-table',
  },
  eRAPaymentHistory: { id: 'era-payment-history' },
  eraReportData: { id: 'era-report-data' },
  headerERAHistory: {
    id: 'era-history-header',
    title: 'ERA Payment History',
    description: '',
  },
  headerPayment: {
    id: 'payment-header',
  },
};

export default class NebFormEraEobManagement extends Form {
  static get properties() {
    return {
      __paymentModel: { type: Array },
      __payerModel: { type: Array },
      __editMode: String,
      __buttonsConfig: Array,
      __reportData: Object,
      __eRAHistory: Array,
      __filteredLineItemIds: Array,
      __selectedLevel: String,
      __matchLineItemReportId: String,
      __selectedLineItemReportId: String,
      __selectedClaimReportId: String,
      __openAdjustments: { reflect: true, type: Boolean, attribute: 'open' },
      __hasRcmSecondaryClaimsFF: Boolean,
      __hasRcmProviderAdjustmentsFF: Boolean,
      practiceUsers: Array,
      eraOverviewFF: Boolean,
      eraActionsFF: Boolean,
      hasEraPayments: Boolean,
      hasEraActionsManualPostFF: Boolean,
      hasRcmEraEobEnhancementsFF: Boolean,
      hasEraOverviewNavigationAFF: Boolean,
      hasEraOverviewNavigationBFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: flex;
        }

        .content {
          position: relative;
        }

        .layout {
          grid-template-rows: repeat(4, min-content) auto;
          padding-bottom: 0;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .button-bar {
          z-index: 1;
          padding-left: ${CSS_SPACING};
          margin-bottom: ${CSS_SPACING};
        }

        .popup-header {
          padding: ${CSS_SPACING};
          padding-bottom: 0;
        }

        .payment-header {
          padding-left: ${CSS_SPACING};
          margin: 0px;
        }

        .buttons-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .buttons-container-left {
          display: grid;
          grid-auto-flow: column;
          gap: 10px;
        }

        .buttons-container-right {
          display: flex;
          margin-right: 20px;
        }

        .details-container {
          display: grid;
          gap: 20px 0px;
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          flex: 1 0 0px;
          z-index: 1;
        }

        .table-spacer {
          padding-bottom: ${CSS_SPACING};
        }

        .note-container {
          display: flex;
          padding: ${CSS_SPACING};
        }

        .history-header {
          margin: ${CSS_SPACING} 0px;
        }

        .history {
          border: ${CSS_BORDER_GREY_1};
          border-radius: 5px;
        }

        .overflow {
          overflow: visible;
        }

        .icon-chevron-container {
          display: flex;
          justify-content: center;
        }

        .icon-chevron {
          position: relative;
          fill: ${CSS_COLOR_HIGHLIGHT};
          transition: 250ms ease-in-out;
          width: 15px;
          height: 15px;
          z-index: 1;
        }

        .icon-chevron:hover {
          cursor: pointer;
          opacity: 0.6;
        }

        :host([open]) .icon-chevron {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }

        .adjustment-detail {
          display: flex;
          height: 0;
          overflow: hidden;
          justify-content: center;
          align-items: flex-start;
          gap: 100px;
          transition: height 250ms ease-in-out;
        }

        :host([open]) .adjustment-detail {
          height: 50px;
          border-bottom: 1px solid rgb(222, 223, 224);
        }
      `,
    ];
  }

  static createModel() {
    return {
      id: '',
      date: '',
      number: null,
      payer: {
        id: '',
        name: '',
        alias: '',
      },
      paymentAmount: null,
      providerAdjustments: [],
      adjustmentAmount: null,
      s3key: null,
      authEFT: '',
      paymentAllocated: null,
      paymentBalance: null,
      status: '',
      type: '',
      payerInfo: PayerInfoLedgerTable.createModel(),
      items: EditChargeTable.createModel(),
      isLegacy: false,
    };
  }

  initState() {
    super.initState();

    this.__paymentModel = [];
    this.__payerModel = [];
    this.__editMode = EDIT_MODE.DISABLED;
    this.__buttonsConfig = [];

    this.__eRAHistory = [];
    this.__reportData = {};
    this.__matchLineItemReportId = '';
    this.__selectedClaimReportId = '';
    this.__selectedLineItemReportId = '';
    this.__openAdjustments = false;
    this.__hasRcmSecondaryClaimsFF = false;
    this.__hasRcmProviderAdjustmentsFF = false;
    this.__selectedLevel = '';

    this.eraOverviewFF = false;
    this.eraActionsFF = false;
    this.practiceUsers = [];
    this.hasEraPayments = false;
    this.hasEraActionsManualPostFF = false;
    this.hasRcmEraEobEnhancementsFF = false;
    this.hasEraOverviewNavigationAFF = false;
    this.hasEraOverviewNavigationBFF = false;

    this.onClickPaymentId = () => {};

    this.onDismiss = () => {};

    this.onUpdateModel = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      dismissEraEobManagement: () => this.onDismiss(),
      printReport: () => this.__printReport(),
      createSecondaryClaims: async lineItemIds => {
        await this.getSecondaryClaimPopup({
          onStatusChange: false,
          lineItemIds,
        });

        return { reload: false };
      },
      clickLink: (key, index) => {
        this.__openLinkOverlay(key, index);
      },
      editPayerPayment: async () => {
        const payment = await getPaymentDetail(this.model.payments[0].id, true);

        const result = await openOverlay(
          OVERLAY_KEYS.UPDATE_PAYER_PAYMENT,
          payment,
        );

        if (result) await this.__updateModel();
      },
      editProviderAdjustment: async index => {
        const result = await editProviderAdjustment({
          payments: this.model.payments,
          index,
        });

        if (result) await this.__updateModel();
      },
      removeCharges: async items => {
        const popupConfig = {
          title: `Remove From ${this.model.type}`,
          message: `Are you sure you want to remove the selected charge(s) from ${
            this.model.type
          } ${this.model.number}?`,
        };

        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          confirmText: 'Yes',
          cancelText: 'No',
          ...popupConfig,
        });

        if (!result) return false;

        const claimStatuses = await this.__getClaimStatusToUpdate(items);
        const promises = !claimStatuses
          ? [
              this.__getApiAndPayload({
                selectedIds: items.map(item => item.id),
              }),
            ]
          : this.__getApiAndPayloadPromises({ items, claimStatuses });

        try {
          await Promise.all(promises.map(({ api, payload }) => api(payload)));

          await this.__updateModel();

          store.dispatch(
            openSuccess(DELETE_CHARGES_SUCCESSFUL({ type: this.model.type })),
          );

          if (claimStatuses) {
            store.dispatch(openSuccess(ERA_EOB_CLAIM_STATUS_UPDATE_SUCCESS));
          }
        } catch (error) {
          store.dispatch(
            openError(DELETE_CHARGES_ERROR({ type: this.model.type })),
          );

          if (claimStatuses) {
            store.dispatch(openError(ERA_EOB_CLAIM_STATUS_UPDATE_ERROR));
          }
        }

        return true;
      },
      changeStatus: e => this.__changeStatus(e),
      updateModel: () => this.__updateModel(),
      scroll: e => this.__setYOffset(e.currentTarget.scrollTop),
      associatePayment: async () => {
        const updateModel = await openOverlay(OVERLAY_KEYS.ASSOCIATE_PAYMENT, {
          defaultPayer: this.model.payer,
          reportId: this.model.id,
          reportType: this.model.type,
          ...(this.model.type === 'ERA'
            ? {
                eClaimERAReportId: this.model.reportId,
                defaultPayer: this.__getPayerFromPayment(),
              }
            : undefined),
        });

        if (updateModel) this.__updateModel();
      },
      changeEditMode: editMode => {
        this.__editMode = editMode;
      },
      performAction: async action => {
        const [payment] = this.model.payments;
        const formattedPayment = await formatPayment(payment);
        const result = await performAction(action, {
          payment: formattedPayment,
          s3key: this.model.s3key,
          fromEraEobPage: true,
        });
        if (result) await this.__updateModel();
      },
      clickNoteIcon: async () => {
        const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.PAYMENT,
          parentId: this.model.payments.length
            ? this.model.payments[0].id
            : this.model.voidedPayment.id,
          parentData: {
            transactionDate: parseDate(this.model.date)
              .startOf('day')
              .format('MM/DD/YYYY'),
            paymentType: this.model.type,
            eobEraId: this.model.number,
            paymentId: this.model.payments.length
              ? this.model.payments[0].paymentNumber
              : '-',
            amount: this.model.paymentAmount,
            payer: this.model.payments.length
              ? `(${this.__payerModel[0].payer.alias}) ${this.model.payer.name}`
              : `(${this.model.voidedPayment.alias}) ${this.model.payer.name}`,
          },
          patientId: this.model.payments.length
            ? this.model.payments[0].patientId
            : this.model.voidedPayment.patientId,
        });

        if (result) await this.__updateModel();
      },
      reprocessERA: async () => {
        const response = await this.__reprocessERA();

        if (response) {
          setTimeout(() => this.__updateModel(), 2000);
        }
      },
      selectedEraLevel: (lineItemIds, selectedLevel) => {
        this.__filteredLineItemIds = lineItemIds;
        this.__selectedLevel = selectedLevel;
      },
      updateERAPosted: async ({ lineItemReportId, claimReportId }) => {
        this.__sideLoading = true;

        const result = await updateEraReportData(ERA_ACTIONS.ERA_POSTED, {
          model: this.model,
          lineItemReportId,
          claimReportId,
        });
        if (result) await this.__updateModel();

        this.__sideLoading = false;
      },
      mergeLineItems: async (lineItemReportId, claimReportId) => {
        this.__sideLoading = true;

        const result = await updateEraReportData(ERA_ACTIONS.MERGE_LINE_ITEMS, {
          model: this.model,
          lineItemReportId,
          claimReportId,
        });
        if (result) await this.__updateModel();

        this.__sideLoading = false;
      },
      postERA: async ({
        claimReportId,
        lineItemReportId,
        selectedNodeType,
      }) => {
        this.__sideLoading = true;

        const result = await updateEraReportData(ERA_ACTIONS.POST_ERA, {
          model: this.model,
          claimReportId,
          lineItemReportId,
          selectedNodeType,
        });
        if (result) await this.__updateModel();

        this.__sideLoading = false;
      },
      resetStatus: async ({ claimReportId, lineItemReportId }) => {
        this.__sideLoading = true;

        const result = await updateEraReportData(ERA_ACTIONS.RESET_STATUS, {
          model: this.model,
          claimReportId,
          lineItemReportId,
        });
        if (result) await this.__updateModel();

        this.__sideLoading = false;
      },

      startMatch: (matchLineItemReportId, matchClaimReportId) => {
        this.__matchLineItemReportId = matchLineItemReportId;
        this.__matchClaimReportId = matchClaimReportId;
        this.__editMode = EDIT_MODE.MATCH;
      },
      stopMatch: () => {
        this.__matchLineItemReportId = '';
        this.__matchClaimReportId = '';
        this.__editMode = EDIT_MODE.DISABLED;
        return this.__updateModel();
      },
      matchCharge: async lineItemId => {
        this.__sideLoading = true;

        const result = await updateEraReportData(ERA_ACTIONS.MATCH_CHARGE, {
          model: this.model,
          lineItemId,
          lineItemReportId: this.__matchLineItemReportId,
          claimReportId: this.__matchClaimReportId,
        });
        if (result) await this.__updateModel();

        this.__sideLoading = false;
      },
      matchClaim: async claimReportId => {
        const patient = await openPopup(POPUP_RENDER_KEYS.MATCH_CLAIM);
        if (!patient) return;

        const result = await matchClaim({
          claimReportId,
          patient,
          model: this.model,
          reportData: this.__reportData,
        });

        if (result) await this.__updateModel();
      },
      startManualPost: ({ claimReportId, lineItemReportId }) => {
        this.__editMode = EDIT_MODE.MANUAL_POST;
        this.__selectedClaimReportId = claimReportId;
        this.__selectedLineItemReportId = lineItemReportId;
      },
      manualPost: async model => {
        let claimIdToMarkAsDenied = null;
        let claimStatusEffectiveDate = null;

        if (
          this.hasRcmEraEobEnhancementsFF &&
          !this.__selectedLineItemReportId
        ) {
          const claimId = this.__getClaimIdFromReportData(
            this.__selectedClaimReportId,
          );
          const { id, status, claimStatusEffectiveDate: effectiveDate } =
            this.__getModelClaimFromId(claimId) || {};

          if (this.__isClaimFromReportNotDenied(status)) {
            const markClaimAsDenied = await this.__attemptToUpdateClaimStatusToDenied(
              MANUAL_POST_CLAIM_DENIED_CONFIRMATION,
            );

            if (markClaimAsDenied) {
              claimIdToMarkAsDenied = id;
              claimStatusEffectiveDate = getNewClaimStatusEffectiveDate(
                effectiveDate,
              );
            }
          }
        }

        this.__sideLoading = true;
        const result = await updateEraReportData(ERA_ACTIONS.MANUAL_POST, {
          body: {
            paymentId: this.model.payments[0].id,
            lineItems: model,
            claimIdToMarkAsDenied,
            claimStatusEffectiveDate,
          },
          eraId: this.model.id,
          lineItemReportId: this.__selectedLineItemReportId,
          claimReportId: this.__selectedClaimReportId,
        });

        if (result) {
          this.__editMode = EDIT_MODE.DISABLED;
          await this.__updateModel();
        }

        this.__sideLoading = false;
      },
      markAsDenied: async ({ claimReportId }) => {
        const confirm = await this.__attemptToUpdateClaimStatusToDenied(
          MARK_CLAIM_AS_DENIED_CONFIRMATION,
        );

        if (confirm) {
          this.__sideLoading = true;
          const claimId = this.__getClaimIdFromReportData(claimReportId);
          const { id, claimStatusEffectiveDate: effectiveDate } =
            this.__getModelClaimFromId(claimId) || {};

          const result = await updateEraReportData(ERA_ACTIONS.MARK_AS_DENIED, {
            claimId: id,
            effectiveDate: getNewClaimStatusEffectiveDate(effectiveDate),
          });

          if (result) await this.__updateModel();
          this.__sideLoading = false;
        }
      },
      linkERA: async () => {
        const res = await openOverlay(OVERLAY_KEYS.ABANDONED_ERA, {
          ...this.model,
          amount: this.model.paymentAmount,
          showBackButton: false,
          effectiveDate: this.model.date,
        });
        if (!res) return null;

        if (res === ABANDONED_ERA_RESPONSE.POSTED) {
          return this.handlers.reprocessERA();
        }

        return this.__changeStatus({
          event: 'select',
          value: ERA_EOB_STATUS.CLOSED,
        });
      },
      toggleAdjustmentDetail: () => {
        this.__openAdjustments = !this.__openAdjustments;
      },
      reverseClaim: async ({ claimId, issuedAmount, claimReportId }) => {
        if (!claimId) {
          await this.__promptAddRemitOffset({
            claimId,
            claimReportId,
            issuedAmount: decimalToCents(issuedAmount),
            unmatched: true,
          });

          return;
        }

        const { payerPlanId } = this.model.payments[0];
        const claimPaymentsWithAllocation = await getClaimPaymentsWithAllocation(
          { claimId, payerPlanId },
        );

        if (claimPaymentsWithAllocation.length === 0) {
          await this.__promptAddRemitOffset({
            claimId,
            claimReportId,
            issuedAmount: decimalToCents(issuedAmount),
          });

          return;
        }

        const [{ amount }] = claimPaymentsWithAllocation;

        if (
          claimPaymentsWithAllocation.length === 1 &&
          amount === decimalToCents(issuedAmount)
        ) {
          await this.__reverseClaimAndRemoveWarnings({
            claimId,
            claimReportId,
            selectedPaymentIds: [claimPaymentsWithAllocation[0].id],
          });

          return;
        }

        const { accepted, paymentIds, remainderAmount } = await openPopup(
          POPUP_RENDER_KEYS.ALLOCATED_PAYMENTS,
          {
            allocatedPayments: claimPaymentsWithAllocation,
            issuedAmount,
          },
        );

        if (accepted) {
          await this.__reverseClaimAndRemoveWarnings({
            claimId,
            claimReportId,
            selectedPaymentIds: paymentIds,
            remainderAmount,
          });
        }
      },
    };
  }

  async __reverseClaimAndRemoveWarnings({
    claimId,
    claimReportId,
    selectedPaymentIds,
    remainderAmount,
  }) {
    const result = await updateEraReportData(ERA_ACTIONS.REVERSE_CLAIM, {
      model: this.model,
      paymentId: this.model.payments[0].id,
      selectedPaymentIds,
      remainderAmount,
      claimId,
      claimReportId,
      reportData: this.__reportData,
    });

    if (result) await this.__updateModel();
  }

  async __promptAddRemitOffset({
    claimId,
    issuedAmount,
    claimReportId,
    unmatched,
  }) {
    const message = unmatched
      ? REVERSE_UNMATCHED_CLAIM_WARNING
      : REVERSE_CLAIM_NO_PREVIOUS_ALLOCATIONS_WARNING;

    const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Attention',
      message,
      confirmText: 'Yes',
      cancelText: 'No',
    });

    if (accepted) {
      await this.__reverseClaimAndRemoveWarnings({
        claimId,
        claimReportId,
        selectedPaymentIds: [],
        remainderAmount: issuedAmount,
      });
    }
  }

  async connectedCallback() {
    super.connectedCallback();
    const features = await getFeatures();

    this.__hasRcmSecondaryClaimsFF = features.includes(
      FEATURE_FLAGS.SECONDARY_CLAIMS,
    );

    this.__hasRcmProviderAdjustmentsFF = features.includes(
      FEATURE_FLAGS.RCM_PROVIDER_ADJUSTMENTS,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  async __createSecondaryClaimsWithDuplicates(lineItemIds) {
    let result;

    try {
      if (this.__isEra()) {
        result = await eraApi.createSecondaryClaims({
          eraId: this.model.id,
          lineItemIds,
          version: 1,
        });
      } else {
        result = await eobApi.createSecondaryClaims({
          eobId: this.model.id,
          lineItemIds,
          version: 1,
        });
      }
      store.dispatch(
        openSuccess(SECONDARY_CLAIMS_CREATION_SUCCESSFUL(result.count)),
      );
    } catch (e) {
      console.error(e);
      store.dispatch(openError(SECONDARY_CLAIMS_CREATION_ERROR));
    }
  }

  __getModelClaimFromId(claimId) {
    return this.model.claims.find(claim => claim.id === claimId);
  }

  __getClaimIdFromReportData(claimReportId) {
    const claimFromReport = this.__reportData.claims?.find(
      claim => claim.claimReportId === claimReportId,
    );

    return claimFromReport?.id;
  }

  __isClaimFromReportNotDenied(status) {
    if (!status) return false;

    return ![
      CLAIM_STATUS.ERA_EOB_RECEIVED_DENIED,
      CLAIM_STATUS.DENIED,
    ].includes(status);
  }

  __attemptToUpdateClaimStatusToDenied(message) {
    return openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Update Status',
      message,
      confirmText: 'Yes',
      cancelText: 'No',
    });
  }

  async __createSecondaryClaimsWithoutDuplicates(lineItemIds) {
    let result;

    try {
      if (this.__isEra()) {
        result = await eraApi.createSecondaryClaims({
          eraId: this.model.id,
          lineItemIds,
          version: 2,
        });
      } else {
        result = await eobApi.createSecondaryClaims({
          eobId: this.model.id,
          lineItemIds,
          version: 2,
        });
      }

      const { totalIgnoredClaims, totalGeneratedClaims } = result;

      store.dispatch(
        openSuccess(SECONDARY_CLAIMS_CREATION_SUCCESSFUL(totalGeneratedClaims)),
      );

      if (totalIgnoredClaims) {
        store.dispatch(
          openWarning(SECONDARY_CLAIMS_CREATION_WARNING(totalIgnoredClaims)),
        );
      }
    } catch (e) {
      console.error(e);
      store.dispatch(openError(SECONDARY_CLAIMS_CREATION_ERROR));
    }
  }

  async __reprocessERA() {
    let response = false;

    try {
      await eraApi.reprocessEra(this.model.reportId);
      store.dispatch(openSuccess(ERA_REPROCESSED_SUCCESS_MESSAGE));
      response = true;
    } catch (e) {
      console.error(e);
      store.dispatch(openError(ERA_REPROCESSED_ERROR_MESSAGE));
    }

    return response;
  }

  __getPayerFromPayment() {
    if (this.model.payments && this.model.payments.length) {
      return {
        id: this.model.payments[0].payerPlanId,
        alias: this.model.payments[0].alias,
        name: this.model.payments[0].payerName,
      };
    }
    return null;
  }

  __setYOffset(scrollTop) {
    const controllerElement = this.shadowRoot.getElementById(
      ELEMENTS.chargesController.id,
    );

    const form = controllerElement.shadowRoot.getElementById(ELEMENTS.form.id);

    if (form) {
      form.setYOffset(scrollTop);
    }
  }

  __getApiAndPayload({ selectedIds, claimStatus = '' }) {
    const isEra = this.__isEra();
    const commonPayload = { version: 2, claimStatus };

    const payload = isEra
      ? {
          eraId: this.model.id,
          lineItemIds: selectedIds,
          reportId: this.model.reportId,
        }
      : {
          eobId: this.model.id,
          chargeIds: selectedIds,
        };

    const api = isEra ? eraApi.removeEraCharges : eobApi.removeEobCharges;
    return { api, payload: { ...commonPayload, ...payload } };
  }

  async __getClaimStatusToUpdate(items) {
    const itemsWithAssociatedClaims = items.filter(
      item => item.claim.claimNumber,
    );

    const hasAssociatedClaims =
      itemsWithAssociatedClaims && itemsWithAssociatedClaims.length;

    if (hasAssociatedClaims) {
      const charges = itemsWithAssociatedClaims
        .map(item => item.chargeNumber)
        .join(', ');

      const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
        title: `A claim has been generated for the following charge(s): ${charges}`,
        message: 'Would you like to update the current claim status?',
        confirmText: 'Yes',
        cancelText: 'No',
      });

      if (!result) return false;

      const claims = itemsWithAssociatedClaims.map(({ claim }) => claim);

      return getClaimsToChangeStatus({ claims, validate: false });
    }

    return false;
  }

  __getApiAndPayloadPromises({ items, claimStatuses: { electronic, paper } }) {
    const promises = [];
    const itemsWithoutClaims = items.filter(item => !item.claim.claimNumber);

    if (itemsWithoutClaims.length > 0) {
      promises.push(
        this.__getApiAndPayload({
          selectedIds: itemsWithoutClaims.map(item => item.id),
        }),
      );
    }

    if (paper && paper.claims.length > 0) {
      promises.push(
        this.__getApiAndPayload({
          selectedIds: paper.claims.map(c => c.claimCharges[0].lineItemId),
          claimStatus: paper.status,
        }),
      );
    }

    if (electronic && electronic.claims.length > 0) {
      promises.push(
        this.__getApiAndPayload({
          selectedIds: electronic.claims.map(c => c.claimCharges[0].lineItemId),
          claimStatus: electronic.status,
        }),
      );
    }

    return promises;
  }

  async __updateModel() {
    const type = this.__isEra() ? 'ERA' : 'EOB';
    const {
      data: [model],
    } = await eraEobApi.getDataById({
      id: this.model.id,
      type,
    });

    this.onUpdateModel(model);
    this.__setYOffset(0);
  }

  __isEra() {
    return this.model.type !== 'EOB';
  }

  __printReport() {
    return printPdf(fetchPdf(this.model.s3key));
  }

  __updateStatus(value) {
    if (this.hasRcmEraEobEnhancementsFF) {
      return eraEobApi.updateEraEobStatus({
        id: this.model.reportId || this.model.id,
        body: { type: this.model.type, status: value, eraId: this.model.id },
      });
    }

    if (this.__isEra()) return eraApi.updateEraStatus(this.model.id, value);
    return eobApi.updateStatus(this.model.id, value);
  }

  async getSecondaryClaimPopup({ onStatusChange, lineItemIds }) {
    const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Create Secondary Claims',
      message: onStatusChange
        ? 'This ERA/EOB has secondary claim draft(s) that have not been created. Would you like to create these secondary claim drafts?'
        : 'Are you sure you want to create secondary claims drafts?',
      confirmText: 'Yes',
      cancelText: 'No',
    });

    if (accepted) {
      if (this.__hasRcmSecondaryClaimsFF || onStatusChange) {
        this.__createSecondaryClaimsWithoutDuplicates(lineItemIds);
      } else {
        this.__createSecondaryClaimsWithDuplicates(lineItemIds);
      }
    }
  }

  async createSecondaryClaims(e) {
    if (
      e.value === 'Closed' &&
      this.__hasRcmSecondaryClaimsFF &&
      (await shouldCreateSecondaryClaims({
        isEra: this.__isEra(),
        id: this.model.id,
        lineItemIds: this.model.lineItemIds,
        primaryPaymentFinancialClass:
          this.model.payments && this.model.payments[0]
            ? this.model.payments[0].payerPlan.financialClass
            : null,
      }))
    ) {
      await this.getSecondaryClaimPopup({
        onStatusChange: true,
        lineItemIds: this.model.lineItemIds,
      });
    }
  }

  async __changeStatus(e) {
    if (e.event !== 'select' || e.value === this.model.status) return;

    await this.createSecondaryClaims(e);

    try {
      await this.__updateStatus(e.value);

      this.model = { ...this.model, status: e.value };

      const successMessage = this.__isEra()
        ? ERA_BANNER_SUCCESS
        : EOB_BANNER_SUCCESS;

      store.dispatch(openSuccess(successMessage));

      if (this.model.status === ERA_EOB_STATUS.CLOSED) this.onDismiss();
      else this.reload();
    } catch (error) {
      const errorMessage = this.__isEra() ? ERA_BANNER_ERROR : EOB_BANNER_ERROR;
      store.dispatch(openError(errorMessage));
    }
  }

  getEncountersDx() {
    const encounterDiagnoses = this.displayItems.reduce(
      (acc, { encounterCharge: { encounterId, diagnoses } = {} }) =>
        encounterId ? { ...acc, [encounterId]: diagnoses } : acc,
      {},
    );

    return Object.entries(encounterDiagnoses).reduce(
      (acc, [encounterId, diagnoses]) => {
        diagnoses.forEach(dx => acc.push({ code: dx.code, encounterId }));
        return acc;
      },
      [],
    );
  }

  getSelectedIds() {
    return this.displayItems.filter(item => item.checked).map(item => item.id);
  }

  async __openLinkOverlay(key, index) {
    switch (key) {
      case 'payer':
        await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: this.__payerModel[index].payer.id,
        });

        break;

      case 'payment':
        await openPayment({
          payment: this.__paymentModel[index].payment,
          readonly: false,
          eraEobInfo: this.model,
        });

        break;

      case 'eob':
        await openOverlay(OVERLAY_KEYS.EOB_FORM, {
          eobId: this.model.id,
          isLegacy: this.model.isLegacy,
          paymentId:
            this.model.payments && this.model.payments.length
              ? this.model.payments[0].id
              : null,
        });

        break;

      case 'paymentPayer':
        await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: this.__paymentModel[index].payer.id,
        });

        break;

      default:
    }

    this.__updateModel();
  }

  __checkReloadButtons(changedProps) {
    const props = ['model', '__editMode', '__hasRcmProviderAdjustmentsFF'];
    return props.some(prop => changedProps.has(prop));
  }

  async update(changed) {
    await super.update(changed);

    if (changed.has('model')) {
      await this.reload();

      this.__sideLoading = true;
      this.__reportData = await getReportData(this.model);
      this.__sideLoading = false;
    }
  }

  async firstUpdated() {
    await super.firstUpdated();

    if (this.model.payerPayment) {
      const { payment } = this.model.payerPayment;

      if (Object.keys(payment).length) {
        const formattedPayment = await formatPayment(payment);
        await openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
          payments: [formattedPayment],
          selectedTab: TABS.OUTSTANDING,
          showAdditionalCharges: true,
        });

        await this.__updateModel();
      }
    }

    this.__updateERAHistory();
  }

  __updateERAHistory() {
    if (this.model.history && this.model.history.length) {
      this.__eRAHistory = [...this.model.history];
    }
  }

  updated(changed) {
    if (changed.has('model')) {
      this.__loadModels();
    }

    if (this.__checkReloadButtons(changed)) {
      this.__buildActionButtonConfig();
    }

    if (changed.has('__editMode')) {
      this.__setYOffset(0);
    }

    super.updated(changed);
  }

  __getERAHistoryConfig() {
    return [
      {
        truncate: true,
        key: 'updatedAt',
        label: 'Date and Time',
        flex: css`1 0 0`,
        formatter: value =>
          value ? parseDate(value).format(MONTH_DAY_YEAR_TIME) : '',
      },
      {
        key: 'originalAmount',
        label: 'Previous Amount',
        truncate: true,
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      {
        key: 'amount',
        label: 'Updated Amount',
        truncate: true,
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      {
        key: 'warn',
        label: 'Warning on Ledger',
        truncate: true,
        flex: css`1 0 0`,
        formatter: warn => (warn ? 'Yes' : 'No'),
      },
    ];
  }

  // eslint-disable-next-line complexity
  __loadPayerModel() {
    const payment =
      this.model.payments && this.model.payments.length
        ? this.model.payments[0]
        : undefined;

    const specificModel =
      payment && !this.model.isLegacy
        ? {
            payer: {
              id: payment.payerPlanId,
              alias: payment.alias,
              name: payment.payerName,
            },
            authEFT: payment.authEFT,
            eraAmount: payment.paymentAmount || 0,
            date: payment.date,
            availableAmount: payment.available,
            paymentId: payment.paymentNumber,
            providerAdjustments: payment.providerAdjustments || [],
          }
        : {
            payer: this.model.payer,
            authEFT: this.model.authEFT,
            eraAmount: this.model.paymentAmount,
            date: this.model.date,
            availableAmount: 0,
          };
    return {
      ...specificModel,
      adjustmentAmount: this.model.adjustmentAmount,
      adjustmentApplied: this.model.adjustmentApplied || 0,
    };
  }

  __loadModels() {
    this.__payerModel = [];
    this.__paymentModel = [];

    if (this.model.payer) {
      this.__payerModel = [this.__loadPayerModel()];
    }

    if (this.model.payments && this.model.isLegacy) {
      this.__paymentModel = this.model.payments.map(payment => ({
        id: payment.id,
        payer: {
          id: payment.payerPlanId,
          alias: payment.alias,
          name: payment.payerName,
        },
        payment: {
          number: payment.paymentNumber,
          id: payment.id,
          amount: payment.paymentAmount || 0,
        },
        paymentAmount: payment.paymentAmount || 0,
        allocatedAmount: payment.paymentAllocated,
        availableAmount: payment.available,
        date: payment.date,
      }));
    }

    this.__updateERAHistory();
  }

  __shouldRenderAssociatePaymentButton() {
    return this.model.isLegacy;
  }

  __renderERAHistoryTable() {
    return this.__eRAHistory.length
      ? html`
          <div class="table-spacer"></div>

          <div class="note-container">
            <div class="history">
              <neb-header
                id="${ELEMENTS.headerERAHistory.id}"
                class="history-header"
                .label="${ELEMENTS.headerERAHistory.title}"
                .description=""
              ></neb-header>
              <neb-table-readonly
                id="${ELEMENTS.eRAPaymentHistory.id}"
                class="history-table"
                .model="${this.__eRAHistory}"
                .config="${this.__getERAHistoryConfig()}"
                .layout="${this.layout}"
              ></neb-table-readonly>
            </div>
            <div class="note-spacer"></div>
          </div>
        `
      : '';
  }

  __renderChargesController() {
    return html`
      <neb-charges-controller
        id="${ELEMENTS.chargesController.id}"
        class="overflow"
        .model="${this.model}"
        .lineItemIds="${this.model.lineItemIds}"
        .patient="${this.model.patient}"
        .layout="${this.layout}"
        .origin="${this.__isEra() ? CHARGES_ORIGIN.ERA : CHARGES_ORIGIN.EOB}"
        .onScroll="${this.handlers.scroll}"
        .onUpdateModel="${this.handlers.updateModel}"
        .onRemoveCharges="${this.handlers.removeCharges}"
        .onCreateSecondaryClaims="${this.handlers.createSecondaryClaims}"
        .editMode="${this.__editMode}"
        .onChangeEditMode="${this.handlers.changeEditMode}"
        .paymentLinks="${false}"
        .onDismiss="${this.handlers.dismissEraEobManagement}"
        .filteredLineItemIds="${this.__filteredLineItemIds}"
        .selectedLevel="${this.__selectedLevel}"
        .matchLineItemReportId="${this.__matchLineItemReportId}"
        .onMatchCharge="${this.handlers.matchCharge}"
        .onManualPost="${this.handlers.manualPost}"
        .hasEraActionsManualPostFF="${this.hasEraActionsManualPostFF}"
        .hasEraOverviewNavigationAFF="${this.hasEraOverviewNavigationAFF}"
        .hasEraOverviewNavigationBFF="${this.hasEraOverviewNavigationBFF}"
      ></neb-charges-controller>
    `;
  }

  createSelectors() {}

  __getPrintReportButton() {
    if (this.model.s3key) {
      return [
        {
          name: 'printReport',
          label: `${this.__isEra() ? 'ERA' : 'EOB'} Report`,
          icon: 'receipt',
          onClick: this.handlers.printReport,
          disabled: false,
        },
      ];
    }

    return [];
  }

  __buildActionButtonConfig() {
    const displayPaymentActions =
      this.model &&
      !this.model.isLegacy &&
      ((this.model.payments && this.model.payments.length) ||
        this.model.voidedPayment);

    this.__buttonsConfig = displayPaymentActions
      ? getActionBar({
          s3key: this.model.s3key,
          reportType: this.model.type,
          paymentDetail: this.model.payments.length
            ? this.model.payments[0]
            : this.model.voidedPayment,
          readonlyOrDirty: this.__editMode !== EDIT_MODE.DISABLED,
          onPerformAction: this.handlers.performAction,
          hasRcmProviderAdjustmentsFF: this.__hasRcmProviderAdjustmentsFF,
        })
      : this.__getPrintReportButton();
  }

  __renderHeader() {
    const statuses = getEraEobStatuses({
      currentStatus: this.model.status,
      hasRcmEraEobEnhancementsFF: this.hasRcmEraEobEnhancementsFF,
    });

    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="popup-header"
        title="${`${this.model.type} #${this.model.number}`}"
        .onCancel="${this.handlers.dismissEraEobManagement}"
        .onClickNoteIcon="${this.handlers.clickNoteIcon}"
        ?showAddNoteIcon="${!this.model.eraEobHasNotes}"
        ?showEditNoteIcon="${!!this.model.eraEobHasNotes}"
        showCancelButton
      ></neb-popup-header>

      <div class="buttons-container">
        <div class="buttons-container-left">
          <neb-button-bar
            id="${ELEMENTS.buttonBar.id}"
            class="pad"
            .config="${this.__buttonsConfig}"
            .stretch="${true}"
          ></neb-button-bar>
        </div>

        <div class="buttons-container-right">
          <neb-select
            id="${ELEMENTS.statusDropdown.id}"
            .items="${statuses}"
            .value="${this.model.status}"
            .onChange="${this.handlers.changeStatus}"
          ></neb-select>
        </div>
      </div>
    `;
  }

  __getVoidedOrRefundedTableConfig(paymentDetail) {
    const actionStr = isPaymentRefunded(paymentDetail) ? 'Refunded' : 'Voided';

    const otherActionStr = isPaymentRefunded(paymentDetail) ? 'Refund' : 'Void';

    return [
      {
        truncate: true,
        label: '',
        flex: css`0 0 ${CSS_SPACING}`,
      },
      {
        key: 'date',
        label: `${actionStr} Date`,
        flex: css`1 0 0`,
        formatter: value => (value ? toFormatDate(value, MONTH_DAY_YEAR) : ''),
      },
      {
        key: 'by',
        label: `${actionStr} By`,
        flex: css`1 0 0`,
        formatter: user =>
          user ? `${user.name.last}, ${user.name.first}` : '',
      },
      {
        key: 'reason',
        label: `${otherActionStr} Reason`,
        flex: css`1 0 0`,
        formatter: (codeRefund = {}) =>
          `${codeRefund.code} - ${codeRefund.description}`,
      },
      ...(isPaymentRefunded(paymentDetail)
        ? [
            {
              key: 'method',
              label: `${otherActionStr} Method`,
              flex: css`1 0 0`,
            },
          ]
        : []),
      ...(isPaymentRefunded(paymentDetail) && paymentDetail.transactionId
        ? [
            {
              key: 'transactionId',
              label: `${otherActionStr} Transaction ID`,
              flex: css`1 0 0`,
              formatter: value => value || '-',
            },
          ]
        : []),
      {
        key: 'amount',
        label: `${actionStr} Amount`,
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
    ];
  }

  __getVoidedOrRefundedPaymentDetail() {
    if (this.model.voidedPayment) {
      const paymentDetail = this.model.voidedPayment;
      const { voidPayment } = paymentDetail;
      return [
        {
          voidedAt: paymentDetail.voidedAt,
          date: paymentDetail.voidedAt,
          by: this.practiceUsers.find(u => u.id === voidPayment.voidedById),
          reason: voidPayment.codeRefund,
          amount: paymentDetail.amount,
        },
      ];
    }

    if (this.model.payments.length) {
      const { refundPayment } = this.model.payments[0];

      if (refundPayment) {
        return [
          {
            refundedAt: this.model.payments[0].refundedAt,
            date: refundPayment.updatedAt,
            by: this.practiceUsers.find(
              u => u.id === refundPayment.refundedById,
            ),
            reason: refundPayment.codeRefund,
            method: refundPayment.refundMethod,
            transactionId: refundPayment.cardRefundId,
            amount: this.model.payments[0].amount,
          },
        ];
      }
    }

    return [];
  }

  __shouldRenderVoidedOrRefundedTable() {
    if (this.model.payments || this.model.voidedPayment) {
      let paymentDetail = this.model.voidedPayment;

      if (!paymentDetail) {
        paymentDetail = this.model.payments.length
          ? this.model.payments[0]
          : {};
      }

      return !this.model.isLegacy && isPaymentRefundedOrVoided(paymentDetail);
    }
    return false;
  }

  __hasPayment() {
    return this.model.payments && this.model.payments.length;
  }

  __renderVoidedOrRefundedTable() {
    const model = this.__getVoidedOrRefundedPaymentDetail();
    const config = this.__getVoidedOrRefundedTableConfig(model[0]);

    return html`
      <neb-table-readonly
        id="${ELEMENTS.voidedOrRefundedTable.id}"
        .model="${model}"
        .config="${config}"
        .layout="${this.layout}"
      ></neb-table-readonly>
    `;
  }

  __renderAdjustmentsRow() {
    if (!this.__hasRcmProviderAdjustmentsFF) return '';

    return html`
      <div class="icon-chevron-container">
        <neb-icon
          id="${ELEMENTS.iconChevron.id}"
          slot="content"
          class="icon-chevron"
          icon="neb:chevron"
          @click="${this.handlers.toggleAdjustmentDetail}"
        ></neb-icon>
      </div>

      <div id="${ELEMENTS.adjustmentDetail.id}" class="adjustment-detail">
        <div>
          <span class="bold">Adjustment Amount: </span>${
            formatDollarAmount(this.model.adjustmentAmount)
          }
        </div>
        <div>
          <span class="bold">Adjustment Applied: </span>${
            formatDollarAmount(this.model.adjustmentApplied)
          }
        </div>
      </div>
    `;
  }

  __renderEraEobManagementDetails() {
    const isRefunded =
      this.__hasPayment() && isPaymentRefunded(this.model.payments[0]);

    return html`
      <neb-table-era-eob-management-details
        id="${ELEMENTS.payerDetail.id}"
        .model="${this.__payerModel}"
        .layout="${this.layout}"
        .onClickLink="${this.handlers.clickLink}"
        .report="${this.model}"
        .isEOB="${!this.__isEra()}"
        .isLegacy="${this.model.isLegacy}"
        .onEditPayerPayment="${this.handlers.editPayerPayment}"
        .onEditProviderAdjustment="${this.handlers.editProviderAdjustment}"
        .isVoided="${!!this.model.voidedPayment}"
        .isRefunded="${isRefunded}"
        .hasRcmProviderAdjustmentsFF="${this.__hasRcmProviderAdjustmentsFF}"
      ></neb-table-era-eob-management-details>

      ${this.__renderAdjustmentsRow()}
    `;
  }

  __renderAssociatePaymentButton() {
    const buttonConfig = [
      {
        name: 'associatePayment',
        icon: 'plus',
        label: 'Associate Payment',
        onClick: this.handlers.associatePayment,
      },
    ];
    return this.__shouldRenderAssociatePaymentButton()
      ? html`
          <neb-button-bar
            id="${ELEMENTS.paymentButtonBar.id}"
            class="button-bar"
            .config="${buttonConfig}"
          ></neb-button-bar>
        `
      : '';
  }

  __renderLinkERAButton() {
    const buttonConfig = [
      {
        name: 'linkERA',
        icon: 'addLink',
        label: 'Link ERA',
        onClick: this.handlers.linkERA,
      },
    ];
    return html`
      <neb-button-bar
        id="${ELEMENTS.paymentButtonBar.id}"
        class="button-bar"
        .config="${buttonConfig}"
      ></neb-button-bar>
    `;
  }

  __shouldRenderLinkERAButton() {
    return (
      this.eraOverviewFF &&
      this.hasRcmEraEobEnhancementsFF &&
      this.__isEra() &&
      !this.hasEraPayments
    );
  }

  __renderPaymentButton() {
    return this.__shouldRenderLinkERAButton()
      ? this.__renderLinkERAButton()
      : this.__renderAssociatePaymentButton();
  }

  __renderEraEobManagementPaymentDetails() {
    return this.__paymentModel.length > 0
      ? html`
          <neb-table-era-eob-management-payment-details
            id="${ELEMENTS.paymentDetail.id}"
            class="${ELEMENTS.paymentDetail.id}"
            .model="${this.__paymentModel}"
            .report="${this.model}"
            .layout="${this.layout}"
            .onClickLink="${this.handlers.clickLink}"
            .onUpdateModel="${this.handlers.updateModel}"
          ></neb-table-era-eob-management-payment-details>
        `
      : '';
  }

  __shouldRenderEraReport() {
    return this.eraOverviewFF && this.__isEra() && this.hasEraPayments;
  }

  __renderEraReportData() {
    return this.__shouldRenderEraReport()
      ? html`
          <neb-era-report-data
            .id="${ELEMENTS.eraReportData.id}"
            .model="${this.model}"
            .reportData="${this.__reportData}"
            .eraActionsFF="${this.eraActionsFF}"
            .hasEraActionsManualPostFF="${this.hasEraActionsManualPostFF}"
            .hasRcmEraEobEnhancementsFF="${this.hasRcmEraEobEnhancementsFF}"
            .hasEraOverviewNavigationFF="${
              this.hasEraOverviewNavigationAFF ||
                this.hasEraOverviewNavigationBFF
            }"
            .onReprocessERA="${this.handlers.reprocessERA}"
            .onSelectLevel="${this.handlers.selectedEraLevel}"
            .onUpdateERAPosted="${this.handlers.updateERAPosted}"
            .onMergeLineItems="${this.handlers.mergeLineItems}"
            .onPostERA="${this.handlers.postERA}"
            .onResetStatus="${this.handlers.resetStatus}"
            .onUpdateModel="${this.handlers.updateModel}"
            .onStartMatch="${this.handlers.startMatch}"
            .editMode="${this.__editMode}"
            .onStopMatch="${this.handlers.stopMatch}"
            .onMatchClaim="${this.handlers.matchClaim}"
            .onStartManualPost="${this.handlers.startManualPost}"
            .onMarkAsDenied="${this.handlers.markAsDenied}"
            .onReverseClaim="${this.handlers.reverseClaim}"
          ></neb-era-report-data>
        `
      : '';
  }

  renderContent() {
    return html`
      <div class="details-container">
        ${this.__renderHeader()}
        <h4 id="${ELEMENTS.headerPayment.id}" class="text payment-header">
          Payment Data
        </h4>
        ${this.__renderEraEobManagementDetails()}
        ${
          this.__shouldRenderVoidedOrRefundedTable()
            ? this.__renderVoidedOrRefundedTable()
            : ''
        }
        ${this.__renderEraEobManagementPaymentDetails()}
        ${this.__renderEraReportData()} ${this.__renderPaymentButton()}
      </div>
      ${this.__renderERAHistoryTable()} ${this.__renderChargesController()}
    `;
  }
}

customElements.define('neb-form-era-eob-management', NebFormEraEobManagement);
