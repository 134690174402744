import '../neb-date-picker';
import '../neb-tooltip';
import '../controls/neb-button-action';
import '../tables/neb-table';
import '../inputs/neb-select';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import {
  openPayfacIframePopup,
  ADD_PAYMENT_METHOD_TEMPLATE,
  filterPaymentMethodsByMerchantProvider,
} from '../../../../../src/features/payfac/utils';
import { getDefaultPaymentMethod } from '../../../../../src/utils/default-payment';
import { TYPE, fetch } from '../../../../neb-api-client/src/billing-codes';
import { fetchOne } from '../../../../neb-api-client/src/patient-api-client';
import { getPatientRelationshipsActiveGroup } from '../../../../neb-api-client/src/patient-relationship-api-client';
import { getPaymentMethods } from '../../../../neb-api-client/src/payments/payment-methods-api-client';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_SPACING_ROW,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { parseDate, dateToRaw } from '../../../../neb-utils/date-util';
import { centsToCurrency, toNumeric } from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import { required } from '../../../../neb-utils/validators';

import { ELEMENTS as ELEMENTS_BASE, NebFormOld } from './neb-form-old';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  nameField: { id: 'name' },
  paymentTypeDropdown: { id: 'payment-type' },
  amountField: { id: 'amount' },
  transactionDatePicker: { id: 'transaction-date' },
  paymentMethodDropdown: { id: 'payment-method' },
  addPaymentMethodButton: { id: 'add-payment-method' },
  addPaymentMethodTooltip: { id: 'add-payment-method-tooltip' },
  merchantAccountDropdown: { id: 'merchant-account' },
  paymentsTable: { id: 'payments-table' },
};

const PAYMENTS_TABLE_CONFIG = [
  {
    key: 'start',
    label: 'Payment Date',
    flex: css`1 0 0`,
    formatter: v => parseDate(v).format('MM/DD/YYYY'),
  },
  {
    key: 'amount',
    label: 'Payment Amount',
    flex: css`1 0 0`,
    formatter: v => centsToCurrency(v),
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`1 0 0`,
    formatter: v => (v === null ? 'Scheduled' : v),
  },
];

export const CONFIRM_DELETE = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Delete One-Time Payment',
  message:
    'Deleting the scheduled payment will remove it from the recurring and scheduled payments list and the payment will not be processed. Are you sure you want to delete this scheduled payment?',
};

export default class NebFormScheduledPaymentSingle extends NebFormOld {
  static get properties() {
    return {
      __merchantAccountNames: Array,
      __paymentMethodNames: Array,
      __paymentTypeNames: Array,
      schedule: Array,
      patientId: String,
      merchantAccounts: Array,
    };
  }

  static createModel() {
    return {
      id: '',
      name: '',
      paymentType: null,
      amount: 0,
      start: null,
      paymentMethod: null,
      merchantAccount: null,
    };
  }

  initState() {
    super.initState();

    this.__merchantAccountNames = [];
    this.__paymentMethods = [];
    this.__paymentMethodNames = [];
    this.__paymentTypeNames = [];
    this.__today = parseDate();
    this.schedule = [];
    this.merchantAccounts = [];

    this.onDelete = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      transactionDateSelectable: date => date > this.__today,
      addPaymentMethod: async () => {
        const { addresses, firstName, lastName } = await fetchOne(
          this.patientId,
          true,
          true,
        );

        const holderAddress =
          addresses.length > 0
            ? {
                postalCode: addresses[0].zipcode,
                address1: addresses[0].address1,
                address2: addresses[0].address2,
                city: addresses[0].city,
                state: addresses[0].state,
              }
            : {
                postalCode: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
              };

        const result = await openPayfacIframePopup({
          ...ADD_PAYMENT_METHOD_TEMPLATE,
          merchantAccounts: this.merchantAccounts,
          firstName,
          lastName,
          holderId: this.patientId,
          ...holderAddress,
        });

        if (result) {
          this.__paymentMethods = await this.__fetchPaymentMethods();

          const addedPaymentMethod = this.__paymentMethods.find(
            pm => pm.id === result.id,
          );

          const merchantAccountOption = this.__merchantAccountNames.find(
            ma => ma.id === addedPaymentMethod.merchantAccountId,
          );

          this.__setPaymentMethodItems(merchantAccountOption);

          const paymentMethodOption = this.__paymentMethodNames.find(
            pm => pm.id === addedPaymentMethod.id,
          );

          this.formService.apply('merchantAccount', merchantAccountOption);
          this.formService.apply('paymentMethod', paymentMethodOption);
        }
      },
      delete: async () => {
        const confirmed = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          CONFIRM_DELETE,
        );

        if (confirmed) {
          this.onDelete();
        }
      },
      save: () => {
        if (this.formService.validate()) {
          const start = dateToRaw(this.state.start);

          let model = this.formService.buildModel();

          model = { ...model, start };

          this.__saving = true;
          this.onSave(model);
        }
      },
      change: e => this.__onChange(e),
    };
  }

  buildSelectors() {
    return {
      name: [required()],
      paymentType: [required()],
      amount: {
        format: v => centsToCurrency(v),
        unformat: v => toNumeric(v),
        validators: [
          {
            error: 'Must be greater than $0.00',
            validate: v => v !== '$0.00',
          },
          {
            error: 'Cannot exceed $200,000.00 for electronic payments',
            validate: v =>
              parseInt(Number(v.replace(/[^0-9.]+/g, '') * 100), 10) <=
              20000000,
          },
        ],
      },
      start: [required()],
      paymentMethod: [required()],
    };
  }

  async __fetchPaymentMethods() {
    if (!this.merchantAccounts.length) return [];

    const relationships = await getPatientRelationshipsActiveGroup(
      this.patientId,
    );

    const paymentMethods = await getPaymentMethods(
      this.merchantAccounts[0],
      this.patientId,
      {
        fetchAll: true,
        deleted: false,
      },
      relationships.related.length > 0
        ? [relationships.primary, ...relationships.related]
        : null,
    );

    return paymentMethods;
  }

  async __fetchBillingCodes() {
    const originalItems = await fetch(TYPE.PAYMENT);

    return originalItems.filter(v => v.active);
  }

  __loadDropdownSelections() {
    const model = this.formService.buildModel();

    let paymentType = null;
    let paymentMethod = null;

    if (this.merchantAccounts.length) {
      if (model.merchantAccountId && model.merchantAccountId.length) {
        model.merchantAccount =
          this.__merchantAccountNames.find(
            account => account.id === model.merchantAccountId,
          ) || null;
      } else {
        model.merchantAccount = this.__merchantAccountNames[0];
      }
    }

    if (
      this.__paymentTypeNames.length &&
      model.paymentTypeId &&
      model.paymentTypeId.length
    ) {
      paymentType =
        this.__paymentTypeNames.find(
          paymentType => paymentType.id === model.paymentTypeId,
        ) || null;

      model.paymentType = paymentType;
    }

    if (
      this.__paymentMethodNames.length &&
      model.paymentMethodId &&
      model.paymentMethodId.length
    ) {
      paymentMethod =
        this.__paymentMethodNames.find(
          paymentMethod => paymentMethod.id === model.paymentMethodId,
        ) || null;

      model.paymentMethod = paymentMethod;
    }

    this.formService.refresh(model);

    this.formService.apply('paymentType', paymentType);
    this.formService.apply('paymentMethod', paymentMethod);
  }

  __setDefaultPaymentMethod() {
    const defaultPayment = getDefaultPaymentMethod(
      this.__paymentMethodNames,
      this.patientId,
    );

    if (defaultPayment) {
      const model = this.formService.buildModel();

      model.paymentMethod = defaultPayment;

      this.formService.refresh(model);
      this.formService.validateKey(['paymentMethod']);
    }
  }

  __updatingSingle() {
    return this.model.id && this.model.id.length;
  }

  __onChange({ name, value, event }) {
    this.formService.apply(name, value);

    if (name === 'merchantAccount' && event === 'select') {
      this.__setPaymentMethodItems(value);
    }
  }

  __setPaymentMethodItems(filterByMerchantAccount) {
    let filteredPaymentMethods = this.__paymentMethods;

    if (filterByMerchantAccount || this.merchantAccounts.length) {
      const currentMerchantAccount = this.merchantAccounts.find(
        m => m.id === this.model.merchantAccountId,
      );

      const filteredPaymentMethodIds = filterPaymentMethodsByMerchantProvider(
        this.__paymentMethods,
        this.merchantAccounts,
        filterByMerchantAccount ||
          currentMerchantAccount ||
          this.merchantAccounts[0],
      ).map(({ id }) => id);

      filteredPaymentMethods = filteredPaymentMethods.filter(({ id }) =>
        filteredPaymentMethodIds.includes(id),
      );

      if (!filteredPaymentMethodIds.includes(this.state.paymentMethod?.id)) {
        this.formService.apply('paymentMethod', null);
      }
    }

    this.__paymentMethodNames = filteredPaymentMethods.map(v => ({
      ...v,
      label: v.description,
    }));
  }

  async updated(changedProps) {
    if (changedProps.has('patientId')) {
      this.__merchantAccountNames = this.merchantAccounts
        .filter(v => v.active)
        .map(v => ({
          ...v,
          label: v.name,
        }));

      const paymentTypes = await this.__fetchBillingCodes();

      this.__paymentMethods = await this.__fetchPaymentMethods();

      this.__paymentTypeNames = paymentTypes.map(v => ({
        ...v,
        label: `${v.code} - ${v.description}`,
      }));

      this.__setPaymentMethodItems();

      this.__loadDropdownSelections();

      if (!this.model.id) {
        this.__setDefaultPaymentMethod();
      }
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          align-items: center;
        }

        .grid-2 {
          grid-template-columns: 1fr 1fr;
        }

        .transactionDate {
          width: 100%;
          height: 70px;
        }

        .grid-row-tooltip {
          display: flex;
        }

        .tooltip {
          margin-left: 10px;
        }

        .table {
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  __deleteLabel() {
    return 'Delete';
  }

  renderActionBar() {
    if (this.__updatingSingle()) {
      return html`
        <neb-action-bar
          id="${ELEMENTS.actionBar.id}"
          confirmLabel="${this.confirmLabel}"
          cancelLabel="${this.__deleteLabel()}"
          removeLabel="${this.cancelLabel}"
          .onConfirm="${this.handlers.save}"
          .onCancel="${this.handlers.delete}"
          .onRemove="${this.handlers.cancel}"
        ></neb-action-bar>
      `;
    }

    return this.__dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="${this.confirmLabel}"
            cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  renderMethodsSection() {
    const merchantAccountField =
      this.__merchantAccountNames.length > 1
        ? html`
            <neb-select
              id="${ELEMENTS.merchantAccountDropdown.id}"
              name="merchantAccount"
              class="merchant-account"
              helper="Required"
              label="Merchant Account"
              .items="${this.__merchantAccountNames}"
              .value="${this.state.merchantAccount}"
              .onChange="${this.handlers.change}"
            ></neb-select>
          `
        : '';

    const paymentMethodField = html`
      <neb-select
        id="${ELEMENTS.paymentMethodDropdown.id}"
        name="paymentMethod"
        helper="Required"
        label="Payment Method"
        .items="${this.__paymentMethodNames}"
        .value="${this.state.paymentMethod}"
        .error="${this.errors.paymentMethod}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;

    const addPaymentMethodTooltip = html`
      <div class="grid-row-tooltip">
        <neb-button-action
          id="${ELEMENTS.addPaymentMethodButton.id}"
          label="Add Payment Method"
          .onClick="${this.handlers.addPaymentMethod}"
        ></neb-button-action>
        <neb-tooltip
          id="${ELEMENTS.addPaymentMethodTooltip.id}"
          class="tooltip"
          defaultAnchor="right"
          ><div slot="tooltip">
            Cards stored on file can be used to easily process transactions
            without requiring a card swipe. Debit cards that require a PIN for
            processing cannot be stored and can only be used by swiping with a
            card reader.
          </div>
        </neb-tooltip>
      </div>
    `;

    if (merchantAccountField) {
      return html`
        ${merchantAccountField} ${paymentMethodField}
        <div></div>
        ${addPaymentMethodTooltip}
      `;
    }
    return html`
      ${paymentMethodField} ${addPaymentMethodTooltip}
    `;
  }

  renderContent() {
    return html`
      <div class="grid grid-2">
        <neb-textfield
          id="${ELEMENTS.nameField.id}"
          name="name"
          helper="Required"
          label="Scheduled Payment Name"
          maxLength="50"
          .value="${this.state.name}"
          .error="${this.errors.name}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.paymentTypeDropdown.id}"
          name="paymentType"
          helper="Required"
          label="Payment Type"
          .items="${this.__paymentTypeNames}"
          .value="${this.state.paymentType}"
          .error="${this.errors.paymentType}"
          .onChange="${this.handlers.change}"
        ></neb-select>

        <neb-textfield
          id="${ELEMENTS.amountField.id}"
          name="amount"
          helper="Required"
          label="Payment Amount"
          maxLength="11"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .value="${this.state.amount}"
          .error="${this.errors.amount}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-date-picker
          id="${ELEMENTS.transactionDatePicker.id}"
          name="start"
          class="transactionDate"
          helperText="Required"
          label="Transaction Date"
          placeholder="Select Date"
          manualPopoverPosition="center"
          .selectedDate="${
            this.state.start ? parseDate(this.state.start) : null
          }"
          .invalidText="Required"
          .onChange="${this.handlers.change}"
          .isDateSelectable="${this.handlers.transactionDateSelectable}"
          ?invalid="${!!this.errors.start}"
          momentFlag
        ></neb-date-picker>

        ${this.renderMethodsSection()}
      </div>

      ${
        this.__updatingSingle()
          ? html`
              <neb-table
                id="${ELEMENTS.paymentsTable.id}"
                class="table"
                emptyMessage="There are no payments"
                .config="${PAYMENTS_TABLE_CONFIG}"
                .model="${this.schedule}"
              ></neb-table>
            `
          : ''
      }
    `;
  }
}

customElements.define(
  'neb-form-scheduled-payment-single',
  NebFormScheduledPaymentSingle,
);
