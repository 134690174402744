import '../../../../../../../src/components/collection-pages/neb-collection-page-rte-insurance-log';
import '../../../inputs/neb-textarea';
import '../../../controls/neb-tab-group';
import '../../../tables/neb-table-covered-services';
import '../../../tables/neb-table-non-covered-services';
import '../../neb-patient-panel';
import '../shared/neb-patient-insurance-last-updated-by-content';
import './coverage-details/neb-patient-insurance-copays-coverage-details';
import './coverage-details/neb-patient-insurance-visit-limit-coverage-details';
import './coverage-details/neb-patient-insurance-coinsurances-coverage-details';
import './coverage-details/neb-patient-insurance-deductibles-coverage-details';
import './coverage-details/neb-patient-insurance-out-of-pockets-coverage-details';
import './neb-patient-insurance-view-table';
import './policy-details/neb-patient-insurance-payer-table';
import './policy-details/neb-patient-insurance-eligibility-claims-details';
import './policy-details/neb-patient-insurance-policy-holder-details';
import './summary/neb-patient-insurance-coinsurances-summary';
import './summary/neb-patient-insurance-copays-summary';
import './summary/neb-patient-insurance-deductibles-summary';
import './summary/neb-patient-insurance-out-of-pockets-summary';
import './summary/neb-patient-insurance-visit-limit-summary';
import '../../../neb-button';
import '../../../neb-text';
import '../../../neb-header';

import { LitElement, html, css } from 'lit';

import {
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_COLOR_YELLOW,
} from '../../../../../../../src/styles';
import { ADD_ONS, hasAddOn } from '../../../../../../../src/utils/add-ons';
import { getRTEStatusIcon } from '../../../../../../../src/utils/real-time-eligibility';
import {
  getProviderUsers,
  getPracticeUsers,
} from '../../../../../../neb-api-client/src/practice-users-api-client';
import { getServiceTypes } from '../../../../../../neb-api-client/src/service-types';
import { openOverlayPatientInsuranceEdit } from '../../../../../../neb-app-layout/neb-open-overlay';
import { baseStyles } from '../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_1,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../../neb-styles/neb-variables';
import { SYSTEM_USER } from '../../../../../../neb-utils/constants';
import { parseDate } from '../../../../../../neb-utils/date-util';
import { toNumeric, objToName } from '../../../../../../neb-utils/formatters';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../../../neb-utils/neb-refresh';
import {
  createModelNewPatientInsurance,
  COVERED_SERVICE_TYPE,
} from '../../../../../../neb-utils/patientInsurance';
import { rteEnabledInsurance } from '../../../../../../neb-utils/payer-plan-util';
import { updateInsuranceFromRTE } from '../../../../../../neb-utils/real-time-eligibility';
import { CollectionService } from '../../../../../../neb-utils/services/collection';
import { MODE } from '../../../../../../neb-utils/table';
import { OVERLAY_KEYS, openOverlay } from '../../../../utils/overlay-constants';
import { SORT_DIR } from '../../../neb-table-header';
import { formatCode } from '../../ledger/charges/neb-ledger-charges-util';

export const ELEMENTS = {
  headerPlanNameText: {
    id: 'header-plan-name',
  },
  headerPlanIdText: {
    id: 'header-plan-id',
  },
  headerInsuredIdText: {
    id: 'header-insured-id',
  },
  editIcon: {
    id: 'edit-icon',
  },
  editText: {
    id: 'edit-text',
  },
  closeButton: {
    id: 'close-button',
  },
  tabs: { id: 'tabs' },
  servicesTabs: { id: 'services-tabs' },
  insuranceViewTable: {
    id: 'insurance-view-table',
  },
  lastUpdatedContent: {
    id: 'last-updated-by-content',
  },
  copaysSummary: {
    id: 'copays-summary',
  },
  coinsurancesSummary: {
    id: 'coinsurances-summary',
  },
  deductiblesSummary: {
    id: 'deductibles-summary',
  },
  outOfPocketsSummary: {
    id: 'out-of-pockets-summary',
  },
  visitLimitSummary: {
    id: 'visit-limit-summary',
  },
  coverageDetailsPage: {
    id: 'coverage-details-page',
  },
  copaysCoverageDetails: {
    selector: 'neb-patient-insurance-copays-coverage-details',
  },
  coinsurancesCoverageDetails: {
    selector: 'neb-patient-insurance-coinsurances-coverage-details',
  },
  deductiblesCoverageDetails: {
    selector: 'neb-patient-insurance-deductibles-coverage-details',
  },
  outOfPocketsCoverageDetails: {
    selector: 'neb-patient-insurance-out-of-pockets-coverage-details',
  },
  visitLimitCoverageDetails: {
    id: 'visit-limit-coverage-details',
  },
  payerTable: {
    id: 'payer-table',
  },
  policyHolderDetails: {
    id: 'policy-holder-details',
  },
  eligibilityClaimsDetails: {
    id: 'eligibility-claims-details',
  },
  notes: {
    id: 'planInfo-notes',
  },
  providerInOutOfNetworkHeaderLink: {
    id: 'in-out-of-network-header-link',
  },
  providerInNetworkText: {
    id: 'provider-in-network-text',
  },
  providerOutOfNetworkText: {
    id: 'provider-out-of-network-text',
  },
  servicesHeader: {
    id: 'services-header',
  },
  tabCoveredServicesDetails: {
    id: 'tab-covered-services-details',
  },
  tabNonCoveredServices: {
    id: 'tab-non-covered-services',
  },
  searchCoveredServicesField: {
    id: 'search-covered-services',
  },
  searchNonCoveredServicesField: {
    id: 'search-non-covered-services',
  },
  coveredServicesTable: {
    id: 'covered-services-table',
  },
  coveredServicesPagination: {
    id: 'covered-services-pagination',
  },
  nonCoveredServicesTable: {
    id: 'non-covered-services-table',
  },
  nonCoveredServicesPagination: {
    id: 'non-covered-services-pagination',
  },
  collectionPageRTEInsuranceLog: { id: 'collection-page-rte-insurance-log' },
  buttonCheckEligibility: { id: 'button-check-eligibility' },
  iconRealTimeEligibilityStatus: { id: 'icon-real-time-eligibility-status' },
};

export const NO_NOTES_PLACEHOLDER = 'This insurance does not have a note';

export const COVERED_SERVICE_TEXT =
  'Review details regarding covered service types or procedures.';

export const NON_COVERED_SERVICES_TEXT =
  'Review a list of non-covered service types or procedures.';

export const NO_COVERED_SERVICES_MESSAGE = 'There are no covered services';

export const NO_NON_COVERED_SERVICES_MESSAGE =
  'There are no non-covered services';

class NebPatientInsuranceViewController extends LitElement {
  static get properties() {
    return {
      jsonExists: Boolean,
      rteJSON: Object,
      patientId: String,
      patientInsurance: Object,
      slim: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        reflect: true,
        type: String,
      },

      __tabs: Array,
      __updatedByUser: String,
      __serviceTypes: Array,
      __updatedAtFormatted: String,
      __selectedTab: {
        type: String,
      },
      __selectedServicesTab: {
        type: String,
      },
      __coveredServicesCollectionState: Object,
      __nonCoveredServicesCollectionState: Object,
      __expandFlags: Array,
      __coveredServiceSortParams: Array,
      __nonCoveredServiceSortParams: Array,
      __payerPlanProviderInOutOfNetwork: Array,
      __providers: Array,
      __checkingRTE: Boolean,
      __hasAddOnCTVerify: { type: Boolean },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        .spacer {
          flex: 1 0 0;
        }

        .button {
          margin-top: 5px;
        }

        .header-container {
          padding: ${CSS_SPACING};
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap-reverse;
        }

        :host([layout='small']) .header-container {
          align-items: unset;
          justify-content: end;
          flex-wrap: unset;
        }

        .header-content {
          display: flex;
          gap: 40px;
          place-items: center;
          flex-wrap: wrap;
        }

        :host([layout='small']) .header-content {
          flex-direction: column;
          gap: 0;
          align-items: center;
          flex-wrap: unset;
        }

        .insurance-id-info-container {
          display: grid;
          grid-template-columns: auto auto;
          grid-gap: 10px;
        }

        .grid {
          display: grid;
        }

        .title {
          font-size: 16px;
          margin-top: 0;
          min-width: 60px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 40px;
        }

        :host([layout='small']) .title {
          text-align: center;
          padding-right: 0;
        }

        .header-column {
          padding-left: 40px;
          max-width: 200px;
          display: flex;
          flex-direction: column;
        }

        .header-column-group-id {
          padding: initial;
        }

        .header-column-insured-id {
          padding-left: 10px;
        }

        :host([layout='small']) .header-column-insured-id,
        :host([layout='small']) .header-column-group-id {
          padding-left: 0;
        }

        .header-column:first-of-type {
          padding-left: 0;
        }

        .notes-container {
          padding: ${CSS_SPACING};
        }

        .notes {
          height: 100%;
          width: 100%;
          padding-bottom: 80px;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .header-column-title {
          min-height: ${CSS_SPACING};
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .header-column-description {
          min-height: ${CSS_SPACING};
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .edit-container {
          padding-left: 40px;
          display: flex;
          text-wrap: nowrap;
        }

        :host([layout='small']) .edit-container {
          padding-left: 0;
          padding-right: 10px;
        }

        .header-title-mobile {
          display: flex;
          justify-content: space-between;
        }

        .header-options-mobile {
          display: flex;
        }

        .edit-icon {
          margin-right: 4px;
          width: 22px;
          height: 22px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .close-icon {
          cursor: pointer;
          min-width: 24px;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .form-container {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          width: 100%;
          overflow: auto;
        }

        .coverage-header {
          padding-top: 40px;
        }

        .services-header {
          padding: 40px 0px ${CSS_SPACING};
        }

        .services-sub-header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .provider-header-container {
          padding: ${CSS_SPACING};
        }

        .provider-sub-header {
          padding-bottom: 5px;
        }

        .panel-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING};
          padding: ${CSS_SPACING};
        }

        :host([layout='small']) .panel-container {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: ${CSS_SPACING};
          padding: ${CSS_SPACING} 0px;
        }

        .search-field {
          padding: 16px ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .table {
          padding-top: ${CSS_SPACING};
        }

        .no-covered-services {
          padding-left: ${CSS_SPACING};
        }

        .pagination {
          flex-direction: row-reverse;
          padding-top: ${CSS_SPACING};
          padding-right: ${CSS_SPACING};
          margin-bottom: ${CSS_SPACING};
        }

        .icon-status {
          display: block;
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        :host([layout='small']) .icon-status {
          margin: 15px 0;
        }

        .icon-warning {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_YELLOW};
        }

        .icon-validated,
        .icon-partially-validated {
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }

        .link {
          text-decoration: underline;
          font-weight: bold;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .no-result-text {
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }

        .provider-networks-container {
          padding: 0 10px 10px;
        }

        .provider-networks-row {
          display: flex;
        }

        .summary-table {
          width: auto;
        }

        :host([layout='small']) .summary-table {
          width: max-content;
        }

        .flex-one {
          padding-right: ${CSS_SPACING};
          margin-left: 10px;
          width: 35%;
        }

        summary-coverage {
          padding: inherit;
        }

        :host([layout='small']) .coverage-container,
        :host([layout='small']) .summary-coverage {
          padding: 10px 20px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.rteJSON = {
      idInfo: { subscriber: {}, payer: {}, provider: {} },
      benefitInfo: { activeServiceTypes: {}, inactiveServiceTypes: {} },
    };

    this.jsonExists = false;
    this.slim = false;
    this.layout = '';
    this.patientId = '';
    this.patientInsurance = { ...createModelNewPatientInsurance() };

    this.__tabs = [
      { id: 'summary', label: 'Summary' },
      { id: 'policy-details', label: 'Policy Details' },
      { id: 'coverage-details', label: 'Coverage Details' },
      { id: 'rte-history-log', label: 'RTE History Log' },
    ];

    this.__checkingRTE = false;
    this.__serviceTypes = [];
    this.__selectedTab = this.__tabs[0].id;
    this.__selectedServicesTab = 'covered-services-details';
    this.__updatedByUser = '';
    this.__updatedAtFormatted = '';
    this.__coveredServicesCollectionState = CollectionService.createModel();
    this.__nonCoveredServicesCollectionState = CollectionService.createModel();
    this.__payerPlanProviderInOutOfNetwork = [];
    this.__providers = [];
    this.__expandFlags = [];
    this.__coveredServiceSortParams = [
      {
        key: 'serviceTypeProcedureCode',
        dir: SORT_DIR.ASC,
      },
    ];

    this.__nonCoveredServiceSortParams = [
      {
        key: 'serviceTypeProcedureCode',
        dir: SORT_DIR.ASC,
      },
    ];

    this.__navItems = [
      {
        id: 'covered-services-details',
        label: 'Covered Services Details',
        renderer: () => this.__renderCoveredServiceDetails(),
      },
      {
        id: 'non-covered-services',
        label: 'Non-Covered Services',
        renderer: () => this.__renderNonCoveredServices(),
      },
    ];

    this.__hasAddOnCTVerify = false;

    this.onClose = () => {};

    this.onChange = () => {};

    this.onOpenReport = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      panelClick: () => {},
      close: () => this.onClose(),
      selectTab: selectedTab => {
        if (this.__selectedTab !== this.__tabs[2].id) {
          this.__selectedServicesTab = 'covered-services-details';
        }

        this.__selectedTab = selectedTab;
      },
      selectServicesTab: selectedServicesTab => {
        this.__selectedServicesTab = selectedServicesTab;
      },
      checkRealTimeEligibility: async () => {
        if (!this.__checkingRTE) await this.__checkEligibility();
      },
      edit: async () => {
        let existingNode;

        if (this.jsonExists) {
          const benefitViewer = this.parentNode.querySelector(
            '#benefits-viewer',
          );

          existingNode = benefitViewer || null;
        }

        const updatedInsurance = await openOverlayPatientInsuranceEdit({
          patientId: this.patientId,
          patientInsurance: this.patientInsurance,
          context: {
            hasAddOnCTVerify: this.__hasAddOnCTVerify,
            providers: this.__providers,
            jsonExists: this.jsonExists,
            rteJSON: this.rteJSON,
            existingNode,
          },
        });

        if (existingNode) {
          existingNode.attachedToEdit = false;
          this.parentNode.insertBefore(
            existingNode,
            this.parentNode.firstChild,
          );
        }

        if (updatedInsurance) this.onChange(updatedInsurance);

        sendRefreshNotification(
          [REFRESH_CHANGE_TYPE.PATIENT_INSURANCE, REFRESH_CHANGE_TYPE.PATIENT],
          this.patientId,
        );
      },
      clearSearchCoveredServices: () =>
        this.__coveredServicesCollectionService.search(''),
      changeSearchCoveredServices: ({ value }) => {
        if (value !== this.__coveredServicesCollectionState.searchText) {
          this.__coveredServicesCollectionService.search(value);
        }
      },
      clearSearchNonCoveredServices: () =>
        this.__nonCoveredServicesCollectionService.search(''),
      changeSearchNonCoveredServices: ({ value }) => {
        if (value !== this.__nonCoveredServicesCollectionState.searchText) {
          this.__nonCoveredServicesCollectionService.search(value);
        }
      },
      searchServices: ({ terms, item }) => {
        let firstColumn;
        let description;

        if (item.type === COVERED_SERVICE_TYPE.ServiceType) {
          firstColumn = item.serviceType.code;
          description = item.serviceType.description;
        } else {
          firstColumn = formatCode(
            item.charge.procedure,
            item.charge.modifiers,
          );

          description = item.charge.description;
        }

        return terms.every(
          term =>
            firstColumn.toLowerCase().includes(term) ||
            description.toLowerCase().includes(term),
        );
      },
      changeCoveredServicesCollectionState: state => {
        this.__coveredServicesCollectionState = state;
        this.__expandFlags = this.__coveredServicesCollectionState.pageItems.map(
          () => false,
        );
      },
      pageChangedCoveredServices: index =>
        this.__coveredServicesCollectionService.setPageIndex(index),
      toggleExpand: (_name, _item, index) => {
        const expandFlags = this.__expandFlags;

        expandFlags[index] = !expandFlags[index];

        this.__expandFlags = [...expandFlags];
      },
      changeNonCoveredServicesCollectionState: state => {
        this.__nonCoveredServicesCollectionState = state;
      },
      pageChangedNonCoveredServices: index =>
        this.__nonCoveredServicesCollectionService.setPageIndex(index),
      sortCoveredServices: (_name, result) => {
        this.__coveredServiceSortParams = [result[0]];
        this.__coveredServicesCollectionService.setSortParams(result[0]);
      },
      sortNonCoveredServices: (_name, result) => {
        this.__nonCoveredServiceSortParams = [result[0]];
        this.__nonCoveredServicesCollectionService.setSortParams(result[0]);
      },
      viewPayerPlan: async () => {
        const shouldUpdate = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: this.patientInsurance.payerPlan.id,
        });

        if (shouldUpdate) {
          this.onChange();
        }
      },
      sort: (a, b, key) => {
        switch (key) {
          case 'serviceTypeProcedureCode':
            let aCompareString;
            let bCompareString;

            if (a.type === COVERED_SERVICE_TYPE.ServiceType) {
              aCompareString = a.serviceType.code;
            } else {
              aCompareString = formatCode(
                a.charge.procedure,
                a.charge.modifiers,
              );
            }

            if (b.type === COVERED_SERVICE_TYPE.ServiceType) {
              bCompareString = b.serviceType.code;
            } else {
              bCompareString = formatCode(
                b.charge.procedure,
                b.charge.modifiers,
              );
            }

            return aCompareString.localeCompare(bCompareString);

          case 'copay':
            const inCopayCompare = toNumeric(
              a.inNetworkCopayAmount,
            ).localeCompare(toNumeric(b.inNetworkCopayAmount));

            if (inCopayCompare === 0) {
              return toNumeric(a.outOfNetworkCopayAmount).localeCompare(
                toNumeric(b.outOfNetworkCopayAmount),
              );
            }

            return inCopayCompare;

          case 'coinsurance':
            const inCoinsuranceCompare = a.inNetworkCoinsurancePercent.localeCompare(
              b.inNetworkCoinsurancePercent,
              undefined,
              { numeric: true },
            );

            if (inCoinsuranceCompare === 0) {
              return a.outOfNetworkCoinsurancePercent.localeCompare(
                b.outOfNetworkCoinsurancePercent,
                undefined,
                { numeric: true },
              );
            }

            return inCoinsuranceCompare;

          case 'preAuthorization':
            if (a.preAuthorizationRequired === b.preAuthorizationRequired) {
              return 0;
            }
            if (!a.preAuthorizationRequired) return -1;

            return 1;

          case 'description':
            let aDescription;
            let bDescription;

            if (a.type === COVERED_SERVICE_TYPE.ServiceType) {
              aDescription = a.serviceType.description;
            } else aDescription = a.charge.description;

            if (b.type === COVERED_SERVICE_TYPE.ServiceType) {
              bDescription = b.serviceType.description;
            } else bDescription = b.charge.description;

            return aDescription.localeCompare(bDescription);

          default:
            return undefined;
        }
      },
      openReport: report => {
        this.onOpenReport(report);
      },
    };
  }

  __initServices() {
    this.__coveredServicesCollectionService = new CollectionService(
      {
        onChange: this.__handlers.changeCoveredServicesCollectionState,
        onSort: this.__handlers.sort,
        onSearch: this.__handlers.searchServices,
      },
      {
        sortParams: {
          key: 'serviceTypeProcedureCode',
          dir: SORT_DIR.ASC,
        },
        hideInactive: false,
      },
    );

    this.__nonCoveredServicesCollectionService = new CollectionService(
      {
        onChange: this.__handlers.changeNonCoveredServicesCollectionState,
        onSort: this.__handlers.sort,
        onSearch: this.__handlers.searchServices,
      },
      {
        sortParams: {
          key: 'serviceTypeProcedureCode',
          dir: SORT_DIR.ASC,
        },
        hideInactive: false,
      },
    );
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasAddOnCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
  }

  __setCoveredServicesPage() {
    const { pageIndex } = this.__coveredServicesCollectionState;

    this.__coveredServicesCollectionService.setItems(
      this.patientInsurance.coveredServices,
    );

    this.__coveredServicesCollectionService.setPageIndex(pageIndex);
  }

  __setNonCoveredServicesPage() {
    const { pageIndex } = this.__nonCoveredServicesCollectionState;

    this.__nonCoveredServicesCollectionService.setItems(
      this.patientInsurance.nonCoveredServices,
    );

    this.__nonCoveredServicesCollectionService.setPageIndex(pageIndex);
  }

  async __checkEligibility() {
    this.__checkingRTE = true;

    try {
      const updatedInsurance = await updateInsuranceFromRTE(
        this.patientInsurance.id,
        this.patientId,
        { providerId: this.patientInsurance.providerId || null },
      );

      if (updatedInsurance) this.onChange(updatedInsurance);

      sendRefreshNotification(
        [REFRESH_CHANGE_TYPE.PATIENT_INSURANCE, REFRESH_CHANGE_TYPE.PATIENT],
        this.patientId,
      );
    } catch (e) {
      console.log(e);
    }

    this.__checkingRTE = false;
  }

  update(changedProps) {
    if (changedProps.has('patientInsurance')) {
      this.__setCoveredServicesPage();
      this.__setNonCoveredServicesPage();
    }

    super.update(changedProps);
  }

  async firstUpdated() {
    this.__serviceTypes = await getServiceTypes({
      hideInactive: false,
    });

    await this.__fetchProviders();
    this.__mapPayerPlanProvidersInOutOfNetwork();
  }

  async updated(changedProps) {
    if (changedProps.has('patientInsurance')) {
      if (this.patientInsurance.updatedAt) {
        this.__updatedAtFormatted = parseDate(
          this.patientInsurance.updatedAt,
        ).format('MM/DD/YYYY');
      }

      this.__mapPayerPlanProvidersInOutOfNetwork();

      if (this.patientInsurance.updatedBy === SYSTEM_USER.id) {
        this.__updatedByUser = 'System';
      } else {
        const user = (await getPracticeUsers()).find(
          u => u.id === this.patientInsurance.updatedBy,
        );

        this.__updatedByUser = user
          ? `${user.name.first} ${user.name.last}`
          : '';
      }
    }
  }

  __formatProvider(provider) {
    return {
      ...provider,
      label: objToName(provider.name, {
        middleInitial: true,
        reverse: true,
        preferred: true,
      }),
    };
  }

  async __fetchProviders() {
    this.__providers = (await getProviderUsers())
      .filter(
        provider =>
          provider.type === 'provider' || provider.type === 'specialist',
      )
      .map(provider => this.__formatProvider(provider));
  }

  __mapPayerPlanProvidersInOutOfNetwork() {
    this.__payerPlanProviderInOutOfNetwork = this.__providers.map(p => {
      const matchingProvider = this.patientInsurance.payerPlan.providerInOutOfNetwork.find(
        entry => entry.providerId === p.id,
      );

      return {
        label: p ? p.label : '',
        isInNetwork: matchingProvider ? matchingProvider.isInNetwork : null,
      };
    });
  }

  __renderEdit() {
    return html`
      <div class="edit-container">
        <neb-icon
          id="${ELEMENTS.editIcon.id}"
          class="edit-icon"
          icon="neb:edit"
        ></neb-icon>

        <neb-text
          id="${ELEMENTS.editText.id}"
          bold
          link
          @click="${this.__handlers.edit}"
          >EDIT</neb-text
        >
      </div>
    `;
  }

  __renderStatusRTE() {
    return this.__hasAddOnCTVerify &&
      rteEnabledInsurance(this.patientInsurance.payerPlan)
      ? html`
          <neb-icon
            id="${ELEMENTS.iconRealTimeEligibilityStatus.id}"
            class="icon-status icon-${
              this.patientInsurance.realTimeEligibilityStatus
            }"
            .icon="${
              this.patientInsurance.realTimeEligibilityStatus
                ? getRTEStatusIcon(
                    this.patientInsurance.realTimeEligibilityStatus,
                  )
                : ''
            }"
          ></neb-icon>
        `
      : '';
  }

  __renderCheckRTE() {
    return this.__hasAddOnCTVerify &&
      rteEnabledInsurance(this.patientInsurance.payerPlan)
      ? html`
          <neb-button
            id="${ELEMENTS.buttonCheckEligibility.id}"
            class="button"
            label="Check Eligibility"
            ?disabled="${this.__checkingRTE}"
            .onClick="${this.__handlers.checkRealTimeEligibility}"
          ></neb-button>
        `
      : '';
  }

  __renderTitle() {
    return this.patientInsurance.planInfo.planName
      ? html`
          <h2 id="${ELEMENTS.headerPlanNameText.id}" class="title">
            ${this.patientInsurance.planInfo.planName}
          </h2>
        `
      : '';
  }

  __renderCloseButton() {
    return html`
      <neb-icon
        id="${ELEMENTS.closeButton.id}"
        class="close-icon"
        icon="neb:close"
        @click="${this.__handlers.close}"
      ></neb-icon>
    `;
  }

  __renderHeaderTitleMobile() {
    return this.layout === 'small'
      ? html`
          <div class="header-title-mobile">
            ${this.__renderTitle()}
            <div class="header-options-mobile">
              ${this.__renderEdit()} ${this.__renderCloseButton()}
            </div>
          </div>
        `
      : html`
          ${this.__renderTitle()}
        `;
  }

  __renderHeader() {
    return html`
      <div class="header-content">
        ${this.__renderTitle()}
        <div class="insurance-id-info-container">
          <div class="grid">
            <span class="bold">Group ID</span>
            <span id="${ELEMENTS.headerPlanIdText.id}"
              >${this.patientInsurance.planInfo.groupIdentifier}</span
            >
          </div>
          <div class="grid">
            <span class="bold">Insured ID</span>
            <span id="${ELEMENTS.headerInsuredIdText.id}"
              >${this.patientInsurance.planInfo.memberIdentifier}</span
            >
          </div>
        </div>
        ${this.__renderEdit()} ${this.__renderStatusRTE()}
        ${this.__renderCheckRTE()}
      </div>
      <div></div>
      ${this.__renderCloseButton()}
    `;
  }

  __renderHeaderMobile() {
    return html`
      <div class="header-content">
        ${this.__renderTitle()}
        <div class="insurance-id-info-container">
          <div class="grid">
            <span class="bold">Group ID</span>
            <span id="${ELEMENTS.headerPlanIdText.id}"
              >${this.patientInsurance.planInfo.groupIdentifier}</span
            >
          </div>
          <div class="grid">
            <span class="bold">Insured ID</span>
            <span id="${ELEMENTS.headerInsuredIdText.id}"
              >${this.patientInsurance.planInfo.memberIdentifier}</span
            >
          </div>
        </div>
        ${this.__renderStatusRTE()} ${this.__renderCheckRTE()}
      </div>
      <div></div>
      ${this.__renderEdit()} ${this.__renderCloseButton()}
    `;
  }

  __renderSummaryCoverage() {
    return html`
      <neb-header class="coverage-header" label="Coverage"></neb-header>

      <div class="panel-container summary-coverage">
        <neb-patient-panel
          .layout="${this.layout}"
          title="Copays"
          .onClick="${this.__handlers.panelClick}"
        >
          <neb-patient-insurance-copays-summary
            id="${ELEMENTS.copaysSummary.id}"
            .copays="${this.patientInsurance.copays}"
            .allServiceTypes="${this.__serviceTypes}"
            .layout="${this.layout}"
          ></neb-patient-insurance-copays-summary>
        </neb-patient-panel>

        <neb-patient-panel
          .layout="${this.layout}"
          title="Coinsurances"
          .onClick="${this.__handlers.panelClick}"
        >
          <neb-patient-insurance-coinsurances-summary
            id="${ELEMENTS.coinsurancesSummary.id}"
            .coinsurances="${this.patientInsurance.coinsurances}"
            .allServiceTypes="${this.__serviceTypes}"
            .layout="${this.layout}"
          ></neb-patient-insurance-coinsurances-summary>
        </neb-patient-panel>

        <neb-patient-panel
          .layout="${this.layout}"
          title="Deductibles"
          .onClick="${this.__handlers.panelClick}"
        >
          <neb-patient-insurance-deductibles-summary
            id="${ELEMENTS.deductiblesSummary.id}"
            .deductibles="${this.patientInsurance.deductibles}"
            .layout="${this.layout}"
          ></neb-patient-insurance-deductibles-summary>
        </neb-patient-panel>

        <neb-patient-panel
          .layout="${this.layout}"
          title="Out of Pocket Maximums"
          .onClick="${this.__handlers.panelClick}"
        >
          <neb-patient-insurance-out-of-pockets-summary
            id="${ELEMENTS.outOfPocketsSummary.id}"
            .outOfPockets="${this.patientInsurance.outOfPockets}"
            .layout="${this.layout}"
          ></neb-patient-insurance-out-of-pockets-summary>
        </neb-patient-panel>

        <neb-patient-panel
          .layout="${this.layout}"
          title="Visit Limits"
          .onClick="${this.__handlers.panelClick}"
        >
          <neb-patient-insurance-visit-limit-summary
            id="${ELEMENTS.visitLimitSummary.id}"
            .visitLimit="${this.patientInsurance.patientInsuranceVisitLimit}"
            .layout="${this.layout}"
          ></neb-patient-insurance-visit-limit-summary>
        </neb-patient-panel>
      </div>
    `;
  }

  __renderSummary() {
    return html`
      <neb-patient-insurance-view-table
        id="${ELEMENTS.insuranceViewTable.id}"
        .patientInsurance="${this.patientInsurance}"
        class="summary-table"
      ></neb-patient-insurance-view-table>

      <neb-patient-insurance-last-updated-by-content
        id="${ELEMENTS.lastUpdatedContent.id}"
        .updatedBy="${this.__updatedByUser}"
        .updatedAt="${this.__updatedAtFormatted}"
      ></neb-patient-insurance-last-updated-by-content>

      ${this.__renderSummaryCoverage()}
    `;
  }

  __notesContent() {
    return this.patientInsurance.planInfo.notes.length > 0
      ? this.patientInsurance.planInfo.notes
      : NO_NOTES_PLACEHOLDER;
  }

  __renderPolicyDetails() {
    return html`
      <neb-patient-insurance-payer-table
        id="${ELEMENTS.payerTable.id}"
        .patientInsurance="${this.patientInsurance}"
      ></neb-patient-insurance-payer-table>

      <neb-patient-insurance-eligibility-claims-details
        id="${ELEMENTS.eligibilityClaimsDetails.id}"
        .patientInsurance="${this.patientInsurance}"
      ></neb-patient-insurance-eligibility-claims-details>

      <neb-patient-insurance-policy-holder-details
        id="${ELEMENTS.policyHolderDetails.id}"
        .patientInsurance="${this.patientInsurance}"
      ></neb-patient-insurance-policy-holder-details>

      <div class="notes-container">
        <neb-textarea
          class="notes"
          id="${ELEMENTS.notes.id}"
          label="Notes"
          maxlength="500"
          .value="${this.__notesContent()}"
          ?readonly="${true}"
        ></neb-textarea>
      </div>
    `;
  }

  __renderCopaysCoverageDetails() {
    return this.patientInsurance.copays.map(
      copay => html`
        <neb-patient-insurance-copays-coverage-details
          .copay="${copay}"
          .allServiceTypes="${this.__serviceTypes}"
        ></neb-patient-insurance-copays-coverage-details>
      `,
    );
  }

  __renderVisitLimitCoverageDetails() {
    return html`
      <neb-patient-insurance-visit-limit-coverage-details
        .id="${ELEMENTS.visitLimitCoverageDetails.id}"
        .visitLimit="${this.patientInsurance.patientInsuranceVisitLimit}"
      ></neb-patient-insurance-visit-limit-coverage-details>
    `;
  }

  __renderCoinsurancesCoverageDetails() {
    return this.patientInsurance.coinsurances.map(
      coinsurance => html`
        <neb-patient-insurance-coinsurances-coverage-details
          .coinsurance="${coinsurance}"
          .allServiceTypes="${this.__serviceTypes}"
        ></neb-patient-insurance-coinsurances-coverage-details>
      `,
    );
  }

  __renderDeductiblesCoverageDetails() {
    return this.patientInsurance.deductibles.map(
      deductible => html`
        <neb-patient-insurance-deductibles-coverage-details
          .deductible="${deductible}"
          .allServiceTypes="${this.__serviceTypes}"
        ></neb-patient-insurance-deductibles-coverage-details>
      `,
    );
  }

  __renderOutOfPocketsCoverageDetails() {
    return this.patientInsurance.outOfPockets.map(
      outOfPocket => html`
        <neb-patient-insurance-out-of-pockets-coverage-details
          .outOfPocket="${outOfPocket}"
        ></neb-patient-insurance-out-of-pockets-coverage-details>
      `,
    );
  }

  __renderNoCoveredServicesText() {
    return html`
      <div class="no-covered-services">${NO_COVERED_SERVICES_MESSAGE}</div>
    `;
  }

  __renderCoveredServicesPagination() {
    return html`
      <neb-pagination
        id="${ELEMENTS.coveredServicesPagination.id}"
        class="pagination"
        .pageCount="${this.__coveredServicesCollectionState.pageCount}"
        .currentPage="${this.__coveredServicesCollectionState.pageIndex}"
        .onPageChanged="${this.__handlers.pageChangedCoveredServices}"
      ></neb-pagination>
    `;
  }

  __renderNonCoveredServicesPagination() {
    return html`
      <neb-pagination
        id="${ELEMENTS.nonCoveredServicesPagination.id}"
        class="pagination"
        .pageCount="${this.__nonCoveredServicesCollectionState.pageCount}"
        .currentPage="${this.__nonCoveredServicesCollectionState.pageIndex}"
        .onPageChanged="${this.__handlers.pageChangedNonCoveredServices}"
      ></neb-pagination>
    `;
  }

  __renderCoveredServicesTable() {
    return html`
      <neb-table-covered-services
        id="${ELEMENTS.coveredServicesTable.id}"
        class="table"
        emptyMessage="${NO_COVERED_SERVICES_MESSAGE}"
        .model="${this.__coveredServicesCollectionState.pageItems}"
        .mode="${MODE.EXPAND}"
        .expandFlags="${this.__expandFlags}"
        .onToggleExpand="${this.__handlers.toggleExpand}"
        .onSort="${this.__handlers.sortCoveredServices}"
        .sortParams="${this.__coveredServiceSortParams}"
        .layout="${this.layout}"
        >${this.__renderNoCoveredServicesText()}</neb-table-covered-services
      >

      ${this.__renderCoveredServicesPagination()}
    `;
  }

  __renderNonCoveredServicesTable() {
    return html`
      <neb-table-non-covered-services
        id="${ELEMENTS.nonCoveredServicesTable.id}"
        class="table"
        emptyMessage="${NO_NON_COVERED_SERVICES_MESSAGE}"
        .model="${this.__nonCoveredServicesCollectionState.pageItems}"
        .mode="${MODE.NONE}"
        .onSort="${this.__handlers.sortNonCoveredServices}"
        .sortParams="${this.__nonCoveredServiceSortParams}"
        .layout="${this.layout}"
      ></neb-table-non-covered-services>

      ${this.__renderNonCoveredServicesPagination()}
    `;
  }

  __renderCoveredServiceDetails() {
    return html`
      <div
        id="${ELEMENTS.tabCoveredServicesDetails.id}"
        class="services-sub-header"
      >
        ${COVERED_SERVICE_TEXT}
      </div>

      <neb-textfield
        id="${ELEMENTS.searchCoveredServicesField.id}"
        class="search-field"
        label="Enter code or description to filter list below."
        leadingIcon="neb:search"
        .trailingIcon="${
          this.__coveredServicesCollectionState.searchText ? 'neb:clear' : ''
        }"
        .trailingVisible="${!!this.__coveredServicesCollectionState.searchText}"
        .value="${this.__coveredServicesCollectionState.searchText}"
        .onChange="${this.__handlers.changeSearchCoveredServices}"
        .onClickIcon="${this.__handlers.clearSearchCoveredServices}"
      ></neb-textfield>

      ${this.__renderCoveredServicesTable()}
    `;
  }

  __renderNonCoveredServices() {
    return html`
      <div
        id="${ELEMENTS.tabNonCoveredServices.id}"
        class="services-sub-header"
      >
        ${NON_COVERED_SERVICES_TEXT}
      </div>

      <neb-textfield
        id="${ELEMENTS.searchNonCoveredServicesField.id}"
        class="search-field"
        label="Enter code or description to filter list below."
        leadingIcon="neb:search"
        .trailingIcon="${
          this.__nonCoveredServicesCollectionState.searchText ? 'neb:clear' : ''
        }"
        .trailingVisible="${
          !!this.__nonCoveredServicesCollectionState.searchText
        }"
        .value="${this.__nonCoveredServicesCollectionState.searchText}"
        .onChange="${this.__handlers.changeSearchNonCoveredServices}"
        .onClickIcon="${this.__handlers.clearSearchNonCoveredServices}"
      ></neb-textfield>

      ${this.__renderNonCoveredServicesTable()}
    `;
  }

  __renderSelectedTab() {
    const item = this.__navItems.find(
      item => item.id === this.__selectedServicesTab,
    );

    return item.renderer();
  }

  __renderCoveredNonCoveredServicesTabs() {
    return html`
      <neb-tab-group
        class="tabs"
        id="${ELEMENTS.servicesTabs.id}"
        .selectedId="${this.__selectedServicesTab}"
        .items="${this.__navItems}"
        .onSelect="${this.__handlers.selectServicesTab}"
      ></neb-tab-group>
    `;
  }

  __renderProviderInOutOfNetwork(networkType) {
    const matchingProviders = this.__payerPlanProviderInOutOfNetwork.filter(
      entry => entry.isInNetwork === networkType,
    );

    if (!matchingProviders.length) {
      return html`
        <div class="no-result-text">
          There are no providers listed as
          ${networkType === true ? 'in network' : 'out of network'} for this
          plan.
        </div>
      `;
    }

    return matchingProviders.map(
      p =>
        html`
          <div>${p.label}</div>
        `,
    );
  }

  __renderProviderInOutOfNetworkSection() {
    return html`
      <neb-text
        id="${ELEMENTS.providerInOutOfNetworkHeaderLink.id}"
        class="provider-header-container bold link"
        link
        .onClick="${this.__handlers.viewPayerPlan}"
        >Providers In and Out of Network
      </neb-text>

      <div class="provider-networks-container">
        <div class="provider-networks-row">
          <div class="flex-one">
            <div class="provider-sub-header bold">In Network</div>
            <neb-text id="${ELEMENTS.providerInNetworkText.id}">
              ${this.__renderProviderInOutOfNetwork(true)}
            </neb-text>
          </div>

          <div class="flex-one">
            <div class="provider-sub-header bold">Out of Network</div>
            <neb-text id="${ELEMENTS.providerOutOfNetworkText.id}">
              ${this.__renderProviderInOutOfNetwork(false)}
            </neb-text>
          </div>
        </div>
      </div>
    `;
  }

  __renderCoveredNonCoveredServices() {
    return html`
      <neb-header
        id="${ELEMENTS.servicesHeader.id}"
        class="services-header"
        label="Covered/Non-Covered Services Details"
      ></neb-header>

      <div class="flex-row tabs-header">
        ${this.__renderCoveredNonCoveredServicesTabs()}
      </div>

      ${this.__renderSelectedTab()}
    `;
  }

  __renderCoverageDetails() {
    return html`
      <div
        id="${ELEMENTS.coverageDetailsPage.id}"
        class="panel-container coverage-container"
      >
        ${this.__renderCopaysCoverageDetails()}
        ${this.__renderCoinsurancesCoverageDetails()}
        ${this.__renderDeductiblesCoverageDetails()}
        ${this.__renderOutOfPocketsCoverageDetails()}
        ${this.__renderVisitLimitCoverageDetails()}
      </div>

      <div>${this.__renderProviderInOutOfNetworkSection()}</div>

      ${this.__renderCoveredNonCoveredServices()}
    `;
  }

  __renderRTEHistory() {
    return html`
      <neb-collection-page-rte-insurance-log
        id="${ELEMENTS.collectionPageRTEInsuranceLog.id}"
        .layout="${this.layout}"
        .patientId="${this.patientId}"
        .patientInsuranceId="${this.patientInsurance.id}"
        .onOpenReport="${this.__handlers.openReport}"
      ></neb-collection-page-rte-insurance-log>
    `;
  }

  __renderTab() {
    switch (this.__selectedTab) {
      case this.__tabs[0].id:
        return this.__renderSummary();

      case this.__tabs[1].id:
        return this.__renderPolicyDetails();

      case this.__tabs[2].id:
        return this.__renderCoverageDetails();

      case this.__tabs[3].id:
        return this.__renderRTEHistory();

      default:
        return html``;
    }
  }

  render() {
    return html`
      <div class="container">
        <div class="header-container">
          ${
            this.layout === 'small'
              ? this.__renderHeaderMobile()
              : this.__renderHeader()
          }
        </div>

        <neb-tab-group
          id="${ELEMENTS.tabs.id}"
          .items="${this.__tabs}"
          .layout="${this.layout}"
          .selectedId="${this.__selectedTab}"
          .onSelect="${this.__handlers.selectTab}"
        ></neb-tab-group>

        <div class="form-container">${this.__renderTab()}</div>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-patient-insurance-view-controller',
  NebPatientInsuranceViewController,
);
