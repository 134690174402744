import { readDataUrlFromBlob } from '../../neb-utils/blobProcessor';
import { FEATURE_FLAGS, hasFeatureOrBeta } from '../../neb-utils/feature-util';

import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const apiClient = new ApiClient({ microservice: 'api' });
export const billingClient = new ApiClient({ microservice: 'billing' });
export const coreApiClient = new ApiClientV2({ microservice: 'core' });

export const headerJSON = { 'Content-Type': 'application/json' };

export const getSignatureFile = async (
  providerId,
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  try {
    const response = await billingClient.makeRequest({
      optOutLoadingIndicator,
      path: `providers/${providerId}/signature`,
      responseType: RESPONSE_TYPE.RAW,
      method: Method.GET,
      queryParams,
      version: 1,
    });
    return readDataUrlFromBlob(await response.blob());
  } catch (err) {
    return null;
  }
};

export const saveSignatureFile = async (providerId, base64File) => {
  const apiDeprecationFF = await hasFeatureOrBeta(
    FEATURE_FLAGS.API_DEPRECATION,
  );

  if (apiDeprecationFF) {
    try {
      const fileResponse = await fetch(base64File);
      const file = await fileResponse.blob();
      const response = await coreApiClient.makeRequest({
        optOutLoadingIndicator: false,
        path: '/api/v1/tenants/:tenantId/providers/:providerId/signature',
        replacements: { providerId },
        responseType: RESPONSE_TYPE.RAW,
        method: Method.PUT,
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
      return readDataUrlFromBlob(await response.blob());
    } catch (err) {
      return null;
    }
  } else {
    try {
      const fileResponse = await fetch(base64File);
      const file = await fileResponse.blob();
      const response = await apiClient.makeRequest({
        optOutLoadingIndicator: false,
        path: `providers/${providerId}/signature`,
        responseType: RESPONSE_TYPE.RAW,
        method: Method.PUT,
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
      return readDataUrlFromBlob(await response.blob());
    } catch (err) {
      return null;
    }
  }
};

export const deleteSignatureFile = async providerId => {
  const apiDeprecationFF = await hasFeatureOrBeta(
    FEATURE_FLAGS.API_DEPRECATION,
  );

  if (apiDeprecationFF) {
    try {
      await coreApiClient.makeRequest({
        optOutLoadingIndicator: false,
        method: Method.DELETE,
        path: '/api/v1/tenants/:tenantId/providers/:providerId/signature',
        replacements: { providerId },
        responseType: RESPONSE_TYPE.RAW,
        headers: headerJSON,
      });

      return true;
    } catch (err) {
      return false;
    }
  } else {
    try {
      await apiClient.makeRequest({
        optOutLoadingIndicator: false,
        method: Method.DELETE,
        path: `providers/${providerId}/signature`,
        responseType: RESPONSE_TYPE.RAW,
        headers: headerJSON,
      });

      return true;
    } catch (err) {
      return false;
    }
  }
};

export const getProviderSignatureS3Key = async providerId => {
  const apiDeprecationFF = await hasFeatureOrBeta(
    FEATURE_FLAGS.API_DEPRECATION,
  );

  if (apiDeprecationFF) {
    const {
      data: [s3Key],
    } = await coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.GET,
      path: '/api/v1/tenants/:tenantId/providers/:providerId/signature-s3-key',
      replacements: { providerId },
      headers: headerJSON,
      cacheKey: `${providerId}-s3-key`,
    });

    return s3Key || null;
  }
  const {
    data: [s3Key],
  } = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `providers/${providerId}/signature-s3-key`,
    headers: headerJSON,
    cacheKey: `${providerId}-s3-key`,
  });

  return s3Key || null;
};
