import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../packages/neb-utils/feature-util';

export const apiClient = new ApiClient({
  microservice: 'api',
});
export const coreApiClient = new ApiClientV2({ microservice: 'core' });

export const getProblemList = async patientId => {
  let res;

  const apiDeprecationFF = await hasFeatureOrBeta(
    FEATURE_FLAGS.API_DEPRECATION,
  );

  if (apiDeprecationFF) {
    res = await coreApiClient.makeRequest({
      method: Method.GET,
      path: '/api/v1/tenants/:tenantId/patients/:patientId/problem-list',
      replacements: { patientId },
    });
  } else {
    res = await apiClient.makeRequest({
      method: Method.GET,
      path: `patients/${patientId}/problem-list`,
    });
  }
  return res.data;
};
