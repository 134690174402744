import { FEATURE_FLAGS, hasFeatureOrBeta } from '../../neb-utils/feature-util';

import {
  mapNextAppointmentToServerModel,
  mapServerModelToNextAppointment,
} from './mappers/next-appointment-mapper';
import ApiClient, { Method } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const apiClient = new ApiClient({ microservice: 'api' });

export const coreApiClient = new ApiClientV2({ microservice: 'core' });

const buildBaseUrl = encounterId => `encounters/${encounterId}/nextAppointment`;

export const createNextAppointment = async (nextAppointment, encounterId) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.POST,
      path:
        '/api/v1/tenants/:tenantId/encounters/:encounterId/next-appointment',
      replacements: { encounterId },
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(nextAppointment),
    });
  }

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: buildBaseUrl(encounterId),
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(nextAppointment),
  });
};

export const updateNextAppointment = async (nextAppointment, encounterId) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const serverModel = mapNextAppointmentToServerModel(nextAppointment);

    const json = await coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.PUT,
      path:
        '/api/v1/tenants/:tenantId/encounters/:encounterId/next-appointment/:id',
      replacements: { encounterId, id: nextAppointment.id },
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(serverModel),
    });

    return mapServerModelToNextAppointment(json.data[0]);
  }

  const serverModel = mapNextAppointmentToServerModel(nextAppointment);
  const json = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${buildBaseUrl(encounterId)}/${nextAppointment.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(serverModel),
  });
  return mapServerModelToNextAppointment(json.data[0]);
};

export const getNextAppointment = async encounterId => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const json = await coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.GET,
      path:
        '/api/v1/tenants/:tenantId/encounters/:encounterId/next-appointment',
      replacements: { encounterId },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return json.count === 0
      ? null
      : mapServerModelToNextAppointment(json.data[0]);
  }

  const json = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${buildBaseUrl(encounterId)}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return json.count === 0
    ? null
    : mapServerModelToNextAppointment(json.data[0]);
};
