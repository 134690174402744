import '../../neb-popup-header';

import { navigate } from '@neb/router';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import { UpdateNotificationService } from '../../../../../../src/services/update-notifications';
import { formatAllocationLineItemsV2 } from '../../../../../../src/utils/allocate-charges/neb-allocate-charge-util';
import { getProviderAdjustmentsTotal } from '../../../../../../src/utils/payment-util';
import {
  RECOUP_ERROR,
  RECOUP_SUCCESS,
  REMOVE_RECOUP_HISTORY_ERROR,
  REMOVE_RECOUP_HISTORY_SUCCESS,
} from '../../../../../../src/utils/user-message';
import {
  allocatePayment,
  getChangedLineItemsForAllocationV2,
  getAllocatedCharges,
  deleteAllocation,
  recoupAllocation,
  removeRecoupHistory,
} from '../../../../../neb-api-client/src/allocation-api-client';
import {
  getLineItemDetails,
  getPatientsWithOpenCharges,
} from '../../../../../neb-api-client/src/ledger/line-items';
import * as patientApiClient from '../../../../../neb-api-client/src/patient-api-client';
import { getPatientRelationships } from '../../../../../neb-api-client/src/patient-relationship-api-client';
import { getPayerPlans } from '../../../../../neb-api-client/src/payer-plan-api-client';
import {
  getUnallocatedPayments,
  getPaymentDetail,
} from '../../../../../neb-api-client/src/payments-api-client';
import { getProviderUsers } from '../../../../../neb-api-client/src/practice-users-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { openDirtyPopup } from '../../../../../neb-popup';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../../neb-redux/services/locations';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../../neb-utils/date-util';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../../neb-utils/feature-util';
import {
  objToName,
  HIDE_PREFERRED_OPTS,
} from '../../../../../neb-utils/formatters';
import { filterOpenCharges } from '../../../../../neb-utils/neb-ledger-util';
import { mapPatientName } from '../../../../../neb-utils/patient';
import {
  NebFormAllocationCharges,
  TABS,
} from '../../forms/neb-form-allocation-charges';
import Overlay from '../neb-overlay';

import { sortRawPatients, sortPatients } from './util';

const uniq = arr => [...new Set(arr)];

const BANNER_SUCCESS = 'Charges and payments allocated successfully';
const BANNER_ERROR = 'An error occurred when allocating the payment(s)';

const REMOVE_ALLOCATION_BANNER_MESSAGE = {
  success: 'Allocation(s) successfully removed',
  error: 'An error has occurred while removing the allocated payment(s)',
};

const DATE_3_MONTHS_BEFORE = parseDate()
  .startOf('day')
  .subtract(3, 'month');

export const ELEMENTS = {
  header: {
    id: 'header',
    title: 'Allocate Payments',
  },
  allocationChargesForm: { id: 'allocation-charges-form' },
};

function formatPatientsForDropdown(rawPatients, patientIdsInDropdown) {
  const filtered = rawPatients.filter(p => patientIdsInDropdown.includes(p.id));

  const sorted = sortRawPatients(filtered);

  return sorted.map(raw => ({
    label: objToName(mapPatientName(raw), HIDE_PREFERRED_OPTS),
    data: { id: raw.id },
  }));
}

function getPatientNameForPayment(raw) {
  return objToName(mapPatientName(raw), {
    reverse: true,
    middleInitial: true,
  });
}

class NebOverlayAllocatePayment extends Overlay {
  static get properties() {
    return {
      __loading: Boolean,
      __filteredFormModel: Object,
      __menuItemsMap: Object,
      __payments: Array,
      __patients: Array,
      __patientsHash: Object,
      __enablePatientDropdown: Boolean,
      __locations: Array,
      __defaultLocationId: String,
      __itemFilters: Object,
      __payers: Array,
      __selectedTab: String,
      __lineItems: Array,
      __providers: Array,
      __selectPayment: Object,
      __hasRCMSecondaryFieldFeatureFlag: Boolean,
      __hasRCMChangeSecondaryFeatureFlag: Boolean,
      __hasRCMProviderAdjustmentsFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .form {
          height: auto;
          flex: 1 0 0;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initServices();
  }

  initState() {
    super.initState();

    this.__selectedTab = '';
    this.__enablePatientDropdown = false;
    this.__loading = true;
    this.__selectPayment = {};
    this.__payments = [];
    this.__filteredFormModel = NebFormAllocationCharges.createModel();
    this.__payers = [];
    this.__providers = [];
    this.__lineItems = [];
    this.__patients = [];
    this.__patientsHash = {};

    this.__itemFilters = {
      dateOfTransactionFrom: null,
      dateOfTransactionTo: null,
      patient: '',
      locations: [],
    };

    this.__locations = [];
    this.__defaultLocationId = '';
    this.__hasRCMSecondaryFieldFeatureFlag = false;
    this.__hasRCMChangeSecondaryFeatureFlag = false;
    this.__hasRCMProviderAdjustmentsFF = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      selectPayment: payment => {
        this.__selectPayment = payment;

        const query = this.__getTransactionDates(payment);
        this.__filterCharges(payment, query);
      },
      save: async model => {
        const changedLineItems = getChangedLineItemsForAllocationV2(
          this.__filteredFormModel,
          model,
        );

        if (changedLineItems.length === 0) {
          return;
        }

        try {
          await allocatePayment(this.__selectPayment.id, changedLineItems, 4);
          await this.__refreshPaymentDetail();
          await this.__load(this.__itemFilters, true);

          store.dispatch(openSuccess(BANNER_SUCCESS));
        } catch (_) {
          store.dispatch(openError(BANNER_ERROR));
        }
      },
      cancel: async () => {
        if (this.isDirty && (await openDirtyPopup())) {
          this.__filteredFormModel = [...this.__filteredFormModel];
        }
      },
      filterItems: ({ itemFilters, selectFirstPatient, onlyPatients }) => {
        this.__itemFilters = { ...itemFilters };
        this.__filterCharges(
          this.__selectPayment,
          itemFilters,
          selectFirstPatient,
          onlyPatients,
        );
      },
      payerRefresh: async () => {
        await this.__refreshPaymentDetail();
      },
      loadPaymentDetails: async payment => {
        this.__loading = true;
        await this.__refreshPaymentDetail(payment);
        const query = { ...this.__itemFilters };
        await this.__filterCharges(this.__selectPayment, query);
        this.__loading = false;
      },
      removeAllocation: items => this.__removeAllocation(items),

      loadTabData: selectedTab => this.__setTabAndLoadCharges(selectedTab),
      recoup: async ({ allocationId, paymentId }) => {
        this.__loading = true;

        try {
          await recoupAllocation(
            this.__selectPayment.id,
            paymentId,
            allocationId,
          );

          await this.__filterCharges(this.__selectPayment, this.__itemFilters);
          await this.__refreshPaymentDetail();
          this.__loading = false;
          store.dispatch(openSuccess(RECOUP_SUCCESS));
        } catch (error) {
          this.__loading = false;
          store.dispatch(openError(RECOUP_ERROR));
        }
      },
      removeRecoupHistory: async ({ allocationId, paymentId, recoupId }) => {
        this.__loading = true;

        try {
          await removeRecoupHistory(allocationId, paymentId, recoupId);

          await this.__filterCharges(this.__selectPayment, this.__itemFilters);
          await this.__refreshPaymentDetail();
          this.__loading = false;
          store.dispatch(openSuccess(REMOVE_RECOUP_HISTORY_SUCCESS));
        } catch (error) {
          this.__loading = false;
          store.dispatch(openError(REMOVE_RECOUP_HISTORY_ERROR));
        }
      },
    };
  }

  initServices() {
    this.__locationsService = new LocationsService(
      ({ userLocations, defaultLocationId }) => {
        this.__locations = userLocations;

        if (this.__locations.length) {
          this.__defaultLocationId = defaultLocationId;
        }
      },
    );

    this.__notificationService = new UpdateNotificationService({
      callback: () => {
        this.rerenderPatientInfo();
      },
    });
  }

  async __removeAllocation(items) {
    const lineItems = items.map(li => ({
      id: li.id,
      debits: li.debits
        .map(debit => ({ id: debit.id }))
        .filter(debit => debit.id),
    }));

    try {
      await deleteAllocation(this.__selectPayment.id, lineItems);
      await this.__load({}, true);
      await this.__refreshPaymentDetail();

      store.dispatch(openSuccess(REMOVE_ALLOCATION_BANNER_MESSAGE.success));
    } catch (_) {
      store.dispatch(openError(REMOVE_ALLOCATION_BANNER_MESSAGE.error));
    }
  }

  async __setTabAndLoadCharges(selectedTab) {
    if (this.__selectedTab === selectedTab) {
      return;
    }

    this.__selectedTab = selectedTab;

    const query = { ...this.__itemFilters };

    await this.__load({}, true);

    if (
      !!query.dateOfTransactionFrom ||
      !!query.dateOfServiceFrom ||
      query.locations.length ||
      query.additionalCharges
    ) {
      await this.__filterCharges(this.__selectPayment, query);
    }
  }

  async __refreshPaymentDetail(payment) {
    const paymentId = payment ? payment.id : this.__selectPayment.id;

    const refreshedPayment = await getPaymentDetail(paymentId);
    const refreshedPaymentBalance = this.__hasRCMProviderAdjustmentsFF
      ? getProviderAdjustmentsTotal(refreshedPayment.providerAdjustments) +
        refreshedPayment.available
      : refreshedPayment.available;

    if (this.__selectPayment.id === paymentId) {
      this.__selectPayment =
        refreshedPaymentBalance > 0 ? refreshedPayment : {};
    }

    this.__payments = this.__payments.map(p =>
      p.id === refreshedPayment.id ? refreshedPayment : p,
    );
  }

  __filterOpenCharges(lineItems, payment) {
    const isPayerPayment = !!payment.payerPlanId;

    return filterOpenCharges(lineItems, isPayerPayment);
  }

  __getLocations(query) {
    return query && query.locations && query.locations.length
      ? { locations: query.locations }
      : { locations: [] };
  }

  __getTransactionDates(payment, query) {
    if (query) {
      return {
        ...(query.dateOfTransactionFrom
          ? {
              dateOfTransactionFrom: parseDate(
                query.dateOfTransactionFrom,
              ).startOf('day'),
            }
          : { dateOfTransactionFrom: null }),
        ...(query.dateOfTransactionTo
          ? {
              dateOfTransactionTo: parseDate(query.dateOfTransactionTo).endOf(
                'day',
              ),
            }
          : { dateOfTransactionTo: null }),
      };
    }

    let paymentDates = {
      ...(payment.dateOfServiceFrom
        ? {
            dateOfTransactionFrom: parseDate(payment.dateOfServiceFrom).startOf(
              'day',
            ),
          }
        : {}),
      ...(payment.dateOfServiceTo
        ? {
            dateOfTransactionTo: parseDate(payment.dateOfServiceTo).endOf(
              'day',
            ),
          }
        : {}),
    };

    paymentDates = this.__updateDefaultFilterDatesForPayment(
      paymentDates,
      payment,
    );

    return paymentDates;
  }

  __buildLocationsQuery(query) {
    const userLocationIds = this.__locations.map(({ id }) => id);

    return {
      ...(query && query.locations && query.locations.length
        ? { locationIds: query.locations.map(({ data }) => data.id) }
        : {
            ...(userLocationIds &&
              userLocationIds.length && { locationIds: userLocationIds }),
          }),
    };
  }

  __buildTransactionDateQuery(payment, query) {
    const txDates = this.__getTransactionDates(payment, query);

    return {
      ...(txDates.dateOfTransactionFrom && {
        dateOfServiceFrom: txDates.dateOfTransactionFrom.toISOString(),
      }),
      ...(txDates.dateOfTransactionTo && {
        dateOfServiceTo: txDates.dateOfTransactionTo.toISOString(),
      }),
    };
  }

  async __filterOutstandingCharges(
    payment,
    query,
    selectFirstPatient = true,
    onlyPatients = false,
  ) {
    const isPayerPayment = !!payment.payerPlanId;
    const isDiscountPayment = !!payment.codeDiscountId;

    const patientsWithOpenCharges = await getPatientsWithOpenCharges(
      {
        ...(isPayerPayment
          ? { payerPlanId: payment.payerPlanId }
          : { noPayerPlan: true }),
        ...(payment.patientId ? { patientId: payment.patientId } : {}),
        ...(payment.patientId && !isPayerPayment && !isDiscountPayment
          ? { useRelationships: true }
          : {}),
        ...this.__buildTransactionDateQuery(payment, query),
        ...this.__buildLocationsQuery(query),
        ...(this.model.showAdditionalCharges
          ? { additionalCharges: query.additionalCharges || false }
          : {}),
      },
      {
        ...(this.model.selectedLineItemIds &&
          this.model.selectedLineItemIds.length && {
            lineItemIds: this.model.selectedLineItemIds,
          }),
      },
    );

    const patientIdsInDropdown =
      patientsWithOpenCharges.length > 0
        ? patientsWithOpenCharges
        : [...(payment.patientId ? [payment.patientId] : [])];

    const patientsInDropdown = await this.__rebuildPatientList(
      patientIdsInDropdown,
      this.__payments,
    );

    let patientId;

    if (patientsInDropdown && patientsInDropdown.length && selectFirstPatient) {
      patientId = patientsInDropdown[0].data.id;
    }

    const queryPatientId = query?.patient?.data?.id;

    if (queryPatientId && patientIdsInDropdown.includes(queryPatientId)) {
      patientId = queryPatientId;
    }

    this.__itemFilters = {
      ...this.__itemFilters,
      patient: patientsInDropdown.find(p => p.data.id === patientId),
      ...this.__getTransactionDates(payment, query),
      ...this.__getLocations(query),
      ...(this.model.showAdditionalCharges
        ? { additionalCharges: query.additionalCharges || false }
        : {}),
    };

    this.__patients = patientsInDropdown;
    this.__enablePatientDropdown =
      patientIdsInDropdown.length > 1 ||
      (patientIdsInDropdown.length === 1 &&
        payment.patientId !== null &&
        payment.patientId !== patientIdsInDropdown[0]);

    if (!patientId) {
      this.__selectPayment = payment;

      this.__filteredFormModel = [];
      return;
    }

    if (onlyPatients) return;

    const lineItems = await getLineItemDetails(
      {
        patientId,
        useRelationships: false,
        ...(isPayerPayment
          ? { payerPlanId: payment.payerPlanId }
          : { noPayerPlan: true }),
        ...this.__buildTransactionDateQuery(payment, query),
        ...this.__buildLocationsQuery(query),
        ...(this.model.showAdditionalCharges
          ? { additionalCharges: query.additionalCharges || false }
          : {}),
      },
      {
        ...(this.model.selectedLineItemIds &&
          this.model.selectedLineItemIds.length && {
            lineItemIds: this.model.selectedLineItemIds,
          }),
      },
    );

    lineItems.sort(
      (a, b) =>
        b.dateOfService.localeCompare(a.dateOfService) ||
        b.chargeNumber - a.chargeNumber,
    );

    const shouldNotFilterOpenCharges =
      this.model.showAdditionalCharges &&
      (query.additionalCharges ||
        (this.model.selectedLineItemIds?.length && !this.model.fromEraEobPage));

    const charges = shouldNotFilterOpenCharges
      ? lineItems
      : this.__filterOpenCharges(lineItems, payment);

    this.__filteredFormModel = await formatAllocationLineItemsV2(
      charges,
      this.__providers,
      this.__payers,
      payment,
      this.__patientsHash,
      this.__hasRCMSecondaryFieldFeatureFlag,
    );

    this.__selectPayment = payment;
  }

  async __getAllocatedCharges({ query, payment, providers, payerPlans }) {
    const locationIds =
      query.locations && query.locations.length
        ? query.locations.map(({ data }) => data.id)
        : this.__locations.map(location => location.id);

    const filteredLocationIds = locationIds.filter(id => id);

    const queryParams = query
      ? {
          ...(query.dateOfTransactionFrom && {
            dateOfServiceFrom: moment
              .tz(query.dateOfTransactionFrom, 'UTC')
              .startOf('day')
              .toISOString(),
          }),
          ...(query.dateOfTransactionTo && {
            dateOfServiceTo: moment
              .tz(query.dateOfTransactionTo, 'UTC')
              .endOf('day')
              .toISOString(),
          }),
          ...(query.patient &&
            query.patient.data &&
            query.patient.data.id && {
              patientId: query.patient.data.id,
            }),
          ...(filteredLocationIds.length
            ? {
                locationIds: filteredLocationIds,
              }
            : {}),
        }
      : null;

    const lineItems = await getAllocatedCharges(payment.id, queryParams, true);

    const patientIds = lineItems.map(li => li.patientId);

    this.__patientsHash = await this.__getPatientsHash(patientIds);

    const formattedLineItems = await formatAllocationLineItemsV2(
      lineItems,
      providers,
      payerPlans,
      payment,
      this.__patientsHash,
      this.__hasRCMSecondaryFieldFeatureFlag,
    );

    return formattedLineItems;
  }

  async __filterAllocatedCharges(payment, query) {
    query = {
      ...query,
      locations: this.__formatLocations(this.__locations),
    };

    if (query.patient) {
      const queryPatientId = query.patient.data.id;
      const foundPatient = this.__patients.some(
        patient => patient.data.id === queryPatientId,
      );

      if (!foundPatient) {
        query = {
          ...query,
          patient: '',
        };
      }

      this.__itemFilters = {
        ...this.__itemFilters,
        patient: query.patient,
      };
    }

    const lineItems = await this.__getAllocatedCharges({
      query,
      payment,
      providers: this.__providers,
      payerPlans: this.__payers,
    });

    const patientIds = [...new Set(lineItems.map(li => li.patientId))];

    const patients = await patientApiClient.fetchSome(patientIds);

    const sortedPatients = sortPatients(patients);

    const relatedPatientIds = payment.patientId
      ? (await getPatientRelationships(payment.patientId)).map(
          relationship => relationship.relatedPatientId,
        )
      : [];

    this.__hasRelationships = relatedPatientIds.length > 0;

    if (!query.patient) {
      this.__patients = [
        this.__hasRelationships
          ? {
              label: '[All Related Patients]',
              data: {
                id: '',
                patientIds: [payment.patientId, ...relatedPatientIds],
              },
            }
          : { label: '[All Patients]', data: { id: '' } },
        ...sortedPatients.map(p => ({
          label: objToName(p.name, {
            reverse: true,
            middleInitial: true,
          }),
          data: { id: p.id },
        })),
      ];

      this.__itemFilters = {
        ...this.__itemFilters,
        patient: this.__hasRelationships
          ? this.__patients[0]
          : this.__patients.find(p => p.label === payment.patientName) ||
            this.__patients[0],
      };
    }

    this.__filteredFormModel = lineItems;
    this.__selectPayment = payment;
  }

  async __filterCharges(payment, query, selectFirstPatient, onlyPatients) {
    if (this.__selectedTab === TABS.ALLOCATED) {
      await this.__filterAllocatedCharges(payment, query);
    } else if (this.__selectedTab === TABS.OUTSTANDING) {
      await this.__filterOutstandingCharges(
        payment,
        query,
        selectFirstPatient,
        onlyPatients,
      );
    }
  }

  __getPatientForFilter(patientName) {
    if (
      this.__itemFilters.patient &&
      this.__itemFilters.patient.data.id &&
      this.__patients.some(
        p => p.data.id === this.__itemFilters.patient.data.id,
      )
    ) {
      return this.__patients.find(
        p => p.data.id === this.__itemFilters.patient.data.id,
      );
    }

    return this.__hasRelationships
      ? this.__patients[0]
      : this.__patients.find(p => p.label === patientName) ||
          this.__patients[0];
  }

  async connectedCallback() {
    this.__locationsService.connect();
    this.__notificationService.connect();

    this.__providers = await getProviderUsers();
    this.__payers = await this.__getPayerPlans();

    const features = await getFeatures();

    this.__hasRCMSecondaryFieldFeatureFlag = features.includes(
      FEATURE_FLAGS.RCM_SECONDARY_FIELD,
    );

    this.__hasRCMChangeSecondaryFeatureFlag = features.includes(
      FEATURE_FLAGS.RCM_CHANGE_SECONDARY,
    );

    this.__hasRCMProviderAdjustmentsFF = features.includes(
      FEATURE_FLAGS.RCM_PROVIDER_ADJUSTMENTS,
    );

    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__notificationService.disconnect();
    this.__locationsService.disconnect();
  }

  async __getPatientsHash(patientIds) {
    const uniqueIds = uniq(patientIds);

    const patientsList = await patientApiClient.fetchSome(
      uniqueIds,
      {},
      true,
      true,
    );

    return patientsList.reduce((memo, patient) => {
      memo[patient.id] = patient;
      return memo;
    }, {});
  }

  async __removePatientPaymentsWithoutCharges(payments, lineItems) {
    const lineItemIds = lineItems.map(lineItem => lineItem.id).filter(a => a);

    const filteredPayments = await Promise.all(
      payments.map(async payment => {
        const isPatientPayment = !payment.payerPlanId;
        const isDiscountPayment = !!payment.codeDiscountId;

        if (isPatientPayment) {
          const lineItemsForPayment = await getLineItemDetails(
            {
              noPayerPlan: true,
              patientId: payment.patientId,
              ...(!isDiscountPayment ? { useRelationships: true } : {}),
            },
            {
              ...(lineItemIds.length && { lineItemIds }),
            },
          );

          if (!lineItemsForPayment.length) return null;
        }
        return payment;
      }),
    );

    return filteredPayments.filter(payment => payment);
  }

  async __rebuildPatientList(patientIdsInDropDown, payments) {
    const patientIdsInPayments = payments
      ? payments.map(p => p.patientId).filter(a => a)
      : [];

    const patientIds = [
      ...patientIdsInPayments,
      ...patientIdsInDropDown,
      ...(this.model.patientId ? [this.model.patientId] : []),
    ];

    this.__patientsHash = await this.__getPatientsHash(patientIds);
    return formatPatientsForDropdown(
      Object.values(this.__patientsHash),
      patientIdsInDropDown,
    );
  }

  __getPatientList(payments) {
    const lineItems = this.__lineItems;

    const patientIds = uniq([
      ...lineItems.map(li => li.patientId),
      this.model.patientId,
    ]);

    return this.__rebuildPatientList(patientIds, payments);
  }

  async __getPayments() {
    const lineItems = this.__lineItems;

    const payerPlanIds = lineItems
      .map(li => li.lineItemDebits.map(lid => lid.debit.payerId))
      .flat()
      .filter(id => id);

    const patientIds = uniq([
      ...lineItems.map(li => li.patientId),
      this.model.patientId,
    ]);

    const includePatient = lineItems.some(li =>
      li.lineItemDebits.some(lid => !lid.debit.payerId),
    );

    const unallocatedPayments = await getUnallocatedPayments({
      includePatient,
      payerPlanIds,
      patientIds,
      useRelationships: true,
    });

    if (includePatient) {
      return this.__removePatientPaymentsWithoutCharges(
        unallocatedPayments,
        lineItems,
      );
    }

    return unallocatedPayments;
  }

  async __getPayerPlans() {
    const { payerPlan: payerPlans } = await getPayerPlans();
    return payerPlans;
  }

  async __load(filters, ignorePayments = false) {
    this.__loading = true;

    const { payments, selectedLineItemIds } = this.model;

    if (payments) {
      if (!ignorePayments) {
        this.__payments = payments;
      }
    } else {
      this.__lineItems = await getLineItemDetails(
        {},
        { lineItemIds: selectedLineItemIds },
      );

      this.__payments = await this.__getPayments();
      this.__patients = await this.__getPatientList(payments);
    }

    if (this.__payments.length) {
      const payment = this.__selectPayment.paymentNumber
        ? this.__selectPayment
        : this.__payments[0];

      const hasDates = Boolean(
        this.__itemFilters.dateOfTransactionFrom ||
          this.__itemFilters.dateOfTransactionTo,
      );

      const transactionDates = this.__getTransactionDates(
        payment,
        hasDates ? this.__itemFilters : null,
      );
      const queryUpdated = {
        ...filters,
        ...transactionDates,
      };

      await this.__filterCharges(payment, queryUpdated);
    } else {
      this.__patients = await this.__getPatientList([]);
      this.__filteredFormModel = await formatAllocationLineItemsV2(
        this.__lineItems,
        this.__providers,
        this.__payers,
        null,
        this.__patientsHash,
        this.__hasRCMSecondaryFieldFeatureFlag,
      );

      this.__itemFilters = {
        ...this.__itemFilters,
        patient: this.__patients[0],
      };
    }

    this.__loading = false;
  }

  async rerenderPatientInfo() {
    const patientIds = this.__payments
      ? this.__payments.map(li => li.patientId).filter(a => a)
      : [];

    const patientsHash = await this.__getPatientsHash(patientIds);

    this.__payments = this.__payments.map(p => {
      if (!p.patientId) return p;

      const patientName = getPatientNameForPayment(patientsHash[p.patientId]);

      return {
        ...p,
        patientName,
        payerName: p.payerPlan ? p.payerPlan.payerName : patientName,
      };
    });
  }

  getFirstPaymentKey(key) {
    return this.__payments && this.__payments.length
      ? this.__payments[0][key]
      : '';
  }

  update(changedProps) {
    if (
      changedProps.has('__locations') &&
      this.__locations &&
      this.__locations.length
    ) {
      this.__getDefaultItemFilters();

      if (
        !(
          changedProps.has('model') &&
          this.model.selectedTab &&
          this.__selectedTab !== this.model.selectedTab
        )
      ) {
        this.__load(this.__itemFilters);
      }
    }

    if (
      changedProps.has('layout') &&
      this.layout &&
      this.layout !== 'large' &&
      this.model.patientId
    ) {
      navigate(`#/patients/${this.model.patientId}/ledger/activity/payments`);
    }

    if (changedProps.has('model')) {
      this.__notificationService.update({
        patient: { id: this.model.patientId },
      });

      if (
        this.model.selectedTab &&
        this.__selectedTab !== this.model.selectedTab
      ) {
        this.__setTabAndLoadCharges(this.model.selectedTab);
      }
    }

    super.update(changedProps);
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      data: location,
      label: location.name,
    }));
  }

  __updateDefaultFilterDatesForPayment(itemFilters, payment) {
    if (
      this.__selectedTab === TABS.OUTSTANDING &&
      !!payment.payerPlanId &&
      !itemFilters.dateOfTransactionFrom &&
      !itemFilters.dateOfTransactionTo
    ) {
      return { ...itemFilters, dateOfTransactionFrom: DATE_3_MONTHS_BEFORE };
    }

    return itemFilters;
  }

  __getDefaultItemFilters() {
    const location = this.__locations.find(
      l => l.id === this.__defaultLocationId,
    );

    if (location) {
      this.__itemFilters = {
        ...this.__itemFilters,
        locations: this.__formatLocations([location]),
      };
    }

    if (this.model.payments && this.model.payments.length) {
      this.__payments = this.model.payments;
      this.__itemFilters = {
        ...this.__itemFilters,
        ...this.__getTransactionDates(this.__payments[0]),
      };
    }
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${ELEMENTS.header.title}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-allocation-charges
        id="${ELEMENTS.allocationChargesForm.id}"
        class="form"
        .layout="${this.layout}"
        .model="${this.__filteredFormModel}"
        .payments="${this.__payments}"
        .patientId="${this.model.patientId}"
        .patients="${this.__patients}"
        .patientsHash="${this.__patientsHash}"
        .selectedPayer="${this.getFirstPaymentKey('payerName')}"
        .itemsMap="${this.__menuItemsMap}"
        .itemFilters="${this.__itemFilters}"
        .onChangeDirty="${this.handlers.dirty}"
        .onItemFiltersChange="${this.handlers.filterItems}"
        .onSelectPayment="${this.handlers.selectPayment}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        .onPayerUpdate="${this.handlers.payerRefresh}"
        .onLoadPaymentDetails="${this.handlers.loadPaymentDetails}"
        .enablePatientDropdown="${this.__enablePatientDropdown}"
        .locations="${this.__locations}"
        .defaultLocationId="${this.__defaultLocationId}"
        ?loading="${this.__loading}"
        .payers="${this.__payers}"
        .selectedTab="${this.__selectedTab}"
        .onSelectTab="${this.handlers.loadTabData}"
        .onRemoveAllocation="${this.handlers.removeAllocation}"
        .hasRCMSecondaryField="${this.__hasRCMSecondaryFieldFeatureFlag}"
        .hasRCMChangeSecondary="${this.__hasRCMChangeSecondaryFeatureFlag}"
        .showAdditionalCharges="${this.model.showAdditionalCharges}"
        .onRecoup="${this.handlers.recoup}"
        .onRemoveRecoupHistory="${this.handlers.removeRecoupHistory}"
      >
      </neb-form-allocation-charges>
    `;
  }
}

customElements.define(
  'neb-overlay-allocate-payment',
  NebOverlayAllocatePayment,
);
