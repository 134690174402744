import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../packages/neb-utils/feature-util';

export const appointmentsApiClient = new ApiClient({
  microservice: 'appointments',
});

export const apiClient = new ApiClient({
  microservice: 'api',
});

export const coreApiClient = new ApiClientV2({
  microservice: 'core',
});

export const getAlertsToDisplayByPatient = async patientId => {
  const res = await appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/alerts`,
    version: 1,
  });

  return res.data ? res.data : null;
};

export const getAllAlertsByPatient = async patientId => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const res = await coreApiClient.makeRequest({
      method: Method.GET,
      path: '/api/v1/tenants/:tenantId/patients/:patientId/alerts',
      replacements: { patientId },
    });

    return res.count ? res.data[0].alerts : null;
  }

  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/alerts`,
  });

  return res.count ? res.data[0].alerts : null;
};

export const createAlert = async (patientId, alert) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const res = await coreApiClient.makeRequest({
      method: Method.POST,
      path: '/api/v1/tenants/:tenantId/patients/:patientId/alerts',
      replacements: { patientId },
      body: JSON.stringify(alert),
      headers: {
        'Content-Type': 'application/json',
      },
      opts: {
        updateNotificationDetails: { alert: { patientId } },
      },
    });

    return res.count ? res.data[0].alerts : null;
  }

  const res = await apiClient.makeRequest({
    method: Method.POST,
    path: `patients/${patientId}/alerts`,
    body: JSON.stringify(alert),
    headers: {
      'Content-Type': 'application/json',
    },
    opts: {
      updateNotificationDetails: { alert: { patientId } },
    },
  });

  return res.count ? res.data[0].alerts : null;
};

export const updateAlerts = async (patientId, alerts, isReorder = false) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const res = await coreApiClient.makeRequest({
      method: Method.PUT,
      path: '/api/v1/tenants/:tenantId/patients/:patientId/alerts',
      replacements: { patientId },
      body: JSON.stringify({ alerts, isReorder }),
      headers: {
        'Content-Type': 'application/json',
      },
      opts: {
        updateNotificationDetails: { alert: { patientId } },
      },
    });

    return res.count ? res.data[0].alerts : null;
  }

  const res = await apiClient.makeRequest({
    method: Method.PUT,
    path: `patients/${patientId}/alerts`,
    body: JSON.stringify({ alerts, isReorder }),
    headers: {
      'Content-Type': 'application/json',
    },
    opts: {
      updateNotificationDetails: { alert: { patientId } },
    },
  });

  return res.count ? res.data[0].alerts : null;
};

export const deleteAlert = async (patientId, alert) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const res = await coreApiClient.makeRequest({
      method: Method.DELETE,
      path: '/api/v1/tenants/:tenantId/patients/:patientId/alerts/:id',
      replacements: { id: alert.id, patientId },
      body: JSON.stringify(alert),
      headers: {
        'Content-Type': 'application/json',
      },
      opts: {
        updateNotificationDetails: { alert: { patientId } },
      },
    });

    return res.count ? res.data[0].alerts : null;
  }

  const res = await apiClient.makeRequest({
    method: Method.DELETE,
    path: `patients/${patientId}/alerts/${alert.id}`,
    body: JSON.stringify(alert),
    headers: {
      'Content-Type': 'application/json',
    },
    opts: {
      updateNotificationDetails: { alert: { patientId } },
    },
  });

  return res.count ? res.data[0].alerts : null;
};
