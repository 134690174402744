import { FEATURE_FLAGS, hasFeatureOrBeta } from '../../neb-utils/feature-util';

import ApiClient, { Method } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const apiClient = new ApiClient({ microservice: 'api' });
export const appointmentsApiClient = new ApiClientV2({
  microservice: 'appointments',
});
export const headerJSON = { 'Content-Type': 'application/json' };

export async function update(items) {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return appointmentsApiClient.makeRequest({
      optOutLoadingIndicator: false,
      path: '/api/v1/tenants/:tenantId/cancel-reschedule-reasons',
      method: Method.PUT,
      headers: headerJSON,
      body: { cancelRescheduleReasons: items },
    });
  }

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'cancelRescheduleReasons',
    method: Method.PUT,
    headers: headerJSON,
    body: JSON.stringify(items),
  });
}

export async function fetchMany() {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const res = await appointmentsApiClient.makeRequest({
      method: Method.GET,
      path: '/api/v1/tenants/:tenantId/cancel-reschedule-reasons',
      cacheKey: 'cancel-reschedule-reasons',
    });

    return res.data;
  }

  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: 'cancelRescheduleReasons',
    cacheKey: 'cancel-reschedule-reasons',
  });
  return res.data;
}
