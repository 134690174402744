import { FEATURE_FLAGS, hasFeatureOrBeta } from '../../neb-utils/feature-util';

import ApiClient, { Method } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const coreApiClient = new ApiClientV2({ microservice: 'core' });
export const apiClient = new ApiClient({ microservice: 'api' });

export const METADATA = [
  'occ',
  ...new Array(7).fill(null).map((_, index) => `c${index + 1}`),
  ...new Array(12).fill(null).map((_, index) => `t${index + 1}`),
  ...new Array(5).fill(null).map((_, index) => `l${index + 1}`),
  's',
  'c',
  'p',
];

export function mapToModel(raw) {
  const items = METADATA.map(() => ({
    left: {
      issue: '',
      color: '#FFFFFF',
    },
    right: {
      issue: '',
      color: '#FFFFFF',
    },
  }));

  raw.listings
    .map(item => ({
      color: item.color || '#FFFFFF',
      issue: item.issue || '',
      location: item.location || '',
    }))
    .forEach(item => {
      const [side, target] = item.location.split('-');
      const sideKey = side === 'l' ? 'left' : 'right';
      const index = METADATA.findIndex(meta => meta === target);

      items[index][sideKey] = {
        issue: item.issue,
        color: item.color,
      };
    });

  return {
    occiput: items[0],
    cDisks: items.slice(1, 8),
    tDisks: items.slice(8, 20),
    lDisks: items.slice(20, 25),
    sacrum: items[25],
    coccyx: items[26],
    pelvis: items[27],
  };
}

function mapToRaw(model) {
  const listings = [
    model.occiput,
    ...model.cDisks,
    ...model.tDisks,
    ...model.lDisks,
    model.sacrum,
    model.coccyx,
    model.pelvis,
  ]
    .reduce((accum, curr, index) => {
      const key = METADATA[index];

      return [
        ...accum,
        { ...curr.left, location: `l-${key}` },
        { ...curr.right, location: `r-${key}` },
      ];
    }, [])
    .filter(item => item.issue || item.color !== '#FFFFFF');

  return { listings };
}

export async function update(patientId, model) {
  let res;

  const apiDeprecationFF = await hasFeatureOrBeta(
    FEATURE_FLAGS.API_DEPRECATION,
  );

  if (apiDeprecationFF) {
    res = await coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.POST,
      version: 1,
      path: '/api/v1/tenants/:tenantId/patients/:patientId/listings',
      replacements: { patientId },
      body: JSON.stringify(mapToRaw(model)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } else {
    res = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.POST,
      version: 1,
      path: `patients/${patientId}/listings`,
      body: JSON.stringify(mapToRaw(model)),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  return mapToModel(res.data[0]);
}

export async function fetch(patientId, optOutLoadingIndicator = false) {
  let res;

  const apiDeprecationFF = await hasFeatureOrBeta(
    FEATURE_FLAGS.API_DEPRECATION,
  );

  if (apiDeprecationFF) {
    res = await coreApiClient.makeRequest({
      method: Method.GET,
      version: 1,
      path: '/api/v1/tenants/:tenantId/patients/:patientId/listings',
      replacements: { patientId },
      cacheKey: `${patientId}-patients-listings`,
      optOutLoadingIndicator,
    });
  } else {
    res = await apiClient.makeRequest({
      method: Method.GET,
      version: 1,
      path: `patients/${patientId}/listings`,
      cacheKey: `${patientId}-patients-listings`,
      optOutLoadingIndicator,
    });
  }
  return mapToModel(res.data[0]);
}
