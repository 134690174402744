import { html } from 'lit';

import { parseDate } from './date-util';
import { centsToCurrency, decimalToCents } from './formatters';

export const AUTHORIZATION_TYPE = Object.freeze({
  Visits: 'Visits',
  Units: 'Units',
  DollarAmount: 'Dollar Amount',
  DateRange: 'Date Range',
});

export const AUTHORIZATION_STATUS = Object.freeze({
  Empty: '',
  Approved: 'Approved',
  Denied: 'Denied',
  Requested: 'Requested',
  Completed: 'Completed',
  Appealed: 'Appealed',
  Inactive: 'Inactive',
});

export const AUTHORIZATION_TYPES = Object.values(AUTHORIZATION_TYPE);
export const AUTHORIZATION_STATUSES = Object.values(AUTHORIZATION_STATUS);

const VALID_AUTHORIZATION_STATUSES = [
  AUTHORIZATION_STATUS.Approved,
  AUTHORIZATION_STATUS.Requested,
  AUTHORIZATION_STATUS.Appealed,
];

export const NO_REMAINING_AUTHORIZATIONS_MESSAGE =
  'The Authorization has no units, visits, or dollar amount remaining.';

export const isAuthorizationValidForLinking = status =>
  VALID_AUTHORIZATION_STATUSES.includes(status);

export const createModelPatientAuth = () => ({
  allProviders: true,
  authorizationNumber: '',
  authorizedDates: '',
  authorizationType: AUTHORIZATION_TYPE.Visits,
  dateRequested: null,
  dateApproved: null,
  dateApprovedFrom: null,
  dateApprovedTo: null,
  renderedOutside: '',
  status: AUTHORIZATION_STATUS.Empty,
  totalAuthorized: '',
  serviceTypes: [],
  authorizedProviders: [],
  diagnoses: [],
  charges: [],
  note: '',
  totalRendered: '',
});

export const rawCurrencyToModel = amount =>
  amount === null || amount === undefined
    ? ''
    : centsToCurrency(amount.toString(), true);

export const rawDateToModel = raw => (raw ? parseDate(raw) : null);

export const rawRenderAmountToModel = ({ authorizationType }, value) => {
  if (value === null || value === undefined) return '';

  return authorizationType === AUTHORIZATION_TYPE.DollarAmount
    ? rawCurrencyToModel(value)
    : `${value}`;
};

const rawAuthorizedProvidersToModel = (allProviders, rawProviders) =>
  allProviders || !rawProviders
    ? []
    : rawProviders.map(rawProvider => ({
        ...rawProvider,
      }));

const rawServiceTypesToModel = rawServiceTypes =>
  !rawServiceTypes
    ? []
    : rawServiceTypes.map(rawServiceType => ({
        ...rawServiceType,
      }));

const rawChargesToModel = (raw, rawCharges) =>
  !rawCharges
    ? []
    : rawCharges.map(rawCharge => ({
        id: rawCharge.id,
        procedure: rawCharge.charge.procedure,
        description: rawCharge.charge.description,
        modifiers: rawCharge.charge.modifiers,
        chargeId: rawCharge.chargeId,
        rendered: rawRenderAmountToModel(raw, rawCharge.rendered),
        allowed: rawRenderAmountToModel(raw, rawCharge.allowed),
        encounters: rawCharge.encounters || [],
      }));

const rawDiagnosesToModel = dxs => (!dxs ? [] : dxs.map(raw => ({ ...raw })));

export const formatAuthorizedDates = ({ dateApprovedFrom, dateApprovedTo }) => {
  const start = dateApprovedFrom
    ? parseDate(dateApprovedFrom).format('MM/DD/YYYY')
    : '';
  const end = dateApprovedTo
    ? parseDate(dateApprovedTo).format('MM/DD/YYYY')
    : '';

  return start || end ? `${start} - ${end}` : '';
};

const getAuthorizationRemainingVisits = ({
  totalAuthorized,
  renderedOutside,
  totalRendered,
}) => totalAuthorized - renderedOutside - totalRendered;

export const formatAuthorizationLabel = patientAuthorization => {
  if (!patientAuthorization) return '';

  const dateRange = formatAuthorizedDates(patientAuthorization);

  const showRemainingLabel =
    patientAuthorization.authorizationType === AUTHORIZATION_TYPE.Visits &&
    patientAuthorization.totalRendered !== undefined;

  return `${patientAuthorization.authorizationNumber}${
    dateRange.length ? ` (${dateRange})` : ''
  }${
    showRemainingLabel
      ? ` • ${getAuthorizationRemainingVisits(patientAuthorization)} Remaining`
      : ''
  }`;
};

// eslint-disable-next-line complexity
const formatTotalAuthorized = ({ totalAuthorized, authorizationType }) => {
  if (authorizationType === AUTHORIZATION_TYPE.DateRange) {
    return '-';
  }

  if (
    authorizationType === AUTHORIZATION_TYPE.Visits &&
    totalAuthorized !== ''
  ) {
    return totalAuthorized > 1 || totalAuthorized === '0'
      ? `${totalAuthorized} visits`
      : `${totalAuthorized} visit`;
  }

  if (
    authorizationType === AUTHORIZATION_TYPE.Units &&
    totalAuthorized !== ''
  ) {
    return totalAuthorized > 1 || totalAuthorized === '0'
      ? `${totalAuthorized} units`
      : `${totalAuthorized} unit`;
  }

  return totalAuthorized;
};

export const rawPatientAuthorizationToModel = raw => {
  const result = {
    ...raw,
    allProviders: raw.allProviders,
    authorizationNumber: raw.authorizationNumber || '',
    authorizationType: raw.authorizationType || '',
    dateRequested: raw.dateRequested,
    dateApproved: raw.dateApproved,
    dateApprovedFrom: raw.dateApprovedFrom,
    dateApprovedTo: raw.dateApprovedTo,
    renderedOutside: rawRenderAmountToModel(raw, raw.renderedOutside),
    status: raw.status || '',
    totalAuthorized: rawRenderAmountToModel(raw, raw.totalAuthorized),
    serviceTypes: rawServiceTypesToModel(raw.serviceTypes),
    authorizedProviders: rawAuthorizedProvidersToModel(
      raw.allProviders,
      raw.authorizedProviders,
    ),
    diagnoses: rawDiagnosesToModel(raw.diagnoses),
    charges: rawChargesToModel(raw, raw.charges),
    note: raw.note || '',
    totalRendered: rawRenderAmountToModel(raw, raw.totalRendered),
  };

  return {
    ...result,
    authorizedDates: formatAuthorizedDates(result),
    totalAuthorizedSummary: formatTotalAuthorized(result),
  };
};

export const rawPatientAuthorizationsToModel = raws =>
  !raws ? [] : raws.map(raw => rawPatientAuthorizationToModel(raw));

export const currencyToRaw = amount =>
  amount && amount.length > 0
    ? decimalToCents(Number(amount.replace(/[^0-9.]/g, '')))
    : null;

export const renderAmountToRaw = ({ authorizationType }, value) =>
  authorizationType === AUTHORIZATION_TYPE.DollarAmount
    ? currencyToRaw(value)
    : parseInt(value, 10);

const authorizedProvidersToRaw = (allProviders, providers) =>
  allProviders || !providers
    ? []
    : providers.map(provider => ({
        providerId: provider.providerId,
      }));

const serviceTypesToRaw = serviceTypes =>
  !serviceTypes
    ? []
    : serviceTypes.map(serviceType => ({
        serviceTypeId: serviceType.serviceTypeId,
      }));

const chargesToRaw = (model, charges) =>
  !charges
    ? []
    : charges.map(charge => ({
        chargeId: charge.chargeId,
        allowed: charge.allowed
          ? renderAmountToRaw(model, charge.allowed)
          : null,
      }));

const diagnosesToRaw = diagnoses =>
  !diagnoses
    ? []
    : diagnoses.map(dx => ({
        diagnosisCode: dx.diagnosisCode,
        shortDescription: dx.shortDescription,
      }));

export const patientAuthorizationToRaw = model => ({
  ...model,
  allProviders: model.allProviders || false,
  authorizationNumber: model.authorizationNumber,
  authorizationType: model.authorizationType ? model.authorizationType : null,
  renderedOutside: model.renderedOutside
    ? renderAmountToRaw(model, model.renderedOutside)
    : null,
  status: model.status ? model.status : null,
  totalAuthorized: model.totalAuthorized
    ? renderAmountToRaw(model, model.totalAuthorized)
    : null,
  serviceTypes: serviceTypesToRaw(model.serviceTypes),
  authorizedProviders: authorizedProvidersToRaw(
    model.allProviders,
    model.authorizedProviders,
  ),
  charges: chargesToRaw(model, model.charges),
  diagnoses: diagnosesToRaw(model.diagnoses),
});

export const getPostBodyFromRaw = obj => {
  if (obj) {
    delete obj.id;
    delete obj.patientId;
    delete obj.patientCaseId;
    delete obj.authorizedDates;
    delete obj.totalRendered;
  }

  return obj;
};

export const hasAuthorizationRemaining = authorization => {
  if (authorization?.authorizationType === AUTHORIZATION_TYPE.DateRange) {
    return true;
  }

  if (authorization && authorization.status === AUTHORIZATION_STATUS.Approved) {
    let remainingAuthorizations;

    if (authorization.authorizationType === AUTHORIZATION_TYPE.DollarAmount) {
      remainingAuthorizations =
        currencyToRaw(authorization.totalAuthorized) -
        currencyToRaw(authorization.renderedOutside) -
        currencyToRaw(authorization.totalRendered);
    } else {
      remainingAuthorizations =
        parseInt(authorization.totalAuthorized, 10) -
        parseInt(authorization.renderedOutside, 10) -
        parseInt(authorization.totalRendered, 10);
    }

    if (remainingAuthorizations > 0) return true;

    return false;
  }

  return true;
};

export const filterAuthorizationsFromCases = cases =>
  cases
    .flatMap(({ patientAuthorizations }) => patientAuthorizations)
    .filter(({ status }) => isAuthorizationValidForLinking(status))
    .map(auth => ({ ...auth, label: formatAuthorizationLabel(auth) }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const filterValidAuthorizations = patientAuthorizations =>
  patientAuthorizations.filter(({ status }) =>
    isAuthorizationValidForLinking(status),
  );

const PROC_AUTH_MESSAGE_LINES = [
  'One or more of the procedures added are not listed on the authorization associated with this encounter.',
  'Are you sure you wish to post these procedures?',
];

export const PROCEDURE_NOT_AUTHORIZED_MESSAGE = `${
  PROC_AUTH_MESSAGE_LINES[0]
}\n${PROC_AUTH_MESSAGE_LINES[1]}`;

export const PROCEDURE_NOT_AUTHORIZED_POPUP_MODEL = {
  title: 'Procedure Code Not Included in Authorization',
  message: html`
    <p>${PROC_AUTH_MESSAGE_LINES[0]}</p>
    <p>${PROC_AUTH_MESSAGE_LINES[1]}</p>
  `,
  confirmText: 'YES',
  cancelText: 'NO',
};
