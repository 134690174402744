import { html } from 'lit';

import Table, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { SCHEDULE_PAYMENT_STATUS } from '../../../../packages/neb-utils/enums';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  blankIcons: { selector: '[id^=icon-blank-]' },
};

class NebTableScheduledPaymentRecurring extends Table {
  renderRow(item, index) {
    const clickable = item.status === SCHEDULE_PAYMENT_STATUS.PAID;

    return html`
      <div
        id="row-${index}"
        class="row row-data"
        row="${index}"
        @click="${this.handlers.clickRow}"
      >
        <div class="content" ?clickable="${clickable}">
          ${this.__renderDataCells(item, index)}
          ${
            clickable
              ? this.renderModeCell(index)
              : this.__renderBlankIcon(index)
          }
        </div>
      </div>
    `;
  }

  __renderBlankIcon(index) {
    return html`
      <span id="icon-blank-${index}" class="cell cell-detail"></span>
    `;
  }
}

customElements.define(
  'neb-table-scheduled-payment-recurring',
  NebTableScheduledPaymentRecurring,
);
