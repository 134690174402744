import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';
import '../controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { CLAIM_STATUS } from '../../../packages/neb-utils/claims';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../packages/neb-utils/feature-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { number } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { FetchService } from '../../../packages/neb-utils/services/fetch';
import { ADD_ONS, hasAddOn } from '../../utils/add-ons';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters, ELEMENTS as BASE_ELEMENTS } from './neb-filters';

export const SUBMISSION_METHODS = [
  { label: 'Paper Claims', data: { id: 'Paper Claims' } },
  { label: 'Electronic Claims', data: { id: 'Electronic Claims' } },
];

export const STATUSES = [
  { label: 'Ready to Submit', data: { id: 'null' } },
  { label: CLAIM_STATUS.DRAFT, data: { id: 'Draft' } },
  {
    label: CLAIM_STATUS.SUBMITTING,
    data: { id: CLAIM_STATUS.SUBMITTING },
  },
];

export const CLAIM_SCRUB_ITEMS = [
  selectors.ITEM_EMPTY,
  {
    label: 'No',
    data: { id: 'No' },
  },
  {
    label: 'Yes',
    data: { id: 'Yes' },
  },
];

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  dosRange: { id: 'range-date-of-service' },
  invoiceTextfield: { id: 'textfield-invoice' },
  showHiddenCheckbox: { id: 'show-hidden-checkbox' },
  claimTextfield: { id: 'textfield-claim' },
  submissionMethodsSelect: { id: 'select-submission-method' },
  statusesSelect: { id: 'select-status' },
  payersSelect: { id: 'select-payers' },
  providersSelect: { id: 'select-providers' },
  locationsSelect: { id: 'select-locations' },
  claimScrubSelect: { id: 'select-claim-scrub' },
  amountRange: { id: 'range-amount' },
  patientSearch: { id: 'search-patient' },
};

class NebClaimsWorklistReadyToSubmitFilters extends NebFilters {
  static get properties() {
    return {
      __patientItems: Array,
      __payerItems: Array,
      __payerPlansState: Object,
      enablePatient: Boolean,
      providers: Array,
      userLocations: Array,
      defaultLocationId: String,
      payerPlans: Array,

      __hasRTSScrubFF: Boolean,
      __hasCTRevAccelAddOn: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(4, 1fr);
        }

        .checkbox {
          padding-top: 27px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      dos: NebDateRange.createModel(),
      invoice: '',
      claim: '',
      submissionMethods: [],
      statuses: [],
      payerIds: [],
      providerIds: [],
      locationIds: [],
      claimScrubbed: '',
      amount: NebCurrencyRange.createModel(),
      patientId: '',
      showHidden: false,
    };
  }

  initState() {
    super.initState();

    this.initPatientsService();

    this.showHidden = false;
    this.enablePatient = false;
    this.providers = [];
    this.userLocations = [];
    this.defaultLocationId = '';
    this.payerPlans = [];

    this.__patientItems = [];
    this.__hasCTRevAccelAddOn = false;
    this.__hasRTSScrubFF = false;

    this.__payerPlansState = FetchService.createModel();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      searchPatients: e => this.__patientsService.search(e.value),
      requestMorePatients: () => this.__patientsService.fetchMore(),
      clearInvoiceSearch: () => this.formService.apply('invoice', ''),
      clearClaimSearch: () => this.formService.apply('claim', ''),
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      changePatient: e => {
        if (e.value) {
          return this.__formService.apply(e.name, e.value);
        }

        return this.__formService.apply(e.name, selectors.ITEM_EMPTY);
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      apply: e => {
        const isButtonPress = !!e;
        const model = this.formService.build();
        const { from, to } = model.dos;

        this.onApply(
          {
            ...model,
            dos: {
              from: from ? parseDate(from._i) : null,
              to: to ? parseDate(to._i) : null,
            },
          },
          isButtonPress,
        );
      },
    };
  }

  initPatientsService() {
    this.__patientsService = createServerPatientsCollection({
      onChange: state => {
        this.__patientItems = state.pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
      alwaysShowInactive: true,
    });
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select([]),
        submissionMethods: selectors.multiSelect([]),
        statuses: selectors.multiSelect([]),
        payerIds: selectors.multiSelect([]),
        providerIds: selectors.multiSelect([]),
        locationIds: selectors.multiSelect([]),
        claimScrubbed: selectors.select([]),
        dos: NebDateRange.createSelectors(),
        amount: NebCurrencyRange.createSelectors(),
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasRTSScrubFF = await hasFeatureOrBeta(FEATURE_FLAGS.RTS_SCRUB);

    this.__hasCTRevAccelAddOn = await hasAddOn(ADD_ONS.CT_REV_ACCEL);
  }

  getProviderItems() {
    return this.providers.map(data => ({
      label: objToName(data.name, DEFAULT_NAME_OPTS),
      data,
    }));
  }

  update(changedProps) {
    if (
      (changedProps.has('__payerPlansState') ||
        changedProps.has('userLocations') ||
        changedProps.has('defaultLocationId')) &&
      (this.__payerPlansState && this.userLocations && this.defaultLocationId)
    ) {
      this.__getDefaultLocation();
    }

    super.update(changedProps);
  }

  __getDefaultLocation() {
    const location = this.userLocations.find(
      l => l.data.id === this.defaultLocationId,
    );

    if (location) {
      this.handlers.change({
        name: 'locationIds',
        value: [location],
      });
    }
    this.handlers.apply();
  }

  renderPatientFilter() {
    return this.enablePatient
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSearch.id}"
            name="patientId"
            label="Patient"
            helper=" "
            itemHeight="80"
            .items="${this.__patientItems}"
            .value="${this.state.patientId}"
            .onChange="${this.handlers.changePatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRequest="${this.handlers.requestMorePatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            showSearch
            forceDown
          ></neb-select-search>
        `
      : '';
  }

  renderLocationsFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.locationsSelect.id}"
        name="locationIds"
        label="Locations"
        helper=" "
        .value="${this.state.locationIds}"
        .items="${this.userLocations}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  renderClaimScrubFilter() {
    return this.__hasRTSScrubFF && this.__hasCTRevAccelAddOn
      ? html`
          <neb-select
            id="${ELEMENTS.claimScrubSelect.id}"
            name="claimScrubbed"
            label="Claim Scrubbed"
            helper=" "
            .value="${this.state.claimScrubbed}"
            .items="${CLAIM_SCRUB_ITEMS}"
            .onChange="${this.handlers.change}"
          ></neb-select>
        `
      : html``;
  }

  renderCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.showHiddenCheckbox.id}"
        name="showHidden"
        class="checkbox"
        label="Show Hidden Items"
        ?checked="${this.state.showHidden}"
        value="${this.state.showHidden}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  __renderInvoiceFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.invoiceTextfield.id}"
        name="invoice"
        label="Invoice #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.invoice ? 'neb:clear' : ''}"
        .value="${this.state.invoice}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearInvoiceSearch}"
      ></neb-textfield>
    `;
  }

  __renderClaimTextFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.claimTextfield.id}"
        name="claim"
        label="Claim #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.claim ? 'neb:clear' : ''}"
        .value="${this.state.claim}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearClaimSearch}"
      ></neb-textfield>
    `;
  }

  __renderSubmissionMethodsFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.submissionMethodsSelect.id}"
        name="submissionMethods"
        label="Submission Method"
        helper=" "
        .value="${this.state.submissionMethods}"
        .items="${SUBMISSION_METHODS}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderPayerFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.payersSelect.id}"
        name="payerIds"
        label="Payers"
        helper=" "
        .value="${this.state.payerIds}"
        .items="${this.payerPlans}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderProviderFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.providersSelect.id}"
        name="providerIds"
        label="Providers"
        helper=" "
        .value="${this.state.providerIds}"
        .items="${this.getProviderItems()}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderDosFilter() {
    const date = {
      from: this.state.dos.from ? parseDate(this.state.dos.from) : null,
      to: this.state.dos.to ? parseDate(this.state.dos.to) : null,
    };

    return html`
      <neb-range-date
        id="${ELEMENTS.dosRange.id}"
        name="dos"
        label="Date"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
        .minGap="${true}"
      ></neb-range-date>
    `;
  }

  __renderStatusesFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.statusesSelect.id}"
        name="statuses"
        label="Status"
        helper=" "
        .value="${this.state.statuses}"
        .items="${STATUSES}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderAmountFilter() {
    return html`
      <neb-range-currency
        id="${ELEMENTS.amountRange.id}"
        name="amount"
        label="Amount"
        .model="${this.state.amount}"
        .onChange="${this.handlers.change}"
        .minGap="${true}"
      ></neb-range-currency>
    `;
  }

  __renderFullFilters() {
    return html`
      ${this.renderPatientFilter()} ${this.__renderInvoiceFilter()}
      ${this.__renderClaimTextFilter()}
      ${this.__renderSubmissionMethodsFilter()} ${this.__renderPayerFilter()}
      ${this.__renderProviderFilter()} ${this.__renderDosFilter()}
      ${this.__renderStatusesFilter()} ${this.__renderAmountFilter()}
      ${this.renderLocationsFilter()} ${this.renderClaimScrubFilter()}
      ${this.renderCheckbox()}
    `;
  }

  renderCollapsedContent() {
    return html`
      ${this.__renderDosFilter()} ${this.__renderProviderFilter()}
      ${this.__renderPayerFilter()} ${this.__renderSubmissionMethodsFilter()}
    `;
  }

  renderContent() {
    return this.hasCollapsedFilters
      ? html`
          ${this.__renderStatusesFilter()} ${this.renderPatientFilter()}
          ${this.__renderInvoiceFilter()} ${this.__renderClaimTextFilter()}
          ${this.__renderAmountFilter()}${this.renderLocationsFilter()}
          ${this.renderClaimScrubFilter()} ${this.renderCheckbox()}
        `
      : this.__renderFullFilters();
  }
}

window.customElements.define(
  'neb-filters-claims-worklist-ready-to-submit',
  NebClaimsWorklistReadyToSubmitFilters,
);
