import { LitElement, html, css } from 'lit';

import { CSS_FONT_SIZE_BODY, CSS_COLOR_HIGHLIGHT } from '../../../styles';
import '../../misc/neb-icon';

export const ELEMENTS = {
  thumbnailContainer: {
    selector: 'thumbnail-container',
  },
  noDocumentsIcon: {
    id: 'no-documents-icon',
  },
  noDocumentsMessage: {
    id: 'no-documents-message',
  },
};

export const NO_DOCUMENTS_MESSAGE = 'No associated documents found';
class NebDocumentsTileView extends LitElement {
  static get properties() {
    return {
      documents: Array,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.documents = [];

    this.onSelectDocument = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectDocument: (doc, idx) => {
        this.onSelectDocument(doc, idx);
      },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
          grid-gap: 16px;
          min-height: 200px;
        }

        .thumbnail-container {
          width: 200px;
          height: 200px;
          overflow: hidden;
          position: relative;
          cursor: pointer;
        }

        .thumbnail {
          box-sizing: border-box;
          border: 3px solid transparent;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }

        .thumbnail-container:hover .thumbnail {
          transform: scale(1.05);
        }

        .thumbnail-name {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(128, 128, 128, 0.7);
          color: white;
          padding: 5px;
          font-size: 14px;
          opacity: 0;
          transition: opacity 0.3s ease;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .thumbnail-container:hover .thumbnail-name {
          opacity: 1;
        }

        .no-documents-message {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          grid-column: 1 / -1;
          height: 100%;
          padding-top: 50px;
        }

        .text {
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-document {
          width: 50px;
          height: 50px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin: 0 0 5px 0;
        }

        .icon-thumbnail {
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  __renderNoDocumentsMessage() {
    return html`
      <div id="${ELEMENTS.noDocumentsMessage.id}" class="no-documents-message">
        <neb-icon
          id="${ELEMENTS.noDocumentsIcon.id}"
          class="icon icon-document"
          icon="neb:noDocuments"
        ></neb-icon>
        <div id="no-documents-message" class="text">
          ${NO_DOCUMENTS_MESSAGE}
        </div>
      </div>
    `;
  }

  render() {
    return html`
      ${
        !this.documents.length
          ? this.__renderNoDocumentsMessage()
          : this.documents.map(
              (doc, idx) => html`
                <div
                  id="thumbnail-container-${idx}"
                  class="thumbnail-container"
                  @click="${() => this.__handlers.selectDocument(doc, idx)}"
                >
                  ${
                    doc.hasThumbnail
                      ? html`
                          <img
                            class="thumbnail"
                            src="${doc.img || ''}"
                            alt="Document thumbnail"
                          />
                        `
                      : html`
                          <neb-icon
                            class="icon-thumbnail thumbnail"
                            icon="neb:placeholder"
                          ></neb-icon>
                        `
                  }
                  <div class="thumbnail-name">${doc.name}</div>
                </div>
              `,
            )
      }
    `;
  }
}

customElements.define('neb-documents-tile-view', NebDocumentsTileView);
