import { openPopup } from '@neb/popup';
import { css, html } from 'lit';
import moment from 'moment-timezone';

import { updateClaimsDataV2 } from '../../../../packages/neb-api-client/src/claims-data';
import { fetchMany as getCases } from '../../../../packages/neb-api-client/src/patient-cases';
import {
  openSuccess,
  openError,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { buildClaimsDataToUpdate } from '../../../utils/claim-errors/claim-errors';
import { NebFormClaimErrorItdDoo } from '../../forms/claim-errors/neb-form-claim-error-itd-doo';

import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
};

const BANNER_SUCCESS = 'Claim Data Updated Successfully';
const BANNER_CASE_UPDATED_SUCCESS = 'Date of Onset in case updated';
const BANNER_ERROR = 'An error occurred while updating the claim';

class NebOverlayClaimErrorItdDoo extends NebOverlayClaimError {
  static get properties() {
    return {
      formModel: Object,

      __cases: Array,
    };
  }

  initState() {
    super.initState();

    this.widthRatio = '60/40';
    this.formModel = NebFormClaimErrorItdDoo.createModel();

    this.__cases = [];
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
      addNewCase: newCase => {
        this.__cases = [...this.__cases, newCase];
      },
      save: async model => {
        let updateCaseDOO = false;

        const shouldDisplayUpdateCaseDOOPopup = this.__shouldDisplayUpdateCaseDOOPopup(
          model,
        );

        if (shouldDisplayUpdateCaseDOOPopup) {
          updateCaseDOO = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Set Case Date of Onset',
            message:
              'Do you wish to update the Date of Onset for the associated case with the Encounter Date of Onset?',
            confirmText: 'YES',
            cancelText: 'NO',
          });
        }

        const { claim } = this.model;
        const updatedClaimsData = await buildClaimsDataToUpdate({
          claim,
          formModel: model,
          updateCaseDOO,
        });

        try {
          await updateClaimsDataV2(updatedClaimsData);
          store.dispatch(openSuccess(BANNER_SUCCESS));

          if (updateCaseDOO) {
            store.dispatch(openSuccess(BANNER_CASE_UPDATED_SUCCESS));
          }

          this.isDirty = false;
          this.dismiss(true);
        } catch (e) {
          console.error(e);
          store.dispatch(openError(BANNER_ERROR));
        }
      },
    };
  }

  async connectedCallback() {
    this.__cases = await this.__getCases();
    this.__setFormModel();
    super.connectedCallback();
  }

  static get styles() {
    return [
      super.styles,
      css`
        .encounter-summary {
          overflow: auto;
        }

        .service-date-select {
          display: flex;
          margin: 0 0 10px 0;
        }

        .left-panel-container {
          padding: 5px 10px;
        }
      `,
    ];
  }

  __shouldDisplayUpdateCaseDOOPopup(newModel) {
    if (!newModel.patientCase.data) {
      return false;
    }

    const oldDateOfOnset = parseDate(this.formModel.dateOfOnset);
    const newDateOfOnset = parseDate(newModel.dateOfOnset);

    const oldCaseDateOfOnset = parseDate(
      this.formModel.patientCase.data?.onsetSymptomsDate,
    );
    const newCaseDateOfOnset = parseDate(
      newModel.patientCase.data?.onsetSymptomsDate,
    );

    if (
      this.__areBothFalsyOrEqual(oldCaseDateOfOnset, newCaseDateOfOnset) &&
      this.__areBothFalsyOrEqual(oldDateOfOnset, newDateOfOnset)
    ) {
      return false;
    }

    if (this.__areBothFalsyOrEqual(newDateOfOnset, newCaseDateOfOnset)) {
      return false;
    }

    return true;
  }

  __areBothFalsyOrEqual(value1, value2) {
    return (!value1 && !value2) || (value1 && value2 && value1.isSame(value2));
  }

  __setFormModel() {
    const { caseId, dateOfOnset } = this.model;
    const caseItem = this.__cases.find(c => c.data.id === caseId);

    this.formModel.dateOfOnset = dateOfOnset;
    this.formModel.patientId = this.model.claim.patientId;

    if (caseItem) {
      this.formModel.patientCase = caseItem;
      this.formModel.initialTxDate = caseItem.data.initialTxDate;
    }
  }

  async __getCases() {
    const { patientId } = this.model;
    const cases = await getCases(patientId);

    return cases.map(c => ({
      label: `${c.name} - ${
        c.onsetSymptomsDate
          ? moment.utc(c.onsetSymptomsDate).format('MM/DD/YYYY')
          : 'Gradual'
      }`,
      data: c,
    }));
  }

  renderForm() {
    return html`
      <neb-form-claim-error-itd-doo
        id="${ELEMENTS.form.id}"
        .model="${this.formModel}"
        .cases="${this.__cases}"
        .onSave="${this.__handlers.save}"
        .onChangeDirty="${this.__handlers.dirty}"
        .onAddNewCase="${this.__handlers.addNewCase}"
      ></neb-form-claim-error-itd-doo>
    `;
  }
}

customElements.define(
  'neb-overlay-claim-error-itd-doo',
  NebOverlayClaimErrorItdDoo,
);
