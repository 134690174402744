import { html, css } from 'lit';
import moment from 'moment-timezone';

import { openPayment } from '../../../../../src/utils/payment-util';
import { getMerchantAccounts } from '../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import { getScheduledPayments } from '../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import { getPaymentByElectronicPaymentId } from '../../../../neb-api-client/src/payments-api-client';
import { getPracticeUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import CollectionPage, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../neb-lit-components/src/components/neb-page-collection';
import { SORT_DIR } from '../../../../neb-lit-components/src/components/neb-table-header';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../neb-lit-components/src/utils/overlay-constants';
import { CSS_COLOR_HIGHLIGHT } from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP,
  SCHEDULE_PAYMENT_STATUS,
  SCHEDULE_RUN_STATUS,
} from '../../../../neb-utils/enums';
import { centsToCurrency, objToName } from '../../../../neb-utils/formatters';

export const MERCHANT_ACCOUNTS_TEXT =
  'Set up your merchant accounts in Merchant Account Services to schedule payments using credit cards.';

export const NO_ITEMS_TEXT =
  'There are no scheduled payments. Click "Schedule New Payment" to schedule a payment.';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  textNoMerchantAccount: {
    id: 'text-no-merchant-account',
  },
};

const NAME_OPTS = {
  reverse: true,
};

const TABLE_CONFIG = [
  {
    key: 'name',
    label: 'Name',
    flex: css`3 0 0`,
  },
  {
    key: 'createdAt',
    label: 'Date Created',
    flex: css`2 0 0`,
    formatter: v => moment(v).format('MM/DD/YYYY'),
  },
  {
    key: 'createdBy',
    label: 'Created By',
    flex: css`3 0 0`,
    formatter: v => (v ? objToName(v.name, NAME_OPTS) : ''),
  },
  {
    key: 'paymentDate',
    label: 'Payment Date',
    flex: css`2 0 0`,
    formatter: v => moment(v).format('MM/DD/YYYY'),
  },
  {
    key: 'scheduleType',
    label: 'Type',
    flex: css`2 0 0`,
    formatter: v => SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP[v],
  },
  {
    key: 'frequency',
    label: 'Billing Cycle',
    flex: css`2 0 0`,
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`2 0 0`,
    formatter: v => (v === null ? 'Scheduled' : v),
  },
  {
    key: 'amount',
    label: 'Amount',
    flex: css`2 0 0`,
    formatter: v => centsToCurrency(v),
  },
];

class NebRecurringScheduledPage extends CollectionPage {
  static get properties() {
    return {
      patientId: String,
      __scheduledPayments: Array,
      __merchantAccounts: Array,
      __isLoaded: Boolean,
    };
  }

  static get config() {
    return {
      unifyForm: true,
      showInactiveFilter: null,
      title: '',
      addLabel: '',
      initialSortKey: 'start',
      initialSortOrder: SORT_DIR.DESC,
      overlayKey: OVERLAY_KEYS.ADD_SCHEDULED_PAYMENT,
      description: '',
      searchLabel: '',
      tableConfig: TABLE_CONFIG,
    };
  }

  initState() {
    super.initState();

    this.onRecurringScheduledChange = () => {};
    this.__merchantAccounts = [];
    this.__isLoaded = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      addItem: async () => {
        const resultItem = await openOverlay(this.getConfig().overlayKey, {
          context: this.buildContext(),
        });

        if (resultItem) {
          const items = await this.fetch();

          this.service.setItems(items);

          this.onRecurringScheduledChange(items.length);
        }
      },

      selectItem: async (_, item) => {
        const selectedSchedule = this.__scheduledPayments.find(
          scheduledPayment => scheduledPayment.id === item.id,
        );

        const parentItem = selectedSchedule[selectedSchedule.scheduleType];

        if (selectedSchedule.status === SCHEDULE_PAYMENT_STATUS.FAILED) {
          await openOverlay(OVERLAY_KEYS.FAILED_SCHEDULED_PAYMENT, {
            type: item.scheduleType,
            patientId: this.patientId,
            item: parentItem,
            schedule: selectedSchedule,
          });
        } else if (
          selectedSchedule.status === SCHEDULE_PAYMENT_STATUS.PAID &&
          !selectedSchedule.manuallyPaid
        ) {
          if (this.layout === 'large' && selectedSchedule.run.length) {
            const paidRun = selectedSchedule.run.find(
              r => r.status === SCHEDULE_RUN_STATUS.PAID,
            );

            if (paidRun.transactionId) {
              const payment = await getPaymentByElectronicPaymentId(
                paidRun.transactionId,
              );

              await openPayment({ payment, readonly: false });
            }
          }
        } else {
          await openOverlay(OVERLAY_KEYS.SCHEDULED_PAYMENT, {
            type: item.scheduleType,
            patientId: this.patientId,
            item: parentItem,
          });
        }

        const items = await this.fetch();

        this.service.setItems(items);

        this.onRecurringScheduledChange(items.length);
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .row-form {
          padding-top: 0;
          height: 100%;
        }

        .flex {
          display: flex;
          justify-content: space-between;
        }

        .flex-end {
          align-items: flex-end;
        }

        .text-highlight {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .no-merchant-info-container {
          display: flex;
          position: relative;
          height: 100%;
          width: 100%;
        }

        .icon-container {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .icon-credit-card {
          height: 80px;
          width: 80px;
          fill: #0b9fcb;
          padding: 0;
        }
      `,
    ];
  }

  getDetailsByScheduleType(obj, users) {
    const { scheduleType } = obj;
    const {
      name,
      patientPackageId,
      paymentTypeId,
      createdBy: userId,
      createdAt,
    } = obj[scheduleType];

    const user = users.find(u => u.id === userId);

    return {
      name,
      paymentTypeId,
      patientPackageId: patientPackageId || null,
      createdBy: user ? { name: user.name } : null,
      createdAt,
    };
  }

  mapToModel(obj, users) {
    const {
      id,
      patientId,
      amount,
      scheduleType,
      recurringPayment,
      paymentPlan,
      status: paymentStatus,
      manuallyPaid,
    } = obj;

    const paymentDate = parseDate(obj.start).format('MM/DD/YYYY');

    const details = this.getDetailsByScheduleType(obj, users);

    const status = manuallyPaid
      ? SCHEDULE_PAYMENT_STATUS.PAID_MANUALLY
      : paymentStatus;

    return {
      id,
      ...details,
      frequency:
        recurringPayment?.frequency ||
        paymentPlan?.frequency ||
        SCHEDULE_PAYMENT_TYPE_DISPLAY_MAP.singlePayment,
      patientId,
      amount,
      scheduleType,
      status,
      paymentDate,
    };
  }

  async fetchMerchantAccounts() {
    this.__merchantAccounts = await getMerchantAccounts({ hideInactive: true });
    this.__isLoaded = true;
  }

  async updated(changedProps) {
    if (changedProps.has('patientId')) {
      const items = await this.fetch();

      this.service.setItems(items);
    }
  }

  async firstUpdated(changedProps) {
    await this.fetchMerchantAccounts();

    super.firstUpdated(changedProps);
  }

  async fetch() {
    const users = await getPracticeUsers();

    this.__scheduledPayments = await getScheduledPayments(this.patientId, {
      onlyNextPaymentPlan: true,
    });

    return this.__scheduledPayments.map(s => this.mapToModel(s, users));
  }

  buildContext() {
    return { patientId: this.patientId };
  }

  __merchantAccountsExists() {
    return this.__merchantAccounts.length;
  }

  __renderNoMerchantInfo() {
    return html`
      <div class="no-merchant-info-container">
        <div class="icon-container">
          <neb-icon class="icon-credit-card" icon="neb:creditCard"></neb-icon>

          <div class="text-highlight" id="${ELEMENTS.textNoMerchantAccount.id}">
            ${MERCHANT_ACCOUNTS_TEXT}
          </div>
        </div>
      </div>
    `;
  }

  renderNoItemsContent() {
    return NO_ITEMS_TEXT;
  }

  __renderTable() {
    return html`
      <div class="row">${this.renderTable()}</div>

      ${this.renderFooter()} ${this.renderPagination()}
    `;
  }

  __renderAddButton() {
    return html`
      <div class="row row-margins flex">
        <neb-button-action
          id="${ELEMENTS.addButton.id}"
          label="Schedule New Payment"
          .onClick="${this.handlers.addItem}"
        >
        </neb-button-action>
      </div>
    `;
  }

  renderContent() {
    if (this.__isLoaded) {
      if (!this.__merchantAccountsExists()) {
        return html`
          ${this.__renderNoMerchantInfo()}
        `;
      }

      return html`
        ${this.__renderAddButton()} ${this.__renderTable()}
      `;
    }

    return '';
  }
}

customElements.define(
  'neb-recurring-scheduled-page',
  NebRecurringScheduledPage,
);
