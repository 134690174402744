import { FEATURE_FLAGS, hasFeatureOrBeta } from '../../neb-utils/feature-util';

import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';
import downloadCSV from './utils/download-csv';

export const apiClient = new ApiClient({ microservice: 'api' });
export const coreApiClient = new ApiClientV2({
  microservice: 'core',
});
export const headerJSON = { 'Content-Type': 'application/json' };

const BASE_REQUEST = {
  method: Method.GET,
  responseType: RESPONSE_TYPE.RAW,
  optOutLoadingIndicator: false,
};
export const DOWNLOAD_PATIENTS_REQUEST = {
  ...BASE_REQUEST,
  path: 'patients/roster',
};

export const createPatients = importJobId =>
  coreApiClient.makeRequest({
    method: Method.PUT,
    headers: headerJSON,
    responseType: RESPONSE_TYPE.RAW,
    path: '/api/v1/tenants/:tenantId/patient-roster/:importJobId/patients',
    replacements: { importJobId },
    optOutLoadingIndicator: false,
  });

export const downloadPatients = async () => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const response = await coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.GET,
      responseType: RESPONSE_TYPE.RAW,
      path: '/api/v1/tenants/:tenantId/patients/roster',
    });

    return downloadCSV(response);
  }

  const response = await apiClient.makeRequest(DOWNLOAD_PATIENTS_REQUEST);
  return downloadCSV(response);
};

export const STATUS_IN_QUERY = Object.freeze({
  'Needs Attention': 'need_attention',
  Duplicate: 'duplicate',
  Existing: 'existing',
});

export const downloadPatientRoster = async (importJobId, importStatus) => {
  const response = await coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    responseType: RESPONSE_TYPE.RAW,
    path: '/api/v1/tenants/:tenantId/patient-roster/:importJobId/csv',
    replacements: { importJobId },
    queryParams: {
      importStatus: STATUS_IN_QUERY[importStatus],
    },
  });

  return downloadCSV(response);
};

export const getPatientRoster = async (
  importPatientJobId,
  { limit = 25, offset = 0, importStatus },
) => {
  const response = await coreApiClient.makeRequest({
    method: Method.GET,
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/patient-roster/:importPatientJobId',
    replacements: { importPatientJobId },
    queryParams: {
      limit,
      offset,
      importStatus,
    },
  });

  return response.data;
};

export const getImportPatientJob = async importPatientJobId => {
  const response = await coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/import-patient-job/:importPatientJobId',
    replacements: { importPatientJobId },
  });

  return response.data[0];
};

export const getPatientRosterCounts = async importPatientJobId => {
  const path =
    '/api/v1/tenants/:tenantId/patient-roster/:importPatientJobId/counts';

  try {
    const response = await coreApiClient.makeRequest({
      path,
      method: Method.GET,
      optOutLoadingIndicator: false,
      replacements: { importPatientJobId },
    });

    return response.data[0];
  } catch (err) {
    console.log('error: ', err.message);
    return {
      attentionCount: 0,
      readyCount: 0,
      duplicateCount: 0,
      existingCount: 0,
    };
  }
};
