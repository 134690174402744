import { LitElement, css, html } from 'lit';

import { buildModel } from '../../../../packages/neb-api-client/src/services/encounter-charge';
import checkUnallocationAuthorization from '../../../../packages/neb-api-client/src/services/encounter-charge/check-unallocation-authorization';
import saveCharges from '../../../../packages/neb-api-client/src/services/encounter-charge/save-charges';
import {
  CHARGES,
  DIAGNOSES,
  ENCOUNTER,
  EncounterDataService,
} from '../../../../packages/neb-api-client/src/services/encounter-data';
import nebFormManageEncounterService from '../../../../packages/neb-lit-components/src/components/forms/encounter/neb-form-manage-encounter/neb-form-manage-encounter-service';
import { EMPTY_SELECT_VALUE } from '../../../../packages/neb-lit-components/src/components/forms/utils';
import { EncounterService } from '../../../../packages/neb-patient/src/services/encounter';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import '../../../../packages/neb-lit-components/src/components/forms/encounter/neb-form-manage-encounter';
import { baseStyles } from '../../../styles';

export const ELEMENTS = {
  form: {
    id: 'form',
  },
};

class NebManageEncounterController extends LitElement {
  static get properties() {
    return {
      __patientEncounters: Array,
      __encounterCharges: Array,
      __encounterDiagnoses: Array,

      __encounters: Array,
      __billingEncounterCharges: Array,

      __selectedEncounter: Object,
      __locations: Array,

      __formModel: Object,
      model: Object,
      dirty: Boolean,
      hideEncounterTableDetails: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__patientEncounters = [];
    this.__encounterCharges = [];
    this.__encounterDiagnoses = [];

    this.__encounters = [];
    this.__billingEncounterCharges = [];

    this.__selectedEncounter = EMPTY_SELECT_VALUE;
    this.__locations = [];

    this.__formModel = buildModel();
    this.model = [];
    this.hideEncounterTableDetails = false;

    this.onChangeDirty = () => {};

    this.onDismiss = () => {};

    this.onCancel = () => {};
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          height: 100%;
          width: 100%;
        }

        .form {
          flex: 1 0 0;
        }
      `,
    ];
  }

  __initHandlers() {
    this.handlers = {
      encounterSelected: selectedEncounter => {
        this.__selectedEncounter = selectedEncounter;
        const { id } = selectedEncounter;

        if (!id) {
          this.__encounterCharges = [];
          this.__encounterDiagnoses = [];
          return;
        }

        this.__encounterDataService.update(id, [ENCOUNTER, CHARGES, DIAGNOSES]);
      },
      checkUnallocationAuth: charges => {
        const { charges: pristineCharges } = this.__formModel;
        return checkUnallocationAuthorization({ charges, pristineCharges });
      },
      cancel: () => {
        this.onCancel(this.isDirty);
      },
      save: async ({ charges, diagnoses }) => {
        const { id: encounterId, patientId, signed } = this.__selectedEncounter;
        const { charges: pristineCharges } = this.__formModel;

        const formattedDiagnoses = diagnoses.map(({ item }) => item);
        const diagnosesDirty = !(
          JSON.stringify(formattedDiagnoses) ===
          JSON.stringify(this.__encounterDiagnoses)
        );

        const savedCharges = await saveCharges({
          pristineCharges,
          charges,
          patientId,
          billingEncounterCharges: this.__billingEncounterCharges,
          encounterId,
          signed,
          diagnosesUpdated: diagnosesDirty,
          diagnoses: formattedDiagnoses,
        });

        if (savedCharges) {
          this.isDirty = false;
          this.onChangeDirty(this.isDirty);
          this.onDismiss(true);
        }
      },
      dirty: dirty => {
        this.isDirty = dirty;
        this.onChangeDirty(dirty);
      },
    };
  }

  __initServices() {
    this.__encounterService = new EncounterService(patientEncounters => {
      this.__patientEncounters = patientEncounters;
    });

    this.__encounterDataService = new EncounterDataService(
      ({ charges, diagnoses }) => {
        this.__encounterCharges = charges;
        this.__encounterDiagnoses = diagnoses;
      },
    );

    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.__encounterService.connect();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__encounterService.disconnect();
    this.__locationsService.disconnect();
  }

  get __validEncounters() {
    return this.__patientEncounters.filter(({ id }) =>
      this.model.encounterIds.includes(id),
    );
  }

  __checkUpdateManageEncounterForm(changedProps) {
    const setupProps = ['__patientEncounters', '__encounterDiagnoses'];
    return setupProps.some(prop => changedProps.has(prop));
  }

  __checkReloadCharges() {
    return (
      this.__validEncounters.length === 1 &&
      this.__encounterCharges.length === 0
    );
  }

  async updated(changedProps) {
    if (!this.isDirty) {
      if (changedProps.has('model')) {
        const { patientId } = this.model;
        this.__encounterService.update(patientId);
      }

      if (this.__checkUpdateManageEncounterForm(changedProps)) {
        if (this.__checkReloadCharges()) {
          this.__encounterDataService.update(this.__validEncounters[0].id, [
            ENCOUNTER,
            CHARGES,
            DIAGNOSES,
          ]);
        }

        const {
          encounters,
          charges = [],
          diagnoses = [],
          encounterCharges,
          selectedEncounter,
        } = await nebFormManageEncounterService({
          patientId: this.model.patientId,
          patientEncounters: this.__validEncounters,
          patientCharges: this.__encounterCharges,
          patientDiagnoses: this.__encounterDiagnoses,
          selectedEncounter: this.__selectedEncounter,
          locations: this.__locations,
        });

        this.__encounters = encounters;
        this.__billingEncounterCharges = encounterCharges;
        this.__selectedEncounter = selectedEncounter;
        this.__formModel = { charges, diagnoses };
      }
    }
    super.updated(changedProps);
  }

  render() {
    return html`
      <neb-form-manage-encounter
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .encounters="${this.__encounters}"
        .model="${this.__formModel}"
        .selectedEncounter="${this.__selectedEncounter}"
        .encounterCharges="${this.__billingEncounterCharges}"
        .encounterSelected="${this.handlers.encounterSelected}"
        .onCheckingUnallocationAuth="${this.handlers.checkUnallocationAuth}"
        .onCancel="${this.handlers.cancel}"
        .onBack="${this.handlers.cancel}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
        .hideEncounterTableDetails="${this.hideEncounterTableDetails}"
      ></neb-form-manage-encounter>
    `;
  }
}

window.customElements.define(
  'neb-manage-encounter-controller',
  NebManageEncounterController,
);
