import '../../../../../src/components/misc/neb-icon';
import '../controls/neb-button-action';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { TITLE_DEMOGRAPHICS } from '../../../../../src/components/pages/patients/profile/neb-patient-profile-page';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_WHITE,
  CSS_BORDER_GREY_2,
} from '../../../../neb-styles/neb-variables';

const DEFAULT_TITLE = 'TITLE';
export const ELEMENTS = {
  title: { id: 'title' },
  patientDemographicReportLink: {
    id: 'patient-demographic-report-link',
  },
};

class NebPatientPanel extends LitElement {
  static get properties() {
    return {
      onClick: {
        reflect: true,
        type: Function,
      },
      layout: {
        type: String,
        reflect: true,
      },
      title: String,
      hasPatientDemographicReport: Boolean,
      patientId: String,
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
      }

      .container {
        flex: 1 0 0;
        background-color: ${CSS_COLOR_WHITE};
        padding: 10px 0 14px;
        border: ${CSS_BORDER_GREY_2};
        border-radius: 5px;
      }

      :host([onClick]) .container {
        cursor: pointer;
      }

      :host([layout='small']) .container {
        width: 100%;
        padding: 10px;
      }

      .title {
        padding: 0px 10px;
        color: ${CSS_COLOR_HIGHLIGHT};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        display: flex;
        justify-content: space-between;
      }

      :host([layout='small']) .title {
        padding-left: 0px;
      }

      .patient-demographic-report-link {
        font-weight: normal;
      }

      .icon-printer {
        width: 20px;
        height: 20px;
        fill: ${CSS_COLOR_HIGHLIGHT};
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.title = DEFAULT_TITLE;
    this.layout = '';
    this.hasPatientDemographicReport = false;
    this.patientId = '';
  }

  __initHandlers() {
    this.__handlers = {
      click: e => this.onClick(e),
      openPatientDemographicReportParamPopup: () => {
        openPopup(POPUP_RENDER_KEYS.PATIENT_DEMOGRAPHIC_REPORT_PARAMS, {
          patientId: this.patientId,
        });
      },
    };
  }

  __renderPatientDemographicReportLink() {
    return this.title === TITLE_DEMOGRAPHICS && this.hasPatientDemographicReport
      ? html`
          <neb-button-action
            id="${ELEMENTS.patientDemographicReportLink.id}"
            class="patient-demographic-report-link"
            label="Patient Demographics"
            leadingIcon="print"
            .onClick="${this.__handlers.openPatientDemographicReportParamPopup}"
          ></neb-button-action>
        `
      : '';
  }

  render() {
    return html`
      <div class="container" @click="${this.__handlers.click}">
        <div id="${ELEMENTS.title.id}" class="title">
          ${this.title} ${this.__renderPatientDemographicReportLink()}
        </div>

        <slot></slot>
      </div>
    `;
  }
}
customElements.define('neb-patient-panel', NebPatientPanel);
