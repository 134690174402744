import { ISO_DATE_FORMAT } from '../../neb-input/nebFormatUtils';
import { parseDate } from '../../neb-utils/date-util';
import { FEATURE_FLAGS, hasFeatureOrBeta } from '../../neb-utils/feature-util';

import ApiClient, { Method } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const apiClient = new ApiClient({ microservice: 'api' });
export const appointmentsApiClient = new ApiClientV2({
  microservice: 'appointments',
});
export const coreApiClient = new ApiClientV2({
  microservice: 'core',
});

export const getEmptyTreatmentPlan = () => ({
  startDate: parseDate()
    .startOf('day')
    .format(ISO_DATE_FORMAT),
  treatmentPhases: [],
});

export const calculateTreatmentPlan = async (
  patientId,
  treatmentPlan,
  queryParams = {},
  optOutLoadingIndicator = true,
) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return appointmentsApiClient.makeRequest({
      path: '/api/v1/tenants/:tenantId/patients/:patientId/treatment-plans',
      queryParams,
      replacements: { patientId },
      method: Method.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(treatmentPlan),
      optOutLoadingIndicator,
    });
  }

  return apiClient.makeRequest({
    path: `patients/${patientId}/treatmentPlans`,
    queryParams,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(treatmentPlan),
    optOutLoadingIndicator,
  });
};

export const getTreatmentPlan = async (patientId, queryParams = {}) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const res = await coreApiClient.makeRequest({
      path: '/api/v1/tenants/:tenantId/patients/:patientId/treatment-plans',
      replacements: { patientId },
      method: Method.GET,
      queryParams,
    });

    return res.data[0];
  }

  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/treatmentPlans`,
    queryParams,
  });

  return res.data[0];
};

export const getCompletedTreatmentPlans = async patientId => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const res = await coreApiClient.makeRequest({
      path: '/api/v1/tenants/:tenantId/patients/:patientId/treatment-plans',
      replacements: { patientId },
      method: Method.GET,
      queryParams: { status: 'Completed' },
    });

    return res.data;
  }

  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/treatmentPlans`,
    queryParams: { status: 'Completed' },
  });

  return res.data;
};

export const updateTreatmentPlan = async (
  patientId,
  treatmentPlan,
  queryParams = {},
  optOutLoadingIndicator = true,
) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      path: '/api/v1/tenants/:tenantId/patients/:patientId/treatment-plans/:id',
      replacements: { patientId, id: treatmentPlan.id },
      method: Method.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(treatmentPlan),
      optOutLoadingIndicator,
      queryParams,
    });
  }

  return apiClient.makeRequest({
    path: `patients/${patientId}/treatmentPlans/${treatmentPlan.id}`,
    queryParams,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(treatmentPlan),
    optOutLoadingIndicator,
  });
};

export const createTreatmentPlan = async (
  patientId,
  treatmentPlan,
  queryParams = {},
  optOutLoadingIndicator = true,
) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      path: '/api/v1/tenants/:tenantId/patients/:patientId/treatment-plans',
      replacements: { patientId },
      method: Method.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(treatmentPlan),
      optOutLoadingIndicator,
      queryParams,
    });
  }

  return apiClient.makeRequest({
    path: `patients/${patientId}/treatmentPlans`,
    queryParams,
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(treatmentPlan),
    optOutLoadingIndicator,
  });
};
