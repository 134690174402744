import moment from 'moment-timezone';

import { computeTime } from '../../../packages/neb-input/nebFormatUtils';
/* eslint-disable complexity */

export const EMPTY_RESCHEDULE_REASON = {
  label: '',
  data: { id: '00000000-0000-0000-0000-000000000000' },
};

export const formatSelectItems = items =>
  items.map(data => ({
    data,
    label: data.name,
  }));

export const INITIAL_DURATION = 1800000;
export const MAX_DURATION = 35700000;

export const DEFAULT_COLOR = '#C0E6FF';

export function getDurationDisplay(startDate, endDate) {
  const start = startDate.format('h:mm A');
  const end = endDate.format('h:mm A');
  const duration = computeTime(endDate.diff(startDate));
  const durationDisplay = `${start} - ${end} (${duration})`;

  return durationDisplay;
}

export const SELECT_BY_TYPE = {
  CUSTOM: 'custom',
  DATE: 'date',
  TIME: 'time',
};

export function mapSplitsToModel(splits) {
  return splits
    .map(split => {
      const duration = moment(split.end).diff(split.start, 'ms');

      return {
        start: split.start,
        duration,
        resourceId: split.resourceId,
        providerValid: true,
        resourceValid: true,
      };
    })
    .sort((a, b) => moment(a.start).diff(b.start));
}

export function getAppointmentFormModel(appointment) {
  return {
    id: appointment.id,
    patientId: appointment.patientId || '',
    providerId: appointment.providerId || '',
    locationId: appointment.locationId,
    resourceId: appointment.resourceId || '',
    roomId: appointment.roomId || '',
    caseId: appointment.caseId || '',
    patientAuthorizationId: appointment.patientAuthorizationId || null,
    appointmentTypeId: appointment.appointmentTypeId,
    start: appointment.start.toISOString(),
    duration: appointment.end.diff(appointment.start),
    walkIn: !!appointment.walkIn,
    type: SELECT_BY_TYPE.DATE,
    rrule: '',
    note: appointment.note || '',
    cancelRescheduleReasonId: appointment.cancelRescheduleReasonId || '',
    rescheduleReason: appointment.rescheduleReason || '',
    splits: appointment.appointmentSplits
      ? mapSplitsToModel(appointment.appointmentSplits)
      : [],
  };
}
