import '../../../../neb-lit-components/src/components/encounter/neb-encounter-card-list';
import '../../../../neb-lit-components/src/components/encounter/neb-encounter-filter';
import '../../../../neb-lit-components/src/components/encounter-charges/neb-encounter-charges-summary';
import '../../../../neb-lit-components/src/components/encounter-diagnoses/neb-encounter-diagnoses-summary';
import '../../../../neb-lit-components/src/components/neb-checkbox-old';
import '../../../../../src/components/misc/neb-icon';
import '../../../../neb-lit-components/src/components/neb-loading-overlay';
import '../../../../neb-material-design/src/components/neb-loading-spinner';
import '../../../../neb-styles/neb-icons';
import '../../../../neb-lit-components/src/components/encounter/neb-encounter-summary';
import '../../../../neb-document/src/components/neb-documents-scroll';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import * as documentsApiClient from '../../../../neb-api-client/src/document-api-client';
import {
  getAllEncounters,
  copyEncounter,
} from '../../../../neb-api-client/src/encounters-api-client';
import postAllCharges from '../../../../neb-api-client/src/services/encounter/post-all-charges';
import { EncounterDataService } from '../../../../neb-api-client/src/services/encounter-data';
import {
  PRACTICE_SETTINGS,
  getPracticeSettings,
} from '../../../../neb-api-client/src/services/practice-settings';
import {
  openSuccess,
  openError,
  openInfo,
  openWarning,
} from '../../../../neb-dialog/neb-banner-state';
import { BUTTON_ROLE } from '../../../../neb-lit-components/src/components/neb-button';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_SIZE_BODY,
  CSS_BORDER_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import {
  formatSectionTitle,
  formatEncounterCards,
} from '../../../../neb-utils/neb-charting-util';

export const ELEMENTS = {
  buttonCancelEncounter: {
    id: 'button-cancel-encounter',
  },
  buttonCopyEncounter: {
    id: 'button-copy-encounter',
  },
  chargesSummary: {
    id: 'charges-summary',
  },
  chargesTitle: {
    id: 'charges-title',
  },
  encounterDocumentsTitle: {
    id: 'encounter-documents-title',
  },
  containerContent: {
    id: 'container-content',
  },
  copyAssessment: {
    id: 'copy-assessment',
  },
  copyEncounterTitle: {
    id: 'copy-encounter-title',
  },
  copyObjective: {
    id: 'copy-objective',
  },
  copyPlan: {
    id: 'copy-plan',
  },
  copyDocuments: {
    id: 'copy-documents',
  },
  copySubjective: {
    id: 'copy-subjective',
  },
  diagnosesSummary: {
    id: 'diagnoses-summary',
  },
  diagnosesTitle: {
    id: 'diagnoses-title',
  },
  encounterSummary: {
    id: 'encounter-summary',
  },
  documentsSummary: { id: 'documents-summary' },
  iconClose: {
    id: 'icon-close',
  },
  idFilter: {
    id: 'id-filter',
  },
  priorEncounterCardList: {
    id: 'prior-encounter-card-list',
  },
  priorEncounterCardTitle: {
    id: 'prior-encounter-card-title',
  },
  priorEncounterFilter: {
    id: 'prior-encounter-filter',
  },
  search: {
    id: 'search',
  },
  spinner: {
    id: 'spinner',
  },
};
export const COPY_SUCCESS_MESSAGE = 'Prior encounter copied';
const COPY_ERROR_MESSAGE = 'An error occurred when copying the prior encounter';
export const INACTIVE_MESSAGES = {
  single: type => `1 ${type} was not copied because it is now inactive`,
  plural: (count, types) =>
    `${count} ${types} were not copied because they are now inactive`,
  combined: (diagnoses, charges) =>
    `${diagnoses} and ${charges} were not copied because they are now inactive`,
};

const APPEND_TEXT =
  "The chart note for today's encounter already has content in one or more sections, as well as posted charges. To replace the content of the existing note, unpost the charges in the ledger. Would you like to append the copied content to the existing note?";

const APPEND_OR_REPLACE_TEXT =
  "The chart note for today's encounter already has content in one or more sections. Would you like to append the copied content into the existing note, or replace the content of the existing note?";

const removeSpecialCharacters = str => str.replace(/[()-/]/g, '');

class NebCopyPriorEncounter extends LitElement {
  static get properties() {
    return {
      __locations: Array,
      __checkboxes: {
        type: Object,
      },
      __currentEncounterData: {
        type: Object,
      },
      __encounterData: {
        type: Object,
      },
      __filteredEncounters: {
        type: Array,
      },
      __loadingEncounterData: {
        type: Boolean,
      },
      __loadingPriorEncounters: {
        type: Boolean,
      },
      __priorEncounters: {
        type: Array,
      },
      __searchFilter: {
        type: String,
      },
      __searchText: {
        type: String,
      },
      __selectedPriorEncounterId: {
        type: String,
      },
      __autoPostCharges: {
        type: Boolean,
      },
      layout: {
        type: String,
        reflect: true,
      },
      encounter: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();

    this.__initServices();
  }

  __initState() {
    this.__locations = [];

    this.__checkboxes = {
      subjective: true,
      objective: true,
      assessment: true,
      plan: true,
      documents: true,
    };

    this.__encounterData = {
      charges: [],
      diagnoses: [],
      notes: {
        chartNotes: {
          assessment: '',
          objective: '',
          plan: '',
          subjective: '',
        },
      },
      encounter: {
        patientId: 0,
      },
      documents: [],
    };

    this.encounterDocuments = [];
    this.totalEncounterDocuments = 0;

    this.__filteredEncounters = [];
    this.__loadingEncounterData = true;
    this.__loadingPriorEncounters = true;
    this.__loadingMoreDocuments = false;
    this.__autoPostCharges = false;
    this.__priorEncounters = [];
    this.__priorEncountersFilter = [
      {
        label: 'All',
        onSelect: () => {},
      },
      {
        label: 'Open',
        onSelect: () => {},
      },
      {
        label: 'Signed',
        onSelect: () => {},
      },
    ];

    [this.__searchFilter, ,] = this.__priorEncountersFilter;
    this.__searchText = '';
    this.__selectedPriorEncounterId = '';
    this.layout = '';
    this.encounter = null;

    this.onCancel = () => {};

    this.onCopy = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      cancel: () => {
        this.onCancel();
      },
      clearSearch: () => {
        this.__searchText = '';

        this.__searchPriorEncounters();
      },
      copy: () => this.__copyPriorEncounter(),
      filter: ({ value }) => {
        this.__searchFilter = value;

        this.__searchPriorEncounters();
      },
      search: ({ value }) => {
        this.__searchText = value;

        this.__searchPriorEncounters();
      },
      selectPriorEncounter: async ({ value: encounter }) => {
        this.__selectedPriorEncounterId = encounter.id;
        this.__loadingEncounterData = true;

        this.encounterDocuments = [];

        if (this.encounter.patientId) {
          await this.__getSelectedEncounterDocuments(
            this.__selectedPriorEncounterId,
            this.encounter.patientId,
            0,
            false,
          );
        }

        this.__encounterDataService.update(this.__selectedPriorEncounterId);
      },
      requestDocuments: async () => {
        this.__loadingMoreDocuments = true;

        if (this.encounter.patientId && this.encounterDocuments.length >= 10) {
          await this.__getSelectedEncounterDocuments(
            this.__selectedPriorEncounterId,
            this.encounter.patientId,
            this.encounterDocuments.length,
            true,
          );

          this.__encounterData.documents = this.encounterDocuments;
        }
        this.__encounterDataService.update(this.__selectedPriorEncounterId);
      },
      updateAssessmentCheckbox: assessment => {
        this.__checkboxes = { ...this.__checkboxes, assessment };
      },
      updateObjectiveCheckbox: objective => {
        this.__checkboxes = { ...this.__checkboxes, objective };
      },
      updatePlanCheckbox: plan => {
        this.__checkboxes = { ...this.__checkboxes, plan };
      },
      updateSubjectiveCheckbox: subjective => {
        this.__checkboxes = { ...this.__checkboxes, subjective };
      },
      updateDocumentsCheckbox: documents => {
        this.__checkboxes = { ...this.__checkboxes, documents };
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });

    this.__currentEncounterDataService = new EncounterDataService(data => {
      this.__currentEncounterData = { ...data };
      this.__encounterData.documents = this.encounterDocuments;
      this.__loadingEncounterData = false;
      this.__loadingMoreDocuments = false;
    });

    this.__encounterDataService = new EncounterDataService(data => {
      this.__encounterData = { ...data };
      this.__encounterData.documents = this.encounterDocuments;
      this.__loadingEncounterData = false;
      this.__loadingMoreDocuments = false;
    });
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__locationsService.connect();

    const practiceSettings = await getPracticeSettings();

    this.__autoPostCharges =
      practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES];
  }

  disconnectedCallback() {
    this.__locationsService.disconnect();
    super.disconnectedCallback();
  }

  __appendOrReplace() {
    const {
      charges,
      diagnoses,
      notes: { chartNotes },
    } = this.__currentEncounterData;
    return (
      charges.length > 0 ||
      diagnoses.length > 0 ||
      (chartNotes.assessment && this.__checkboxes.assessment) ||
      (chartNotes.subjective && this.__checkboxes.subjective) ||
      (chartNotes.objective && this.__checkboxes.objective) ||
      (chartNotes.plan && this.__checkboxes.plan)
    );
  }

  __checkBoxSelected() {
    return (
      this.__checkboxes.subjective ||
      this.__checkboxes.objective ||
      this.__checkboxes.assessment ||
      this.__checkboxes.plan ||
      this.__checkboxes.documents
    );
  } // ! Search Logic //

  __filterSearchItem(item, terms) {
    const {
      appointmentType,
      encounterNumber,
      fullDate,
      provider,
    } = this.__formatSearchItem(item);

    const search = [appointmentType, encounterNumber, fullDate, provider].join(
      ' ',
    );
    return terms.every(term =>
      search.toLowerCase().includes(term.toLowerCase()),
    );
  }

  __formatSearchItem({
    appointmentType = '',
    encounterNumber = 0,
    fullDate = '',
    provider = '',
  }) {
    return {
      appointmentType: appointmentType.toLowerCase(),
      encounterNumber,
      fullDate: removeSpecialCharacters(fullDate.toLowerCase()),
      provider: provider.toLowerCase(),
    };
  }

  __searchPriorEncounters() {
    let textSearchResults = [];

    if (!this.__searchText) {
      textSearchResults = this.__priorEncounters;
    } else {
      textSearchResults = this.__priorEncounters.filter(encounter =>
        this.__filterSearchItem(
          encounter,
          removeSpecialCharacters(this.__searchText).split(' '),
        ),
      );
    }

    if (this.__searchFilter.label !== 'All') {
      if (this.__searchFilter.label === 'Signed') {
        this.__filteredEncounters = textSearchResults.filter(
          encounter => encounter.signed,
        );
      } else {
        this.__filteredEncounters = textSearchResults.filter(
          encounter => !encounter.signed,
        );
      }
    } else {
      this.__filteredEncounters = textSearchResults;
    }
  } // ! End Search Logic //
  // ! Save logic //

  __handleBanners([
    {
      inactiveDiagnosesCount,
      inactiveChargesCount,
      codeExpiredCount,
      codeDescriptionChangeCount,
    },
  ]) {
    if (
      inactiveDiagnosesCount === 0 &&
      inactiveChargesCount === 0 &&
      codeExpiredCount === 0 &&
      codeDescriptionChangeCount === 0
    ) {
      store.dispatch(openSuccess(COPY_SUCCESS_MESSAGE));
      return;
    }

    let message = '';

    if (codeExpiredCount) {
      store.dispatch(
        openWarning(
          'One or more diagnosis codes is not active for the date of service',
        ),
      );
    }

    if (codeDescriptionChangeCount) {
      store.dispatch(
        openInfo(
          'One or more of the diagnosis descriptions has been updated to match the date of service',
        ),
      );
    }

    if (inactiveChargesCount > 0 && inactiveDiagnosesCount > 0) {
      const diagnosesMsg = `${inactiveDiagnosesCount} ${
        inactiveDiagnosesCount > 1 ? 'Diagnoses' : 'Diagnosis'
      }`;
      const chargesMsg = `${inactiveChargesCount} ${
        inactiveChargesCount > 1 ? 'Charges' : 'Charge'
      }`;
      message = INACTIVE_MESSAGES.combined(diagnosesMsg, chargesMsg);

      store.dispatch(openInfo(message, true));
    } else if (inactiveChargesCount > 1 || inactiveDiagnosesCount > 1) {
      message = INACTIVE_MESSAGES.plural(
        inactiveChargesCount || inactiveDiagnosesCount,
        inactiveChargesCount > 1 ? 'Charges' : 'Diagnoses',
      );

      store.dispatch(openInfo(message, true));
    } else if (inactiveChargesCount === 1 || inactiveDiagnosesCount === 1) {
      message = INACTIVE_MESSAGES.single(
        inactiveChargesCount > 0 ? 'Charge' : 'Diagnosis',
      );

      store.dispatch(openInfo(message));
    }
  }

  async __getSelectedEncounterDocuments(
    encounterId,
    patientId,
    offset,
    isRefetch,
  ) {
    const result = await this.__getDocuments(
      patientId,
      10,
      offset,
      encounterId,
    );

    if (result) {
      this.totalEncounterDocuments = result.count;

      this.encounterDocuments = isRefetch
        ? this.encounterDocuments.concat(result.data)
        : result.data;
    }
  }

  __noDocuments() {
    return (
      !this.__encounterData ||
      !this.__encounterData.documents ||
      this.__encounterData.documents.length === 0
    );
  }

  __getDocuments(
    patientId,
    limit,
    offset,
    encounterId,
    searchTerms = null,
    documentId = null,
  ) {
    try {
      return documentsApiClient.getDocumentsWithThumbnails({
        patientId,
        limit,
        offset,
        searchTerms,
        documentId,
        encounterId,
      });
    } catch (err) {
      console.error(
        `An issue occurred getting document metadata: ${err}, patientId: ${patientId}`,
      );

      return {
        data: [],
        count: 0,
      };
    }
  }

  async __copyPriorEncounter() {
    let action = 'replace';

    if (this.__appendOrReplace()) {
      const disableReplace = this.__currentEncounterData.charges.some(
        ({ postedToLedgerId }) => postedToLedgerId,
      );

      action = await openPopup(POPUP_RENDER_KEYS.CHARTING_NOTE_COPY, {
        title: 'Append or Replace Prior Encounter',
        message: disableReplace ? APPEND_TEXT : APPEND_OR_REPLACE_TEXT,
        disableReplace,
      });
    }

    if (action) {
      const queryParams = {
        ...this.__checkboxes,
        copyEncounterId: this.__selectedPriorEncounterId,
        action,
      };

      try {
        const { id: encounterId, patientId } = this.encounter;

        const response = await copyEncounter(encounterId, queryParams);

        this.__handleBanners(response);

        if (this.__autoPostCharges) {
          await postAllCharges(encounterId, patientId);
        }

        this.onCopy(true);
      } catch (err) {
        store.dispatch(openError(COPY_ERROR_MESSAGE));
      }
    }
  } // ! End Save Logic //

  async __fetchPriorEncounters() {
    const {
      appointmentTypes: { items: apptTypes },
      providers: { item: providers },
    } = store.getState();
    const { data: priorEncounters } = await getAllEncounters(
      this.encounter.patientId,
      {
        encounterId: this.encounter.id,
      },
    );
    this.__priorEncounters = formatEncounterCards(
      priorEncounters,
      providers,
      apptTypes,
      this.__locations,
    );

    this.__filteredEncounters = [...this.__priorEncounters];
    this.__loadingPriorEncounters = false;
  }

  async updated(changedProps) {
    if (
      changedProps.has('__filteredEncounters') &&
      this.__filteredEncounters.length > 0 &&
      !this.__selectedPriorEncounterId
    ) {
      this.__selectedPriorEncounterId = this.__filteredEncounters[0].id;

      if (this.encounter.patientId) {
        await this.__getSelectedEncounterDocuments(
          this.__selectedPriorEncounterId,
          this.encounter.patientId,
          0,
          false,
        );
      }

      this.__encounterDataService.update(this.__selectedPriorEncounterId);
    }

    if (changedProps.has('encounter') && this.encounter) {
      this.__currentEncounterDataService.update(this.encounter.id);

      this.__fetchPriorEncounters();
    }
  }

  __getEncounterDocumentsLabel(label, count) {
    const modelCount = count && count > 0 ? ` (${count})` : '';
    return `${label}${modelCount}`;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: calc(100% - 45px);
        }

        .content {
          flex: 1 0 0;
          display: flex;
          height: 100%;
          width: 100%;
          min-height: 0;

          margin: 0;
        }

        .container-encounter-summary {
          position: relative;
          flex: 2 0 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: inherit;
          margin: 0 ${CSS_SPACING};

          overflow: auto;
          overflow: hidden;
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;

          hyphens: auto;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
        }

        .encounter-summary {
          display: flex;
          width: 100%;
          height: 100%;

          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
          overflow: auto;
        }

        .container-diagnoses {
          position: relative;
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          margin-bottom: ${CSS_SPACING};

          overflow: auto;
          overflow: hidden;
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
        }

        .container-charges {
          position: relative;
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;

          overflow: auto;
          overflow: hidden;
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
        }

        .container-prior-encounter {
          position: relative;
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          min-width: 265px;
        }

        .container-search {
          display: flex;
          align-items: flex-end;
          width: 100%;
        }

        .search-prior-encounter {
          display: flex;
          width: 100%;
          margin-right: 10px;
        }

        .select-prior-encounter-filter {
          width: 134px;
        }

        .column-3 {
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
        }

        .column-4 {
          flex: 2 0 0;
          display: flex;
          flex-direction: column;
          margin-right: 35px;
          width: 10%;
        }

        .encounter-card-list {
          flex: 11 0 0;
          display: flex;
          width: 100%;
          height: 100%;

          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
          overflow-y: scroll;
        }

        .prior-encounters {
          padding-left: 0;
          display: inline-flex;
          align-items: center;
        }

        .charges-content,
        .diagnoses-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        neb-checkbox-old {
          margin-right: ${CSS_SPACING};
        }

        .title-text {
          font-weight: bold;
          font-family: ${CSS_FONT_FAMILY};
        }

        .title-header {
          font-size: ${CSS_FONT_SIZE_HEADER};
        }

        .title-section-header {
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_HIGHLIGHT};
          padding: 10px;
        }

        .label {
          display: flex;
          width: fit-content;
          height: fit-content;

          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-family: ${CSS_FONT_FAMILY};
        }

        .label-header {
          font-family: ${CSS_FONT_FAMILY};
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_HIGHLIGHT};
          padding: 10px;
        }

        .icon {
          cursor: pointer;
          display: flex;
          width: 20px;
          height: 20px;
        }

        .container-action-bar {
          flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 92px;

          margin-top: 15px;
        }

        .container-checkboxes,
        .container-buttons {
          display: flex;
          flex-direction: row;
        }

        .container-checkboxes {
          margin-left: -10px;
          margin-bottom: 15px;
        }

        .button {
          margin-right: 10px;
        }

        .button-copy {
          white-space: nowrap;
        }

        .spinner {
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
        }

        .container-documents {
          position: relative;
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          overflow-y: hidden;
          height: 100%;

          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
          margin: 20px 20px 0px 20px;
        }

        .documents-scroll-container {
          width: 100%;
          height: 100%;
          overflow-y: hidden;
        }
      `,
    ];
  }

  __renderContent() {
    return html`
      <div class="content">
        ${this.__renderPriorEncounters()}

        <div class="column-4">
          ${this.__renderEncounterSummary()} ${this.__renderDocumentsPanel()}
        </div>

        <div class="column-3">
          ${this.__renderDiagnoses()} ${this.__renderCharges()}
        </div>
      </div>
    `;
  }

  __renderPriorEncounters() {
    return html`
      <div class="container-prior-encounter">
        <div class="container-search">
          <neb-textfield
            id="${ELEMENTS.search.id}"
            class="search search-prior-encounter"
            leadingIcon="neb:search"
            placeholder="Filter Encounters"
            .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
            .value="${this.__searchText}"
            .onChange="${this.__handlers.search}"
            .onClickIcon="${this.__handlers.clearSearch}"
          ></neb-textfield>

          <neb-select
            id="${ELEMENTS.priorEncounterFilter.id}"
            class="select select-prior-encounter-filter"
            .items="${this.__priorEncountersFilter}"
            .value="${this.__searchFilter}"
            .onChange="${this.__handlers.filter}"
          ></neb-select>
        </div>

        <div
          id="${ELEMENTS.priorEncounterCardTitle.id}"
          class="label label-header"
        >
          Prior Encounters
        </div>

        <neb-encounter-card-list
          id="${ELEMENTS.priorEncounterCardList.id}"
          class="encounter-card-list"
          message="There are no prior encounters for this patient."
          .models="${this.__filteredEncounters}"
          .layout="${this.layout}"
          .selectedEncounterId="${this.__selectedPriorEncounterId}"
          .onItemClicked="${this.__handlers.selectPriorEncounter}"
        ></neb-encounter-card-list>

        ${
          this.__loadingPriorEncounters
            ? html`
                <neb-loading-overlay
                  id="${ELEMENTS.spinner.id}"
                  class="spinner"
                  showDelay="0"
                  .show="${this.__loadingEncounterData}"
                ></neb-loading-overlay>
              `
            : ''
        }
      </div>
    `;
  }

  __renderEncounterSummary() {
    const { notes } = this.__encounterData || {
      notes: [],
    };
    return html`
      <div class="container-encounter-summary">
        <div class="label label-header">Encounter Summary</div>

        <neb-encounter-summary
          id="${ELEMENTS.encounterSummary.id}"
          class="encounter-summary"
          .model="${notes}"
        ></neb-encounter-summary>

        ${this.__renderEncounterDataSpinner()}
      </div>
    `;
  }

  __renderDiagnoses() {
    const { diagnoses } = this.__encounterData || {
      diagnoses: [],
    };
    const diagnosesTitleText = formatSectionTitle('Diagnoses', diagnoses);
    return html`
      <div class="container-diagnoses">
        <div id="${ELEMENTS.diagnosesTitle.id}" class="label label-header">
          ${diagnosesTitleText}
        </div>
        ${
          diagnoses.length > 0
            ? html`
                <neb-encounter-diagnoses-summary
                  id="${ELEMENTS.diagnosesSummary.id}"
                  class="diagnoses-content"
                  .model="${diagnoses}"
                >
                </neb-encounter-diagnoses-summary>
              `
            : ''
        }
        ${this.__renderEncounterDataSpinner()}
      </div>
    `;
  }

  __renderCharges() {
    const { charges } = this.__encounterData || {
      charges: [],
    };
    const chargesTitleText = formatSectionTitle('Charges', charges);
    return html`
      <div class="container-charges">
        <div id="${ELEMENTS.chargesTitle.id}" class="label label-header">
          ${chargesTitleText}
        </div>
        ${
          charges.length > 0
            ? html`
                <neb-encounter-charges-summary
                  id="${ELEMENTS.chargesSummary.id}"
                  class="charges-content"
                  .savedCharges="${charges}"
                ></neb-encounter-charges-summary>
              `
            : ''
        }
        ${this.__renderEncounterDataSpinner()}
      </div>
    `;
  }

  __renderDocumentsPanel() {
    const { documents } = this.__encounterData || {
      documents: [],
    };
    const encounterDocumentsTitleText = this.__getEncounterDocumentsLabel(
      'Encounter Documents',
      this.totalEncounterDocuments,
    );

    return html`
          <div class="container-documents">
            <div
              id="${ELEMENTS.encounterDocumentsTitle.id}"
              class="label label-header"
            >
              ${encounterDocumentsTitleText}
            </div>
            <div class="documents-scroll-container">
              <neb-documents-scroll
                id="${ELEMENTS.documentsSummary.id}"
                .documents="${documents || []}"
                .totalDocuments="${this.totalEncounterDocuments || 0}"
                .horizontalScroll="${true}"
                .noDocuments="${this.__noDocuments()}"
                .onRequestDocuments="${this.__handlers.requestDocuments}"
                .isOnEncounterDocuments="${true}"
              ></neb-documents-scroll>
            </div>
              ${this.__renderLoadingMoreDocumentsSpinner()}
              ${this.__renderEncounterDataSpinner()}
            </div>
          </div>
        `;
  }

  __renderCopyDocumentCheckbox() {
    return html`
      <neb-checkbox-old
        id="${ELEMENTS.copyDocuments.id}"
        label="Copy Encounter Documents"
        .checked="${this.__checkboxes.documents}"
        .onClick="${this.__handlers.updateDocumentsCheckbox}"
      ></neb-checkbox-old>
    `;
  }

  __renderFooter() {
    return html`
      <div class="container-action-bar">
        <div class="container-checkboxes">
          <neb-checkbox-old
            id="${ELEMENTS.copySubjective.id}"
            label="Copy Subjective"
            .checked="${this.__checkboxes.subjective}"
            .onClick="${this.__handlers.updateSubjectiveCheckbox}"
          ></neb-checkbox-old>

          <neb-checkbox-old
            id="${ELEMENTS.copyObjective.id}"
            label="Copy Objective"
            .checked="${this.__checkboxes.objective}"
            .onClick="${this.__handlers.updateObjectiveCheckbox}"
          ></neb-checkbox-old>

          <neb-checkbox-old
            id="${ELEMENTS.copyAssessment.id}"
            label="Copy Assessment"
            .checked="${this.__checkboxes.assessment}"
            .onClick="${this.__handlers.updateAssessmentCheckbox}"
          ></neb-checkbox-old>

          <neb-checkbox-old
            id="${ELEMENTS.copyPlan.id}"
            label="Copy Plan"
            .checked="${this.__checkboxes.plan}"
            .onClick="${this.__handlers.updatePlanCheckbox}"
          ></neb-checkbox-old>

          ${this.__renderCopyDocumentCheckbox()}
        </div>

        <div class="container-buttons">
          <neb-button
            id="${ELEMENTS.buttonCopyEncounter.id}"
            class="button button-copy"
            label="Copy Encounter"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.copy}"
            ?disabled="${
              !(
                this.__priorEncounters.length > 0 &&
                this.__selectedPriorEncounterId &&
                this.__encounterData &&
                this.__checkBoxSelected()
              )
            }"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancelEncounter.id}"
            class="button button-cancel"
            label="Cancel"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }

  __renderLoadingMoreDocumentsSpinner() {
    return html`
      ${
        this.__loadingMoreDocuments
          ? html`
              <neb-loading-overlay
                id="${ELEMENTS.spinner.id}"
                class="spinner"
                showDelay="0"
                .show="${this.__loadingMoreDocuments}"
              ></neb-loading-overlay>
            `
          : ''
      }
    `;
  }

  __renderEncounterDataSpinner() {
    return html`
      ${
        this.__loadingEncounterData
          ? html`
              <neb-loading-overlay
                id="${ELEMENTS.spinner.id}"
                class="spinner"
                showDelay="0"
                .show="${this.__loadingEncounterData}"
              ></neb-loading-overlay>
            `
          : ''
      }
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.containerContent.id}" class="container-content">
        ${this.__renderContent()} ${this.__renderFooter()}
      </div>
    `;
  }
}

customElements.define('neb-copy-prior-encounter', NebCopyPriorEncounter);
