import { API_URL, CORE_API_URL, REGISTRY_API_URL } from '../../neb-utils/env';
import { FEATURE_FLAGS, hasFeatureOrBeta } from '../../neb-utils/feature-util';

export async function getOnlineBookingPublic(shortName) {
  const url = `${API_URL}/api/v1/public/${shortName}/onlineBooking`;
  const coreUrl = `${CORE_API_URL}/public/v1/${shortName}/online-booking`;

  let res;
  let json;

  const apiDeprecationFF = await hasFeatureOrBeta(
    FEATURE_FLAGS.API_DEPRECATION,
  );

  if (apiDeprecationFF) {
    res = await fetch(coreUrl, {
      method: 'GET',
    });

    json = await res.json();
  } else {
    res = await fetch(url, {
      method: 'GET',
    });

    json = await res.json();
  }

  return json.data[0];
}

export async function getPracticeInfoPublic(shortName) {
  const url = `${REGISTRY_API_URL}/public/v1/tenants/${shortName}`;

  const res = await fetch(url, {
    method: 'GET',
  });

  const json = await res.json();

  return json.data[0];
}
