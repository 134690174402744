import { css, html } from 'lit';

import '../../../../packages/neb-lit-components/src/components/forms/neb-form-patient-insurance-edit';
import {
  getPatientInsurances,
  getAdditionalInsuranceInfo,
} from '../../../../packages/neb-api-client/src/patient-insurance-api-client';
import { getProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import { openError } from '../../../../packages/neb-dialog/neb-banner-state';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  createModelNewPatientInsurance,
  mapToPatientInsuranceModel,
} from '../../../../packages/neb-utils/patientInsurance';
import { ADD_ONS, hasAddOn } from '../../../utils/add-ons';

import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';

export const LOADING_INSURANCE_INFO_ERROR =
  'Error loading insurance information';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  formEditInsurance: {
    id: 'form-edit-insurance',
  },
};

class NebOverlayClaimErrorInsurance extends NebOverlayClaimError {
  static get properties() {
    return {
      __patientInsurances: Array,
      __patientInsurance: Object,
      __providers: Array,
      __hasAddOnCTVerify: Boolean,
      __displayForm: Boolean,
    };
  }

  initState() {
    super.initState();

    this.widthRatio = '50/50';

    this.__patientInsurances = [];
    this.__patientInsurance = { ...createModelNewPatientInsurance() };
    this.__providers = [];
    this.__hasAddOnCTVerify = false;
    this.__displayForm = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      saveInsurance: () => {
        this.isDirty = false;
        this.dismiss(true);
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .form {
          flex: 1 0 0;
        }
      `,
    ];
  }

  async firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      await this.__getEditFormData();
    }

    super.firstUpdated();
  }

  async __getEditFormData() {
    const insuranceId = this.model.claim.insurance.id;

    try {
      const [
        additionalInfo,
        patientInsurances,
        providers,
        hasAddOnCTVerify,
      ] = await Promise.all([
        getAdditionalInsuranceInfo(this.model.patientId, insuranceId),
        getPatientInsurances(this.model.patientId),
        getProviderUsers(),
        hasAddOn(ADD_ONS.CT_VERIFY),
      ]);

      this.__patientInsurances = patientInsurances.map(ins =>
        mapToPatientInsuranceModel(ins),
      );

      this.__providers = providers;

      this.__hasAddOnCTVerify = hasAddOnCTVerify;

      const patientInsurance = patientInsurances.find(
        ins => ins.id === insuranceId,
      );

      this.__patientInsurance = {
        ...mapToPatientInsuranceModel({
          ...patientInsurance,
          ...additionalInfo,
        }),
      };

      this.__displayForm = true;
    } catch (error) {
      this.__displayForm = false;
      store.dispatch(openError(LOADING_INSURANCE_INFO_ERROR));
    }
  }

  renderForm() {
    return this.__displayForm
      ? html`
          <neb-form-patient-insurance-edit
            id="${ELEMENTS.formEditInsurance.id}"
            .layout="${this.layout}"
            .patientId="${this.model.patientId}"
            .patientInsurances="${this.__patientInsurances}"
            .patientInsurance="${this.__patientInsurance}"
            .hasAddOnCTVerify="${this.__hasAddOnCTVerify}"
            .providers="${this.__providers}"
            .onCancel="${this.handlers.dismiss}"
            .onSave="${this.handlers.saveInsurance}"
            .onChangeDirty="${this.handlers.dirty}"
            .hideCloseIcon="${true}"
          ></neb-form-patient-insurance-edit>
        `
      : '';
  }
}

customElements.define(
  'neb-overlay-claim-error-insurance',
  NebOverlayClaimErrorInsurance,
);
