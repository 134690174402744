import { mapUserToClientModel } from '../../packages/neb-api-client/src/mappers/practice-user-mapper';
import ApiClient, {
  buildQueryString,
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

const BASE_PATH = 'tenants';
const URL_LENGTH_LIMIT = 30;
const microservice = 'registry';

export const apiClient = new ApiClient({
  microservice,
});

export const apiClientWithTenant = new ApiClient({
  microservice,
  useTenant: true,
});

function splitTenantIds(tenantIds) {
  return tenantIds.reduce((acc, val, ind) => {
    const subIndex = Math.floor(ind / URL_LENGTH_LIMIT);

    if (!Array.isArray(acc[subIndex])) {
      acc[subIndex] = [val];
    } else {
      acc[subIndex].push(val);
    }

    return acc;
  }, []);
}

export const getPractices = async (tenantIds = []) => {
  const results = await Promise.allSettled(
    tenantIds.map(tenantId =>
      apiClient.makeRequest({
        headers: {
          'Content-Type': 'application/json',
        },
        method: Method.GET,
        path: `practices/${tenantId}`,
        version: 1,
      }),
    ),
  );

  const tenants = [];
  const tenantErrors = [];

  results.forEach(result => {
    if (result.status === 'fulfilled') {
      tenants.push(result.value.data[0]);
    } else {
      tenantErrors.push(result);
    }
  });

  if (tenantErrors.length) {
    console.error(
      `GET practices/tenantId returned ${tenantErrors.length} errors: `,
      tenantErrors,
    );
  }

  return tenants.sort((a, b) => a.name.localeCompare(b.name));
};

export function getTenants(tenantIds = [], query) {
  if (query) {
    const path = `${BASE_PATH}${buildQueryString(query)}`;

    return apiClient.makeRequest({
      headers: {
        'Content-Type': 'application/json',
        ...(tenantIds && { tenantIds }),
      },
      method: Method.GET,
      path,
      cacheKey: path,
    });
  }

  return splitTenantIds(tenantIds).reduce(async (accP, val) => {
    const acc = await accP;
    const { data } = await apiClient.makeRequest({
      headers: {
        'Content-Type': 'application/json',
      },
      method: Method.GET,
      path: BASE_PATH,
      queryParams: {
        tenantIds: val.join(),
      },
    });

    return [...acc, ...data];
  }, Promise.resolve([]));
}

async function createUserRequest(
  [
    path,
    method,
    version = 1,
    responseType = RESPONSE_TYPE.JSON,
    cacheKey = null,
    optOutLoadingIndicator = false,
  ],
  body,
) {
  await apiClientWithTenant.makeRequest({
    version,
    path,
    responseType,
    method,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey,
    optOutLoadingIndicator,
  });

  return undefined;
}

export const createUser = user =>
  createUserRequest(['user', 'POST', 1, true], mapUserToClientModel(user));

export function clearSingleTenantCache(tenantId) {
  return apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.DELETE,
    path: `cache/${tenantId}`,
    responseType: RESPONSE_TYPE.RAW,
  });
}
