/* eslint-disable no-case-declarations */
/* eslint-disable complexity */
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-bar';
import '../../../../packages/neb-lit-components/src/components/controls/neb-horizontal-scroll';
import '../../../../packages/neb-lit-components/src/components/controls/neb-menu-button';
import '../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../../packages/neb-lit-components/src/components/neb-header';
import '../../../../packages/neb-lit-components/src/components/neb-text';
import '../../../../packages/neb-lit-components/src/components/patients/ledger/charges/neb-ledger-charges-claim-section';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table-selected-line-items';
import '../../controllers/charges/neb-era-eob-charges-controller';
import '../era-eob/neb-form-manual-post';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import {
  getLedgerInvoiceItems,
  getPotentialClaims,
} from '../../../../packages/neb-api-client/src/invoice-api-client';
import { buildPatientPackageAssociations } from '../../../../packages/neb-api-client/src/ledger/line-items';
import { fetchOne } from '../../../../packages/neb-api-client/src/patient-api-client';
import {
  getPracticeSettings,
  PRACTICE_SETTINGS,
} from '../../../../packages/neb-api-client/src/services/practice-settings';
import { openWarning } from '../../../../packages/neb-dialog/neb-banner-state';
import {
  changeClaimStatus,
  generateBatch,
  validatePotentialClaimCharges,
  verifyPotentialClaims,
} from '../../../../packages/neb-lit-components/src/components/claims/utils';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import PayerInfoLedgerTable from '../../../../packages/neb-lit-components/src/components/tables/neb-table-ledger-payer-info';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import {
  CODE_PAYMENTS,
  CODE_WRITE_OFFS,
} from '../../../../packages/neb-utils/constants';
import Debouncer from '../../../../packages/neb-utils/debouncer';
import { PROVIDER_TYPE } from '../../../../packages/neb-utils/enums';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../packages/neb-utils/feature-util';
import {
  centsToCurrency,
  currencyToCents,
} from '../../../../packages/neb-utils/formatters';
import {
  BILL_TYPE,
  LINE_ITEM_TYPE,
  PACKAGE_CODES,
  checkForWarnings,
  validateOwedAmounts,
  viewEncounterSummary,
  viewPayment,
  viewPayerPlan,
  viewLedgerLineItem,
  viewERAsEOBs,
} from '../../../../packages/neb-utils/neb-ledger-util';
import { URL_NO_ACCESS } from '../../../../packages/neb-utils/neb-request-security';
import { NO_REMAINING_AUTHORIZATIONS_MESSAGE } from '../../../../packages/neb-utils/patientAuthorization';
import { ITEM_EMPTY } from '../../../../packages/neb-utils/selectors';
import {
  deepCopy,
  getValueByPath,
  map,
} from '../../../../packages/neb-utils/utils';
import { EDIT_MODE } from '../../../utils/neb-charges-util';
import { SORT_BY, sort } from '../../../utils/sort/sort';
import {
  CHARGE_HEADER,
  MANUAL_POST_LINE_ITEM,
  MATCH_CHARGE_HEADER,
} from '../../../utils/user-message';
import { EditChargeTable } from '../../tables/charges/neb-table-charges-edit';

import {
  getChargesManagementButtons,
  isEraOrEOB,
} from './neb-form-charges-management/neb-form-charges-management-util';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  eobCharges: {
    id: 'eob-charges',
  },
  tabGroup: { id: 'tab-group' },
  tablePayer: { id: 'payer-table' },
  tableEdit: { id: 'edit-table' },
  tableDisplay: { id: 'display-table' },
  formAllocate: { id: 'allocate-form' },
  container: { id: 'container' },
  buttonBar: { id: 'button-bar' },
  hoverHorizontalScrollBar: { id: 'hover-horizontal-scroll-bar' },
  header: { id: 'header' },
};

const DEBOUNCE_DELAY = 600;

export const FEE_SCHEDULE_FF_CONFIRM_MESSAGE = html`
  <div>
    Editing the fee schedule on this charge will update modifiers on a signed
    encounter.
  </div>
  <br />
  <div>Do you wish to proceed?</div>
`;

export class NebFormChargesManagement extends NebForm {
  static get properties() {
    return {
      __firstScrollHandler: Boolean,
      __secondScrollHandler: Boolean,
      __hasProvider: Boolean,
      __minWidth: Number,
      __selectedTab: String,
      __payerSearch: String,
      __initialState: Object,
      __authorizations: Array,
      __hasClaimAttachmentsFeatureFlag: Boolean,
      __lineItemIdsWithChangedFeeSchedules: Array,
      __sortParams: Object,
      __buttonsConfig: Array,
      __autoPostCharges: Boolean,
      __showERAsAndEOBsAssociations: Boolean,
      __hasRcmEraEobEnhancements: Boolean,
      __hasRCMChangeSecondary: Boolean,
      __hasRcmRecoupsFeatureFlag: Boolean,

      patient: Object,
      editMode: String,
      associateMode: Boolean,
      displayItems: Array,
      origin: String,
      hasChartingPermission: Boolean,
      hasFifo: Boolean,
      toggleHeaderCheckbox: Boolean,
      paymentLinks: Boolean,
      itemsMap: Object,
      matchLineItemReportId: String,
      itemsForAllocation: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: flex;
          min-width: 100%;
        }

        .scroll-horizontal {
          overflow-x: auto;
          scrollbar-width: none;

          /* Necessary styles for allowing popover to not intersect with top of div */
          padding-top: 182px;
          margin-top: -182px;
        }

        .scroll-horizontal-bulk {
          min-height: 500px;
        }

        .scroll-content {
          display: flex;
          flex-direction: column;
        }

        .scroll-horizontal::-webkit-scrollbar {
          width: 0;
          display: none;
        }

        .button-bar {
          z-index: 1;
          padding-left: ${CSS_SPACING};
        }

        .scroll-content .button-bar {
          padding-bottom: 20px;
        }

        .scroll-horizontal-bulk .scroll-content .button-bar {
          padding-bottom: 10px;
        }

        .action-bar {
          position: relative;
          width: 100%;
          z-index: 3;
          position: fixed;
          bottom: 0%;
        }

        .container-form {
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
        }

        .content-form {
          display: flex;
          min-height: 0;
          flex-flow: column nowrap;
          position: relative;
        }
      `,
    ];
  }

  static createModel() {
    return {
      reportId: '',
      payer: {},
      payerInfo: PayerInfoLedgerTable.createModel(),
      items: EditChargeTable.createModel(),
    };
  }

  static createModelItemsMap() {
    return {
      ...PayerInfoLedgerTable.createModelItemsMap(),
      ...EditChargeTable.createModelItemsMap(),
    };
  }

  get __checkedRowItems() {
    return this.displayItems.filter(row => row.checked);
  }

  __noneChecked() {
    if (this.__checkedRowItems.length) {
      return false;
    }

    openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: 'Bulk Actions',
      message: 'Please select one or more charges before performing an action.',
    });

    return true;
  }

  initState() {
    super.initState();

    this.__firstScrollHandler = false;
    this.__secondScrollHandler = false;
    this.__hasProvider = false;
    this.__navItems = [];
    this.__payerSearch = '';
    this.itemsMap = NebFormChargesManagement.createModelItemsMap();
    this.__initialState = NebFormChargesManagement.createModel();
    this.__authorizations = [];
    this.__lineItemIdsWithChangedFeeSchedules = [];
    this.__sortParams = {};
    this.__autoPostCharges = false;
    this.__buttonsConfig = [];
    this.toggleHeaderCheckbox = true;
    this.__validClaimItems = [];
    this.__previousPatientOwedAmounts = [];
    this.__showERAsAndEOBsAssociations = false;
    this.__hasRCMChangeSecondary = false;
    this.__hasRcmEraEobEnhancements = false;
    this.__hasRcmRecoupsFeatureFlag = false;

    this.editMode = EDIT_MODE.DISABLED;
    this.associateMode = false;
    this.origin = '';
    this.displayItems = [];
    this.patient = {};
    this.hasChartingPermission = false;
    this.hasFifo = false;
    this.paymentLinks = true;
    this.matchLineItemReportId = '';
    this.itemsForAllocation = [];

    this.onToggleItem = () => {};

    this.onSelectAll = () => {};

    this.onBulkActionClick = () => {};

    this.onCancelAssociate = () => {};

    this.onSave = () => {};

    this.onRefetch = async () => {};

    this.onClickERAsEOBs = () => {};

    this.onMatchCharge = () => {};

    this.__generateBatchDebounce = new Debouncer(async () => {
      await generateBatch(this.__validClaimItems);
      this.onRefetch();
    }, DEBOUNCE_DELAY);

    this.onBulkSelect = () => {};

    this.onManualPost = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      selectAll: () => {
        this.onSelectAll();
      },

      selectTab: tab => {
        this.__selectedTab = tab;
      },
      toggleItem: e => this.onToggleItem(e),

      bulkActionClick: async ({ name: actionId }) => {
        const result = await this.onBulkActionClick({ actionId });

        if (!result || (result && result.reload === false)) {
          return;
        }

        this.reload();
      },
      viewEncounterSummary: ({ encounterId, appointmentTypeId, patientId }) => {
        if (!this.hasChartingPermission) return navigate(URL_NO_ACCESS);

        return viewEncounterSummary({
          encounterId,
          appointmentTypeId,
          patientId,
          isDirty: this.__dirty,
          formIsValid: this.formService.validate(),
          onLoad: this.onRefetch,
          onSave: this.handlers.save,
        });
      },
      viewPayment: payment =>
        viewPayment({
          payment,
          isDirty: this.__dirty,
          formIsValid: this.formService.validate(),
          onLoad: this.onRefetch,
          onSave: this.handlers.save,
        }),
      viewPayerPlan: payerPlan =>
        viewPayerPlan({
          payerPlan,
          isDirty: this.__dirty,
          formIsValid: this.formService.validate(),
          onLoad: this.onRefetch,
          onSave: this.handlers.save,
        }),
      viewLedgerLineItem: ({ patientId, id }) =>
        viewLedgerLineItem({
          patientId,
          id,
          isDirty: this.__dirty,
          formIsValid: this.formService.validate(),
          onLoad: this.onRefetch,
          onSave: this.handlers.save,
        }),
      viewClaim: async ({ claimId }) => {
        await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, { claimId });
        return this.onRefetch();
      },
      viewInvoice: async ({ invoiceId, patientId }) => {
        const [result, patient] = await Promise.all([
          getLedgerInvoiceItems(invoiceId),
          fetchOne(patientId),
        ]);

        const lineItemIds = result.data.map(li => li.id);

        await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES, {
          patient,
          lineItemIds,
          selectedIds: [],
        });

        return this.onRefetch();
      },
      viewERAsEOBs: ({ associatedERAsAndEOBs, lineItemId, refetchData }) =>
        viewERAsEOBs({
          associatedERAsAndEOBs,
          lineItemId,
          refetchData,
          isDirty: this.__dirty,
          formIsValid: this.formService.validate(),
          onSave: this.handlers.save,
          onLoad: this.onRefetch,
          onOpenOverlay: this.onClickERAsEOBs,
        }),
      viewERAsEOBsDisplayTable: ({
        associatedERAsAndEOBs,
        lineItemId,
        refetchData,
      }) =>
        viewERAsEOBs({
          associatedERAsAndEOBs,
          lineItemId,
          refetchData,
          isDirty: this.__dirty,
          formIsValid: true,
          onSave: this.handlers.save,
          onLoad: this.onRefetch,
          onOpenOverlay: this.onClickERAsEOBs,
        }),
      navigateToPatientLedger: patientId => {
        navigate(`/patients/${patientId}/ledger/activity/encounters`);
      },

      removeItem: (name, index) => {
        this.formService.removeItem(name, index);

        const segments = name.split('.');
        validateOwedAmounts(segments.slice(0, 2), this.state, this.formService);
      },

      change: async e => {
        const segments = e.name.split('.');
        const keyToChange = segments[segments.length - 1];
        const lineItemIndex = segments[1];
        const lineItemDebitIndex = segments[3];

        const isSelfPay = this.__isSelfPayLineItemDebit(
          lineItemIndex,
          lineItemDebitIndex,
        );

        if (e.event === 'focus' && isSelfPay) {
          this.__previousPatientOwedAmounts[lineItemIndex] = e.value;
        }

        if (keyToChange === 'feeScheduleId' && e.value) {
          this.__changeFeeSchedule(e, segments);
        } else {
          this.formService.apply(e.name, e.value);

          const secondaryInsuranceId = getValueByPath(this.state, [
            'payerInfo',
            '0',
            'secondaryInsuranceId',
          ]);

          if (keyToChange === 'id' && !e.value) {
            this.__removeAllocations(segments);
          }

          if (
            keyToChange === 'payerId' &&
            e.value &&
            !equal(secondaryInsuranceId, ITEM_EMPTY)
          ) {
            this.__setSecondaryPlan(segments, secondaryInsuranceId);
          }

          if (keyToChange === 'payerId' && !e.value) {
            this.__setSecondaryPlan(segments, ITEM_EMPTY);

            if (this.__isCarePackageWithInsurance(lineItemIndex)) {
              const patientPackageAssociations = await this.__fetchPatientPackageAssociations(
                {
                  patientOwedChanged: true,
                  changedLineItemIndex: lineItemIndex,
                },
              );

              await this.__recalculateCarePackage({
                patientPackageAssociations,
                lineItemIndex,
                patientPackageId: this.state.items[lineItemIndex]
                  .patientPackageId,
              });
            }
          }

          if (keyToChange === 'payerId') {
            this.formService.validate();
          }

          if (keyToChange === 'allowedAmount') {
            validateOwedAmounts(
              segments.slice(0, 2),
              this.state,
              this.formService,
            );
          }

          if (
            keyToChange === 'patientInsuranceId' &&
            e.value &&
            e.value.data &&
            e.value.data.payerPlanId
          ) {
            this.formService.apply(
              [
                ...segments.slice(0, segments.length - 1),
                'debit',
                'payerId',
              ].join('.'),
              e.value.data.payerPlanId,
            );
          }
        }
      },

      blur: async e => {
        const segments = e.name.split('.');
        const key = segments[segments.length - 1];

        switch (key) {
          case 'units':
          case 'taxRate':
            await this.__blurUnitsTaxRate(segments.slice(0, 2));
            break;

          case 'unitCharge':
          case 'feeScheduleCharge':
          case 'billedAmount':
          case 'allowedAmount':
            validateOwedAmounts(
              segments.slice(0, 2),
              this.state,
              this.formService,
            );

            break;

          case 'codePaymentId':
            this.__blurCodePaymentId(segments);
            break;

          case 'amount':
            if (segments[segments.length - 2] === 'debit') {
              this.__blurDebitAmount(segments, e.value);
            }

            if (segments[segments.length - 3] === 'adjustments') {
              this.__blurAdjustmentsAmount(segments);
            }
            break;

          case 'codeId':
            if (segments[segments.length - 3] === 'adjustments') {
              this.__blurAdjustmentsCode(segments);
            }
            break;

          default:
        }

        this.formService.validateKey(segments, true);
      },

      addItem: (name, index = -1) => {
        const segments = name.split('.');
        const section = segments[2];
        const path = segments.slice(0, 2);

        this.formService.addItem(name, index);

        if (section === 'lineItemDebits' || section === 'adjustments') {
          validateOwedAmounts(path, this.state, this.formService);
        }
      },

      setMinWidth: minWidth => {
        this.__minWidth = minWidth;
      },

      horizontalScroll: e => {
        if (this.__firstScrollHandler) {
          this.__firstScrollHandler = false;
          return;
        }

        this.__secondScrollHandler = true;

        this.__elements.hoverHorizontalScrollBar.setScrollLeft(
          e.currentTarget.scrollLeft,
        );
      },
      save: async (disableTable = true) => {
        const valid = this.formService.validate();
        const onlyWarnings = await checkForWarnings(this.errors);

        if (valid || onlyWarnings) {
          const rawModel = this.formService.build();
          const model = map(rawModel, (keyPath, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.onSave(model, disableTable);
        }
      },
      hoverBarScroll: scrollLeft => {
        if (this.__secondScrollHandler) {
          this.__secondScrollHandler = false;
          return;
        }

        this.__firstScrollHandler = true;

        this.__elements.container.scrollLeft = scrollLeft;
      },

      cancelAssociation: () => {
        this.onCancelAssociate();
      },
      sort: (_, result) => {
        this.__sortParams = result[0];
        const { key, dir } = this.__sortParams;

        const by = {
          provider: SORT_BY.NAME,
          location: SORT_BY.NAME,
          patientName: SORT_BY.NAME,
          claimStatus: SORT_BY.NAME,
          dateOfService: SORT_BY.DATE,
          invoiceNumber: SORT_BY.NUMBER,
          encounterNumber: SORT_BY.NUMBER,
          claimNumber: SORT_BY.NUMBER,
        };

        sort({ by: by[key], array: this.displayItems, key, dir });
      },
      selectBulkAction: checked => {
        this.onBulkSelect(checked);
      },
      bulkChangeClaimStatus: async claims => {
        const result = await changeClaimStatus(claims);

        if (result) this.onRefetch();
      },
      openClaimBatchesOverlay: () => openOverlay(OVERLAY_KEYS.CLAIM_BATCHES),
      updateBillingNotes: () => this.onRefetch(),
      saveAndSubmit: async () => {
        if (this.__noneChecked()) return;

        if (!(await validatePotentialClaimCharges(this.__checkedRowItems))) {
          return;
        }

        const invoices = await this.__checkedRowItems.reduce((acc, item) => {
          if (!acc.find(({ invoiceId }) => invoiceId === item.invoiceId)) {
            acc.push({
              invoiceId: item.invoiceId,
              claimId: item.claimId,
              primaryInsuranceId: item.primaryPlanId,
            });
          }
          return acc;
        }, []);

        const { data: potentialClaims } = await getPotentialClaims(invoices);

        const goodRowItems = await verifyPotentialClaims(potentialClaims);

        if (goodRowItems.length) {
          this.__validClaimItems = goodRowItems;
          await this.__generateBatchDebounce.debounce();
          this.__setAllCheckboxes(false);
        }
      },
      cancel: () => {
        this.onCancel();
      },
      changeFeeSchedule: async (index, feeSchedule) => {
        const { items: charges } = this.state;
        const charge = charges[index];

        const { id } = feeSchedule.data;

        const { chargeId } = charge.encounterCharge;

        const foundCharge = feeSchedule.data.charges.find(
          c => c.id === chargeId,
        );

        const hasFeeScheduleModifiersChanged =
          charge.encounterCharge.signed &&
          charge.feeScheduleId !== id &&
          foundCharge.modifiers.some((mod, idx) => {
            const modifier = mod || null;
            const chargeModifier = charge[`modifier_${idx + 1}`] || null;

            return modifier !== chargeModifier;
          });

        let result = true;

        if (hasFeeScheduleModifiersChanged) {
          result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Fee Schedule Modifiers',
            message: FEE_SCHEDULE_FF_CONFIRM_MESSAGE,
            confirmText: 'YES',
            cancelText: 'NO',
          });
        }

        return result;
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    const features = await getFeatures();
    this.__showERAsAndEOBsAssociations = features.includes(
      FEATURE_FLAGS.SHOW_ERA_EOB_ASSOCIATIONS,
    );

    this.__hasRCMChangeSecondary = features.includes(
      FEATURE_FLAGS.RCM_CHANGE_SECONDARY,
    );

    this.__hasRcmEraEobEnhancements = features.includes(
      FEATURE_FLAGS.ERA_EOB_ENHANCEMENTS,
    );

    this.__hasRcmRecoupsFeatureFlag = features.includes(
      FEATURE_FLAGS.OWL_RCM_RECOUPS,
    );

    const practiceSettings = await getPracticeSettings();
    this.__autoPostCharges =
      practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES];
  }

  createSelectors() {
    return {
      children: {
        reportId: { ignorePristine: true },
        payerInfo: PayerInfoLedgerTable.createSelectors(this.itemsMap),
        items: EditChargeTable.createSelectors(this.itemsMap),
      },
    };
  }

  __isSelfPayLineItemDebit(lineItemIndex, lineItemDebitIndex) {
    const lineItemDebitField = this.state.items[lineItemIndex]?.lineItemDebits[
      lineItemDebitIndex
    ];
    return lineItemDebitField && !lineItemDebitField.debit.payerId;
  }

  __lineItemHasPackageDebit(lineItemIndex) {
    return this.state.items[lineItemIndex].lineItemDebits.some(
      lineItemDebit => lineItemDebit.codePaymentId.label === PACKAGE_CODES[0],
    );
  }

  __isCarePackageWithInsurance() {
    const payerInfo = this.state.payerInfo[0];
    return (
      payerInfo.billType.data.id === BILL_TYPE.INSURANCE &&
      payerInfo.packageId.data.id !== ''
    );
  }

  __shouldRecalculateCarePackage(lineItemIndex, value) {
    return (
      this.__isCarePackageWithInsurance() &&
      this.__previousPatientOwedAmounts[lineItemIndex] !== value
    );
  }

  __recalculateCarePackage({ patientPackageAssociations, patientPackageId }) {
    this.__removeNonCarePackageItems(patientPackageId);
    this.__rebuildPatientPackageAssociations(
      patientPackageAssociations,
      patientPackageId,
    );

    this.__addNonPackageLineItemDebit(
      patientPackageAssociations,
      patientPackageId,
    );
  }

  __setAllCheckboxes(checked) {
    this.handlers.selectBulkAction(checked);
  }

  __showAuthorizationWarning() {
    store.dispatch(
      openWarning(
        NO_REMAINING_AUTHORIZATIONS_MESSAGE,
        this.handlers.editAuthorization,
      ),
    );
  }

  __setSecondaryPlan(segments, secondaryInsuranceId) {
    this.formService.apply(
      [...segments.slice(0, segments.length - 2), 'patientInsuranceId'].join(
        '.',
      ),
      secondaryInsuranceId,
    );

    this.formService.apply(
      [...segments.slice(0, segments.length - 1), 'payerId'].join('.'),
      secondaryInsuranceId.data.payerPlanId || null,
    );
  }

  __applyFeeScheduleDebit(itemIndex, index, code, amount) {
    const basePath = `items.${itemIndex}.lineItemDebits`;

    this.formService.addItem(basePath, index);
    this.formService.apply(`${basePath}.${index}.codePaymentId`, code);
    this.formService.apply(`${basePath}.${index}.debit.amount`, amount);
  }

  __applyFeeScheduleAdj(itemIndex, index, code, amount) {
    const basePath = `items.${itemIndex}.adjustments`;

    this.formService.addItem(basePath, index);
    this.formService.apply(`${basePath}.${index}.codeId`, code);
    this.formService.apply(`${basePath}.${index}.amount`, amount);
  }

  __changeFeeSchedule(e, segments) {
    const itemIndex = Number(segments[1]);
    const itemPath = segments.slice(0, segments.length - 1);
    const lineItem = getValueByPath(this.state, itemPath);

    if (this.getBillType() === BILL_TYPE.SELF) {
      const currentFS = getValueByPath(this.state, [
        ...itemPath,
        'feeScheduleId',
      ]);

      if (!equal(e.value, currentFS)) {
        const initialLineItem = this.__initialState.items[itemIndex];

        if (
          !this.__lineItemIdsWithChangedFeeSchedules.includes(
            initialLineItem.id,
          )
        ) {
          this.__lineItemIdsWithChangedFeeSchedules.push(initialLineItem.id);
        }

        this.formService.apply(e.name, e.value);

        this.__removeLineItemDebitsAndAdjustments({
          lineItem,
          itemIndex,
          pkgAdjustmentId: null,
          feeScheduleChange: true,
        });

        switch (true) {
          case equal(e.value.data.id, initialLineItem.feeScheduleId.data.id):
            const { lineItemDebits, adjustments } = initialLineItem;

            lineItemDebits.forEach((lid, index) => {
              this.__applyFeeScheduleDebit(
                itemIndex,
                index,
                lid.codePaymentId,
                lid.debit.amount,
              );
            });

            adjustments.forEach((a, index) => {
              this.__applyFeeScheduleAdj(itemIndex, index, a.codeId, a.amount);
            });

            break;

          case equal(e.value, ITEM_EMPTY):
            const owed = getValueByPath(this.state, [
              ...itemPath,
              'feeScheduleCharge',
            ]);

            this.formService.addItem(`items.${itemIndex}.lineItemDebits`, 0);
            this.formService.addItem(`items.${itemIndex}.adjustments`, 0);

            this.formService.apply(
              `items.${itemIndex}.lineItemDebits.0.debit.amount`,
              owed,
            );

            break;

          default:
            const ffs = this.itemsMap.paymentTypes.find(
              pt => pt.data.code === 'FFS',
            );

            const { patientAdjustmentCodeId, charges } = e.value.data;

            const { adjustmentAmount, amount } = charges.find(
              c => c.procedure === lineItem.code,
            );

            const patientOwed = centsToCurrency(
              (amount - adjustmentAmount) * lineItem.units,
            );

            const code =
              adjustmentAmount !== 0
                ? this.itemsMap.adjustments.find(
                    a => a.data.id === patientAdjustmentCodeId,
                  )
                : ITEM_EMPTY;

            this.__applyFeeScheduleDebit(itemIndex, 0, ffs, patientOwed);

            this.__applyFeeScheduleAdj(
              itemIndex,
              0,
              code,
              centsToCurrency(adjustmentAmount * lineItem.units),
            );

            break;
        }
      }
    }
  }

  __removeAllocations(segments) {
    const allocationsPath = [
      ...segments.slice(0, segments.length - 1),
      'allocations',
    ];

    const allocations = getValueByPath(this.state, allocationsPath);

    for (let i = 0; i < allocations.length; i++) {
      this.formService.removeItem(allocationsPath.join('.'));
    }

    this.formService.apply(
      [...segments.slice(0, segments.length - 2), 'patientInsuranceId'].join(
        '.',
      ),
      ITEM_EMPTY,
    );
  }

  __blurAdjustmentsCode(segments) {
    const amountPath = [...segments.slice(0, segments.length - 1), 'amount'];

    this.formService.unsetPristine(amountPath);
    this.formService.validateKey(amountPath, true);
  }

  __blurAdjustmentsAmount(segments) {
    const codeIdPath = [...segments.slice(0, segments.length - 1), 'codeId'];

    this.formService.unsetPristine(codeIdPath);
    this.formService.validateKey(codeIdPath, true);
    validateOwedAmounts(segments.slice(0, 2), this.state, this.formService);
  }

  async __blurDebitAmount(segments, value) {
    const lineItemDebitIndex = Number(segments[segments.length - 3]);
    const lineItemIndex = Number(segments[1]);

    const isSelfPay = this.__isSelfPayLineItemDebit(
      lineItemIndex,
      lineItemDebitIndex,
    );

    if (isSelfPay) {
      const codePaymentIdPath = [
        ...segments.slice(0, segments.length - 2),
        'codePaymentId',
      ];

      this.formService.unsetPristine(codePaymentIdPath);
      this.formService.validateKey(codePaymentIdPath, true);

      if (this.__shouldRecalculateCarePackage(lineItemIndex, value)) {
        const patientPackageAssociations = await this.__fetchPatientPackageAssociations(
          {
            patientOwedChanged: true,
            changedLineItemIndex: lineItemIndex,
          },
        );

        this.__recalculateCarePackage({
          lineItemIndex,
          patientPackageAssociations,
          patientPackageId: this.state.items[lineItemIndex].patientPackageId,
        });
      }
    }

    validateOwedAmounts(segments.slice(0, 2), this.state, this.formService);
  }

  __blurCodePaymentId(segments) {
    const lineItemDebitIndex = Number(segments[segments.length - 2]);

    const lineItemIndex = Number(segments[1]);

    const patient = !this.state.items[lineItemIndex].lineItemDebits[
      lineItemDebitIndex
    ].debit.payerId;

    if (patient) {
      const debitAmountPath = [
        ...segments.slice(0, segments.length - 1),
        'debit',
        'amount',
      ];

      this.formService.unsetPristine(debitAmountPath);
      this.formService.validateKey(debitAmountPath, true);
    }
  }

  async __blurUnitsTaxRate(itemPath) {
    const lineItemIndex = Number(itemPath[1]);
    const packageExists = this.state.payerInfo[0].packageId.data.id;

    const encounterChargesExist = this.state.items.some(
      ({ type }) => type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE,
    );

    const { id: pkgAdjustmentId } = this.itemsMap.adjustments.find(
      adjustment => adjustment.data.code === PACKAGE_CODES[0],
    ).data;

    const unitsOrTaxRateChanged = this.state.items.some(
      ({ units, taxAmount }, index) =>
        parseInt(units, 10) !== this.model.items[index].units ||
        currencyToCents(taxAmount) !== this.model.items[index].taxAmount,
    );

    const packageItemsWithIdNullExists = this.state.items.some(
      ({ lineItemDebits, adjustments }) =>
        lineItemDebits.some(
          ({ codePaymentId, debit: { id } }) =>
            codePaymentId.label === PACKAGE_CODES[0] && !id,
        ) ||
        adjustments.some(
          ({ codeId, id }) => codeId.data.id === pkgAdjustmentId && !id,
        ),
    );

    if (
      !packageExists ||
      !encounterChargesExist ||
      (!unitsOrTaxRateChanged && !packageItemsWithIdNullExists) ||
      this.__isCarePackageWithInsurance()
    ) {
      validateOwedAmounts(itemPath, this.state, this.formService);
      return this.requestUpdate();
    }

    const patientPackageAssociations = unitsOrTaxRateChanged
      ? await this.__fetchPatientPackageAssociations({
          changedLineItemIndex: lineItemIndex,
          patientOwedChanged: false,
        })
      : this.__getOriginalPatientPackageAssociations();

    await this.requestUpdate();

    return this.__rebuildPatientPackageAssociations(
      patientPackageAssociations,
      this.state.items[lineItemIndex].patientPackageId,
    );
  }

  __addNonPackageLineItemDebit(patientPackageAssociations, patientPackageId) {
    this.state.items.forEach((item, itemIndex) => {
      if (item.patientPackageId !== patientPackageId) {
        return;
      }

      const lineItem = patientPackageAssociations[item.id];

      if (
        lineItem &&
        lineItem.lineItemDebits.length === 1 &&
        lineItem.lineItemDebits[0].codePaymentId !== CODE_PAYMENTS.PACKAGE.id
      ) {
        this.handlers.addItem(`items.${itemIndex}.lineItemDebits`);

        this.formService.apply(
          `items.${itemIndex}.lineItemDebits.1.debit.amount`,
          centsToCurrency(lineItem.lineItemDebits[0].debit.amount),
        );

        this.formService.apply(
          `items.${itemIndex}.lineItemDebits.1.codePaymentId`,
          this.itemsMap.paymentTypes.find(
            paymentType =>
              paymentType.data.id === CODE_PAYMENTS.GENERAL_PAYMENT.id,
          ),
        );

        return;
      }

      if (lineItem) {
        for (let i = lineItem.lineItemDebits.length - 1; i >= 0; i -= 1) {
          if (
            lineItem.lineItemDebits[i].codePaymentId !==
            CODE_PAYMENTS.PACKAGE.id
          ) {
            this.handlers.addItem(`items.${itemIndex}.lineItemDebits`);

            const debitIndex =
              this.state.items[itemIndex].lineItemDebits.length - 1;

            const nonPkg = this.itemsMap.paymentTypes.find(
              paymentType =>
                paymentType.data.id ===
                lineItem.lineItemDebits[i].codePaymentId,
            );

            this.formService.apply(
              `items.${itemIndex}.lineItemDebits.${debitIndex}.codePaymentId`,
              nonPkg,
            );

            this.formService.apply(
              `items.${itemIndex}.lineItemDebits.${debitIndex}.debit.amount`,
              centsToCurrency(lineItem.lineItemDebits[i].debit.amount),
            );
          }
        }

        if (!lineItem.lineItemDebits.length) {
          this.handlers.addItem(`items.${itemIndex}.lineItemDebits`);
        }
      }
    });
  }

  __fetchPatientPackageAssociations({
    patientOwedChanged,
    changedLineItemIndex,
  }) {
    return buildPatientPackageAssociations({
      lineItems: deepCopy(
        this.state.items.filter(
          item =>
            item.patientPackageId ===
            this.state.items[changedLineItemIndex].patientPackageId,
        ),
      ),
      patientOwedChanged,
      changedLineItemIndex,
    });
  }

  __getOriginalPatientPackageAssociations() {
    return this.model.items.reduce(
      (acc, { type, id, lineItemDebits, adjustments }) => {
        if (type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE) {
          acc[id] = { lineItemDebits, adjustments };
        }
        return acc;
      },
      {},
    );
  }

  __rebuildPatientPackageAssociations(
    patientPackageAssociations,
    patientPackageId,
  ) {
    this.state.items.forEach((item, itemIndex) => {
      if (item.patientPackageId !== patientPackageId) {
        return;
      }

      const lineItem = patientPackageAssociations[item.id];

      if (lineItem) {
        const { id: pkgAdjustmentId } = this.itemsMap.adjustments.find(
          adjustment => adjustment.data.code === PACKAGE_CODES[0],
        ).data;

        this.__removeLineItemDebitsAndAdjustments({
          lineItem: item,
          itemIndex,
          pkgAdjustmentId,
        });

        const pkgLineItemDebit = lineItem.lineItemDebits.find(
          ({ responsibilityType }) => responsibilityType === PACKAGE_CODES[0],
        );

        if (pkgLineItemDebit) {
          this.__addLineItemPkgDebit(itemIndex, pkgLineItemDebit);
        }
        const pkgLineItemAdjustment = lineItem.adjustments.find(
          ({ codeId }) => codeId === pkgAdjustmentId,
        );

        if (pkgLineItemAdjustment) {
          this.__addLineItemPkgAdjustment(itemIndex, pkgLineItemAdjustment);
        }

        if (!this.state.items[itemIndex].lineItemDebits.length) {
          this.handlers.addItem(`items.${itemIndex}.lineItemDebits`, 0);
        }

        if (!this.state.items[itemIndex].adjustments.length) {
          this.handlers.addItem(`items.${itemIndex}.adjustments`, 0);
        }
      } else {
        const { adjustments, lineItemDebits } = this.state.items[itemIndex];

        if (lineItemDebits.length > 1) {
          this.formService.removeItem(
            `items.${itemIndex}.lineItemDebits.1.debit.allocations`,
            0,
          );
        } else {
          this.handlers.addItem(`items.${itemIndex}.lineItemDebits`);
        }

        const pkgAdjustmentIndex = adjustments.findIndex(
          adj => adj.codeId.data.id === CODE_WRITE_OFFS.PACKAGE.id,
        );

        if (pkgAdjustmentIndex !== -1) {
          this.formService.removeItem(
            `items.${itemIndex}.adjustments`,
            pkgAdjustmentIndex,
          );
        }

        if (!adjustments.length) {
          this.handlers.addItem(`items.${itemIndex}.adjustments`, 0);
        }
      }

      validateOwedAmounts(['items', itemIndex], this.state, this.formService);
    });

    this.requestUpdate();
  }

  __removeNonCarePackageItems(patientPackageId) {
    this.state.items.forEach((item, itemIndex) => {
      const lineItemDebitLength = item.lineItemDebits.length - 1;

      for (let i = lineItemDebitLength; i >= 0; i -= 1) {
        if (
          item.lineItemDebits[i].codePaymentId.label !==
            CODE_PAYMENTS.PACKAGE.code &&
          !item.lineItemDebits[i].debit.payerId &&
          item.patientPackageId === patientPackageId
        ) {
          const itemPath = `items.${itemIndex}`;
          this.formService.removeItem(`${itemPath}.lineItemDebits`, i);
        }
      }
    });
  }

  __isEmptyLineItemDebit(lineItemDebit) {
    return (
      !lineItemDebit.debit.payerId &&
      !lineItemDebit.codePaymentId.label &&
      !currencyToCents(lineItemDebit.debit.amount)
    );
  }

  __isEmptyAdjustment(adjustment) {
    return !adjustment.codeId.data.id && !currencyToCents(adjustment.amount);
  }

  __removeLineItemDebitsAndAdjustments({
    lineItem: { lineItemDebits, adjustments },
    itemIndex,
    pkgAdjustmentId,
    feeScheduleChange = false,
  }) {
    const itemPath = `items.${itemIndex}`;

    const lineItemDebitLength = lineItemDebits.length - 1;

    for (let i = lineItemDebitLength; i >= 0; i -= 1) {
      if (
        lineItemDebits[i].codePaymentId.label === PACKAGE_CODES[0] ||
        this.__isEmptyLineItemDebit(lineItemDebits[i]) ||
        feeScheduleChange
      ) {
        this.formService.removeItem(`${itemPath}.lineItemDebits`, i);
      }
    }

    const adjustmentLength = adjustments.length - 1;

    for (let i = adjustmentLength; i >= 0; i -= 1) {
      if (
        adjustments[i].codeId.data.id === pkgAdjustmentId ||
        this.__isEmptyAdjustment(adjustments[i]) ||
        feeScheduleChange
      ) {
        this.formService.removeItem(`${itemPath}.adjustments`, i);
      }
    }
  }

  __addLineItemPkgDebit(
    itemIndex,
    {
      codePaymentId,
      debit: { amount, allocations, id = '' },
    },
  ) {
    const lineItemDebitPath = `items.${itemIndex}.lineItemDebits`;

    this.handlers.addItem(lineItemDebitPath);
    const debitIndex = this.state.items[itemIndex].lineItemDebits.length - 1;

    this.formService.apply(
      `${lineItemDebitPath}.${debitIndex}.codePaymentId`,
      this.itemsMap.paymentTypes.find(
        paymentType => paymentType.data.id === codePaymentId,
      ),
    );

    this.formService.apply(
      `${lineItemDebitPath}.${debitIndex}.debit.amount`,
      centsToCurrency(amount),
    );

    this.formService.apply(`${lineItemDebitPath}.${debitIndex}.debit.id`, id);

    const [allocation] = allocations;
    this.__addLineItemPkgDebitAllocation({
      itemIndex,
      debitIndex,
      allocation,
    });
  }

  __addLineItemPkgDebitAllocation({ itemIndex, debitIndex, allocation }) {
    const allocationPath = `items.${itemIndex}.lineItemDebits.${debitIndex}.debit.allocations`;

    this.handlers.addItem(allocationPath);

    this.formService.apply(`${allocationPath}.0.amount`, allocation.amount);

    this.formService.apply(
      `${allocationPath}.0.credit.amount`,
      allocation.credit.amount,
    );

    this.formService.apply(
      `${allocationPath}.0.credit.patientPackageId`,
      allocation.credit.patientPackageId,
    );
  }

  __addLineItemPkgAdjustment(itemIndex, { id = '', amount, codeId }) {
    const adjustmentPath = `items.${itemIndex}.adjustments`;

    this.handlers.addItem(adjustmentPath);
    const adjustmentIndex = this.state.items[itemIndex].adjustments.length - 1;

    this.formService.apply(
      `${adjustmentPath}.${adjustmentIndex}.amount`,
      centsToCurrency(amount),
    );

    this.formService.apply(
      `${adjustmentPath}.${adjustmentIndex}.codeId`,
      this.itemsMap.adjustments.find(
        adjustment => adjustment.data.id === codeId,
      ),
    );

    this.formService.apply(`${adjustmentPath}.${adjustmentIndex}.id`, id);
  }

  __hasSecondaryChargesAndHoldClaim() {
    return this.displayItems
      .filter(e => e.secondaryOwed)
      .every(e => e.holdClaim);
  }

  __buildButtonConfig() {
    this.__buttonsConfig = getChargesManagementButtons({
      displayItems: this.displayItems,
      editMode: this.editMode,
      origin: this.origin,
      hasFifo: this.hasFifo,
      associateMode: this.associateMode,
      hasChartingPermission: this.hasChartingPermission,
      autoPostCharges: this.__autoPostCharges,
      hideAllocatePayment: this.__hasRcmEraEobEnhancements,
    }).map(button => ({ ...button, onClick: this.handlers.bulkActionClick }));
  }

  __checkReloadButtons(changedProps) {
    const props = [
      '__autoPostCharges',
      'displayItems',
      'origin',
      'hasFifo',
      'associateMode',
      'hasChartingPermission',
      'editMode',
      '__hasRcmEraEobEnhancements',
    ];
    return props.some(prop => changedProps.has(prop));
  }

  checkReloadModel(changedProps) {
    if (!changedProps.has('model') && !changedProps.has('itemsMap')) return;
    if (!this.model.items.length) return;

    this.reload();
  }

  update(changed) {
    this.checkReloadModel(changed);

    if (this.__checkReloadButtons(changed)) {
      this.__buildButtonConfig();
    }

    super.update(changed);
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('model')) {
      this.__elements = {
        hoverHorizontalScrollBar: this.shadowRoot.getElementById(
          ELEMENTS.hoverHorizontalScrollBar.id,
        ),
        container: this.shadowRoot.getElementById(ELEMENTS.container.id),
      };

      this.__initialState = this.formService.convert(this.model, 'format');
    }

    if (changedProps.has('editMode')) {
      this.__lineItemIdsWithChangedFeeSchedules = [];

      if (this.__elements.hoverHorizontalScrollBar) {
        this.__elements.hoverHorizontalScrollBar.setScrollOffsetTop(0);
      }
    }

    if (changedProps.has('associateMode')) {
      if (this.associateMode) {
        this.__elements = {
          ...this.__elements,
          eobCharges: this.shadowRoot.getElementById(ELEMENTS.eobCharges.id),
        };

        this.__elements.eobCharges.setYOffset(0);
      } else {
        this.__elements = {
          ...this.__elements,
          hoverHorizontalScrollBar: this.shadowRoot.getElementById(
            ELEMENTS.hoverHorizontalScrollBar.id,
          ),
        };

        this.__elements.hoverHorizontalScrollBar.setScrollOffsetTop(0);
      }
    }

    const hasDisplayItemsChangedAndExist =
      changedProps.has('displayItems') && this.displayItems.length;

    if (hasDisplayItemsChangedAndExist && this.itemsMap.users.length) {
      this.__hasProvider = this.displayItems.some(charge => {
        const { providerId } = charge;
        const provider = this.itemsMap.users.find(
          user => user.data.id === providerId,
        );
        return provider && provider.data.type === PROVIDER_TYPE.PROVIDER;
      });
    }

    const shouldSortDisplayItems =
      isEraOrEOB(this.origin) &&
      this.__sortParams.key &&
      this.editMode === EDIT_MODE.DISABLED;

    if (hasDisplayItemsChangedAndExist && shouldSortDisplayItems) {
      this.handlers.sort(null, [this.__sortParams]);
    }
  }

  setYOffset(offset = 0) {
    if (!this.associateMode && this.__elements.hoverHorizontalScrollBar) {
      this.__elements.hoverHorizontalScrollBar.setScrollOffsetTop(offset);
    } else if (this.associateMode) {
      if (!this.__elements.eobCharges) {
        this.__elements = {
          ...this.__elements,
          eobCharges: this.shadowRoot.getElementById(ELEMENTS.eobCharges.id),
        };
      }
      this.__elements.eobCharges.setYOffset(offset);
    }
  }

  getInsuranceId() {
    return this.state.payerInfo[0].insuranceId.data.id;
  }

  getBillType() {
    return this.state.payerInfo[0].billType.data.id;
  }

  __renderButtonBar() {
    return html`
      <neb-button-bar
        id="${ELEMENTS.buttonBar.id}"
        class="button-bar"
        .config="${this.__buttonsConfig}"
        .stretch="${true}"
      ></neb-button-bar>
    `;
  }

  __renderChargesContainer() {
    if (this.editMode === EDIT_MODE.MANUAL_POST) {
      return this.__renderFormManualPostCharges();
    }

    return this.associateMode
      ? html`
          ${this.__renderButtonBar()}
          <neb-era-eob-charges-controller
            id="${ELEMENTS.eobCharges.id}"
            class="eob-charges"
            .model="${this.model}"
            .layout="${this.layout}"
            .noChargesMessage="${'There are no charges.'}"
            .onCancel="${this.handlers.cancelAssociation}"
            .chargesType="${this.origin}"
            .editMode="${this.editMode}"
            .matchLineItemReportId="${this.matchLineItemReportId}"
            .onMatchCharge="${this.onMatchCharge}"
          ></neb-era-eob-charges-controller>
        `
      : html`
          <div
            id="${ELEMENTS.container.id}"
            class="scroll-horizontal ${
              isEraOrEOB(this.origin) ? 'scroll-horizontal-bulk' : ''
            }"
            @scroll="${this.handlers.horizontalScroll}"
          >
            <div class="scroll-content">
              ${this.__renderButtonBar()}${this.__renderItemTable()}
            </div>
          </div>
          <neb-horizontal-scroll
            id="${ELEMENTS.hoverHorizontalScrollBar.id}"
            .onChange="${this.handlers.hoverBarScroll}"
            .yOffset="${
              this.editMode !== EDIT_MODE.DISABLED || this.associateMode
                ? 80
                : 0
            }"
            .minWidth="${this.__minWidth}"
            .fixAtBottom="${true}"
          ></neb-horizontal-scroll>
        `;
  }

  __getChargesTabDescription() {
    switch (this.editMode) {
      case EDIT_MODE.MATCH:
        return MATCH_CHARGE_HEADER;
      case EDIT_MODE.MANUAL_POST:
        return '';
      default:
        return CHARGE_HEADER;
    }
  }

  __renderChargesTab() {
    const label =
      this.editMode === EDIT_MODE.MANUAL_POST
        ? MANUAL_POST_LINE_ITEM
        : 'Charges';
    const description = this.__getChargesTabDescription();

    return html`
      <neb-header
        id="${ELEMENTS.header.id}"
        label="${label}"
        description="${description}"
      ></neb-header>

      ${this.__renderChargesContainer()}
    `;
  }

  renderPatientColumn() {
    return isEraOrEOB(this.origin) || (this.patient && this.patient.name);
  }

  __renderFormManualPostCharges() {
    return html`
      <neb-form-manual-post
        id="${ELEMENTS.formAllocate.id}"
        name="items"
        emptyMessage="${'No charges'}"
        .model="${this.itemsForAllocation}"
        .menuItems="${this.itemsMap}"
        .paymentDetail="${this.paymentDetail}"
        .payer="${this.model.payer}"
        .onCancel="${this.handlers.cancel}"
        .onManualPost="${this.onManualPost}"
      ></neb-form-manual-post>
    `;
  }

  __renderItemTable() {
    return this.editMode === EDIT_MODE.TABLE
      ? html`
          <neb-table-charges-edit
            id="${ELEMENTS.tableEdit.id}"
            name="items"
            .hasMultiLocations="${this.hasMultiLocations}"
            .renderPatientColumn="${this.renderPatientColumn()}"
            ?isEraEob="${isEraOrEOB(this.origin)}"
            .model="${this.state.items}"
            .errors="${this.errors.items}"
            .initialState="${this.__initialState.items}"
            .hasErrors="${this.formService.hasErrors}"
            .itemsMap="${this.itemsMap}"
            .primaryInsuranceId="${this.getInsuranceId()}"
            .billType="${this.getBillType()}"
            .isCarePackageWithInsurance="${this.__isCarePackageWithInsurance()}"
            .onChange="${this.handlers.change}"
            .onChangeFeeSchedule="${this.handlers.changeFeeSchedule}"
            .onAdd="${this.handlers.addItem}"
            .onRemove="${this.handlers.removeItem}"
            .onBlur="${this.handlers.blur}"
            .onMinWidthUpdated="${this.handlers.setMinWidth}"
            .onSelectChargeNumber="${this.handlers.viewLedgerLineItem}"
            .onSelectEncounter="${this.handlers.viewEncounterSummary}"
            .onSelectPayerPlan="${this.handlers.viewPayerPlan}"
            .onClickPaymentId="${this.handlers.viewPayment}"
            .showERAsAndEOBsAssociations="${this.__showERAsAndEOBsAssociations}"
            .onClickERAsEOBs="${this.handlers.viewERAsEOBs}"
            .hasRCMChangeSecondary="${this.__hasRCMChangeSecondary}"
          ></neb-table-charges-edit>
        `
      : html`
          <neb-table-selected-line-items
            id="${ELEMENTS.tableDisplay.id}"
            .model="${this.displayItems}"
            .layout="${this.layout}"
            .hasMultiLocations="${this.hasMultiLocations}"
            .onChange="${this.handlers.toggleItem}"
            .onSelectEncounter="${this.handlers.viewEncounterSummary}"
            .onSelectChargeNumber="${this.handlers.viewLedgerLineItem}"
            .onSelectPatient="${this.handlers.navigateToPatientLedger}"
            .onSelectClaim="${this.handlers.viewClaim}"
            .onSelectInvoice="${this.handlers.viewInvoice}"
            .renderPatientColumn="${this.renderPatientColumn()}"
            .onMinWidthUpdated="${this.handlers.setMinWidth}"
            .onSort="${this.handlers.sort}"
            .sortParams="${[this.__sortParams]}"
            ?isEraEob="${isEraOrEOB(this.origin)}"
            ?disabled="${this.editMode === EDIT_MODE.HEADER}"
            preview
            .onBulkSelect="${this.handlers.selectBulkAction}"
            .onChangeClaimStatus="${this.handlers.bulkChangeClaimStatus}"
            .onOpenClaimBatchesOverlay="${
              this.handlers.openClaimBatchesOverlay
            }"
            .onSelectAll="${this.handlers.selectAll}"
            .toggleHeaderCheckbox="${this.__toggleHeaderCheckbox}"
            .onSaveAndSubmit="${this.handlers.saveAndSubmit}"
            .onClickPaymentId="${this.handlers.viewPayment}"
            .onUpdateBillingNotes="${this.handlers.updateBillingNotes}"
            .paymentLinks="${this.paymentLinks}"
            .showERAsAndEOBsAssociations="${this.__showERAsAndEOBsAssociations}"
            .onClickERAsEOBs="${this.handlers.viewERAsEOBsDisplayTable}"
            .hasRcmRecoupsFeatureFlag="${this.__hasRcmRecoupsFeatureFlag}"
          ></neb-table-selected-line-items>
        `;
  }

  renderActionBar() {
    return this.editMode === EDIT_MODE.TABLE
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            class="action-bar"
            .confirmLabel="${this.confirmLabel}"
            .cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  render() {
    return html`
      <div class="container-form">
        <div class="content-form">
          <div class="layout">${this.__renderChargesTab()}</div>
        </div>
        ${this.renderFooter()}
      </div>
    `;
  }
}

customElements.define('neb-form-charges-management', NebFormChargesManagement);
