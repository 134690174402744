import { css, html, LitElement } from 'lit';

import '../../../packages/neb-lit-components/src/components/neb-profile-photo';
import '../../../packages/neb-lit-components/src/components/neb-text';
import { hidePropIfEncounterOnly } from '../../../packages/neb-lit-components/src/utils/encounter-only-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { getPhotoModel } from '../../../packages/neb-utils/patient';
import {
  baseStyles,
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_BORDER_GREY_2,
  CSS_COLOR_BLACK,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_COLOR_YELLOW,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
  CSS_SPACING_ROW_LARGE,
} from '../../styles';
import { getRTEStatusIcon } from '../../utils/real-time-eligibility';
import '../misc/neb-icon';

export const ELEMENTS = {
  encounterNumber: { id: 'encounter-number' },
  icon: { id: 'icon' },
  iconConfirmedAt: { id: 'icon-confirmed' },
  iconLoop: { id: 'icon-loop' },
  iconRTEStatus: { id: 'icon-rte-status' },
  iconStatus: { id: 'icon-status' },
  labelDuration: { id: 'label-duration' },
  markerColor: { id: 'marker-color' },
  providerName: { id: 'provider-name' },
  resource: { id: 'resource' },
  resourcePlaceholder: { id: 'resource-spacer' },
  room: { id: 'room' },
  roomColor: { id: 'room-color' },
  serviceDate: { id: 'service-date' },
  serviceTime: { id: 'service-time' },
  serviceType: { id: 'service-type' },
  title: { id: 'title' },
  profilePhoto: { id: 'profile-photo' },
  patientLabel: { id: 'patient-label' },
  location: { id: 'location' },
  appointmentType: { id: 'appointment-type' },
};

const contrastTextColor = backGroundColor => {
  const R = parseInt(backGroundColor.slice(1, 3), 16);
  const G = parseInt(backGroundColor.slice(3, 5), 16);
  const B = parseInt(backGroundColor.slice(5, 7), 16);
  const luma = (0.299 * R + 0.587 * G + 0.114 * B) / 255;
  return luma > 0.5 ? CSS_COLOR_BLACK : CSS_COLOR_WHITE;
};

class NebEventCard extends LitElement {
  static get properties() {
    return {
      arrived: {
        type: Boolean,
        reflect: true,
      },
      hideProvider: {
        type: Boolean,
        reflect: true,
      },
      selected: {
        type: Boolean,
        reflect: true,
      },
      layout: {
        type: String,
        reflect: true,
      },
      contextOpen: Boolean,
      model: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          cursor: pointer;
          display: block;
          border-bottom: ${CSS_BORDER_GREY_2};
          width: 100%;
        }

        :host([selected]) {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_WHITE};
        }

        :host(:not([selected])):hover {
          background-color: ${CSS_COLOR_GREY_4};
        }

        .container {
          display: grid;
          grid-template-columns: auto 1fr;
        }

        .marker {
          display: block;
          width: 20px;
        }

        :host([layout='small']) .marker {
          width: 12px;
        }

        .location {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        .provider-placeholder {
          color: ${CSS_COLOR_GREY_1};
          font-style: italic;
        }

        .room {
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-grid;
          border-radius: 10px;
          padding: 0 10px;
        }

        .room-placeholder {
          color: ${CSS_COLOR_GREY_1};
          font-style: italic;
        }

        :host([selected]) .room-placeholder {
          color: ${CSS_COLOR_WHITE};
          font-style: italic;
        }

        .blocked-off-time {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-template-rows: 64px;
          padding: ${CSS_SPACING};
          align-items: center;
        }

        .blocked-off {
          font-size: 18px;
        }

        .event {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-template-rows: auto ${CSS_SPACING};
          grid-row-gap: ${CSS_SPACING_ROW_LARGE};
          padding: ${CSS_SPACING} 12px;
          align-items: center;
          white-space: nowrap;
        }

        :host([arrived]) .container {
          font-style: italic;
        }

        .info {
          display: grid;
          grid-template-columns: 1fr minmax(50px, 1fr);
        }

        .time-and-profile {
          display: grid;
          grid-template-columns: minmax(65px, 1fr) auto;
          align-items: center;
        }

        .time-info {
          margin-right: 5px;
        }

        .appointment-info {
          display: grid;
          align-self: center;
        }

        .overflow-ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .photo {
          width: 64px;
          height: 64px;
          margin-right: 10px;
          font-size: 2.25rem;
        }

        :host([selected]) .photo {
          color: ${CSS_COLOR_WHITE};
          fill: ${CSS_COLOR_WHITE};
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .icon-group {
          display: grid;
          width: 60px;
          grid-auto-rows: min-content;
          grid-gap: {CSS_SPACING_ROW_LARGE};
          grid-template-columns: 1fr 1fr;
          align-items: center;
          justify-self: end;
        }

        .icon {
          display: flex;
          align-self: center;
          width: 24px;
          height: 24px;

          fill: ${CSS_COLOR_BLACK};
        }

        .icon-rte-status {
          display: flex;
          align-self: center;
          width: 20px;
          height: 20px;

          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        .icon-warning {
          fill: ${CSS_COLOR_YELLOW};
        }

        .icon-validated,
        .icon-partially-validated {
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }

        :host([selected]) .icon {
          fill: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.contextOpen = false;
    this.arrived = false;
    this.event = false;
    this.hideProvider = false;
    this.selected = false;
    this.layout = '';
    this.model = {};

    this.onDisplay = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      display: () => this.onDisplay(this.model),
    };
  }

  __getPatientName({ patient, firstName, lastName }) {
    this.model = {
      ...this.model,
      patient: {
        ...this.model.patient,
        name: patient.name || {
          first: firstName,
          last: lastName,
          middle: null,
        },
      },
    };

    return this.model.patient.name;
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.arrived = !!this.model.arrivedAt && this.model.status === 'Active';
    }
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    if (this.contextOpen) {
      window.dispatchEvent(
        new CustomEvent('neb-context-menu-close', {
          bubbles: true,
          composed: true,
        }),
      );
    }
    super.disconnectedCallback();
  }

  __renderIconConfirmedAt() {
    return this.model.confirmedAt
      ? html`
          <neb-icon
            id="${ELEMENTS.iconConfirmedAt.id}"
            class="icon"
            icon="neb:confirmed"
          ></neb-icon>
        `
      : '';
  }

  __renderIconLoop() {
    return this.model.recurrenceEventId
      ? html`
          <neb-icon
            id="${ELEMENTS.iconLoop.id}"
            class="icon icon-loop"
            icon="neb:loop"
          ></neb-icon>
        `
      : '';
  }

  __renderIconRTEStatus() {
    const { realTimeEligibilityStatus } = this.model;

    return realTimeEligibilityStatus
      ? html`
          <neb-icon
            id="${ELEMENTS.iconRTEStatus.id}"
            class="icon-rte-status icon-${realTimeEligibilityStatus}"
            .icon="${getRTEStatusIcon(realTimeEligibilityStatus)}"
          ></neb-icon>
        `
      : html``;
  }

  __renderIconSigned() {
    return this.model.encounterNumber
      ? html`
          <neb-icon
            id="${ELEMENTS.icon.id}"
            class="icon icon-signed"
            .icon="${this.model.signed ? 'neb:signed' : 'neb:unsigned'}"
          ></neb-icon>
        `
      : '';
  }

  // eslint-disable-next-line complexity
  __renderIconStatus() {
    const { status, arrivedAt, isRescheduled } = this.model;

    let icon;

    switch (status) {
      case 'Checked-In':
        icon = 'neb:checkedIn';
        break;

      case 'Checked-Out':
        icon = 'neb:checkedOut';
        break;

      case 'Active':
        icon = arrivedAt ? 'neb:arrived' : '';
        break;

      case 'Canceled':
        icon = 'neb:canceled';
        break;

      default:
        icon = isRescheduled ? 'neb:rescheduled' : '';
    }

    return icon
      ? html`
          <neb-icon
            id="${ELEMENTS.iconStatus.id}"
            class="icon icon-status"
            .icon="${icon}"
          ></neb-icon>
        `
      : '';
  }

  __renderTimeInfo() {
    return html`
      <div class="time-info">
        <div id="${ELEMENTS.serviceTime.id}" class="bold">
          ${hidePropIfEncounterOnly(this.model, 'formattedServiceTime')}
        </div>

        <div id="${ELEMENTS.labelDuration.id}" class="overflow-ellipsis">
          ${hidePropIfEncounterOnly(this.model, 'duration')}
        </div>
      </div>
    `;
  }

  __renderBlockedOffTimeCard() {
    return html`
      <div class="event">
        <div class="info">
          <div class="time-and-profile">
            ${this.__renderTimeInfo()}

            <div class="photo"></div>
          </div>

          <div class="appointment-info">
            <div class="blocked-off bold">Blocked-Off Time</div>

            <div></div>
          </div>
        </div>

        <div class="icon-group"></div>
      </div>
    `;
  }

  __renderResource() {
    if (this.model.splits && this.model.splits.length > 1) {
      return html`
        <div id="${ELEMENTS.resource.id}" class="overflow-ellipsis">
          Split: ${this.model.splits.length} Rooms
        </div>
      `;
    }

    return this.model.resource
      ? html`
          <div id="${ELEMENTS.resource.id}" class="overflow-ellipsis">
            ${this.model.resource.name}
          </div>
        `
      : html`
          <div
            id="${ELEMENTS.resourcePlaceholder.id}"
            class="room-placeholder overflow-ellipsis"
          >
            No Room Scheduled
          </div>
        `;
  }

  __renderRoom() {
    const { room, status } = this.model;
    return room && status === 'Checked-In'
      ? html`
          <div class="overflow-ellipsis">
            <div
              id="${ELEMENTS.roomColor.id}"
              class="room"
              style="background-color: ${room.color}"
            >
              <span
                id="${ELEMENTS.room.id}"
                class="overflow-ellipsis"
                style="color: ${contrastTextColor(room.color)}"
                >${room.name}</span
              >
            </div>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <div
          id="${ELEMENTS.markerColor.id}"
          class="marker"
          style="background-color: ${this.model.markerColor};"
        ></div>

        ${
          this.model.groupId
            ? this.__renderBlockedOffTimeCard()
            : html`
                <div class="event">
                  <div class="info">
                    <div class="time-and-profile">
                      ${this.__renderTimeInfo()}

                      <neb-profile-photo
                        id="${ELEMENTS.profilePhoto.id}"
                        class="photo"
                        .model="${getPhotoModel(this.model.patient)}"
                        .selected="${this.selected}"
                        .onDisplay="${this.__handlers.display}"
                      ></neb-profile-photo>
                    </div>

                    <div class="appointment-info overflow-ellipsis">
                      <div
                        id="${ELEMENTS.patientLabel.id}"
                        class="bold overflow-ellipsis"
                      >
                        ${
                          objToName(
                            this.__getPatientName(this.model),
                            DEFAULT_NAME_OPTS,
                          )
                        }
                      </div>

                      <div
                        id="${ELEMENTS.appointmentType.id}"
                        class="overflow-ellipsis"
                      >
                        ${
                          hidePropIfEncounterOnly(this.model, 'appointmentType')
                        }
                      </div>

                      ${
                        this.hideProvider
                          ? ''
                          : html`
                              <div
                                id="${ELEMENTS.providerName.id}"
                                class="overflow-ellipsis"
                              >
                                ${
                                  this.model.provider ===
                                    'No Provider Scheduled' ||
                                  !this.model.provider
                                    ? html`
                                        <span class="provider-placeholder"
                                          >${
                                            this.model.provider ||
                                              'No Provider Scheduled'
                                          }</span
                                        >
                                      `
                                    : this.model.provider
                                }
                              </div>
                            `
                      }
                      ${this.__renderResource()}
                    </div>
                  </div>

                  <div class="icon-group">
                    ${this.__renderIconRTEStatus()} ${this.__renderIconStatus()}
                    ${this.__renderIconLoop()} ${this.__renderIconSigned()}
                    ${this.__renderIconConfirmedAt()}
                  </div>

                  <div
                    id="${ELEMENTS.location.id}"
                    class="location overflow-ellipsis"
                  >
                    <div class="overflow-ellipsis">
                      ${this.model.locationName}
                    </div>

                    ${this.__renderRoom()}
                  </div>
                </div>
              `
        }
      </div>
    `;
  }
}

customElements.define('neb-event-card', NebEventCard);
