import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../packages/neb-utils/feature-util';

export const apiClient = new ApiClient({ microservice: 'api' });
export const coreApiClient = new ApiClientV2({ microservice: 'core' });

const baseUrl = 'tags';

export const fetchMany = async (optOutLoadingIndicator = false) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const res = await coreApiClient.makeRequest({
      headers: {
        'Content-Type': 'application/json',
      },
      path: '/api/v1/tenants/:tenantId/tags',
      method: Method.GET,
      optOutLoadingIndicator,
    });

    return res.data;
  }

  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    path: baseUrl,
    method: Method.GET,
    optOutLoadingIndicator,
  });

  return res.data;
};

export const save = async tag => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      body: JSON.stringify(tag),
      headers: {
        'Content-Type': 'application/json',
      },
      path: '/api/v1/tenants/:tenantId/tags',
      method: Method.POST,
    });
  }

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(tag),
    headers: {
      'Content-Type': 'application/json',
    },
    path: baseUrl,
    method: Method.POST,
  });
};

export const update = async tag => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      body: JSON.stringify(tag),
      headers: {
        'Content-Type': 'application/json',
      },
      path: '/api/v1/tenants/:tenantId/tags/:tagId',
      replacements: { tagId: tag.id },
      method: Method.PUT,
    });
  }

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(tag),
    headers: {
      'Content-Type': 'application/json',
    },
    path: `${baseUrl}/${tag.id}`,
    method: Method.PUT,
  });
};

export const remove = async id => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      headers: {
        'Content-Type': 'application/json',
      },
      path: '/api/v1/tenants/:tenantId/tags/:tagId',
      replacements: { tagId: id },
      method: Method.DELETE,
    });
  }

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `${baseUrl}/${id}`,
    method: Method.DELETE,
  });
};

export const replace = async (oldId, newId) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      headers: {
        'Content-Type': 'application/json',
      },
      path: '/api/v1/tenants/:tenantId/tags/:oldId/replace-tag/:newId',
      replacements: { oldId, newId },
      method: Method.PUT,
    });
  }

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `${baseUrl}/${oldId}/replace-tag/${newId}`,
    method: Method.PUT,
  });
};
