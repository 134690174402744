/* eslint-disable complexity */
import '../misc/neb-insurance-header';

import { atMin } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import {
  createAndAddInvoiceToLineItems,
  getInvoicesWithMatchingHeader,
} from '../../../packages/neb-api-client/src/invoice-api-client';
import {
  addLineItemsToInvoice,
  removeLineItemsFromInvoice,
} from '../../../packages/neb-api-client/src/ledger/invoices';
import {
  buildPatientPackageAssociations,
  voidLineItems,
} from '../../../packages/neb-api-client/src/ledger/line-items';
import { fetchOne } from '../../../packages/neb-api-client/src/patient-cases';
import { getPatientGuarantors } from '../../../packages/neb-api-client/src/patient-guarantor-api-client';
import {
  PRACTICE_SETTINGS,
  getPracticeSettings,
} from '../../../packages/neb-api-client/src/services/practice-settings';
import { openOverlayPatientInsuranceView } from '../../../packages/neb-app-layout/neb-open-overlay';
import { OPEN_SUCCESS } from '../../../packages/neb-dialog/neb-banner-state';
import { NebModifiers } from '../../../packages/neb-lit-components/src/components/field-groups/neb-modifiers';
import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { openDirtyDialog } from '../../../packages/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import {
  CSS_COLOR_GREY_4,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../packages/neb-styles/neb-variables';
import {
  CODE_PAYMENTS,
  CODE_WRITE_OFFS,
} from '../../../packages/neb-utils/constants';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  centsToCurrency,
  currencyToCents,
  objToName,
  DEFAULT_NAME_OPTS,
  currencyToCentsWithNegative,
  centsToCurrencyWithNegative,
} from '../../../packages/neb-utils/formatters';
import * as masks from '../../../packages/neb-utils/masks';
import { currency, number } from '../../../packages/neb-utils/masks';
import {
  BILL_TYPE,
  changeFeeScheduleCalculations,
  formatTaxRate,
  ITEM_NONE,
  lineItemHasPackageCreditsOrAdjustments,
  NDCPopupStateValidator,
  supplementalInfoPopupStateValidator,
  orderingProviderInfoPopupStateValidator,
  owedGrandTotalValidator,
  validateAdjustmentCodes,
  validateAllowedAgainstBilled,
  validateAllowedAgainstTotalOwed,
  validatePatientOwedTypes,
  getLidPaymentIds,
} from '../../../packages/neb-utils/neb-ledger-util';
import {
  hasAuthorizationRemaining,
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
} from '../../../packages/neb-utils/patientAuthorization';
import {
  numeric,
  currency as currencySelector,
} from '../../../packages/neb-utils/selectors';
import { deepCopy } from '../../../packages/neb-utils/utils';
import { autoAllocatePayments } from '../../api-clients/auto-allocation';
import { formatGradualDate } from '../../features/charting/neb-charting-encounter-header-util';
import {
  BILLED_STATUS,
  EPSDT,
  formatEncounterChargeDetails,
  HOLD_STATUS,
} from '../../formatters/encounter-charge-details';
import {
  openError,
  openInfo,
  openSuccess,
  openWarning,
} from '../../store/index';
import {
  CSS_BORDER_GREY_2,
  CSS_COLOR_ERROR,
  CSS_FONT_WEIGHT_BOLD,
  CSS_ERROR_BACKGROUND_COLOR,
  CSS_BANNER_ERROR_BORDER_COLOR,
  CSS_COLOR_HIGHLIGHT,
} from '../../styles';
import { ADD_ONS, hasAddOn } from '../../utils/add-ons';
import { getAutoAllocationBanner } from '../../utils/auto-allocation';
import { fetchBillingHeaderInfo } from '../../utils/billing-header-form/neb-billing-header-form-util';
import { ChargeBalancer } from '../../utils/charge-balancer';
import {
  formatDiagnoses,
  getDiagnosisItemHeight,
  ITEM_MIN_WIDTH,
} from '../../utils/diagnoses';
import {
  getPrimaryPayerLineItemDebits,
  isSecondaryRespLineItemDebit,
  isPatientRespLineItemDebit,
  isPrimaryPayerLineItemDebit,
  getSecondaryPayerLineItemDebits,
  getPatientLineItemDebits,
  getPrimaryOwedTotal,
  getSecondaryOwedTotal,
  getPatientOwedTotal,
  calculateAdjustmentsSum,
} from '../../utils/ledger-form/neb-ledger-form-util';
import { ERROR_AUTO_ALLOCATE_PAYMENT } from '../../utils/user-message';

import '../misc/neb-encounter-charge-details';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../misc/neb-billing-header';
import { FEE_SCHEDULE_FF_CONFIRM_MESSAGE } from './charges/neb-form-charges-management';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  insuranceHeader: { id: 'insurance-header' },
  billedAmountLabel: { id: 'billed-amount-label' },
  billedAmount: { id: 'billed-amount' },
  allowedAmountLabel: { id: 'allowed-amount-label' },
  allowedAmount: { id: 'allowed-amount', tag: 'neb-textfield' },
  primaryOwedAmount: { id: 'primaryOwed-amount' },
  primaryOwedLabel: { id: 'primaryOwed-label' },
  primaryPaidLabel: { id: 'primaryPaid-label' },
  primaryPaidAmount: { id: 'primaryPaid-amount' },
  primaryPaymentsLabel: { id: 'primaryPayments-label' },
  primaryPaymentsIds: { id: 'primaryPayments-ids' },
  primaryPaymentLinks: {
    selector: '#primaryPayments-ids > div > neb-text[link]',
    tag: 'neb-text',
  },
  paymentNumber: {
    id: 'payment-number',
    tag: 'neb-text',
  },
  secondaryPaidLabel: { id: 'secondaryPaid-label' },
  secondaryPaidAmount: { id: 'secondaryPaid-amount' },
  secondaryPaymentsLabel: { id: 'secondaryPayments-label' },
  secondaryPaymentsIds: { id: 'secondaryPayments-ids' },
  secondaryPaymentLinks: {
    selector: '#secondaryPayments-ids > div > neb-text[link]',
    tag: 'neb-text',
  },
  secondaryOwedAmount: { id: 'secondaryOwed-amount' },
  secondaryOwedLabel: { id: 'secondaryOwed-label' },
  patientOwedAmount: { id: 'patientOwed-amount', tag: 'neb-textfield' },
  patientOwedLabel: { id: 'patientOwed-label' },
  patientPaidLabel: { id: 'patientPaid-label' },
  patientPaidAmount: { id: 'patientPaid-amount' },
  patientPaymentsLabel: { id: 'patientPayments-label' },
  patientPaymentsIds: { id: 'patientPayments-ids' },
  patientPaymentLinks: {
    selector: '#patientPayments-ids > div > neb-text[link]',
    tag: 'neb-text',
  },
  adjustmentAmount: { id: 'adjustment-amount', tag: 'neb-textfield' },
  adjustmentLabel: { id: 'adjustment-label' },
  modifiersLabel: { id: 'modifiers-label' },
  modifiers: { id: 'modifiers', tag: 'neb-modifiers' },
  displayedErrors: { id: 'displayed-errors' },
  billingHeader: { id: 'billing-header' },
  dateOfServiceLabel: { id: 'date-of-service-label' },
  dateOfService: { id: 'date-of-service' },
  codeLabel: { id: 'code-label' },
  code: { id: 'code' },
  descriptionLabel: { id: 'description-label' },
  description: { id: 'description' },
  providerLabel: { id: 'provider-label' },
  provider: { id: 'provider' },
  unitChargeLabel: { id: 'unit-charge-label' },
  unitCharge: { id: 'unit-charge' },
  taxLabel: { id: 'tax-label' },
  tax: { id: 'tax' },
  diagnoses: { id: 'diagnoses' },
  diagnosesLabel: { id: 'diagnoses-label' },
  units: { id: 'units' },
  unitsLabel: { id: 'units-label' },
  feeScheduleChargeAmount: { id: 'fee-schedule-charge-amount' },
  feeScheduleChargeAmountLabel: { id: 'fee-schedule-charge-amount-label' },
  feeSchedule: { id: 'fee-schedule' },
  feeScheduleLabel: { id: 'fee-schedule-label' },
  taxRate: { id: 'tax-rate' },
  taxRateLabel: { id: 'tax-rate-label' },
  textNoEncounterDx: { id: 'text-no-encounter-dx' },
  encounterChargeDetails: {
    id: 'encounter-charge-details',
    tag: 'neb-encounter-charge-details',
  },
  erasEobsLabel: { id: 'eras-eobs-label' },
  erasEobsLink: { id: 'eras-eobs' },
  recoupedIndicators: { selector: '[id^=recouped-indicator-]' },
};

const MODIFIERS_ERROR_MESSAGE = 'Modifiers must be two characters';
const PAYMENT_RESPONSIBILITY = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  PATIENT: 'Patient',
};

export class NebFormLineItem extends NebForm {
  static get properties() {
    return {
      payerInformation: Object,
      providerInformation: Object,
      taxRates: Array,
      feeSchedules: Array,
      claims: Array,
      locations: Array,
      authorizations: Array,
      encounterChargeDetails: Object,
      codePayments: Array,
      codeWriteOffs: Array,
      patientInsurances: Array,
      isCarePackageWithInsurance: Boolean,
      multiCarePackageEnabled: Boolean,
      lineItems: Array,
      showERAsAndEOBsAssociations: Boolean,
      associatedERAsAndEOBs: Array,
      settingsCharge: Object,
      hasAutoCalculateAllowedAmountForCarePackageFeatureFlag: Boolean,
      hasRCMChangeSecondary: Boolean,
      hasRcmRecoupsFeatureFlag: Boolean,

      __feeSchedules: Array,
      __diagnosisItems: Array,
      __hasFifo: Boolean,
      __hasAutoPostCharges: Boolean,
      __authorizationWarning: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .layout {
          display: flex;
          flex-direction: column;
          gap: ${CSS_SPACING};
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_4};
        }

        .header-insurance {
          background-color: ${CSS_COLOR_WHITE};
        }

        .billed-field {
          display: flex;
          flex-direction: column;
        }

        .error-area {
          color: ${CSS_COLOR_ERROR};
          border-top: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
          font-weight: bold;
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
        }

        .top-fields {
          display: flex;
          gap: ${CSS_SPACING};
          justify-content: space-between;
        }

        .billing-container {
          display: flex;
          flex-direction: column;
          gap: ${CSS_SPACING};
          border: ${CSS_BORDER_GREY_2};
          padding: ${CSS_SPACING};
          border-radius: 5px;
        }

        .billing-header {
          background-color: ${CSS_COLOR_WHITE};
        }

        .billing-info-fields {
          display: flex;
          justify-content: space-between;
        }

        .billing-info-field {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
        }

        .billing-info-icon {
          display: flex;
          flex: 0 0 0;
          align-items: center;
          margin-right: ${CSS_SPACING};
        }

        .era-eob-link,
        .payment-id-link {
          display: flex;
          margin-top: 10px;
          width: max-content;
          padding-bottom: 10px;
          padding-right: 5px;
        }

        .label {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          white-space: nowrap;
        }

        .plain-text-value {
          margin-top: 10px;
        }

        .charge-details-container {
          border: ${CSS_BORDER_GREY_2};
          padding: ${CSS_SPACING} ${CSS_SPACING} 5px ${CSS_SPACING};
          border-radius: 5px;
          background-color: ${CSS_COLOR_WHITE};
        }

        .charge-info-fields {
          display: flex;
          gap: 10px;
        }

        .charge-info-fields > div:nth-child(1) {
          flex: 1;
          min-width: 110px;
        }

        .charge-info-fields > div:nth-child(2) {
          flex: 1;
          min-width: 90px;
        }

        .charge-info-fields > div:nth-child(3) {
          flex: 2;
          min-width: 150px;
        }

        .charge-info-fields > div:nth-child(4) {
          flex: 2;
          min-width: 210px;
        }

        .charge-info-fields > div:nth-child(5) {
          flex: 2;
          min-width: 210px;
          display: flex;
          gap: 10px;
        }

        .charge-info-fields > div:nth-child(6) {
          flex: 2;
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }

        .fee-schedule-select {
          width: 210px;
        }

        .charge-modifiers {
          position: relative;
          top: -16px;
        }

        .ellipse-field {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .responsibilities-container {
          border: ${CSS_BORDER_GREY_2};
          padding: ${CSS_SPACING} ${CSS_SPACING} 5px ${CSS_SPACING};
          border-radius: 5px;
          background-color: ${CSS_COLOR_WHITE};
        }

        .responsibilities-fields {
          display: flex;
          justify-content: space-between;
          margin: 0px 20px;
        }

        .units-field {
          width: 70px;
        }

        .fee-sch-chrg-field {
          width: 120px;
        }

        .allowed-field {
          width: 120px;
        }

        .encounter-charge-details {
          background-color: ${CSS_COLOR_WHITE};
        }

        .payment-link {
          display: flex;
          align-items: center;
        }
        .recouped-icon {
          width: 16px;
          height: 16px;
          stroke-width: 1;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 2px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      EPSDTCode: false,
      EPSDTType: null,
      adjustments: [],
      allowedAmount: 0,
      billType: null,
      billedAmount: 0,
      billedWaiting: false,
      billedPatient: false,
      billedPrimary: false,
      billedSecondary: false,
      billedPaymentReceived: false,
      billedPaidInFull: false,
      billingNotes: [],
      chargeNumber: null,
      code: '',
      codeQualifier: null,
      codeType: null,
      dateOfService: null,
      description: '',
      encounterCharge: {
        diagnosisPointers: [],
        diagnoses: [],
        encounterNumber: null,
        feeScheduleName: '',
        suppressFromClaim: false,
      },
      feeScheduleCharge: 0,
      feeScheduleId: '',
      guarantorId: null,
      holdClaim: false,
      holdStatement: false,
      holdSuperBill: false,
      id: '',
      identificationNumber: null,
      invoiceId: null,
      invoiceNumber: null,
      lineItemDebits: [],
      modifier_1: null,
      modifier_2: null,
      modifier_3: null,
      modifier_4: null,
      modifiers: NebModifiers.createModel(),
      nationalDrugCode: null,
      nationalDrugCodeDosage: null,
      nationalDrugCodeNumberCategory: null,
      nationalDrugCodeQualifier: null,
      nationalDrugCodeSequenceOrPrescription: null,
      nationalDrugCodeUnitOfMeasurement: null,
      ndc: false,
      patientCaseId: null,
      patientId: '',
      patientPackageDeduct: 0,
      patientPackageId: null,
      pos: '',
      postedById: '',
      primaryInsuranceId: null,
      primaryPayerId: '',
      purchase: null,
      reportTransmissionCode: null,
      reportTypeCode: null,
      secondaryInsuranceId: null,
      shaded24: null,
      supplementalInformation: false,
      orderingProvider: false,
      orderingProviderCredentials: '',
      orderingProviderFirstName: '',
      orderingProviderLastName: '',
      orderingProviderMiddleName: '',
      orderingProviderNPI: '',
      orderingProviderOtherId: '',
      orderingProviderQualifier: '',
      taxAmount: 0,
      taxRate: 0,
      taxName: '',
      tertiaryInsuranceId: null,
      type: '',
      unitCharge: 0,
      units: 1,
      voidedAt: null,
      voidedById: null,
    };
  }

  initState() {
    super.initState();

    this.payerInformation = {};
    this.providerInformation = null;
    this.taxRates = [];
    this.feeSchedules = [];
    this.claims = [];
    this.locations = [];
    this.authorizations = [];
    this.encounterChargeDetails = {};
    this.selectedHoldStatuses = [];
    this.selectedBilledStatuses = [];
    this.shaded24 = '';
    this.epsdtValue = '';
    this.codePayments = [];
    this.codeWriteOffs = [];
    this.patientInsurances = [];
    this.isCarePackageWithInsurance = false;
    this.multiCarePackageEnabled = false;
    this.lineItems = [];
    this.showERAsAndEOBsAssociations = false;
    this.associatedERAsAndEOBs = [];
    this.settingsCharge = {};
    this.hasAutoCalculateAllowedAmountForCarePackageFeatureFlag = false;
    this.hasRCMChangeSecondary = false;
    this.hasRcmRecoupsFeatureFlag = false;

    this.__feeSchedules = [];
    this.__diagnosisItems = [];
    this.__hasFifo = false;
    this.__hasAutoPostCharges = false;
    this.__authorizationWarning = false;
    this.__previousPatientOwedAmount = 0;
    this.__previousPatientOwedAmountPopup = [];

    this.onRefresh = () => {};

    this.onUnpost = () => {};

    this.onOpenPaymentDetailOverlay = () => {};

    this.onOpenInvoiceOverlay = () => {};

    this.onOpenEncounterOverlay = () => {};

    this.onOpenClaimOverlay = () => {};

    this.onUpdateBillingHeader = () => {};

    this.onClickERAsEOBs = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeUnits: async e => {
        if (e.event === 'blur') {
          this.formService.apply('units', e.value);

          await this.__recalculateCarePackageUnitsOrTaxRate();

          this.formService.validate();
        } else {
          this.formService.apply('units', e.value);
          this.__calculateBilledInfo();
          this.formService.validate();
        }
      },
      changeFeeScheduleCharge: e => {
        this.formService.apply('feeScheduleCharge', e.value);
        this.__calculateBilledInfo();
        this.formService.validate();
      },
      changeAllowedAmount: e => {
        this.formService.apply('allowedAmount', e.value);
        this.__balancer.allowedAmount = currencyToCentsWithNegative(e.value);

        if (
          !lineItemHasPackageCreditsOrAdjustments(this.state) ||
          this.isCarePackageWithInsurance
        ) {
          this.__updateAdjustmentAmount(this.__balancer.adjustmentAmount);
        }
        this.formService.validate();
      },
      changeModifiers: e => {
        this.formService.apply(e.name, e.value);
        this.formService.validate();
      },
      changePrimaryOwed: e => {
        if (this.payerInformation.billType !== 'Insurance') {
          return;
        }

        if (e.event === 'blur') {
          let primaryDebitIndex = 0;

          const amount = currencyToCents(e.value);

          primaryDebitIndex = this.state.lineItemDebits.findIndex(item =>
            isPrimaryPayerLineItemDebit(item, this.state),
          );

          if (primaryDebitIndex === -1) {
            this.formService.addItem('lineItemDebits');
            primaryDebitIndex = this.state.lineItemDebits.length - 1;
          }

          if (!this.state.lineItemDebits[primaryDebitIndex].id && !amount) {
            this.formService.removeItem('lineItemDebits', primaryDebitIndex);
          } else {
            this.formService.apply(
              `lineItemDebits.${primaryDebitIndex}.debit.amount`,
              amount,
            );

            this.formService.apply(
              `lineItemDebits.${primaryDebitIndex}.debit.payerId`,
              this.state.primaryPayerId,
            );

            this.formService.apply(
              `lineItemDebits.${primaryDebitIndex}.patientInsuranceId`,
              this.state.primaryInsuranceId,
            );
          }

          this.formService.validate();
        }
      },
      openPatientOwedPopup: async () => {
        this.__previousPatientOwedAmountPopup = getPatientLineItemDebits(
          this.state,
        );

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Patient Responsibility',
            initialCodes: this.state.lineItemDebits.filter(lineItemDebit =>
              isPatientRespLineItemDebit(lineItemDebit),
            ),
            displayPaidAmount: true,
            paymentCodes: this.codePayments,
            isCarePackageWithInsurance: this.isCarePackageWithInsurance,
          },
        );

        if (result && result.popupChanged) {
          this.__deleteIndividualItems('lineItemDebits', result.itemsToDelete);
          this.__addIndividualItems('patientOwed', result.itemsToAdd);
          this.__editIndividualItems('lineItemDebits', result.itemsToEdit);

          if (this.__shouldRecalculateCarePackage()) {
            await this.__recalculateCarePackageOwedAmount();
            this.__updateAllowedAmount();
          }

          this.formService.validate();
        }

        setTimeout(() => {
          this.shadowRoot.getElementById(ELEMENTS.patientOwedAmount.id).focus();
        }, 150);
      },
      openAdjustmentsPopup: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Adjustments',
            initialCodes: this.state.adjustments.sort(
              (a, b) => a.order - b.order,
            ),
            displayPaidAmount: false,
            billType: this.state.billType,
            codeWriteOffs: this.codeWriteOffs,
            isCarePackageWithInsurance: this.isCarePackageWithInsurance,
          },
        );

        if (result) {
          this.__deleteIndividualItems('adjustments', result.itemsToDelete);
          this.__addIndividualItems('adjustments', result.itemsToAdd);
          this.__editIndividualItems('adjustments', result.itemsToEdit);
          this.__updateAllowedAmount();
          this.formService.validate();
        }

        setTimeout(() => {
          this.shadowRoot.getElementById(ELEMENTS.adjustmentAmount.id).focus();
        }, 150);
      },
      openSecondaryOwedPopup: async () => {
        if (!this.payerInformation.secondaryInsurance) {
          return;
        }

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Secondary Responsibility',
            initialCodes: getSecondaryPayerLineItemDebits(this.state),
            displayPaidAmount: true,
            paymentCodes: this.codePayments,
            isCarePackageWithInsurance: false,
          },
        );

        if (result) {
          this.__deleteIndividualItems('lineItemDebits', result.itemsToDelete);
          this.__addIndividualItems('secondaryOwed', result.itemsToAdd);
          this.__editIndividualItems('lineItemDebits', result.itemsToEdit);

          this.formService.validate();
        }

        setTimeout(() => {
          this.shadowRoot
            .getElementById(ELEMENTS.secondaryOwedAmount.id)
            .focus();
        }, 150);
      },
      changeTaxRate: async e => {
        if (e.event === 'select') {
          this.formService.apply('taxRate', e.value.data.rate);
          this.formService.apply('taxName', e.value.data.name);
          this.__calculateBilledInfo();

          if (this.isCarePackageWithInsurance) {
            this.__updateAllowedAmount();
          } else {
            await this.__recalculateCarePackageUnitsOrTaxRate();
          }
          this.formService.validate();
        }
      },
      changeDiagnoses: e => {
        if (e.event === 'select') {
          while (this.state.encounterCharge.diagnosisPointers.length) {
            this.formService.removeItem('encounterCharge.diagnosisPointers');
          }

          const diagnosesToAdd = e.value.map(({ data }) => ({
            diagnosisCode: data.code,
          }));

          diagnosesToAdd.forEach(({ diagnosisCode }, index) => {
            this.formService.addItem('encounterCharge.diagnosisPointers');
            this.formService.apply(
              `encounterCharge.diagnosisPointers.${index}.diagnosisCode`,
              diagnosisCode,
            );
          });
        }
      },
      changeFeeSchedule: async e => {
        if (
          e.event !== 'select' ||
          e.value.data.id === this.state.feeScheduleId ||
          this.state.billType !== BILL_TYPE.SELF
        ) {
          return;
        }

        if (
          this.state.lineItemDebits.some(
            lineItemDebit => lineItemDebit.debit.allocations.length,
          )
        ) {
          const result = await openPopup(POPUP_RENDER_KEYS.DIALOG, {
            title: 'Remove Allocation',
            message: [
              'The charge that you are changing the fee schedule for has a payment allocated.',
              'Changing the fee schedule will unallocate all payments for the charge.',
              'Are you sure that you want to change the fee schedule?',
            ],
            buttons: [
              { name: 'yes', label: 'Yes' },
              { name: 'no', label: 'No' },
            ],
          });

          if (result === 'no') {
            return;
          }
        }

        const res = await this.__updateFeeScheduleChargeFields(e);

        if (res) {
          const lineItem = {
            units: Number(this.state.units),
            unitCharge: this.state.unitCharge,
            taxRate: this.state.taxRate,
            chargeId: this.state.encounterCharge.chargeId,
          };

          const result = changeFeeScheduleCalculations({
            lineItem,
            feeSchedule: e.value.data,
          });

          this.__removeAllLineItemDebits();
          this.__removeAllAdjustments();

          this.formService.apply('feeScheduleId', e.value.data.id);

          this.formService.apply(
            'allowedAmount',
            centsToCurrency(result.allowedAmount),
          );

          this.formService.apply('billedAmount', result.billedAmount);

          this.formService.apply('taxAmount', result.taxAmount);

          this.formService.apply(
            'feeScheduleCharge',
            centsToCurrency(result.feeScheduleChargeAmount),
          );

          this.__addIndividualItems('patientOwed', result.patientOwed);
          this.__addIndividualItems('adjustments', result.adjustments);

          this.formService.validate();
        }
      },
      changeHoldStatus: value => {
        const selectedStatuses = value.map(status => status.value);

        this.formService.apply(
          'holdClaim',
          !!selectedStatuses.some(status => status === HOLD_STATUS.CLAIM),
        );

        this.formService.apply(
          'holdStatement',
          !!selectedStatuses.some(status => status === HOLD_STATUS.STATEMENT),
        );

        this.formService.apply(
          'holdSuperBill',
          !!selectedStatuses.some(status => status === HOLD_STATUS.SUPERBILL),
        );

        this.__formatEncounterChargeDetails();
      },
      changeBilledStatus: value => {
        const selectedStatuses = value.map(status => status.value);

        this.formService.apply(
          'billedWaiting',
          !!selectedStatuses.some(status => status === BILLED_STATUS.WAITING),
        );

        this.formService.apply(
          'billedPrimary',
          !!selectedStatuses.some(status => status === BILLED_STATUS.PRIMARY),
        );

        this.formService.apply(
          'billedSecondary',
          !!selectedStatuses.some(status => status === BILLED_STATUS.SECONDARY),
        );

        this.formService.apply(
          'billedPaymentReceived',
          !!selectedStatuses.some(status => status === BILLED_STATUS.RECEIVED),
        );

        this.formService.apply(
          'billedPaidInFull',
          !!selectedStatuses.some(status => status === BILLED_STATUS.PAID),
        );

        this.__formatEncounterChargeDetails();
      },
      changeFl24: value => {
        this.formService.apply('shaded24', value);
        this.__formatEncounterChargeDetails();
      },
      changeEpsdtType: value => {
        const epsdtValue = value.label !== EPSDT.NO_REASON ? value.label : null;

        this.formService.apply('EPSDTType', epsdtValue);
        this.__formatEncounterChargeDetails();
      },
      changeNDC: ({ value }) => {
        this.formService.apply('ndc', value);
        this.__formatEncounterChargeDetails();
      },
      changeNDCDetails: ({
        nationalDrugCode,
        nationalDrugCodeDosage,
        nationalDrugCodeNumberCategory,
        nationalDrugCodeQualifier,
        nationalDrugCodeSequenceOrPrescription,
        nationalDrugCodeUnitOfMeasurement,
      }) => {
        this.formService.apply('nationalDrugCode', nationalDrugCode);

        this.formService.apply(
          'nationalDrugCodeDosage',
          nationalDrugCodeDosage,
        );

        this.formService.apply(
          'nationalDrugCodeNumberCategory',
          nationalDrugCodeNumberCategory,
        );

        this.formService.apply(
          'nationalDrugCodeQualifier',
          nationalDrugCodeQualifier,
        );

        this.formService.apply(
          'nationalDrugCodeSequenceOrPrescription',
          nationalDrugCodeSequenceOrPrescription,
        );

        this.formService.apply(
          'nationalDrugCodeUnitOfMeasurement',
          nationalDrugCodeUnitOfMeasurement,
        );

        this.formService.validate();

        this.__formatEncounterChargeDetails();
      },
      changeBilledPatient: ({ value }) => {
        this.formService.apply('billedPatient', value);
        this.__formatEncounterChargeDetails();
      },
      addToInvoice: async () => {
        if (!(await this.__dirtyGuard())) {
          return;
        }

        const invoices = await getInvoicesWithMatchingHeader(
          this.state.patientId,
          [this.state.id],
        );

        const invoiceId = await openPopup(POPUP_RENDER_KEYS.ADD_TO_INVOICE, {
          invoices,
        });

        if (!invoiceId) {
          return;
        }

        const invoice = invoices.find(i => i.id === invoiceId);

        const addToInvoice = await this.__displayMultipleLocationPopup(invoice);

        if (addToInvoice) {
          try {
            await addLineItemsToInvoice({
              lineItemIds: [this.state.id],
              invoiceId,
              patientId: this.state.patientId,
            });

            this.onRefresh();

            openSuccess('Charge added successfully to Invoice');
          } catch (e) {
            console.error(e);

            openError('An error has occurred when adding charge to Invoice');
          }
        }
      },
      createInvoice: async () => {
        if (!(await this.__dirtyGuard())) {
          return;
        }

        try {
          await createAndAddInvoiceToLineItems(
            [this.model.id],
            this.model.patientId,
          );

          openSuccess('Invoice created successfully');

          this.onRefresh();
        } catch (e) {
          console.error(e);

          openError('An error has occurred when creating the invoice');
        }
      },
      removeFromInvoice: async () => {
        if (!(await this.__dirtyGuard())) {
          return;
        }

        try {
          await removeLineItemsFromInvoice(
            [this.model.id],
            [this.model.invoiceId],
          );

          openSuccess('Charge removed from invoice successfully');

          this.onRefresh();
        } catch (e) {
          console.error(e);

          openError('An error has occurred when removing charge from invoice');
        }
      },
      unpost: async () => {
        if (
          this.model.lineItemDebits.some(
            lineItemDebit => lineItemDebit.debit.allocations.length,
          )
        ) {
          const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Unpost Charge',
            message: html`
              <div>
                The charge that you are unposting has an allocated payment.
                Unposting the charge will remove all allocations and the charge
                will return to the encounter as Not Posted.
              </div>

              <br />
              <div>Are you sure you want to unpost the selected charge?</div>
            `,
            confirmText: 'Yes',
            cancelText: 'No',
          });

          if (!result) {
            return;
          }
        }

        try {
          await voidLineItems({
            lineItemIds: [this.model.id],
          });

          openSuccess('Charge unposted successfully');

          this.onUnpost();
        } catch (e) {
          console.error(e);

          openError('An error has occurred when unposting the charge');
        }
      },
      autoAllocatePayments: async () => {
        if (!(await this.__dirtyGuard())) {
          return;
        }

        try {
          const { data: allocations } = await autoAllocatePayments({
            lineItemIds: [this.model.id],
          });

          const returnBanner = getAutoAllocationBanner(allocations);

          if (returnBanner.type === OPEN_SUCCESS) {
            openSuccess(returnBanner.message);

            this.onRefresh();
          } else {
            openInfo(returnBanner.message);
          }
        } catch (e) {
          console.error(e);
          openError(ERROR_AUTO_ALLOCATE_PAYMENT);
        }
      },
      openPaymentDetailOverlay: async ({ name: paymentId }) => {
        if (await this.__dirtyGuard()) {
          this.onOpenPaymentDetailOverlay(paymentId);
        }
      },
      openInvoiceOverlay: async () => {
        if (await this.__dirtyGuard()) {
          this.onOpenInvoiceOverlay();
        }
      },
      openEncounterOverlay: async () => {
        if (await this.__dirtyGuard()) {
          this.onOpenEncounterOverlay();
        }
      },
      openClaimOverlay: async claimId => {
        if (await this.__dirtyGuard()) {
          this.onOpenClaimOverlay(claimId);
        }
      },
      openPayerOverlay: async () => {
        if (await this.__dirtyGuard()) {
          const updated = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
            id: this.payerInformation.payerId,
          });

          if (updated) {
            this.onRefresh();
          }
        }
      },
      openInsuranceOverlay: async id => {
        if (await this.__dirtyGuard()) {
          const updated = await openOverlayPatientInsuranceView({
            patientId: this.model.patientId,
            patientInsurance: { id },
          });

          if (updated) {
            this.onRefresh();
          }
        }
      },
      openCaseOverlay: async () => {
        if (await this.__dirtyGuard()) {
          const { patientId, patientCaseId } = this.model;

          const guarantors = await getPatientGuarantors(patientId);
          const caseItem = await fetchOne(patientId, patientCaseId, true);

          let authChanged = false;

          const updated = await openOverlay(OVERLAY_KEYS.CASE, {
            item: caseItem,
            context: {
              patientId,
              guarantors,
              onAuthorizationChange: () => {
                authChanged = true;
              },
            },
          });

          if (updated || authChanged) {
            authChanged = false;
            this.onRefresh();
          }
        }
      },
      openAuthorizationOverlay: async () => {
        if (await this.__dirtyGuard()) {
          const {
            patientAuthorizationId: id,
            patientId,
            patientCaseId,
          } = this.model;

          const updated = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
            id,
            patientId,
            patientCaseId,
          });

          if (updated) {
            this.onRefresh();
          }
        }
      },
      openCarePackageOverlay: async packageId => {
        if (await this.__dirtyGuard()) {
          await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, {
            item: {
              id: packageId,
              patientId: this.model.patientId,
            },
          });

          this.onRefresh();
        }
      },
      openCarePackagesSummaryOverlay: async () => {
        if (await this.__dirtyGuard()) {
          await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGES_SUMMARY, {
            patientId: this.model.patientId,
            lineItems: this.lineItems,
            lineItem: this.model,
          });

          this.onRefresh();
        }
      },
      changeAdjustments: e => {
        this.__updateAdjustmentAmount(currencyToCentsWithNegative(e.value));
        this.__updateAllowedAmount();
        this.formService.validate();
      },
      changePatientOwed: async e => {
        if (e.event === 'focus') {
          this.__previousPatientOwedAmount = e.value;
          return;
        }

        if (e.event === 'blur') {
          if (this.__previousPatientOwedAmount === e.value) {
            return;
          }
          const patientLineItemDebits = getPatientLineItemDebits(this.state);

          if (
            this.__responsibleHasMultiple('patientResponsibleDebits') &&
            !this.isCarePackageWithInsurance
          ) {
            return;
          }

          if (patientLineItemDebits.length && patientLineItemDebits[0].id) {
            const patientLineItemDebit = patientLineItemDebits[0];
            this.__editIndividualItems('lineItemDebits', [
              {
                individualRowId: patientLineItemDebit.id,
                codeId: patientLineItemDebit.codePaymentId,
                amount: currencyToCents(e.value),
              },
            ]);
          } else {
            const codeId = this.isCarePackageWithInsurance
              ? CODE_PAYMENTS.PACKAGE.id
              : CODE_PAYMENTS.GENERAL_PAYMENT.id;
            this.__addIndividualItems('patientOwed', [
              { codeId, amount: currencyToCents(e.value) },
            ]);
          }

          if (this.__shouldRecalculateCarePackage(e.value)) {
            await this.__recalculateCarePackageOwedAmount();
            this.__updateAllowedAmount();
          }

          this.formService.validate();
        }
      },
      changeSecondaryOwed: e => {
        if (e.event === 'blur') {
          const secondaryLineItemDebits = getSecondaryPayerLineItemDebits(
            this.state,
          );

          if (this.__responsibleHasMultiple('secondaryResponsibleDebits')) {
            return;
          }

          if (secondaryLineItemDebits.length && secondaryLineItemDebits[0].id) {
            const secondaryLineItemDebit = secondaryLineItemDebits[0];

            this.__editIndividualItems('lineItemDebits', [
              {
                individualRowId: secondaryLineItemDebit.id,
                codeId: secondaryLineItemDebit.codePaymentId,
                amount: currencyToCents(e.value),
              },
            ]);
          } else {
            const codeId = CODE_PAYMENTS['PR-45'].id;

            this.__addIndividualItems('secondaryOwed', [
              { codeId, amount: currencyToCents(e.value) },
            ]);
          }

          this.formService.validate();
        }
      },
      openEditBillingHeaderPopup: async () => {
        const model = {
          invoiceId: this.state.invoiceId,
          invoiceNumber: this.state.invoiceNumber,
          lineItemId: this.state.id,
          patientId: this.state.patientId,
          payerInformation: this.payerInformation,
          encounterCharge: this.state.encounterCharge,
          multiCarePackageEnabled: this.multiCarePackageEnabled,
          hasRCMChangeSecondary: this.hasRCMChangeSecondary,
        };

        if (await this.__dirtyGuard()) {
          let result = await openPopup(POPUP_RENDER_KEYS.BILLING_HEADER, model);

          if (result === 'newCase') {
            const payerInfo = await this.__openAddCaseOverlay();

            if (payerInfo.isNewPayerInfo) {
              model.payerInformation = {
                ...this.payerInformation,
                ...payerInfo.data,
              };

              result = await openPopup(POPUP_RENDER_KEYS.BILLING_HEADER, {
                ...model,
                triggerSaveAndClose: true,
              });
            } else {
              result = false;
            }
          }

          if (result) {
            this.onUpdateBillingHeader();
          }
        }

        this.__focusOnEditBillingIcon();
      },
      changeSupplementalInfo: ({ value }) => {
        this.formService.apply('supplementalInformation', value);
        this.__formatEncounterChargeDetails();
      },
      changeSupplementalInfoDetails: ({
        codeQualifier,
        identificationNumber,
        reportTransmissionCode,
        reportTypeCode,
      }) => {
        this.formService.apply('codeQualifier', codeQualifier);
        this.formService.apply('identificationNumber', identificationNumber);
        this.formService.apply('reportTypeCode', reportTypeCode);
        this.formService.apply(
          'reportTransmissionCode',
          reportTransmissionCode,
        );

        this.formService.validate();
        this.__formatEncounterChargeDetails();
      },
      keydown: ({ key, name }) => {
        const responsibleHasMultiple = this.__responsibleHasMultiple(name);

        if (key === 'Enter' || (responsibleHasMultiple && key === 'click')) {
          switch (name) {
            case 'secondaryResponsibleDebits':
              this.handlers.openSecondaryOwedPopup();
              break;
            case 'patientResponsibleDebits':
              if (
                this.isCarePackageWithInsurance &&
                getPatientLineItemDebits(this.state).length <= 1
              ) {
                return;
              }
              this.handlers.openPatientOwedPopup();
              break;
            case 'adjustments':
              this.handlers.openAdjustmentsPopup();
              break;
            default:
          }
        }
      },
      changeOrderingProvider: ({ value }) => {
        this.formService.apply('orderingProvider', value);
        this.__formatEncounterChargeDetails();
      },
      changeOrderingProviderDetails: ({
        orderingProviderCredentials,
        orderingProviderFirstName,
        orderingProviderLastName,
        orderingProviderMiddleName,
        orderingProviderNPI,
        orderingProviderOtherId,
        orderingProviderQualifier,
      }) => {
        this.formService.apply(
          'orderingProviderCredentials',
          orderingProviderCredentials,
        );

        this.formService.apply(
          'orderingProviderFirstName',
          orderingProviderFirstName,
        );

        this.formService.apply(
          'orderingProviderLastName',
          orderingProviderLastName,
        );

        this.formService.apply(
          'orderingProviderMiddleName',
          orderingProviderMiddleName,
        );

        this.formService.apply('orderingProviderNPI', orderingProviderNPI);
        this.formService.apply(
          'orderingProviderOtherId',
          orderingProviderOtherId,
        );

        this.formService.apply(
          'orderingProviderQualifier',
          orderingProviderQualifier,
        );

        this.__formatEncounterChargeDetails();
      },
      clickERAsEOBs: async () => {
        if (await this.__dirtyGuard()) {
          this.onClickERAsEOBs();
        }
      },
    };
  }

  async __openAddCaseOverlay() {
    const { guarantors, cases } = await fetchBillingHeaderInfo(
      this.state.patientId,
      this.guarantorId,
      this.patientCaseId,
    );
    const newCase = await openOverlay(OVERLAY_KEYS.CASE, {
      context: {
        patientId: this.state.patientId,
        guarantors,
        isFirstCase: !cases.length,
      },
    });

    const payerInfo = {
      isNewPayerInfo: false,
      data: {},
    };

    if (newCase) {
      const label = `${newCase.name} - ${formatGradualDate(
        newCase.onsetSymptomsDate,
      )}`;
      payerInfo.isNewPayerInfo = true;
      payerInfo.data = {
        case: label,
        caseId: newCase.id,
        authorizationId: '',
        authorizationNumber: '',
      };

      return payerInfo;
    }

    return payerInfo;
  }

  __shouldRecalculateCarePackage() {
    return (
      this.isCarePackageWithInsurance &&
      (this.__previousPatientOwedAmount !== getPatientOwedTotal(this.state) ||
        !equal(
          this.__previousPatientOwedAmountPopup,
          getPatientLineItemDebits(this.state),
        ))
    );
  }

  __focusOnEditBillingIcon() {
    const billingHeader = this.shadowRoot.getElementById(
      ELEMENTS.billingHeader.id,
    );
    const editIcon = billingHeader.shadowRoot.getElementById('edit-button');
    const icon = editIcon.shadowRoot.getElementById('icon');
    icon.focus();
  }

  __responsibleHasMultiple(name) {
    switch (name) {
      case 'secondaryResponsibleDebits':
        return getSecondaryPayerLineItemDebits(this.state).length > 1;

      case 'patientResponsibleDebits':
        return (
          getPatientLineItemDebits(this.state).length > 1 ||
          lineItemHasPackageCreditsOrAdjustments(this.state)
        );

      case 'adjustments':
        return this.__hasMultipleAdjustments();

      default:
    }

    return null;
  }

  async __dirtyGuard() {
    if (!this.__dirty) {
      return true;
    }

    const formIsValid = this.formService.validate();
    const result = await openDirtyDialog({ formIsValid });

    switch (result) {
      case 'save':
        return formIsValid && this.handlers.save(false);
      case 'discard':
        this.formService.reset();
        return true;
      default:
        return false;
    }
  }

  __displayMultipleLocationPopup(invoice) {
    return this.__hasDiffChargeLocations(invoice)
      ? openPopup(POPUP_RENDER_KEYS.LOCATIONS_WARNING, {
          invoice,
        })
      : true;
  }

  __hasDiffChargeLocations(invoice) {
    const locationIds = new Set([
      ...(invoice && invoice.locationIds ? invoice.locationIds : []),
      this.state.encounterCharge.locationId,
    ]);

    return locationIds.size !== 1;
  }

  createSelectors() {
    return {
      format: v => ({
        ...v,
        lineItemDebits: v.lineItemDebits || [],
        taxName: v.taxName || '',
        taxRate: v.taxRate || 0,
        feeScheduleId: v.feeScheduleId || '',
      }),
      unformat: v => ({
        ...v,
        encounterCharge: {
          ...v.encounterCharge,
          diagnosisPointers: v.encounterCharge.diagnosisPointers
            ? v.encounterCharge.diagnosisPointers.map(ec => ec.diagnosisCode)
            : [],
        },
        modifier_1: v.modifiers[0],
        modifier_2: v.modifiers[1],
        modifier_3: v.modifiers[2],
        modifier_4: v.modifiers[3],
        epsdt: { type: v.EPSDTType },
        taxRate: { name: v.taxName, rate: v.taxRate },
      }),
      children: {
        modifiers: NebModifiers.createSelectors(
          { format: v => v || '', unformat: v => v || null },
          MODIFIERS_ERROR_MESSAGE,
        ),
        allowedAmount: {
          format: v => centsToCurrency(v),
          unformat: v => currencyToCentsWithNegative(v),
          validateManually: true,
          validateRaw: true,
          validators: [
            validateAllowedAgainstBilled(
              'The total Owed amounts must equal the Allowed amount',
            ),
            validateAllowedAgainstTotalOwed(
              'The total Owed amounts must equal the Allowed amount.',
              true,
            ),
          ],
        },
        feeScheduleCharge: currencySelector(),
        lineItemDebits: {
          createItem: () => ({
            debit: {
              allocations: [],
              amount: 0,
              id: '',
              payerId: null,
              allocated: 0,
            },
            id: '',
            patientInsuranceId: null,
            codePaymentId: null,
          }),
          children: {
            $: {
              children: {
                debit: {
                  children: {
                    amount: {
                      validateManually: true,
                      validateRaw: true,
                      validators: [
                        validateAllowedAgainstTotalOwed(
                          'The total Owed amounts must be equal to or less than the Allowed amount.',
                        ),
                        owedGrandTotalValidator(
                          "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                        ),
                        validateAllowedAgainstTotalOwed(
                          'The total Owed amounts must equal the Allowed amount.',
                          true,
                        ),
                        validatePatientOwedTypes(
                          'Patient responsibility type is required.',
                        ),
                      ],
                    },
                    allocations: {
                      createItem: () => ({
                        amount: 0,
                        credit: {
                          amount: 0,
                          patientPackageId: null,
                        },
                      }),
                    },
                  },
                },
              },
            },
          },
        },
        adjustments: {
          createItem: () => ({ amount: 0, codeId: '', id: '' }),
          children: {
            $: {
              children: {
                amount: {
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    validateAdjustmentCodes(
                      'Invalid adjustment code.',
                      this.codeWriteOffs,
                      this.model.billType,
                      this.isCarePackageWithInsurance,
                    ),
                    owedGrandTotalValidator(
                      "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                    ),
                  ],
                },
              },
            },
          },
        },
        encounterCharge: {
          children: {
            diagnosisPointers: {
              createItem: () => ({ diagnosisCode: '' }),
            },
          },
        },
        units: numeric(0, {
          validateManually: true,
          validateRaw: true,
          validators: [atMin(1, true, 'Units is required')],
        }),

        ndc: {
          validators: [NDCPopupStateValidator('NDC Info is required')],
        },

        supplementalInformation: {
          validators: [
            supplementalInfoPopupStateValidator(
              'Supplemental Info is required',
            ),
          ],
        },

        orderingProvider: {
          validators: [
            orderingProviderInfoPopupStateValidator(
              'Ordering Provider Info is required',
            ),
          ],
        },
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasFifo = await hasAddOn(ADD_ONS.CT_FIFO);

    const practiceSettings = await getPracticeSettings();

    this.__hasAutoPostCharges =
      practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES];
  }

  update(changedProps) {
    super.update(changedProps);

    if (!this.__focused) {
      this.__focusOnModifiersField();
    }

    if (changedProps.has('model')) {
      this.formService.validate();
      this.__diagnosisItems = formatDiagnoses(this.__getDiagnoses());

      this.__balancer = new ChargeBalancer({
        billedAmount: this.model.billedAmount,
        taxRate: this.model.taxRate,
        allowedAmount: this.model.allowedAmount,
        adjustmentAmount: calculateAdjustmentsSum(this.model.adjustments),
      });
    }

    if (changedProps.has('claims') || changedProps.has('locations')) {
      this.__formatEncounterChargeDetails();
    }

    if (changedProps.has('feeSchedules')) {
      this.__feeSchedules = this.feeSchedules.filter(({ data }) => {
        const { active, charges, id } = data;

        if (id === '' || id === this.state.feeScheduleId) {
          return true;
        }

        const covered = charges
          ? charges.some(
              charge => charge.id === this.state.encounterCharge.chargeId,
            )
          : false;

        return active && covered;
      });
    }

    if (changedProps.has('authorizations')) {
      this.__showAuthorizationWarning();
    }
  }

  __focusOnModifiersField() {
    setTimeout(() => {
      const modifiers = this.shadowRoot.querySelector('neb-modifiers');

      if (modifiers) {
        const textfield = modifiers.shadowRoot.querySelector('neb-textfield');

        if (textfield) {
          textfield.focus();
          this.__focused = true;
        }
      }
    }, 100);
  }

  async __recalculateCarePackageUnitsOrTaxRate() {
    if (
      !lineItemHasPackageCreditsOrAdjustments(this.state) ||
      this.isCarePackageWithInsurance
    ) {
      return;
    }

    const unitOrTaxRateHasChanged =
      parseInt(this.state.units, 10) !== parseInt(this.model.units, 10) ||
      parseInt(this.state.taxAmount, 10) !== parseInt(this.model.taxAmount, 10);

    const packageItemsWithIdNullExists =
      this.state.lineItemDebits.some(
        ({ codePaymentId, debit: { id } }) =>
          codePaymentId === CODE_PAYMENTS.PACKAGE.id && !id,
      ) ||
      this.state.adjustments.some(
        ({ codeId, id }) => codeId === CODE_WRITE_OFFS.PACKAGE.id && !id,
      );

    if (!unitOrTaxRateHasChanged && !packageItemsWithIdNullExists) {
      return;
    }

    const lineItem = unitOrTaxRateHasChanged
      ? (await buildPatientPackageAssociations({
          lineItems: [deepCopy(this.state)],
          patientOwedChanged: false,
        }))[this.state.id]
      : this.model;

    if (this.hasAutoCalculateAllowedAmountForCarePackageFeatureFlag) {
      this.__addPackageAdjustment({
        adjustments: lineItem.adjustments,
        patientOwedChanged: false,
      });

      this.__addPackageLineItemDebit(lineItem.lineItemDebits);
    } else {
      this.__addPackageLineItemDebit(lineItem.lineItemDebits);

      this.__addPackageAdjustment({
        adjustments: lineItem.adjustments,
        patientOwedChanged: false,
      });
    }
  }

  async __recalculateCarePackageOwedAmount() {
    const carePackageDistribution = await buildPatientPackageAssociations({
      lineItems: [deepCopy(this.state)],
      patientOwedChanged: true,
    });

    if (Object.keys(carePackageDistribution).length !== 0) {
      const lineItem = carePackageDistribution[this.state.id];
      const { lineItemDebits } = lineItem;

      if (
        lineItemDebits.length &&
        lineItemDebits[0].codePaymentId !== CODE_PAYMENTS.PACKAGE.id
      ) {
        this.formService.apply(
          'lineItemDebits.1.codePaymentId',
          CODE_PAYMENTS.GENERAL_PAYMENT.id,
        );

        return;
      }

      this.__removeNonCarePackageItems();

      this.__addPackageAdjustment({
        adjustments: lineItem.adjustments,
        patientOwedChanged: true,
      });

      this.__addPackageLineItemDebit(lineItemDebits);
      this.__addNonPackageLineitemDebit(lineItemDebits);
    } else if (this.hasAutoCalculateAllowedAmountForCarePackageFeatureFlag) {
      this.__addPackageAdjustment({
        adjustments: [],
      });

      this.__addPackageLineItemDebit([]);
    } else {
      this.__addPackageLineItemDebit([]);
      this.__addPackageAdjustment({
        adjustments: [],
      });
    }
  }

  __addPackageLineItemDebit(lineItemDebits) {
    const lineItemDebitLength = this.state.lineItemDebits.length - 1;

    let existingLineItemDebitId;

    for (let i = lineItemDebitLength; i >= 0; i -= 1) {
      const { id, codePaymentId } = this.state.lineItemDebits[i];

      if (codePaymentId === CODE_PAYMENTS.PACKAGE.id) {
        existingLineItemDebitId = id;
        this.formService.removeItem('lineItemDebits', i);
      }
    }

    const pkgLineItemDebit = lineItemDebits.find(
      ({ responsibilityType }) =>
        responsibilityType === CODE_PAYMENTS.PACKAGE.code,
    );

    if (pkgLineItemDebit) {
      this.handlers.addItem('lineItemDebits');

      const debitIndex = this.state.lineItemDebits.length - 1;

      if (existingLineItemDebitId) {
        this.formService.apply(
          `lineItemDebits.${debitIndex}.id`,
          existingLineItemDebitId,
        );
      }

      this.formService.apply(
        `lineItemDebits.${debitIndex}.codePaymentId`,
        pkgLineItemDebit.codePaymentId,
      );

      this.formService.apply(
        `lineItemDebits.${debitIndex}.debit.amount`,
        pkgLineItemDebit.debit.amount,
      );

      this.__addLineItemDebitAllocations(
        debitIndex,
        pkgLineItemDebit.debit.allocations[0],
      );
    }

    if (!this.isCarePackageWithInsurance) {
      const adjustmentAmount = this.state.adjustments.reduce(
        (acc, cur) => acc + cur.amount,
        0,
      );

      const oldAllowedAmount = this.state.billedAmount + this.state.taxAmount;
      const allowedAmount = this
        .hasAutoCalculateAllowedAmountForCarePackageFeatureFlag
        ? oldAllowedAmount - adjustmentAmount
        : oldAllowedAmount;

      this.formService.apply('allowedAmount', centsToCurrency(allowedAmount));
    }
  }

  __addLineItemDebitAllocations(debitIndex, allocation) {
    const allocationPath = `lineItemDebits.${debitIndex}.debit.allocations`;

    this.handlers.addItem(allocationPath);

    this.formService.apply(`${allocationPath}.0.amount`, allocation.amount);

    this.formService.apply(
      `${allocationPath}.0.credit.amount`,
      allocation.credit.amount,
    );

    this.formService.apply(
      `${allocationPath}.0.credit.patientPackageId`,
      allocation.credit.patientPackageId,
    );
  }

  __addPackageAdjustment({ adjustments }) {
    const adjustmentLength = this.state.adjustments.length - 1;

    for (let i = adjustmentLength; i >= 0; i -= 1) {
      if (this.state.adjustments[i].codeId === CODE_WRITE_OFFS.PACKAGE.id) {
        this.formService.removeItem('adjustments', i);
      }
    }

    const adjustment = adjustments.find(
      ({ codeId }) => codeId === CODE_WRITE_OFFS.PACKAGE.id,
    );

    if (adjustment) {
      this.handlers.addItem('adjustments');
      const adjustmentIndex = this.state.adjustments.length - 1;

      this.formService.apply(
        `adjustments.${adjustmentIndex}.amount`,
        adjustment.amount,
      );

      this.formService.apply(
        `adjustments.${adjustmentIndex}.codeId`,
        adjustment.codeId,
      );
    }
  }

  __removeNonCarePackageItems() {
    const lineItemDebitLength = this.state.lineItemDebits.length - 1;

    for (let i = lineItemDebitLength; i >= 0; i -= 1) {
      if (
        this.state.lineItemDebits[i].codePaymentId !==
          CODE_PAYMENTS.PACKAGE.id &&
        !this.state.lineItemDebits[i].patientInsuranceId
      ) {
        this.formService.removeItem('lineItemDebits', i);
      }
    }
  }

  __addNonPackageLineitemDebit(lineItemDebits) {
    lineItemDebits.forEach(debit => {
      if (debit.codePaymentId !== CODE_PAYMENTS.PACKAGE.id) {
        this.handlers.addItem('lineItemDebits');
        const debitIndex = this.state.lineItemDebits.length - 1;

        this.formService.apply(
          `lineItemDebits.${debitIndex}.codePaymentId`,
          debit.codePaymentId,
        );

        this.formService.apply(
          `lineItemDebits.${debitIndex}.debit.amount`,
          debit.debit.amount,
        );
      }
    });
  }

  __showAuthorizationWarning() {
    if (this.authorizations.length) {
      const hasRemaining = hasAuthorizationRemaining(this.authorizations[0]);
      this.__authorizationWarning = !hasRemaining;

      if (!hasRemaining) {
        openWarning(NO_REMAINING_AUTHORIZATIONS_MESSAGE);
      }
    } else {
      this.__authorizationWarning = false;
    }
  }

  async __updateFeeScheduleChargeFields(e) {
    let result = true;

    if (e.value.data.id) {
      const foundCharge = e.value.data.charges.find(
        c => c.id === this.state.encounterCharge.chargeId,
      );

      const hasFeeScheduleModifiersChanged =
        this.state.encounterCharge.signed &&
        this.state.feeScheduleId !== e.value.data.id &&
        foundCharge.modifiers.some((mod, idx) => {
          const modifier = mod || null;
          const chargeModifier = this.state.modifiers[idx] || null;

          return modifier !== chargeModifier;
        });

      if (hasFeeScheduleModifiersChanged) {
        result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Fee Schedule Modifiers',
          message: FEE_SCHEDULE_FF_CONFIRM_MESSAGE,
          confirmText: 'YES',
          cancelText: 'NO',
        });
      }

      if (!result) return false;

      foundCharge.modifiers.forEach((mod, idx) => {
        this.formService.apply(`modifiers.${idx}`, mod);
      });

      this.formService.apply(
        'encounterCharge.suppressFromClaim',
        foundCharge.suppressFromClaim,
      );

      this.formService.apply('encounterCharge.feeScheduleName', e.value.label);
    } else {
      this.formService.apply(
        'encounterCharge.suppressFromClaim',
        this.settingsCharge.suppressFromClaim,
      );

      this.formService.apply('encounterCharge.feeScheduleName', '');
    }

    return result;
  }

  __formatEncounterChargeDetails() {
    this.encounterChargeDetails = formatEncounterChargeDetails({
      ...this.state,
      claims: this.claims,
      locations: this.locations,
    });
  }

  __getDiagnoses() {
    const { diagnoses, encounterId } = this.model.encounterCharge;
    return diagnoses.map(({ code, description }) => ({
      code,
      description,
      encounterId,
    }));
  }

  __getSelectedDiagnoses() {
    const selectedCodes = this.state.encounterCharge.diagnosisPointers.map(
      ({ diagnosisCode }) => diagnosisCode,
    );

    return this.__diagnosisItems.filter(({ data }) =>
      selectedCodes.includes(data.code),
    );
  }

  __getSelectedFeeSchedule() {
    if (!this.feeSchedules.length) {
      return ITEM_NONE;
    }

    return this.feeSchedules.find(
      ({ data }) => data.id === this.state.feeScheduleId,
    );
  }

  __getSelectedTaxRate() {
    return (
      this.taxRates.find(
        taxRate =>
          taxRate.data.name === this.state.taxName &&
          taxRate.data.rate === this.state.taxRate,
      ) || formatTaxRate({ name: this.state.taxName, rate: this.state.taxRate })
    );
  }

  __deleteIndividualItems(type, itemsToDelete) {
    itemsToDelete.forEach(itemToDelete => {
      const indexToDelete = this.__state[type].findIndex(
        individualType => individualType.id === itemToDelete.individualRowId,
      );

      this.formService.removeItem(type, indexToDelete);
    });
  }

  __deleteAdjustmentByCodeId(codeId) {
    const indexToDelete = this.__existingAdjustmentIndexByCodeId(codeId);
    this.formService.removeItem('adjustments', indexToDelete);
  }

  __editAdjustmentByCodeId(adjustmentToEdit) {
    const indexToEdit = this.__existingAdjustmentIndexByCodeId(
      adjustmentToEdit.codeId,
    );

    this.formService.apply(
      `adjustments.${indexToEdit}.codeId`,
      adjustmentToEdit.codeId,
    );

    this.formService.apply(
      `adjustments.${indexToEdit}.amount`,
      adjustmentToEdit.amount,
    );
  }

  __deleteItemsBeforeAdding(type) {
    const isLineItemDebits = type !== 'adjustments';
    const attribute = isLineItemDebits ? 'lineItemDebits' : 'adjustments';

    let countAddedItemsToDelete = 0;

    if (isLineItemDebits) {
      countAddedItemsToDelete = this.__debitWithNoIdLength(type);
    } else {
      countAddedItemsToDelete = this.__state.adjustments.filter(adj => !adj.id)
        .length;
    }

    while (countAddedItemsToDelete) {
      const indexToDelete = isLineItemDebits
        ? this.__debitWithNoIdIndex(type)
        : this.__adjustmentWithNoIdIndex();

      if (indexToDelete !== -1) {
        this.formService.removeItem(attribute, indexToDelete);
      }

      countAddedItemsToDelete -= 1;
    }
  }

  __removeAllAdjustments() {
    while (this.state.adjustments.length) {
      this.formService.removeItem('adjustments');
    }
  }

  __removeAllLineItemDebits() {
    while (this.state.lineItemDebits.length) {
      this.formService.removeItem('lineItemDebits');
    }
  }

  __addIndividualItems(type, itemsToAdd, deleteItemsBeforeAdding = true) {
    const isLineItemDebits = type !== 'adjustments';
    const attribute = isLineItemDebits ? 'lineItemDebits' : 'adjustments';

    if (deleteItemsBeforeAdding) {
      this.__deleteItemsBeforeAdding(type);
    }

    const codeId = isLineItemDebits ? 'codePaymentId' : 'codeId';
    const amount = isLineItemDebits ? 'debit.amount' : 'amount';

    itemsToAdd.forEach(itemToAdd => {
      const indexToAdd = this.__state[attribute].length;

      this.formService.addItem(attribute);

      this.formService.apply(
        `${attribute}.${indexToAdd}.${codeId}`,
        itemToAdd.codeId,
      );

      this.formService.apply(
        `${attribute}.${indexToAdd}.${amount}`,
        itemToAdd.amount,
      );

      if (type === 'secondaryOwed') {
        this.formService.apply(
          `${attribute}.${indexToAdd}.debit.payerId`,
          this.payerInformation.secondaryPayerPlanId,
        );

        this.formService.apply(
          `${attribute}.${indexToAdd}.patientInsuranceId`,
          this.model.secondaryInsuranceId,
        );
      }
    });
  }

  __editIndividualItems(type, itemsToEdit) {
    const isLineItemDebits = type !== 'adjustments';

    itemsToEdit.forEach(itemToEdit => {
      const indexToEdit = isLineItemDebits
        ? this.__existingDebitIndex(itemToEdit)
        : this.__existingAdjustmentIndex(itemToEdit);

      const codeId = isLineItemDebits ? 'codePaymentId' : 'codeId';
      const amount = isLineItemDebits ? 'debit.amount' : 'amount';

      this.formService.apply(
        `${type}.${indexToEdit}.${codeId}`,
        itemToEdit.codeId,
      );

      this.formService.apply(
        `${type}.${indexToEdit}.${amount}`,
        itemToEdit.amount,
      );
    });
  }

  __debitWithNoIdLength(type) {
    let length;

    switch (type) {
      case 'patientOwed':
        length = this.__state.lineItemDebits.filter(
          lineItemDebit =>
            isPatientRespLineItemDebit(lineItemDebit) && !lineItemDebit.id,
        ).length;

        break;
      case 'secondaryOwed':
        length = this.__state.lineItemDebits.filter(
          lineItemDebit =>
            isSecondaryRespLineItemDebit(lineItemDebit, this.state) &&
            !lineItemDebit.id,
        ).length;

        break;
      default:
        break;
    }

    return length;
  }

  __debitWithNoIdIndex(type) {
    let index;

    switch (type) {
      case 'patientOwed':
        index = this.__state.lineItemDebits.findIndex(
          lineItemDebit =>
            isPatientRespLineItemDebit(lineItemDebit) && !lineItemDebit.id,
        );

        break;
      case 'secondaryOwed':
        index = this.__state.lineItemDebits.findIndex(
          lineItemDebit =>
            isSecondaryRespLineItemDebit(lineItemDebit, this.state) &&
            !lineItemDebit.id,
        );

        break;
      default:
        break;
    }

    return index;
  }

  __adjustmentWithNoIdIndex() {
    return this.__state.adjustments.findIndex(adj => !adj.id);
  }

  __existingDebitIndex(itemToEdit) {
    return this.__state.lineItemDebits.findIndex(
      debit => debit.id === itemToEdit.individualRowId,
    );
  }

  __existingAdjustmentIndex(itemToEdit) {
    return this.__state.adjustments.findIndex(
      adj => adj.id === itemToEdit.individualRowId,
    );
  }

  __existingAdjustmentIndexByCodeId(codeId) {
    return this.__state.adjustments.findIndex(
      adjustment => adjustment.codeId === codeId,
    );
  }

  __calculateLineItemDebitPaidSum(lineItemDebit) {
    return lineItemDebit.debit.allocations.reduce(
      (sum, a) => sum + a.amount,
      0,
    );
  }

  __calculateTotalPaidSum(lineItemDebits) {
    const totalDebitCents = lineItemDebits.reduce(
      (total, lineitemDebit) =>
        total + this.__calculateLineItemDebitPaidSum(lineitemDebit),
      0,
    );

    return centsToCurrency(totalDebitCents);
  }

  __defaultAdjustmentCodeId() {
    return this.state.billType === BILL_TYPE.INSURANCE
      ? CODE_WRITE_OFFS.NEGOTIATED_RATE.id
      : CODE_WRITE_OFFS.WRITE_OFF.id;
  }

  __updateAllowedAmount() {
    if (
      !lineItemHasPackageCreditsOrAdjustments(this.state) ||
      this.isCarePackageWithInsurance
    ) {
      this.__balancer.adjustmentAmount = calculateAdjustmentsSum(
        this.state.adjustments,
      );

      this.formService.apply(
        'allowedAmount',
        centsToCurrency(this.__balancer.allowedAmount),
      );
    }
  }

  __updateAdjustmentAmount(amount) {
    const adjustments = this.state.adjustments.filter(adjustment => {
      if (adjustment.codeId === CODE_WRITE_OFFS.PACKAGE.id) {
        amount -= adjustment.amount;
        return false;
      }
      return true;
    });

    switch (adjustments.length) {
      case 0:
        if (amount) {
          this.__addIndividualItems(
            'adjustments',
            [{ codeId: this.__defaultAdjustmentCodeId(), amount }],
            false,
          );
        }

        break;

      case 1:
        if (amount) {
          this.__editAdjustmentByCodeId({
            codeId: adjustments[0].codeId,
            amount,
          });
        } else {
          this.__deleteAdjustmentByCodeId(adjustments[0].codeId);
        }

        break;

      default:
        break;
    }
  }

  __calculateBilledInfo() {
    const units = Number(this.state.units) || 1;
    const feeScheduleCharge = currencyToCents(this.state.feeScheduleCharge);

    this.__balancer.billedAmount = feeScheduleCharge * units;
    this.__balancer.taxRate = this.state.taxRate;

    this.formService.apply('billedAmount', this.__balancer.billedAmount);
    this.formService.apply('taxAmount', this.__balancer.taxAmount);

    if (
      !lineItemHasPackageCreditsOrAdjustments(this.state) ||
      this.isCarePackageWithInsurance
    ) {
      this.formService.apply(
        'allowedAmount',
        centsToCurrency(this.__balancer.allowedAmount),
      );

      this.__updateAdjustmentAmount(this.__balancer.adjustmentAmount);
    }
  }

  __displayRecoups(paymentResponsibility) {
    const allowedRecoupResponsibilities = [
      PAYMENT_RESPONSIBILITY.PRIMARY,
      PAYMENT_RESPONSIBILITY.SECONDARY,
    ];
    return (
      this.hasRcmRecoupsFeatureFlag &&
      this.state?.recoups?.length &&
      allowedRecoupResponsibilities.includes(paymentResponsibility)
    );
  }

  __getRecoups({ recoups }, paymentResponsibility) {
    const payerPlanId =
      paymentResponsibility === PAYMENT_RESPONSIBILITY.SECONDARY
        ? this.payerInformation.secondaryPayerPlanId
        : this.payerInformation.payerId;

    return recoups
      .filter(({ fromPayment }) => fromPayment.payerPlanId === payerPlanId)
      .map(({ fromPayment }) => ({
        id: fromPayment.id,
        displayCode: fromPayment.paymentNumber,
        recouped: true,
      }));
  }

  __renderRecoupedIndicator({ paymentId }) {
    return html`
      <neb-icon
        id="recouped-indicator-${paymentId}"
        class="recouped-icon"
        icon="neb:swapVerticalCircle"
        title="Reversed"
      ></neb-icon>
    `;
  }

  __getPaymentIcon(paymentMethod) {
    if (paymentMethod === 'ERA') return 'neb:receipt';
    return '';
  }

  __renderPaymentNumber({
    id: paymentId,
    paymentMethod,
    displayCode,
    recouped,
  }) {
    return html`
      <div class="payment-link">
        <neb-text
          id="${ELEMENTS.paymentNumber.id}"
          link
          wrapText
          .name="${paymentId}"
          trailingIcon="${this.__getPaymentIcon(paymentMethod)}"
          .onClick="${this.handlers.openPaymentDetailOverlay}"
          >${displayCode}
        </neb-text>
        ${recouped ? this.__renderRecoupedIndicator({ paymentId }) : ''}
      </div>
    `;
  }

  __renderPaymentIds(lineItemDebits, paymentResponsibility) {
    const payments = getLidPaymentIds(lineItemDebits);
    const allPayments = [
      ...Array.from(payments.entries()).map(([_, payment]) => payment),
      ...(this.__displayRecoups(paymentResponsibility)
        ? this.__getRecoups(this.state, paymentResponsibility)
        : []),
    ];

    return allPayments
      .sort((a, b) => a.displayCode - b.displayCode)
      .map((payment, i) => {
        const leadingComma = i === 0 ? '' : ', ';
        return html`
          ${leadingComma}&nbsp${this.__renderPaymentNumber(payment)}
        `;
      });
  }

  __renderERAsAndEOBs() {
    return this.showERAsAndEOBsAssociations
      ? html`
          <div class="era-eob-field">
            <span id="${ELEMENTS.erasEobsLabel.id}" class="label">ERA/EOB</span>
            <div>
              <span class="era-eob-link">
                <neb-text
                  id="${ELEMENTS.erasEobsLink.id}"
                  link
                  name="era-eob"
                  .onClick="${this.handlers.clickERAsEOBs}"
                >
                  ${this.associatedERAsAndEOBs.length ? '✓' : ''}</neb-text
                ></span
              >
            </div>
          </div>
        `
      : '';
  }

  __renderPrimaryPayerSection() {
    const primaryPayerLineItemDebits = getPrimaryPayerLineItemDebits(
      this.state,
    );

    return html`
      <div>
        <div id="${ELEMENTS.primaryOwedLabel.id}" class="label">Prim. Owed</div>
        <neb-textfield
          id="${ELEMENTS.primaryOwedAmount.id}"
          helper=" "
          name="primaryResponsibleDebits"
          .error="${this.__hasError('lineItemDebits')}"
          .value="${centsToCurrency(getPrimaryOwedTotal(this.state))}"
          .mask="${currency}"
          .onChange="${this.handlers.changePrimaryOwed}"
          ?disabled="${this.payerInformation.billType !== 'Insurance'}"
        ></neb-textfield>
      </div>

      <div class="billed-field">
        <span id="${ELEMENTS.primaryPaidLabel.id}" class="label"
          >Prim. Paid
        </span>
        <span id="${ELEMENTS.primaryPaidAmount.id}" class="plain-text-value">
          ${this.__calculateTotalPaidSum(primaryPayerLineItemDebits)}</span
        >
      </div>

      <div class="billed-field">
        <span id="${ELEMENTS.primaryPaymentsLabel.id}" class="label"
          >Pymt IDs</span
        >
        <div>
          <span id="${ELEMENTS.primaryPaymentsIds.id}" class="payment-id-link">
            ${
              this.__renderPaymentIds(
                primaryPayerLineItemDebits,
                PAYMENT_RESPONSIBILITY.PRIMARY,
              )
            }</span
          >
        </div>
      </div>

      ${this.__renderERAsAndEOBs()}
    `;
  }

  __renderSecondaryPayerSection() {
    const secondaryPayerLineItemDebits = getSecondaryPayerLineItemDebits(
      this.state,
    );

    return html`
      <div class="billed-field">
        <div id="${ELEMENTS.secondaryOwedLabel.id}" class="label">
          Sec. Owed
        </div>
        <neb-textfield
          id="${ELEMENTS.secondaryOwedAmount.id}"
          helper=" "
          name="secondaryResponsibleDebits"
          .error="${this.__hasError('lineItemDebits')}"
          .value="${centsToCurrency(getSecondaryOwedTotal(this.state))}"
          .onChange="${this.handlers.changeSecondaryOwed}"
          .mask="${currency}"
          trailingIcon="neb:expand"
          .onClickIcon="${this.handlers.openSecondaryOwedPopup}"
          .onKeydown="${this.handlers.keydown}"
          .onClick="${this.handlers.keydown}"
          ?readonly="${secondaryPayerLineItemDebits.length > 1}"
          ?disabled="${!this.payerInformation.secondaryInsurance}"
        ></neb-textfield>
      </div>

      <div class="billed-field">
        <span id="${ELEMENTS.secondaryPaidLabel.id}" class="label"
          >Sec. Paid
        </span>
        <span id="${ELEMENTS.secondaryPaidAmount.id}" class="plain-text-value">
          ${this.__calculateTotalPaidSum(secondaryPayerLineItemDebits)}</span
        >
      </div>

      <div class="billed-field">
        <span id="${ELEMENTS.secondaryPaymentsLabel.id}" class="label"
          >Pymt IDs</span
        >
        <div>
          <span
            id="${ELEMENTS.secondaryPaymentsIds.id}"
            class="payment-id-link"
          >
            ${
              this.__renderPaymentIds(
                secondaryPayerLineItemDebits,
                PAYMENT_RESPONSIBILITY.SECONDARY,
              )
            }</span
          >
        </div>
      </div>
    `;
  }

  __renderPatientResponsibilitySection() {
    const patientLineItemDebits = getPatientLineItemDebits(this.state);

    return html`
      <div>
        <div id="${ELEMENTS.patientOwedLabel.id}" class="label">Pt. Owed</div>
        <neb-textfield
          id="${ELEMENTS.patientOwedAmount.id}"
          helper=" "
          name="patientResponsibleDebits"
          .error="${this.__hasError('lineItemDebits')}"
          .value="${centsToCurrency(getPatientOwedTotal(this.state))}"
          .onChange="${this.handlers.changePatientOwed}"
          .mask="${currency}"
          trailingIcon="neb:expand"
          .onClickIcon="${this.handlers.openPatientOwedPopup}"
          .onKeydown="${this.handlers.keydown}"
          .onClick="${this.handlers.keydown}"
          ?readonly="${
            this.isCarePackageWithInsurance
              ? patientLineItemDebits.length > 1
              : patientLineItemDebits.length > 1 ||
                lineItemHasPackageCreditsOrAdjustments(this.state)
          }"
        ></neb-textfield>
      </div>
      <div class="billed-field">
        <span id="${ELEMENTS.patientPaidLabel.id}" class="label"
          >Pt. Paid
        </span>
        <span id="${ELEMENTS.patientPaidAmount.id}" class="plain-text-value">
          ${this.__calculateTotalPaidSum(patientLineItemDebits)}</span
        >
      </div>
      <div class="billed-field">
        <span id="${ELEMENTS.patientPaymentsLabel.id}" class="label"
          >Pymt IDs</span
        >
        <span id="${ELEMENTS.patientPaymentsIds.id}" class="plain-text-value">
          ${
            this.__renderPaymentIds(
              patientLineItemDebits,
              PAYMENT_RESPONSIBILITY.PATIENT,
            )
          }</span
        >
      </div>
    `;
  }

  __hasMultipleAdjustments() {
    return (
      this.state.adjustments.length > 1 ||
      (this.model.billType === BILL_TYPE.PACKAGE &&
        lineItemHasPackageCreditsOrAdjustments(this.state))
    );
  }

  __renderAdjustmentsSection() {
    return html`
      <div>
        <div id="${ELEMENTS.adjustmentLabel.id}" class="label">Adj.</div>
        <neb-textfield
          id="${ELEMENTS.adjustmentAmount.id}"
          helper=" "
          name="adjustments"
          .error="${this.__hasError('adjustments')}"
          .value="${
            centsToCurrencyWithNegative(
              calculateAdjustmentsSum(this.state.adjustments),
            )
          }"
          .onChange="${this.handlers.changeAdjustments}"
          .mask="${masks.currencyNegative}"
          trailingIcon="neb:expand"
          .onClickIcon="${this.handlers.openAdjustmentsPopup}"
          .onKeydown="${this.handlers.keydown}"
          .onClick="${this.handlers.keydown}"
          ?readonly="${this.__hasMultipleAdjustments()}"
        ></neb-textfield>
      </div>
    `;
  }

  __getErrorMessages() {
    const errorSet = new Set();

    Object.entries(this.__errors).forEach(([attribute, value]) => {
      if (typeof value !== 'object' && value !== '') {
        errorSet.add(value);
      } else if (attribute === 'modifiers' && value.some(v => v)) {
        errorSet.add(MODIFIERS_ERROR_MESSAGE);
      } else if (attribute === 'adjustments' && value[0]) {
        errorSet.add(value[0].amount);
      } else if (attribute === 'lineItemDebits' && value[0]) {
        errorSet.add(value[0].debit.amount);
      }
    });

    return Array.from(errorSet).filter(Boolean);
  }

  __hasError(type) {
    const value = this.__errors[type];

    if (typeof value !== 'object' && value !== '') {
      return true;
    }

    if (type === 'adjustments' && value[0]) {
      return value[0].amount !== '';
    }

    if (type === 'lineItemDebits' && value[0]) {
      return value[0].debit.amount !== '';
    }

    return false;
  }

  __displayErrors() {
    const errors = this.__getErrorMessages();

    if (!errors.length) {
      return '';
    }

    return html`
      <div class="error-area" id="${ELEMENTS.displayedErrors.id}">
        <ul>
          ${
            errors.map(
              error =>
                html`
                  <li>${error}</li>
                `,
            )
          }
        </ul>
      </div>
    `;
  }

  __buildButtonConfig() {
    const buttons = [];

    if (!this.state.invoiceId) {
      buttons.push({
        name: 'createInvoice',
        icon: 'noteAdd',
        label: 'Create Invoice',
        onClick: this.handlers.createInvoice,
      });

      buttons.push({
        name: 'addToInvoice',
        icon: 'plus',
        label: 'Add to Invoice',
        onClick: this.handlers.addToInvoice,
      });
    } else {
      buttons.push({
        name: 'removeFromInvoice',
        icon: 'minus',
        label: 'Remove from Invoice',
        onClick: this.handlers.removeFromInvoice,
      });
    }

    if (this.__hasFifo) {
      buttons.push({
        name: 'autoAllocatePayment',
        icon: 'flashAuto',
        label: 'Auto Allocate Payment',
        onClick: this.handlers.autoAllocatePayments,
      });
    }

    if (!this.__hasAutoPostCharges) {
      buttons.push({
        name: 'unpost',
        icon: 'return',
        label: 'Unpost',
        onClick: this.handlers.unpost,
      });
    }

    return buttons;
  }

  __renderDiagnoses() {
    if (!this.__diagnosisItems.length) {
      return html`
        <span id="${ELEMENTS.textNoEncounterDx.id}">No Encounter Dx</span>
      `;
    }

    return html`
      <neb-select
        id="${ELEMENTS.diagnoses.id}"
        helper=" "
        maxSelection="4"
        multiSelect
        showFullText
        wrapText
        itemHeight="${getDiagnosisItemHeight(this.__diagnosisItems)}"
        itemMinWidth="${ITEM_MIN_WIDTH}"
        .items="${this.__diagnosisItems}"
        .value="${this.__getSelectedDiagnoses()}"
        .onChange="${this.handlers.changeDiagnoses}"
        .useStartingSoftSelectIndex="${true}"
      ></neb-select>
    `;
  }

  __renderFeeScheduleDropdown() {
    const disabled = [BILL_TYPE.INSURANCE, BILL_TYPE.PACKAGE].includes(
      this.model.billType,
    );

    return html`
      <neb-select
        id="${ELEMENTS.feeSchedule.id}"
        class="fee-schedule-select"
        .items="${this.__feeSchedules}"
        .value="${this.__getSelectedFeeSchedule()}"
        .disabled="${disabled}"
        .onChange="${this.handlers.changeFeeSchedule}"
        .useStartingSoftSelectIndex="${true}"
      ></neb-select>
    `;
  }

  __renderChargeDetails() {
    return html`
      <div class="charge-details-container">
        <div class="charge-info-fields">
          <div>
            <div id="${ELEMENTS.dateOfServiceLabel.id}" class="label">
              Date of Service
            </div>
            <div id="${ELEMENTS.dateOfService.id}" class="plain-text-value">
              ${
                this.state.dateOfService
                  ? parseDate(this.state.dateOfService).format('MM/DD/YYYY')
                  : ''
              }
            </div>
          </div>

          <div>
            <div id="${ELEMENTS.codeLabel.id}" class="label">Code</div>
            <div id="${ELEMENTS.code.id}" class="plain-text-value">
              ${this.state.code}
            </div>
          </div>

          <div>
            <div id="${ELEMENTS.descriptionLabel.id}" class="label">
              Description
            </div>
            <div
              id="${ELEMENTS.description.id}"
              class="ellipse-field plain-text-value"
            >
              ${this.state.description}
            </div>
          </div>

          <div>
            <div id="${ELEMENTS.modifiersLabel.id}" class="label">
              Modifiers
            </div>
            <neb-modifiers
              class="charge-modifiers"
              id="${ELEMENTS.modifiers.id}"
              name="modifiers"
              .values="${this.state.modifiers}"
              .errors="${this.errors.modifiers}"
              .onChange="${this.handlers.changeModifiers}"
            ></neb-modifiers>
          </div>

          <div>
            <div>
              <div id="${ELEMENTS.diagnosesLabel.id}" class="label">
                Diagnoses
              </div>
              ${this.__renderDiagnoses()}
            </div>
          </div>

          <div>
            <div>
              <div id="${ELEMENTS.providerLabel.id}" class="label">
                Provider
              </div>
              <div
                id="${ELEMENTS.provider.id}"
                class="ellipse-field plain-text-value"
              >
                ${
                  this.providerInformation
                    ? objToName(
                        this.providerInformation.name,
                        DEFAULT_NAME_OPTS,
                      )
                    : ''
                }
              </div>
            </div>
          </div>
        </div>

        <div class="charge-info-fields">
          <div>
            <div id="${ELEMENTS.unitsLabel.id}" class="label">Units</div>
            <neb-textfield
              id="${ELEMENTS.units.id}"
              class="units-field"
              name="units"
              helper=" "
              maxLength="3"
              .value="${this.state.units}"
              .mask="${number}"
              .error="${this.__hasError('units')}"
              .inputMode="${'numeric'}"
              .onChange="${this.handlers.changeUnits}"
            ></neb-textfield>
          </div>

          <div>
            <div id="${ELEMENTS.unitChargeLabel.id}" class="label">
              Unit Charge
            </div>
            <div id="${ELEMENTS.unitCharge.id}" class="plain-text-value">
              ${centsToCurrency(this.state.unitCharge)}
            </div>
          </div>

          <div>
            <div id="${ELEMENTS.feeScheduleChargeAmountLabel.id}" class="label">
              Fee Sch Chrg
            </div>
            <neb-textfield
              id="${ELEMENTS.feeScheduleChargeAmount.id}"
              class="fee-sch-chrg-field"
              name="feeScheduleCharge"
              helper=" "
              .value="${this.state.feeScheduleCharge}"
              .error="${this.__hasError('feeScheduleCharge')}"
              .mask="${currency}"
              .inputMode="${'numeric'}"
              ?disabled="${
                lineItemHasPackageCreditsOrAdjustments(this.state) &&
                  !this.isCarePackageWithInsurance
              }"
              .onChange="${this.handlers.changeFeeScheduleCharge}"
            ></neb-textfield>
          </div>

          <div>
            <div id="${ELEMENTS.feeScheduleLabel.id}" class="label">
              Fee Schedule
            </div>
            ${this.__renderFeeScheduleDropdown()}
          </div>

          <div>
            <div>
              <div id="${ELEMENTS.taxRateLabel.id}" class="label">Tax Rate</div>
              <neb-select
                id="${ELEMENTS.taxRate.id}"
                name="taxRate"
                .items="${this.taxRates}"
                .value="${this.__getSelectedTaxRate()}"
                .onChange="${this.handlers.changeTaxRate}"
                .useStartingSoftSelectIndex="${true}"
              ></neb-select>
            </div>

            <div>
              <div id="${ELEMENTS.taxLabel.id}" class="label">Tax</div>
              <div id="${ELEMENTS.tax.id}" class="plain-text-value">
                ${centsToCurrency(this.state.taxAmount)}
              </div>
            </div>
          </div>

          <div>
            <div>
              <div id="${ELEMENTS.billedAmountLabel.id}" class="label">
                Billed
              </div>
              <div id="${ELEMENTS.billedAmount.id}" class="plain-text-value">
                ${centsToCurrency(this.state.billedAmount)}
              </div>
            </div>

            <div>
              <div id="${ELEMENTS.allowedAmountLabel.id}" class="label">
                Allowed
              </div>
              <neb-textfield
                id="${ELEMENTS.allowedAmount.id}"
                helper=" "
                class="allowed-field"
                name="allowedAmount"
                .error="${this.__hasError('allowedAmount')}"
                .value="${this.state.allowedAmount}"
                .mask="${currency}"
                .inputMode="${'numeric'}"
                .onChange="${this.handlers.changeAllowedAmount}"
              ></neb-textfield>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  __renderResponsibilitiesSection() {
    return html`
      <div class="responsibilities-container">
        <div class="top-fields">
          ${this.__renderPrimaryPayerSection()}
          ${this.__renderSecondaryPayerSection()}
          ${this.__renderPatientResponsibilitySection()}
          ${this.__renderAdjustmentsSection()}
        </div>
      </div>
    `;
  }

  __renderAdditionalChargeDetails() {
    return html`
      <neb-encounter-charge-details
        id="${ELEMENTS.encounterChargeDetails.id}"
        class="encounter-charge-details"
        .model="${this.encounterChargeDetails}"
        .onChangeHoldStatus="${this.handlers.changeHoldStatus}"
        .onChangeBilledStatus="${this.handlers.changeBilledStatus}"
        .onChangeFl24="${this.handlers.changeFl24}"
        .onChangeEpsdtType="${this.handlers.changeEpsdtType}"
        .onChangeNDC="${this.handlers.changeNDC}"
        .onChangeNDCDetails="${this.handlers.changeNDCDetails}"
        .onOpenInvoiceOverlay="${this.handlers.openInvoiceOverlay}"
        .onOpenEncounterOverlay="${this.handlers.openEncounterOverlay}"
        .onOpenClaimOverlay="${this.handlers.openClaimOverlay}"
        .onChangeSupplementalInfo="${this.handlers.changeSupplementalInfo}"
        .onChangeSupplementalInfoDetails="${
          this.handlers.changeSupplementalInfoDetails
        }"
        .onChangeOrderingProvider="${this.handlers.changeOrderingProvider}"
        .onChangeOrderingProviderDetails="${
          this.handlers.changeOrderingProviderDetails
        }"
        .onChangeBilledPatient="${this.handlers.changeBilledPatient}"
      ></neb-encounter-charge-details>
    `;
  }

  renderFooter() {
    return html`
      ${this.__displayErrors()}${super.renderFooter()}
    `;
  }

  renderContent() {
    return html`
      <neb-insurance-header
        id="${ELEMENTS.insuranceHeader.id}"
        class="header-insurance"
        .patientInsurances="${this.patientInsurances}"
        .patientId="${this.model.patientId}"
        .onOpenInsuranceOverlay="${this.handlers.openInsuranceOverlay}"
      ></neb-insurance-header>

      <neb-billing-header
        id="${ELEMENTS.billingHeader.id}"
        class="billing-header"
        .authorizations="${this.authorizations}"
        .model="${this.payerInformation}"
        .buttonConfig="${this.__buildButtonConfig()}"
        .showWarningIcon="${this.__authorizationWarning}"
        .onOpenEditBillingHeaderPopup="${
          this.handlers.openEditBillingHeaderPopup
        }"
        .onOpenPayerOverlay="${this.handlers.openPayerOverlay}"
        .onOpenInsuranceOverlay="${this.handlers.openInsuranceOverlay}"
        .onOpenCaseOverlay="${this.handlers.openCaseOverlay}"
        .onOpenAuthorizationOverlay="${this.handlers.openAuthorizationOverlay}"
        .onOpenCarePackageOverlay="${this.handlers.openCarePackageOverlay}"
        .onOpenCarePackagesSummaryOverlay="${
          this.handlers.openCarePackagesSummaryOverlay
        }"
        .multiCarePackageEnabled="${this.multiCarePackageEnabled}"
      ></neb-billing-header>

      ${this.__renderChargeDetails()} ${this.__renderResponsibilitiesSection()}
      ${this.__renderAdditionalChargeDetails()}
    `;
  }
}

customElements.define('neb-form-line-item', NebFormLineItem);
