import '../../filters/neb-filters-era-eob-management';
import '../../tables/era-eob/neb-table-era-eob-management';
import '../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../../../packages/neb-lit-components/src/components/neb-unsupported-page';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-bar';

import { openPopup } from '@neb/popup';
import { css, html, LitElement } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { ISO_DATE_FORMAT } from '../../../../packages/neb-input/nebFormatUtils';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_ERROR,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../packages/neb-utils/feature-util';
import {
  EMPTY_RESPONSE,
  FetchService,
  SORT_DIR,
} from '../../../../packages/neb-utils/services/fetch';
import { setValueByPath } from '../../../../packages/neb-utils/utils';
import * as eobApi from '../../../api-clients/eob';
import * as eraApi from '../../../api-clients/era';
import * as eraEobApi from '../../../api-clients/era-eob';
import { ERA_EOB_STATUS } from '../../../utils/era';
import { openPayment } from '../../../utils/payment-util';
import {
  ESPECIFIC_ERA_RESOLUTION_MESSAGE,
  ERA_EOB_STATUS_CHANGE_SUCCESS_MESSAGE,
  errorOccurred,
  NO_ITEMS_INITIAL_LOAD,
} from '../../../utils/user-message';

export const ELEMENTS = {
  header: { id: 'header' },
  table: { id: 'table' },
  pagination: { id: 'pagination' },
  filters: { id: 'filters' },
  casesUnsupportedPage: { id: 'cases-unsupported-page' },
  tabs: {
    id: 'tabs',
  },
  description: { id: 'description' },
  buttonBar: { id: 'button-bar' },
};

export const TABS = {
  CLOSED: 'Closed',
  OPEN: 'Open',
};

class NebEraEobManagementController extends LitElement {
  static get properties() {
    return {
      __model: Array,
      __state: Object,
      __sortParams: Object,
      __selectedTab: String,
      __buttonLabel: String,
      __confirmationMessage: String,
      __clickNote: Boolean,
      __eraOverviewFF: Boolean,
      __hasRcmEraEobEnhancementsFF: Boolean,
      __hasAppliedFilters: Boolean,
      __hasDisableInitialLoadFF: Boolean,
      refreshing: { type: Boolean },
      layout: {
        type: String,
        reflect: true,
      },
      description: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
          flex: 1 0 0;
        }

        :host([layout='small']) {
          height: auto;
        }

        .description {
          padding: ${CSS_SPACING};
          margin: 0;
        }

        .header-container {
          display: flex;
          align-items: center;
          margin: 20px;
        }

        .header-text {
          flex: 1 0 0;
          height: fit-content;
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .container-links {
          display: flex;
          align-items: center;
        }

        .container-link {
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        .container-link-abandoned {
          margin-left: ${CSS_SPACING};
        }

        .container-link:focus,
        .container-link:hover {
          opacity: 0.65;
        }

        .icon {
          margin-right: 5px;
        }

        .icon-warning {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_ERROR};
        }

        .icon-batch {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .link {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-decoration: underline;
        }

        .pagination {
          display: flex;
          padding: ${CSS_SPACING};
        }

        .filters {
          margin-bottom: ${CSS_SPACING};
        }

        .span {
          margin-left: ${CSS_SPACING};
        }

        .button-bar {
          z-index: 1;
          padding-left: ${CSS_SPACING};
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__selectedTab = TABS.OPEN;
    this.__state = FetchService.createModel();
    this.__initialSortKey = 'number';
    this.__clickNote = false;
    this.__eraOverviewFF = false;
    this.__hasDisableInitialLoadFF = false;
    this.__hasAppliedFilters = false;
    this.__hasRcmEraEobEnhancementsFF = false;

    this.onRefreshTable = () => {};
    this.__sortParams = {
      key: this.__initialSortKey,
      dir: SORT_DIR.DESC,
    };

    this.layout = '';
  }

  __initHandlers() {
    this.__handlers = {
      pageChanged: index => {
        this.__service.setPageIndex(index);
      },
      changeState: state => {
        this.__state = state;
      },
      change: e => {
        setValueByPath(this.__state.pageItems, e.name.split('.'), e.value);
        this.__state = { ...this.__state };
      },
      changeSort: (_name, result) => {
        const sortKey = 'sortField';
        this.__sortParams = result[0];
        this.__service.setQuery(sortKey, result[0].key);
        this.__service.setQuery('sortDir', result[0].dir);
      },
      clickLink: (key, index) => {
        const row = this.__state.pageItems[index];
        this.__openLinkOverlay(key, row);
      },
      clickNote: value => {
        this.__clickNote = value;
      },
      tableChange: e => this.__handleRowCheck(e),
      selectAll: () => {
        this.__state = this.__setChecked(true);
      },
      deselectAll: () => {
        this.__state = this.__setChecked(false);
      },
      openEob: async () => {
        const payerPayment = await openOverlay(OVERLAY_KEYS.EOB_FORM);

        if (payerPayment) {
          await openOverlay(OVERLAY_KEYS.ERA_EOB_MANAGEMENT, {
            readonly: false,
            payerPayment,
          });
        }

        this.__load();
      },
      updateStatus: async checkedRowItems => {
        if (checkedRowItems.length) {
          const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Update ERA/EOB status',
            message: this.__confirmationMessage,
            confirmText: 'Yes',
            cancelText: 'No',
          });

          if (accepted) {
            this.__bulkUpdateStatus(checkedRowItems);
          }
        } else {
          this.__handlers.openNoClaimsPopup();
        }
      },
      openNoClaimsPopup: () =>
        openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: 'Bulk Actions',
          message:
            'Please select one or more ERAs/EOBs before performing an action.',
        }),
      applyFilters: model => {
        const filterQueries = this.__createFilterQueries(model);
        this.__service.setPageIndex(0);
        Object.entries(filterQueries).forEach(([k, v]) => {
          this.__service.setQuery(k, v);
        });

        this.__hasAppliedFilters = true;
        this.__load();
      },
      selectItemCell: (_, key, index) => {
        if (!this.__clickNote) this.__selectCell(key, index);
      },
      selectTab: selectedTab => {
        if (this.__selectTab === selectedTab) return;

        this.__hasAppliedFilters = false;
        this.__service.setPageIndex(0);
        this.__selectedTab = selectedTab;
      },
      updateBillingNotes: () => this.__load(),
    };
  }

  __initServices() {
    const client = async query => {
      if (this.__isInitialLoadDisabled()) {
        return EMPTY_RESPONSE;
      }

      const payload = {
        ...query,
        status: this.__selectedTab,
      };
      const data = await eraEobApi.getData(payload);
      return data;
    };

    if (this.__service) {
      this.__service.cancel();
    }

    this.__service = new FetchService(
      { onChange: this.__handlers.changeState },
      client,
      {
        hideInactive: false,
        sortParams: this.sortParams,
      },
    );
  }

  __isInitialLoadDisabled() {
    return this.__hasDisableInitialLoadFF && !this.__hasAppliedFilters;
  }

  async __bulkUpdateStatus(selectedItems) {
    if (!selectedItems.length) return;

    try {
      const erasToUpdate = selectedItems.filter(era => era.type === 'ERA');
      const eobsToUpdate = selectedItems.filter(eob => eob.type === 'EOB');

      if (erasToUpdate.length > 0) {
        const erasUpdatePayload = {
          eraIds: erasToUpdate.map(era => era.id),
          eraStatus: {
            status: this.statusToUpdate,
          },
        };
        await eraApi.bulkUpdateStatus(erasUpdatePayload);
      }

      if (eobsToUpdate.length > 0) {
        const eobsUpdatePayload = {
          eobIds: eobsToUpdate.map(eob => eob.id),
          eobStatus: {
            status: this.statusToUpdate,
          },
        };
        await eobApi.bulkUpdateStatus(eobsUpdatePayload);
      }

      store.dispatch(
        openSuccess(
          ERA_EOB_STATUS_CHANGE_SUCCESS_MESSAGE(selectedItems.length),
        ),
      );

      this.__load();
    } catch (error) {
      store.dispatch(openError(errorOccurred('updating', 'ERA/EOB status')));
    }
  }

  async connectedCallback() {
    const features = await getFeatures();

    this.__eraOverviewFF = features.includes(FEATURE_FLAGS.ERA_OVERVIEW);

    this.__hasDisableInitialLoadFF = features.includes(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    this.__hasRcmEraEobEnhancementsFF = features.includes(
      FEATURE_FLAGS.ERA_EOB_ENHANCEMENTS,
    );

    super.connectedCallback();
  }

  // eslint-disable-next-line complexity
  __createFilterQueries(model) {
    let value;

    const excludedProperties = [
      'date',
      'payerAmount',
      'balanceAmount',
      'reportId',
      'paymentId',
      'type',
    ];
    const filteredQuery = Object.entries(model)
      .map(([k, v]) => {
        value = v;

        if (excludedProperties.includes(k)) return {};

        if (Array.isArray(v)) value = !v.length ? undefined : v;
        return { [k]: value || undefined };
      })
      .reduce((o, element) => ({ ...o, ...element }), {});
    return {
      ...filteredQuery,
      dateFrom: model.date.from
        ? model.date.from.format(ISO_DATE_FORMAT)
        : undefined,
      dateTo: model.date.to ? model.date.to.format(ISO_DATE_FORMAT) : undefined,
      payerAmountMin:
        model.payerAmount.min !== null ? model.payerAmount.min : undefined,
      payerAmountMax:
        model.payerAmount.max !== null ? model.payerAmount.max : undefined,
      balanceAmountMin:
        model.balanceAmount.min !== null ? model.balanceAmount.min : undefined,
      balanceAmountMax:
        model.balanceAmount.max !== null ? model.balanceAmount.max : undefined,
      status: this.__selectedTab,
      reportNumber: model.reportId.length ? model.reportId : undefined,
      paymentNumber: model.paymentId.length ? model.paymentId : undefined,
      type:
        model.type &&
        model.type.data !== null &&
        model.type.data.id !== null &&
        model.type.data.id.length
          ? model.type.data.id
          : undefined,
    };
  }

  __load() {
    this.__updateMessagesFromStatus();
    this.__service.fetch();
  }

  __updateMessagesFromStatus() {
    const currentTabIsOpen = this.__selectedTab === TABS.OPEN;

    this.statusToUpdate = currentTabIsOpen
      ? ERA_EOB_STATUS.CLOSED
      : ERA_EOB_STATUS.OPEN;

    this.description = currentTabIsOpen
      ? 'Review open ERAs/EOBs and perform updates.'
      : 'Review closed ERAs/EOBs.';

    this.__buttonLabel = `Change Status to ${this.statusToUpdate}`;
    this.__confirmationMessage = `Are you sure you would like to update the status of the selected ERAs/EOBs to ${
      this.statusToUpdate
    }?`;
  }

  updated(changedProps) {
    if (changedProps.has('__selectedTab')) {
      this.__hasAppliedFilters = false;
      this.__load();
    }

    super.updated(changedProps);
  }

  __refresh() {
    this.__load();
    this.onRefreshTable();
  }

  async __openLinkOverlay(key, row) {
    switch (key) {
      case 'payer':
        await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: row.payments[0].payerPlan.id,
        });

        break;
      case 'payment':
        await openPayment({
          payment: row.payments[0],
          readonly: false,
          eraEobInfo: row,
        });

        break;
      default:
        return;
    }
    this.__refresh();
  }

  async __selectEraEob(item) {
    if (!this.__clickNote) {
      const { data } = await eraEobApi.getDataById({
        id: item.id,
        type: item.type,
      });

      if (data.length) {
        await openOverlay(OVERLAY_KEYS.ERA_EOB_MANAGEMENT, {
          readonly: false,
          era: data[0],
          hasRcmEraEobEnhancementsFF: this.__hasRcmEraEobEnhancementsFF,
        });
      } else {
        store.dispatch(openError(`${item.type} not found.`));
      }

      this.__refresh();
    }
  }

  __selectCell(key, index) {
    if (key !== 'checked') {
      this.__selectEraEob(this.__state.pageItems[index]);
    }
  }

  __setChecked(checked) {
    this.__state.pageItems = this.__state.pageItems.map(item => ({
      ...item,
      checked,
    }));

    return { ...this.__state };
  }

  __handleRowCheck(e) {
    const terms = e.name.split('.');
    const index = terms[0];

    const updatedItem = { ...this.__state.pageItems[index] };
    updatedItem.checked = e.value;
    this.__state.pageItems.splice(index, 1, updatedItem);
    this.__state = {
      ...this.__state,
      pageItems: [...this.__state.pageItems],
    };
  }

  __getTabs() {
    return [
      {
        id: TABS.OPEN,
        label: TABS.OPEN,
      },
      {
        id: TABS.CLOSED,
        label: TABS.CLOSED,
      },
    ];
  }

  __getEmptyMessage() {
    return this.__isInitialLoadDisabled() ? NO_ITEMS_INITIAL_LOAD : 'No items';
  }

  __renderSmall() {
    return html`
      <neb-unsupported-page
        id="${ELEMENTS.casesUnsupportedPage.id}"
        .layout="${this.layout}"
        icon="wallet"
        location="ERA Management"
        specificMessage="${ESPECIFIC_ERA_RESOLUTION_MESSAGE}"
      >
      </neb-unsupported-page>
    `;
  }

  __renderFilters() {
    return html`
      <neb-filters-era-eob-management
        id="${ELEMENTS.filters.id}"
        class="filters"
        .onApply="${this.__handlers.applyFilters}"
        .eraOverviewFF="${this.__eraOverviewFF}"
      ></neb-filters-era-eob-management>
    `;
  }

  __renderTable() {
    return html`
      <neb-table-era-eob-management
        id="${ELEMENTS.table.id}"
        class="table"
        .sortParams="${[this.__sortParams]}"
        .model="${this.__state.pageItems}"
        .onChange="${this.__handlers.tableChange}"
        .onSelectAll="${this.__handlers.selectAll}"
        .onDeselectAll="${this.__handlers.deselectAll}"
        .onUpdateStatus="${this.__handlers.updateStatus}"
        .onClickNote="${this.__handlers.clickNote}"
        .onOpenNoClaimsPopup="${this.__handlers.openNoClaimsPopup}"
        .onSort="${this.__handlers.changeSort}"
        .onClickLink="${this.__handlers.clickLink}"
        .onSelectCell="${this.__handlers.selectItemCell}"
        .onUpdateBillingNotes="${this.__handlers.updateBillingNotes}"
        .buttonLabel="${this.__buttonLabel}"
        .eraOverviewFF="${this.__eraOverviewFF}"
        .emptyMessage="${this.__getEmptyMessage()}"
      ></neb-table-era-eob-management>
    `;
  }

  __renderTitle() {
    return html`
      <div class="header-container">
        <div id="${ELEMENTS.header.id}" class="header-text">
          ERA/EOB Charge Management
        </div>
      </div>
    `;
  }

  __renderPagination() {
    return this.__state.pageCount > 1
      ? html`
          <neb-pagination
            id="${ELEMENTS.pagination.id}"
            class="pagination"
            .currentPage="${this.__state.pageIndex}"
            .onPageChanged="${this.__handlers.pageChanged}"
            .pageCount="${this.__state.pageCount}"
          ></neb-pagination>
        `
      : '';
  }

  __buildButtonConfig() {
    return [
      {
        name: 'addEob',
        icon: 'plus',
        label: 'Add EOB',
        onClick: this.__handlers.openEob,
      },
    ];
  }

  __renderButtonBar() {
    return html`
      <neb-button-bar
        id="${ELEMENTS.buttonBar.id}"
        class="button-bar"
        .config="${this.__buildButtonConfig()}"
      ></neb-button-bar>
    `;
  }

  __renderTabs() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .layout="${this.layout}"
        .items="${this.__getTabs()}"
        .selectedId="${this.__selectedTab}"
        .onSelect="${this.__handlers.selectTab}"
      ></neb-tab-group>

      <p class="description" id="${ELEMENTS.description.id}">
        ${this.description}
      </p>

      ${this.__renderFilters()} ${this.__renderButtonBar()}
      ${this.__renderTable()} ${this.__renderPagination()}
    `;
  }

  render() {
    if (this.layout !== 'large') {
      return html`
        ${this.__renderSmall()}
      `;
    }

    return html`
      ${this.__renderTitle()} ${this.__renderTabs()}
    `;
  }
}

customElements.define(
  'neb-era-eob-management-controller',
  NebEraEobManagementController,
);
