/* eslint-disable complexity */
import '../../../../packages/neb-lit-components/src/components/patients/neb-patient-summary';
import '../../../../packages/neb-lit-components/src/components/neb-date-time';
import '../../../../packages/neb-material-design/src/components/neb-md-textarea';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';

import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { css, html } from 'lit';

import {
  DISPLAY_ON,
  createBannerController,
} from '../../../../packages/neb-alert/components/neb-alert-banner-controller';
import { getValidAvailability } from '../../../../packages/neb-api-client/src/availability-api-client';
import { getCalendarSummaryIntegrated } from '../../../../packages/neb-api-client/src/calendar-summary-api-client';
import { fetchMany as fetchCases } from '../../../../packages/neb-api-client/src/patient-cases';
import { getPatientGuarantors } from '../../../../packages/neb-api-client/src/patient-guarantor-api-client';
import { fetchMany as fetchReasons } from '../../../../packages/neb-api-client/src/reasons-api-client';
import {
  fetchManyRooms,
  fetchRoomAvailability,
} from '../../../../packages/neb-api-client/src/rooms-api-client';
import { fetchOne } from '../../../../packages/neb-api-client/src/settings-api-client';
import { openWarning } from '../../../../packages/neb-dialog/neb-banner-state';
import { toFraction } from '../../../../packages/neb-input/util/nebDatetimeUtil';
import '../../../../packages/neb-lit-components/src/components/forms/neb-form-recurring-appointment';
import '../../../../packages/neb-lit-components/src/components/neb-appointment-card';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { AvailabilityService } from '../../../../packages/neb-redux/services/availability';
import { LAYOUT_TYPE } from '../../../../packages/neb-redux/services/layout';
import { ProviderHoursService } from '../../../../packages/neb-redux/services/provider-hours';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_ERROR,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_HEADER,
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_SPACING_ROW_LARGE,
  CSS_BUTTON_SPACING_ROW,
  CSS_WARNING_COLOR,
} from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { fetchPatient } from '../../../../packages/neb-utils/patient';
import {
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
  filterAuthorizationsFromCases,
  hasAuthorizationRemaining,
} from '../../../../packages/neb-utils/patientAuthorization';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { UpdateNotificationService } from '../../../services/update-notifications';
import {
  DEFAULT_COLOR,
  EMPTY_RESCHEDULE_REASON,
  INITIAL_DURATION,
  MAX_DURATION,
  SELECT_BY_TYPE,
  formatSelectItems,
  getDurationDisplay,
} from '../../../utils/scheduling/appointments';
import { DATE_FORMAT } from '../../misc/neb-scheduling-calendar';

import '../../misc/neb-appointment-calendar-view';
import '../../../../packages/neb-lit-components/src/components/field-groups/neb-duration';
import NebFormSplitAppointment from './neb-form-split-appointment';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  containerHeader: {
    id: 'container-header',
  },
  textHeader: {
    id: 'text-header',
  },
  iconClose: {
    id: 'icon-close',
  },
  buttonNewPatient: {
    id: 'button-new-patient',
  },
  containerPatient: {
    id: 'container-patient',
  },
  textSearchPatient: {
    id: 'patient-search',
  },
  viewPatientSummary: {
    id: 'view-patient-summary',
  },
  selectInputAppointmentType: {
    id: 'select-appointment-type',
  },
  selectInputProvider: {
    id: 'select-provider',
  },
  selectInputLocation: {
    id: 'select-location',
  },
  selectRooms: {
    id: 'select-room',
  },
  buttonCase: {
    id: 'button-case',
  },
  caseSearch: {
    id: 'select-case',
  },
  tooltipCase: {
    id: 'tooltip-case',
  },
  tooltipAuth: {
    id: 'tooltip-auth',
  },
  selectAuthorizations: {
    id: 'select-auth',
  },
  cardOneTimeAppointment: {
    id: 'card-one-time',
  },
  cardRecurringSeries: {
    id: 'card-recurring',
  },
  formRecurringAppointment: {
    id: 'form-recurring',
  },
  viewDateTimePage: {
    id: 'date-time-page',
  },
  textNoteAppointment: {
    id: 'textarea-note',
  },
  containerRescheduleReason: {
    id: 'container-reschedule-reason',
  },
  selectInputRescheduleReason: {
    id: 'select-reschedule-reason',
  },
  textNoteReschedule: {
    id: 'text-reschedule',
  },
  calendarView: {
    id: 'calendar-view',
  },
  errorStart: {
    id: 'error-start',
  },
  iconStartError: {
    id: 'icon-start-error',
  },
  iconAuthorizationWarning: {
    id: 'icon-authorization-warning',
  },
  splitForm: {
    id: 'split-form',
  },
  addSplitButton: {
    id: 'add-split-button',
  },
};

const SERVICE_NAMES = [
  '__alertBanner',
  '__providerHoursService',
  '__availabilityService',
  '__updateNotificationService',
];

export const COLOR_VIEW_KEY = 'colorView';
export const COLOR_VIEW = {
  PROVIDER: 'provider',
  ROOM: 'room',
};

function getColorViewFromLocalStorage() {
  const storedProp = localStorage.getItem(COLOR_VIEW_KEY);

  return storedProp || COLOR_VIEW.PROVIDER;
}

export default class NebFormAppointment extends NebForm {
  static get properties() {
    return {
      locations: Array,
      providers: Array,

      __oneTimeAppointment: Boolean,
      __preventFetching: Boolean,
      __shouldFocus: Boolean,
      appointmentTypes: Array,
      __authorizations: Array,
      __filteredAppointmentTypes: Array,
      __cases: Array,
      __displayAuthorizations: Array,
      __rescheduleReasons: Array,
      __rooms: Array,
      __filteredRooms: Array,
      __timeFrameAvailabilities: Array,
      __availability: Object,
      __calendarSummary: Object,
      __dateOverride: Object,
      __patient: Object,
      __schedulingSettings: Object,
      __selectedAuthorization: Object,
      __colorView: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          overflow: hidden;
        }

        .container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .content {
          display: grid;
          overflow: auto;
          grid-template-columns:
            minmax(min-content, 1fr) minmax(0, max-content)
            minmax(200px, 800px);
        }

        .calendar-view {
          grid-column: 2;
          overflow-y: auto;
          display: flex;
          user-select: none;
        }

        :host([layout='small']) .content {
          display: grid;
          grid-template-columns: 1fr;
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-content {
          grid-column: 3;
          overflow: hidden auto;
        }

        .content-summary {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          grid-column: 1;
        }

        .search {
          width: 100%;
        }

        .patient-summary {
          padding: ${CSS_SPACING};
        }

        :host([layout='small']) .patient-summary {
          padding: 0;
        }

        .content-right {
          padding: ${CSS_SPACING};
          display: grid;
        }

        .content-form {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
        }

        .span-2 {
          grid-column: span 2;
        }

        .button-add-patient {
          padding: ${CSS_SPACING} 0 13px;
        }

        .button {
          padding: ${CSS_BUTTON_SPACING_ROW} 0;
        }

        .container-reschedule-reason {
          padding-top: ${CSS_SPACING};
        }

        .text-note-reschedule {
          margin-top: ${CSS_SPACING};
        }

        .container-header {
          display: grid;
          grid-template-columns: 1fr min-content;
        }

        .text-header {
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: bold;
        }

        .row-with-tooltip {
          display: grid;
          grid-column: span 2;
          grid-template-columns: 1fr min-content;
          align-items: center;
          grid-gap: ${CSS_SPACING};
        }

        .tooltip {
          padding-top: 10px;
        }

        .appointment-card-container {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          grid-column-gap: ${CSS_SPACING};
          grid-column: span 2;
          padding-top: ${CSS_SPACING_ROW_LARGE};
        }

        :host(:not([layout='large'])) .appointment-card-container {
          display: grid;
          grid-template-columns: 1fr;
          grid-column: span 2;
        }

        .container-note {
          display: grid;
          grid-column: span 2;
          padding-bottom: ${CSS_SPACING};
        }

        .appointment-card {
          height: 120px;
        }

        .date-time-page,
        .recurring-appointment-page {
          display: grid;
          grid-column: span 2;
          padding-top: ${CSS_SPACING_ROW};
        }

        .icon-authorization-warning {
          display: block;
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_WARNING_COLOR};
        }

        .icon-close {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .spacer {
          height: ${CSS_SPACING};
        }

        .start-error-container {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-column: span 2;
          grid-gap: ${CSS_SPACING_ROW_LARGE};
          color: ${CSS_COLOR_ERROR};
          align-items: center;
        }

        .start-error-icon {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_ERROR};
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.providers = [];
    this.locations = [];
    this.appointmentTypes = [];
    this.__schedulingSettings = {
      providerRequired: true,
      resourceRequired: false,
    };

    this.__oneTimeAppointment = true;
    this.__preventFetching = false;
    this.__shouldFocus = false;
    this.__authorizations = [];
    this.__filteredAppointmentTypes = [];
    this.__cases = [];
    this.__displayAuthorizations = [];
    this.__rescheduleReasons = [EMPTY_RESCHEDULE_REASON];
    this.__rooms = [];
    this.__filteredRooms = [];
    this.__timeFrameAvailabilities = [];
    this.__roomAvailability = [];
    this.__colorView = '';

    this.__availability = null;

    this.__dateOverride = null;
    this.__patient = null;
    this.__selectedAuthorization = {};

    this.__availabilityService = new AvailabilityService(() => {});
    this.__providerHoursService = new ProviderHoursService(timeFrames => {
      this.__timeFrameAvailabilities = this.state.providerId.data.id
        ? timeFrames[this.state.providerId.data.id]
        : timeFrames.roomOnlyHours;
    });

    this.__updateNotificationService = new UpdateNotificationService({
      callback: () => this.__loadPatient(),
      query: {},
    });

    this.__alertBanner = createBannerController(DISPLAY_ON.scheduling);
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      addSplit: () => {
        this.formService.addItem('splits');
        this.__updateSplits(parseDate(this.state.start));
      },
      addPatient: async () => {
        const newPatient = await openOverlay(OVERLAY_KEYS.PATIENT);

        if (newPatient && newPatient.id) {
          this.formService.apply('patientId', newPatient);
          this.__applyPatientPreferred(newPatient);
          this.__resetCases();

          this.__updateNotificationService.update({
            patient: {
              id: newPatient.id,
            },
          });
        }
      },
      addCase: async () => {
        const activeCases =
          this.__cases.length === 0
            ? 0
            : this.__cases.filter(c => c.data.active).length;
        const isFirstCase = this.__cases.length === 0 || activeCases === 0;

        const guarantors = await getPatientGuarantors(
          this.state.patientId.id,
          {},
          true,
        );

        const newCase = await openOverlay(OVERLAY_KEYS.CASE, {
          context: {
            patientId: this.state.patientId.id,
            guarantors: guarantors.filter(g => g.relation !== 'Self'),
            isFirstCase,
          },
        });

        if (newCase && newCase.id) {
          await this.__loadCases(this.state.patientId.id);

          const matchedCase = this.__cases.find(c => c.data.id === newCase.id);
          this.formService.apply('caseId', matchedCase);
          this.__changeAuthorization({ caseId: matchedCase.data.id });
        }
      },
      changePatient: async (patient, reschedule = false) => {
        if (patient) {
          this.formService.apply('patientId', patient);

          if (!reschedule) {
            this.__applyPatientPreferred(patient);

            await this.__loadCases(patient.id);
            this.__handleDefaultCase();
          }

          this.__alertBanner.update(patient.id);

          this.__updateNotificationService.update({
            patient: {
              id: patient.id,
            },
          });
        } else {
          this.formService.apply('patientId', '');
          this.formService.apply('caseId', selectors.ITEM_EMPTY);
          this.__changeAuthorization();
          this.__alertBanner.reset();
        }
      },
      changeAndFilter: async e => {
        if (!equal(e.value, this.state[e.name])) {
          this.formService.apply(e.name, e.value);

          if (e.name === 'locationId') {
            this.__filterRooms();
            this.__resetSplitsForLocationChange();
          }

          if (e.name === 'resourceId') {
            if (e.value.data.checkInAvailable) {
              this.formService.apply('roomId', e.value);
            } else {
              this.formService.apply('roomId', selectors.ITEM_EMPTY);
            }

            await this.__fetchRoomAvailability();
          }

          if (e.name !== 'appointmentTypeId') {
            this.__filterAppointmentTypes();
          } else if (!this.state.splits.length) {
            this.formService.apply(
              'duration',
              this.state.appointmentTypeId.data.duration,
            );
          }

          if (this.__typeDisabled()) {
            this.formService.apply('appointmentTypeId', selectors.ITEM_EMPTY);
            this.__availability = null;
          }

          await this.__fetchAvailabilityAndSummary();

          this.__providerHoursService.update(
            this.state.appointmentTypeId.data.id,
            this.__roomAvailability,
          );
        }
      },
      change: e => {
        this.formService.apply(e.name, e.value);

        if (e.name === 'duration') {
          this.__fetchAvailabilityAndSummary();
        }
      },
      changeCase: ({ value }) => {
        if (!equal(value, this.state.caseId)) {
          this.formService.apply('caseId', value);
          this.__changeAuthorization({ caseId: value.data.id });
        }
      },
      changeAuth: ({ value }) => {
        if (!equal(value, this.__selectedAuthorization)) {
          if (
            equal(value, selectors.ITEM_EMPTY) &&
            this.state.caseId.data.patientAuthorization.id
          ) {
            this.formService.apply('caseId', selectors.ITEM_EMPTY);
          } else {
            const matchedCase = this.__cases.find(
              c => c.data.id === value.patientCaseId,
            );

            if (matchedCase.data.id && !equal(matchedCase, this.state.caseId)) {
              this.formService.apply('caseId', matchedCase);
            }
          }

          this.__changeAuthorization({ authId: value.id });
        }
      },
      toggleColorView: async e => {
        const view = e ? COLOR_VIEW.PROVIDER : COLOR_VIEW.ROOM;
        this.__colorView = view;
        localStorage.setItem(COLOR_VIEW_KEY, view);
        await this.__fetchAvailabilityAndSummary();
      },
      authorizationBannerClick: async () => {
        const result = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          id: this.state.patientAuthorizationId,
          patientId: this.state.patientId.id,
          patientCaseId: this.state.caseId.data.id,
        });

        if (result) {
          await this.__loadCases(this.state.patientId.id);

          this.__changeAuthorization({ caseId: this.state.caseId.data.id });
        }
      },
      authorizationWarningClick: () => this.__showAuthorizationWarning(),
      cardClick: async () => {
        if (this.__oneTimeAppointment && this.state.splits.length) {
          const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Split Appointment',
            message:
              'Scheduling this appointment as a recurring series will remove the split rooms, and use the first room selected. Are you sure you want to continue?',

            confirmText: 'Continue',
            cancelText: 'Cancel',
          });

          if (!accepted) return;

          this.__removeSplits();
        }

        this.__oneTimeAppointment = !this.__oneTimeAppointment;
        if (this.__oneTimeAppointment) this.formService.apply('rrule', '');

        this.__fetchAvailabilityAndSummary();
      },
      changeDate: async date => {
        await this.__changeDate(date);
      },
      changeType: async e => {
        if (e.value !== this.state.type) {
          this.handlers.change(e);
          await this.__fetchAvailabilityAndSummary();
          this.formService.validateKey(['start'], true);
        }
      },
      changeMonth: async e => {
        const { month } = e;
        const { year } = e;
        const monthStart = parseDate(this.state.start)
          .year(year)
          .month(month - 1)
          .date(1);

        this.formService.apply('start', monthStart.toISOString());

        await this.__fetchAvailabilityAndSummary();
      },
      recurringChange: rState => {
        const duration = this.__getDuration(rState.durationTime);
        const rruleString = rState.rrule ? rState.rrule.toString() : '';

        if (rruleString && rruleString !== this.state.rrule) {
          this.formService.apply('rrule', rruleString);
        }

        if (duration && duration !== this.state.duration) {
          this.formService.apply('duration', duration);
        }

        if (rState.startDate && rState.startDate !== this.state.start) {
          this.formService.apply('start', rState.startDate);
        }

        this.__fetchAvailabilityAndSummary();
      },
      preventFetching: v => {
        this.__preventFetching = v;
      },
      updateCalendar: async () => {
        await this.__fetchAvailabilityAndSummary();
      },
      resizeDown: duration => {
        this.formService.apply('duration', duration);
      },
      resizeUp: async duration => {
        duration = Math.min(duration, MAX_DURATION);

        const durationDelta = duration - this.state.duration;

        const newStart = parseDate(this.state.start).subtract(
          durationDelta,
          'ms',
        );

        if (newStart.isSame(this.state.start, 'day')) {
          this.formService.apply('duration', duration);

          await this.__changeDate(newStart);
        }
      },
      changeSplit: e => {
        this.formService.apply(e.name, e.value);
        this.__updateSplits(parseDate(this.state.start));
      },
      removeSplit: index => {
        this.formService.removeItem('splits', index);

        if (this.state.splits.length) {
          this.__updateSplits(parseDate(this.state.start), false);
        }
      },
      removeSplits: () => {
        this.__removeSplits();
      },
      save: (...args) => {
        if (this.__oneTimeAppointment || this.__isValidRecurrence()) {
          this.save(...args);
        }
      },
    };
  }

  static createModel() {
    const dateRoundCoeff = 1000 * 60 * 30;

    let dateStart = parseDate()
      .seconds(0)
      .milliseconds(0);

    dateStart = parseDate(
      Math.ceil(dateStart.valueOf() / dateRoundCoeff) * dateRoundCoeff,
    );

    return {
      id: '',
      patientId: '',
      providerId: '',
      locationId: '',
      resourceId: '',
      roomId: '',
      caseId: '',
      patientAuthorizationId: null,
      appointmentTypeId: '',
      start: dateStart.toISOString(),
      duration: INITIAL_DURATION,
      walkIn: false,
      type: SELECT_BY_TYPE.DATE,
      rrule: '',
      note: '',
      cancelRescheduleReasonId: EMPTY_RESCHEDULE_REASON.data.id,
      rescheduleReason: '',
      quickActionFetch: false,
      providerCalendarFetch: false,
      roomCalendarFetch: false,
      splits: [],

      dragAndDropStart: '',
      dragAndDropProviderId: '',
      dragAndDropLocationId: '',
      dragAndDropResourceId: '',
      splitRescheduleIndex: 0,
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: {
          ...selectors.select([], selectors.ITEM_EMPTY, {
            validators: [
              {
                error: 'Required',
                validate: v => v.id,
              },
            ],
          }),
          format: () =>
            this.__patient && this.__patient.id === this.model.patientId
              ? { ...this.__patient }
              : '',
          unformat: v => v.id || null,
        },
        providerId: {
          ...selectors.select(this.providers, selectors.ITEM_EMPTY, {
            validators: [
              {
                error: 'Required',
                validate: v => {
                  if (
                    this.__schedulingSettings &&
                    this.__schedulingSettings.providerRequired
                  ) {
                    return v.data && v.data.id;
                  }
                  return true;
                },
              },
            ],
          }),
          format: v => {
            if (v) {
              return (
                this.providers.find(item => item.data.id === v) ||
                selectors.ITEM_EMPTY
              );
            }

            const preferredProvider =
              this.__patient && this.__patient.preferredProviderId
                ? this.providers.find(
                    l => l.data.id === this.__patient.preferredProviderId,
                  )
                : null;

            const singleProvider =
              this.providers.length === 1
                ? this.providers[0]
                : selectors.ITEM_EMPTY;
            return preferredProvider || singleProvider;
          },
          unformat: v => (v.data ? v.data.id : ''),
        },
        locationId: {
          ...selectors.select(this.locations, selectors.ITEM_EMPTY, {
            validators: [
              {
                error: 'Required',
                validate: v => v.data.id,
              },
            ],
          }),
          format: v => {
            if (v) {
              return (
                this.locations.find(item => item.data.id === v) ||
                selectors.ITEM_EMPTY
              );
            }

            const preferredLocation =
              this.__patient && this.__patient.preferredLocationId
                ? this.locations.find(
                    l => l.data.id === this.__patient.preferredLocationId,
                  )
                : null;
            const singleLocation =
              this.locations.length === 1
                ? this.locations[0]
                : selectors.ITEM_EMPTY;
            return preferredLocation || singleLocation;
          },
          unformat: v => (v.data ? v.data.id : ''),
        },
        resourceId: {
          ...selectors.select(this.__rooms, selectors.ITEM_EMPTY, {
            validators: [
              {
                error: 'Required',
                validate: v => {
                  if (
                    this.__schedulingSettings &&
                    this.__schedulingSettings.resourceRequired
                  ) {
                    return v.data && v.data.id;
                  }
                  return true;
                },
              },
            ],
          }),
        },
        roomId: selectors.select(this.__rooms, selectors.ITEM_EMPTY, {
          validators: [],
        }),
        appointmentTypeId: {
          ...selectors.select(this.appointmentTypes, selectors.ITEM_EMPTY, {
            validators: [
              {
                error: 'Required',
                validate: v => v.data.id,
              },
            ],
          }),
        },
        caseId: selectors.select(this.__cases, selectors.ITEM_EMPTY, {
          validators: [],
        }),
        cancelRescheduleReasonId: {
          ...selectors.select(
            this.__rescheduleReasons,
            EMPTY_RESCHEDULE_REASON,
            {
              validators: [],
            },
          ),
          format: v => {
            const reason = this.__rescheduleReasons.find(
              item => item.data.id === v,
            );
            const altReason =
              this.__rescheduleReasons.length > 1
                ? this.__rescheduleReasons[1]
                : EMPTY_RESCHEDULE_REASON;
            return reason || altReason;
          },
        },
        start: selectors.createDefaultModifiers({
          validators: [
            {
              error: '',
              validate: v => !!this.__availability && !!v,
            },
            {
              error:
                'Selected date not available. Choose a different date or select Override.',
              validate: v => {
                if (!this.__availability || this.__preventFetching) return true;

                if (
                  this.state.type === SELECT_BY_TYPE.CUSTOM ||
                  !this.__oneTimeAppointment
                ) {
                  return true;
                }

                const start = parseDate(v);
                const formattedDate = start.format('YYYY-MM-DD');
                const dayAvailability = this.__availability[formattedDate];

                const availableDay =
                  dayAvailability && dayAvailability.length > 0;

                return availableDay;
              },
            },
            {
              error:
                'Selected time not available. Choose a different time or select Override.',
              validate: v => {
                if (!this.__availability || this.__preventFetching) return true;

                if (
                  this.state.type === SELECT_BY_TYPE.CUSTOM ||
                  !this.__oneTimeAppointment
                ) {
                  return true;
                }

                const start = parseDate(v);
                const hours = start.hours();
                const minutes = start.minutes();

                const formattedDate = start.format('YYYY-MM-DD');
                const dayAvailability = this.__availability[formattedDate];
                const availableDay =
                  dayAvailability && dayAvailability.length > 0;

                const timeSlot = toFraction(hours, minutes, true);
                const availableSlot = dayAvailability.includes(timeSlot);
                return availableDay && availableSlot;
              },
            },
          ],
        }),
        splits: NebFormSplitAppointment.createSelectors(this.__rooms),
      },
    };
  }

  __isValidRecurrence() {
    const recurringForm = this.shadowRoot.getElementById(
      ELEMENTS.formRecurringAppointment.id,
    );

    return recurringForm.formService.validate();
  }

  __removeSplits() {
    const { length } = this.state.splits;

    for (let i = 0; i < length; i++) this.formService.removeItem('splits', 0);
  }

  __updateSplits(newStart, refresh = true) {
    let durationSum = 0;

    this.state.splits.forEach((split, index) => {
      const splitStart = newStart.clone().add(durationSum, 'ms');
      this.formService.apply(`splits.${index}.start`, splitStart.toISOString());

      durationSum += this.__getDuration(split.duration);
    });

    if (this.state.duration !== this.__getSplitsDuration()) {
      this.formService.apply('duration', this.__getSplitsDuration());
    }

    if (refresh) {
      this.__fetchAvailabilityAndSummary();
    }
  }

  async __changeDate(date) {
    const newStart = date;

    this.formService.apply('start', newStart.toISOString());

    if (this.state.splits.length) {
      this.__updateSplits(newStart, false);
    }
    await this.__fetchAvailabilityAndSummary();
  }

  __getDuration({ hours, minutes }) {
    return Math.floor(hours * (1000 * 60 * 60) + minutes * (1000 * 60));
  }

  __getSplitsDuration() {
    return this.state.splits.reduce(
      (acc, split) => acc + this.__getDuration(split.duration),
      0,
    );
  }

  async __loadPatient() {
    this.__patient = await fetchPatient(this.state.patientId.id, true);
    this.formService.apply('patientId', { ...this.__patient });
  }

  async __handlePatientPrefill() {
    if (this.model.patientId) {
      this.__patient = await fetchPatient(this.model.patientId, true);
      this.__updateNotificationService.update({
        patient: {
          id: this.model.patientId,
        },
      });
    }
    this.__shouldFocus = true;
  }

  async __loadSettings() {
    const {
      data: { providerRequired, resourceRequired },
    } = await fetchOne(true);

    this.__schedulingSettings = { providerRequired, resourceRequired };
  }

  async __loadRooms() {
    const res = await fetchManyRooms({ includeAppointmentTypes: true });
    const rooms = formatSelectItems(res);

    this.__rooms = rooms
      ? [selectors.ITEM_EMPTY, ...rooms]
      : [selectors.ITEM_EMPTY];
  }

  __filterRooms() {
    this.__filteredRooms = this.__rooms.filter(
      ({ data }) =>
        (data.active &&
          data.scheduleAvailable &&
          data.locationId === this.state.locationId.data.id) ||
        (data.id === '' && !this.__schedulingSettings.resourceRequired),
    );

    const roomIncluded = this.state.resourceId
      ? this.__filteredRooms.find(
          r => r.data.id === this.state.resourceId.data.id,
        )
      : null;

    if (!roomIncluded) {
      this.formService.apply('resourceId', selectors.ITEM_EMPTY);
      this.formService.apply('roomId', selectors.ITEM_EMPTY);

      this.__roomAvailability = [];

      this.__providerHoursService.update(
        this.state.appointmentTypeId.data.id,
        this.__roomAvailability,
      );
    }
  }

  __handleDefaultApptType() {
    const defaultType = this.__filteredAppointmentTypes.find(
      r => !!r.data.isDefault,
    );

    if (defaultType) {
      this.formService.apply('appointmentTypeId', defaultType);
      this.formService.apply('duration', defaultType.data.duration);
    } else {
      this.formService.apply('appointmentTypeId', selectors.ITEM_EMPTY);
      this.__availability = null;
    }
  }

  __filterAppointmentTypes() {
    if (this.appointmentTypes && this.appointmentTypes.length > 0) {
      const { providerRequired, resourceRequired } = this.__schedulingSettings;
      const { providerId, resourceId, locationId } = this.state;
      const roomAppointmentTypes = resourceId.data.appointmentTypes || [];

      this.__filteredAppointmentTypes = this.appointmentTypes.filter(
        apptType => {
          const { data } = apptType;

          const hasMatchingProvider = providerId
            ? !providerId.data.id || data.providers.includes(providerId.data.id)
            : false;
          const hasMatchingLocation = locationId
            ? data.locations.includes(locationId.data.id)
            : false;

          const hasMatchingAppointmentType =
            resourceId.data.allTypes || roomAppointmentTypes.includes(data.id);

          if (providerRequired && resourceRequired) {
            return (
              hasMatchingProvider &&
              hasMatchingLocation &&
              hasMatchingAppointmentType &&
              data.active
            );
          }

          if (providerRequired) {
            if (resourceId.data.id) {
              return (
                hasMatchingProvider &&
                hasMatchingLocation &&
                hasMatchingAppointmentType &&
                data.active
              );
            }
            return hasMatchingProvider && hasMatchingLocation && data.active;
          }

          if (resourceRequired) {
            if (providerId.data.id) {
              return (
                hasMatchingProvider &&
                hasMatchingLocation &&
                hasMatchingAppointmentType &&
                data.active
              );
            }
            return (
              hasMatchingLocation && hasMatchingAppointmentType && data.active
            );
          }

          return hasMatchingLocation && data.active;
        },
      );
    }

    const typeIncluded = this.__filteredAppointmentTypes.find(
      r => r.data.id === this.state.appointmentTypeId.data.id,
    );

    if (!typeIncluded && this.state.appointmentTypeId.data.active) {
      this.__handleDefaultApptType();
    }

    if (!this.state.appointmentTypeId.data.id) {
      this.__handleDefaultApptType();
    }
  }

  __formatGradualDate(date, dateFormat = 'MM/DD/YYYY') {
    return date ? parseDate(date).format(dateFormat) : 'Gradual';
  }

  async __loadCases(patientId) {
    if (patientId) {
      const cases = await fetchCases(patientId, true);
      this.__cases = [
        selectors.ITEM_EMPTY,
        ...cases.map(c => ({
          data: c,
          label: `${c.name} - ${this.__formatGradualDate(c.onsetSymptomsDate)}`,
        })),
      ];

      this.__authorizations = [
        selectors.ITEM_EMPTY,
        ...filterAuthorizationsFromCases(cases),
      ];

      this.__displayAuthorizations = [...this.__authorizations];
    }
  }

  __resetCases() {
    this.__cases = [selectors.ITEM_EMPTY];
    this.formService.apply('caseId', selectors.ITEM_EMPTY);
    this.formService.apply('patientAuthorizationId', null);
    this.__authorizations = [selectors.ITEM_EMPTY];
    this.__displayAuthorizations = [selectors.ITEM_EMPTY];
    this.__selectedAuthorization = { ...selectors.ITEM_EMPTY };
  }

  __handleDefaultCase() {
    if (this.state.patientId.id && !this.state.caseId.data.id) {
      const defaultCase = this.__cases.find(c => c.data.isDefault);

      if (defaultCase) {
        this.formService.apply('caseId', defaultCase);
        this.__changeAuthorization({ caseId: defaultCase.data.id });
      }
    }
  }

  async __loadReasons() {
    const res = await fetchReasons();

    const reasons = res
      .filter(item => item.active)
      .map(item => ({
        label: item.name,
        data: { id: item.id },
      }));

    this.__rescheduleReasons = [EMPTY_RESCHEDULE_REASON, ...reasons];
  }

  __connectServices() {
    SERVICE_NAMES.forEach(name => this[name].connect());
  }

  __disconnectServices() {
    SERVICE_NAMES.forEach(name => {
      try {
        this[name].disconnect();
      } catch (_) {
        // noop
      }
    });
  }

  connectedCallback() {
    this.__colorView = getColorViewFromLocalStorage();

    super.connectedCallback();

    this.__connectServices();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__disconnectServices();
  }

  async load() {
    await Promise.all([
      this.__loadSettings(),
      this.__handlePatientPrefill(),
      this.__loadRooms(),
      this.__loadCases(this.model.patientId),
      this.__loadReasons(),
    ]);
  }

  async reload() {
    this.__sideLoading = true;
    this.__reloadPromise = await this.load();

    this.build();
    this.__sideLoading = false;
    this.__reloadPromise = null;

    this.__filterRooms();
    this.__filterAppointmentTypes();

    if (this.model.id) {
      if (this.model.caseId && this.state.caseId.data.id) {
        this.__changeAuthorization({ caseId: this.model.caseId });
      }
    } else {
      this.__handleDefaultCase();
    }

    await this.__fetchRoomAvailability();

    this.__providerHoursService.update(
      this.state.appointmentTypeId.data.id,
      this.__roomAvailability,
    );

    await this.__fetchAvailabilityAndSummary();
  }

  __isQuickReschedule() {
    const {
      quickActionFetch,
      providerCalendarFetch,
      roomCalendarFetch,
    } = this.model;

    return quickActionFetch || providerCalendarFetch || roomCalendarFetch;
  }

  __resetSplitsForLocationChange() {
    if (this.state.splits.length) {
      this.__removeSplits();

      this.formService.addItem('splits');
      this.__updateSplits(parseDate(this.state.start), false);
    }
  }

  async __handleDragAndDropValues() {
    const { model } = this;

    if (model.providerCalendarFetch) {
      if (
        model.dragAndDropProviderId &&
        model.providerId !== model.dragAndDropProviderId
      ) {
        const newProvider = this.providers.find(
          p => p.data.id === model.dragAndDropProviderId,
        );

        this.formService.apply(
          'providerId',
          newProvider || selectors.ITEM_EMPTY,
        );
      }

      if (
        model.dragAndDropLocationId &&
        model.locationId !== model.dragAndDropLocationId
      ) {
        const newLocation = this.locations.find(
          p => p.data.id === model.dragAndDropLocationId,
        );

        this.formService.apply(
          'locationId',
          newLocation || selectors.ITEM_EMPTY,
        );

        this.__filterRooms();
        this.__resetSplitsForLocationChange();
      }
    }

    if (model.roomCalendarFetch) {
      if (model.dragAndDropResourceId) {
        const newResource = this.__filteredRooms.find(
          p => p.data.id === model.dragAndDropResourceId,
        );

        if (model.splitRescheduleIndex && newResource) {
          this.formService.apply(
            `splits.${model.splitRescheduleIndex}.resourceId`,
            newResource,
          );
        } else {
          this.formService.apply(
            'resourceId',
            newResource || selectors.ITEM_EMPTY,
          );

          if (newResource && newResource.data.checkInAvailable) {
            this.formService.apply('roomId', newResource);
          } else {
            this.formService.apply('roomId', selectors.ITEM_EMPTY);
          }

          await this.__fetchRoomAvailability();
        }
      }
    }

    if (model.dragAndDropStart && model.start !== model.dragAndDropStart) {
      this.formService.apply('start', model.dragAndDropStart);

      if (this.state.splits.length) {
        this.__updateSplits(parseDate(this.state.start));
      }
    }

    this.__filterAppointmentTypes();

    const appointmentType = this.__filteredAppointmentTypes.find(
      a => a.data.id === model.appointmentTypeId,
    );

    if (this.__typeDisabled() || !appointmentType) {
      this.formService.apply('appointmentTypeId', selectors.ITEM_EMPTY);
      this.__availability = null;
    }

    if (appointmentType) {
      this.formService.apply('appointmentTypeId', appointmentType);
    }

    await this.__fetchAvailabilityAndSummary();

    this.__providerHoursService.update(
      this.state.appointmentTypeId.data.id,
      this.__roomAvailability,
    );
  }

  async firstUpdated() {
    this.__alertBanner.update(this.model.patientId);

    if (!this.__isQuickReschedule()) {
      await this.reload();
    }
  }

  async updated(changedProps) {
    if (
      changedProps.has('model') &&
      this.model.id &&
      this.__isQuickReschedule()
    ) {
      await this.reload();
      await this.__handleDragAndDropValues();
    }

    if (changedProps.has('__preventFetching') && !this.__preventFetching) {
      await this.__fetchAvailabilityAndSummary();
    }
  }

  __renderSmall() {
    return html`
      <div class="content">
        ${this.__renderHeader()} ${this.__renderPatientSummary()}

        <div class="content-form">${this.__renderContent()}</div>
      </div>
    `;
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.containerHeader.id}" class="container-header">
        <div id="${ELEMENTS.textHeader.id}" class="text-header">
          ${!this.model.id ? 'New Appointment' : 'Reschedule Appointment'}
        </div>

        <neb-icon
          id="${ELEMENTS.iconClose.id}"
          class="icon icon-close"
          icon="neb:close"
          @click="${this.handlers.cancel}"
        ></neb-icon>
      </div>
      ${
        this.model.id
          ? html`
              <div class="spacer"></div>
            `
          : ''
      }
    `;
  }

  __applyPatientPreferred(patient) {
    let applied = false;

    if (patient.preferredProviderId) {
      const preferredProvider = this.providers.find(
        l => l.data.id === patient.preferredProviderId,
      );

      if (
        (!this.state.providerId || !this.state.providerId.data.id) &&
        preferredProvider
      ) {
        this.formService.apply(
          'providerId',
          preferredProvider || selectors.ITEM_EMPTY,
        );

        applied = true;
      }
    }

    if (patient.preferredLocationId) {
      const preferredLocation = this.locations.find(
        l => l.data.id === patient.preferredLocationId,
      );

      if (
        (!this.state.locationId || !this.state.locationId.data.id) &&
        preferredLocation
      ) {
        this.formService.apply(
          'locationId',
          preferredLocation || selectors.ITEM_EMPTY,
        );

        this.__filterRooms();
        applied = true;
      }
    }

    if (applied) {
      this.__filterAppointmentTypes();
    }

    this.__fetchAvailabilityAndSummary();
  }

  __renderContainerPatient() {
    return !this.model.id
      ? html`
          <div class="span-2 patient-search">
            <neb-button-action
              id="${ELEMENTS.buttonNewPatient.id}"
              class="button-add-patient"
              label="New Patient"
              .onClick="${this.handlers.addPatient}"
            ></neb-button-action>

            <div
              id="${ELEMENTS.containerPatient.id}"
              class="container-patient span-2"
            >
              <neb-patient-search
                id="${ELEMENTS.textSearchPatient.id}"
                name="patientId"
                class="search"
                label="Search for a Patient..."
                helper="Required"
                maxVisibleItems="4"
                .patient="${this.state.patientId}"
                .shouldFocus="${this.__shouldFocus}"
                .onChange="${this.handlers.changePatient}"
                required
              ></neb-patient-search>
            </div>
          </div>
        `
      : '';
  }

  __getProviderItems(providers) {
    return this.__schedulingSettings &&
      !this.__schedulingSettings.providerRequired
      ? [selectors.ITEM_EMPTY, ...providers]
      : providers;
  }

  __renderProvider() {
    return html`
      <neb-select
        id="${ELEMENTS.selectInputProvider.id}"
        class="select select-provider span-2"
        name="providerId"
        label="Provider"
        .helper="${
          this.__schedulingSettings.providerRequired ? 'Required' : ''
        }"
        .items="${this.__getProviderItems(this.providers)}"
        .value="${this.state.providerId}"
        .error="${this.errors.providerId}"
        .onChange="${this.handlers.changeAndFilter}"
        ?disabled="${this.model.hasEncounter}"
        wrapText
      ></neb-select>
    `;
  }

  __renderLocation() {
    return html`
      <neb-select
        id="${ELEMENTS.selectInputLocation.id}"
        class="select select-location span-2"
        name="locationId"
        label="Location"
        helper="Required"
        .items="${this.locations}"
        .value="${this.state.locationId}"
        .onChange="${this.handlers.changeAndFilter}"
        .error="${this.errors.locationId}"
      ></neb-select>
    `;
  }

  __roomDisabled() {
    if (!this.state.locationId.data.id) {
      return true;
    }

    return this.__schedulingSettings.resourceRequired
      ? this.__filteredRooms.length < 1
      : this.__filteredRooms.length < 2;
  }

  __renderRoom() {
    return !this.state.splits.length
      ? html`
          <neb-select
            id="${ELEMENTS.selectRooms.id}"
            class="select select-room span-2"
            name="resourceId"
            label="Scheduled Room"
            .helper="${
              this.__schedulingSettings.resourceRequired ? 'Required' : ''
            }"
            .items="${this.__filteredRooms}"
            .value="${this.state.resourceId}"
            .onChange="${this.handlers.changeAndFilter}"
            .error="${this.errors.resourceId}"
            .disabled="${this.__roomDisabled()}"
          ></neb-select>

          <neb-button-action
            id="${ELEMENTS.addSplitButton.id}"
            class="button"
            label="Add Split Room"
            .onClick="${this.handlers.addSplit}"
            ?disabled="${!this.__oneTimeAppointment || this.__roomDisabled()}"
          ></neb-button-action>
        `
      : this.__renderSplits();
  }

  __typeDisabled() {
    return this.__filteredAppointmentTypes.length < 1;
  }

  __renderAppointmentType() {
    return html`
      <neb-select
        id="${ELEMENTS.selectInputAppointmentType.id}"
        class="select select-provider span-2"
        name="appointmentTypeId"
        label="Appointment Type"
        helper="Required"
        .items="${this.__filteredAppointmentTypes}"
        .value="${this.state.appointmentTypeId}"
        .error="${this.errors.appointmentTypeId}"
        .disabled="${this.__typeDisabled()}"
        .onChange="${this.handlers.changeAndFilter}"
        wrapText
      ></neb-select>
    `;
  }

  __showAuthorizationWarning() {
    if (
      this.__selectedAuthorization.id &&
      !hasAuthorizationRemaining(this.__selectedAuthorization)
    ) {
      if (this.layout === 'small') {
        store.dispatch(openWarning(NO_REMAINING_AUTHORIZATIONS_MESSAGE));
      } else {
        store.dispatch(
          openWarning(
            NO_REMAINING_AUTHORIZATIONS_MESSAGE,
            this.handlers.authorizationBannerClick,
          ),
        );
      }
    }
  }

  __changeAuthorization({ caseId, authId } = {}) {
    if (caseId) {
      this.__displayAuthorizations = this.__authorizations.filter(
        auth => auth.patientCaseId === caseId,
      );

      if (this.model.patientAuthorizationId) {
        const foundAuth = this.__displayAuthorizations.find(
          a => a.id === this.model.patientAuthorizationId,
        );

        if (!foundAuth) {
          this.__selectedAuthorization = this.__displayAuthorizations.length
            ? this.__displayAuthorizations[0]
            : { ...selectors.ITEM_EMPTY };
        } else {
          this.__selectedAuthorization = foundAuth;
        }
      } else {
        this.__selectedAuthorization = this.__displayAuthorizations.length
          ? this.__displayAuthorizations[0]
          : { ...selectors.ITEM_EMPTY };
      }

      this.__showAuthorizationWarning();
    } else if (authId) {
      this.__selectedAuthorization = this.__displayAuthorizations.length
        ? this.__displayAuthorizations.find(auth => auth.id === authId)
        : { ...selectors.ITEM_EMPTY };

      this.__showAuthorizationWarning();

      if (this.__selectedAuthorization) {
        const matchedCase = this.__cases.find(
          c => c.data.id === this.__selectedAuthorization.patientCaseId,
        );
        this.formService.apply('caseId', matchedCase);

        this.__displayAuthorizations = this.__authorizations.filter(
          auth => auth.patientCaseId === matchedCase.data.id,
        );
      }
    } else {
      this.__selectedAuthorization = { ...selectors.ITEM_EMPTY };
      this.__displayAuthorizations = [...this.__authorizations];
    }

    if (this.__selectedAuthorization && this.__selectedAuthorization.id) {
      this.formService.apply(
        'patientAuthorizationId',
        this.__selectedAuthorization.id,
      );
    } else this.formService.apply('patientAuthorizationId', null);
  }

  __renderCase() {
    return html`
      <neb-button-action
        id="${ELEMENTS.buttonCase.id}"
        class="button"
        label="Add Case"
        .disabled="${!this.state.patientId.id}"
        .onClick="${this.handlers.addCase}"
      ></neb-button-action>

      <div class="row-with-tooltip">
        <neb-select
          id="${ELEMENTS.caseSearch.id}"
          class="select select-appointment-type"
          name="caseId"
          label="Case"
          .items="${this.__cases}"
          .value="${this.state.caseId}"
          .error="${this.errors.caseId}"
          .disabled="${!this.state.patientId.id}"
          .onChange="${this.handlers.changeCase}"
          wrapText
        ></neb-select>

        <neb-tooltip class="tooltip" id="${ELEMENTS.tooltipCase.id}">
          <div slot="tooltip">
            Selecting a Case will also apply a valid associated Authorization to
            the appointment.
          </div>
        </neb-tooltip>
      </div>

      <div class="row-with-tooltip">
        <neb-select
          id="${ELEMENTS.selectAuthorizations.id}"
          class="select select-appointment-type"
          label="Authorizations"
          helper=""
          .disabled="${!this.state.patientId.id}"
          .items="${this.__displayAuthorizations}"
          .value="${this.__selectedAuthorization}"
          .onChange="${this.handlers.changeAuth}"
          showTitle
        ></neb-select>
        ${
          hasAuthorizationRemaining(this.__selectedAuthorization)
            ? html`
                <neb-tooltip class="tooltip" id="${ELEMENTS.tooltipAuth.id}">
                  <div slot="tooltip">
                    Selecting an Authorization will also apply the associated
                    Case to the appointment.
                  </div>
                </neb-tooltip>
              `
            : html`
                <div class="tooltip">
                  <neb-icon
                    id="${ELEMENTS.iconAuthorizationWarning.id}"
                    class="icon-authorization-warning"
                    icon="neb:warning"
                    @click="${this.handlers.authorizationWarningClick}"
                  ></neb-icon>
                </div>
              `
        }
      </div>
    `;
  }

  __renderAppointmentCards() {
    return !this.model.id
      ? html`
          <div class="appointment-card-container">
            <neb-appointment-card
              id="${ELEMENTS.cardOneTimeAppointment.id}"
              class="one-time-appointment appointment-card"
              title="One-Time Appointment"
              icon="calendar"
              ?selected="${this.__oneTimeAppointment}"
              .onClick="${this.handlers.cardClick}"
            ></neb-appointment-card>
            <neb-appointment-card
              id="${ELEMENTS.cardRecurringSeries.id}"
              class="recurring-series appointment-card"
              title="Recurring Series"
              ?selected="${!this.__oneTimeAppointment}"
              icon="loop"
              .onClick="${this.handlers.cardClick}"
            ></neb-appointment-card>
          </div>
        `
      : '';
  }

  __schedulingRequirementsFilled() {
    const { providerRequired, resourceRequired } = this.__schedulingSettings;

    if (providerRequired && resourceRequired) {
      return (
        !!this.state.providerId &&
        !!this.state.providerId.data.id &&
        !!this.state.resourceId &&
        !!this.state.resourceId.data.id
      );
    }

    if (providerRequired) {
      return this.state.providerId && !!this.state.providerId.data.id;
    }
    return this.state.resourceId && !!this.state.resourceId.data.id;
  }

  async __fetchRoomAvailability() {
    if (!this.state.resourceId || !this.state.resourceId.data.id) {
      this.__roomAvailability = [];
      return;
    }

    this.__roomAvailability = await fetchRoomAvailability(
      this.state.resourceId.data.id,
    );
  }

  async __updateAvailability() {
    const ready =
      !!this.state &&
      (this.__schedulingRequirementsFilled() &&
        !!this.state.appointmentTypeId.data.id &&
        !!this.state.locationId.data.id);

    if (ready) {
      const date = parseDate(this.state.start);

      try {
        this.__availability = await this.__availabilityService.updateAvailability(
          {
            appointmentId: this.state.id,
            appointmentTypeId: this.state.appointmentTypeId.data.id,
            date,
            disableFetch: !(
              this.state.type !== SELECT_BY_TYPE.CUSTOM &&
              this.__oneTimeAppointment === true
            ),
            duration: this.state.splits.length
              ? this.__getDuration(this.state.splits[0].duration)
              : this.state.duration,
            providerId: this.state.providerId.data.id || null,
            locationId: this.state.locationId.data.id,
            resourceId: this.state.resourceId.data.id,
          },
          this.__abortController.signal,
        );

        if (this.state.type === SELECT_BY_TYPE.TIME) {
          this.__setProviderAvailability();
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          throw error;
        }
      }
    }
  }

  async __updateSplitAvailability() {
    const start = parseDate(this.state.start);

    if (
      !this.state.splits.length ||
      !this.state.resourceId.data.id ||
      !this.state.appointmentTypeId.data.id
    ) {
      return;
    }

    try {
      const res = await getValidAvailability(
        {
          forAppointmentId: this.state.id,
          appointmentTypeId: this.state.appointmentTypeId.data.id,
          start,
          duration: this.state.duration,
          providerId: this.state.providerId.data.id || null,
          locationId:
            this.locations && this.locations.length > 1
              ? this.state.locationId.data.id
              : undefined,
          resourceId: this.state.resourceId.data.id,
          splits: this.state.splits.map(split => ({
            start: split.start,
            duration: this.__getDuration(split.duration),
            resourceId: split.resourceId.data.id,
          })),
        },
        this.__abortController.signal,
      );

      res.forEach((r, index) => {
        if (index < this.state.splits.length) {
          this.formService.apply(
            `splits.${index}.providerValid`,
            r.providerValid,
          );

          this.formService.apply(
            `splits.${index}.resourceValid`,
            r.resourceValid,
          );
        }
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  }

  __setProviderAvailability() {
    if (this.__availability && this.__selectedTimeRange) {
      Object.entries(this.__availability).forEach(([date, hours]) => {
        this.__availability[date] = hours.filter(hour =>
          this.__isHourWithinRange(hour),
        );
      });
    }
  }

  __renderOneTime() {
    return html`
      ${
        this.errors.start && this.__availability
          ? html`
              <div id="${ELEMENTS.errorStart.id}" class="start-error-container">
                <neb-icon
                  id="${ELEMENTS.iconStartError.id}"
                  class="start-error-icon"
                  icon="neb:warning"
                ></neb-icon>

                ${this.errors.start}
              </div>
            `
          : ''
      }

      <neb-date-time
        id="${ELEMENTS.viewDateTimePage.id}"
        class="date-time-page"
        .availability="${this.__availability}"
        .duration="${this.state.duration}"
        .hasSplits="${this.state.splits.length}"
        .layout="${this.layout}"
        .selectedDate="${parseDate(this.state.start)}"
        .timeFrameAvailabilities="${this.__timeFrameAvailabilities}"
        .type="${this.state.type}"
        .enableWalkIn="${this.__oneTimeAppointment}"
        .walkIn="${this.state.walkIn}"
        .onChange="${this.handlers.change}"
        .onChangeDate="${this.handlers.changeDate}"
        .onChangeType="${this.handlers.changeType}"
        .onChangeMonth="${this.handlers.changeMonth}"
        .onChangeTimeFrame="${this.handlers.changeTimeFrame}"
      ></neb-date-time>
    `;
  }

  __renderRecurring() {
    return html`
      <neb-form-recurring-appointment
        class="recurring-appointment-page"
        id="${ELEMENTS.formRecurringAppointment.id}"
        .appointmentDuration="${this.state.duration}"
        .selectedDate="${this.state.start}"
        .onRecurringModelChange="${this.handlers.recurringChange}"
        .layout="${this.layout}"
      ></neb-form-recurring-appointment>
    `;
  }

  renderLoadingBlocker() {
    return '';
  }

  __renderAppointmentNote() {
    return html`
      <neb-md-textarea
        id="${ELEMENTS.textNoteAppointment.id}"
        name="note"
        class="text text-note-appointment"
        labelText="Appointment Note"
        rows="4"
        maxLength="500"
        showCharacterCount
        .value="${this.state.note}"
        .onChange="${this.handlers.change}"
      >
      </neb-md-textarea>
    `;
  }

  __renderRescheduleReason() {
    return this.__rescheduleReasons.length > 1
      ? html`
          <neb-select
            id="${ELEMENTS.selectInputRescheduleReason.id}"
            class="dropdown dropdown-reschedule-reason"
            name="cancelRescheduleReasonId"
            label="Reschedule Reason"
            .items="${this.__rescheduleReasons}"
            .value="${this.state.cancelRescheduleReasonId}"
            .onChange="${this.handlers.change}"
          ></neb-select>
        `
      : '';
  }

  __renderContainerRescheduleReasons() {
    return this.model.id
      ? html`
          <div
            id="${ELEMENTS.containerRescheduleReason.id}"
            class="container-reschedule-reason"
          >
            ${this.__renderRescheduleReason()}

            <neb-textarea
              id="${ELEMENTS.textNoteReschedule.id}"
              class="text text-note-reschedule"
              label="Reschedule Note"
              name="rescheduleReason"
              maxLength="500"
              showCount
              .value="${this.state.rescheduleReason}"
              .onChange="${this.handlers.change}"
            >
            </neb-textarea>
          </div>
        `
      : '';
  }

  __renderPatientSummary() {
    return (this.layout !== LAYOUT_TYPE.SMALL && this.state.patientId.id) ||
      (this.layout === LAYOUT_TYPE.SMALL && !!this.model.id)
      ? html`
          <div class="content-summary">
            <neb-patient-summary-controller
              id="${ELEMENTS.viewPatientSummary.id}"
              class="patient-summary"
              .layout="${this.layout}"
              .patientId="${this.state.patientId.id}"
            >
            </neb-patient-summary-controller>
          </div>
        `
      : html``;
  }

  async __updateCalendarSummary() {
    if (this.__isCalendarVisible()) {
      const date = parseDate(this.state.start).format(DATE_FORMAT);

      try {
        this.__calendarSummary = await getCalendarSummaryIntegrated({
          start: date,
          end: date,
          providerId: this.state.providerId.data.id,
          locationId: this.state.locationId.data.id,
          resourceId: this.state.resourceId.data.id,
          signal: this.__abortController.signal,
          colorView: this.__colorView,
        });
      } catch (error) {
        if (error.name !== 'AbortError') {
          throw error;
        }
      }
    }
  }

  async __fetchAvailabilityAndSummary() {
    if (this.__preventFetching) {
      return;
    }

    if (this.__abortController) {
      this.__abortController.abort();
    }

    this.__abortController = new AbortController();

    await Promise.all([
      this.__updateAvailability(),
      this.__updateSplitAvailability(),
      this.__updateCalendarSummary(),
    ]);
  }

  __getAppointmentColor(appointmentTypeId) {
    const appointmentType = this.appointmentTypes.find(
      apptType => apptType.data.id === appointmentTypeId,
    );

    return appointmentType ? appointmentType.data.color : DEFAULT_COLOR;
  }

  __getCalendarAppointment() {
    const { id, duration, start, appointmentTypeId } = this.state;

    const momentStart = start ? parseDate(start) : null;

    if (!momentStart || !duration) return null;

    const appointmentStartMinutes =
      momentStart.hours() * 60 + momentStart.minutes();

    const durationMinutes = duration / 60000;

    const end = momentStart.clone().add(durationMinutes, 'minutes');

    const title = getDurationDisplay(momentStart, end);

    if (durationMinutes) {
      return {
        id,
        active: true,
        color: this.__getAppointmentColor(appointmentTypeId.data.id),
        title,
        start: momentStart.toISOString(),
        end: end.toISOString(),
        layout: {
          top: appointmentStartMinutes,
          left: 0,
          height: durationMinutes,
          width: 100,
        },
        preview: true,
      };
    }

    return null;
  }

  __isCalendarVisible() {
    return !!(
      this.state.locationId &&
      this.state.locationId.data.id &&
      ((this.state.providerId && this.state.providerId.data.id) ||
        (this.state.resourceId && this.state.resourceId.data.id))
    );
  }

  __renderCalendarColumn() {
    const start = this.state.start && parseDate(this.state.start);

    return this.__isCalendarVisible() && this.state.start
      ? html`
          <neb-appointment-calendar-view
            id="${ELEMENTS.calendarView.id}"
            class="calendar-view"
            .layout="${this.layout}"
            .appointment="${this.__getCalendarAppointment()}"
            .model="${this.__calendarSummary}"
            .start="${start}"
            .targetDate="${start.format(DATE_FORMAT)}"
            .providerId="${this.state.providerId.data.id}"
            .locationId="${this.state.locationId.data.id}"
            .resourceId="${this.state.resourceId.data.id}"
            .colorView="${this.__colorView}"
            .hasSplits="${!!this.state.splits.length}"
            .onClickTimeSlot="${this.handlers.changeDate}"
            .onResizeDown="${this.handlers.resizeDown}"
            .onResizeUp="${this.handlers.resizeUp}"
            .onUpdateResizing="${this.handlers.preventFetching}"
            .onUpdateCalendar="${this.handlers.updateCalendar}"
            .onToggleColorView="${this.handlers.toggleColorView}"
            .isResizing="${this.__preventFetching}"
          ></neb-appointment-calendar-view>
        `
      : '';
  }

  __renderSplits() {
    return html`
      <neb-form-split-appointment
        id="${ELEMENTS.splitForm.id}"
        class="span-2"
        name="splits"
        layout="${this.layout}"
        .enableConflictCheck="${true}"
        .appointmentRoom="${this.state.resourceId}"
        .rooms="${this.__filteredRooms.filter(room => room.data.id)}"
        .errors="${this.errors}"
        .splits="${this.state.splits}"
        .totalDuration="${this.__getSplitsDuration()}"
        .onChange="${this.handlers.changeSplit}"
        .onChangeAppointmentRoom="${this.handlers.changeAndFilter}"
        .onAddSplit="${this.handlers.addSplit}"
        .onRemoveSplit="${this.handlers.removeSplit}"
        .onRemoveSplits="${this.handlers.removeSplits}"
      ></neb-form-split-appointment>
    `;
  }

  __renderContent() {
    return html`
      ${this.__renderContainerPatient()}${this.__renderProvider()}
      ${this.__renderLocation()}${this.__renderRoom()}
      ${this.__renderAppointmentType()} ${this.__renderCase()}
      ${this.__renderAppointmentCards()}
      ${
        this.__oneTimeAppointment
          ? this.__renderOneTime()
          : this.__renderRecurring()
      }

      <div class="container-note">
        ${this.__renderAppointmentNote()}
        ${this.__renderContainerRescheduleReasons()}
      </div>
    `;
  }

  __renderBig() {
    return html`
      <div class="content">
        ${this.__renderPatientSummary()} ${this.__renderCalendarColumn()}

        <div class="container-content">
          <div class="content-right">
            ${this.__renderHeader()}

            <div class="content-form">${this.__renderContent()}</div>
          </div>
        </div>
      </div>
    `;
  }

  renderActionBar() {
    return this.__dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            class="action-bar"
            .confirmLabel="${!this.model.id ? 'SCHEDULE' : 'RESCHEDULE'}"
            .onCancel="${this.handlers.cancel}"
            .onConfirm="${this.handlers.save}"
          ></neb-action-bar>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.layout === 'small' ? this.__renderSmall() : this.__renderBig()}
        ${this.renderFooter()}
      </div>
    `;
  }
}

window.customElements.define('neb-form-appointment', NebFormAppointment);
