import '../neb-button';
import '../neb-loading-overlay';
import '../neb-text';

import { openPopup } from '@neb/popup';
import { css, html } from 'lit';
import moment from 'moment-timezone';

import * as clearinghouseApi from '../../../../../src/api-clients/clearinghouse';
import * as eClaimFileApi from '../../../../../src/api-clients/eclaim-file';
import {
  CONFIRM_X12_CLAIM_DOWNLOAD,
  generateFileName,
} from '../../../../../src/utils/e-claim-files';
import * as claimBatchesApi from '../../../../neb-api-client/src/claim-batches';
import * as eClaimsApi from '../../../../neb-api-client/src/electronic-claims/claims';
import { openError } from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_COLOR_GREY_1,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { downloadURLtoFile } from '../../../../neb-utils/blobProcessor';
import {
  TOOLTIP_TEXT,
  getPrintSettings,
  setPrintSettings,
} from '../../../../neb-utils/claims';
import {
  getClaimJsonFromS3,
  openS3ClaimTab,
} from '../../../../neb-utils/electronic-claims';
import { normalizeForSearch } from '../../../../neb-utils/formatters';
import { fetchPracticeUsers } from '../../../../neb-utils/neb-ledger-util';
import { MODE } from '../../../../neb-utils/table';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../neb-page-collection';
import { SORT_DIR } from '../neb-table-header';
import {
  CLAIM_BATCH_STATUS,
  CONFIG as TABLE_CONFIG,
} from '../tables/neb-table-claim-batches';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonRefresh: { id: 'button-refresh' },
  adjustPrintSettingsLink: { id: 'adjust-print-settings-link' },
  iconClose: { id: 'icon-close' },
  spinner: { id: 'spinner' },
};

class CollectionClaimBatches extends CollectionPage {
  static get config() {
    return {
      hideDetailArrow: true,
      hideHeader: false,
      initialSortKey: 'createdAt',
      initialSortOrder: SORT_DIR.DESC,
      pluralName: 'Claim Batches',
      searchLabel: 'Enter search term to filter list below.',
      showCancelButton: true,
      tableConfig: TABLE_CONFIG,
      unifyForm: true,
    };
  }

  static get properties() {
    return {
      __allLoading: Boolean,
      __loading: Array,
      __users: Array,
      __expandedFlags: Array,

      model: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .row {
          position: relative;
        }

        .container-header {
          display: flex;
          justify-content: space-between;
        }

        .container-header-actions {
          display: grid;
          justify-items: end;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
        }

        .container-print {
          padding: 5px 10px 0 0;
          display: flex;
        }

        .icon-close {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .button-refresh {
          padding-top: 15px;
          align-self: center;
        }

        .spinner {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__allLoading = false;
    this.__expandedFlags = [];
    this.__loading = [];
    this.__users = [];

    this.__tableState.hideInactive = null;
    this.model = {
      batchId: '',
    };

    this.onSelect = () => {};

    this.onCancel = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      cancel: () => this.onCancel(),
      refreshAll: async () => {
        const items = await this.fetch();
        this.service.setItems(items);
      },
      retryClaimBatch: async ({ index, value: claimId, eClaimFileId }) => {
        this.__loading[index] = true;
        this.__loading = [...this.__loading];

        try {
          await eClaimsApi.retryClaimBatch(eClaimFileId, claimId);
        } catch (retryClaimBatchError) {
          console.log(retryClaimBatchError);
        }

        const updatedClaimBatch = await claimBatchesApi.fetchOne(
          { eClaimFileId },
          claimId,
          true,
        );

        const newClaimBatches = this.service
          .getItems()
          .map(claimBatch =>
            updatedClaimBatch.id === claimBatch.id
              ? updatedClaimBatch
              : claimBatch,
          );

        this.service.setItems(newClaimBatches);

        this.__loading[index] = false;
        this.__loading = [...this.__loading];
      },
      refreshClaimBatch: async ({ index, value: claimId, eClaimFileId }) => {
        this.__loading[index] = true;
        this.__loading = [...this.__loading];
        const updatedClaimBatch = await claimBatchesApi.fetchOne(
          { eClaimFileId },
          claimId,
          true,
        );

        const newClaimBatches = this.service
          .getItems()
          .map(claimBatch =>
            updatedClaimBatch.id === claimBatch.id
              ? updatedClaimBatch
              : claimBatch,
          );

        this.service.setItems(newClaimBatches);

        this.__loading[index] = false;
        this.__loading = [...this.__loading];
      },
      sort: (_, result) => {
        this.service.setSortParams(result[0]);
      },
      openPrintSettings: async () => {
        const settings = getPrintSettings();

        const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_PRINT_SETTINGS, {
          title: 'Print Settings',
          tooltipText: TOOLTIP_TEXT.PRINT_OFFSETS,
          items: [
            {
              name: 'top',
              value: settings.top,
            },
            {
              name: 'left',
              value: settings.left,
            },
            {
              name: 'bold',
              value: settings.bold,
            },
          ],
        });

        if (result) {
          setPrintSettings(result);
        }
      },

      clickDownloadIcon: async item => {
        let allowDownload = true;

        if (item.status === CLAIM_BATCH_STATUS.PENDING) {
          allowDownload = await this.__openClaimX12DownloadPopup();
        }

        if (allowDownload) {
          try {
            const {
              clearinghouseName,
              createdAt,
              eClaimFileId,
              batchId,
              claimBatchId,
              rowIndex,
              clearinghouseId,
            } = item;

            const { id } = store.getState().session.item;

            const url = await eClaimFileApi.downloadFile(eClaimFileId, {
              updatedBy: id,
            });

            const clearinghouse = await clearinghouseApi.fetchOne(
              clearinghouseId,
            );

            await claimBatchesApi.updateBatchStatus(batchId);

            const {
              practiceIdentifierId,
              customExtension,
              customFilenameTemplate,
            } = clearinghouse;

            downloadURLtoFile(
              url,
              generateFileName({
                clearinghousePartner: clearinghouseName,
                batchId,
                practiceIdentifierId,
                customExtension,
                customFilenameTemplate,
                createdAt,
              }),
            );

            await this.handlers.refreshClaimBatch({
              index: rowIndex,
              value: claimBatchId,
              eClaimFileId,
            });
          } catch (err) {
            store.dispatch(
              openError(
                'An error occurred when attempting to download the file',
              ),
            );
          }
        }
      },
      clickS3Icon: async (record = {}) => {
        const { eClaimFileId, clearinghouseName, batchId } = record;

        const s3Data = await getClaimJsonFromS3(eClaimFileId);

        if (s3Data) {
          openS3ClaimTab({
            claim: s3Data,
            batchId,
            partnerName: clearinghouseName,
          });
        }
      },
      toggleExpand: (_name, _item, index, _expand) => {
        const expandFlags = this.__expandedFlags;
        expandFlags[index] = !expandFlags[index];
        this.__expandedFlags = [...expandFlags];
      },
    };
  }

  async connectedCallback() {
    await super.connectedCallback();

    this.__expandedFlags = this.__tableState.pageItems.map(() => false);
  }

  async fetch() {
    this.__allLoading = true;

    const { patientId } = this.model;

    const queryParams = {
      patientId,
    };

    // eslint-disable-next-line prefer-const
    const [claimBatches, users] = await Promise.all([
      claimBatchesApi.fetchMany(queryParams, true),
      fetchPracticeUsers(),
    ]);

    this.__users = users;

    this.__allLoading = false;

    return claimBatches;
  }

  formatSearchItem({ batchId, status, createdAt }) {
    const searchTerm = [
      batchId,
      status,
      moment(createdAt).format('MM-DD-YYYY'),
      moment(createdAt).format('MM/DD/YYYY'),
      moment(createdAt).format('MMDDYYYY'),
    ].join(' ');

    return normalizeForSearch(searchTerm);
  }

  filterSearchItem({ terms, item }) {
    return terms.every(term => item.includes(term));
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model.batchId) {
      this.service.search(this.model.batchId);
    }

    super.update(changedProps);
  }

  __renderLoadingOverlay() {
    return this.__allLoading
      ? html`
          <neb-loading-overlay
            id="${ELEMENTS.spinner.id}"
            class="spinner"
            title="Loading Batch Claims..."
            showDelay="0"
            .show="${this.__allLoading}"
          ></neb-loading-overlay>
        `
      : '';
  }

  renderNoItemsContent() {
    return 'There are currently no Claim Batches.';
  }

  async __openClaimX12DownloadPopup() {
    const result = await openPopup(
      POPUP_RENDER_KEYS.CONFIRM,
      CONFIRM_X12_CLAIM_DOWNLOAD,
    );

    return result;
  }

  __renderAdjustSettingsLink() {
    return html`
      <div class="container-print">
        <neb-text
          id="${ELEMENTS.adjustPrintSettingsLink.id}"
          link
          caption
          .onClick="${this.handlers.openPrintSettings}"
          >Adjust Print Settings
        </neb-text>
      </div>
    `;
  }

  renderHeader() {
    return !this.getConfig().hideHeader
      ? html`
          <div class="container-header">
            <neb-page-collection-header
              id="${ELEMENTS.header.id}"
              class="header"
              .hideInactive="${this.__tableState.hideInactive}"
              .layout="${this.layout}"
              .title="${this.getTitle()}"
              .description="${this.getConfig().description}"
              .searchLabel="${this.getConfig().searchLabel}"
              .searchText="${this.__tableState.searchText}"
              .onCancel="${this.handlers.cancel}"
              .onHideInactive="${this.handlers.hideInactive}"
              .onSearch="${this.handlers.search}"
              ?showCancelButton="${this.getConfig().showCancelButton}"
            ></neb-page-collection-header>

            <div class="container-header-actions">
              <neb-icon
                id="${ELEMENTS.iconClose.id}"
                class="icon-close"
                icon="neb:close"
                @click="${this.handlers.cancel}"
              ></neb-icon>

              <neb-button
                id="${ELEMENTS.buttonRefresh.id}"
                class="button-refresh"
                label="Refresh All"
                .onClick="${this.handlers.refreshAll}"
              ></neb-button>

              ${this.__renderAdjustSettingsLink()}
            </div>
          </div>
        `
      : '';
  }

  renderTable() {
    return html`
      <neb-table-claim-batches
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        emptyMessage="There are currently no Claim Batches."
        .layout="${this.layout}"
        .model="${this.__tableState.pageItems}"
        .loading="${this.__loading}"
        .config="${this.getConfig().tableConfig}"
        .mode="${MODE.EXPAND}"
        .expandFlags="${this.__expandedFlags}"
        .sortParams="${[this.__tableState.sortParams]}"
        .onChange="${this.handlers.tableChange}"
        .onRefresh="${this.handlers.refreshClaimBatch}"
        .onRetryBatch="${this.handlers.retryClaimBatch}"
        .onSort="${this.handlers.sort}"
        .users="${this.__users}"
        .onClickDownloadIcon="${this.handlers.clickDownloadIcon}"
        .onClickS3Icon="${this.handlers.clickS3Icon}"
        .patientId="${this.model.patientId}"
        .onSelect="${this.model.onSelect}"
        .onToggleExpand="${this.handlers.toggleExpand}"
        ?showDetailArrow="${!this.getConfig().hideDetailArrow}"
        >${this.renderTableSlot()}
      </neb-table-claim-batches>

      ${this.__renderLoadingOverlay()}
    `;
  }
}

window.customElements.define(
  'neb-collection-claim-batches',
  CollectionClaimBatches,
);
