import '../../../../packages/neb-lit-components/src/components/patients/cases/neb-patient-case-form';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import { html, css } from 'lit';

import { mapServerModelsToInsurances } from '../../../../packages/neb-api-client/src/mappers/patient-case-mapper';
import { fetchOne } from '../../../../packages/neb-api-client/src/patient-api-client';
import * as caseApi from '../../../../packages/neb-api-client/src/patient-cases';
import { getPatientInsurances } from '../../../../packages/neb-api-client/src/patient-insurance-api-client';
import { GuarantorService } from '../../../../packages/neb-api-client/src/services/guarantor';
import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { OrganizationService } from '../../../../packages/neb-redux/services/organization';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../packages/neb-utils/neb-refresh';
import { getPatientPackages } from '../../../api-clients/patient-package';
import { ADD_ONS, hasAddOn } from '../../../utils/add-ons';
import NebFormPatientCase, {
  ITEM_SELF,
} from '../../forms/patients/cases/neb-form-patient-case';

export const BANNER_SUCCESS = 'Case successfully saved.';
export const CASE_OVERRIDE =
  "Case billing override disabled. This can be enabled from the patient's Billing tab.";
export const BANNER_ERROR = 'An error occurred when saving the Case.';
export const ELEMENTS = {
  header: {
    id: 'header',
  },
  form: {
    id: 'form',
  },
};

class NebOverlayCase extends Overlay {
  static get properties() {
    return {
      __hasAddOnCTVerify: Boolean,
      __hasFFAuthTypeVisits: Boolean,

      __patientCase: Object,
      __guarantors: Array,
      __insurances: Array,
      __organizations: Array,
      __patientPackages: Array,
      __preferredProviderId: String,
      __newCase: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 80rem;
          flex-flow: column nowrap;
        }

        .form {
          flex: 1 0 0;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__patientCase = NebFormPatientCase.createModel();
    this.__guarantors = [];
    this.__hasAddOnCTVerify = false;
    this.__hasFFAuthTypeVisits = false;
    this.__insurances = [];
    this.__organizations = [];
    this.__patientPackages = [];
    this.__preferredProviderId = '';
    this.__newCase = null;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async (patientCase, closeAfterSave) => {
        const key = patientCase.id ? 'update' : 'add';

        try {
          const result = await caseApi[key](patientCase.patientId, patientCase);
          const { caseOverrideUpdated } = result;
          this.isDirty = false;
          this.__newCase = result;

          if (this.__hasFFAuthTypeVisits) {
            if (closeAfterSave) {
              this.dismiss(result);
            }

            if (key === 'update') {
              this.model = {
                ...this.model,
                item: { ...result.patientCase },
              };
            } else {
              this.model = {
                ...this.model,
                item: { ...result },
              };
            }

            this.__setPatientCase();
          } else {
            this.dismiss(result);
          }

          store.dispatch(openSuccess(BANNER_SUCCESS));

          if (caseOverrideUpdated) {
            store.dispatch(openSuccess(CASE_OVERRIDE));
            sendRefreshNotification(
              [REFRESH_CHANGE_TYPE.PATIENT],
              patientCase.patientId,
            );
          }
        } catch (e) {
          console.error(e);
          store.dispatch(openError(BANNER_ERROR));
        }
      },
      changeAuthorization: result =>
        this.model.context && this.model.context.onAuthorizationChange
          ? this.model.context.onAuthorizationChange(result)
          : null,
      updateGuarantors: async () => {
        await this.__guarantorService.update(this.model.context.patientId, {
          includeBalance: true,
        });
      },
      updateOrganizations: async () => {
        await this.__organizationsService.fetch();
      },
      searchOrganization: ({ value }) =>
        this.__organizationsService.search(value),
      reloadInsurances: async () => {
        await this.__loadInsurances();
      },
      reloadPackages: async () => {
        await this.__loadPackages();
      },
      dismiss: () => {
        this.dismiss(this.__newCase);
      },
    };
  }

  __getDefaultGuarantorId() {
    const defaultGuarantor = this.__guarantors.find(g => g.data.default);

    return defaultGuarantor ? defaultGuarantor.data.id : '';
  }

  __formatGuarantor(guarantor) {
    return guarantor.personId
      ? `${guarantor.person.lastName}, ${guarantor.person.firstName}`
      : `${guarantor.organization.name}`;
  }

  async __loadData() {
    const { patientId } = this.model.context;

    this.__guarantorService = new GuarantorService(guarantors => {
      this.__guarantors = [
        ITEM_SELF,
        ...guarantors
          .filter(g => g.relation !== 'Self')
          .map(g => ({ data: { ...g }, label: this.__formatGuarantor(g) }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ];
    });

    await this.__guarantorService.update(patientId);

    this.__organizationsService = new OrganizationService(organizations => {
      this.__organizations = organizations.map(organization => ({
        ...organization,
        label: organization.name,
      }));
    });

    this.__setPatientCase();
  }

  __setPatientCase() {
    const { patientId, patientName, isFirstCase } = this.model.context;

    this.__patientCase =
      this.model.item && this.model.item.id
        ? {
            ...this.model.item,
            onsetSymptomsDate: this.model.item.onsetSymptomsDate
              ? { date: this.model.item.onsetSymptomsDate, gradual: false }
              : { date: null, gradual: true },
            patientName: patientName || {},
            patientAuthorization: this.model.item.patientAuthorization || {},
          }
        : {
            ...this.__patientCase,
            isDefault: isFirstCase,
            patientId,
            guarantorId: this.__getDefaultGuarantorId(),
          };
  }

  async __loadPatientInfo() {
    const {
      patientId,
      isFromPatientCasePage,
      preferredProviderId: preferredProviderIdFromContext,
    } = this.model.context;

    const patient = isFromPatientCasePage ? {} : await fetchOne(patientId);

    this.__preferredProviderId =
      preferredProviderIdFromContext || patient.preferredProviderId;
  }

  async __loadPackages() {
    const rawPackages = await getPatientPackages(this.model.context.patientId, {
      includeShared: true,
    });

    this.__patientPackages = rawPackages.map(data => ({
      data,
      label: data.name,
    }));
  }

  async __loadInsurances() {
    const insurances = await getPatientInsurances(this.model.context.patientId);

    this.__insurances = mapServerModelsToInsurances(insurances);
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasAddOnCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
    this.__hasFFAuthTypeVisits = await hasFeatureOrBeta(
      FEATURE_FLAGS.AUTH_TYPE_VISITS,
    );

    await this.__loadInsurances();
    await this.__loadPackages();
    await this.__loadData();
    await this.__loadPatientInfo();
  }

  getTitle() {
    return this.__patientCase && this.__patientCase.id
      ? 'Update Case'
      : 'Add Case';
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-patient-case-form
        id="${ELEMENTS.form.id}"
        class="form"
        .layout="${this.layout}"
        .model="${this.__patientCase}"
        .guarantors="${this.__guarantors}"
        .insurances="${this.__insurances}"
        .hasAddOnCTVerify="${this.__hasAddOnCTVerify}"
        .organizations="${this.__organizations}"
        .patientPackages="${this.__patientPackages}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .onAddInsurance="${this.handlers.reloadInsurances}"
        .onAddPackage="${this.handlers.reloadPackages}"
        .onGuarantorChange="${this.handlers.updateGuarantors}"
        .onSearchOrganizations="${this.handlers.searchOrganization}"
        .onUpdateOrganization="${this.handlers.updateOrganizations}"
        .onAuthorizationChange="${this.handlers.changeAuthorization}"
        .preferredProviderId="${this.__preferredProviderId}"
      ></neb-patient-case-form>
    `;
  }
}

window.customElements.define('neb-overlay-case', NebOverlayCase);
