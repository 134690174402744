import { CLAIM_STATUS } from '../../../../../packages/neb-utils/claims';
import { REVERSAL_OF_PREVIOUS_PAYMENT } from '../../../../utils/era';
import { ERA_DETAILS_TYPES, ERA_STATUSES, getLineItemStatus } from '../utils';

const claimHasSpecificWarning = (claim, warning) => {
  const { statusMessage = [] } = claim;

  if (Array.isArray(statusMessage)) {
    return statusMessage.some(message => message.includes(warning));
  }

  return statusMessage.includes(warning);
};

export const checkMatchButtonEnabled = (
  { patientMedicalRecordNumber, id },
  nodeType = ERA_DETAILS_TYPES.LINE_ITEM_LEVEL,
) =>
  nodeType === ERA_DETAILS_TYPES.LINE_ITEM_LEVEL
    ? patientMedicalRecordNumber && !id
    : !patientMedicalRecordNumber;

export const checkFilterByMatchStatus = (patientMedicalRecordNumber, id) =>
  !checkMatchButtonEnabled({ patientMedicalRecordNumber, id });

export const checkManualPostButtonEnabled = (
  { statusMessage = [] },
  nodeType,
  hasRcmEraEobEnhancementsFF,
) => {
  if (
    hasRcmEraEobEnhancementsFF &&
    nodeType === ERA_DETAILS_TYPES.CLAIM_LEVEL
  ) {
    return claimHasSpecificWarning({ statusMessage }, 'fully written off');
  }

  return (
    statusMessage &&
    Array.isArray(statusMessage) &&
    statusMessage.some(message => message.includes('out of balance'))
  );
};

export const checkMarkAsDeniedButtonEnabled = (
  { statusMessage = [] },
  nodeType,
  claimStatus,
) => {
  if (nodeType !== ERA_DETAILS_TYPES.CLAIM_LEVEL) {
    return false;
  }

  return (
    statusMessage.includes('fully written off') &&
    claimStatus !== CLAIM_STATUS.DENIED &&
    claimStatus !== CLAIM_STATUS.ERA_EOB_RECEIVED_DENIED
  );
};

export const checkReversalButtonEnabled = (
  claim,
  nodeType,
  hasRcmEraReversalsFF,
) =>
  hasRcmEraReversalsFF &&
  nodeType === ERA_DETAILS_TYPES.CLAIM_LEVEL &&
  claimHasSpecificWarning(claim, 'Claim is a reversal') &&
  claim?.claimStatus === REVERSAL_OF_PREVIOUS_PAYMENT;

export const checkClaimFiltered = ({
  filteredStatuses,
  eraFailed,
  claim: {
    claimErrorMessage: claimFailed,
    patientMedicalRecordNumber,
    lineItems,
  },
}) => {
  if (!filteredStatuses.length) return false;

  if (filteredStatuses.includes(ERA_STATUSES.MATCH)) {
    return lineItems.every(({ id }) =>
      checkFilterByMatchStatus(patientMedicalRecordNumber, id),
    );
  }

  return lineItems.every(({ errorMessage: lineItemFailed, posted }) =>
    filteredStatuses.includes(
      getLineItemStatus({
        eraFailed,
        claimFailed,
        lineItemFailed,
        posted,
      }),
    ),
  );
};

export const ERA_REPORT_ACTIONS = {
  POST: 'post',
  MERGE: 'merge',
  MATCH: 'match',
  MANUAL_POST: 'manual-post',
  MARK_AS_DENIED: 'mark-as-denied',
  REVERSE: 'reverse',
};

export const getLevelActions = ({
  level,
  hasRcmEraEobEnhancementsFF,
  hasRcmEraReversalsFF,
}) => {
  const availableActions = {
    [ERA_DETAILS_TYPES.LINE_ITEM_LEVEL]: [
      ERA_REPORT_ACTIONS.POST,
      ERA_REPORT_ACTIONS.MERGE,
      ERA_REPORT_ACTIONS.MATCH,
      ERA_REPORT_ACTIONS.MANUAL_POST,
    ],
    [ERA_DETAILS_TYPES.CLAIM_LEVEL]: [
      ERA_REPORT_ACTIONS.POST,
      ERA_REPORT_ACTIONS.MATCH,
      ...(hasRcmEraEobEnhancementsFF
        ? [ERA_REPORT_ACTIONS.MANUAL_POST, ERA_REPORT_ACTIONS.MARK_AS_DENIED]
        : []),
      ...(hasRcmEraReversalsFF ? [ERA_REPORT_ACTIONS.REVERSE] : []),
    ],
    [ERA_DETAILS_TYPES.ERA_LEVEL]: [ERA_REPORT_ACTIONS.POST],
  };

  return availableActions[level] || [];
};

export const getNextMatchLineItemReportId = (
  reportLineItems,
  currentLineItemReportId,
) => {
  const reportLineItemKeys = Object.keys(reportLineItems);
  if (reportLineItemKeys.length === 1) return {};

  const currPosition = reportLineItemKeys.findIndex(
    key => key === currentLineItemReportId,
  );

  const nextPosition =
    currPosition < reportLineItemKeys.length - 1 ? currPosition + 1 : 0;

  return Object.values(reportLineItems)[nextPosition];
};
