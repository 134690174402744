import { APPOINTMENTS_API_URL } from '../../../../neb-utils/env';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../neb-utils/feature-util';

export const FETCH_SERVICES = 'FETCH_SERVICES';

export function requestServices() {
  return {
    type: FETCH_SERVICES,
  };
}

export function requestServicesSuccess(response) {
  return {
    type: FETCH_SERVICES,
    status: 'success',
    response,
  };
}

export function requestServicesFailure(error) {
  return {
    type: FETCH_SERVICES,
    status: 'failed',
    error,
  };
}

export function fetchServices() {
  return async (dispatch, getState) => {
    const state = getState();
    const apiPrefix = state.apiOverride.value;
    const { tenantShortName } = state.booking;
    const servicesStore = state.services;

    if (
      servicesStore &&
      (servicesStore.isFetching || servicesStore.fetchingError)
    ) {
      return;
    }

    dispatch(requestServices());

    let url;

    if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
      url = `${APPOINTMENTS_API_URL}/public/v1/${tenantShortName}/appointment-types`;
    } else {
      url = `${apiPrefix}/api/v1/public/${tenantShortName}/appointmentTypes`;
    }

    try {
      const res = await fetch(url, {
        method: 'GET',
      });
      const json = await res.json();

      if (!res.ok) {
        const err = new Error();
        err.res = json;
        throw err;
      }

      dispatch(requestServicesSuccess(json));
    } catch (err) {
      const errObj = err.res ? err.res : err;
      console.log(errObj);
      dispatch(requestServicesFailure(errObj));
    }
  };
}
