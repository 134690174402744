import { FEATURE_FLAGS, hasFeatureOrBeta } from '../../neb-utils/feature-util';
import { mapToPatientModel } from '../../neb-utils/patient';

import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const BASE_URL = 'patients';
export const apiClient = new ApiClient({ microservice: 'api' });
export const coreApiClient = new ApiClientV2({ microservice: 'core' });
export const billingApiClient = new ApiClientV2({ microservice: 'billing' });
export const emailApiClient = new ApiClientV2({ microservice: 'email' });
const uniq = arr => [...new Set(arr)];

// neb-api api/v1/tenants/:tenantId/patients/:id PUT
export const updatePatient = async (
  patient,
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      path: '/api/v1/tenants/:tenantId/patients/:id',
      replacements: { id: patient.id },
      queryParams,
      method: Method.PUT,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patient),
      cacheKey: patient.id,
      optOutLoadingIndicator,
      updateNotificationDetails: { patient },
    });
  }

  return apiClient.makeRequest({
    path: `${BASE_URL}/${patient.id}`,
    queryParams,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patient),
    cacheKey: patient.id,
    optOutLoadingIndicator,
    updateNotificationDetails: { patient },
  });
};

export const matchBookingAccountToPatient = (patient, cognitoId) =>
  updatePatient({ ...patient, bookingAccountId: cognitoId }, { force: true });

// neb-api api/v1/tenants/:tenantId/patients POST
export const createPatient = async (
  patient,
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      path: '/api/v1/tenants/:tenantId/patients',
      queryParams,
      method: Method.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patient),
      cacheKey: 'all',
      optOutLoadingIndicator,
      updateNotificationDetails: { patient },
    });
  }

  return apiClient.makeRequest({
    path: BASE_URL,
    queryParams,
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patient),
    cacheKey: 'all',
    optOutLoadingIndicator,
    updateNotificationDetails: { patient },
  });
};

// neb-ms-core api/v1/tenants/:tenantId/patients/nextMRN GET
export const getNextMRN = async () => {
  const nextMRN = await coreApiClient.makeRequest({
    optOutLoadingIndicator: true,
    path: '/api/v1/tenants/:tenantId/patients/nextMRN',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return nextMRN;
};

// neb-ms-core api/v1/tenants/:tenantId/patients/match GET
export const getPotentialMatches = async onlineAccount => {
  const potentialMatches = await coreApiClient.makeRequest({
    optOutLoadingIndicator: true,
    path: '/api/v1/tenants/:tenantId/patients/match',
    queryParams: onlineAccount,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return potentialMatches;
};

// neb-ms-billing api/v5/tenants/:tenantId/patients GET
export const fetchMany = async (queryParams = {}) => {
  const res = await billingApiClient.makeRequest({
    cacheKey: JSON.stringify(queryParams),
    path: '/api/v5/tenants/:tenantId/patients',
    method: Method.GET,
    queryParams,
  });

  return {
    count: res.count,
    isRecentPatientList: res.isRecentPatientList,
    data: res.data.map(item => mapToPatientModel(item)),
  };
};

// neb-api api/v1/tenants/:tenantId/patients/patient-lists POST
export const fetchSome = async (
  patientIds,
  queryParams = {},
  noMapping = false,
  optOutLoadingIndicator = false,
  parseUniquePatientIds = false,
) => {
  if (patientIds.length === 0) return [];

  if (parseUniquePatientIds) {
    patientIds = uniq(patientIds);
  }

  const path = `${BASE_URL}/patient-lists`;

  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const body = JSON.stringify({ patientIds, ...queryParams });

    const res = await coreApiClient.makeRequest({
      method: 'POST',
      path: '/api/v1/tenants/:tenantId/patients/patient-lists',
      headers: {
        'Content-Type': 'application/json',
      },
      cacheKey: `${path}-${JSON.stringify(queryParams)}-${body}`,
      forceCache: true,
      body,
      optOutLoadingIndicator,
    });

    return noMapping ? res.data : res.data.map(item => mapToPatientModel(item));
  }

  const body = JSON.stringify(patientIds);

  const res = await apiClient.makeRequest({
    method: 'POST',
    path,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${path}-${JSON.stringify(queryParams)}-${body}`,
    forceCache: true,
    body,
    queryParams,
    optOutLoadingIndicator,
  });

  return noMapping ? res.data : res.data.map(item => mapToPatientModel(item));
};

// neb-api api/v1/tenants/:tenantId/patients/:id GET
export const fetchOne = async (
  patientId,
  optOutLoadingIndicator = false,
  noMapping = false,
) => {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const res = await coreApiClient.makeRequest({
      path: '/api/v1/tenants/:tenantId/patients/:id',
      replacements: { id: patientId },
      cacheKey: patientId,
      optOutLoadingIndicator,
    });

    if (noMapping) return res.data[0];

    return mapToPatientModel(res.data[0]);
  }

  const res = await apiClient.makeRequest({
    path: `${BASE_URL}/${patientId}`,
    cacheKey: patientId,
    optOutLoadingIndicator,
  });

  if (noMapping) return res.data[0];

  return mapToPatientModel(res.data[0]);
};

export const inviteBookingPatient = (
  body,
  queryParams = {},
  optOutLoadingIndicator = false,
  version = 1,
) =>
  emailApiClient.makeRequest({
    method: Method.POST,
    path:
      version === 1
        ? '/v1/tenants/:tenantId/booking-invitation'
        : '/v2/tenants/:tenantId/booking-invitation',
    replacements: {},
    queryParams,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
    version,
  });

// neb-ms-billing api/v1/tenants/:tenantId/patients/:id GET
export const getOne = async (
  id,
  isBookingAccount = false,
  optOutLoadingIndicator = false,
) => {
  const res = await billingApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/patients/:id',
    replacements: { id },
    queryParams: { isBookingAccount },
    optOutLoadingIndicator,
  });

  return res.data[0];
};

// neb-ms-billing api/v1/tenants/:tenantId/patients/:id/summary GET
export const getPatientSummary = ({ id, optOutLoadingIndicator = false }) =>
  billingApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/patients/:id/summary',
    replacements: { id },
    headers: { 'Content-Type': 'application/json' },
    optOutLoadingIndicator,
  });

export const excludeFromBatchStatement = ({ patientId, exclude }) =>
  billingApiClient.makeRequest({
    method: Method.PUT,
    path:
      '/api/tenants/:tenantId/patients/:patientId/statements/exclude-from-batch',
    replacements: { patientId },
    headers: { 'Content-Type': 'application/json' },
    responseType: RESPONSE_TYPE.RAW,
    body: JSON.stringify({ excludeFromBatchStatement: exclude }),
  });
