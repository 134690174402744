import { css, html } from 'lit';

import '../../../neb-header';
import '../../../tables/neb-table-encounter-charges';
import '../../../controls/neb-button-action';
import '../../../encounter/neb-encounter-card';
import '../../../inputs/neb-select-search';
import '../../../neb-popup-header';
import '../../../../../../neb-www-practice-charting/src/components/diagnosis/neb-encounter-diagnosis-table';

import { addNDCFields } from '../../../../../../../src/api-clients/charges';
import {
  NO_CHARGES_SELECT_ADD_CHARGES,
  NO_OPEN_ENCOUNTERS,
  NO_RESULTS,
  NO_SELECTED_ENCOUNTER_CHARGES,
} from '../../../../../../../src/utils/user-message';
import {
  getUpdatedBilledAmount,
  buildModel,
  getSettingCharges,
} from '../../../../../../neb-api-client/src/services/encounter-charge';
import addCharges from '../../../../../../neb-api-client/src/services/encounter-charge/add-charges';
import {
  PRACTICE_SETTINGS,
  getPracticeSettings,
} from '../../../../../../neb-api-client/src/services/practice-settings';
import { openDirtyPopup } from '../../../../../../neb-popup';
import { map } from '../../../../../../neb-utils/utils';
import { range, required } from '../../../../../../neb-utils/validators';
import { openOverlay, OVERLAY_KEYS } from '../../../../utils/overlay-constants';
import { NebModifiers } from '../../../field-groups/neb-modifiers';
import nebTableEncounterChargesService from '../../../tables/services/neb-table-encounter-charges-service';
import NebForm, { ELEMENTS as ELEMENTS_BASE } from '../../neb-form';
import { EMPTY_SELECT_VALUE } from '../../utils';

import {
  filterEncounters,
  CHARGES_SELECTOR,
} from './neb-form-manage-encounter-util';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  header: { id: 'header' },
  chargeTable: { id: 'charge-table' },
  diagnosesTable: { id: 'diagnoses-table' },
  description: { id: 'description' },
  encounterDropdown: { id: 'encounter-dropdown' },
  addButton: { id: 'add-button' },
  addDiagnosisButton: {
    id: 'add-diagnosis',
  },
  autoPointDiagnosesButton: {
    id: 'auto-point-diagnoses-button',
  },
};

const CARD_HEIGHT_LOCATION = 130;
const CARD_HEIGHT_MOBILE = 133;

class NebFormManageEncounter extends NebForm {
  static get properties() {
    return {
      encounters: Array,
      encounterCharges: Array,
      selectedEncounter: Object,
      hideEncounterTableDetails: Boolean,

      __encounters: Array,
      __filteredEncounters: Array,
      __settingCharges: Array,
      __itemHeight: Number,
      __loadingLabel: String,
      __authorizedProcedures: Array,
      __autoPostCharges: Boolean,
    };
  }

  initState() {
    super.initState();

    this.encounters = [];
    this.encounterCharges = [];
    this.selectedEncounter = EMPTY_SELECT_VALUE;
    this.hideEncounterTableDetails = false;

    this.__encounters = [];
    this.__filteredEncounters = [];
    this.__settingCharges = [];
    this.__itemHeight = CARD_HEIGHT_LOCATION;
    this.__loadingLabel = '';
    this.__authorizedProcedures = null;
    this.__autoDiagnosisPointing = false;
    this.__autoPostCharges = false;

    this.encounterSelected = () => {};

    this.onBack = () => {};

    this.onCheckingUnallocationAuth = () => {};
  }

  __get4DiagnosisPointers() {
    return this.state.diagnoses.slice(
      0,
      Math.min(this.state.diagnoses.length, 4),
    );
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      selectEncounter: async ({ value }) => {
        const discardChanges = await (this.__dirty ? openDirtyPopup() : true);

        if (!discardChanges) {
          return null;
        }

        return this.encounterSelected(value || EMPTY_SELECT_VALUE);
      },
      renderEncounterItem: model => html`
        <neb-encounter-card
          .layout="${this.layout}"
          .model="${model}"
        ></neb-encounter-card>
      `,
      filterEncounter: terms => {
        this.__filteredEncounters = filterEncounters(this.encounters, terms);
      },
      addCharges: async () => {
        await this.__getSettingCharges();

        const formServiceMappedCharges = await addCharges({
          stateCharges: this.__stateCharges,
          settingCharges: this.__settingCharges,
          isDirty: this.__dirty,
          filterNdc: true,
        });
        formServiceMappedCharges.forEach(mappedCharge => {
          this.formService.addItem('charges');
          Object.entries(mappedCharge).forEach(([key, value]) => {
            if (
              key.endsWith('diagnosisPointers') &&
              this.__autoDiagnosisPointing
            ) {
              return this.formService.apply(
                key,
                this.__get4DiagnosisPointers(),
              );
            }
            return this.formService.apply(key, value);
          });
        });
      },
      autoPointDiagnoses: () => {
        if (!this.state.diagnoses.length) {
          return;
        }
        const diags4 = this.__get4DiagnosisPointers();

        this.state.charges.forEach((item, index) => {
          if (!item.diagnosisPointers.length) {
            this.formService.apply(`charges.${index}.diagnosisPointers`, [
              ...diags4,
            ]);
          }
        });
      },
      removeCharge: (name, index) => {
        this.formService.removeItem(name, index);
      },
      postAllToLedger: async () => {
        const postAll = this.__stateCharges.some(({ posted }) => !posted);
        this.__stateCharges.forEach((_, idx) =>
          this.formService.apply(`charges.${idx}.posted`, postAll),
        );

        await this.handlers.save();
      },
      addDiagnosis: async () => {
        const previousDiagnoses = this.state.diagnoses.map(
          ({ item: { code } }) => code,
        );

        const selectedDiagnoses = (await openOverlay(
          OVERLAY_KEYS.ADD_DIAGNOSIS,
          {
            showICD10Dropdown: true,
            selectedDiagnosis: this.__formatDiagnoses(),
          },
        )).filter(dx => !previousDiagnoses.includes(dx.diagnosisCode));

        this.formService.apply('diagnoses', [
          ...this.state.diagnoses,
          ...this.__unformatDiagnoses(selectedDiagnoses),
        ]);

        return selectedDiagnoses;
      },
      reorderDiagnoses: diagnoses => {
        this.formService.apply(
          'diagnoses',
          diagnoses.map(dx => ({
            item: dx,
            label: `${dx.code} - ${dx.shortDescription}`,
          })),
        );
      },
      removeDiagnosis: diagnosis => {
        const diagnosisIndex = this.state.diagnoses
          .map(({ item: { code } }) => code)
          .indexOf(diagnosis.code);

        this.formService.removeItem('diagnoses', diagnosisIndex);

        this.removeDiagnosisFromCharges(diagnosis);
      },

      removeAllDiagnoses: () => {
        this.state.charges.forEach((charge, index) =>
          this.formService.apply(`charges.${index}.diagnosisPointers`, []),
        );

        this.formService.apply('diagnoses', []);
      },
      change: ({ name, value }) => {
        if (name === 'charges') {
          const charges = value;
          this.__setChargesState(charges);
        } else this.formService.apply(name, value);

        const { billedAmountKey, billedAmountValue } = getUpdatedBilledAmount({
          name,
          value,
          charges: this.__stateCharges,
        });
        if (!billedAmountKey) return;

        this.formService.apply(billedAmountKey, billedAmountValue);
      },
      save: async () => {
        if (this.formService.validate()) {
          const rawModel = this.formService.build();
          const model = map(rawModel, (_, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          const authUnallocation = await this.onCheckingUnallocationAuth(
            this.__stateCharges,
          );
          if (!authUnallocation) return;

          this.__saving = true;
          this.onSave(await this.__mapNDCFieldsToModel(model));
        } else this.onError();
      },
    };
  }

  get __stateCharges() {
    const { charges } = this.formService.build();
    return charges;
  }

  static createModel() {
    return buildModel();
  }

  createSelectors() {
    return {
      children: {
        charges: {
          ...CHARGES_SELECTOR,
          children: {
            $: {
              children: {
                diagnosisPointers: {
                  unsafe: true,
                  clipPristine: true,
                  format: v =>
                    v.map(dx =>
                      this.model.diagnoses.find(
                        d => d.item.code === dx.diagnosisCode,
                      ),
                    ),
                  unformat: v =>
                    v.map(dx => ({
                      diagnosisCode: dx.item.code,
                    })),
                  validators: [],
                },
                modifiers: NebModifiers.createSelectors({
                  format: v => v || '',
                  unformat: v => v || null,
                }),
                units: {
                  format: v => v.toString(),
                  unformat: v => parseInt(v, 10),
                  validators: [required('1 - 999'), range(1, 999)],
                },
              },
            },
          },
        },
        diagnoses: {
          unsafe: true,
          clipPristine: true,
        },
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    const practiceSettings = await getPracticeSettings();
    this.__autoDiagnosisPointing =
      practiceSettings[PRACTICE_SETTINGS.AUTO_DIAGNOSIS_POINTING];

    this.__autoPostCharges =
      practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES];
  }

  async update(changedProps) {
    if (changedProps.has('encounters')) {
      this.__filteredEncounters = this.encounters;
    }

    if (changedProps.has('selectedEncounter')) {
      const { appointmentId } = this.selectedEncounter;

      if (appointmentId) {
        const { authorizedProcedures } = await nebTableEncounterChargesService({
          appointmentId,
        });
        this.__authorizedProcedures = authorizedProcedures;
      }
    }

    super.update(changedProps);
  }

  updated() {
    this.__itemHeight =
      this.layout === 'small' ? CARD_HEIGHT_MOBILE : CARD_HEIGHT_LOCATION;
  }

  static get styles() {
    return [
      super.styles,
      css`
        .encounter-dropdown {
          z-index: 2;
        }

        .charges-header {
          margin-top: 20px;
        }

        .content {
          position: relative;
          width: 100%;
          height: 100%;
        }

        .layout {
          overflow-y: auto;
        }
        #action-bar {
          position: relative;
          width: 100%;
          z-index: 3;
        }
      `,
    ];
  }

  __formatDiagnoses() {
    return this.state.diagnoses.map(({ item: { code, shortDescription } }) => ({
      diagnosisCode: code,
      shortDescription,
    }));
  }

  __unformatDiagnoses(selectedDiagnoses) {
    const result = selectedDiagnoses.map(
      ({ diagnosisCode, shortDescription }) => ({
        item: { code: diagnosisCode, shortDescription },
        label: `${diagnosisCode} - ${shortDescription}`,
      }),
    );

    return result;
  }

  async __getSettingCharges() {
    this.__settingCharges = await (this.__settingCharges.length
      ? this.__settingCharges
      : getSettingCharges(
          this.selectedEncounter.patientId,
          this.selectedEncounter.appointmentId,
        ));
  }

  async __mapNDCFieldsToModel(model) {
    let mappedEncounterCharge;

    await this.__getSettingCharges();

    if (model.charges && model.charges.length) {
      mappedEncounterCharge = model.charges.map(encounterCharge => {
        const foundCharge = this.__settingCharges.find(
          c => c.id === encounterCharge.chargeId,
        );

        return foundCharge
          ? { ...encounterCharge, ...addNDCFields(foundCharge) }
          : encounterCharge;
      });
    }

    const mappedModel = {
      charges: mappedEncounterCharge || model.charges,
      diagnoses: model.diagnoses,
    };

    return mappedModel;
  }

  removeDiagnosisFromCharges(diagnosis) {
    this.state.charges.forEach((charge, chargeIndex) => {
      if (charge.diagnosisPointers.length) {
        const dxPointerIndex = charge.diagnosisPointers.findIndex(
          ({ item: { id } }) => id === diagnosis.id,
        );

        if (dxPointerIndex !== -1) {
          this.formService.removeItem(
            `charges.${chargeIndex}.diagnosisPointers`,
            dxPointerIndex,
          );
        }
      }
    });
  }

  renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Manage Encounter Charges and Diagnoses"
        .showBackButton="${!this.hideEncounterTableDetails}"
        .showCancelButton="${!this.hideEncounterTableDetails}"
        .onCancel="${this.onCancel}"
        .onBack="${this.onBack}"
      ></neb-popup-header>
    `;
  }

  __renderDescription() {
    return !this.hideEncounterTableDetails
      ? html`
          <div id="${ELEMENTS.description.id}" class="pad">
            Add or modify the charges and diagnoses associated with the
            patient's encounter.
          </div>
        `
      : '';
  }

  __setChargesState(charges) {
    charges.forEach((charge, chargeIndex) =>
      Object.entries(charge).forEach(([k, v]) => {
        if (k === 'modifiers') {
          const modifiers = v;
          modifiers.forEach((modifier, modifierIdx) => {
            this.formService.apply(
              `charges.${chargeIndex}.modifiers.${modifierIdx}`,
              modifier,
            );
          });
        } else if (k === 'order') {
          this.formService.apply(`charges.${chargeIndex}.order`, chargeIndex);
        } else this.formService.apply(`charges.${chargeIndex}.${k}`, v);
      }),
    );
  }

  __renderEncountersDropdown() {
    return html`
      <neb-select-search
        id="${ELEMENTS.encounterDropdown.id}"
        class="pad"
        helper="Required"
        label="Select Encounter"
        .itemHeight="${this.__itemHeight}"
        .items="${this.__filteredEncounters}"
        .maxVisibleItems="${this.layout === 'small' ? 3 : 4}"
        .value="${this.selectedEncounter}"
        .onChange="${this.handlers.selectEncounter}"
        .onRenderItem="${this.handlers.renderEncounterItem}"
        .onSearch="${this.handlers.filterEncounter}"
        ?disabled="${this.__encounters.length <= 1}"
        .emptyMessage="${
          this.__encounters.length ? NO_RESULTS : NO_OPEN_ENCOUNTERS
        }"
        showSearch
        forceDown
      >
      </neb-select-search>
    `;
  }

  __renderAddChargesButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.addButton.id}"
        label="Add Charge"
        .onClick="${this.handlers.addCharges}"
        .disabled="${!this.selectedEncounter.label}"
      ></neb-button-action>
    `;
  }

  __renderAutoPointDiagnoses() {
    return html`
      <neb-button-action
        id="${ELEMENTS.autoPointDiagnosesButton.id}"
        class="header-buttons"
        leadingIcon="autoPoint"
        label="Auto Point Diagnoses"
        .disabled="${!this.selectedEncounter}"
        .onClick="${this.handlers.autoPointDiagnoses}"
      ></neb-button-action>
    `;
  }

  __renderAddDiagnosisButton() {
    return html`
      <div class="pad">
        <neb-button-action
          id="${ELEMENTS.addDiagnosisButton.id}"
          label="Add Diagnosis"
          .onClick="${this.handlers.addDiagnosis}"
          .disabled="${!this.selectedEncounter.label}"
        ></neb-button-action>
      </div>
    `;
  }

  __renderChargesTable() {
    return html`
      <neb-table-encounter-charges
        id="${ELEMENTS.chargeTable.id}"
        class="pad"
        name="charges"
        .layout="${this.layout}"
        .dirty="${this.__dirty}"
        .errors="${this.__errors.charges}"
        .model="${this.state.charges}"
        .diagnoses="${this.state.diagnoses}"
        .hasAutoPostCharges="${this.__autoPostCharges}"
        .onChange="${this.handlers.change}"
        .onAddAll="${this.handlers.postAllToLedger}"
        .onRemove="${this.handlers.removeCharge}"
        .reorder="${this.layout !== 'small'}"
        .authorizedProcedures="${this.__authorizedProcedures}"
        .hideEncounterTableDetails="${this.hideEncounterTableDetails}"
        >${
          this.selectedEncounter.label
            ? NO_CHARGES_SELECT_ADD_CHARGES
            : NO_SELECTED_ENCOUNTER_CHARGES
        }</neb-table-encounter-charges
      >
    `;
  }

  __renderDiagnosesSection() {
    return html`
      <neb-header label="Diagnoses"></neb-header>${
        this.__renderAddDiagnosisButton()
      }

      <neb-encounter-diagnosis-table
        id="${ELEMENTS.diagnosesTable.id}"
        class="pad"
        .savedCodes="${this.state.diagnoses.map(({ item }) => item)}"
        .onRemoveDiagnosis="${this.handlers.removeDiagnosis}"
        .onRemoveAllDiagnoses="${this.handlers.removeAllDiagnoses}"
        .onReorderDiagnoses="${this.handlers.reorderDiagnoses}"
      ></neb-encounter-diagnosis-table>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderDescription()} ${this.__renderEncountersDropdown()}
      ${this.__renderDiagnosesSection()}
      <neb-header label="Charges" class="charges-header"></neb-header>
      <div class="pad">
        ${this.__renderAddChargesButton()} ${this.__renderAutoPointDiagnoses()}
      </div>
      ${this.__renderChargesTable()}
    `;
  }
}

customElements.define('neb-form-manage-encounter', NebFormManageEncounter);
