import '../neb-provider-signature';

import { html, LitElement, css } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { NO_PRIOR_ENCOUNTERS } from '../../../../../src/utils/user-message';
import { getAppointmentById } from '../../../../neb-api-client/src/appointment-api-client';
import { mapToPatientCaseName } from '../../../../neb-api-client/src/mappers/patient-case-mapper';
import { trimBlankLines } from '../../../../neb-api-client/src/notes';
import { fetchOne } from '../../../../neb-api-client/src/patient-cases';
import {
  toPhoneDisplayValue,
  toDisplayAge,
  toFormatDate,
} from '../../../../neb-input/nebFormatUtils';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { ckeditorStyles } from '../../../../neb-styles/neb-ckeditor-styles';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_FAMILY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_BORDER_GREY_2,
  CSS_COLOR_GREY_1,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { HIDE_PREFERRED_OPTS } from '../../../../neb-utils/formatters';
import {
  getPatientDisplayName,
  SERVICE_DATE_LONG_FORMAT,
} from '../../../../neb-utils/neb-charting-util';
import { formatAuthorizationLabel } from '../../../../neb-utils/patientAuthorization';
import { hideIfEncounterOnly } from '../../utils/encounter-only-util';

const NUMBER_OF_MODEL_KEYS = 4;

export const ELEMENTS = {
  practiceInfo: {
    id: 'practice-info',
  },
  patientInfo: {
    id: 'patient-info',
  },
  assessmentInfo: {
    id: 'assessment-info',
  },
  planInfo: {
    id: 'plan-info',
  },
  encounterInfo: {
    id: 'encounter-info',
  },
  practiceName: {
    id: 'practice-name',
  },
  businessName: {
    id: 'business-name',
  },
  patientName: {
    id: 'patient-name',
  },
  patientAddress1: {
    id: 'patient-address1',
  },
  patientAddress2: {
    id: 'patient-address2',
  },
  patientCityStateZip: {
    id: 'patient-city-state-zipcode',
  },
  patientPhone: {
    id: 'patient-phone-number',
  },
  patientEmail: {
    id: 'patient-email',
  },
  patientDOB: {
    id: 'patient-dob',
  },
  patientMRN: {
    id: 'patient-mrn',
  },
  encounterServiceDate: {
    id: 'encounter-service-date',
  },
  encounterApptType: {
    id: 'encounter-appt-type',
  },
  encounterProvider: {
    id: 'encounter-provider',
  },
  subjectiveDetail: {
    id: 'subjective-detail',
  },
  objectiveDetail: {
    id: 'objective-detail',
  },
  assessmentDetail: {
    id: 'assessment-detail',
  },
  planDetail: {
    id: 'plan-detail',
  },
  signature: {
    id: 'signature',
  },
  encounterCase: {
    id: 'encounter-case',
  },
  encounterAuthorization: {
    id: 'encounter-authorization',
  },
  encounterAddress1: {
    id: 'encounter-address1',
  },
  encounterAddress2: {
    id: 'encounter-address2',
  },
  encounterCityStateZip: {
    id: 'encounter-city-state-zipcode',
  },
  encounterPhone: {
    id: 'encounter-phone-number',
  },
  noPriorEncounterMessage: {
    id: 'no-prior-encounter-message',
  },
};

class NebEncounterSummary extends LitElement {
  static get properties() {
    return {
      __caseName: {
        type: String,
      },
      __authorizationLabel: {
        type: String,
      },
      __locations: {
        type: Array,
      },
      __appointment: {
        type: Object,
      },
      layout: {
        type: String,
        reflect: true,
      },
      model: {
        type: Object,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initServices();
  }

  __initState() {
    this.__caseName = '';
    this.__authorizationLabel = '';
    this.__locations = [];
    this.__appointment = null;

    this.layout = '';

    this.model = {
      practiceInfo: {},
      patient: {},
      encounter: {},
      chartNotes: {},
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__locationsService.disconnect();
  }

  scrollToSection(section) {
    this.shadowRoot.getElementById(section).scrollIntoView();
  }

  __checkValidModel() {
    return Object.keys(this.model).length === NUMBER_OF_MODEL_KEYS;
  }

  async __loadCaseAndAuth({
    appointment = {},
    appointmentId,
    case: patientCase = {},
  }) {
    if (appointment.caseId || patientCase?.id) {
      return patientCase;
    }

    if (appointment.id) return null;

    if (appointmentId) {
      const fetchedAppointment = await this.__getAppointment(
        {
          appointmentId,
          appointment,
        },
        true,
      );

      if (fetchedAppointment && fetchedAppointment.caseId) {
        return fetchOne(
          fetchedAppointment.patientId,
          fetchedAppointment.caseId,
        );
      }
    }

    return null;
  }

  async __getAppointment({ appointmentId, appointment = {} }, forceUpdate) {
    if (!forceUpdate) {
      if (this.__appointment && this.__appointment.id === appointmentId) {
        return this.__appointment;
      }

      if (appointment.id) {
        this.__appointment = appointment;

        return this.__appointment;
      }
    }

    const { appointment: fetchedAppointment } = await getAppointmentById(
      appointmentId,
      {
        includeEncounterOnly: true,
      },
    );

    this.__appointment = fetchedAppointment;

    return this.__appointment;
  }

  async updated(changedProps) {
    if (changedProps.has('model') && this.model) {
      const { encounter } = this.model;

      if (!encounter) {
        return;
      }

      const patientCase = await this.__loadCaseAndAuth(encounter);

      if (patientCase) {
        this.__caseName = mapToPatientCaseName(
          patientCase.name,
          patientCase.onsetSymptomsDate,
        );

        const appointment = await this.__getAppointment(encounter);
        const patientAuthorization = patientCase.patientAuthorizations.find(
          ({ id }) => id === appointment.patientAuthorizationId,
        );

        this.__authorizationLabel = formatAuthorizationLabel(
          patientAuthorization,
        );
      } else {
        this.__caseName = '';
        this.__authorizationLabel = '';
      }
    }

    super.updated(changedProps);
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
      }

      .encounter-summary-content {
        font-size: ${CSS_FONT_SIZE_BODY};
        font-family: ${CSS_FONT_FAMILY};
        width: inherit;
      }

      .section-container {
        display: flex;
        flex-direction: column;
        padding-bottom: ${CSS_SPACING};
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-word;
      }

      .section-title {
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }

      .title-underline {
        padding-bottom: 10px;
        border-bottom: ${CSS_BORDER_GREY_2};
      }

      :host([layout='small']) .encounter-summary-content {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
      }

      .text-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      :host([layout='small']) {
        flex: 0;
      }

      .no-prior-encounters {
        font-size: ${CSS_FONT_SIZE_BODY};
        font-style: italic;
        color: ${CSS_COLOR_GREY_1};
      }

      .chart-note-details {
        font-family: 'Helvetica';
        font-size: 16px;
      }
    `;
  }

  __encounterAddress(location) {
    return html`
      <span id="${ELEMENTS.encounterAddress1.id}">${location.address1}</span>

      <span id="${ELEMENTS.encounterAddress2.id}">${location.address2}</span>

      <span id="${ELEMENTS.encounterCityStateZip.id}">
        ${location.city}, ${location.state} ${location.zipCode}
      </span>

      <span id="${ELEMENTS.encounterPhone.id}">
        ${toPhoneDisplayValue(location.phoneNumber)}
      </span>
    `;
  }

  __getLocationName(location) {
    const { practiceInfo } = this.model;

    return location && location.businessName
      ? html`
          <span id="${ELEMENTS.businessName.id}" class="section-title">
            ${location.businessName}
          </span>
        `
      : html`
          <span id="${ELEMENTS.practiceName.id}" class="section-title">
            ${practiceInfo.name}
          </span>
        `;
  }

  __renderEncounterAddress() {
    const { practiceInfo, encounter } = this.model;

    const encounterLocation = this.__locations.find(
      location => location.id === encounter.locationId,
    );

    if (encounter.locationId && encounterLocation) {
      return this.__encounterAddress(encounterLocation);
    }

    if (practiceInfo.locations[0]) {
      return this.__encounterAddress(practiceInfo.locations[0]);
    }
    return '';
  }

  __renderLocationName() {
    const { encounter } = this.model;

    const encounterLocation = this.__locations.find(
      location => location.id === encounter.locationId,
    );

    return this.__getLocationName(encounterLocation);
  }

  __renderPracticeInfo() {
    const { practiceInfo, location } = this.model;

    return (practiceInfo &&
      practiceInfo.name &&
      practiceInfo.name.length > 0) ||
      (location && location.businessName && location.businessName.length > 0)
      ? html`
          <div id="${ELEMENTS.practiceInfo.id}" class="section-container">
            ${this.__renderLocationName()} ${this.__renderEncounterAddress()}
          </div>
        `
      : '';
  }

  __renderPatientInfo() {
    return this.model.patient
      ? html`
          <div id="${ELEMENTS.patientInfo.id}" class="section-container">
            <span id="patient-title" class="section-title">Patient</span>

            <span id="${ELEMENTS.patientName.id}">
              ${getPatientDisplayName(this.model.patient, HIDE_PREFERRED_OPTS)}
            </span>

            ${this.__renderPatientAgeAndDOB()} ${this.__renderMRN()}
            ${this.__renderPatientAddress()} ${this.__renderPatientPhone()}
            ${this.__renderPatientEmail()}
          </div>
        `
      : '';
  }

  __renderPatientAddress() {
    const { addresses } = this.model.patient;

    return html`
      ${
        addresses && addresses.length > 0
          ? html`
              <span id="${ELEMENTS.patientAddress1.id}">
                ${addresses[0].address1}
              </span>

              <span id="${ELEMENTS.patientAddress2.id}">
                ${addresses[0].address2}
              </span>

              <span id="${ELEMENTS.patientCityStateZip.id}">
                ${addresses[0].city ? `${addresses[0].city},` : ''}
                ${addresses[0].state} ${addresses[0].zipcode}
              </span>
            `
          : ''
      }
    `;
  }

  __renderPatientPhone() {
    const { phoneNumbers } = this.model.patient;

    return html`
      ${
        phoneNumbers && phoneNumbers.length > 0
          ? html`
              <span id="${ELEMENTS.patientPhone.id}">
                ${toPhoneDisplayValue(phoneNumbers[0].number)}
                (${phoneNumbers[0].type})
              </span>
            `
          : ''
      }
    `;
  }

  __renderPatientEmail() {
    const { emailAddresses } = this.model.patient;

    return html`
      <span id="${ELEMENTS.patientEmail.id}">
        ${emailAddresses && emailAddresses.length > 0 ? emailAddresses[0] : ''}
      </span>
    `;
  }

  __renderPatientAgeAndDOB() {
    const { dateOfBirth } = this.model.patient;

    return html`
      <span id="${ELEMENTS.patientDOB.id}">
        ${toFormatDate(dateOfBirth, 'MM/DD/YYYY')}
        (${toDisplayAge(dateOfBirth)})
      </span>
    `;
  }

  __renderMRN() {
    const { medicalRecordNumber } = this.model.patient;

    return html`
      <span id="${ELEMENTS.patientMRN.id}" class="text-overflow">
        MRN: ${medicalRecordNumber}
      </span>
    `;
  }

  __renderEncounterAppointmentType() {
    if (hideIfEncounterOnly(this.model.encounter)) {
      return html``;
    }

    const { appointmentType } = this.model.encounter;
    return html`
      <span id="${ELEMENTS.encounterApptType.id}">
        Appointment Type: ${appointmentType}
      </span>
    `;
  }

  __renderEncounterInfo() {
    const { serviceDate, provider } = this.model.encounter;

    return html`
      <div id="${ELEMENTS.encounterInfo.id}" class="section-container">
        <span id="encounter-title" class="section-title">
          Encounter Information
        </span>

        <span id="${ELEMENTS.encounterServiceDate.id}">
          Service Date:
          ${parseDate(serviceDate).format(SERVICE_DATE_LONG_FORMAT)}
        </span>

        ${this.__renderEncounterAppointmentType()}

        <span id="${ELEMENTS.encounterProvider.id}">
          Rendering Provider: ${provider}
        </span>

        <span id="${ELEMENTS.encounterCase.id}">
          Case: ${this.__caseName}
        </span>

        ${this.__renderAuthorization()}
      </div>
    `;
  }

  __renderAuthorization() {
    return this.__authorizationLabel
      ? html`
          <span id="${ELEMENTS.encounterAuthorization.id}">
            Authorization: ${this.__authorizationLabel}
          </span>
        `
      : '';
  }

  __formatNoteContent(noteContent) {
    noteContent = trimBlankLines(noteContent);
    noteContent = unsafeHTML(noteContent);
    return noteContent;
  }

  __renderSubjective() {
    return html`
      <div id="subjective-info" class="section-container">
        <span id="subjective-title" class="section-title title-underline">
          Subjective
        </span>

        <span id="${ELEMENTS.subjectiveDetail.id}" class="chart-note-details">
          ${this.__formatNoteContent(this.model.chartNotes.subjective)}
        </span>
      </div>
    `;
  }

  __renderObjective() {
    return html`
      <div id="objective-info" class="section-container">
        <span id="objective-title" class="section-title title-underline">
          Objective
        </span>

        <span id="${ELEMENTS.objectiveDetail.id}" class="chart-note-details">
          ${this.__formatNoteContent(this.model.chartNotes.objective)}
        </span>
      </div>
    `;
  }

  __renderAssessment() {
    return html`
      <div id="${ELEMENTS.assessmentInfo.id}" class="section-container">
        <span id="assessment-title" class="section-title title-underline">
          Assessment
        </span>

        <span id="${ELEMENTS.assessmentDetail.id}" class="chart-note-details">
          ${this.__formatNoteContent(this.model.chartNotes.assessment)}
        </span>
      </div>
    `;
  }

  __renderPlan() {
    return html`
      <div id="${ELEMENTS.planInfo.id}" class="section-container">
        <span id="plan-title" class="section-title title-underline">Plan</span>

        <span id="${ELEMENTS.planDetail.id}" class="chart-note-details">
          ${this.__formatNoteContent(this.model.chartNotes.plan)}
        </span>
      </div>
    `;
  }

  __renderSignature() {
    const { encounter } = this.model;

    return encounter.signed
      ? html`
          <neb-provider-signature
            id="${ELEMENTS.signature.id}"
            .model="${encounter}"
          ></neb-provider-signature>
        `
      : '';
  }

  __renderContent() {
    if (!this.model) return html``;

    return this.__checkValidModel()
      ? html`
          ${this.__renderPracticeInfo()} ${this.__renderPatientInfo()}
          ${this.__renderEncounterInfo()} ${this.__renderSubjective()}
          ${this.__renderObjective()} ${this.__renderAssessment()}
          ${this.__renderPlan()} ${this.__renderSignature()}
        `
      : html`
          <div
            id="${ELEMENTS.noPriorEncounterMessage.id}"
            class="no-prior-encounters"
          >
            ${NO_PRIOR_ENCOUNTERS}.
          </div>
        `;
  }

  render() {
    return html`
      ${unsafeHTML(ckeditorStyles)}
      <div id="encounter-summary-content" class="encounter-summary-content">
        ${this.__renderContent()}
      </div>
    `;
  }
}

customElements.define('neb-encounter-summary', NebEncounterSummary);
