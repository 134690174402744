import { html, css } from 'lit';

import { allowDownload } from '../../../../../src/utils/e-claim-files';
import { bulkPrintClaimBatches } from '../../../../neb-api-client/src/claim-batches';
import { openError } from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_COLOR_GREY_3,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { getPrintSettings } from '../../../../neb-utils/claims';
import { parseDate } from '../../../../neb-utils/date-util';
import { capitalize } from '../../../../neb-utils/formatters';
import {
  showLoading,
  getFileURL,
  showError,
} from '../../../../neb-utils/neb-pdf-print-util';
import { MODE } from '../../../../neb-utils/table';

import Table, { ELEMENTS as BASE_ELEMENTS } from './neb-table';

import '../controls/neb-button-icon';
import '../controls/neb-progress-linear';
import '../../../../../src/components/misc/neb-icon';

export const CLAIM_BATCH_STATUS = Object.freeze({
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  READY_TO_DOWNLOAD: 'Ready to Download',
  ERRORED: 'Errored',
});

export const CONFIG = [
  {
    key: 'isElectronic',
    label: '',
    flex: css`24px 0 0`,
  },
  {
    sortable: true,
    key: 'batchId',
    label: 'Batch ID',
    flex: css`0.8 0 0`,
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`0.8 0 0`,
  },
  {
    sortable: true,
    key: 'createdAt',
    label: 'Date Started',
    flex: css`1 0 0`,
    compare: (a, b) => {
      const A = a != null ? new Date(a) : '';
      const B = b != null ? new Date(b) : '';

      if (A !== B) {
        return A < B ? -1 : 1;
      }

      return 0;
    },
  },
  {
    key: 'createdBy',
    label: 'Created By',
    flex: css`0.8 0 0`,
  },
  {
    key: 'clearinghousePartnerName',
    label: 'Clearinghouse',
    flex: css`0.8 0 0`,
  },
  {
    key: 'totalClaims',
    label: 'Total Claims',
    flex: css`0.7 0 0`,
  },
  {
    key: 'totalSuccessful',
    label: 'Successful',
    flex: css`0.7 0 0`,
  },
  {
    key: 'totalFailed',
    label: 'Failure',
    flex: css`0.7 0 0`,
  },
  {
    key: 'print',
    label: '',
    flex: css`0 0 24px`,
  },
  {
    key: 'download',
    label: '',
    flex: css`0 0 24px`,
  },
];

const EXPANDED_CONFIG = [
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
  },
];

const OPEN_TAB_ERROR_MESSAGE =
  'Cannot open claim tab, please check your pop-up blocker';

const INVALID_CLAIM_ERROR_MESSAGE = 'An error occurred when fetching claims';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonIconPrints: { selector: '.button-icon-print' },
  buttonIconDownloads: { selector: '.button-icon-download' },
  iconClaims: { selector: '.icon-claim' },
  buttonIconRefreshes: { selector: '[id^=button-icon-refresh]' },
  buttonIconOpen: { selector: '[id^=button-icon-open]' },
  tableExpandedRow: { selector: '[id^=table-expanded-row-]' },
};

class TableClaimBatches extends Table {
  static get properties() {
    return {
      loading: Array,
      users: Array,
      patientId: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell {
          align-items: center;
        }
        .icon-claim {
          margin-left: 5px;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
        .icon-open {
          margin-left: 10px;
          width: 14px;
          height: 14px;
        }
        .button-icon-refresh {
          margin-left: 5px;
          width: 24px;
          height: 24px;
        }

        .row-data[disabled] {
          background-color: ${CSS_COLOR_GREY_3};
        }

        .row-blocker {
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.5);
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }

        .text-link {
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};

          cursor: pointer;
        }

        .text-link:focus,
        .text-link:hover {
          opacity: 0.65;
        }

        .text-link::-moz-focus-inner {
          border: 0;
        }

        neb-progress-linear {
          position: absolute;
          bottom: 0;
          left: 0;

          z-index: 1;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.loading = [];
    this.users = [];

    this.onRefresh = () => {};

    this.onClickDownloadIcon = () => {};

    this.onClickS3Icon = () => {};

    this.hasAllClaimsFeatureFlag = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      // eslint-disable-next-line complexity
      print: async id => {
        let claimWindow;
        let res;

        claimWindow = this.__openWindow();

        if (claimWindow) {
          try {
            const settings = getPrintSettings();

            res = await bulkPrintClaimBatches(settings, id);

            claimWindow.location = getFileURL(res.buffer);
          } catch (e) {
            console.error(e);
            const msg = 'An error occurred when printing the claim';
            store.dispatch(openError(msg));
            showError(claimWindow, msg);
          }
        } else {
          return;
        }

        if (res) {
          const { buffer, bufferWithCmsBackground } = res;

          if (buffer && bufferWithCmsBackground) {
            const msg =
              'Only 1 of 2 tabs could be opened, please check your pop-up blocker';
            claimWindow.location = getFileURL(res.buffer);
            claimWindow = this.__openWindow(msg);
            claimWindow.location = getFileURL(res.bufferWithCmsBackground);
          } else if (buffer) {
            claimWindow.location = getFileURL(res.buffer);
          } else if (bufferWithCmsBackground) {
            claimWindow.location = getFileURL(res.bufferWithCmsBackground);
          } else {
            store.dispatch(openError(INVALID_CLAIM_ERROR_MESSAGE));
          }
        }
      },
      download: e => this.onClickDownloadIcon(e),
      refresh: e => this.onRefresh(e),
      retryBatch: e => this.onRetryBatch(e),
      clickOpen: item => this.onClickS3Icon(item),
      clickBatchId: e => {
        const batchId = parseInt(e.target.textContent, 10);

        this.onSelect('all-claims', { batchId });
      },
    };
  }

  __openWindow(error = OPEN_TAB_ERROR_MESSAGE) {
    try {
      const claimWindow = window.open();
      showLoading(claimWindow, 'Loading claim, please wait...');

      return claimWindow;
    } catch (e) {
      console.error(e);
      store.dispatch(openError(error));
    }

    return null;
  }

  __getClaimIcon(value) {
    return value ? 'neb:electronicClaims' : 'neb:paperClaims';
  }

  __getClaimIconId(value, index) {
    return value ? `isElectronic-${index}` : `isPaper-${index}`;
  }

  __disablePrintButton(rowIndex) {
    if (this.model[rowIndex].totalFailed === this.model[rowIndex].totalClaims) {
      return true;
    }
    return this.loading[rowIndex];
  }

  __shouldRenderDownload(item) {
    const { s3KeyX12, clearinghouseId, updatedAt } = item;

    const checkDuration =
      item.status === CLAIM_BATCH_STATUS.PENDING
        ? allowDownload(updatedAt)
        : true;

    return s3KeyX12 && clearinghouseId && checkDuration;
  }

  __renderDownload(rowIndex, claimBatchId) {
    return this.__shouldRenderDownload(this.model[rowIndex])
      ? html`
          <neb-button-icon
            class="button-icon-download"
            id="button-icon-download-${rowIndex}"
            icon="neb:download"
            .onClick="${
              () =>
                this.handlers.download({
                  ...this.model[rowIndex],
                  claimBatchId,
                  rowIndex,
                })
            }"
          ></neb-button-icon>
        `
      : '';
  }

  __renderIcon({ value, rowIndex, claimId }) {
    if (capitalize(value) === CLAIM_BATCH_STATUS.PENDING) {
      return html`
        <neb-button-icon
          id="button-icon-refresh-${rowIndex}"
          class="button-icon-refresh"
          icon="neb:refresh"
          .onClick="${
            name =>
              this.handlers.refresh({
                index: rowIndex,
                name,
                value: claimId,
                eClaimFileId: this.model[rowIndex].eClaimFileId,
              })
          }"
          ?disabled="${this.loading[rowIndex]}"
        ></neb-button-icon>
      `;
    }

    if (
      capitalize(value) === CLAIM_BATCH_STATUS.ERRORED &&
      this.model[rowIndex].s3KeyX12
    ) {
      return html`
        <neb-button-icon
          id="button-icon-refresh-${rowIndex}"
          class="button-icon-refresh"
          icon="neb:refresh"
          .onClick="${
            name =>
              this.handlers.retryBatch({
                index: rowIndex,
                name,
                value: claimId,
                eClaimFileId: this.model[rowIndex].eClaimFileId,
              })
          }"
          ?disabled="${this.loading[rowIndex]}"
        ></neb-button-icon>
      `;
    }

    return '';
  }

  __renderPrint(rowIndex) {
    return capitalize(this.model[rowIndex].status) ===
      CLAIM_BATCH_STATUS.COMPLETED
      ? html`
          <neb-button-icon
            class="button-icon-print"
            id="button-icon-print-${rowIndex}"
            icon="neb:print"
            .onClick="${() => this.handlers.print(this.model[rowIndex].id)}"
            ?disabled="${this.__disablePrintButton(rowIndex)}"
          ></neb-button-icon>
        `
      : '';
  }

  __renderOpenJSON(rowIndex) {
    return this.model[rowIndex].s3KeyJson
      ? html`
          <neb-button-icon
            id="button-icon-open-${rowIndex}"
            icon="neb:open"
            class="icon-open"
            .onClick="${() => this.handlers.clickOpen(this.model[rowIndex])}"
          ></neb-button-icon>
        `
      : '';
  }

  renderRow(item, index) {
    const rowModel = this.model[index];
    const rowErrors = this.errors[index];
    const expanded = this.expandFlags[index];
    const loading = this.loading[index];

    return html`
      ${this.__renderReorderSpacer(index)}

      <div
        id="row-${index}"
        class="row row-data"
        row="${index}"
        @click="${this.handlers.clickRow}"
        ?hidden="${index === this.__startDragIndex}"
        ?preview="${this.preview}"
        ?expanded="${expanded}"
        ?disabled="${loading}"
      >
        <div class="content" ?clickable="${this.mode !== MODE.NONE}">
          ${this.__renderReorderCell(index)}
          ${this.__renderDataCells(item, index)}
          ${this.__renderRemoveCell(index)} ${this.renderModeCell(index)}
        </div>

        ${
          expanded
            ? html`
                <div id="section-expand-${index}">
                  ${this.renderExpandedRow(index, rowModel, rowErrors)}
                </div>
              `
            : ''
        }
        ${this.__renderReorderLayer(index)}
        ${
          loading
            ? html`
                <div class="row-blocker"></div>
                <neb-progress-linear indeterminate></neb-progress-linear>
              `
            : ''
        }
      </div>
    `;
  }

  renderExpandedRow(index) {
    const description =
      this.model[index].description || 'No available message to display';

    return html`
      <neb-table
        id="table-expanded-row-${index}"
        class="table"
        .config="${EXPANDED_CONFIG}"
        .model="${[{ description }]}"
      ></neb-table>
    `;
  }

  // eslint-disable-next-line complexity
  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'batchId': {
        return this.onSelect
          ? html`
              <span class="text-link" @click="${this.handlers.clickBatchId}"
                >${value}
              </span>
              ${this.__renderOpenJSON(rowIndex)}
            `
          : html`
              <neb-text>${value}</neb-text>
              ${this.__renderOpenJSON(rowIndex)}
            `;
      }
      case 'createdAt':
        return parseDate(value).format('MM/DD/YYYY');

      case 'createdBy':
        return this.model[rowIndex].userId
          ? this.users.find(
              user => user.data.id === this.model[rowIndex].userId,
            ).label
          : '-';

      case 'clearinghousePartnerName':
        return this.model[rowIndex].clearinghouseName || '-';

      case 'print': {
        return this.__renderPrint(rowIndex);
      }

      case 'download': {
        return this.__renderDownload(rowIndex, this.model[rowIndex].id);
      }

      case 'isElectronic': {
        return html`
          <neb-icon
            id="${this.__getClaimIconId(value, rowIndex)}"
            class="icon-claim"
            .icon="${this.__getClaimIcon(value)}"
          ></neb-icon>
        `;
      }

      case 'status': {
        const claimId = this.model[rowIndex].id;

        return html`
          <div>${capitalize(value)}</div>

          ${this.__renderIcon({ value, rowIndex, claimId })}
        `;
      }

      default:
        return value;
    }
  }
}

window.customElements.define('neb-table-claim-batches', TableClaimBatches);
