import ApiClient from '../neb-api-client/src/utils/api-client-utils';
import { FEATURE_FLAGS, hasFeatureOrBeta } from '../neb-utils/feature-util';

export const billingApiClient = new ApiClient({ microservice: 'billing' });
export const coreApiClient = new ApiClient({ microservice: 'core' });
export const defaultApiClient = new ApiClient();

export const ApiClientType = Object.freeze({
  DEFAULT: 'DEFAULT',
  BILLING: 'BILLING',
  CORE: 'CORE',
});

export const createNamedReducer = (reducerFunction, reducerName) => (
  state,
  action,
) => {
  const { name } = action;
  const isInitializationCall = state === undefined;
  return isInitializationCall ||
    reducerName === name ||
    reducerName.startsWith(name) ||
    (name && name.startsWith(reducerName))
    ? reducerFunction(state, action)
    : state;
};

const __hasSession = getState => getState().session.item != null;

const getVersionAndClient = async apiClientType => {
  if (apiClientType === ApiClientType.BILLING) {
    return { apiClient: billingApiClient, version: 1 };
  }

  if (
    apiClientType === ApiClientType.CORE ||
    (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION))
  ) {
    return { apiClient: coreApiClient, version: 1 };
  }

  return { apiClient: defaultApiClient, version: undefined };
};

export const createTenantApiRequestAction = (
  requestOptions,
  actionCreators,
  apiClientType = ApiClientType.DEFAULT,
) => async (dispatch, getState) => {
  const { url, query, optOutLoadingIndicator, options } = requestOptions;
  const [
    startActionCreator,
    completeActionCreator,
    errorActionCreator,
  ] = actionCreators;
  if (!__hasSession(getState)) return undefined;
  dispatch(startActionCreator());

  try {
    const { apiClient, version } = await getVersionAndClient(apiClientType);
    const apiUrl = await url;

    const json = await apiClient.makeRequest({
      path: apiUrl,
      queryParams: query,
      optOutLoadingIndicator,
      version,
      ...options,
    });
    return dispatch(completeActionCreator(json));
  } catch (err) {
    return dispatch(errorActionCreator(err));
  }
};
