import { getLocations } from '../../../src/api-clients/locations';
import {
  BILLING_API_URL,
  APPOINTMENTS_API_URL,
  API_URL,
} from '../../neb-utils/env';
import { FEATURE_FLAGS, hasFeatureOrBeta } from '../../neb-utils/feature-util';

import { getActiveProviderUsers } from './practice-users-api-client';
import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';

export const billingApiClient = new ApiClient({ microservice: 'billing' });

const baseUrl = 'appointment-types';

function mapToModel(raw) {
  const model = {
    id: raw.id || '',
    active: Boolean(raw.active),
    color: raw.color || '#000',
    description: raw.description || '',
    duration: raw.duration || 0,
    locations: raw.locations,
    name: raw.name || '',
    order: Number(raw.order) || -1,
    providers: raw.providers,
    visibleOnline: Boolean(raw.visibleOnline),
    newPatient: Boolean(raw.newPatient),
    walkInEnabled: Boolean(raw.walkInEnabled),
    isDefault: Boolean(raw.isDefault),
  };

  return model;
}

function mapToModelWithProvidersAndLocations(raw, providers, locations) {
  const model = {
    id: raw.id || '',
    active: Boolean(raw.active),
    color: raw.color || '#000',
    description: raw.description || '',
    duration: raw.duration || 0,
    locations:
      locations.filter(location =>
        raw.locations.find(locationId => location.id === locationId),
      ) || [],
    name: raw.name || '',
    order: Number(raw.order) || -1,
    providers: raw.providers
      .map(id => providers.find(provider => provider.id === id))
      .filter(item => item),
    visibleOnline: Boolean(raw.visibleOnline),
    newPatient: Boolean(raw.newPatient),
    walkInEnabled: Boolean(raw.walkInEnabled),
    isDefault: Boolean(raw.isDefault),
  };

  return model;
}

export function createAppointmentType(model) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(model),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: baseUrl,
    version: 1,
  });
}

export function deleteAppointmentType(id) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.DELETE,
    path: `${baseUrl}/${id}`,
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });
}

export function getAppointmentTypes(optOutLoadingIndicator = false) {
  return billingApiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: `${baseUrl}`,
    optOutLoadingIndicator,
    version: 1,
  });
}

export function orderAppointmentTypes(ids) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify({ ids }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `${baseUrl}/order`,
    version: 1,
  });
}

export function updateAppointmentType(model) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(model),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `${baseUrl}/${model.id}`,
    version: 1,
  });
}

export function setDefaultAppointmentType(model) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(model),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `${baseUrl}/default`,
    version: 1,
  });
}

export function add(model) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(model),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: baseUrl,
    version: 1,
  });
}

export async function fetchMany(optOutLoadingIndicator = false) {
  const res = await billingApiClient.makeRequest({
    method: Method.GET,
    path: baseUrl,
    optOutLoadingIndicator,
    version: 1,
  });

  return res.data.map(item => mapToModel(item));
}

export async function fetchManyWithProvidersAndLocations(
  optOutLoadingIndicator = false,
) {
  const providers = await getActiveProviderUsers(optOutLoadingIndicator);
  const locations = await getLocations({
    hideInactive: true,
    hideAddressOnly: true,
  });

  const res = await billingApiClient.makeRequest({
    method: Method.GET,
    path: baseUrl,
    optOutLoadingIndicator,
    version: 1,
  });

  return res.data.map(item =>
    mapToModelWithProvidersAndLocations(item, providers, locations),
  );
}

export function remove(id) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.DELETE,
    path: `${baseUrl}/${id}`,
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });
}

export function reorder(ids) {
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify({ ids }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `${baseUrl}/order`,
    version: 1,
  });
}

export const update = model =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(model),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `${baseUrl}/${model.id}`,
    version: 1,
  });

export async function getAppointmentTypeLocationPublic(shortName) {
  const url = `${BILLING_API_URL}/public/v1/${shortName}/appointment-type-location`;

  const res = await fetch(url, {
    method: 'GET',
  });

  const json = await res.json();

  return json.data;
}

export async function getAppointmentTypesPublic(shortName) {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    const url = `${APPOINTMENTS_API_URL}/public/v1/${shortName}/appointment-types`;

    const res = await fetch(url, {
      method: 'GET',
    });

    const json = await res.json();

    return json.data;
  }

  const url = `${API_URL}/api/v1/public/${shortName}/appointmentTypes`;

  const res = await fetch(url, {
    method: 'GET',
  });

  const json = await res.json();

  return json.data;
}
