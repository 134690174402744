import '../../../../../neb-lit-components/src/components/neb-popup-header';
import '../../../../../neb-lit-components/src/components/patients/payment/neb-patient-payment-post';

import { openPopup } from '@neb/popup';
import { html, LitElement, css } from 'lit';

import { getLowInventoryMessage } from '../../../../../../src/api-clients/inventory';
import { NebFormPayment } from '../../../../../../src/components/forms/neb-form-payment';
import { openPayfacReaderPopup } from '../../../../../../src/features/payfac/utils';
import { getPaymentPostBanner } from '../../../../../../src/utils/auto-allocation';
import {
  createBannerController,
  DISPLAY_ON,
} from '../../../../../neb-alert/components/neb-alert-banner-controller';
import { allocatePayment } from '../../../../../neb-api-client/src/allocation-api-client';
import { getBillingCodesPayments } from '../../../../../neb-api-client/src/billing-codes';
import { createLineItems } from '../../../../../neb-api-client/src/ledger/line-items';
import * as patientApiClient from '../../../../../neb-api-client/src/patient-api-client';
import {
  getElectronicPayment,
  refundElectronicPayment,
} from '../../../../../neb-api-client/src/payments/electronic-payments-api-client';
import {
  getPaymentDetail,
  refundPayment,
  postPatientPaymentForEncounter,
  postPayment,
  printReceipt,
  voidPayment,
} from '../../../../../neb-api-client/src/payments-api-client';
import {
  openSuccess,
  openError,
  openInfo,
} from '../../../../../neb-dialog/neb-banner-state';
import { TABS } from '../../../../../neb-lit-components/src/components/forms/neb-form-allocation-charges';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../../neb-lit-components/src/utils/overlay-constants';
import { PAYMENT_ACTION_KEYS } from '../../../../../neb-popup/src/neb-popup-payment-action';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
} from '../../../../../neb-styles/neb-variables';
import { calculateAutoAllocateDebits } from '../../../../../neb-utils/auto-allocate-util';
import {
  ELECTRONIC_PAYMENT_TYPES,
  REFUND_MESSAGES,
  GENIUS_SALE_TYPES,
} from '../../../../../neb-utils/electronic-payments-util';
import {
  objToName,
  centsToCurrency,
} from '../../../../../neb-utils/formatters';
import {
  is500Error,
  isRefunded,
} from '../../../../../neb-utils/neb-payment-util';
import { printPdf } from '../../../../../neb-utils/neb-pdf-print-util';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../../neb-utils/neb-refresh';
import { getAppointmentListData } from '../../../../../neb-utils/pdf/print-upcoming-appointments';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  description: {
    id: 'description',
  },
  form: {
    id: 'form',
  },
  post: {
    id: 'post',
  },
};
export const BANNER_MESSAGE = {
  success: 'Payment posted successfully',
  error: 'An error occurred when posting the Payment',
};
export const VOIDED_BANNER_MESSAGE = {
  success: 'Payment voided successfully',
  error: 'An error occurred when voiding the payment',
};
export const VOIDED_DISCOUNT_BANNER_MESSAGE = {
  success: 'Discount voided successfully',
  error: 'An error occurred when voiding the discount',
};

const REFUND_BANNER_MESSAGE = {
  success: 'Payment refunded successfully',
  error: 'An error occurred when refunding the payment',
};
export const PAGE_TITLE = {
  form: () => 'Add Patient Payment',
  post: ({ codePayment: { description } }) =>
    `Patient Payment - ${description}`,
};
const DEFAULT_PAYMENT_TYPE = 'Gen - General Payment';

class NebPatientPaymentController extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      model: Object,
      __paymentDetail: Object,
      __paymentTypes: Array,
      __voidPayment: Object,
      __formModel: Object,
      __locations: Array,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__locations = userLocations;
    });
  }

  __initState() {
    this.__alertBanner = createBannerController(DISPLAY_ON.payment);
    this.layout = 'large';
    this.model = {};
    this.__paymentDetail = null;
    this.__paymentTypes = [];
    this.__voidPayment = {};
    this.__formModel = NebFormPayment.createModel();
    this.__locations = [];

    this.onDirtyChange = () => {};

    this.onDismiss = () => {};

    this.onDismissAll = () => {};

    this.onAllocatePayment = () => {};

    this.onEmailReceipt = () => {};

    this.onPostPaymentAction = () => {};

    this.onVoidPaymentAction = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      allocatePayment: async () => {
        await openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
          patientId: this.model.patientId,
          payments: [this.__paymentDetail],
          selectedTab: TABS.OUTSTANDING,
        });

        try {
          this.__paymentDetail = await getPaymentDetail(
            this.__paymentDetail.id,
          );
        } catch (e) {
          store.dispatch(
            openError('An error occurred when retrieving payment detail.'),
          );
        }
      },
      print: (...args) => this.__printPatientPayment(...args),
      email: () => this.__emailPatientPayment(),
      save: payment => this.__savePatientPayment(payment),
      dirty: dirty => this.onDirtyChange(dirty),
      dismiss: () => this.__dismissForm(),
      closeForm: () => this.__closeForm(),
      voidPayment: () => this.__voidPatientPayment(),
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__locationsService.disconnect();
  }

  async firstUpdated() {
    if (this.model.patientId) {
      this.__alertBanner.connect();

      this.__alertBanner.update(this.model.patientId);
    } else this.__alertBanner.disconnect();

    try {
      const paymentBillingCodes = await getBillingCodesPayments({}, true);

      this.__paymentTypes = paymentBillingCodes
        .filter(payment => payment.active && payment.forPatient)
        .map(payment => ({
          id: payment.id,
          code: payment.code,
          label: `${payment.code} - ${payment.description}`,
        }));
    } catch (e) {
      store.dispatch(
        openError('An error occurred when retrieving payment types.'),
      );
    }
  }

  update(changedProps) {
    if (changedProps.has('model') || changedProps.has('__paymentTypes')) {
      const codePayment = this.model.chargeInfo
        ? this.__paymentTypes.find(
            pt =>
              pt.label ===
              (this.model.chargeInfo.paymentType
                ? this.model.chargeInfo.paymentType
                : DEFAULT_PAYMENT_TYPE),
          )
        : null;

      const defaultModel = NebFormPayment.createModel();

      this.__formModel = {
        ...defaultModel,
        amount: this.model.chargeInfo
          ? this.model.chargeInfo.amount
          : defaultModel.amount,
        codePaymentId: codePayment
          ? codePayment.id
          : defaultModel.codePaymentId,
      };
    }

    super.update(changedProps);
  }

  async __printPatientPayment(includeFutureAppointments = false) {
    const printPayment = {
      ...this.__paymentDetail,
      amount: centsToCurrency(this.__paymentDetail.amount),
    };

    const selectedLocation =
      this.__locations.find(
        location => location.id === this.__paymentDetail.locationId,
      ) || null;

    const appointmentListData = includeFutureAppointments
      ? await getAppointmentListData(this.model.patientId, selectedLocation, 5)
      : null;

    printPdf(
      printReceipt({
        patientPayments: [printPayment],
        selectedLocation,
        appointmentListData,
      }),
    );
  }

  async __emailPatientPayment() {
    const patient = await patientApiClient.fetchOne(
      this.model.patientId,
      true,
      false,
    );

    const res = await openPopup(POPUP_RENDER_KEYS.EMAIL_RECEIPT, {
      paymentDetail: this.__paymentDetail,
      patientEmailAddresses: patient.emailAddresses,
    });

    if (res.success) {
      this.__paymentDetail = await getPaymentDetail(this.__paymentDetail.id);
    }
  }

  __formatPayerName() {
    return this.model.patient.name.last
      ? objToName(this.model.patient.name, {
          reverse: true,
          middleInitial: true,
        })
      : this.model.patient.name;
  }

  async __resolveAppointmentIdForRecurrence() {
    if (this.__resolvedAppointmentId) return this.__resolvedAppointmentId;

    let id = this.model.appointmentId ? this.model.appointmentId : null;

    if (this.model.recurrenceResolver) {
      id = await this.model.recurrenceResolver();
    }

    this.__resolvedAppointmentId = id;
    return id;
  }

  async __savePatientPayment(payment) {
    const appointmentId = await this.__resolveAppointmentIdForRecurrence();
    const formattedPayment = {
      ...payment,
      patientId: this.model.patientId,
      locationId: payment.locationId || this.model.locationId || null,
      appointmentId,
    };

    try {
      if (this.model.chargeInfo && this.model.chargeInfo.lineItems) {
        const {
          payment,
          lineItems,
          postedChargeIds,
          postedChargeCodes,
        } = await createLineItems({
          patientId: this.model.patientId,
          payment: formattedPayment,
          lineItems: this.model.chargeInfo.lineItems,
          invoiceId: this.model.chargeInfo.invoiceId,
          guarantorId: this.model.chargeInfo.guarantorId,
          attributeToUserId: this.model.chargeInfo.attributeToUserId,
        });

        const inventoryMessage = await getLowInventoryMessage({
          chargeIds: postedChargeIds,
          codes: postedChargeCodes,
        });

        if (inventoryMessage.length) {
          store.dispatch(openInfo(inventoryMessage));
        }

        this.__paymentDetail = { ...payment, purchase: true, lineItems };
      } else if (this.model.encounterId) {
        this.__paymentDetail = await postPatientPaymentForEncounter(
          formattedPayment,
          this.model.patientId,
          this.model.encounterId,
          true,
        );
      } else {
        this.__paymentDetail = await postPayment(formattedPayment);
      }

      if (payment && payment.receipt) {
        this.__paymentDetail.receipt = payment.receipt;
      }

      this.__handlers.dirty(false);

      if (this.model.autoAllocate) {
        const {
          lineItem: {
            id: lineItemId,
            allowedAmount,
            lineItemDebits,
            adjustments,
          },
        } = this.model.autoAllocate;

        const updatedDebits = calculateAutoAllocateDebits(
          lineItemDebits,
          this.__paymentDetail,
        );

        const selectedLineItem = {
          id: lineItemId,
          allowedAmount,
          debits: updatedDebits,
          adjustments,
        };

        await allocatePayment(this.__paymentDetail.id, [selectedLineItem]);

        sendRefreshNotification([REFRESH_CHANGE_TYPE.LEDGER]);

        this.onPostPaymentAction(this.__paymentDetail);

        store.dispatch(openSuccess(getPaymentPostBanner(this.__paymentDetail)));

        this.__closeForm();
      } else {
        this.onPostPaymentAction(this.__paymentDetail);
        store.dispatch(openSuccess(getPaymentPostBanner(this.__paymentDetail)));
      }
    } catch (e) {
      store.dispatch(openError(BANNER_MESSAGE.error));
    }
  }

  __maybeAddPayerKey() {
    return Object.prototype.hasOwnProperty.call(this.model, 'patient')
      ? { payer: this.__formatPayerName() }
      : {};
  }

  async __voidPatientPayment(skipElectronic = false) {
    let refundPaymentAction;

    if (this.__paymentDetail.electronicPaymentId && !skipElectronic) {
      refundPaymentAction = await this.__refundElectronicPayment();

      if (
        refundPaymentAction &&
        refundPaymentAction.action !== PAYMENT_ACTION_KEYS.REFUND
      ) {
        return undefined;
      }
    } else if (this.__paymentDetail.electronicPaymentId) {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND,
        paymentMethod: `${this.__paymentDetail.paymentMethod} - ${
          this.__paymentDetail.maskedCardDescription
        }`,
        paymentId: this.__paymentDetail.paymentNumber,
        amount: centsToCurrency(this.__paymentDetail.amount),
        ...this.__maybeAddPayerKey(),
      });

      if (refundPaymentAction) {
        try {
          const result = await refundPayment({
            paymentId: this.__paymentDetail.id,
            codeRefundId: refundPaymentAction.selectedReason.id,
            refundMethod: refundPaymentAction.refundMethod,
          });
          this.__paymentDetail = result;
          this.__voidPayment = result.refundPayment;
          this.onVoidPaymentAction(this.__voidPayment);
          this.onPostPaymentAction(this.__paymentDetail);

          store.dispatch(openSuccess(REFUND_BANNER_MESSAGE.success));
          return refundPaymentAction;
        } catch (e) {
          console.error(e);
          store.dispatch(openError(REFUND_BANNER_MESSAGE.error));
          return {};
        }
      }
    } else {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.VOID,
        paymentMethod: this.__paymentDetail.paymentMethod,
        paymentId: this.__paymentDetail.paymentNumber,
        amount: centsToCurrency(this.__paymentDetail.amount),
        ...this.__maybeAddPayerKey(),
      });
    }

    if (refundPaymentAction && refundPaymentAction.selectedReason) {
      const voidPaymentBody = {
        paymentId: this.__paymentDetail.id,
        codeRefundId: refundPaymentAction.selectedReason.id,
        refundMethod: refundPaymentAction.refundMethod,
      };

      try {
        if (this.__paymentDetail.electronicPaymentId) {
          const result = await refundPayment(voidPaymentBody);
          this.__paymentDetail = result;
          this.__voidPayment = result.refundPayment;
          this.onPostPaymentAction(this.__paymentDetail);
          this.onVoidPaymentAction(this.__voidPayment);
          store.dispatch(openSuccess(REFUND_BANNER_MESSAGE.success));
        } else {
          const result = await voidPayment(voidPaymentBody);
          this.__paymentDetail = result;
          this.__voidPayment = result.voidPayment;
          this.onPostPaymentAction(this.__paymentDetail);
          this.onVoidPaymentAction(this.__voidPayment);
          store.dispatch(openSuccess(VOIDED_BANNER_MESSAGE.success));
        }
      } catch (e) {
        store.dispatch(openError(VOIDED_BANNER_MESSAGE.error));
      }
    }

    return undefined;
  }

  async __refundElectronicPayment(opts = {}) {
    const ePayment = await getElectronicPayment(
      this.__paymentDetail.electronicPaymentId,
    );
    const refundPaymentAction = await openPopup(
      POPUP_RENDER_KEYS.PAYMENT_ACTION,
      {
        action: PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC,
        paymentId: this.__paymentDetail.paymentNumber,
        paymentMethod: `${this.__paymentDetail.paymentMethod} - ${
          this.__paymentDetail.maskedCardDescription
        }`,
        amount: centsToCurrency(this.__paymentDetail.amount),
        selectedReader: opts.selectedReader,
        selectedReason: opts.selectedReason,
        ePayment,
        ...this.__maybeAddPayerKey(),
      },
    );

    if (refundPaymentAction) {
      if (refundPaymentAction.action === PAYMENT_ACTION_KEYS.REFUND) {
        return refundPaymentAction;
      }

      let done = false;

      const originalPaymentAmount = this.__paymentDetail.amount;

      let paymentSuccess;
      let geniusRefund;

      while (!done) {
        done = true;

        if (ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT) {
          const payerPlanId = this.__paymentDetail
            ? this.__paymentDetail.payerPlanId || null
            : null;

          geniusRefund = await openPayfacReaderPopup({
            amount: refundPaymentAction.amount / 100,
            merchantAccounts: [
              {
                id: ePayment.merchantAccountId,
                cardReaders: [refundPaymentAction.selectedReader],
                active: true,
              },
            ],
            holderId: this.model.patientId,
            title: 'Merchant Account Details',
            subheader:
              'Select the merchant account details for this transaction, then follow the prompts on the card reader.',
            message: 'Payment Amount',
            confirmLabel: 'Submit',
            holderType: 'patient',
            geniusSaleType: GENIUS_SALE_TYPES.RETURN,
            logContent: {
              ...ePayment,
              patientId: this.model.patientId || null,
              payerPlanId,
              action: 'refund - cardReader - neb-patient-payment-controller',
            },
            pendingModel: {
              action: 'refund',
              patientId: this.model.patientId || null,
              payerPlanId,
              amount: refundPaymentAction.amount,
              referenceId: ePayment.referenceId,
              electronicPaymentId: ePayment.id,
              electronicReferenceId: ePayment.referenceId,
              codeRefundId: refundPaymentAction.selectedReason,
            },
          });

          if (!geniusRefund) {
            this.__refundElectronicPayment(refundPaymentAction);

            return {};
          }

          if (geniusRefund.status === 'DECLINED') {
            const response = await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              {
                ...(ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                  ? REFUND_MESSAGES.FAILED_400_MANUAL
                  : REFUND_MESSAGES.FAILED_400),
                subheader: geniusRefund.status,
                hyperLink:
                  ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                    ? 'Process Refund Manually'
                    : '',
              },
            );

            if (response.action === 'link') {
              this.__voidPatientPayment(true);
            }
            return {};
          }
        }

        try {
          const result = await openPopup(
            POPUP_RENDER_KEYS.TRANSACTION_PROCESSING,
            {
              promise: refundElectronicPayment(
                ePayment.merchantAccountId,
                ePayment.id,
                {
                  ...geniusRefund,
                  amount: refundPaymentAction.amount,
                  logContent: {
                    action: 'cardOnFile - neb-patient-payment-controller',
                    rawModel: ePayment,
                  },
                },
              ),
            },
          );

          if (result.success) {
            paymentSuccess = true;
            const refundPaymentBody = {
              paymentId: this.__paymentDetail.id,
              codeRefundId: refundPaymentAction.selectedReason.id,
              refundMethod: refundPaymentAction.refundMethod,
              cardRefundId: result.refund.returnId || result.refund.saleId,
              amount: refundPaymentAction.amount,
            };

            const res = await refundPayment(refundPaymentBody);
            this.__voidPayment = res.refundPayment || {};
            this.__paymentDetail = res;
          }

          if (result) {
            let response;

            if (result.success) {
              if (isRefunded(result.status)) {
                response = await openPopup(
                  POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                  REFUND_MESSAGES.APPROVED,
                );
              } else {
                response = await openPopup(
                  POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                  REFUND_MESSAGES.VOIDED,
                );
              }
            } else if (is500Error(result.code)) {
              response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
                ...REFUND_MESSAGES.FAILED_500,
                subheader: result.status,
              });

              if (response.action === 'confirm') {
                done = false;
              }
            } else {
              response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
                ...(ePayment.type
                  ? REFUND_MESSAGES.FAILED_400_MANUAL
                  : REFUND_MESSAGES.FAILED_400),
                subheader: result.status,
                hyperLink:
                  ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                    ? 'Process Refund Manually'
                    : '',
              });

              if (response.action === 'link') {
                this.__voidPatientPayment(true);
              }
            }
          }
        } catch (e) {
          store.dispatch(openError(REFUND_BANNER_MESSAGE.error));
        }
      }

      if (paymentSuccess) {
        store.dispatch(openSuccess(REFUND_BANNER_MESSAGE.success));

        this.onVoidPaymentAction(this.__voidPayment);
        this.onPostPaymentAction(this.__paymentDetail);

        if (originalPaymentAmount !== refundPaymentAction.amount) {
          this.__closeForm();
        }
      }
    }
    return refundPaymentAction;
  }

  __closeForm() {
    if (this.model.appointmentId || this.model.doNotDismissAll) {
      this.onDismiss();
      return;
    }

    this.onDismissAll();
  }

  __dismissForm() {
    this.onDismiss();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        :host([layout='small']) .header {
          padding: 10px ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .form {
          flex: 1 0 0;
        }
      `,
    ];
  }

  __renderHeader() {
    const title = PAGE_TITLE[this.__paymentDetail ? 'post' : 'form'];

    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${title(this.__paymentDetail)}"
        .onCancel="${this.__handlers.closeForm}"
        .onBack="${this.__handlers.dismiss}"
        .showBackButton="${
          !this.__paymentDetail &&
            !this.model.appointmentId &&
            !this.model.packageOrSubscription
        }"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  __renderForm() {
    return html`
      <neb-form-payment
        id="${ELEMENTS.form.id}"
        class="form"
        .model="${this.__formModel}"
        .layout="${this.layout}"
        .chargeInfo="${this.model.chargeInfo}"
        .disablePaymentTypeAndAmount="${
          this.model.chargeInfo &&
            this.model.chargeInfo.amount &&
            !this.model.chargeInfo.allowEditPaymentTypeAndAmount
        }"
        .alwaysShowActionBar="${
          this.model.chargeInfo &&
            this.model.chargeInfo.amount &&
            this.model.chargeInfo.alwaysShowActionBar
        }"
        .patientId="${this.model.patientId}"
        .forPayer="${false}"
        .locations="${this.__locations}"
        .onSave="${this.__handlers.save}"
        .onChangeDirty="${this.__handlers.dirty}"
        .onCancel="${this.__handlers.dismiss}"
      ></neb-form-payment>
    `;
  }

  __renderPaymentPost() {
    const { firstName, lastName } = store.getState().session.item;

    return html`
      <neb-patient-payment-post
        id="${ELEMENTS.post.id}"
        .paymentTypes="${this.__paymentTypes}"
        .model="${
          { ...this.__paymentDetail, postedBy: `${lastName}, ${firstName}` }
        }"
        .layout="${this.layout}"
        .onAllocatePayment="${this.__handlers.allocatePayment}"
        .autoAllocate="${!!this.model.autoAllocate}"
        .chargeInfo="${this.model.chargeInfo}"
        .onPrint="${this.__handlers.print}"
        .onEmail="${this.__handlers.email}"
        .onClose="${this.__handlers.closeForm}"
        .onVoidPayment="${this.__handlers.voidPayment}"
        .voidPayment="${this.__voidPayment}"
      ></neb-patient-payment-post>
    `;
  }

  render() {
    return html`
      ${this.__renderHeader()}
      ${this.__paymentDetail ? this.__renderPaymentPost() : this.__renderForm()}
    `;
  }
}

customElements.define(
  'neb-patient-payment-controller',
  NebPatientPaymentController,
);
