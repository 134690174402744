import { centsToCurrency } from '../../neb-utils/formatters';

import ApiClient, {
  buildQueryString,
  Method,
  RESPONSE_TYPE,
} from './utils/api-client-utils';
import { getQueryParams } from './utils/query-params';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const BASE_URL = 'patients';

export function filterQuery(query) {
  return Object.fromEntries(Object.entries(query).filter(([_, v]) => v));
}

export const getInvoice = (
  patientId,
  invoiceId,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/invoices/${invoiceId}`,
    version: 1,
    cacheKey: `${patientId}-${invoiceId}-invoice`,
    optOutLoadingIndicator,
  });

export const getInvoices = (patientId, optOutLoadingIndicator = false) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/invoices`,
    version: 1,
    cacheKey: `${patientId}-invoices`,
    optOutLoadingIndicator,
  });

export const createAndAddInvoiceToLineItems = (lineItemIds, patientId) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `${BASE_URL}/${patientId}/invoices`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lineItemIds,
    }),
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });

export const getInvoicesWithMatchingHeader = async (patientId, lineItemIds) => {
  const queryParams = getQueryParams('lineItemIds', lineItemIds);

  const invoices = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/invoices/matching-header-info?${queryParams}`,
    version: 1,
  });

  return invoices.map(i => ({
    ...i,
    label: `${i.invoiceNumber} - ${centsToCurrency(i.total)}`,
  }));
};

export const getLedgerInvoiceItems = (
  invoiceId,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `invoices/${invoiceId}/line-items`,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    cacheKey: `${invoiceId}-ledger-invoice-items`,
    optOutLoadingIndicator,
  });

export function getReadyToSubmitHeaders(query) {
  return {
    'Content-Type': 'application/json',
    ...(query.statuses && { statuses: query.statuses }),
    ...(query.submissionMethods && {
      submissionMethods: query.submissionMethods,
    }),
    ...(query.payerIds && { payerIds: query.payerIds }),
    ...(query.providerIds && { providerIds: query.providerIds }),
    ...(query.locationIds && { locationIds: query.locationIds }),
  };
}

export const getReadyToSubmit = async (query = {}) => {
  const prefix = query && query.patientId ? `${query.patientId}-` : '';
  const queryString = buildQueryString(filterQuery(query));

  const res = await apiClient.makeRequest({
    method: Method.GET,
    headers: getReadyToSubmitHeaders(query),
    path: `invoices${queryString}`,
    version: 3,
    cacheKey: `${prefix}get-ready-to-submit-${queryString}`,
  });
  return res;
};

export const getPotentialClaims = async (invoices = []) => {
  const res = await apiClient.makeRequest({
    body: JSON.stringify({ invoices }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: 'invoices/potential-claims',
    version: 1,
  });
  return res;
};

export const getInvoiceSupplementalInfo = async invoiceId => {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: `invoices/${invoiceId}/supplemental-information`,
    version: 1,
  });
  return res;
};

export const updateInvoiceSupplementalInfo = async (
  queryParams = {},
  pathParams = {},
) => {
  const res = await apiClient.makeRequest({
    method: Method.PUT,
    path: `invoices/${pathParams.invoiceId}/supplemental-information`,
    version: 1,
    queryParams,
  });
  return res;
};
