import { html, css } from 'lit';

import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { openWarning } from '../../../packages/neb-dialog/neb-banner-state';
import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  BILL_TYPE,
  BILL_TYPE_ITEMS,
  ITEM_INSURANCE,
  ITEM_SELF,
} from '../../../packages/neb-utils/neb-ledger-util';
import {
  hasAuthorizationRemaining,
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
} from '../../../packages/neb-utils/patientAuthorization';
import { ITEM_EMPTY } from '../../../packages/neb-utils/selectors';
import { CSS_FONT_WEIGHT_BOLD, CSS_WARNING_COLOR } from '../../styles';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  billType: { id: 'bill-type' },
  payer: { id: 'payer' },
  payerLabel: { id: 'payer-label' },
  plan: { id: 'plan' },
  secondary: { id: 'secondary' },
  guarantor: { id: 'guarantor' },
  addCaseButton: { id: 'button-add-case' },
  case: { id: 'case' },
  authorization: { id: 'authorization' },
  iconAuthorizationWarning: {
    id: 'icon-authorization-warning',
  },
  carePackage: { id: 'carePackage' },
};

const REQUIRED_ERROR = 'Required';

export class NebFormBillingHeader extends NebForm {
  static get properties() {
    return {
      invoiceNumber: String,
      triggerSaveAndClose: Boolean,
      carePackageWithInsuranceEnabled: Boolean,
      multiCarePackageEnabled: Boolean,
      __selectedAuthorization: Object,
    };
  }

  static createModel() {
    return {
      authorization: {
        id: null,
        label: '',
      },
      billType: {
        id: BILL_TYPE.SELF,
        label: 'Self Pay',
      },
      case: {
        id: null,
        label: '',
      },
      guarantor: {
        id: null,
        label: 'Self',
      },
      payer: {
        id: null,
        label: 'Self',
      },
      primaryInsurance: {
        id: null,
        label: '',
      },
      secondaryInsurance: {
        id: null,
        label: '',
      },
      patientPackage: {
        id: null,
        label: '',
      },
    };
  }

  initState() {
    super.initState();

    this.invoiceNumber = '';
    this.triggerSaveAndClose = false;
    this.carePackageWithInsuranceEnabled = false;
    this.multiCarePackageEnabled = false;

    this.confirmLabel = 'Update';
    this.dropdownItems = {
      insurances: [],
      guarantors: [],
      cases: [],
      authorizations: [],
      packages: [],
    };

    this.onSave = () => {};
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      const authorizationInfo = this.dropdownItems.authorizations.find(
        auth => auth.data.id === this.model.authorization.id,
      );

      if (authorizationInfo) {
        this.__selectedAuthorization = authorizationInfo.data;
      }

      if (this.triggerSaveAndClose) {
        this.__dirty = true;
      }
    }
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeBillType: e => {
        if (e.event === 'select') {
          const billTypeInfo = e.value;

          this.formService.apply('billType.id', billTypeInfo.data.id);
          this.formService.apply('billType.label', billTypeInfo.label);

          if (this.state.billType.id !== BILL_TYPE.INSURANCE) {
            this.formService.apply('payer.label', 'Self');
          } else {
            this.formService.apply('payer.label', '');
          }

          this.formService.apply('payer.id', null);

          this.formService.apply('primaryInsurance.id', null);
          this.formService.apply('primaryInsurance.label', '');

          this.formService.apply('secondaryInsurance.id', null);
          this.formService.apply('secondaryInsurance.label', '');

          const packageInfo =
            this.multiCarePackageEnabled &&
            billTypeInfo.data.id === BILL_TYPE.PACKAGE
              ? this.model.patientPackage
              : { id: null, label: '' };

          this.formService.apply('patientPackage.id', packageInfo.id);
          this.formService.apply('patientPackage.label', packageInfo.label);
        }
      },
      changePlan: e => {
        if (e.event === 'select') {
          const planInfo = e.value.data;

          this.__changeInsuranceState(e, planInfo);
          this.formService.validate();
        }
      },
      changeCarePackage: e => {
        if (e.event === 'select') {
          const carePackage = e.value.data;

          this.__changePackageState(e, carePackage);
          this.formService.validate();
        }
      },
      changeGuarantor: e => {
        if (e.event === 'select') {
          const guarantorInfo = e.value.data;

          if (!guarantorInfo) {
            this.formService.apply(`${e.name}.id`, e.value.id);
          } else {
            this.formService.apply(`${e.name}.id`, guarantorInfo.id);
          }

          this.formService.apply(`${e.name}.label`, e.value.label);
        }
      },
      // eslint-disable-next-line complexity
      changeCase: e => {
        if (e.event !== 'select') return;

        const caseInfo = e.value.data;
        const caseLabel = e.value.label;

        if (!this.state.case.label && !caseLabel) {
          return;
        }

        this.formService.apply(`${e.name}.id`, caseInfo.id);
        this.formService.apply(`${e.name}.label`, caseLabel);

        this.__selectedAuthorization = caseInfo.id
          ? caseInfo.patientAuthorizations[0]
          : null;

        if (this.__selectedAuthorization) {
          this.formService.apply(
            'authorization.id',
            this.__selectedAuthorization.id,
          );

          const dateRange = this.__selectedAuthorization.authorizedDates
            ? ` (${this.__selectedAuthorization.authorizedDates})`
            : '';

          this.formService.apply(
            'authorization.label',
            `${this.__selectedAuthorization.authorizationNumber}${dateRange}`,
          );
        } else {
          this.formService.apply('authorization.id', null);
          this.formService.apply('authorization.label', '');
        }

        if (!hasAuthorizationRemaining(this.__selectedAuthorization)) {
          this.__showAuthorizationWarning();
        }

        if (this.__hasCaseWithAuthAndPayer()) {
          const activeInsurances = this.__getActiveInsurances();
          this.__updatePlanFieldsWithCasePlanInfo(caseInfo, activeInsurances);

          if (this.__hasCaseWithAuthAndSecInsurance()) {
            this.__updateSecondaryFieldWithCaseSecondaryInfo(
              caseInfo,
              activeInsurances,
            );
          }
        }
      },
      changeAuthorization: e => {
        if (e.event === 'select') {
          this.__selectedAuthorization = e.value.data;

          if (this.__selectedAuthorization.id) {
            this.formService.apply(
              `${e.name}.id`,
              this.__selectedAuthorization.id,
            );

            this.formService.apply(`${e.name}.label`, e.value.label);

            const foundCase = this.dropdownItems.cases.find(
              c => c.data.id === this.__selectedAuthorization.patientCaseId,
            );

            this.formService.apply(
              'case.id',
              this.__selectedAuthorization.patientCaseId,
            );

            this.formService.apply('case.label', foundCase.label);

            if (this.__hasCaseWithAuthAndPayer()) {
              const activeInsurances = this.__getActiveInsurances();
              const caseFound = this.__findCase();

              this.__updatePlanFieldsWithCasePlanInfo(
                caseFound.data,
                activeInsurances,
              );

              if (this.__hasCaseWithAuthAndSecInsurance()) {
                this.__updateSecondaryFieldWithCaseSecondaryInfo(
                  caseFound.data,
                  activeInsurances,
                );
              }
            }
          }

          if (!hasAuthorizationRemaining(this.__selectedAuthorization)) {
            this.__showAuthorizationWarning();
          }
        }
      },
      authorizationWarningClick: () => this.__showAuthorizationWarning(),
      save: () => {
        this.formService.validate();

        if (this.__hasRequiredError()) {
          return;
        }

        this.onSave(this.state);
      },
      addNewCase: () => this.onCancel('newCase'),
    };
  }

  createSelectors() {
    return {
      children: {
        primaryInsurance: {
          validateManually: true,
          validators: [
            {
              error: REQUIRED_ERROR,
              validate: (value, _, state) => {
                if (
                  this.__dirty &&
                  state.billType.id === BILL_TYPE.INSURANCE &&
                  (value.label === '' || value.label === null)
                ) {
                  return false;
                }
                return true;
              },
            },
          ],
        },

        patientPackage: {
          validateManually: true,
          validators: [
            {
              error: REQUIRED_ERROR,
              validate: (value, _, state) => {
                if (
                  this.__dirty &&
                  state.billType.id === BILL_TYPE.PACKAGE &&
                  !this.multiCarePackageEnabled &&
                  (value.label === '' || value.label === null)
                ) {
                  return false;
                }
                return true;
              },
            },
          ],
        },
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow-y: unset;
          min-height: unset;
        }

        .label {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .container-select {
          display: flex;
          align-items: center;
          width: 100%;
          height: fit-content;
        }

        .select {
          display: flex;
          width: 100%;
          margin-bottom: 14px;
        }

        .icon-authorization-warning {
          margin: 0 0 0 10px;
          display: block;
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_WARNING_COLOR};
        }
      `,
    ];
  }

  __hasRequiredError() {
    return Object.values(this.__errors).some(value => value === REQUIRED_ERROR);
  }

  __changeInsuranceState(e, insuranceInfo) {
    this.formService.apply(`${e.name}.id`, insuranceInfo.id);
    this.formService.apply(`${e.name}.label`, e.value.label);

    if (!this.state.primaryInsurance.id) {
      this.formService.apply('secondaryInsurance.id', null);
      this.formService.apply('secondaryInsurance.label', '');
    }

    if (e.name === 'primaryInsurance') {
      const payerName = insuranceInfo.payerPlan
        ? `(${insuranceInfo.payerPlan.alias}) ${
            insuranceInfo.payerPlan.payerName
          }`
        : '';

      this.formService.apply(
        'payer.id',
        insuranceInfo.payerPlan ? insuranceInfo.payerPlan.id : null,
      );

      this.formService.apply('payer.label', payerName);
    }
  }

  __changePackageState(e, packageInfo) {
    this.formService.apply('patientPackage.id', packageInfo.id);
    this.formService.apply('patientPackage.label', e.value.label);
  }

  __updatePlanFieldsWithCasePlanInfo(caseInfo, activeInsurances) {
    this.formService.apply('billType.id', ITEM_INSURANCE.data.id);
    this.formService.apply('billType.label', ITEM_INSURANCE.label);

    this.formService.apply('secondaryInsurance.id', null);
    this.formService.apply('secondaryInsurance.label', '');

    const primaryInsurance = activeInsurances.find(
      i => i.data.id === caseInfo.payerInsurance.primaryInsuranceId,
    );

    this.formService.apply(
      'primaryInsurance.id',
      caseInfo.payerInsurance.primaryInsuranceId,
    );

    this.formService.apply('primaryInsurance.label', primaryInsurance.label);

    const payerName = `(${caseInfo.primaryPayer.alias}) ${
      caseInfo.primaryPayer.payerName
    }`;

    const payerId = caseInfo.primaryPayer.id;

    this.formService.apply('payer.label', payerName);
    this.formService.apply('payer.id', payerId);
  }

  __updateSecondaryFieldWithCaseSecondaryInfo(caseInfo, activeInsurances) {
    const secondaryInsurance = activeInsurances.find(
      i => i.data.id === caseInfo.payerInsurance.secondaryInsuranceId,
    );

    this.formService.apply(
      'secondaryInsurance.id',
      caseInfo.payerInsurance.secondaryInsuranceId,
    );

    this.formService.apply(
      'secondaryInsurance.label',
      secondaryInsurance.label,
    );
  }

  __findCase() {
    const activeCases = this.__getActiveCases();
    const chosenCase = this.state.case.id
      ? activeCases.find(c => c.data.id === this.state.case.id)
      : null;

    return chosenCase;
  }

  __hasCaseWithAuthAndPayer() {
    const chosenCase = this.__findCase();

    return Boolean(
      chosenCase &&
        chosenCase.data.payerInsurance.primaryPayerId &&
        chosenCase.data.primaryPayer.id &&
        chosenCase.data.patientAuthorizations.length,
    );
  }

  __hasCaseWithAuthAndSecInsurance() {
    const chosenCase = this.__findCase();

    return Boolean(
      this.__hasCaseWithAuthAndPayer() &&
        chosenCase &&
        chosenCase.data.payerInsurance.secondaryInsuranceId,
    );
  }

  __getActiveInsurances() {
    return this.dropdownItems.insurances.filter(
      insurance => insurance.data.active,
    );
  }

  __getActiveGuarantors() {
    const validGuarantors = this.dropdownItems.guarantors.filter(
      guarantor => guarantor.data.active,
    );

    return [ITEM_SELF, ...validGuarantors];
  }

  __getActiveCases() {
    const validCases = this.dropdownItems.cases.filter(c => c.data.active);

    return [ITEM_EMPTY, ...validCases];
  }

  __getAuthorizationItems() {
    if (!this.state.case.id) {
      return [ITEM_EMPTY, ...this.dropdownItems.authorizations];
    }

    const foundCase = this.__findCase();

    if (!foundCase) {
      return [];
    }

    return this.dropdownItems.authorizations.filter(
      auth => auth.data.patientCaseId === foundCase.data.id,
    );
  }

  __getInsurances() {
    const validInsurances = this.__getActiveInsurances().filter(
      insurance =>
        insurance.data.id !== this.state.primaryInsurance.id &&
        insurance.data.id !== this.state.secondaryInsurance.id,
    );

    return [ITEM_EMPTY, ...validInsurances];
  }

  __getCarePackages() {
    const validPackages = this.dropdownItems.packages.filter(
      carePackage => carePackage.data.active,
    );

    return [ITEM_EMPTY, ...validPackages];
  }

  __showAuthorizationWarning() {
    store.dispatch(openWarning(NO_REMAINING_AUTHORIZATIONS_MESSAGE));
  }

  __renderPayerField() {
    return html`
      <div>
        <div id="${ELEMENTS.payerLabel.id}" class="label">Payer</div>
        <div id="${ELEMENTS.payer.id}">${this.state.payer.label}</div>
      </div>
    `;
  }

  __renderBillTypeDropdown() {
    return html`
      <neb-select
        id="${ELEMENTS.billType.id}"
        name="billType"
        label="Bill Type"
        .value="${this.state.billType}"
        .items="${BILL_TYPE_ITEMS}"
        .onChange="${this.handlers.changeBillType}"
        .disabled="${this.__hasCaseWithAuthAndPayer()}"
        showFullText
        wrapText
        itemHeight="60"
        forceDown="${true}"
      ></neb-select>
    `;
  }

  __renderPlanDropdown() {
    const billType = this.state.billType.id;

    switch (billType) {
      case BILL_TYPE.INSURANCE:
        return html`
          <neb-select
            id="${ELEMENTS.plan.id}"
            name="primaryInsurance"
            label="Plan"
            .value="${this.state.primaryInsurance}"
            .items="${this.__getInsurances()}"
            .onChange="${this.handlers.changePlan}"
            .disabled="${this.__hasCaseWithAuthAndPayer()}"
            .error="${this.__errors.primaryInsurance}"
            showFullText
            wrapText
            itemHeight="60"
          ></neb-select>
        `;
      case BILL_TYPE.PACKAGE:
        return this.__renderCarePackageDropdown();
      default:
        return '';
    }
  }

  __renderSecondaryDropdown() {
    return this.state.billType.id === BILL_TYPE.INSURANCE
      ? html`
          <neb-select
            id="${ELEMENTS.secondary.id}"
            name="secondaryInsurance"
            label="Secondary"
            .value="${this.state.secondaryInsurance}"
            .items="${this.__getInsurances()}"
            .onChange="${this.handlers.changePlan}"
            .disabled="${
              !this.state.primaryInsurance.id ||
                this.__hasCaseWithAuthAndSecInsurance()
            }"
            showFullText
            wrapText
            itemHeight="60"
          ></neb-select>
        `
      : '';
  }

  __renderGuarantorDropdown() {
    return html`
      <neb-select
        id="${ELEMENTS.guarantor.id}"
        name="guarantor"
        label="Guarantor"
        .value="${this.state.guarantor}"
        .items="${this.__getActiveGuarantors()}"
        .onChange="${this.handlers.changeGuarantor}"
        showFullText
        wrapText
        itemHeight="60"
      ></neb-select>
    `;
  }

  __renderAddCaseButton() {
    return html`
      <div>
        <neb-button-action
          id="${ELEMENTS.addCaseButton.id}"
          class="button"
          label="Add Case"
          .onClick="${this.handlers.addNewCase}"
        ></neb-button-action>
      </div>
    `;
  }

  __renderCaseDropdown() {
    return html`
      <neb-select
        id="${ELEMENTS.case.id}"
        name="case"
        label="Case"
        .value="${this.state.case}"
        .items="${this.__getActiveCases()}"
        .onChange="${this.handlers.changeCase}"
        showFullText
        wrapText
        itemHeight="60"
      ></neb-select>
    `;
  }

  __renderAuthorizationDropdown() {
    return html`
      <div class="container-select">
        <neb-select
          id="${ELEMENTS.authorization.id}"
          class="select"
          name="authorization"
          label="Authorization"
          .value="${this.state.authorization}"
          .items="${this.__getAuthorizationItems()}"
          .onChange="${this.handlers.changeAuthorization}"
          showFullText
          wrapText
          itemHeight="60"
          showTitle
        ></neb-select>
        ${
          hasAuthorizationRemaining(this.__selectedAuthorization)
            ? ''
            : html`
                <neb-icon
                  id="${ELEMENTS.iconAuthorizationWarning.id}"
                  class="icon-authorization-warning"
                  icon="neb:warning"
                  @click="${this.handlers.authorizationWarningClick}"
                ></neb-icon>
              `
        }
      </div>
    `;
  }

  __renderCarePackageDropdown() {
    if (this.multiCarePackageEnabled) {
      return '';
    }

    return html`
      <neb-select
        id="${ELEMENTS.carePackage.id}"
        name="patientPackage"
        label="Care Package"
        showFullText
        wrapText
        itemHeight="60"
        .items="${this.__getCarePackages()}"
        .onChange="${this.handlers.changeCarePackage}"
        .value="${this.state.patientPackage}"
        .error="${this.__errors.patientPackage}"
      ></neb-select>
    `;
  }

  __renderInsuranceCarePackageDropdown() {
    return this.state.billType.id === BILL_TYPE.INSURANCE &&
      this.carePackageWithInsuranceEnabled
      ? this.__renderCarePackageDropdown()
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderBillTypeDropdown()} ${this.__renderPayerField()}
      ${this.__renderPlanDropdown()} ${this.__renderSecondaryDropdown()}
      ${this.__renderInsuranceCarePackageDropdown()}
      ${this.__renderGuarantorDropdown()} ${this.__renderAddCaseButton()}
      ${this.__renderCaseDropdown()} ${this.__renderAuthorizationDropdown()}
    `;
  }
}

customElements.define('neb-form-billing-header', NebFormBillingHeader);
