import '../../../neb-lit-components/src/components/neb-unsupported-page';
import '../../../neb-lit-components/src/components/neb-nav-list';
import '../../../neb-lit-components/src/components/patients/cases/neb-patient-cases-page';
import '../../../neb-lit-components/src/components/claims/neb-claims-worklist-controller';
import '../../../neb-lit-components/src/components/neb-tab-old';
import '../../../neb-app-layout/neb-no-access-page';
import '../../../../src/components/controllers/ledger/neb-ledger-claims-controller';
import './billing/neb-patient-tabs-billing-controller';
import './clinical/neb-patient-tabs-clinical-controller';
import './documents/neb-patient-documents-controller';
import './insurance/neb-patient-insurance-controller';
import './neb-patient-tabs-overview-controller';

import { decodeUserPermissions } from '@neb/permissions';
import {
  matchRouteSwitch,
  navigate,
  redirect,
  getRoute,
  EVENT_ROUTE_CHANGE,
} from '@neb/router';
import { LitElement, html, css } from 'lit';

import { saveLastLocation } from '../../../../src/api-clients/recent-record';
import { locationToEnum } from '../../../../src/utils/recent-records';
import { GuarantorService } from '../../../neb-api-client/src/services/guarantor';
import { ELEMENTS as BALANCE_CONTROLLER_ELEMENTS } from '../../../neb-lit-components/src/components/patients/ledger/balance/neb-ledger-balance-controller';
import { ELEMENTS as CHARGE_CONTROLLER_ELEMENTS } from '../../../neb-lit-components/src/components/patients/ledger/charges/neb-ledger-charges-controller';
import { store, connect } from '../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';
import { joinPaths } from '../../../neb-utils';
import { objToName, HIDE_PREFERRED_OPTS } from '../../../neb-utils/formatters';
import { setHoverBarYOffset } from '../../../neb-utils/neb-ledger-util';
import { NEBULA_REFRESH_EVENT } from '../../../neb-utils/neb-refresh';

import { ELEMENTS as LEDGER_CONTROLLER_ELEMENTS } from './ledger/neb-patient-ledger-controller';

export const ELEMENTS = {
  container: { id: 'container' },
  controllerBilling: { id: 'controller-billing' },
  controllerClinical: { id: 'controller-clinical' },
  controllerDocuments: { id: 'controller-documents' },
  controllerLedger: { id: 'controller-ledger' },
  controllerInsurance: { id: 'controller-insurance' },
  controllerOverview: { id: 'controller-overview' },
  ledgerClaimsController: { id: 'ledger-claims-controller' },
  navList: { id: 'nav-list' },
  tabs: { id: 'tabs' },
  tabItems: { selector: 'neb-tab-old' },
  casesPage: { id: 'cases-page' },
  claimsWorklistController: { id: 'claims-worklist-controller' },
  noAccessPage: { id: 'no-access-page' },
  casesUnsupportedPage: { id: 'cases-unsupported-page' },
};

const ROUTES_TO_IGNORE_MOBILE = [
  'overview',
  'clinical',
  'billing',
  'insurance',
  'activity',
];

const ROUTES_TO_IGNORE_DESKTOP = [
  ...ROUTES_TO_IGNORE_MOBILE,
  'ledger',
  'ledger/activity',
  'ledger/balance',
  'ledger/charges',
  'claims-worklist',
  'claims',
];

class NebPatientTabsController extends connect(store)(LitElement) {
  static get properties() {
    return {
      __permissions: Object,
      __selectedTab: String,
      __ledgerPatient: Object,
      __guarantors: Array,
      documentCount: Number,
      caseCount: Number,
      insuranceCount: Number,
      patient: Object,
      route: String,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        :host(:not([layout='small'])) {
          width: 0;
        }

        .container {
          display: flex;
          height: 100%;
          flex-direction: column;
          overflow: auto;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
        }

        :host([layout='small']) .container {
          padding: 0;
        }

        .content {
          margin: 0;
          flex: 1 0 0;
          min-height: 0;
          background-color: ${CSS_COLOR_WHITE};
        }

        .list {
          width: 100%;
          height: 100%;
          --item-padding: 15px;
        }

        .tabs {
          flex: 0 0 auto;
        }

        .ledger {
          min-height: fit-content;
          min-height: -moz-fit-content;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__workListEnabled = false;
    this.__ledgerPatient = null;
    this.__permissions = {};
    this.__navItems = [];
    this.__tabs = [];
    this.__guarantors = [];
    this.documentCount = 0;
    this.caseCount = 0;
    this.insuranceCount = 0;
    this.route = '';
    this.layout = '';
    this.patient = {};

    this.onClick = () => {};

    this.onUpdatePatient = () => {};

    this.onEditPatient = () => {};

    this.__guarantorService = new GuarantorService(data => {
      this.__guarantors = data;
    });
  }

  __initHandlers() {
    this.__handlers = {
      clickPrimary: tab => {
        if (tab !== this.__selectedTab) {
          navigate(`#/patients/${this.patient.id}/${tab}`);
        }
      },

      editClicked: () => this.onEditPatient(),

      selectNavItem: index => {
        const { name } = this.__navItems[index];
        navigate(`#/patients/${this.patient.id}/${name}`);
      },

      updateGuarantors: () => {
        this.__guarantorService.update(this.patient.id, {
          includeBalance: true,
        });
      },
      updateCaseCount: count => {
        if (count) {
          this.caseCount = count;
        }
      },

      updatePatient: () => this.onUpdatePatient(),

      scroll: e => {
        const controllerLedger = this.shadowRoot.getElementById(
          ELEMENTS.controllerLedger.id,
        );

        if (controllerLedger) {
          return setHoverBarYOffset({
            context: controllerLedger,
            top: e.currentTarget.scrollTop,
            chargesPageId: LEDGER_CONTROLLER_ELEMENTS.chargesPage.id,
            balancePageId: LEDGER_CONTROLLER_ELEMENTS.balancePage.id,
            openChargesId: CHARGE_CONTROLLER_ELEMENTS.openCharges.id,
            closedChargesId: CHARGE_CONTROLLER_ELEMENTS.closedCharges.id,
            outstandingChargesId:
              BALANCE_CONTROLLER_ELEMENTS.outstandingCharges.id,
          });
        }

        return undefined;
      },

      routeChanged: e => {
        const route = e && e.detail ? e.detail : undefined;

        if (this.__isRouteIgnored(route)) return;

        const patientId = route.split('/')[2];

        if (!patientId) return;
        const patientAndId = `/patients/${patientId}`;

        let routePart = route.replace(patientAndId, '');
        let additionalInformation = null;

        if (routePart.includes('/encounters/')) {
          const encounterId = routePart.substring(
            routePart.lastIndexOf('/') + 1,
          );

          additionalInformation = {
            encounterId,
          };

          routePart = routePart.substring(0, routePart.lastIndexOf('/'));
        }

        this.__postLastVisitedLocation({
          patientId,
          route: routePart,
          additionalInformation,
        });
      },
    };
  }

  _stateChanged({ session }) {
    if (session) {
      const {
        item: { access, tenantId },
      } = session;

      const { p } = access[tenantId];

      this.__permissions = decodeUserPermissions(p);
    }
  }

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener(
      NEBULA_REFRESH_EVENT,
      this.__handlers.updateGuarantors,
    );

    window.addEventListener(EVENT_ROUTE_CHANGE, this.__handlers.routeChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener(
      NEBULA_REFRESH_EVENT,
      this.__handlers.updateGuarantors,
    );

    window.removeEventListener(
      EVENT_ROUTE_CHANGE,
      this.__handlers.routeChanged,
    );
  }

  genNavItems() {
    const allNavItems = [
      {
        exact: false,
        label: 'Overview',
        name: 'overview',
        permission: 'patients',
        path: '/overview/',
        resolver: tail => html`
          <neb-patient-tabs-overview-controller
            id="${ELEMENTS.controllerOverview.id}"
            class="content"
            .small="${this.layout === 'small'}"
            .layout="${this.layout}"
            .patientId="${this.patient ? this.patient.id : ''}"
            .route="${tail}"
            .guarantors="${this.__guarantors}"
            .onEditPatient="${this.__handlers.editClicked}"
          ></neb-patient-tabs-overview-controller>
        `,
      },
      {
        exact: false,
        label: 'Clinical',
        name: 'clinical',
        permission: 'charting',
        path: '/clinical/',
        resolver: tail => html`
          <neb-patient-tabs-clinical-controller
            id="${ELEMENTS.controllerClinical.id}"
            class="content"
            .layout="${this.layout}"
            .patientId="${this.patient.id}"
            .route="${tail}"
          ></neb-patient-tabs-clinical-controller>
        `,
      },
      {
        exact: false,
        label: this.getDocumentLabel(),
        name: 'documents',
        permission: 'patients',
        path: '/documents/',
        resolver: () => html`
          <neb-patient-documents-controller
            id="${ELEMENTS.controllerDocuments.id}"
            class="content"
            .small="${this.layout === 'small'}"
            .medium="${this.layout === 'medium'}"
            .layout="${this.layout}"
            .patientId="${this.patient.id}"
            .totalDocuments="${this.documentCount}"
            visible
          ></neb-patient-documents-controller>
        `,
      },
      {
        exact: false,
        label: 'Billing',
        name: 'billing',
        permission: 'patients',
        path: '/billing/',
        resolver: tail => html`
          <neb-patient-tabs-billing-controller
            id="${ELEMENTS.controllerBilling.id}"
            class="content"
            .patient="${this.patient}"
            .guarantors="${this.__guarantors}"
            .route="${tail}"
            .layout="${this.layout}"
            .small="${this.layout === 'small'}"
            .onGuarantorChange="${this.__handlers.updateGuarantors}"
            .onUpdatePatient="${this.__handlers.updatePatient}"
          ></neb-patient-tabs-billing-controller>
        `,
      },
      {
        exact: false,
        label: this.getInsuranceLabel(),
        name: 'insurance',
        permission: 'patients',
        path: '/insurance/',
        resolver: tail => html`
          <neb-patient-insurance-controller
            id="${ELEMENTS.controllerInsurance.id}"
            class="content"
            .patientId="${this.patient.id}"
            .route="${tail}"
            .layout="${this.layout}"
            .patientDemographic="${
              {
                patientId: this.patient.id,
                lastName: this.patient.name.last,
                firstName: this.patient.name.first,
                middleName: this.patient.name.middle,
                suffix: this.patient.name.suffix,
                dateOfBirth: this.patient.dateOfBirth,
                sex: this.patient.sex,
              }
            }"
          ></neb-patient-insurance-controller>
        `,
      },
      {
        exact: false,
        label: 'Ledger',
        name: 'ledger',
        permission: 'billing',
        path: '/ledger/',
        resolver: tail => html`
          <neb-patient-ledger-controller
            id="${ELEMENTS.controllerLedger.id}"
            class="content ledger"
            .patient="${this.__ledgerPatient}"
            .route="${tail}"
            .layout="${this.layout}"
          ></neb-patient-ledger-controller>
        `,
      },
      {
        exact: false,
        label: 'Claims',
        name: 'claims',
        permission: 'billing',
        path: '/claims/',
        resolver: tail => html`
          <neb-ledger-claims-controller
            id="${ELEMENTS.ledgerClaimsController.id}"
            .layout="${this.layout}"
            .route="${tail}"
            .baseRoute="${`#/patients/${this.patient.id}/claims`}"
            .patientId="${this.patient ? this.patient.id : ''}"
          ></neb-ledger-claims-controller>
        `,
      },
      {
        exact: false,
        hideInMobile: true,
        label: this.getCaseLabel(),
        name: 'cases',
        permission: 'patients',
        path: '/cases/',
        resolver: () =>
          this.layout !== 'small'
            ? html`
                <neb-patient-cases-page
                  id="${ELEMENTS.casesPage.id}"
                  class="content"
                  .layout="${this.layout}"
                  .patientId="${this.patient.id}"
                  .patientName="${this.patient.name}"
                  .preferredProviderId="${this.patient.preferredProviderId}"
                  .onUpdateCaseCount="${this.__handlers.updateCaseCount}"
                ></neb-patient-cases-page>
              `
            : '',
      },
    ];

    const navItems = this.__getDisplayedNavItems(allNavItems);

    this.__setTabs(navItems);

    return navItems;
  }

  __buildFullRoute(sufix) {
    return `/patients/${this.patient.id}/${sufix}`;
  }

  __isRouteIgnored(route) {
    const routesToIgnore =
      this.layout === 'small'
        ? ROUTES_TO_IGNORE_MOBILE
        : ROUTES_TO_IGNORE_DESKTOP;
    return (
      !route ||
      !route.startsWith('/patients') ||
      routesToIgnore.some(toIgnore => route === this.__buildFullRoute(toIgnore))
    );
  }

  async __postLastVisitedLocation({ patientId, route, additionalInformation }) {
    const content = matchRouteSwitch(this.__navItems, route);

    if (!content) return;

    const lastLocation = locationToEnum('PATIENT', route);

    await saveLastLocation({
      patientId,
      lastLocation,
      additionalInformation,
    });
  }

  __setTabs(navItems) {
    this.__tabs = navItems.map(navItem => navItem.name);

    if (this.layout !== 'small' && !this.__selectedTab && this.__tabs.length) {
      this.__selectedTab = this.__tabs[0];
    }
  }

  __getDisplayedNavItems(navItems) {
    return this.__filterMobileNavItems(
      this.__filterPermittedNavItems(navItems),
    );
  }

  __filterPermittedNavItems(navItems) {
    if (this.__permissions) {
      const { superUser, permissions } = this.__permissions;

      if (superUser) return navItems;
      return [
        ...navItems.filter(n =>
          permissions.some(p => p.access && p.name === n.permission),
        ),
      ];
    }

    return [];
  }

  __filterMobileNavItems(navItems) {
    return this.layout === 'small'
      ? navItems.filter(item => !item.hideInMobile)
      : navItems;
  }

  getDocumentLabel() {
    return this.documentCount
      ? `Documents (${this.documentCount})`
      : 'Documents';
  }

  getCaseLabel() {
    return this.caseCount ? `Cases (${this.caseCount})` : 'Cases';
  }

  getInsuranceLabel() {
    return this.insuranceCount
      ? `Insurance (${this.insuranceCount})`
      : 'Insurance';
  }

  getSelectedIndex() {
    return this.__navItems.findIndex(item => item.name === this.__selectedTab);
  }

  update(changedProps) {
    if (changedProps.has('patient')) {
      this.__ledgerPatient = this.patient
        ? {
            id: this.patient.id,
            preferredName: this.patient.name.preferred,
            name: objToName(this.patient.name, HIDE_PREFERRED_OPTS),
          }
        : null;
    }

    if (
      changedProps.has('documentCount') ||
      changedProps.has('caseCount') ||
      changedProps.has('insuranceCount') ||
      changedProps.has('__permissions') ||
      (changedProps.has('layout') && this.layout !== 'small')
    ) {
      this.__navItems = this.genNavItems();
    }

    if (changedProps.has('layout') || changedProps.has('route')) {
      const segments = this.route.split('/');

      this.__selectedTab = '';

      if (this.__tabs.includes(segments[1])) {
        this.__selectedTab = segments[1];
      }

      if (
        this.layout !== 'small' &&
        !this.__selectedTab &&
        this.__tabs.length
      ) {
        const [firstTab] = this.__tabs;

        const route = joinPaths(getRoute(), `/${firstTab}/`);

        redirect(route);

        this.__selectedTab = firstTab;
      }
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('patient') && this.patient && this.patient.id) {
      this.__handlers.updateGuarantors();
    }
  }

  firstUpdated() {
    if (this.patient && this.patient.id && this.route) {
      this.__postLastVisitedLocation({
        patientId: this.patient.id,
        route: this.route.startsWith('/') ? this.route : `/${this.route}`,
        additionalInformation: null,
      });
    }
  }

  __renderBig() {
    return html`
      <neb-tabs-old
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selected="${this.__selectedTab}"
        .onChange="${this.__handlers.clickPrimary}"
        primary
      >
        ${
          this.__navItems.map(
            item => html`
              <neb-tab-old
                name="${item.name}"
                .compact="${this.layout === 'medium'}"
                >${item.label}</neb-tab-old
              >
            `,
          )
        }
      </neb-tabs-old>
    `;
  }

  __renderSmall() {
    const selectedIndex = this.getSelectedIndex();

    if (this.route.split('/')[1] === 'cases') {
      return html`
        <neb-unsupported-page
          id="${ELEMENTS.casesUnsupportedPage.id}"
          .layout="${this.layout}"
          icon="patients"
          location="Cases"
        >
        </neb-unsupported-page>
      `;
    }

    return selectedIndex === -1
      ? html`
          <neb-nav-list
            id="${ELEMENTS.navList.id}"
            class="list"
            .selectedIndex="${selectedIndex}"
            .items="${this.__navItems.map(item => item.label)}"
            .onSelect="${this.__handlers.selectNavItem}"
          ></neb-nav-list>
        `
      : '';
  }

  __renderContent() {
    const content = matchRouteSwitch(this.__navItems, this.route);

    if (!content) {
      if (this.layout === 'small') return '';

      return this.__renderNoAccessPage();
    }

    return html`
      ${content}
    `;
  }

  __renderNoAccessPage() {
    navigate('#/no-access');

    return html`
      <neb-no-access-page
        id="${ELEMENTS.noAccessPage.id}"
        class="no-access-page"
        .layout="${this.layout}"
        .pageExists="${false}"
      >
      </neb-no-access-page>
    `;
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @scroll="${this.__handlers.scroll}"
      >
        ${this.layout === 'small' ? this.__renderSmall() : this.__renderBig()}
        ${this.__renderContent()}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-patient-tabs-controller',
  NebPatientTabsController,
);
