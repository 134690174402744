import '../controls/neb-switch';
import '../controls/neb-tab-group';
import '../inputs/neb-select-search';
import '../inputs/neb-textarea';
import '../patients/neb-patient-card';

import { isRequired, isSsn } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import { formatPersonForPatientCard } from '../../../../neb-api-client/src/formatters/person';
import { BILL_TYPES } from '../../../../neb-api-client/src/mappers/patient-case-mapper';
import { getOrganizations } from '../../../../neb-api-client/src/organizations';
import { fetchOne } from '../../../../neb-api-client/src/patient-api-client';
import { putPatientInsurance } from '../../../../neb-api-client/src/patient-insurance-api-client';
import { PAYER_PLAN_VERSION_CLAIMS } from '../../../../neb-api-client/src/payer-plan-api-client';
import { getServiceTypes } from '../../../../neb-api-client/src/service-types';
import { PayerPlanQueryService } from '../../../../neb-api-client/src/services/payer-plan';
import { PersonService } from '../../../../neb-api-client/src/services/person';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_COLOR_GREY_1,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import Debouncer from '../../../../neb-utils/debouncer';
import {
  currencyToCents,
  formatDisplayProviders,
} from '../../../../neb-utils/formatters';
import {
  phone,
  currency,
  numberNoLeadingZeroAllowZero,
  ssn,
} from '../../../../neb-utils/masks';
import { createModel } from '../../../../neb-utils/patient';
import {
  createModelNewPatientInsurance,
  EMPTY_PAYER_PLAN,
  DATA_KEYS,
  INSURED_RELATIONSHIP,
  patientInsuranceToRaw,
  mapToPatientInsuranceModel,
  isWorkersComp,
  getIndexOrderForDefaultLevel,
  isMedicareSecondary,
  DEFAULT_LEVEL,
  INSURANCE_TYPE,
  POLICY_HOLDER_ITEMS,
  findNewPayerId,
  addLabelToPlan,
  getInsurancePlanName,
  removeFutureDates,
  isSelfSelected,
  shouldDisablePolicyHolderField,
  mapCoverageLevelValueSimplified,
  mapCoverageLevelDisplayValue,
  mapSelectedServiceTypesDisplay,
  getSelectedServiceTypes,
  getSelectedServiceType,
  createModelCopay,
  createModelCoinsurance,
  createModelDeductible,
  createModelOutOfPocket,
  createModelCoveredService,
  createModelNonCoveredService,
  COVERED_SERVICE_TYPE,
  QUANTITY_TYPES,
  TERMS,
  selectedServiceTypeDisplay,
  getAvailableServiceTypesForServices,
  COVERAGE_LEVEL,
} from '../../../../neb-utils/patientInsurance';
import {
  EMPTY_POLICY_HOLDER,
  personToPolicyHolder,
  rawPatientToPolicyHolder,
  convertPatientAddress,
  convertPhone,
  getPolicyHolderIdAttributes,
} from '../../../../neb-utils/policyHolder';
import * as selectors from '../../../../neb-utils/selectors';
import {
  isPhoneNumber,
  isZipCode,
  max,
} from '../../../../neb-utils/validators';
import { sortServiceTypes } from '../../utils/insurance-util';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';
import { POPOVER_POSITION } from '../neb-date-picker';
import {
  POPUP_CONFIRM_PAYER,
  POPUP_CONFIRM_PLAN_ID,
  POPUP_MSG_PAYER,
  POPUP_MSG_PLAN_ID,
  findMatchingMemberAndPayerPlan,
  findMatchingMemberAndGroup,
  getRequiredText,
  rowValidationNetworkFields,
} from '../patients/insurance/shared/neb-patient-insurance-validation-util';
import { formatCode } from '../patients/ledger/charges/neb-ledger-charges-util';
import { SEX } from '../patients/neb-patient-profile-data';

import NebForm, { ELEMENTS as BASE_ELEMENTS } from './neb-form';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  headerPlanNameText: {
    id: 'header-plan-name',
  },
  headerPlanIdText: {
    id: 'header-plan-id',
  },
  headerInsuredIdText: {
    id: 'header-insured-id',
  },
  closeButton: {
    id: 'close-button',
  },
  tabs: {
    id: 'tabs',
  },
  tabPolicy: {
    id: 'tab-policy',
    label: 'Policy',
  },
  tabCoverage: {
    id: 'tab-coverage',
    label: 'Coverage',
  },
  defaultLevelDropdown: {
    id: 'defaultLevel',
  },
  payerDropdown: {
    id: 'payerPlan',
  },
  addNewPayerButton: {
    id: 'add-new-payer',
  },
  planNameField: {
    id: `${DATA_KEYS.PlanInfo}-planName`,
  },
  typeField: {
    id: 'payerPlan-financialClass',
  },
  groupIdField: {
    id: `${DATA_KEYS.PlanInfo}-groupIdentifier`,
  },
  groupIdTooltip: {
    id: 'group-id-tooltip',
  },
  groupIdTooltipText: {
    id: 'group-id-tooltip-text',
  },
  insuranceTypeCodeDropdown: {
    id: 'insuranceTypeCode',
  },
  activeSwitch: {
    id: 'active',
  },
  startDateField: {
    id: `${DATA_KEYS.PlanInfo}-start`,
  },
  termDateField: {
    id: `${DATA_KEYS.PlanInfo}-end`,
  },
  startDateTooltip: {
    id: 'start-date-tooltip',
  },
  startDateTooltipText: {
    id: 'start-date-tooltip-text',
  },
  termDateTooltip: {
    id: 'term-date-tooltip',
  },
  termDateTooltipText: {
    id: 'term-date-tooltip-text',
  },
  resetDateField: {
    id: 'reset-date-field',
  },
  resetDateTooltip: {
    id: 'reset-date-tooltip',
  },
  resetDateTooltipText: {
    id: 'reset-tooltip-Text',
  },
  contactNameField: {
    id: `${DATA_KEYS.PlanInfo}-contactName`,
  },
  phoneField: {
    id: `${DATA_KEYS.PlanInfo}-phone`,
  },
  extensionField: {
    id: `${DATA_KEYS.PlanInfo}-extension`,
  },
  websiteField: {
    id: `${DATA_KEYS.PlanInfo}-website`,
  },
  personSearchField: {
    id: 'person-search-field',
  },
  policyHolderRelationDropdown: {
    id: 'insuredRelationship',
  },
  policyHolderInsuredNameField: {
    id: `${DATA_KEYS.PolicyHolder}-insuredName`,
  },
  policyHolderFirstNameField: {
    id: `${DATA_KEYS.PolicyHolder}-firstName`,
  },
  policyHolderMiddleNameField: {
    id: `${DATA_KEYS.PolicyHolder}-middleName`,
  },
  policyHolderLastNameField: {
    id: `${DATA_KEYS.PolicyHolder}-lastName`,
  },
  policyHolderDobField: {
    id: `${DATA_KEYS.PolicyHolder}-dateOfBirth`,
  },
  policyHolderAddressForm: {
    id: `${DATA_KEYS.PolicyHolder}-address`,
  },
  policyHolderPhoneField: {
    id: `${DATA_KEYS.PolicyHolder}-phone-number`,
  },
  sexDropdown: { id: `${DATA_KEYS.PolicyHolder}-sex` },
  suffixField: { id: `${DATA_KEYS.PolicyHolder}-suffix` },
  memberIdentifierField: {
    id: `${DATA_KEYS.PlanInfo}-memberIdentifier`,
  },
  memberIdentifierToolTip: {
    id: 'member-identifier-tooltip',
  },
  memberIdentifierToolTipText: {
    id: 'member-identifier-tooltip-text',
  },
  subscriberIdentifierField: {
    id: `${DATA_KEYS.PlanInfo}-subscriberIdentifier`,
  },
  subscriberIdentifierToolTip: {
    id: 'subscriber-identifier-tooltip',
  },
  subscriberIdentifierToolTipText: {
    id: 'subscriber-identifier-tooltip-text',
  },
  wcbCaseNumber: {
    id: `${DATA_KEYS.PlanInfo}-wcb-case-number`,
  },
  notesTextArea: {
    id: 'planInfo-notes',
  },
  editCoverageForm: {
    id: 'edit-coverage-form',
  },
  copayCoverageLevelDropdown: {
    id: 'copay-coverage-level-dropdown',
  },
  copayServiceTypesMultiselect: {
    id: 'copay-service-types-multiselect',
  },
  copayInNetworkField: {
    id: 'copay-in-network-field',
  },
  copayOutOfNetworkField: {
    id: 'copay-out-of-network-field',
  },
  coinsCoverageLevelDropdown: {
    id: 'coins-coverage-level-dropdown',
  },
  coinsServiceTypesMultiselect: {
    id: 'coins-service-types-multiselect',
  },
  coinsInNetworkField: {
    id: 'coins-in-network-field',
  },
  coinsOutOfNetworkField: {
    id: 'coins-out-of-network-field',
  },
  deductiblesCoverageLevelDropdown: {
    id: 'deductible-coverage-level-dropdown',
  },
  deductiblesServiceTypesMultiselect: {
    id: 'deductible-service-types-multiselect',
  },
  deductiblesInNetworkAmount: {
    id: 'deductible-in-network-amount',
  },
  deductiblesInNetworkRemaining: {
    id: 'deductible-in-network-remaining',
  },
  deductiblesInNetworkStart: {
    id: 'deductible-in-network-start',
  },
  deductiblesInNetworkMet: {
    id: 'deductible-in-network-met',
  },
  deductiblesOutOfNetworkAmount: {
    id: 'deductible-out-of-network-amount',
  },
  deductiblesOutOfNetworkRemaining: {
    id: 'deductible-out-of-network-remaining',
  },
  deductiblesOutOfNetworkStart: {
    id: 'deductible-out-of-network-start',
  },
  deductiblesOutOfNetworkMet: {
    id: 'deductible-out-of-network-met',
  },
  outOfPocketsCoverageLevelDropdown: {
    id: 'outOfPockets-coverage-level-dropdown',
  },
  outOfPocketsInNetworkAmount: {
    id: 'outOfPockets-in-network-amount',
  },
  outOfPocketsInNetworkRemaining: {
    id: 'outOfPockets-in-network-remaining',
  },
  outOfPocketsInNetworkStart: {
    id: 'outOfPockets-in-network-start',
  },
  outOfPocketsInNetworkMet: {
    id: 'outOfPockets-in-network-met',
  },
  outOfPocketsOutOfNetworkAmount: {
    id: 'outOfPockets-out-of-network-amount',
  },
  outOfPocketsOutOfNetworkRemaining: {
    id: 'outOfPockets-out-of-network-remaining',
  },
  outOfPocketsOutOfNetworkStart: {
    id: 'outOfPockets-out-of-network-start',
  },
  outOfPocketsOutOfNetworkMet: {
    id: 'outOfPockets-out-of-network-met',
  },
  coveredServicesServiceTypeDropdown: {
    id: 'coveredServices-service-type',
  },
  coveredServicesProcedureCodeText: {
    id: 'coveredServices-procedure-code',
  },
  coveredServicesPreAuthCheckbox: {
    id: 'coveredServices-pre-auth',
  },
  coveredServicesQuantityTypeDropdown: {
    id: 'coveredServices-quantity-type',
  },
  coveredServicesQuantityField: {
    id: 'coveredServices-quantity',
  },
  coveredServicesTermDropdown: {
    id: 'coveredServices-term',
  },
  coveredServicesRemainingQuantityField: {
    id: 'coveredServices-remainingQuantity',
  },
  coveredServicesAsOfDatePicker: {
    id: 'coveredServices-asOfDate',
  },
  coveredServicesInNetworkCopayAmountField: {
    id: 'coveredServices-inNetworkCopayAmount',
  },
  coveredServicesOutOfNetworkCopayAmountField: {
    id: 'coveredServices-outOfNetworkCopayAmount',
  },
  coveredServicesInNetworkCoinsuranceField: {
    id: 'coveredServices-inNetworkCoinsurancePercent',
  },
  coveredServicesOutOfNetworkCoinsuranceField: {
    id: 'coveredServices-outOfNetworkCoinsurancePercent',
  },
  nonCoveredServicesServiceTypeDropdown: {
    id: 'nonCoveredServices-service-type',
  },
  nonCoveredServicesProcedureCodeText: {
    id: 'nonCoveredServices-procedure-code',
  },
  copaysRemoveButton: {
    id: 'copay-remove-button',
  },
  copaysAddButton: {
    id: 'copay-add-button',
  },
  coinsurancesRemoveButton: { id: 'coins-remove-button' },
  coinsurancesAddButton: {
    id: 'coins-add-button',
  },
  deductiblesRemoveButton: { id: 'deductible-remove-button' },
  deductiblesAddButton: {
    id: 'deductible-add-button',
  },
  outOfPocketsRemoveButton: { id: 'outOfPockets-remove-button' },
  outOfPocketsAddButton: {
    id: 'outOfPockets-add-button',
  },
  coveredServicesRemoveButton: {
    id: 'coveredServices-remove-button',
  },
  coveredServicesAddButton: {
    id: 'coveredServices-add-button',
  },
  nonCoveredServicesRemoveButton: {
    id: 'nonCoveredServices-remove-button',
  },
  nonCoveredServicesAddButton: {
    id: 'nonCoveredServices-add-button',
  },
  copaysIsDefault: {
    id: 'copays-isDefault',
  },
  coinsurancesIsDefault: {
    id: 'coinsurances-isDefault',
  },
  deductiblesIsDefault: {
    id: 'deductibles-isDefault',
  },
  outOfPocketsIsDefault: {
    id: 'outOfPockets-isDefault',
  },
  visitLimitAsOf: {
    id: 'visit-limit-as-of',
  },
  renderedOutsidePractice: {
    id: 'rendered-outside-practice-textfield',
    label: 'Rendered Outside Practice',
  },
  maxVisits: {
    id: 'max-visits-textfield',
    label: 'Annual Visit Limit',
  },
  renderedPractice: {
    id: 'rendered-practice-textfield',
    label: 'Rendered at this Practice',
  },
  totalRemaining: {
    id: 'total-remaining-textfield',
    label: 'Total Remaining',
  },
  selectProvider: { id: 'select-provider', label: 'Provider' },
  insuredSSNField: {
    id: `${DATA_KEYS.PolicyHolder}-insuredSSN`,
    label: 'Insured SSN',
  },
  insuredSSNToolTip: { id: 'insured-ssn-tooltip' },
  insuredSSNToolTipText: { id: 'insured-ssn-tooltip-text' },
};

export const SAVE_SUCCESS_TEXT = 'Insurance saved.';
export const BILL_TYPE_CARE_PACKAGE = 'Default Bill Type set to Care Package.';
export const BILL_TYPE_SELF = 'Default Bill Type set to Self Pay.';
export const CASE_OVERRIDE =
  "Case billing override disabled. This can be enabled from the patient's Billing tab.";
export const SAVE_ERROR_TEXT = 'An error occurred when saving the Insurance.';
export const NO_RESULTS_FOUND = 'No results found';

const QUANTITY_TYPE_ITEMS = [
  '',
  QUANTITY_TYPES.Units,
  QUANTITY_TYPES.Visits,
  QUANTITY_TYPES.Episodes,
  QUANTITY_TYPES.DollarAmount,
];
const TERM_ITEMS = [
  '',
  TERMS.CalendarYear,
  TERMS.ServiceYear,
  TERMS.Month,
  TERMS.Day,
];

class NebFormPatientInsuranceEdit extends NebForm {
  static get properties() {
    return {
      __patient: Object,
      __payerPlans: Array,
      __searchResults: Array,
      __payerPlanItems: Array,
      __selectedPayer: Object,
      __selectedTab: String,
      __selectedPayerPlan: Object,
      __serviceTypes: Array,
      __serviceTypeToAdd: String,
      __coverageTabViewed: Boolean,
      __formattedProviders: Array,
      __selectedProvider: Object,
      patientInsurance: Object,
      patientInsurances: Array,
      patientId: String,
      providers: Array,
      hasAddOnCTVerify: Boolean,
      hideCloseIcon: Boolean,
    };
  }

  static createModel() {
    return {
      ...createModelNewPatientInsurance(),
    };
  }

  createSelectors() {
    return {
      children: {
        payerPlanId: [isRequired()],
        insuranceTypeCode: {
          validators: [
            {
              error: 'Required',
              validate: v =>
                !isMedicareSecondary(
                  this.state.payerPlan.financialClass,
                  this.state.defaultLevel,
                ) || v.trim(),
            },
          ],
        },
        payerPlan: {
          clipPristine: true,
          unsafe: true,
        },
        planInfo: {
          children: {
            memberIdentifier: [isRequired()],
            phone: [isPhoneNumber],
          },
        },
        policyHolder: {
          children: {
            insuredName: {
              validators: [
                {
                  error: 'Required',
                  validate: v =>
                    !isWorkersComp(this.state.payerPlan) || v.trim(),
                },
              ],
            },
            firstName: {
              validators: [
                {
                  error: 'Required',
                  validate: v =>
                    isWorkersComp(this.state.payerPlan) || v.trim(),
                },
              ],
            },
            lastName: {
              validators: [
                {
                  error: 'Required',
                  validate: v =>
                    isWorkersComp(this.state.payerPlan) || v.trim(),
                },
              ],
            },
            dateOfBirth: {
              unsafe: true,
            },
            address: {
              children: {
                zipcode: {
                  validators: [isZipCode],
                },
              },
            },
            phones: {
              children: {
                $: {
                  children: {
                    number: [isPhoneNumber],
                  },
                },
              },
            },
            insuredSSN: {
              validators: [isSsn()],
            },
          },
        },
        copays: {
          createItem: () => createModelCopay(false),
          children: {
            $: {
              children: {
                coverageLevel: {
                  clipPristine: true,
                },
                serviceTypes: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.copays[idx].coverageLevel.code === 'NA' ||
                          v.length
                        );
                      },
                    },
                  ],
                  createItem: () => ({
                    serviceTypeId: '',
                  }),
                },
                inNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.copays[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          state.copays[idx].outOfNetworkAmount !== ''
                        );
                      },
                    },
                  ],
                },
                outOfNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.copays[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          state.copays[idx].inNetworkAmount !== ''
                        );
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        coinsurances: {
          createItem: () => createModelCoinsurance(false),
          children: {
            $: {
              children: {
                coverageLevel: {
                  clipPristine: true,
                },
                serviceTypes: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.coinsurances[idx].coverageLevel.code === 'NA' ||
                          v.length
                        );
                      },
                    },
                  ],
                  createItem: () => ({
                    serviceTypeId: '',
                  }),
                },
                inNetworkPercent: {
                  validators: [
                    {
                      error: 'May not exceed 100',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.coinsurances[idx].coverageLevel.code === 'NA' ||
                          v <= 100
                        );
                      },
                    },
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.coinsurances[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          state.coinsurances[idx].outOfNetworkPercent !== ''
                        );
                      },
                    },
                  ],
                },
                outOfNetworkPercent: {
                  validators: [
                    {
                      error: 'May not exceed 100',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.coinsurances[idx].coverageLevel.code === 'NA' ||
                          v <= 100
                        );
                      },
                    },
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.coinsurances[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          state.coinsurances[idx].inNetworkPercent !== ''
                        );
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        deductibles: {
          createItem: () => ({
            ...createModelDeductible(false),
            inNetworkMet: false,
            outOfNetworkMet: false,
          }),
          children: {
            $: {
              children: {
                coverageLevel: {
                  clipPristine: true,
                },
                serviceTypes: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.deductibles[idx].coverageLevel.code === 'NA' ||
                          v.length
                        );
                      },
                    },
                  ],
                  createItem: () => ({
                    serviceTypeId: '',
                  }),
                },
                inNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.deductibles[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          rowValidationNetworkFields(
                            v,
                            state.deductibles[idx].inNetworkRemaining,
                            state.deductibles[idx].inNetworkStart,
                            state.deductibles[idx].outOfNetworkAmount,
                          )
                        );
                      },
                    },
                  ],
                },
                inNetworkRemaining: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.deductibles[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          (v === '') ===
                            (state.deductibles[idx].inNetworkStart === null)
                        );
                      },
                    },
                    {
                      error: 'Can’t exceed Amount Value',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.deductibles[idx].coverageLevel.code === 'NA' ||
                          currencyToCents(v) <=
                            currencyToCents(
                              state.deductibles[idx].inNetworkAmount,
                            )
                        );
                      },
                    },
                  ],
                },
                inNetworkStart: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.deductibles[idx].coverageLevel.code === 'NA' ||
                          v !== null ||
                          (v === null) ===
                            (state.deductibles[idx].inNetworkRemaining === '')
                        );
                      },
                    },
                    {
                      error: 'mm/dd/yyyy',
                      validate: (_, keyPath) => {
                        const idx = keyPath[1];
                        const element = `${
                          ELEMENTS.deductiblesInNetworkStart.id
                        }-${idx}`;

                        if (this.shadowRoot.getElementById(element)) {
                          const dateErrors = this.shadowRoot.getElementById(
                            element,
                          ).invalidTextInput;

                          return !dateErrors;
                        }
                        return true;
                      },
                    },
                  ],
                },
                outOfNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.deductibles[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          rowValidationNetworkFields(
                            v,
                            state.deductibles[idx].outOfNetworkRemaining,
                            state.deductibles[idx].outOfNetworkStart,
                            state.deductibles[idx].inNetworkAmount,
                          )
                        );
                      },
                    },
                  ],
                },
                outOfNetworkRemaining: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.deductibles[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          (v === '') ===
                            (state.deductibles[idx].outOfNetworkStart === null)
                        );
                      },
                    },
                    {
                      error: 'Can’t exceed Amount Value',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.deductibles[idx].coverageLevel.code === 'NA' ||
                          currencyToCents(v) <=
                            currencyToCents(
                              state.deductibles[idx].outOfNetworkAmount,
                            )
                        );
                      },
                    },
                  ],
                },
                outOfNetworkStart: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.deductibles[idx].coverageLevel.code === 'NA' ||
                          v !== null ||
                          (v === null) ===
                            (state.deductibles[idx].outOfNetworkRemaining ===
                              '')
                        );
                      },
                    },
                    {
                      error: 'mm/dd/yyyy',
                      validate: (_, keyPath) => {
                        const idx = keyPath[1];
                        const element = `${
                          ELEMENTS.deductiblesOutOfNetworkStart.id
                        }-${idx}`;

                        if (this.shadowRoot.getElementById(element)) {
                          const dateErrors = this.shadowRoot.getElementById(
                            element,
                          ).invalidTextInput;

                          return !dateErrors;
                        }
                        return true;
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        outOfPockets: {
          createItem: () => ({
            ...createModelOutOfPocket(false),
            inNetworkMet: false,
            outOfNetworkMet: false,
          }),
          children: {
            $: {
              children: {
                coverageLevel: {
                  clipPristine: true,
                },
                inNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.outOfPockets[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          rowValidationNetworkFields(
                            v,
                            state.outOfPockets[idx].inNetworkRemaining,
                            state.outOfPockets[idx].inNetworkStart,
                            state.outOfPockets[idx].outOfNetworkAmount,
                          )
                        );
                      },
                    },
                  ],
                },
                inNetworkRemaining: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.outOfPockets[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          (v === '') ===
                            (state.outOfPockets[idx].inNetworkStart === null)
                        );
                      },
                    },
                    {
                      error: 'Can’t exceed Amount Value',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.outOfPockets[idx].coverageLevel.code === 'NA' ||
                          currencyToCents(v) <=
                            currencyToCents(
                              state.outOfPockets[idx].inNetworkAmount,
                            )
                        );
                      },
                    },
                  ],
                },
                inNetworkStart: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.outOfPockets[idx].coverageLevel.code === 'NA' ||
                          v !== null ||
                          (v === null) ===
                            (state.outOfPockets[idx].inNetworkRemaining === '')
                        );
                      },
                    },
                    {
                      error: 'mm/dd/yyyy',
                      validate: (_, keyPath) => {
                        const idx = keyPath[1];
                        const element = `${
                          ELEMENTS.outOfPocketsInNetworkStart.id
                        }-${idx}`;

                        if (this.shadowRoot.getElementById(element)) {
                          const dateErrors = this.shadowRoot.getElementById(
                            element,
                          ).invalidTextInput;

                          return !dateErrors;
                        }
                        return true;
                      },
                    },
                  ],
                },
                outOfNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.outOfPockets[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          rowValidationNetworkFields(
                            v,
                            state.outOfPockets[idx].outOfNetworkRemaining,
                            state.outOfPockets[idx].outOfNetworkStart,
                            state.outOfPockets[idx].inNetworkAmount,
                          )
                        );
                      },
                    },
                  ],
                },
                outOfNetworkRemaining: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.outOfPockets[idx].coverageLevel.code === 'NA' ||
                          v !== '' ||
                          (v === '') ===
                            (state.outOfPockets[idx].outOfNetworkStart === null)
                        );
                      },
                    },
                    {
                      error: 'Can’t exceed Amount Value',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.outOfPockets[idx].coverageLevel.code === 'NA' ||
                          currencyToCents(v) <=
                            currencyToCents(
                              state.outOfPockets[idx].outOfNetworkAmount,
                            )
                        );
                      },
                    },
                  ],
                },
                outOfNetworkStart: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          state.outOfPockets[idx].coverageLevel.code === 'NA' ||
                          v !== null ||
                          (v === null) ===
                            (state.outOfPockets[idx].outOfNetworkRemaining ===
                              '')
                        );
                      },
                    },
                    {
                      error: 'mm/dd/yyyy',
                      validate: (_, keyPath) => {
                        const idx = keyPath[1];
                        const element = `${
                          ELEMENTS.outOfPocketsOutOfNetworkStart.id
                        }-${idx}`;

                        if (this.shadowRoot.getElementById(element)) {
                          const dateErrors = this.shadowRoot.getElementById(
                            element,
                          ).invalidTextInput;

                          return !dateErrors;
                        }
                        return true;
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        coveredServices: {
          createItem: () => ({
            ...createModelCoveredService(this.__serviceTypeToAdd),
            charge: null,
            ...(this.__serviceTypeToAdd === COVERED_SERVICE_TYPE.ServiceType
              ? { serviceType: { id: '' } }
              : {}),
          }),
          children: {
            $: {
              children: {
                serviceTypeId: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          v !== null ||
                          state.coveredServices[idx].type ===
                            COVERED_SERVICE_TYPE.ProcedureCode
                        );
                      },
                    },
                  ],
                },
                quantityType: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          v !== '' ||
                          state.coveredServices[idx].term === ''
                        );
                      },
                    },
                  ],
                },
                quantity: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          v !== '' ||
                          (state.coveredServices[idx].quantityType === '' &&
                            state.coveredServices[idx].term === '')
                        );
                      },
                    },
                  ],
                },
                term: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          v !== '' ||
                          state.coveredServices[idx].quantityType === ''
                        );
                      },
                    },
                  ],
                },
                remainingQuantity: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          v !== '' ||
                          state.coveredServices[idx].asOfDate === null
                        );
                      },
                    },
                    {
                      error: 'Can’t exceed Quantity Value',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          currencyToCents(v) <=
                            currencyToCents(state.coveredServices[idx].quantity)
                        );
                      },
                    },
                  ],
                },
                asOfDate: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          v !== null ||
                          state.coveredServices[idx].remainingQuantity === ''
                        );
                      },
                    },
                  ],
                },
                inNetworkCoinsurancePercent: {
                  validators: [
                    {
                      error: 'May not exceed 100',
                      validate: v => v <= 100,
                    },
                  ],
                },
                outOfNetworkCoinsurancePercent: {
                  validators: [
                    {
                      error: 'May not exceed 100',
                      validate: v => v <= 100,
                    },
                  ],
                },
              },
            },
          },
        },
        nonCoveredServices: {
          createItem: () => ({
            ...createModelNonCoveredService(this.__serviceTypeToAdd),
            charge: null,
            ...(this.__serviceTypeToAdd === COVERED_SERVICE_TYPE.ServiceType
              ? { serviceType: { id: '' } }
              : {}),
          }),
          children: {
            $: {
              children: {
                serviceTypeId: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !this.__coverageTabViewed ||
                          v !== null ||
                          state.nonCoveredServices[idx].type ===
                            COVERED_SERVICE_TYPE.ProcedureCode
                        );
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        patientInsuranceVisitLimit: {
          children: {
            maxVisits: {
              validators: [
                {
                  error: 'Must be a numeric value',
                  validate: v => Number.isInteger(Number(v)),
                },
                max(999, true, 'Must be at max 999'),
                {
                  error: 'Required',
                  validate: (v, _, state) =>
                    !this.__coverageTabViewed ||
                    v !== '' ||
                    (!state.patientInsuranceVisitLimit.asOf &&
                      !state.patientInsuranceVisitLimit
                        .visitsRenderedOutOfOffice),
                },
              ],
            },
            visitsRenderedOutOfOffice: {
              validators: [
                {
                  error: 'Must be a numeric value',
                  validate: v => Number.isInteger(Number(v)),
                },
                max(999, true, 'Must be at max 999'),
                {
                  error: 'Required',

                  validate: (v, _, state) =>
                    !this.__coverageTabViewed ||
                    v !== '' ||
                    !state.patientInsuranceVisitLimit.asOf,
                },
              ],
            },
            asOf: {
              validators: [
                {
                  error: 'Required',
                  validate: (v, _, state) => {
                    const {
                      visitsRenderedOutOfOffice,
                    } = state.patientInsuranceVisitLimit;

                    if (visitsRenderedOutOfOffice) {
                      return !!visitsRenderedOutOfOffice && !!v;
                    }

                    return true;
                  },
                },
                {
                  error: 'mm/dd/yyyy',
                  validate: () => {
                    if (
                      this.shadowRoot.getElementById(ELEMENTS.visitLimitAsOf.id)
                    ) {
                      const dateErrors = this.shadowRoot.getElementById(
                        ELEMENTS.visitLimitAsOf.id,
                      ).invalidTextInput;
                      return !dateErrors;
                    }
                    return true;
                  },
                },
              ],
            },
          },
        },
        providerId: selectors.select([], selectors.ITEM_EMPTY, {
          validateRaw: true,
          validators: [],
        }),
      },
    };
  }

  initState() {
    super.initState();

    this.patientId = '';
    this.patientInsurance = { ...createModelNewPatientInsurance() };
    this.patientInsurances = [];
    this.organizations = [];
    this.providers = [];
    this.hasAddOnCTVerify = false;
    this.hideCloseIcon = false;
    this.__navItems = [];
    this.__selectedTab = ELEMENTS.tabPolicy.id;
    this.__processing = false;
    this.__payerPlans = [];
    this.__payerPlanCreated = false;
    this.__searchResults = [];
    this.__patient = createModel();
    this.__selfPolicyHolder = null;
    this.__selectedEntity = null;
    this.__selectedPayerPlan = null;
    this.__payerPlanItems = [];
    this.__payerSearchValue = '';
    this.__serviceTypes = [];
    this.__serviceTypeToAdd = COVERED_SERVICE_TYPE.ServiceType;
    this.__coverageTabViewed = false;
    this.__selectedProvider = '';
    this.__formattedProviders = [];

    this.__searchDebouncer = new Debouncer(() => {
      this.__doDebounce();
    });

    this.__setupPayerPlanQueryService();

    this.__personService = new PersonService(data => {
      this.__searchResults = [...data, ...this.organizations];
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeResetDate: ({ value }) => {
        this.formService.apply(
          'planInfo.resetDate',
          value !== null
            ? moment
                .utc(value)
                .endOf('day')
                .toISOString()
            : value,
        );

        this.__updateResetDateSelectable();
      },
      selectTab: async selectedTab => {
        if (this.__selectedTab !== selectedTab) {
          this.__validateServiceTypes();

          if (
            !this.formService.validate() ||
            !(await this.__validateNoDuplicates())
          ) {
            return;
          }

          this.__pruneEmpty();

          this.__coverageTabViewed = true;
          this.__selectedTab = selectedTab;
        }
      },
      defaultChecked: e => {
        const cst = e.name.split('.')[0];

        this.state[cst].forEach((_, idx) =>
          this.formService.apply(`${cst}.${idx}.isDefault`, false),
        );

        this.formService.apply(e.name, true);
      },
      changeStartDate: ({ value }) => {
        this.formService.apply(
          'planInfo.start',
          value !== null
            ? parseDate(value)
                .startOf('day')
                .toISOString()
            : value,
        );

        if (value && !this.state.planInfo.resetDate) {
          let resetDate = null;

          const today = parseDate();
          const startDate = parseDate(value);

          if (startDate.isAfter(today)) {
            resetDate = startDate
              .add(1, 'year')
              .startOf('year')
              .toISOString();
          } else {
            resetDate = today
              .add(1, 'year')
              .startOf('year')
              .toISOString();
          }

          this.formService.apply('planInfo.resetDate', resetDate);
        }

        this.__updateStartDateSelectable();
      },
      changeEndDate: ({ value }) => {
        this.formService.apply(
          'planInfo.end',
          value !== null
            ? parseDate(value)
                .endOf('day')
                .toISOString()
            : value,
        );

        this.__updateEndDateSelectable();
      },
      addPayerPlan: async () => {
        const shouldUpdate = await openOverlay(OVERLAY_KEYS.PAYER_PLAN);

        if (shouldUpdate) {
          this.__payerPlanCreated = true;
          this.__getPayerPlans();

          this.formService.apply('insuranceTypeCode', '');
        }
      },
      changeInsuredRelationship: ({ value: insuredRelationship }) => {
        if (insuredRelationship !== this.state.insuredRelationship) {
          if (insuredRelationship === INSURED_RELATIONSHIP.Self) {
            this.__selectedEntity = null;

            const { policyHolderId } = getPolicyHolderIdAttributes(
              this.state.policyHolder,
            );

            this.__setPolicyHolder({
              ...this.__selfPolicyHolder,
              id: policyHolderId,
            });
          } else if (
            this.state.insuredRelationship === INSURED_RELATIONSHIP.Self
          ) {
            this.__setPolicyHolderEmpty();
          }

          this.formService.apply('insuredRelationship', insuredRelationship);
        }
      },
      changeDefaultLevel: e => {
        if (e.value === this.state.defaultLevel) return;

        const indexOrder = getIndexOrderForDefaultLevel(e.value);

        if (
          isMedicareSecondary(
            this.state.payerPlan.financialClass,
            this.state.defaultLevel,
          )
        ) {
          this.formService.apply('defaultLevel', e.value);
          this.formService.apply('indexOrder', indexOrder);
          this.formService.apply('insuranceTypeCode', '');
        } else {
          this.formService.apply('defaultLevel', e.value);
          this.formService.apply('indexOrder', indexOrder);
        }
      },
      selectEntity: ({ value: entity }) => {
        if (!entity) {
          this.__selectedEntity = null;
          this.__setPolicyHolderEmpty();
        } else if (entity !== NO_RESULTS_FOUND) {
          this.__selectedEntity = entity;

          this.__setPolicyHolder(
            personToPolicyHolder(entity, {
              ...getPolicyHolderIdAttributes(this.state.policyHolder),
              address: entity.isOrganization ? entity.address : null,
              currentInsuredName: this.state.policyHolder.insuredName,
            }),
          );
        }
      },
      searchPerson: ({ value }) => {
        this.__searchValue = value;
        this.__showResults = Boolean(this.__searchValue);
        this.__searchDebouncer.debounce();
      },
      changePayer: e => {
        if (e.value) return this.__applyPayerPlan(e.value.item.id);

        return this.__clearPayerPlan();
      },
      searchPayer: e => {
        this.__payerSearchValue = e.value;

        this.__payerPlanQueryService.update({
          search: e.value,
          hideInactive: true,
        });
      },
      addCst: ({ name }) => this.formService.addItem(name),
      removeCst: (name, idx) => {
        if (this.state[name][idx].isDefault) {
          this.__autoSelectDefault(name, idx);
        }
        this.formService.removeItem(name, idx);
      },
      addCoveredService: async () => {
        let selectedNonCoveredCharges = [];

        if (this.state.nonCoveredServices.length !== 0) {
          selectedNonCoveredCharges = this.__findSelectedCharges(
            this.state.nonCoveredServices,
          );
        }

        const selectedCoveredCharges = this.__findSelectedCharges(
          this.state.coveredServices,
        );

        let results = await openOverlay(
          OVERLAY_KEYS.PATIENT_INSURANCE_SERVICES,
          {
            coveredType: true,
            selectedCharges: selectedCoveredCharges,
            selectedSecondary: selectedNonCoveredCharges,
          },
        );

        if (results === 'serviceType') {
          this.__serviceTypeToAdd = COVERED_SERVICE_TYPE.ServiceType;
          this.formService.addItem('coveredServices');
        } else if (results && results.length) {
          results = results.filter(result => {
            const idx = selectedCoveredCharges.findIndex(
              prevSelected => prevSelected.id === result.id,
            );

            return idx === -1;
          });

          this.__serviceTypeToAdd = COVERED_SERVICE_TYPE.ProcedureCode;

          results.forEach(procedureCode => {
            this.formService.addItem('coveredServices');
            const lastIdx = this.state.coveredServices.length - 1;

            this.formService.apply(
              `coveredServices.${lastIdx}.chargeId`,
              procedureCode.id,
            );

            this.formService.apply(`coveredServices.${lastIdx}.charge`, {
              ...procedureCode,
            });
          });
        }
      },
      addNonCoveredService: async () => {
        let selectedCoveredCharges = [];

        if (this.state.coveredServices.length !== 0) {
          selectedCoveredCharges = this.__findSelectedCharges(
            this.state.coveredServices,
          );
        }

        const selectedNonCoveredCharges = this.__findSelectedCharges(
          this.state.nonCoveredServices,
        );

        let results = await openOverlay(
          OVERLAY_KEYS.PATIENT_INSURANCE_SERVICES,
          {
            coveredType: false,
            selectedCharges: selectedNonCoveredCharges,
            selectedSecondary: selectedCoveredCharges,
          },
        );

        if (results === 'serviceType') {
          this.__serviceTypeToAdd = COVERED_SERVICE_TYPE.ServiceType;
          this.formService.addItem('nonCoveredServices');
        } else if (results && results.length) {
          results = results.filter(result => {
            const idx = selectedNonCoveredCharges.findIndex(
              prevSelected => prevSelected.id === result.id,
            );

            return idx === -1;
          });

          this.__serviceTypeToAdd = COVERED_SERVICE_TYPE.ProcedureCode;

          results.forEach(procedureCode => {
            this.formService.addItem('nonCoveredServices');
            const lastIdx = this.state.nonCoveredServices.length - 1;

            this.formService.apply(
              `nonCoveredServices.${lastIdx}.chargeId`,
              procedureCode.id,
            );

            this.formService.apply(`nonCoveredServices.${lastIdx}.charge`, {
              ...procedureCode,
            });
          });
        }
      },
      changeCopayCoverageLevel: e => {
        const cstIdx = e.name.split('.')[1];

        if (
          e.event === 'select' &&
          e.value.code !== this.state.copays[cstIdx].coverageLevel.code
        ) {
          if (e.value.code === 'NA') {
            this.__removeServiceTypes('copays', cstIdx);
          } else if (this.state.copays[cstIdx].coverageLevel.code === 'NA') {
            this.__selectAllServiceTypes('copays', cstIdx);
          }

          this.formService.apply(e.name, {
            code: e.value.code,
            description: e.value.description,
          });

          if (this.state.copays[cstIdx].coverageLevel.code === 'NA') {
            this.formService.apply(`copays.${cstIdx}.inNetworkAmount`, '');
            this.formService.apply(`copays.${cstIdx}.outOfNetworkAmount`, '');

            if (this.state.copays[cstIdx].isDefault) {
              this.__autoSelectDefault('copays', cstIdx);
            }
            this.formService.apply(`copays.${cstIdx}.isDefault`, false);
          } else {
            this.__makeDefaultIfNoneCurrently('copays', cstIdx);
          }
        }
      },
      changeCoinsCoverageLevel: e => {
        const cstIdx = e.name.split('.')[1];

        if (
          e.event === 'select' &&
          e.value.code !== this.state.coinsurances[cstIdx].coverageLevel.code
        ) {
          if (e.value.code === 'NA') {
            this.__removeServiceTypes('coinsurances', cstIdx);
          } else if (
            this.state.coinsurances[cstIdx].coverageLevel.code === 'NA'
          ) {
            this.__selectAllServiceTypes('coinsurances', cstIdx);
          }

          this.formService.apply(e.name, {
            code: e.value.code,
            description: e.value.description,
          });

          if (this.state.coinsurances[cstIdx].coverageLevel.code === 'NA') {
            this.formService.apply(
              `coinsurances.${cstIdx}.inNetworkPercent`,
              '',
            );

            this.formService.apply(
              `coinsurances.${cstIdx}.outOfNetworkPercent`,
              '',
            );

            if (this.state.coinsurances[cstIdx].isDefault) {
              this.__autoSelectDefault('coinsurances', cstIdx);
            }
            this.formService.apply(`coinsurances.${cstIdx}.isDefault`, false);
          } else {
            this.__makeDefaultIfNoneCurrently('coinsurances', cstIdx);
          }
        }
      },
      changeDeductiblesCoverageLevel: e => {
        const cstIdx = e.name.split('.')[1];

        if (
          e.event === 'select' &&
          e.value.code !== this.state.deductibles[cstIdx].coverageLevel.code
        ) {
          if (e.value.code === 'NA') {
            this.__removeServiceTypes('deductibles', cstIdx);
          } else if (
            this.state.deductibles[cstIdx].coverageLevel.code === 'NA'
          ) {
            this.__selectAllServiceTypes('deductibles', cstIdx);
          }

          this.formService.apply(e.name, {
            code: e.value.code,
            description: e.value.description,
          });

          if (this.state.deductibles[cstIdx].coverageLevel.code === 'NA') {
            this.__clearNetworkKeys('deductibles', cstIdx);

            if (this.state.deductibles[cstIdx].isDefault) {
              this.__autoSelectDefault('deductibles', cstIdx);
            }
            this.formService.apply(`deductibles.${cstIdx}.isDefault`, false);
          } else {
            this.__makeDefaultIfNoneCurrently('deductibles', cstIdx);
          }
        }
      },
      changeOutOfPocketsCoverageLevel: e => {
        const cstIdx = e.name.split('.')[1];

        if (
          e.event === 'select' &&
          this.state.outOfPockets[cstIdx].coverageLevel.code !== e.value.code
        ) {
          this.formService.apply(e.name, {
            code: e.value.code,
            description: e.value.description,
          });

          if (this.state.outOfPockets[cstIdx].coverageLevel.code === 'NA') {
            this.__clearNetworkKeys('outOfPockets', cstIdx);

            if (this.state.outOfPockets[cstIdx].isDefault) {
              this.__autoSelectDefault('outOfPockets', cstIdx);
            }
            this.formService.apply(`outOfPockets.${cstIdx}.isDefault`, false);
          } else {
            this.__makeDefaultIfNoneCurrently('outOfPockets', cstIdx);
          }
        }
      },
      multiSelectChange: e => {
        const cst = e.name.split('.')[0];
        const cstIdx = e.name.split('.')[1];

        if (e.event === 'select' || e.event === 'selectAll') {
          this.__removeServiceTypes(cst, cstIdx);

          e.value.forEach((types, idx) => {
            this.formService.addItem(`${cst}.${cstIdx}.serviceTypes`);
            this.formService.apply(
              `${cst}.${cstIdx}.serviceTypes.${idx}.serviceTypeId`,
              types.id,
            );
          });
        }
        this.formService.validateKey([cst, cstIdx, 'serviceTypes'], true);
      },
      copayTextChange: e => {
        const copayField = e.name.split('.')[2];
        const cstIdx = e.name.split('.')[1];

        if (this.state.copays[cstIdx][copayField] !== e.value) {
          this.formService.apply(e.name, e.value);
          this.__validateCopayNetworkFields(cstIdx);
        }
      },
      copayNetworkTextClear: name => {
        const cstIdx = name.split('.')[1];

        this.formService.apply(name, '');
        this.__validateCopayNetworkFields(cstIdx);
      },
      coinsTextChange: e => {
        const coinsField = e.name.split('.')[2];
        const cstIdx = e.name.split('.')[1];

        if (this.state.coinsurances[cstIdx][coinsField] !== e.value) {
          this.formService.apply(e.name, e.value);
          this.formService.validateKey(
            ['coinsurances', cstIdx, 'inNetworkPercent'],
            true,
          );

          this.formService.validateKey(
            ['coinsurances', cstIdx, 'outOfNetworkPercent'],
            true,
          );
        }
      },
      textClearNetworkFields: name => {
        const cst = name.split('.')[0];
        const cstIdx = name.split('.')[1];
        const field = name.split('.')[2];

        this.formService.apply(`${cst}.${cstIdx}.${field}`, '');

        this.__validateNetworkKeys(cst, cstIdx);
      },
      networkMetChecked: ({ name, value }) => {
        const cst = name.split('.')[0];
        const cstIdx = name.split('.')[1];
        const field = name.split('.')[2];

        if (field === 'inNetworkMet') {
          this.formService.apply(
            `${cst}.${cstIdx}.inNetworkRemaining`,
            '$0.00',
          );
        } else {
          this.formService.apply(
            `${cst}.${cstIdx}.outOfNetworkRemaining`,
            '$0.00',
          );
        }

        this.formService.apply(`${cst}.${cstIdx}.${field}`, value);
      },
      networkChange: e => {
        const cst = e.name.split('.')[0];
        const cstIdx = e.name.split('.')[1];
        const field = e.name.split('.')[2];

        if (this.state[cst][cstIdx][field] !== e.value) {
          this.formService.apply(e.name, e.value);

          this.__validateNetworkKeys(cst, cstIdx);
        }
      },
      visitLimitChange: ({ name, value }) => {
        this.formService.apply(name, value);
        this.__validateVisitLimits();
      },
      servicesServiceTypeChange: e => {
        const cst = e.name.split('.')[0];
        const cstIdx = e.name.split('.')[1];

        if (e.event === 'select') {
          this.formService.apply(`${cst}.${cstIdx}.serviceTypeId`, e.value.id);
          this.formService.apply(`${cst}.${cstIdx}.serviceType.id`, e.value.id);
        }
      },
      servicesQuantityTypeChange: e => {
        const cstIdx = e.name.split('.')[1];

        if (
          e.event === 'select' &&
          e.value !== this.state.coveredServices[cstIdx].quantityType
        ) {
          this.formService.apply(
            `coveredServices.${cstIdx}.quantityType`,
            e.value,
          );

          this.formService.apply(`coveredServices.${cstIdx}.quantity`, '');
          this.formService.apply(
            `coveredServices.${cstIdx}.remainingQuantity`,
            '',
          );

          this.formService.apply(`coveredServices.${cstIdx}.asOfDate`, null);
          this.formService.apply(`coveredServices.${cstIdx}.term`, '');
          this.formService.validateKey(
            ['coveredServices', cstIdx, 'quantityType'],
            true,
          );
        }
      },
      textClear: e => {
        this.formService.apply(e, '');
      },
      cancel: () => {
        if (!this.__processing) this.onCancel();
      },
      // eslint-disable-next-line complexity
      save: async () => {
        if (this.__processing) return;

        if (!this.__validateStartEndResetDateFields()) return;

        const planName = getInsurancePlanName(this.__payerPlans, this.state);

        if (planName) {
          this.formService.apply('planInfo.planName', planName);
        }

        this.__validateServiceTypes();
        const canSave =
          this.formService.validate() && (await this.__validateNoDuplicates());

        if (!canSave) return;

        this.__processing = true;

        this.__pruneEmpty();

        this.formService.apply('providerId', this.state.providerId.data.id);

        const putBody = patientInsuranceToRaw(
          { ...this.state },
          isMedicareSecondary(
            this.state.payerPlan.financialClass,
            this.state.defaultLevel,
          ),
        );

        try {
          const {
            insurance,
            newBillType,
            caseOverrideUpdated,
          } = await putPatientInsurance(putBody);

          const updatedInsurance = mapToPatientInsuranceModel(insurance, false);

          store.dispatch(openSuccess(SAVE_SUCCESS_TEXT));

          switch (newBillType) {
            case BILL_TYPES.CARE_PACKAGE:
              store.dispatch(openSuccess(BILL_TYPE_CARE_PACKAGE));
              break;

            case BILL_TYPES.SELF:
              store.dispatch(openSuccess(BILL_TYPE_SELF));
              break;

            default:
          }

          if (caseOverrideUpdated) {
            store.dispatch(openSuccess(CASE_OVERRIDE));
          }

          this.onSave(updatedInsurance);
        } catch (e) {
          console.log(e);
          store.dispatch(openError(SAVE_ERROR_TEXT));

          this.__processing = false;
        }
      },
      changeProvider: e => {
        if (e.value?.data) {
          this.__selectedProvider = e.value;
          return this.formService.apply(e.name, e.value);
        }

        this.__selectedProvider = selectors.ITEM_EMPTY;

        return this.formService.apply(e.name, selectors.ITEM_EMPTY);
      },
    };

    this.__updateStartDateSelectable();
    this.__updateEndDateSelectable();
    this.__updateResetDateSelectable();
  }

  __validateVisitLimits() {
    const parent = 'patientInsuranceVisitLimit';

    this.formService.validateKey([parent, 'maxVisits'], true);
    this.formService.validateKey([parent, 'visitsRenderedOutOfOffice'], true);
    this.formService.validateKey([parent, 'asOf'], true);
  }

  __makeDefaultIfNoneCurrently(cst, idx) {
    const foundDefault = this.state[cst].filter(f => f.isDefault).length > 0;

    if (!foundDefault) this.formService.apply(`${cst}.${idx}.isDefault`, true);
  }

  __autoSelectDefault(cst, idx) {
    for (let i = 0; i < this.state[cst].length; i++) {
      if (this.state[cst][i].coverageLevel.code !== 'NA' && i !== idx) {
        this.formService.apply(`${cst}.${i}.isDefault`, true);
        i = this.state[cst].length;
      }
    }
  }

  __validateCopayNetworkFields(idx) {
    this.formService.validateKey(['copays', idx, 'inNetworkAmount'], true);
    this.formService.validateKey(['copays', idx, 'outOfNetworkAmount'], true);
  }

  __removeServiceTypes(cst, cstIdx) {
    for (
      let count = this.state[cst][cstIdx].serviceTypes.length;
      count > 0;
      count--
    ) {
      this.formService.removeItem(`${cst}.${cstIdx}.serviceTypes`, count - 1);
    }
  }

  __selectAllServiceTypes(cst, cstIdx) {
    this.__removeServiceTypes(cst, cstIdx);

    this.__serviceTypes.forEach((types, idx) => {
      this.formService.addItem(`${cst}.${cstIdx}.serviceTypes`);
      this.formService.apply(
        `${cst}.${cstIdx}.serviceTypes.${idx}.serviceTypeId`,
        types.id,
      );
    });

    this.formService.validateKey([cst, cstIdx, 'serviceTypes'], true);
  }

  __clearNetworkKeys(cst, cstIdx) {
    this.formService.apply(`${cst}.${cstIdx}.inNetworkAmount`, '');
    this.formService.apply(`${cst}.${cstIdx}.inNetworkRemaining`, '');
    this.formService.apply(`${cst}.${cstIdx}.inNetworkStart`, null);
    this.formService.apply(`${cst}.${cstIdx}.inNetworkMet`, false);
    this.formService.apply(`${cst}.${cstIdx}.outOfNetworkAmount`, '');
    this.formService.apply(`${cst}.${cstIdx}.outOfNetworkRemaining`, '');
    this.formService.apply(`${cst}.${cstIdx}.outOfNetworkStart`, null);
    this.formService.apply(`${cst}.${cstIdx}.outOfNetworkMet`, false);
  }

  __validateNetworkKeys(cst, cstIdx) {
    this.formService.validateKey([cst, cstIdx, 'inNetworkAmount'], true);
    this.formService.validateKey([cst, cstIdx, 'inNetworkRemaining'], true);
    this.formService.validateKey([cst, cstIdx, 'inNetworkStart'], true);
    this.formService.validateKey([cst, cstIdx, 'outOfNetworkAmount'], true);
    this.formService.validateKey([cst, cstIdx, 'outOfNetworkRemaining'], true);
    this.formService.validateKey([cst, cstIdx, 'outOfNetworkStart'], true);
  }

  __findSelectedCharges(services) {
    const selectedCharges = [];

    services.forEach(service => {
      if (service.chargeId != null) {
        selectedCharges.push({
          id: service.chargeId,
        });
      }
    });

    return selectedCharges;
  }

  __validateStartEndResetDateFields() {
    const elementIds = ['resetDateField', 'startDateField', 'termDateField'];

    const hasError = elementIds.some(elementId => {
      const element = this.shadowRoot.getElementById(ELEMENTS[elementId].id);
      return element && element.invalidTextInput;
    });

    return !hasError;
  }

  __updateStartDateSelectable() {
    // eslint-disable-next-line complexity
    this.handlers.startDateSelectable = date => {
      const { end, resetDate } = this.state.planInfo;

      if (!end && !resetDate) {
        return true;
      }

      date = parseDate(date);
      let isEndBefore = false;
      let isResetBefore = false;
      let isResetDateTheSame = false;
      let isEndDateTheSame = false;

      if (end && resetDate) {
        isEndBefore = date.isBefore(end);
        isResetBefore = date.isBefore(resetDate);
        isResetDateTheSame = date
          .startOf('day')
          .isSame(parseDate(resetDate).startOf('day'));

        isEndDateTheSame = date
          .startOf('day')
          .isSame(parseDate(end).startOf('day'));

        if (isResetBefore < isEndBefore) {
          return isResetDateTheSame ? false : isResetBefore;
        }

        if (isResetBefore > isEndBefore) {
          return isEndDateTheSame ? false : isEndBefore;
        }
      }

      if (resetDate) {
        isResetBefore = date.isBefore(resetDate);
        isResetDateTheSame = date
          .startOf('day')
          .isSame(parseDate(resetDate).startOf('day'));

        return isResetDateTheSame ? false : isResetBefore;
      }

      if (end) {
        isEndBefore = date.isBefore(end);
        isEndDateTheSame = date
          .startOf('day')
          .isSame(parseDate(end).startOf('day'));

        return isEndDateTheSame ? false : isEndBefore;
      }

      return false;
    };
  }

  __updateEndDateSelectable() {
    this.handlers.endDateSelectable = date => {
      if (!this.state.planInfo.start) {
        return true;
      }

      const dayIsSame = parseDate(date)
        .startOf('day')
        .isSame(parseDate(this.state.planInfo.start).startOf('day'));

      if (dayIsSame) return false;

      if (date.isAfter(this.state.planInfo.start)) {
        return true;
      }
      return false;
    };
  }

  __updateResetDateSelectable() {
    this.handlers.resetDateSelectable = date => {
      if (!this.state.planInfo.start) {
        return true;
      }

      const dayIsSame = parseDate(date)
        .startOf('day')
        .isSame(parseDate(this.state.planInfo.start).startOf('day'));

      if (dayIsSame) return false;

      return date.isAfter(this.state.planInfo.start);
    };
  }

  __filterNAEntries(cst) {
    for (let idx = this.state[cst].length - 1; idx > -1; idx--) {
      if (
        this.state[cst].length > 1 &&
        this.state[cst][idx].coverageLevel.code ===
          COVERAGE_LEVEL.NotApplicable.code
      ) {
        this.formService.removeItem(cst, idx);
      }
    }
  }

  __pruneEmpty() {
    this.__filterNAEntries('copays');
    this.__filterNAEntries('coinsurances');
    this.__filterNAEntries('deductibles');
    this.__filterNAEntries('outOfPockets');
  }

  __validateServiceTypes() {
    this.state.copays.forEach((_, idx) =>
      this.formService.validateKey(['copays', idx, 'serviceTypes'], true),
    );

    this.state.coinsurances.forEach((_, idx) =>
      this.formService.validateKey(['coinsurances', idx, 'serviceTypes'], true),
    );

    this.state.deductibles.forEach((_, idx) =>
      this.formService.validateKey(['deductibles', idx, 'serviceTypes'], true),
    );
  }

  async __validateNoDuplicates() {
    let canProceed = true;

    const otherPatientInsurances = this.patientInsurances.filter(
      i => i.id !== this.model.id,
    );

    if (
      findMatchingMemberAndPayerPlan(
        otherPatientInsurances,
        this.state,
        this.patientInsurance,
      )
    ) {
      canProceed = await openPopup(POPUP_CONFIRM_PAYER, POPUP_MSG_PAYER);

      if (!canProceed) return canProceed;
    }

    if (
      findMatchingMemberAndGroup(
        otherPatientInsurances,
        this.state,
        this.patientInsurance,
      )
    ) {
      canProceed = await openPopup(POPUP_CONFIRM_PLAN_ID, POPUP_MSG_PLAN_ID);
    }

    return canProceed;
  }

  __setPolicyHolderAddress(
    id,
    parentId,
    address1,
    address2,
    city,
    state,
    zipcode,
  ) {
    this.formService.apply('policyHolder.address.id', id);
    this.formService.apply('policyHolder.address.parentId', parentId);
    this.formService.apply('policyHolder.address.address1', address1);
    this.formService.apply('policyHolder.address.address2', address2);
    this.formService.apply('policyHolder.address.city', city);
    this.formService.apply('policyHolder.address.state', state);
    this.formService.apply('policyHolder.address.zipcode', zipcode);
  }

  __setPolicyHolderPhones(id, parentId, type, phoneNumber) {
    this.formService.apply('policyHolder.phones.0.id', id);
    this.formService.apply('policyHolder.phones.0.parentId', parentId);
    this.formService.apply('policyHolder.phones.0.type', type);
    this.formService.apply('policyHolder.phones.0.number', phoneNumber);
  }

  __setPolicyHolder(policyHolder) {
    this.formService.apply('policyHolder.id', policyHolder.id);
    this.formService.apply(
      'policyHolder.dateOfBirth',
      policyHolder.dateOfBirth,
    );

    this.formService.apply(
      'policyHolder.insuredName',
      policyHolder.insuredName,
    );

    this.formService.apply('policyHolder.firstName', policyHolder.firstName);
    this.formService.apply('policyHolder.middleName', policyHolder.middleName);
    this.formService.apply('policyHolder.lastName', policyHolder.lastName);
    this.formService.apply('policyHolder.suffix', policyHolder.suffix);
    this.formService.apply('policyHolder.sex', policyHolder.sex);
    this.formService.apply('policyHolder.insuredSSN', policyHolder.insuredSSN);

    this.__setPolicyHolderAddress(
      policyHolder.address.id,
      policyHolder.address.parentId,
      policyHolder.address.address1,
      policyHolder.address.address2,
      policyHolder.address.city,
      policyHolder.address.state,
      policyHolder.address.zipcode,
    );

    if (policyHolder.phones.length) {
      this.__setPolicyHolderPhones(
        policyHolder.phones[0].id,
        policyHolder.phones[0].parentId,
        policyHolder.phones[0].type,
        policyHolder.phones[0].number,
      );
    } else this.__setPolicyHolderPhones(null, null, 'Mobile', '');
  }

  __setPolicyHolderEmpty() {
    const {
      policyHolderId,
      policyHolderAddressId,
      policyHolderPhoneId,
    } = getPolicyHolderIdAttributes(this.state.policyHolder);

    const phoneAttrs = {
      parentId: policyHolderId,
      id: policyHolderPhoneId,
    };
    const addressAttrs = {
      parentId: policyHolderId,
      id: policyHolderAddressId,
    };

    this.__setPolicyHolder({
      ...EMPTY_POLICY_HOLDER,
      id: policyHolderId,
      address: convertPatientAddress(null, addressAttrs),
      phones: [convertPhone(null, phoneAttrs)],
    });
  }

  __setupPayerPlanQueryService() {
    const version = PAYER_PLAN_VERSION_CLAIMS;

    this.__payerPlanQueryService = new PayerPlanQueryService(({ model }) => {
      let newId;

      if (this.__payerPlanCreated) {
        newId = findNewPayerId(this.__payerPlans, model);
      }

      this.__payerPlans = model;
      this.__payerPlanItems = this.__payerPlans.map(item => ({
        item,
        label: `(${item.alias}) ${item.payerName}`,
      }));

      if (this.__payerPlanCreated && newId) {
        this.__applyPayerPlan(newId);
      }

      this.__payerPlanCreated = false;
    }, version);
  }

  __formatEntityName(entity) {
    if (entity && entity.name) {
      return entity.name;
    }
    return entity ? `${entity.firstName} ${entity.lastName}` : '';
  }

  __getPayerPlans() {
    this.__payerPlanQueryService.update({
      hideInactive: true,
    });
  }

  __applyPayerPlan(id) {
    const selectedPayerPlan = this.__payerPlans.find(p => p.id === id) || {
      ...EMPTY_PAYER_PLAN,
    };

    this.__selectedPayerPlan = selectedPayerPlan;
    this.formService.apply('payerPlanId', id);
    this.formService.apply('payerPlan', selectedPayerPlan);
    this.formService.apply('planInfo.phone', selectedPayerPlan.phone);
    this.formService.apply('planInfo.extension', selectedPayerPlan.extension);
    this.formService.apply('planInfo.website', selectedPayerPlan.website);
    this.formService.apply(
      'planInfo.contactName',
      selectedPayerPlan.contactName,
    );
  }

  __clearPayerPlan() {
    this.__selectedPayerPlan = null;

    this.formService.apply('payerPlanId', '');
    this.formService.apply('payerPlan', this.model.payerPlan);
    this.formService.apply('planInfo.phone', '');
    this.formService.apply('planInfo.extension', '');
    this.formService.apply('planInfo.website', '');
    this.formService.apply('planInfo.contactName', '');
    this.formService.apply('insuranceTypeCode', '');
  }

  async __doDebounce() {
    const organizations = await getOrganizations();

    const modifiedOrganizations = organizations.map(org => ({
      ...org,
      isOrganization: true,
    }));

    this.organizations = modifiedOrganizations;
    await this.__personService.search(this.__searchValue, this.patientId);
  }

  async __initPatient() {
    this.__patient = await fetchOne(this.patientId, true, true);
    const idAttrs = getPolicyHolderIdAttributes(
      this.patientInsurance.policyHolder,
    );

    this.__selfPolicyHolder = rawPatientToPolicyHolder(this.__patient, idAttrs);

    const phoneAttrs = {
      parentId: idAttrs.policyHolderId,
      id: idAttrs.policyHolderPhoneId,
    };

    this.__selfPolicyHolder.phones = [convertPhone(null, phoneAttrs)];
  }

  __setInitialModel() {
    let model = this.formService.build();

    model = {
      ...this.patientInsurance,
      planInfo: {
        ...this.patientInsurance.planInfo,
        resetDate: this.patientInsurance.planInfo.resetDate
          ? moment
              .utc(this.patientInsurance.planInfo.resetDate)
              .endOf('day')
              .toISOString()
          : null,
      },
      payerPlan: { ...this.patientInsurance.payerPlan },
      policyHolder: { ...this.patientInsurance.policyHolder },
      copays: [...this.patientInsurance.copays],
      coinsurances: [...this.patientInsurance.coinsurances],
      deductibles: [...this.patientInsurance.deductibles],
      outOfPockets: [...this.patientInsurance.outOfPockets],
      coveredServices: [...this.patientInsurance.coveredServices],
      nonCoveredServices: [...this.patientInsurance.nonCoveredServices],
    };

    model.deductibles.forEach((deductible, idx) => {
      if (deductible.inNetworkRemaining === '$0.00') {
        model.deductibles[idx].inNetworkMet = true;
      } else model.deductibles[idx].inNetworkMet = false;

      if (deductible.outOfNetworkRemaining === '$0.00') {
        model.deductibles[idx].outOfNetworkMet = true;
      } else model.deductibles[idx].outOfNetworkMet = false;
    });

    model.outOfPockets.forEach((outOfPocket, idx) => {
      if (outOfPocket.inNetworkRemaining === '$0.00') {
        model.outOfPockets[idx].inNetworkMet = true;
      } else model.outOfPockets[idx].inNetworkMet = false;

      if (outOfPocket.outOfNetworkRemaining === '$0.00') {
        model.outOfPockets[idx].outOfNetworkMet = true;
      } else model.outOfPockets[idx].outOfNetworkMet = false;
    });

    delete model.coverageDates;
    delete model.policyHolderName;
    delete model.policyHolderNameLong;

    this.__formattedProviders = formatDisplayProviders(this.providers);
    this.__selectedProvider = this.__formattedProviders.find(
      p => p.data.id === model.providerId,
    );

    this.formService.refresh(model);

    this.__selectedPayerPlan = this.patientInsurance.payerPlan;
  }

  async firstUpdated() {
    this.__getPayerPlans();

    await this.__initPatient();

    this.__serviceTypes = sortServiceTypes(
      await getServiceTypes({
        hideInactive: true,
      }),
    );

    this.__setInitialModel();

    this.__sideLoading = false;
    this.__reloadPromise = null;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .close-icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .action-bar {
          position: absolute;
          bottom: 0;
          width: 100%;
          z-index: 3;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        .tab-container {
          display: flex;
          min-height: 0;
          flex-flow: column nowrap;
          flex: 1 0 0;
          width: 100%;
          height: 100%;
          overflow-y: auto;
          padding-bottom: 100px;
        }

        .header-container {
          padding: ${CSS_SPACING};
          display: flex;
        }

        .header-plan-name {
          font-size: 16px;
          margin-top: 0px;
          min-width: 60px;
          max-width: 375px;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 50px;
        }

        .header-column {
          padding-left: 50px;
          max-width: 200px;
          display: flex;
          flex-direction: column;
        }

        .header-column:first-of-type {
          padding-left: 0;
        }

        .header-text {
          min-height: ${CSS_SPACING};
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .spacer {
          flex: 1 0 0;
        }

        .flex-one {
          flex: 1;
        }

        .flex-half {
          flex: 0.5;
        }

        .flex-small {
          flex: 0.1;
        }

        .col-fields {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-auto-rows: minmax(min-content, max-content);
          grid-template-columns: 1fr 1fr 1fr;
          flex: 1 0 0;
        }

        .col-fields-two {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-auto-rows: minmax(min-content, max-content);
          grid-template-columns: 1fr 1fr;
          flex: 1 0 0;
        }

        .no-padding {
          padding: 0;
        }

        .column-one {
          grid-column: 1 / 2;
        }

        .column-two {
          grid-column: 2 / 2;
        }

        .column-one-two {
          grid-column: 1 / 3;
        }

        .column-two-three {
          grid-column: 2 / 4;
        }

        .column-one-three {
          grid-column: 1 / 4;
        }

        .title-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 5px ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_1};
        }

        .title {
          padding-bottom: 4px;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .description {
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .content-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 5px;
          display: flex;
          flex-direction: column;
        }

        .plan-id-container {
          flex: 1;
          display: flex;
        }

        .row {
          display: flex;
          padding-bottom: ${CSS_SPACING_ROW};
        }

        .row-item-padding {
          padding-right: ${CSS_SPACING};
        }

        .search {
          z-index: 2;
        }

        .add-button {
          padding-top: 25px;
        }

        .width-hundred {
          width: 100%;
        }

        .tooltip {
          padding-left: 10px;
          padding-top: 24px;
        }

        .combo-field {
          display: flex;
        }

        .align-baseline {
          align-items: baseline;
        }

        .switch {
          padding-top: 4px;
        }

        .phone-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: ${CSS_SPACING};
          grid-column: 1 / 4;
        }

        .cst-container {
          display: flex;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .cst-line-container {
          padding: 16px 16px 0;
        }

        .cst-line {
          border-bottom: 1px solid ${CSS_COLOR_GREY_1};
        }

        .add-cst-button {
          padding: ${CSS_SPACING};
          width: fit-content;
        }

        .percent {
          padding: 25px 0px 0px 10px;
        }

        .notes-container {
          padding: 10px ${CSS_SPACING} ${CSS_SPACING};
          display: flex;
          flex-direction: column;
        }

        .notes {
          height: 135px;
        }

        .remove-icon {
          cursor: pointer;
          width: 28px;
          height: 28px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin: ${CSS_SPACING} 0 0 ${CSS_SPACING};
        }

        .network-labels {
          padding-left: 10px;
          margin-top: 16px;
          margin-right: 20px;
        }

        .procedure-code {
          padding-top: 4px;
        }

        .preauth-checkbox {
          padding-top: 24px;
        }

        .rendered-practice,
        .total-remaining {
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }

  __genNavItems() {
    return [
      {
        id: ELEMENTS.tabPolicy.id,
        label: ELEMENTS.tabPolicy.label,
        resolver: () => this.__renderPolicy(),
      },
      {
        id: ELEMENTS.tabCoverage.id,
        label: ELEMENTS.tabCoverage.label,
        resolver: () => this.__renderCoverage(),
      },
    ];
  }

  __isDefaultCheckboxDisabled(cst, idx) {
    const filteredCst = this.state[cst].filter(
      f => f.coverageLevel.code !== 'NA',
    );

    return (
      filteredCst.length < 2 || this.state[cst][idx].coverageLevel.code === 'NA'
    );
  }

  __renderTitle() {
    return html`
      <h2 id="${ELEMENTS.headerPlanNameText.id}" class="header-plan-name">
        Update Insurance - ${this.patientInsurance.planInfo.planName}
      </h2>
    `;
  }

  __renderHeader() {
    return html`
      ${this.__renderTitle()}

      <div class="header-column">
        <div class="header-text bold">Group ID</div>
        <div id="${ELEMENTS.headerPlanIdText.id}" class="header-text">
          ${this.patientInsurance.planInfo.groupIdentifier}
        </div>
      </div>

      <div class="header-column">
        <div class="header-text bold">Insured ID</div>
        <div id="${ELEMENTS.headerInsuredIdText.id}" class="header-text">
          ${this.patientInsurance.planInfo.memberIdentifier}
        </div>
      </div>

      <div class="spacer"></div>
      ${
        this.hideCloseIcon
          ? ''
          : html`
              <neb-icon
                id="${ELEMENTS.closeButton.id}"
                class="close-icon"
                icon="neb:close"
                @click="${this.handlers.cancel}"
              ></neb-icon>
            `
      }
    `;
  }

  __renderTabs() {
    this.__navItems = this.__genNavItems();

    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  __renderTabContent() {
    const item = this.__navItems.find(({ id }) => id === this.__selectedTab);

    return item.resolver();
  }

  __renderPatientCardItem(model) {
    const patientModel = formatPersonForPatientCard(model);

    return html`
      <neb-patient-card .model="${patientModel}" hidePhoto></neb-patient-card>
    `;
  }

  __renderAddressForm() {
    return html`
      <neb-address
        id="${ELEMENTS.policyHolderAddressForm.id}"
        name="policyHolder.address"
        class="column-one-three"
        .model="${this.state.policyHolder.address}"
        .errors="${this.errors.policyHolder.address}"
        .onChange="${this.handlers.change}"
      ></neb-address>
    `;
  }

  __renderWcbCaseNumber() {
    const showWcbCaseNumber =
      this.__selectedPayerPlan?.wcbCaseNumberOverride || false;

    return showWcbCaseNumber
      ? html`
          <div class="row">
            <neb-textfield
              id="${ELEMENTS.wcbCaseNumber.id}"
              class="flex-half row-item-padding"
              name="planInfo.wcbCaseNumber"
              label="WCB Case Number"
              helper=" "
              .value="${this.state.planInfo.wcbCaseNumber}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>
          </div>
        `
      : '';
  }

  __renderPlanInformation() {
    return html`
      <div class="title-container">
        <div class="title">Plan Information</div>
        <div class="description">
          Enter the plan information; determine whether this plan should default
          as the primary, secondary or other insurance.
        </div>
      </div>

      <div class="content-container">
        <div class="row">
          <neb-select
            id="${ELEMENTS.defaultLevelDropdown.id}"
            name="defaultLevel"
            class="flex-one row-item-padding"
            label="Set As"
            .items="${Object.values(DEFAULT_LEVEL)}"
            .value="${this.state.defaultLevel}"
            .onChange="${this.handlers.changeDefaultLevel}"
            ?disabled="${!this.state.active}"
          ></neb-select>

          <neb-select-search
            id="${ELEMENTS.payerDropdown.id}"
            name="payerPlanId"
            class="flex-one row-item-padding search"
            emptyMessage="${NO_RESULTS_FOUND}"
            label="Payer"
            helper="Required"
            .error="${this.errors.payerPlanId}"
            .search="${this.__payerSearchValue}"
            .items="${this.__payerPlanItems}"
            .value="${addLabelToPlan(this.__selectedPayerPlan)}"
            .onChange="${this.handlers.changePayer}"
            .onSearch="${this.handlers.searchPayer}"
            ?disabled="${this.state.hasAssociatedLineItems}"
            showSearch
          ></neb-select-search>

          <div class="combo-field flex-one align-baseline">
            <neb-button-action
              id="${ELEMENTS.addNewPayerButton.id}"
              class="add-button"
              label="Add New Payer"
              .onClick="${this.handlers.addPayerPlan}"
              ?disabled="${this.state.hasAssociatedLineItems}"
            ></neb-button-action>

            <neb-tooltip class="tooltip flex-small" defaultAnchor="left">
              <div slot="tooltip">
                The Payer field cannot be edited if this insurance plan has been
                associated to a charge.
              </div>
            </neb-tooltip>
          </div>
        </div>

        <div class="row">
          <neb-textfield
            id="${ELEMENTS.planNameField.id}"
            class="flex-one row-item-padding"
            name="planInfo.planName"
            label="Plan Name"
            helper=" "
            .value="${this.state.planInfo.planName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <div class="plan-id-container">
            <neb-textfield
              id="${ELEMENTS.groupIdField.id}"
              class="flex-one"
              name="planInfo.groupIdentifier"
              label="Group ID"
              helper=" "
              .value="${this.state.planInfo.groupIdentifier}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <neb-tooltip
              id="${ELEMENTS.groupIdTooltip.id}"
              class="tooltip"
              defaultAnchor="left"
            >
              <div id="${ELEMENTS.groupIdTooltipText.id}" slot="tooltip">
                Enter the ID for the plan this is NOT the policy number or
                member ID.
              </div>
            </neb-tooltip>
          </div>
        </div>

        ${this.__renderWcbCaseNumber()}

        <div class="row">
          <neb-textfield
            id="${ELEMENTS.typeField.id}"
            class="flex-half row-item-padding"
            name="payerPlan.financialClass"
            label="Type"
            helper=" "
            .value="${this.state.payerPlan.financialClass}"
            ?disabled="${true}"
          ></neb-textfield>

          <neb-select
            id="${ELEMENTS.insuranceTypeCodeDropdown.id}"
            class="flex-one"
            name="insuranceTypeCode"
            label="Medicare Type Code"
            helper="${
              getRequiredText(
                isMedicareSecondary(
                  this.state.payerPlan.financialClass,
                  this.state.defaultLevel,
                ),
              )
            }"
            .items="${
              Object.values(INSURANCE_TYPE).map(
                insurance => insurance.description,
              )
            }"
            .error="${this.errors.insuranceTypeCode}"
            .value="${this.state.insuranceTypeCode}"
            .onChange="${this.handlers.change}"
            ?disabled="${
              !isMedicareSecondary(
                this.state.payerPlan.financialClass,
                this.state.defaultLevel,
              )
            }"
            wrapText
          ></neb-select>
        </div>

        <neb-switch
          id="${ELEMENTS.activeSwitch.id}"
          class="switch"
          name="active"
          label="Active"
          .onChange="${this.handlers.change}"
          ?on="${this.state.active}"
        ></neb-switch>
      </div>
    `;
  }

  __renderResetDateField() {
    return html`
      <div class="combo-field">
        <neb-date-picker
          id="${ELEMENTS.resetDateField.id}"
          name="planInfo.resetDate"
          class="width-hundred"
          label="Reset Date"
          placeholder="Reset Date"
          manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .isDateSelectable="${this.handlers.resetDateSelectable}"
          .onChange="${this.handlers.changeResetDate}"
          .selectedDate="${
            this.state.planInfo.resetDate
              ? moment.utc(this.state.planInfo.resetDate).endOf('day')
              : null
          }"
          momentFlag
        ></neb-date-picker>

        <neb-tooltip
          id="${ELEMENTS.resetDateTooltip.id}"
          class="tooltip"
          defaultAnchor="right"
        >
          <div id="${ELEMENTS.resetDateTooltipText.id}" slot="tooltip">
            Enter the reset date for this plan. This value can be used to
            determine when the deductible, max out pocket and annual visit
            limits are reset.
          </div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderWebsiteAndProvider() {
    if (
      this.hasAddOnCTVerify &&
      this.__selectedPayerPlan?.realTimeEligibilityEnabled
    ) {
      return html`
        <div class="content-container">
          <div class="row">
            <neb-textfield
              id="${ELEMENTS.websiteField.id}"
              class="flex-one row-item-padding"
              name="planInfo.website"
              label="Website"
              helper=" "
              maxLength="50"
              .value="${this.state.planInfo.website}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <neb-select
              id="${ELEMENTS.selectProvider.id}"
              name="providerId"
              class="flex-one"
              label="${ELEMENTS.selectProvider.label}"
              .items="${this.__formattedProviders}"
              .value="${this.__selectedProvider}"
              .onChange="${this.handlers.changeProvider}"
            ></neb-select>
          </div>
        </div>
      `;
    }

    return html`
      <div class="col-fields">
        <neb-textfield
          id="${ELEMENTS.websiteField.id}"
          class="column-one-two"
          name="planInfo.website"
          label="Website"
          helper=" "
          maxLength="50"
          .value="${this.state.planInfo.website}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
      </div>
    `;
  }

  __renderEligibility() {
    return html`
      <div class="title-container">
        <div class="title">Eligibility</div>
        <div class="description">
          Enter eligibility information to keep track of patient coverage dates.
        </div>
      </div>

      <div class="col-fields">
        <div class="combo-field">
          <neb-date-picker
            id="${ELEMENTS.startDateField.id}"
            name="planInfo.start"
            class="width-hundred"
            label="Start Date"
            placeholder="Start Date"
            manualPopoverPosition="${POPOVER_POSITION.CENTER}"
            .onChange="${this.handlers.changeStartDate}"
            .isDateSelectable="${this.handlers.startDateSelectable}"
            .selectedDate="${
              this.state.planInfo.start
                ? parseDate(this.state.planInfo.start).startOf('day')
                : null
            }"
            momentFlag
          ></neb-date-picker>

          <neb-tooltip
            id="${ELEMENTS.startDateTooltip.id}"
            class="tooltip"
            defaultAnchor="right"
          >
            <div id="${ELEMENTS.startDateTooltipText.id}" slot="tooltip">
              Enter date coverage terms start for this coverage period. This
              value can be used to determine copay and deductible collection.
            </div>
          </neb-tooltip>
        </div>

        <div class="combo-field">
          <neb-date-picker
            id="${ELEMENTS.termDateField.id}"
            name="planInfo.end"
            class="width-hundred"
            label="Term Date"
            placeholder="Term Date"
            manualPopoverPosition="${POPOVER_POSITION.CENTER}"
            .onChange="${this.handlers.changeEndDate}"
            .isDateSelectable="${this.handlers.endDateSelectable}"
            .selectedDate="${
              this.state.planInfo.end
                ? parseDate(this.state.planInfo.end).endOf('day')
                : null
            }"
            momentFlag
          ></neb-date-picker>

          <neb-tooltip
            id="${ELEMENTS.termDateTooltip.id}"
            class="tooltip"
            defaultAnchor="right"
          >
            <div id="${ELEMENTS.termDateTooltipText.id}" slot="tooltip">
              Enter date coverage terms end for this coverage period. This value
              can be used to determine copay and deductible collection.
            </div>
          </neb-tooltip>
        </div>
        ${this.__renderResetDateField()}

        <neb-textfield
          id="${ELEMENTS.contactNameField.id}"
          class="column-one"
          name="planInfo.contactName"
          label="Contact Name"
          helper=" "
          maxLength="50"
          .value="${this.state.planInfo.contactName}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.phoneField.id}"
          name="planInfo.phone"
          label="Phone Number"
          helper=" "
          maxLength="14"
          .mask="${phone}"
          .value="${this.state.planInfo.phone}"
          .error="${this.errors.planInfo.phone}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.extensionField.id}"
          name="planInfo.extension"
          label="Extension"
          helper=" "
          inputMode="numeric"
          maxLength="9"
          .value="${this.state.planInfo.extension}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
      </div>

      ${this.__renderWebsiteAndProvider()}
    `;
  }

  __renderPolicyHolder() {
    return html`
      <div class="title-container">
        <div class="title">Policyholder</div>
        <div class="description">
          Enter the policyholder's information. If the policyholder is a
          patient, conduct a search and select the correct patient or guarantor
          to import their contact details. Ensure you correctly identify this
          patient's relationship to the policyholder.
        </div>
      </div>

      <div class="col-fields">
        <neb-select
          id="${ELEMENTS.policyHolderRelationDropdown.id}"
          class="column-one"
          name="insuredRelationship"
          label="Policyholder Relation to Patient"
          helper=" "
          .items="${POLICY_HOLDER_ITEMS}"
          .error="${this.errors.insuredRelationship}"
          .value="${this.state.insuredRelationship}"
          .onChange="${this.handlers.changeInsuredRelationship}"
        ></neb-select>

        <neb-select-search
          id="${ELEMENTS.personSearchField.id}"
          class="column-two-three"
          emptyMessage="${NO_RESULTS_FOUND}"
          label="Search for an existing patient or guarantor"
          .items="${this.__searchResults}"
          .value="${this.__formatEntityName(this.__selectedEntity)}"
          .itemHeight="${84}"
          .onChange="${this.handlers.selectEntity}"
          .onSearch="${this.handlers.searchPerson}"
          .onRenderItem="${this.__renderPatientCardItem}"
          .showMenu="${this.__showResults}"
          ?disabled="${isSelfSelected(this.state.insuredRelationship)}"
          showSearch
        ></neb-select-search>

        ${
          isWorkersComp(this.state.payerPlan)
            ? html`
                <neb-textfield
                  id="${ELEMENTS.policyHolderInsuredNameField.id}"
                  class="column-one-three"
                  name="policyHolder.insuredName"
                  label="Insured Name"
                  helper="Required"
                  .error="${this.errors.policyHolder.insuredName}"
                  .value="${this.state.policyHolder.insuredName}"
                  .onChange="${this.handlers.change}"
                ></neb-textfield>
              `
            : ''
        }

        <neb-textfield
          id="${ELEMENTS.policyHolderFirstNameField.id}"
          name="policyHolder.firstName"
          label="First Name"
          helper="${getRequiredText(!isWorkersComp(this.state.payerPlan))}"
          .error="${this.errors.policyHolder.firstName}"
          .value="${this.state.policyHolder.firstName}"
          .onChange="${this.handlers.change}"
          ?disabled="${
            shouldDisablePolicyHolderField(
              this.state.insuredRelationship,
              this.__selectedEntity,
            )
          }"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.policyHolderMiddleNameField.id}"
          name="policyHolder.middleName"
          label="Middle Name"
          helper=" "
          .value="${this.state.policyHolder.middleName}"
          .onChange="${this.handlers.change}"
          ?disabled="${
            shouldDisablePolicyHolderField(
              this.state.insuredRelationship,
              this.__selectedEntity,
            )
          }"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.policyHolderLastNameField.id}"
          name="policyHolder.lastName"
          label="Last Name"
          helper="${getRequiredText(!isWorkersComp(this.state.payerPlan))}"
          .error="${this.errors.policyHolder.lastName}"
          .value="${this.state.policyHolder.lastName}"
          .onChange="${this.handlers.change}"
          ?disabled="${
            shouldDisablePolicyHolderField(
              this.state.insuredRelationship,
              this.__selectedEntity,
            )
          }"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.suffixField.id}"
          name="policyHolder.suffix"
          label="Suffix"
          helper=" "
          maxLength="10"
          .value="${this.state.policyHolder.suffix}"
          .onChange="${this.handlers.change}"
          ?disabled="${
            shouldDisablePolicyHolderField(
              this.state.insuredRelationship,
              this.__selectedEntity,
            )
          }"
        ></neb-textfield>

        <neb-date-picker
          id="${ELEMENTS.policyHolderDobField.id}"
          name="policyHolder.dateOfBirth"
          class="width-hundred"
          label="Date of Birth"
          placeholder="Select Date"
          manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .isDateSelectable="${removeFutureDates}"
          .onChange="${this.handlers.change}"
          .selectedDate="${
            this.state.policyHolder.dateOfBirth
              ? moment(this.state.policyHolder.dateOfBirth)
              : null
          }"
          ?disabled="${
            shouldDisablePolicyHolderField(
              this.state.insuredRelationship,
              this.__selectedEntity,
            )
          }"
          momentFlag
        ></neb-date-picker>

        <neb-select
          id="${ELEMENTS.sexDropdown.id}"
          name="policyHolder.sex"
          label="Sex"
          helper=" "
          .items="${SEX}"
          .value="${this.state.policyHolder.sex}"
          .onChange="${this.handlers.change}"
          ?disabled="${
            shouldDisablePolicyHolderField(
              this.state.insuredRelationship,
              this.__selectedEntity,
            )
          }"
        ></neb-select>

        <div class="combo-field">
          <neb-textfield
            id="${ELEMENTS.memberIdentifierField.id}"
            class="flex-one"
            name="planInfo.memberIdentifier"
            label="Insured ID or Policy #"
            helper="Required"
            maxLength="50"
            .error="${this.errors.planInfo.memberIdentifier}"
            .value="${this.state.planInfo.memberIdentifier}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-tooltip
            id="${ELEMENTS.memberIdentifierToolTip.id}"
            class="tooltip"
            defaultAnchor="right"
          >
            <div id="${ELEMENTS.memberIdentifierToolTipText.id}" slot="tooltip">
              Enter the insured's policy # or member ID that identifies the
              policyholder.
            </div>
          </neb-tooltip>
        </div>

        <div class="combo-field">
          <neb-textfield
            id="${ELEMENTS.subscriberIdentifierField.id}"
            class="flex-one"
            name="planInfo.subscriberIdentifier"
            label="Subscriber ID"
            helper=" "
            maxLength="50"
            .value="${this.state.planInfo.subscriberIdentifier}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-tooltip
            id="${ELEMENTS.subscriberIdentifierToolTip.id}"
            class="tooltip"
            defaultAnchor="right"
          >
            <div
              id="${ELEMENTS.subscriberIdentifierToolTipText.id}"
              slot="tooltip"
            >
              If the patient is not the policyholder and has a unique subscriber
              ID, enter it here.
            </div>
          </neb-tooltip>
        </div>

        <div class="combo-field">
          <neb-textfield
            id="${ELEMENTS.insuredSSNField.id}"
            name="policyHolder.insuredSSN"
            label="${ELEMENTS.insuredSSNField.label}"
            helper=" "
            maxLength="11"
            .inputMode="${'numeric'}"
            .mask="${ssn}"
            .value="${this.state.policyHolder.insuredSSN}"
            .error="${this.errors.policyHolder.insuredSSN}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-tooltip
            id="${ELEMENTS.insuredSSNToolTip.id}"
            class="tooltip"
            defaultAnchor="right"
          >
            <div id="${ELEMENTS.insuredSSNToolTipText.id}" slot="tooltip">
              If the payer requires the insured's SSN, enter it here.
            </div>
          </neb-tooltip>
        </div>

        ${this.__renderAddressForm()}

        <div class="phone-container">
          <neb-textfield
            id="${ELEMENTS.policyHolderPhoneField.id}"
            name="policyHolder.phones.0.number"
            label="Phone Number"
            helper=" "
            maxLength="14"
            .mask="${phone}"
            .value="${this.state.policyHolder.phones[0].number}"
            .error="${this.errors.policyHolder.phones[0].number}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }

  __renderNotes() {
    return html`
      <div class="notes-container">
        <neb-textarea
          id="${ELEMENTS.notesTextArea.id}"
          class="notes"
          name="planInfo.notes"
          helperText=" "
          label="Notes"
          maxlength="500"
          .value="${this.state.planInfo.notes}"
          .onChange="${this.handlers.change}"
          showCount
        ></neb-textarea>
      </div>
    `;
  }

  __renderPolicy() {
    return html`
      <div class="tab-container">
        ${this.__renderPlanInformation()} ${this.__renderEligibility()}
        ${this.__renderPolicyHolder()} ${this.__renderNotes()}
      </div>
    `;
  }

  __renderAddCstButton(cst, addLabel) {
    const elementName = `${cst}AddButton`;

    return html`
      <neb-button-action
        id="${ELEMENTS[elementName].id}"
        name="${cst}"
        class="add-cst-button"
        label="${addLabel}"
        .onClick="${this.handlers.addCst}"
      ></neb-button-action>
    `;
  }

  __renderDefaultOption(cst, idx) {
    return html`
      <neb-checkbox
        id="${`${ELEMENTS[`${cst}IsDefault`].id}-${idx}`}"
        class="column-one row"
        name="${cst}.${idx}.isDefault"
        label="Default"
        ?checked="${this.state[cst][idx].isDefault}"
        .onChange="${this.handlers.defaultChecked}"
        ?disabled="${this.__isDefaultCheckboxDisabled(cst, idx)}"
      ></neb-checkbox>
    `;
  }

  __renderRemoveCstButton(cst, idx) {
    const threshold = ['coveredServices', 'nonCoveredServices'].includes(cst)
      ? 0
      : 1;
    return this.state[cst].length > threshold
      ? html`
          <neb-icon
            id="${ELEMENTS[`${cst}RemoveButton`].id}-${idx}"
            class="remove-icon"
            icon="neb:minus"
            @click="${() => this.handlers.removeCst(cst, idx)}"
          ></neb-icon>
        `
      : '';
  }

  __renderAddServiceButton(cst, addLabel) {
    const elementName = `${cst}AddButton`;

    return html`
      <neb-button-action
        id="${ELEMENTS[elementName].id}"
        name="${cst}"
        class="add-cst-button"
        label="${addLabel}"
        .onClick="${
          cst === 'coveredServices'
            ? this.handlers.addCoveredService
            : this.handlers.addNonCoveredService
        }"
      ></neb-button-action>
    `;
  }

  __renderCopay(idx) {
    return html`
      <div class="cst-container">
        <div class="col-fields-two">
          <neb-select
            id="${ELEMENTS.copayCoverageLevelDropdown.id}-${idx}"
            class="column-one"
            name="copays.${idx}.coverageLevel"
            label="Coverage Level"
            helper=" "
            .items="${mapCoverageLevelValueSimplified(0)}"
            .value="${
              mapCoverageLevelDisplayValue(this.state.copays[idx].coverageLevel)
            }"
            .onChange="${this.handlers.changeCopayCoverageLevel}"
          ></neb-select>

          <neb-select
            id="${ELEMENTS.copayServiceTypesMultiselect.id}-${idx}"
            class="column-two"
            name="copays.${idx}.serviceTypes"
            label="Service Types"
            allLabel="Service Types"
            helper="${
              getRequiredText(
                this.state.copays[idx].coverageLevel.code !== 'NA',
              )
            }"
            .value="${
              mapSelectedServiceTypesDisplay(
                getSelectedServiceTypes(
                  this.__serviceTypes,
                  this.state.copays[idx].serviceTypes,
                ),
              )
            }"
            .error="${this.errors.copays[idx].serviceTypes}"
            .items="${mapSelectedServiceTypesDisplay(this.__serviceTypes)}"
            .onChange="${this.handlers.multiSelectChange}"
            ?disabled="${this.state.copays[idx].coverageLevel.code === 'NA'}"
            multiSelect
          ></neb-select>

          <neb-textfield
            id="${ELEMENTS.copayInNetworkField.id}-${idx}"
            name="copays.${idx}.inNetworkAmount"
            class="column-one"
            label="In Network"
            maxLength="13"
            helper="${
              getRequiredText(
                this.state.copays[idx].coverageLevel.code !== 'NA' &&
                  this.state.copays[idx].outOfNetworkAmount === '',
              )
            }"
            .trailingIcon="${
              this.state.copays[idx].inNetworkAmount ? 'neb:clear' : ''
            }"
            .mask="${currency}"
            .inputMode="${'numeric'}"
            .value="${this.state.copays[idx].inNetworkAmount}"
            .error="${this.errors.copays[idx].inNetworkAmount}"
            .onChange="${this.handlers.copayTextChange}"
            .onClickIcon="${this.handlers.copayNetworkTextClear}"
            ?disabled="${this.state.copays[idx].coverageLevel.code === 'NA'}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.copayOutOfNetworkField.id}-${idx}"
            name="copays.${idx}.outOfNetworkAmount"
            class="column-two"
            label="Out of Network"
            maxLength="13"
            helper="${
              getRequiredText(
                this.state.copays[idx].coverageLevel.code !== 'NA' &&
                  this.state.copays[idx].inNetworkAmount === '',
              )
            }"
            .trailingIcon="${
              this.state.copays[idx].outOfNetworkAmount ? 'neb:clear' : ''
            }"
            .mask="${currency}"
            .inputMode="${'numeric'}"
            .value="${this.state.copays[idx].outOfNetworkAmount}"
            .error="${this.errors.copays[idx].outOfNetworkAmount}"
            .onChange="${this.handlers.copayTextChange}"
            .onClickIcon="${this.handlers.copayNetworkTextClear}"
            ?disabled="${this.state.copays[idx].coverageLevel.code === 'NA'}"
          ></neb-textfield>
          ${this.__renderDefaultOption('copays', idx)}
        </div>

        ${this.__renderRemoveCstButton('copays', idx)}
      </div>

      <div class="cst-line-container"><div class="cst-line"></div></div>
    `;
  }

  __renderCopays() {
    return html`
      <div class="title-container">
        <div class="title">Copays</div>
        <div class="description">
          Add copay details. This is the amount the patient will pay for covered
          services after the deductible is met.
        </div>
      </div>

      ${this.state.copays.map((_, idx) => this.__renderCopay(idx))}
    `;
  }

  __renderCoinsurance(idx) {
    return html`
      <div class="cst-container">
        <div class="col-fields-two">
          <neb-select
            id="${ELEMENTS.coinsCoverageLevelDropdown.id}-${idx}"
            class="column-one"
            name="coinsurances.${idx}.coverageLevel"
            label="Coverage Level"
            helper=" "
            .items="${mapCoverageLevelValueSimplified(0)}"
            .value="${
              mapCoverageLevelDisplayValue(
                this.state.coinsurances[idx].coverageLevel,
              )
            }"
            .onChange="${this.handlers.changeCoinsCoverageLevel}"
          ></neb-select>

          <neb-select
            id="${ELEMENTS.coinsServiceTypesMultiselect.id}-${idx}"
            class="column-two"
            name="coinsurances.${idx}.serviceTypes"
            label="Service Types"
            helper="${
              getRequiredText(
                this.state.coinsurances[idx].coverageLevel.code !== 'NA',
              )
            }"
            allLabel="Service Types"
            .value="${
              mapSelectedServiceTypesDisplay(
                getSelectedServiceTypes(
                  this.__serviceTypes,
                  this.state.coinsurances[idx].serviceTypes,
                ),
              )
            }"
            .error="${this.errors.coinsurances[idx].serviceTypes}"
            .items="${mapSelectedServiceTypesDisplay(this.__serviceTypes)}"
            .onChange="${this.handlers.multiSelectChange}"
            ?disabled="${
              this.state.coinsurances[idx].coverageLevel.code === 'NA'
            }"
            multiSelect
          ></neb-select>

          <div class="column-one combo-field">
            <neb-textfield
              id="${ELEMENTS.coinsInNetworkField.id}-${idx}"
              name="coinsurances.${idx}.inNetworkPercent"
              class="width-hundred"
              label="In Network Patient %"
              maxLength="3"
              helper="${
                getRequiredText(
                  this.state.coinsurances[idx].coverageLevel.code !== 'NA' &&
                    this.state.coinsurances[idx].outOfNetworkPercent === '',
                )
              }"
              .mask="${numberNoLeadingZeroAllowZero}"
              .inputMode="${'numeric'}"
              .value="${this.state.coinsurances[idx].inNetworkPercent}"
              .error="${this.errors.coinsurances[idx].inNetworkPercent}"
              .onChange="${this.handlers.coinsTextChange}"
              ?disabled="${
                this.state.coinsurances[idx].coverageLevel.code === 'NA'
              }"
            ></neb-textfield>
            <div class="percent">%</div>
          </div>
          <div class="column-two combo-field">
            <neb-textfield
              id="${ELEMENTS.coinsOutOfNetworkField.id}-${idx}"
              name="coinsurances.${idx}.outOfNetworkPercent"
              class="width-hundred"
              label="Out of Network Patient %"
              maxLength="3"
              helper="${
                getRequiredText(
                  this.state.coinsurances[idx].coverageLevel.code !== 'NA' &&
                    this.state.coinsurances[idx].inNetworkPercent === '',
                )
              }"
              .mask="${numberNoLeadingZeroAllowZero}"
              .inputMode="${'numeric'}"
              .value="${this.state.coinsurances[idx].outOfNetworkPercent}"
              .error="${this.errors.coinsurances[idx].outOfNetworkPercent}"
              .onChange="${this.handlers.coinsTextChange}"
              ?disabled="${
                this.state.coinsurances[idx].coverageLevel.code === 'NA'
              }"
            ></neb-textfield>
            <div class="percent">%</div>
          </div>
          ${this.__renderDefaultOption('coinsurances', idx)} <br />
        </div>

        ${this.__renderRemoveCstButton('coinsurances', idx)}
      </div>

      <div class="cst-line-container"><div class="cst-line"></div></div>
    `;
  }

  __renderCoinsurances() {
    return html`
      <div class="title-container">
        <div class="title">Coinsurance</div>
        <div class="description">
          Add coinsurance details. This is the percentage the patient will pay
          for covered services after the deductible is met.
        </div>
      </div>

      ${this.state.coinsurances.map((_, idx) => this.__renderCoinsurance(idx))}
    `;
  }

  __renderInNetwork(cst, idx) {
    return html`
      <neb-textfield
        id="${ELEMENTS[`${cst}InNetworkAmount`].id}-${idx}"
        name="${cst}.${idx}.inNetworkAmount"
        class="column-one"
        label="In Network Amount"
        maxLength="13"
        helper="${
          getRequiredText(
            this.state[cst][idx].coverageLevel.code !== 'NA' &&
              this.state[cst][idx].outOfNetworkAmount === '',
          )
        }"
        .trailingIcon="${
          this.state[cst][idx].inNetworkAmount ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state[cst][idx].inNetworkAmount}"
        .error="${this.errors[cst][idx].inNetworkAmount}"
        .onChange="${this.handlers.networkChange}"
        .onClickIcon="${this.handlers.textClearNetworkFields}"
        ?disabled="${this.state[cst][idx].coverageLevel.code === 'NA'}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS[`${cst}InNetworkRemaining`].id}-${idx}"
        name="${cst}.${idx}.inNetworkRemaining"
        label="In Network Remaining"
        maxLength="13"
        helper=" "
        .trailingIcon="${
          this.state[cst][idx].inNetworkRemaining ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state[cst][idx].inNetworkRemaining}"
        .error="${this.errors[cst][idx].inNetworkRemaining}"
        .onChange="${this.handlers.networkChange}"
        .onClickIcon="${this.handlers.textClearNetworkFields}"
        ?disabled="${
          this.state[cst][idx].coverageLevel.code === 'NA' ||
            this.state[cst][idx].inNetworkMet
        }"
      ></neb-textfield>

      <neb-date-picker
        id="${ELEMENTS[`${cst}InNetworkStart`].id}-${idx}"
        class="width-hundred"
        name="${cst}.${idx}.inNetworkStart"
        placeholder="Date${this.errors[cst][idx].inNetworkStart ? '*' : ''}"
        label="As of"
        manualPopoverPosition="${POPOVER_POSITION.CENTER}"
        .selectedDate="${
          this.state[cst][idx].inNetworkStart
            ? parseDate(this.state[cst][idx].inNetworkStart).startOf('day')
            : null
        }"
        .invalidText="${this.errors[cst][idx].inNetworkStart}"
        .onChange="${this.handlers.networkChange}"
        ?invalid="${!!this.errors[cst][idx].inNetworkStart}"
        ?disabled="${this.state[cst][idx].coverageLevel.code === 'NA'}"
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderOutOfNetwork(cst, idx) {
    return html`
      <neb-textfield
        id="${ELEMENTS[`${cst}OutOfNetworkAmount`].id}-${idx}"
        name="${cst}.${idx}.outOfNetworkAmount"
        class="column-one"
        label="Out of Network Amount"
        maxLength="13"
        helper="${
          getRequiredText(
            this.state[cst][idx].coverageLevel.code !== 'NA' &&
              this.state[cst][idx].inNetworkAmount === '',
          )
        }"
        .trailingIcon="${
          this.state[cst][idx].outOfNetworkAmount ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state[cst][idx].outOfNetworkAmount}"
        .error="${this.errors[cst][idx].outOfNetworkAmount}"
        .onChange="${this.handlers.networkChange}"
        .onClickIcon="${this.handlers.textClearNetworkFields}"
        ?disabled="${this.state[cst][idx].coverageLevel.code === 'NA'}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS[`${cst}OutOfNetworkRemaining`].id}-${idx}"
        name="${cst}.${idx}.outOfNetworkRemaining"
        label="Out of Network Remaining"
        maxLength="13"
        helper=" "
        .trailingIcon="${
          this.state[cst][idx].outOfNetworkRemaining ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state[cst][idx].outOfNetworkRemaining}"
        .error="${this.errors[cst][idx].outOfNetworkRemaining}"
        .onChange="${this.handlers.networkChange}"
        .onClickIcon="${this.handlers.textClearNetworkFields}"
        ?disabled="${
          this.state[cst][idx].coverageLevel.code === 'NA' ||
            this.state[cst][idx].outOfNetworkMet
        }"
      ></neb-textfield>

      <neb-date-picker
        id="${ELEMENTS[`${cst}OutOfNetworkStart`].id}-${idx}"
        class="width-hundred"
        name="${cst}.${idx}.outOfNetworkStart"
        placeholder="Date${this.errors[cst][idx].outOfNetworkStart ? '*' : ''}"
        label="As of"
        manualPopoverPosition="${POPOVER_POSITION.CENTER}"
        .selectedDate="${
          this.state[cst][idx].outOfNetworkStart
            ? parseDate(this.state[cst][idx].outOfNetworkStart).startOf('day')
            : null
        }"
        .invalidText="${this.errors[cst][idx].outOfNetworkStart}"
        .onChange="${this.handlers.networkChange}"
        ?invalid="${!!this.errors[cst][idx].outOfNetworkStart}"
        ?disabled="${this.state[cst][idx].coverageLevel.code === 'NA'}"
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderDeductible(idx) {
    return html`
      <div class="cst-container">
        <div class="col-fields no-padding">
          <neb-select
            id="${ELEMENTS.deductiblesCoverageLevelDropdown.id}-${idx}"
            class="column-one"
            name="deductibles.${idx}.coverageLevel"
            label="Coverage Level"
            helper=" "
            .items="${mapCoverageLevelValueSimplified(0)}"
            .value="${
              mapCoverageLevelDisplayValue(
                this.state.deductibles[idx].coverageLevel,
              )
            }"
            .onChange="${this.handlers.changeDeductiblesCoverageLevel}"
          ></neb-select>

          <neb-select
            id="${ELEMENTS.deductiblesServiceTypesMultiselect.id}-${idx}"
            class="column-two"
            name="deductibles.${idx}.serviceTypes"
            label="Service Types"
            allLabel="Service Types"
            helper="${
              getRequiredText(
                this.state.deductibles[idx].coverageLevel.code !== 'NA',
              )
            }"
            .value="${
              mapSelectedServiceTypesDisplay(
                getSelectedServiceTypes(
                  this.__serviceTypes,
                  this.state.deductibles[idx].serviceTypes,
                ),
              )
            }"
            .items="${mapSelectedServiceTypesDisplay(this.__serviceTypes)}"
            .onChange="${this.handlers.multiSelectChange}"
            .error="${this.errors.deductibles[idx].serviceTypes}"
            ?disabled="${
              this.state.deductibles[idx].coverageLevel.code === 'NA'
            }"
            multiSelect
          ></neb-select>

          ${this.__renderInNetwork('deductibles', idx)}

          <div class="row"></div>
          <neb-checkbox
            id="${ELEMENTS.deductiblesInNetworkMet.id}-${idx}"
            class="row"
            name="deductibles.${idx}.inNetworkMet"
            label="Deductible Met"
            ?checked="${this.state.deductibles[idx].inNetworkMet}"
            .onChange="${this.handlers.networkMetChecked}"
            ?disabled="${
              this.state.deductibles[idx].coverageLevel.code === 'NA'
            }"
          ></neb-checkbox>

          ${this.__renderOutOfNetwork('deductibles', idx)}
          ${this.__renderDefaultOption('deductibles', idx)}
          <neb-checkbox
            id="${ELEMENTS.deductiblesOutOfNetworkMet.id}-${idx}"
            class="row"
            name="deductibles.${idx}.outOfNetworkMet"
            label="Deductible Met"
            ?checked="${this.state.deductibles[idx].outOfNetworkMet}"
            .onChange="${this.handlers.networkMetChecked}"
            ?disabled="${
              this.state.deductibles[idx].coverageLevel.code === 'NA'
            }"
          ></neb-checkbox>
        </div>

        ${this.__renderRemoveCstButton('deductibles', idx)}
      </div>

      <div class="cst-line-container"><div class="cst-line"></div></div>
    `;
  }

  __renderDeductibles() {
    return html`
      <div class="title-container">
        <div class="title">Deductibles</div>
        <div class="description">
          Add deductible details. This is the amount the patient will pay before
          the insurance plan will begin to pay for covered services.
        </div>
      </div>

      ${this.state.deductibles.map((_, idx) => this.__renderDeductible(idx))}
    `;
  }

  __renderOutOfPocket(idx) {
    return html`
      <div class="cst-container">
        <div class="col-fields no-padding">
          <neb-select
            id="${ELEMENTS.outOfPocketsCoverageLevelDropdown.id}-${idx}"
            class="column-one"
            name="outOfPockets.${idx}.coverageLevel"
            label="Coverage Level"
            helper=" "
            .items="${mapCoverageLevelValueSimplified(0)}"
            .value="${
              mapCoverageLevelDisplayValue(
                this.state.outOfPockets[idx].coverageLevel,
              )
            }"
            .onChange="${this.handlers.changeOutOfPocketsCoverageLevel}"
          ></neb-select>

          ${this.__renderInNetwork('outOfPockets', idx)}

          <div class="row"></div>
          <neb-checkbox
            id="${ELEMENTS.outOfPocketsInNetworkMet.id}-${idx}"
            class="row"
            name="outOfPockets.${idx}.inNetworkMet"
            label="OOP Met"
            ?checked="${this.state.outOfPockets[idx].inNetworkMet}"
            .onChange="${this.handlers.networkMetChecked}"
            ?disabled="${
              this.state.outOfPockets[idx].coverageLevel.code === 'NA'
            }"
          ></neb-checkbox>

          ${this.__renderOutOfNetwork('outOfPockets', idx)}
          ${this.__renderDefaultOption('outOfPockets', idx)}
          <neb-checkbox
            id="${ELEMENTS.outOfPocketsOutOfNetworkMet.id}-${idx}"
            class="row"
            name="outOfPockets.${idx}.outOfNetworkMet"
            label="OOP Met"
            ?checked="${this.state.outOfPockets[idx].outOfNetworkMet}"
            .onChange="${this.handlers.networkMetChecked}"
            ?disabled="${
              this.state.outOfPockets[idx].coverageLevel.code === 'NA'
            }"
          ></neb-checkbox>
        </div>

        ${this.__renderRemoveCstButton('outOfPockets', idx)}
      </div>

      <div class="cst-line-container"><div class="cst-line"></div></div>
    `;
  }

  __renderOutOfPockets() {
    return html`
      <div class="title-container">
        <div class="title">Max Out of Pocket</div>
        <div class="description">
          Add max out of pocket details. This is the maximum amount the patient
          will pay before the insurance plan pays for 100% of covered services.
        </div>
      </div>

      ${this.state.outOfPockets.map((_, idx) => this.__renderOutOfPocket(idx))}
    `;
  }

  __renderServiceTypes(cst, idx) {
    const availableServiceTypes = getAvailableServiceTypesForServices(
      this.state,
      this.state[cst][idx].serviceTypeId,
      this.__serviceTypes,
    );

    const selectedServiceType = getSelectedServiceType(
      availableServiceTypes,
      this.state[cst][idx],
    );

    return html`
      <neb-select
        id="${ELEMENTS[`${cst}ServiceTypeDropdown`].id}-${idx}"
        class="column-one"
        name="${cst}.${idx}.serviceTypeId"
        label="Service Types"
        helper="Required"
        .value="${selectedServiceTypeDisplay(selectedServiceType)}"
        .error="${this.errors[cst][idx].serviceTypeId}"
        .items="${mapSelectedServiceTypesDisplay(availableServiceTypes)}"
        .onChange="${this.handlers.servicesServiceTypeChange}"
      ></neb-select>
    `;
  }

  __renderProcedureCode(cst, idx) {
    return html`
      <div class="procedure-code">
        <div>Procedure Code</div>
        <div id="${ELEMENTS[`${cst}ProcedureCodeText`].id}-${idx}">
          ${
            formatCode(
              this.state[cst][idx].charge.procedure,
              this.state[cst][idx].charge.modifiers,
            )
          }
          - ${this.state[cst][idx].charge.description}
        </div>
      </div>
    `;
  }

  __renderCoveredServiceFirstRow(idx) {
    return html`
      <neb-select
        id="${ELEMENTS.coveredServicesQuantityTypeDropdown.id}-${idx}"
        class="column-one"
        name="coveredServices.${idx}.quantityType"
        label="Quantity Type"
        helper="${
          this.state.coveredServices[idx].term.length ? 'Required' : ' '
        }"
        .items="${QUANTITY_TYPE_ITEMS}"
        .value="${this.state.coveredServices[idx].quantityType}"
        .error="${this.errors.coveredServices[idx].quantityType}"
        .onChange="${this.handlers.servicesQuantityTypeChange}"
      ></neb-select>

      <neb-textfield
        id="${ELEMENTS.coveredServicesQuantityField.id}-${idx}"
        name="coveredServices.${idx}.quantity"
        label="Quantity"
        helper="${
          this.state.coveredServices[idx].quantityType.length ||
          this.state.coveredServices[idx].term.length
            ? 'Required'
            : ' '
        }"
        maxLength="${
          this.state.coveredServices[idx].quantityType === 'Dollar Amount'
            ? '13'
            : '3'
        }"
        .mask="${
          this.state.coveredServices[idx].quantityType === 'Dollar Amount'
            ? currency
            : numberNoLeadingZeroAllowZero
        }"
        .inputMode="${'numeric'}"
        .value="${this.state.coveredServices[idx].quantity}"
        .error="${this.errors.coveredServices[idx].quantity}"
        .onChange="${this.handlers.change}"
        ?disabled="${this.state.coveredServices[idx].quantityType === ''}"
      ></neb-textfield>

      <neb-select
        id="${ELEMENTS.coveredServicesTermDropdown.id}-${idx}"
        name="coveredServices.${idx}.term"
        label="Term"
        helper="${
          this.state.coveredServices[idx].quantityType.length ? 'Required' : ' '
        }"
        .value="${this.state.coveredServices[idx].term}"
        .error="${this.errors.coveredServices[idx].term}"
        .items="${TERM_ITEMS}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  // eslint-disable-next-line complexity
  __renderCoveredService(idx) {
    return html`
      <div class="cst-container">
        <div class="col-fields no-padding">
          ${
            this.state.coveredServices[idx].type ===
            COVERED_SERVICE_TYPE.ProcedureCode
              ? this.__renderProcedureCode('coveredServices', idx)
              : this.__renderServiceTypes('coveredServices', idx)
          }

          <neb-checkbox
            id="${ELEMENTS.coveredServicesPreAuthCheckbox.id}-${idx}"
            class="preauth-checkbox"
            name="coveredServices.${idx}.preAuthorizationRequired"
            label="Pre-Authorization Required"
            ?checked="${
              this.state.coveredServices[idx].preAuthorizationRequired
            }"
            .onChange="${this.handlers.change}"
          ></neb-checkbox>

          ${this.__renderCoveredServiceFirstRow(idx)}

          <neb-textfield
            id="${ELEMENTS.coveredServicesRemainingQuantityField.id}-${idx}"
            name="coveredServices.${idx}.remainingQuantity"
            label="Remaining"
            helper="${
              this.state.coveredServices[idx].asOfDate !== null
                ? 'Required'
                : ' '
            }"
            maxLength="${
              this.state.coveredServices[idx].quantityType === 'Dollar Amount'
                ? '13'
                : '3'
            }"
            .value="${this.state.coveredServices[idx].remainingQuantity}"
            .error="${this.errors.coveredServices[idx].remainingQuantity}"
            .mask="${
              this.state.coveredServices[idx].quantityType === 'Dollar Amount'
                ? currency
                : numberNoLeadingZeroAllowZero
            }"
            .inputMode="${'numeric'}"
            .onChange="${this.handlers.change}"
            ?disabled="${this.state.coveredServices[idx].quantityType === ''}"
          ></neb-textfield>

          <neb-date-picker
            id="${ELEMENTS.coveredServicesAsOfDatePicker.id}-${idx}"
            name="coveredServices.${idx}.asOfDate"
            class="width-hundred"
            placeholder="As of"
            label="As of"
            manualPopoverPosition="${POPOVER_POSITION.CENTER}"
            helperText="${
              this.state.coveredServices[idx].remainingQuantity.length
                ? 'Required'
                : ' '
            }"
            .selectedDate="${
              this.state.coveredServices[idx].asOfDate
                ? moment(this.state.coveredServices[idx].asOfDate)
                : null
            }"
            .invalidText="${this.errors.coveredServices[idx].asOfDate}"
            .onChange="${this.handlers.change}"
            ?invalid="${!!this.errors.coveredServices[idx].asOfDate}"
            ?disabled="${this.state.coveredServices[idx].quantityType === ''}"
            momentFlag
          ></neb-date-picker>

          <neb-textfield
            id="${ELEMENTS.coveredServicesInNetworkCopayAmountField.id}-${idx}"
            name="coveredServices.${idx}.inNetworkCopayAmount"
            class="column-one"
            label="In Network Copay Amount"
            helper=" "
            maxLength="13"
            .trailingIcon="${
              this.state.coveredServices[idx].inNetworkCopayAmount
                ? 'neb:clear'
                : ''
            }"
            .mask="${currency}"
            .inputMode="${'numeric'}"
            .value="${this.state.coveredServices[idx].inNetworkCopayAmount}"
            .onChange="${this.handlers.change}"
            .onClickIcon="${this.handlers.textClear}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.coveredServicesOutOfNetworkCopayAmountField.id}-${
              idx
            }"
            name="coveredServices.${idx}.outOfNetworkCopayAmount"
            label="Out of Network Copay Amount"
            helper=" "
            maxLength="13"
            .trailingIcon="${
              this.state.coveredServices[idx].outOfNetworkCopayAmount
                ? 'neb:clear'
                : ''
            }"
            .mask="${currency}"
            .inputMode="${'numeric'}"
            .value="${this.state.coveredServices[idx].outOfNetworkCopayAmount}"
            .onChange="${this.handlers.change}"
            .onClickIcon="${this.handlers.textClear}"
          ></neb-textfield>

          <div class="column-one combo-field">
            <neb-textfield
              id="${ELEMENTS.coveredServicesInNetworkCoinsuranceField.id}-${
                idx
              }"
              name="coveredServices.${idx}.inNetworkCoinsurancePercent"
              class="column-one width-hundred"
              label="In Network Coinsurance %"
              helper=" "
              maxLength="3"
              .mask="${numberNoLeadingZeroAllowZero}"
              .inputMode="${'numeric'}"
              .value="${
                this.state.coveredServices[idx].inNetworkCoinsurancePercent
              }"
              .error="${
                this.errors.coveredServices[idx].inNetworkCoinsurancePercent
              }"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <div class="percent">%</div>
          </div>

          <div class="combo-field">
            <neb-textfield
              id="${ELEMENTS.coveredServicesOutOfNetworkCoinsuranceField.id}-${
                idx
              }"
              name="coveredServices.${idx}.outOfNetworkCoinsurancePercent"
              class="width-hundred"
              label="Out of Network Coinsurance %"
              helper=" "
              maxLength="3"
              .mask="${numberNoLeadingZeroAllowZero}"
              .inputMode="${'numeric'}"
              .value="${
                this.state.coveredServices[idx].outOfNetworkCoinsurancePercent
              }"
              .error="${
                this.errors.coveredServices[idx].outOfNetworkCoinsurancePercent
              }"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <div class="percent">%</div>
          </div>
        </div>

        ${this.__renderRemoveCstButton('coveredServices', idx)}
      </div>

      <div class="cst-line-container"><div class="cst-line"></div></div>
    `;
  }

  __renderCoveredServices() {
    return html`
      <div class="title-container">
        <div class="title">Covered Services</div>
        <div class="description">
          Add additional detail regarding covered service limitations, if
          desired.
        </div>
      </div>

      ${
        this.state.coveredServices.map((_, idx) =>
          this.__renderCoveredService(idx),
        )
      }
    `;
  }

  __renderNonCoveredService(idx) {
    return html`
      <div class="cst-container">
        <div class="col-fields no-padding">
          ${
            this.state.nonCoveredServices[idx].type ===
            COVERED_SERVICE_TYPE.ProcedureCode
              ? this.__renderProcedureCode('nonCoveredServices', idx)
              : this.__renderServiceTypes('nonCoveredServices', idx)
          }
        </div>

        ${this.__renderRemoveCstButton('nonCoveredServices', idx)}
      </div>

      <div class="cst-line-container"><div class="cst-line"></div></div>
    `;
  }

  __renderNonCoveredServices() {
    return html`
      <div class="title-container">
        <div class="title">Non-Covered Services</div>
        <div class="description">
          Add non-covered service types or procedures.
        </div>
      </div>

      ${
        this.state.nonCoveredServices.map((_, idx) =>
          this.__renderNonCoveredService(idx),
        )
      }
    `;
  }

  getVisitLimit() {
    return (
      this.state.patientInsuranceVisitLimit ||
      this.patientInsurance.patientInsuranceVisitLimit ||
      {}
    );
  }

  __renderMaxVisitLimit() {
    return html`
      <div class="title-container">
        <div class="title">Visit Limits</div>
        <div class="description">
          Add additional detail regarding visit limits to be alerted when a
          patient is nearing their annual limit.
        </div>
      </div>

      <div class="col-fields">
        <neb-textfield
          id="${ELEMENTS.maxVisits.id}"
          name="patientInsuranceVisitLimit.maxVisits"
          class="column-one"
          label="${ELEMENTS.maxVisits.label}"
          maxLength="3"
          helper=" "
          .mask="${numberNoLeadingZeroAllowZero}"
          .inputMode="${'numeric'}"
          .value="${String(this.state.patientInsuranceVisitLimit.maxVisits)}"
          .error="${this.errors.patientInsuranceVisitLimit.maxVisits}"
          .onChange="${this.handlers.visitLimitChange}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.renderedOutsidePractice.id}"
          name="patientInsuranceVisitLimit.visitsRenderedOutOfOffice"
          class="column-two"
          label="${ELEMENTS.renderedOutsidePractice.label}"
          maxLength="3"
          helper=" "
          .inputMode="${'numeric'}"
          .error="${
            this.errors.patientInsuranceVisitLimit.visitsRenderedOutOfOffice
          }"
          .mask="${numberNoLeadingZeroAllowZero}"
          .value="${
            String(
              this.state.patientInsuranceVisitLimit.visitsRenderedOutOfOffice,
            )
          }"
          .onChange="${this.handlers.visitLimitChange}"
        ></neb-textfield>

        <neb-date-picker
          id="${ELEMENTS.visitLimitAsOf.id}"
          class="width-hundred"
          name="patientInsuranceVisitLimit.asOf"
          label="As of"
          manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${
            this.getVisitLimit().asOf
              ? moment(this.getVisitLimit().asOf).utc()
              : null
          }"
          .isDateSelectable="${removeFutureDates}"
          .onChange="${this.handlers.visitLimitChange}"
          .invalidText="${this.errors.patientInsuranceVisitLimit.asOf}"
          ?invalid="${!!this.errors.patientInsuranceVisitLimit.asOf}"
          momentFlag
        ></neb-date-picker>

        <div class="row"></div>

        <div
          id="${ELEMENTS.renderedPractice.id}"
          class="rendered-practice flex-one row-item-padding"
        >
          <span>${ELEMENTS.renderedPractice.label}</span>
          <span>${this.getVisitLimit().currentVisits}</span>
        </div>
        <div
          id="${ELEMENTS.totalRemaining.id}"
          class="total-remaining flex-one row-item-padding"
        >
          <span>${ELEMENTS.totalRemaining.label}</span>
          <span
            >${
              this.patientInsurance.patientInsuranceVisitLimit
                .displayTotalRemaining
            }</span
          >
        </div>
      </div>
    `;
  }

  __renderCoverage() {
    return html`
      <div class="tab-container">
        ${this.__renderCopays()}
        ${this.__renderAddCstButton('copays', 'Add Copay')}
        ${this.__renderCoinsurances()}
        ${this.__renderAddCstButton('coinsurances', 'Add Coinsurance')}
        ${this.__renderDeductibles()}
        ${this.__renderAddCstButton('deductibles', 'Add Deductible')}
        ${this.__renderOutOfPockets()}
        ${this.__renderAddCstButton('outOfPockets', 'Add Max Out Of Pocket')}
        ${this.__renderMaxVisitLimit()} ${this.__renderCoveredServices()}
        ${
          this.__renderAddServiceButton(
            'coveredServices',
            'Add Covered Service',
          )
        }
        ${this.__renderNonCoveredServices()}
        ${
          this.__renderAddServiceButton(
            'nonCoveredServices',
            'Add Non-Covered Service',
          )
        }
      </div>
    `;
  }

  renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        class="action-bar"
        confirmLabel="Save"
        cancelLabel="Cancel"
        .onConfirm="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
      ></neb-action-bar>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderTabContent()}
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="header-container">${this.__renderHeader()}</div>
        ${this.__renderTabs()} ${this.renderContent()} ${this.renderFooter()}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-form-patient-insurance-edit',
  NebFormPatientInsuranceEdit,
);
