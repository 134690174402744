import './claim-form-cell/neb-claim-form-cell';
import './claim-form-cell/neb-claim-form-cell-address';
import './claim-form-cell/neb-claim-form-cell-fl-9';
import './claim-form-cell/neb-claim-form-cell-fl-10';
import './claim-form-cell/neb-claim-form-cell-fl-17';
import './claim-form-cell/neb-claim-form-cell-fl-19';
import './claim-form-cell/neb-claim-form-cell-fl-21';
import './claim-form-cell/neb-claim-form-cell-fl-24';
import './claim-form-cell/neb-claim-form-cell-fl-25';
import './claim-form-cell/neb-claim-form-cell-practice-info';
import './claim-form-cell/neb-claim-form-cell-two-column';
import './claim-form-cell/neb-claim-form-cell-date-range';
import '../controls/neb-category-accordion';
import '../inputs/neb-textfield';
import '../inputs/neb-select';
import '../neb-button-actions';
import '../neb-text';
import '../../../../../src/components/misc/neb-icon';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { ADD_ONS, hasAddOn } from '../../../../../src/utils/add-ons';
import {
  formatClaimNameField,
  formatFL4,
} from '../../../../neb-api-client/src/formatters/claims';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_1,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import {
  CLAIM_STATUS,
  CROSS_WALK,
  OTHER_DATE_QUALIFIER,
  isEditable,
  isCancelable,
  formatServiceFacilityNPI,
  isCanceled,
  formatDateForAdditionalInfo,
  filterStatusAvailableToChangeFromTo,
  hasStatus,
  PAYMENT_RESPONSIBILITY_LEVEL_CODE,
  getPrintSettings,
  setPrintSettings,
} from '../../../../neb-utils/claims';
import { parseDate } from '../../../../neb-utils/date-util';
import { CLAIM_RESUBMISSION_CODE } from '../../../../neb-utils/enums';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../neb-utils/feature-util';
import {
  scrubbingErrorFormatter,
  validationErrorFormatter,
} from '../../../../neb-utils/validationErrors';
import {
  claimStatusLineItemsExist,
  CLAIM_VIEW_ELEMENTS,
  translateClearinghouseErrors,
  translateErrors,
  translateNotifications,
  translateWarnings,
} from '../../utils/claim-validation';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { THEME } from '../controls/neb-category';

import { formatDate } from './claim-form-cell/neb-claim-form-cell-fl-11';
import {
  APPROVED_CLAIM_STATUSES,
  DENIED_AND_REJECTED_CLAIM_STATUSES,
  SUBMITTED_CLAIM_STATUSES,
} from './neb-claims-worklist-controller';
import { NEEDS_ATTENTION_CLAIM_STATUSES } from './status-controllers/neb-controller-needs-attention';

const EMPTY_ADDRESS = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  phone: '',
};

const SPACE_DELIMITER = ' '.repeat(3);

function spinalManipulationTemplate(code, description1, description2) {
  return html`
    <div class="grid grid-2">
      <slot></slot>
      <div>Code: ${code}</div>
      <div></div>
      <div>Description 1: ${description1}</div>
      <div></div>
      <div>Description 2: ${description2}</div>
      <div></div>
    </div>
  `;
}

function twoDataCellTemplate({ firstColumnData, secondColumnData }) {
  return html`
    <div class="two-data-cell">
      <div>${firstColumnData}</div>
      <div>${secondColumnData}</div>
    </div>
  `;
}

export const UNEDITABLE_CLAIM_STATUSES = [
  CLAIM_STATUS.CANCELED,
  CLAIM_STATUS.DENIED,
  CLAIM_STATUS.REJECTED,
  CLAIM_STATUS.VALIDATION_ERROR,
  CLAIM_STATUS.SUBMITTING,
  CLAIM_STATUS.TRANSMITTED,
];

export const CATEGORY = {
  ERROR: 'errors',
  NOTIFICATION: 'notifications',
  WARNING: 'warnings',
  VALIDATION_ERROR: 'Validation Errors',
  SCRUBBING_ERROR: 'Claim Scrubbing Errors',
};

export const ELEMENTS = {
  ...CLAIM_VIEW_ELEMENTS,
  claimFormCells: { selector: 'neb-claim-form-cell' },
  adjustPrintSettingsIcon: { id: 'adjust-print-settings-icon' },
  typeOfHealthCoverageCell: { id: 'type-of-health-coverage-cell' },
  signatureOfPhysicianCell: { id: 'signature-of-physician-cell' },
  signatureOfPhysicianImage: { id: 'signature-of-physician-image' },
  acceptAssignmentCell: { id: 'accept-assignment-cell' },
  resubmissionCodeCell: { id: 'resubmission-code-cell' },
  originalReferenceNumber: { id: 'original-reference-number' },
  outsideLabsCell: { id: 'outside-labs-cell' },
  otherInsuredInfoCell: { id: 'other-insured-info-cell' },
  disabilityDatesCell: { id: 'disability-dates-cell' },
  hospitalizationDatesCell: { id: 'hospitalization-dates-cell' },
  amountPaidCell: { id: 'amount-paid-cell' },
  validationAccordion: { id: 'validation-validationAccordion' },
  statusDropdown: { id: 'status-dropdown' },
  fl8Cell: { id: 'fl-8-cell' },
  patientSignatureCell: { id: 'patient-signature-cell' },
  insuredSignatureCell: { id: 'insured-signature-cell' },
  fl30Cell: { id: 'fl-30-cell' },
  originalClaimLink: { id: 'original-claim-link' },
  viewHistoryLogLink: { id: 'view-history-log-link' },
  requestStatusUpdateLink: { id: 'request-status-update-link' },
  cancelClaimLink: { id: 'cancel-claim-link' },
  refreshClaimLink: { id: 'refresh-claim-link' },
  overrideScrubbingErrorsLink: { id: 'override-scrubbing-errors-link' },
  printClaimLink: { id: 'print-claim-link' },
  deleteClaimLink: { id: 'delete-claim-link' },
  generateClaimLink: { id: 'generate-claim-link' },
  refreshDraftLink: { id: 'refresh-draft-link' },
  refreshAndSubmitLink: { id: 'refresh-and-submit-link' },
  rebillLink: { id: 'rebill-link' },
  correctClaimLink: { id: 'correct-claim-link' },
  scrubClaimLink: { id: 'scrub-claim-link' },
};

export class NebClaimFormView extends LitElement {
  static get properties() {
    return {
      signatureSrc: String,
      model: Object,
      offset: Object,
      errors: Object,
      validationErrors: Object,
      scrubbingErrors: Object,
      clearinghouseErrors: Object,
      otherChcErrors: Object,
      isElectronic: Boolean,
      hasMaxClear: Boolean,
      dirty: Boolean,
      __hasRTSScrubFF: Boolean,
      __hasCTRevAccelAddOn: Boolean,
      __hasRcmRelease2FF: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .content-container {
          padding: 0px ${CSS_SPACING} 10px;
        }

        .grid-width-8 {
          display: grid;
          grid-template-columns: repeat(8, 1fr);
        }

        .form {
          grid-template-rows: repeat(20, minmax(90px, auto));
          margin-top: ${CSS_SPACING};
          border-top: ${CSS_BORDER_GREY_1};
          border-left: ${CSS_BORDER_GREY_1};
        }

        .span-column-5 {
          grid-column: span 5;
        }

        .span-column-3 {
          grid-column: span 3;
        }

        .span-column-2 {
          grid-column: span 2;
        }

        .span-row-2 {
          grid-row: span 2;
        }

        .fl-26-27 {
          display: grid;
          grid-column: span 3;
          grid-template-columns: repeat(2, 1fr);
        }

        .image {
          align-self: flex-end;
          max-width: 100%;
          max-height: 120px;
        }

        .fl-21 {
          display: grid;
          grid-column: span 5;
          grid-row: span 2;
        }

        .fl-11,
        .fl-9 {
          display: grid;
          grid-column: span 3;
          grid-row: span 5;
        }

        .fl-10 {
          display: grid;
          grid-column: span 2;
          grid-row: span 5;
        }

        .fl-17 {
          display: grid;
          grid-column: span 5;
        }

        .fl-19 {
          display: grid;
          grid-column: span 5;
          border-right: ${CSS_BORDER_GREY_1};
        }

        .fl-24 {
          display: grid;
          grid-column: span 8;
          border-right: ${CSS_BORDER_GREY_1};
        }

        .address,
        .insured-address {
          display: grid;
          grid-column: span 3;
          grid-row: span 3;
        }

        .insured-address,
        .fl-11 {
          border-right: ${CSS_BORDER_GREY_1};
        }

        .practice-info {
          display: grid;
          grid-column: span 3;
          grid-row: span 2;
        }

        .cell {
          display: grid;
          width: 100%;
        }

        .offset-input {
          width: 100px;
        }

        .two-data-cell {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding-top: 14px;
        }

        .buttons-container {
          display: flex;
          justify-content: space-between;
        }

        .container-print {
          display: flex;
          align-items: center;
        }

        .button-print {
          margin-right: 10px;
        }

        .buttons-container-left {
          display: grid;
          grid-auto-flow: column;
          gap: ${CSS_SPACING};
          margin-top: ${CSS_SPACING};
        }

        .buttons-container-right {
          display: flex;
        }

        .border {
          border-bottom: 3px solid ${CSS_COLOR_GREY_1};
        }

        .accordion[hideMargin] {
          margin-top: 15px;
        }

        .icon {
          cursor: pointer;
          width: 25px;
          height: 25px;
          margin-top: 24px;
          margin-left: ${CSS_SPACING};
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        #status-dropdown {
          width: 250px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      acceptAssignment: '',
      additionalCheckbox: false,
      additionalClaimInformation: '',
      autoAccidentState: '',
      billingProviderAddress: EMPTY_ADDRESS,
      billingProviderNPI: '',
      billingProviderPracticeName: '',
      billingProviderOtherId: '',
      billingProviderOtherIdQualifier: '',
      claimAuthorizations: [{ order: 0, authorizationNumber: '' }],
      claimCharges: [],
      claimCodes: '',
      claimDiagnoses: [],
      claimStatuses: [
        { status: CLAIM_STATUS.UNSAVED_DRAFT, updatedBy: '', description: '' },
      ],
      dateGenerated: '',
      dateOfCurrentIllness: '',
      dateOfCurrentIllnessQualifier: '',
      disabilityFromDate: '',
      disabilityToDate: '',
      federalTaxIdType: '',
      federalTaxIdNumber: '',
      hospitalizationFromDate: '',
      hospitalizationToDate: '',
      insurancePlanName: '',
      insuredAddress: EMPTY_ADDRESS,
      insuredDateOfBirth: '',
      insuredFirstName: '',
      insuredGroupIdNumber: '',
      insuredIdNumber: '',
      insuredLastName: '',
      insuredMiddleName: '',
      insuredSex: '',
      insuredSuffix: '',
      isOutsideLab: '',
      invoice: {
        reportTypeCode: '',
        reportTransmissionCode: '',
        codeQualifier: '',
        identificationNumber: '',
      },
      medicalRecordNumber: '',
      originalReferenceNumber: '',
      otherClaimId: '',
      otherClaimQualifier: '',
      otherDate: '',
      otherDateQualifier: '',
      otherInsurancePlanName: '',
      otherInsuredFirstName: '',
      otherInsuredGroupIdNumber: '',
      otherInsuredLastName: '',
      otherInsuredMiddleName: '',
      otherInsuredSuffix: '',
      otherInsuredAddress: EMPTY_ADDRESS,
      outsideLabChargesAmount: 0,
      paidAmount: 0,
      fl29AmountPaidOverride: false,
      hasNotes: false,
      patientAddress: EMPTY_ADDRESS,
      patientDateOfBirth: '',
      patientDateOfDeath: '',
      patientFirstName: '',
      patientLastName: '',
      patientMiddleName: '',
      patientSex: '',
      patientSsn: '',
      patientSuffix: '',
      referringProviderCredentials: '',
      referringProviderFirstName: '',
      referringProviderLastName: '',
      referringProviderMiddleName: '',
      referringProviderNPI: '',
      referringProviderOtherId: '',
      referringProviderOtherIdQualifier: '',
      referringProviderQualifier: '',
      resubmissionCode: '',
      serviceFacilityAddress: EMPTY_ADDRESS,
      serviceFacilityName: '',
      serviceFacilityNPI: '',
      serviceFacilityOtherId: '',
      serviceFacilityOtherIdQualifier: '',
      spinalManipulationInfoCode: '',
      spinalManipulationInfoDescription: '',
      spinalManipulationInfoDescription2: '',
      totalChargeAmount: 0,
      originalClaimId: '',
      codeQualifier: '',
      reportTransmissionCode: '',
      reportTypeCode: '',
      identificationNumber: '',
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.hasMaxClear = false;
    this.dirty = false;
    this.signatureSrc = '';
    this.isElectronic = null;
    this.validationErrors = {};
    this.scrubbingErrors = {};
    this.clearinghouseErrors = {};
    this.otherChcErrors = {};
    this.errors = {
      billingProviderAddress: {},
      serviceFacilityAddress: {},
      claimAuthorizations: [{ authorizationNumber: '' }],
    };

    this.__hasCTRevAccelAddOn = false;
    this.__hasRTSScrubFF = false;
    this.__hasRcmRelease2FF = false;

    this.model = NebClaimFormView.createModel();

    this.onClickCell = () => {};

    this.onDelete = () => {};

    this.onGenerate = () => {};

    this.onPrint = () => {};

    this.onCancelResubmit = () => {};

    this.onSelectError = () => {};

    this.onStatusChange = () => {};

    this.onRebillClaim = () => {};

    this.onRequestStatus = () => {};

    this.onResetStatus = () => {};

    this.onRefreshClaim = () => {};

    this.onDisplayS3Claim = () => {};

    this.onGenerateWithOverride = () => {};

    this.onRefreshAndSubmit = () => {};

    this.onRefreshDraft = () => {};

    this.onCorrectClaim = () => {};

    this.onVoidClaim = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickCell: cell => this.onClickCell(cell),
      deleteClaim: () => this.onDelete(),
      generateClaim: () => this.onGenerate(),
      scrubClaim: () => this.onScrubClaim(),
      printClaim: () => this.onPrint(),
      selectItem: item =>
        item.name === 'notifications'
          ? this.__selectViewStatusCodes(item)
          : this.onSelectError(item),
      viewRuleSets: () =>
        openOverlay(OVERLAY_KEYS.ASSOCIATED_RULE_SETS, {
          id: this.model.id,
        }),
      changeStatus: ({ event, value }) => {
        if (
          event === 'select' &&
          value !== this.model.claimStatuses[0].status
        ) {
          if (this.model.isElectronic) {
            let effectiveDate = parseDate().endOf('day');

            if (
              this.model.claimStatuses[0].effectiveDate &&
              parseDate(this.model.claimStatuses[0].effectiveDate) >
                effectiveDate
            ) {
              effectiveDate = parseDate(
                this.model.claimStatuses[0].effectiveDate,
              ).endOf('day');
            }
            this.onStatusChange(value, effectiveDate);
          } else {
            this.onStatusChange(value);
          }
        }
      },
      openPrintSettings: async () => {
        const settings = getPrintSettings();

        const result = await openPopup(POPUP_RENDER_KEYS.CLAIM_PRINT_SETTINGS, {
          title: 'Print Settings',
          items: [
            {
              name: 'top',
              value: settings.top,
            },
            {
              name: 'left',
              value: settings.left,
            },
            {
              name: 'bold',
              value: settings.bold,
            },
          ],
        });

        if (result) {
          setPrintSettings(result);
        }
      },
      viewHistoryLog: () =>
        openOverlay(OVERLAY_KEYS.CLAIM_HISTORY_LOG, {
          claimModel: this.model,
          payerPlan: this.payerPlan,
        }),
      resubmitClaim: () => this.onCancelResubmit(),
      requestStatusUpdate: () => this.onRequestStatus(),
      rebill: () => this.onRebillClaim(),
      refreshClaim: () => this.onRefreshClaim(),
      overrideScrubbingErrors: async () => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Override Scrubbing Errors and Submit',
          message:
            'Are you sure that you want to override all claim scrubbing errors and submit this claim?',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (result) {
          this.onGenerateWithOverride();
        }
      },
      refreshDraft: () => this.onRefreshDraft(),
      refreshAndSubmit: () => this.onRefreshAndSubmit(),
      correctClaim: () => this.onCorrectClaim(),
      voidClaim: () => this.onVoidClaim(),
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasRTSScrubFF = await hasFeatureOrBeta(FEATURE_FLAGS.RTS_SCRUB);
    this.__hasRcmRelease2FF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_RELEASE_2,
    );

    this.__hasCTRevAccelAddOn = await hasAddOn(ADD_ONS.CT_REV_ACCEL);
  }

  scrollToCell({ idToScrollTo }) {
    if (idToScrollTo) {
      this.shadowRoot
        .getElementById(idToScrollTo)
        .scrollIntoView({ behavior: 'smooth' });
    }
  }

  __selectViewStatusCodes({ value: { description } }) {
    return description === 'View claim status codes'
      ? this.scrollToCell({ idToScrollTo: ELEMENTS.claimChargesCell.id })
      : '';
  }

  checkRequestStatusDisplay() {
    const {
      NEEDS_ATTENTION,
      ERROR,
      PENDING,
      RECEIVED,
      TRANSMITTED,
    } = CLAIM_STATUS;

    const {
      isElectronic,
      claimStatuses: [{ status }],
    } = this.model;

    return (
      this.hasMaxClear &&
      isElectronic &&
      [NEEDS_ATTENTION, ERROR, PENDING, RECEIVED, TRANSMITTED].includes(status)
    );
  }

  isRebillable() {
    const { status } = this.model.claimStatuses[0];

    return [
      ...SUBMITTED_CLAIM_STATUSES,
      ...DENIED_AND_REJECTED_CLAIM_STATUSES,
    ].includes(status);
  }

  isRefreshable() {
    const { status } = this.model.claimStatuses[0];

    return NEEDS_ATTENTION_CLAIM_STATUSES.includes(status);
  }

  isDraft() {
    const { status } = this.model.claimStatuses[0];

    return status === CLAIM_STATUS.DRAFT;
  }

  isCorrectable() {
    const { status } = this.model.claimStatuses[0];

    return [
      ...SUBMITTED_CLAIM_STATUSES,
      ...DENIED_AND_REJECTED_CLAIM_STATUSES,
      ...APPROVED_CLAIM_STATUSES,
    ].includes(status);
  }

  isInDraftCancelResubmit() {
    return (
      this.model.resubmissionCode === CLAIM_RESUBMISSION_CODE.CANCEL_RESUBMIT &&
      hasStatus(
        [CLAIM_STATUS.UNSAVED_DRAFT],
        this.model.claimStatuses[0].status,
      )
    );
  }

  isCanceledOrHasResubmissionCode() {
    return (
      this.model.resubmissionCode ||
      hasStatus([CLAIM_STATUS.CANCELED], this.model.claimStatuses[0].status)
    );
  }

  getDateOfCurrentIllnessQualifier() {
    if (this.model.dateOfCurrentIllnessQualifier) {
      return !this.isElectronic &&
        CROSS_WALK.claimFilingIndicator(this.model.claimFilingIndicator) ===
          'Medicare'
        ? ''
        : `Qualifier ${this.model.dateOfCurrentIllnessQualifier}`;
    }

    return '';
  }

  get __FL20Config() {
    return [
      {
        label: '20. Outside Labs?',
        data: this.model.isOutsideLab ? 'Yes' : 'No',
      },
      {
        label: '$ Charges',
        data: this.model.outsideLabChargesAmount,
      },
    ];
  }

  get __FL22Config() {
    return [
      {
        label: '22. Resubmission Code',
        data: this.model.resubmissionCode,
      },
      {
        label: 'Original Reference Number',
        data:
          this.isCanceledOrHasResubmissionCode() ||
          this.isInDraftCancelResubmit()
            ? this.model.originalReferenceNumber
            : '',
      },
    ];
  }

  __statusItems() {
    const chcPayerId = this.payerPlan && this.payerPlan.chcPayerId;

    return filterStatusAvailableToChangeFromTo(
      this.model.isElectronic,
      [this.model.claimStatuses[0].status],
      chcPayerId,
    );
  }

  get __statusDropdownTemplate() {
    const claimStatus = this.model.claimStatuses[0].status;
    const statusItems = this.__statusItems();
    const isDisabled = [
      !statusItems || statusItems.length <= 0,
      isCanceled(claimStatus),
      hasStatus(
        [
          CLAIM_STATUS.READY_TO_DOWNLOAD,
          CLAIM_STATUS.UPLOADING_TO_CLEARINGHOUSE,
        ],
        claimStatus,
      ),
    ].some(condition => condition);

    return !this.__isClaimStatusEditable()
      ? html`
          <div class="buttons-container-right">
            <neb-select
              id="${ELEMENTS.statusDropdown.id}"
              label="Status"
              .items="${statusItems}"
              .value="${claimStatus}"
              .onChange="${this.__handlers.changeStatus}"
              ?disabled="${isDisabled}"
            ></neb-select>
            ${this.__renderAdjustSettingsIcon()}
          </div>
        `
      : '';
  }

  get __signatureTemplate() {
    return this.__signatureSrc
      ? html`
          <img
            id="${ELEMENTS.signatureOfPhysicianImage.id}"
            class="image"
            src="${this.__signatureSrc}"
          />
        `
      : '';
  }

  get __validationAccordionTemplate() {
    const model = this.__buildAccordionModel();

    return html`
      <div class="grid-width-8">
        <neb-category-accordion
          id="${ELEMENTS.validationAccordion.id}"
          class="accordion span-column-3"
          ?hideMargin="${model.length}"
          .model="${model}"
          .onSelectItem="${this.__handlers.selectItem}"
          .onViewRuleSets="${this.__handlers.viewRuleSets}"
        ></neb-category-accordion>
      </div>
    `;
  }

  get __FL9Model() {
    return {
      otherInsuredName: formatClaimNameField('otherInsured', this.model),
      otherInsuredGroupIdNumber: this.model.otherInsuredGroupIdNumber,
      otherInsurancePlanName: this.model.otherInsurancePlanName,
    };
  }

  get __claimStatusLineItems() {
    return claimStatusLineItemsExist(
      this.model.claimStatuses[0].claimStatusLineItems,
    )
      ? this.model.claimStatuses[0].claimStatusLineItems
      : [];
  }

  __isClaimStatusEditable() {
    return isEditable(this.model.claimStatuses[0].status);
  }

  __isClaimCancelable() {
    return isCancelable(this.model.claimStatuses[0].status);
  }

  __isClaimStatusEditableOrTransmitted() {
    if (this.model.isElectronic === undefined) {
      return true;
    }

    if (
      UNEDITABLE_CLAIM_STATUSES.includes(this.model.claimStatuses[0].status)
    ) {
      return false;
    }

    return this.__isClaimStatusEditable();
  }

  __getAdditionalFormatting() {
    const DOD_INFO = this.model.patientDateOfDeath
      ? `V5${formatDateForAdditionalInfo(this.model.patientDateOfDeath)}`
      : '';

    if (!this.model.additionalClaimInformation.includes(DOD_INFO)) {
      this.model.additionalClaimInformation += `${SPACE_DELIMITER}${DOD_INFO}`;
      return `${SPACE_DELIMITER}${DOD_INFO}`.replaceAll(
        '   ',
        '&nbsp;&nbsp;&nbsp;',
      );
    }
    return '';
  }

  __formatAdditionalClaimInfo(htmlString = '') {
    const pElement = document.createElement('p');
    pElement.innerHTML = htmlString.replaceAll('   ', '&nbsp;&nbsp;&nbsp;');
    pElement.innerHTML += this.__getAdditionalFormatting();
    return pElement;
  }

  __formatOtherDate({ qualifier, date }) {
    return qualifier
      ? `${qualifier} (${OTHER_DATE_QUALIFIER[qualifier]}) - ${date}`
      : '';
  }

  __formatOtherId({ qualifier, identifier }) {
    return qualifier && identifier ? `${qualifier} - ${identifier}` : '';
  }

  __formatPracticeInfoErrors(type) {
    return {
      address: Object.values(this.errors[`${type}Address`]).reduce(
        (acc, v) => acc || v,
        '',
      ),
      NPI: this.errors[`${type}NPI`],
      otherId: this.errors[`${type}OtherId`],
      otherIdQualifier: this.errors[`${type}OtherIdQualifier`],
    };
  }

  __buildAccordionModel() {
    const errors = translateErrors(this.errors);
    const notifications = translateNotifications(this.model);
    const warnings = translateWarnings(this.errors);
    const validationErrors =
      this.validationErrors && this.validationErrors.errors
        ? validationErrorFormatter(
            this.validationErrors.errors,
            this.__hasRcmRelease2FF,
          )
        : undefined;

    const scrubbingErrors = this.scrubbingErrors
      ? scrubbingErrorFormatter(this.scrubbingErrors)
      : [];

    const clearinghouseErrors =
      this.clearinghouseErrors && this.clearinghouseErrors.errors
        ? translateClearinghouseErrors(this.clearinghouseErrors.errors)
        : [];

    const chcErrors =
      this.otherChcErrors && this.otherChcErrors.errors
        ? translateClearinghouseErrors(this.otherChcErrors.errors)
        : [];

    const model = [];

    if (notifications.length) {
      model.push({
        name: CATEGORY.NOTIFICATION,
        theme: THEME.INFO,
        icon: 'info',
        items: notifications,
      });
    }

    if (errors.length || clearinghouseErrors.length || chcErrors.length) {
      model.push({
        name: CATEGORY.ERROR,
        theme: THEME.ERROR,
        icon: 'warning',
        items: [...errors, ...clearinghouseErrors, ...chcErrors],
      });
    }

    if (scrubbingErrors.length) {
      model.push({
        name: CATEGORY.SCRUBBING_ERROR,
        theme: THEME.ERROR,
        icon: 'warning',
        items: scrubbingErrors,
      });
    }

    if (validationErrors && validationErrors.length) {
      model.push({
        name: CATEGORY.VALIDATION_ERROR,
        theme: THEME.ERROR,
        icon: 'warning',
        items: validationErrors,
      });
    }

    if (warnings.length) {
      model.push({
        name: CATEGORY.WARNING,
        theme: THEME.WARNING,
        icon: 'warning',
        items: warnings,
      });
    }

    return model;
  }

  __renderAdjustSettingsIcon() {
    return !this.__isClaimStatusEditableOrTransmitted()
      ? html`
          <neb-icon
            id="${ELEMENTS.adjustPrintSettingsIcon.id}"
            class="icon"
            icon="neb:settings"
            @click="${this.__handlers.openPrintSettings}"
          ></neb-icon>
        `
      : '';
  }

  __renderFL19() {
    return html`
      <neb-claim-form-cell-fl-19
        id="${ELEMENTS.fl19Cell.id}"
        class="fl-19"
        .model="${this.model}"
        .onClick="${this.__handlers.clickCell}"
        ?renderExpandIcon="${this.__isClaimStatusEditable()}"
        .errors="${this.errors}"
      ></neb-claim-form-cell-fl-19>
    `;
  }

  __renderFL29() {
    if (
      this.model.paymentResponsibilityLevelCode ===
      PAYMENT_RESPONSIBILITY_LEVEL_CODE.PRIMARY
    ) {
      return html`
        <neb-claim-form-cell
          id="${ELEMENTS.amountPaidCell.id}"
          class="cell"
          label="29. Amount Paid"
          borderRight
          borderBottom
          .data="${
            this.model.fl29AmountPaidOverride ? '$0.00' : this.model.paidAmount
          }"
          type="singleSelect"
          name="fl29AmountPaidOverride"
          .renderExpandIcon="${this.__isClaimStatusEditable()}"
          .onClick="${this.__handlers.clickCell}"
        ></neb-claim-form-cell>
      `;
    }

    return html`
      <neb-claim-form-cell
        id="${ELEMENTS.amountPaidCell.id}"
        class="cell"
        label="29. Amount Paid"
        borderRight
        borderBottom
        .data="${this.model.paidAmount}"
      ></neb-claim-form-cell>
    `;
  }

  __renderGenerateClaimLink() {
    return this.__isClaimStatusEditableOrTransmitted()
      ? html`
          <neb-button-action
            id="${ELEMENTS.generateClaimLink.id}"
            leadingIcon="generateSubmit"
            label="Generate"
            .onClick="${this.__handlers.generateClaim}"
          ></neb-button-action>
        `
      : '';
  }

  __renderScrubClaimLink() {
    return this.__isClaimStatusEditableOrTransmitted() &&
      this.__hasCTRevAccelAddOn &&
      this.__hasRTSScrubFF
      ? html`
          <neb-button-action
            id="${ELEMENTS.scrubClaimLink.id}"
            leadingIcon="scrubClaim"
            label="Scrub Claim"
            .onClick="${this.__handlers.scrubClaim}"
          ></neb-button-action>
        `
      : '';
  }

  __renderPrintClaimLink() {
    return !this.__isClaimStatusEditableOrTransmitted()
      ? html`
          <neb-button-action
            id="${ELEMENTS.printClaimLink.id}"
            leadingIcon="print"
            label="Print Claim"
            .onClick="${this.__handlers.printClaim}"
          ></neb-button-action>
        `
      : '';
  }

  __renderRefreshDraftLink() {
    return this.isDraft()
      ? html`
          <neb-button-action
            id="${ELEMENTS.refreshDraftLink.id}"
            leadingIcon="refresh"
            label="Refresh Draft"
            .onClick="${this.__handlers.refreshDraft}"
          ></neb-button-action>
        `
      : '';
  }

  __renderDeleteClaimLink() {
    return this.model.claimStatuses[0].status === CLAIM_STATUS.DRAFT
      ? html`
          <neb-button-action
            id="${ELEMENTS.deleteClaimLink.id}"
            leadingIcon="minus"
            label="Delete"
            .onClick="${this.__handlers.deleteClaim}"
          ></neb-button-action>
        `
      : '';
  }

  __renderViewHistoryLogLink() {
    return !this.__isClaimStatusEditable()
      ? html`
          <neb-button-action
            id="${ELEMENTS.viewHistoryLogLink.id}"
            leadingIcon="books"
            label="View History Log"
            .onClick="${this.__handlers.viewHistoryLog}"
          ></neb-button-action>
        `
      : [];
  }

  __renderRequestStatusUpdateLink() {
    return this.checkRequestStatusDisplay()
      ? html`
          <neb-button-action
            id="${ELEMENTS.requestStatusUpdateLink.id}"
            leadingIcon="electronicClaims"
            label="Request Status Update"
            .onClick="${this.__handlers.requestStatusUpdate}"
          ></neb-button-action>
        `
      : [];
  }

  __renderRebillLink() {
    return this.isRebillable()
      ? html`
          <neb-button-action
            id="${ELEMENTS.rebillLink.id}"
            leadingIcon="generateSubmit"
            label="Rebill Claim"
            .onClick="${this.__handlers.rebill}"
          ></neb-button-action>
        `
      : [];
  }

  __renderCancelClaimLink() {
    return this.__isClaimCancelable()
      ? html`
          <neb-button-action
            id="${ELEMENTS.cancelClaimLink.id}"
            iconWidth="16px"
            iconHeight="15px"
            leadingIcon="void"
            label="Void/Cancel Claim"
            .onClick="${this.__handlers.voidClaim}"
          ></neb-button-action>
        `
      : [];
  }

  __renderRefreshClaimLink() {
    return this.isRefreshable()
      ? html`
          <neb-button-action
            id="${ELEMENTS.refreshClaimLink.id}"
            leadingIcon="refresh"
            label="Refresh"
            .onClick="${this.__handlers.refreshClaim}"
          ></neb-button-action>
        `
      : [];
  }

  __renderOverrideScrubbingErrorsLink() {
    return Object.values(this.scrubbingErrors).length
      ? html`
          <neb-button-action
            id="${ELEMENTS.overrideScrubbingErrorsLink.id}"
            leadingIcon="overrideScrubbingErrors"
            label="Override Scrubbing Errors and Submit"
            .onClick="${this.__handlers.overrideScrubbingErrors}"
          ></neb-button-action>
        `
      : [];
  }

  __renderRefreshAndSubmitLink() {
    return this.isRefreshable()
      ? html`
          <neb-button-action
            id="${ELEMENTS.refreshAndSubmitLink.id}"
            leadingIcon="refreshAndSubmit"
            label="Refresh and Submit"
            .onClick="${this.__handlers.refreshAndSubmit}"
          ></neb-button-action>
        `
      : [];
  }

  __renderCorrectClaimLink() {
    return this.isCorrectable()
      ? html`
          <neb-button-action
            id="${ELEMENTS.correctClaimLink.id}"
            leadingIcon="correctClaim"
            label="Correct Claim"
            .onClick="${this.__handlers.correctClaim}"
          ></neb-button-action>
        `
      : [];
  }

  __renderActionLinks() {
    return html`
      <div class="container-print">
        <div class="buttons-container-left">
          ${this.__renderRefreshAndSubmitLink()}
          ${this.__renderGenerateClaimLink()} ${this.__renderRefreshDraftLink()}
          ${this.__renderRebillLink()} ${this.__renderCorrectClaimLink()}
          ${this.__renderDeleteClaimLink()} ${this.__renderRefreshClaimLink()}
          ${this.__renderScrubClaimLink()}
          ${this.__renderOverrideScrubbingErrorsLink()}
          ${this.__renderCancelClaimLink()} ${this.__renderViewHistoryLogLink()}
          ${this.__renderRequestStatusUpdateLink()}
          ${this.__renderPrintClaimLink()}
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container"></div>

      <div class="content-container">
        ${this.__validationAccordionTemplate}

        <div class="buttons-container">
          <div class="buttons-container-left">
            ${this.__renderActionLinks()}
          </div>

          ${this.__statusDropdownTemplate}
        </div>

        <div class="grid-width-8 form">
          <neb-claim-form-cell
            id="${ELEMENTS.typeOfHealthCoverageCell.id}"
            class="cell span-column-5"
            label="1. Type of Health Insurance Coverage"
            borderRight
            borderBottom
            .data="${
              CROSS_WALK.claimFilingIndicator(this.model.claimFilingIndicator)
            }"
          >
          </neb-claim-form-cell>

          <neb-claim-form-cell
            id="${ELEMENTS.insuredNumberCell.id}"
            class="cell span-column-3"
            label="1a. Insured's I.D. Number"
            borderBottom
            borderRight
            .data="${this.model.insuredIdNumber}"
            .problemText="${this.errors.insuredIdNumber}"
          >
          </neb-claim-form-cell>

          <neb-claim-form-cell
            id="${ELEMENTS.patientNameCell.id}"
            class="cell span-column-3"
            label="2. Patient's Name (Last Name, First Name, Middle Initial)"
            borderBottom
            .data="${formatClaimNameField('patient', this.model)}"
            .problemText="${this.errors.patientFirstName}"
          >
          </neb-claim-form-cell>

          <neb-claim-form-cell
            id="${ELEMENTS.patientDateOfBirthCell.id}"
            class="cell span-column-2"
            label="3. Patient's Date Of Birth and Sex"
            borderRight
            borderLeft
            borderBottom
            .problemText="${this.errors.patientDateOfBirth}"
          >
            ${
              twoDataCellTemplate({
                firstColumnData: formatDate(this.model.patientDateOfBirth),
                secondColumnData: this.model.patientSex,
              })
            }
          </neb-claim-form-cell>

          <neb-claim-form-cell
            id="${ELEMENTS.insuredNameCell.id}"
            class="cell span-column-3"
            label="4. Insured's Name (Last Name, First Name, Middle Initial)"
            borderBottom
            borderRight
            .data="${formatFL4(this.model)}"
            .problemText="${
              this.errors.insuredFirstName || this.errors.insuredLastName
            }"
            .errors="${this.errors.insuredName}"
          >
          </neb-claim-form-cell>

          <neb-claim-form-cell-address
            id="${ELEMENTS.patientAddressCell.id}"
            class="address"
            label="5. Patient's Address"
            .address="${this.model.patientAddress}"
            .errors="${this.errors.patientAddress}"
          >
          </neb-claim-form-cell-address>

          <neb-claim-form-cell
            id="${ELEMENTS.patientRelationshipCell.id}"
            class="cell span-column-2"
            label="6. Patient's Relationship to Insured"
            borderRight
            borderLeft
            borderBottom
            .data="${
              CROSS_WALK.patientRelationToInsured(
                this.model.insuredRelationToPatient,
              )
            }"
            .problemText="${this.errors.insuredRelationToPatient}"
          >
          </neb-claim-form-cell>

          <neb-claim-form-cell-address
            id="${ELEMENTS.insuredAddressCell.id}"
            class="insured-address"
            label="7. Insured's Address"
            .address="${this.model.insuredAddress}"
            .errors="${this.errors.insuredAddress}"
          >
          </neb-claim-form-cell-address>

          <neb-claim-form-cell
            id="${ELEMENTS.fl8Cell.id}"
            class="cell span-column-2 span-row-2"
            label="8. Reserved for NUCC Use"
            borderRight
            borderLeft
            borderBottom
          >
          </neb-claim-form-cell>

          <neb-claim-form-cell-fl-9
            id="${ELEMENTS.otherInsuredInfoCell.id}"
            class="fl-9"
            .errors="${this.errors}"
            .model="${this.__FL9Model}"
          ></neb-claim-form-cell-fl-9>

          <neb-claim-form-cell-fl-10
            id="${ELEMENTS.patientsConditionRelatedToCell.id}"
            class="fl-10"
            .errors="${this.errors}"
            .model="${this.model}"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          ></neb-claim-form-cell-fl-10>

          <neb-claim-form-cell-fl-11
            id="${ELEMENTS.insuredInfoCell.id}"
            class="fl-11"
            .errors="${this.errors}"
            .model="${this.model}"
            .type="${this.type}"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          ></neb-claim-form-cell-fl-11>

          <neb-claim-form-cell
            id="${ELEMENTS.patientSignatureCell.id}"
            class="cell span-column-5 border"
            label="12. Patient's or Authorized Person's Signature"
            data="${this.model.patientSignature ? 'SOF' : ''}"
            borderRight
          >
          </neb-claim-form-cell>

          <neb-claim-form-cell
            id="${ELEMENTS.insuredSignatureCell.id}"
            class="cell span-column-3 border"
            label="13. Insured's or Authorized Person's Signature"
            data="${this.model.insuredSignature ? 'SOF' : ''}"
            borderRight
          >
          </neb-claim-form-cell>

          <neb-claim-form-cell
            id="${ELEMENTS.dateOfCurrentIllnessCell.id}"
            class="cell span-column-3"
            label="14. Date of Current Illness, Injury, or Pregnancy (LMP)"
            borderBottom
            type="radioDatePicker"
            .onClick="${this.__handlers.clickCell}"
            .problemText="${this.errors.dateOfCurrentIllness}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          >
            ${
              twoDataCellTemplate({
                firstColumnData: formatDate(this.model.dateOfCurrentIllness),
                secondColumnData: this.getDateOfCurrentIllnessQualifier(),
              })
            }
          </neb-claim-form-cell>

          <neb-claim-form-cell
            id="${ELEMENTS.otherDateCell.id}"
            class="cell span-column-2"
            label="15. Other Date"
            type="singleSelect"
            name="otherDate"
            borderRight
            borderLeft
            borderBottom
            .data="${
              this.__formatOtherDate({
                qualifier: this.model.otherDateQualifier,
                date: formatDate(this.model.otherDate),
              })
            }"
            .onClick="${this.__handlers.clickCell}"
            .problemText="${this.errors.otherDate}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          >
          </neb-claim-form-cell>

          <neb-claim-form-cell-date-range
            id="${ELEMENTS.disabilityDatesCell.id}"
            class="cell span-column-3"
            label="16. Dates Patient Unable to Work in Current Occupation"
            borderRight
            .fromDate="${formatDate(this.model.disabilityFromDate)}"
            .toDate="${formatDate(this.model.disabilityToDate)}"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          >
          </neb-claim-form-cell-date-range>

          <neb-claim-form-cell-fl-17
            id="${ELEMENTS.referringProviderCell.id}"
            class="fl-17"
            .model="${this.model}"
            .errors="${this.errors}"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          ></neb-claim-form-cell-fl-17>

          <neb-claim-form-cell-date-range
            id="${ELEMENTS.hospitalizationDatesCell.id}"
            class="cell span-column-3"
            label="18. Hospitalization Dates Related to Current Services"
            borderRight
            .fromDate="${formatDate(this.model.hospitalizationFromDate)}"
            .toDate="${formatDate(this.model.hospitalizationToDate)}"
          >
          </neb-claim-form-cell-date-range>

          ${this.__renderFL19()}

          <neb-claim-form-cell-two-column
            id="${ELEMENTS.outsideLabsCell.id}"
            class="cell span-column-3"
            label="20. Outside Labs?"
            type="outsideLabs"
            .config="${this.__FL20Config}"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          >
          </neb-claim-form-cell-two-column>

          <neb-claim-form-cell-fl-21
            id="${ELEMENTS.claimDiagnosesCell.id}"
            class="fl-21"
            .diagnoses="${this.model.claimDiagnoses.map(({ code }) => code)}"
            .problemText="${this.errors.claimDiagnoses}"
          >
          </neb-claim-form-cell-fl-21>

          <neb-claim-form-cell-two-column
            id="${ELEMENTS.resubmissionCodeCell.id}"
            class="cell span-column-3"
            label="22. Resubmission Code and Original Reference Number"
            name="resubmissionCode"
            type="dropdownTextInput"
            .config="${this.__FL22Config}"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${
              this.__isClaimCancelable() || this.__isClaimStatusEditable()
            }"
            .problemText="${
              this.errors.resubmissionCode ||
                this.errors.originalReferenceNumber
            }"
          >
          </neb-claim-form-cell-two-column>

          <neb-claim-form-cell
            id="${ELEMENTS.priorAuthorizationCell.id}"
            class="cell span-column-3"
            label="23. Prior Authorization Number"
            borderBottom
            borderRight
            name="authorizationNumber"
            type="textInput"
            .data="${this.model.claimAuthorizations[0].authorizationNumber}"
            .problemText="${
              this.errors.claimAuthorizations[0].authorizationNumber
            }"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          >
          </neb-claim-form-cell>

          <neb-claim-form-cell-fl-24
            id="${ELEMENTS.claimChargesCell.id}"
            class="fl-24"
            .claimCharges="${this.model.claimCharges}"
            .errors="${this.errors.claimCharges}"
            .billingProviderNPI="${this.model.billingProviderNPI}"
            .billingProviderOtherId="${this.model.billingProviderOtherId}"
            .billingProviderOtherIdQualifier="${
              this.model.billingProviderOtherIdQualifier
            }"
            .claimStatusLineItems="${this.__claimStatusLineItems}"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          ></neb-claim-form-cell-fl-24>

          <neb-claim-form-cell-fl-25
            id="${ELEMENTS.federalTaxIdCell.id}"
            class="cell span-column-2"
            .model="${this.model}"
            .errors="${this.errors}"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          >
          </neb-claim-form-cell-fl-25>

          <div class="fl-26-27">
            <neb-claim-form-cell
              id="${ELEMENTS.patientsAccountNumberCell.id}"
              class="cell"
              label="26. Patient's Account Number"
              borderRight
              borderBottom
              warn
              .data="${this.model.medicalRecordNumber}"
              .problemText="${this.errors.medicalRecordNumber}"
            >
            </neb-claim-form-cell>

            <neb-claim-form-cell
              id="${ELEMENTS.acceptAssignmentCell.id}"
              class="cell"
              label="27. Accept Assignment?"
              type="yesNo"
              name="acceptAssignment"
              borderRight
              borderBottom
              .data="${this.model.acceptAssignment ? 'Yes' : 'No'}"
              .renderExpandIcon="${this.__isClaimStatusEditable()}"
              .onClick="${this.__handlers.clickCell}"
            >
            </neb-claim-form-cell>
          </div>

          <neb-claim-form-cell
            id="${ELEMENTS.totalChargesCell.id}"
            class="cell"
            label="28. Total Charges"
            borderRight
            borderBottom
            .data="${this.model.totalChargeAmount}"
            .problemText="${this.errors.totalChargeAmount}"
          ></neb-claim-form-cell>

          ${this.__renderFL29()}

          <neb-claim-form-cell
            id="${ELEMENTS.fl30Cell.id}"
            class="cell"
            label="30. Rsvd for NUCC Use"
            borderBottom
            borderRight
          ></neb-claim-form-cell>

          <neb-claim-form-cell
            id="${ELEMENTS.signatureOfPhysicianCell.id}"
            class="cell span-column-2 span-row-2"
            label="31. Signature of Physician"
            .data="${this.signatureSrc ? '' : 'SOF'}"
            borderBottom
            borderRight
            >${this.__signatureTemplate}</neb-claim-form-cell
          >

          <neb-claim-form-cell-practice-info
            id="${ELEMENTS.serviceFacilityInfoCell.id}"
            class="practice-info"
            name="${this.model.serviceFacilityName}"
            label="32. Service Facility Location Information"
            popupTitle="Service Facility Location"
            type="serviceFacility"
            typeB="singleSelect"
            .address="${this.model.serviceFacilityAddress}"
            .errors="${this.__formatPracticeInfoErrors('serviceFacility')}"
            .NPI="${formatServiceFacilityNPI(this.model)}"
            .otherIdentifier="${
              this.__formatOtherId({
                qualifier: this.model.serviceFacilityOtherIdQualifier,
                identifier: this.model.serviceFacilityOtherId,
              })
            }"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          ></neb-claim-form-cell-practice-info>

          <neb-claim-form-cell-practice-info
            id="${ELEMENTS.billingInfoCell.id}"
            class="practice-info"
            name="${this.model.billingProviderPracticeName}"
            label="33. Billing Provider Information and Phone Number"
            popupTitle="Billing Provider"
            type="billingProviderInformation"
            typeA="billingProvider"
            typeB="billingProvider"
            .address="${this.model.billingProviderAddress}"
            .errors="${this.__formatPracticeInfoErrors('billingProvider')}"
            .NPI="${this.model.billingProviderNPI}"
            .otherIdentifier="${
              this.__formatOtherId({
                qualifier: this.model.billingProviderOtherIdQualifier,
                identifier: this.model.billingProviderOtherId,
              })
            }"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
          ></neb-claim-form-cell-practice-info>

          <neb-claim-form-cell
            id="${ELEMENTS.spinalManipulationCell.id}"
            class="span-column-3"
            name="spinalManipulationInfo"
            type="spinalManipulation"
            label="Spinal Manipulation Information"
            .data="${
              spinalManipulationTemplate(
                this.model.spinalManipulationInfoCode,
                this.model.spinalManipulationInfoDescription,
                this.model.spinalManipulationInfoDescription2,
              )
            }"
            .problemText="${
              this.errors.spinalManipulationInfoCode ||
                this.errors.spinalManipulationInfoDescription
            }"
            .onClick="${this.__handlers.clickCell}"
            ?renderExpandIcon="${this.__isClaimStatusEditable()}"
            borderBottom
            footerBreak
            borderRight
          ></neb-claim-form-cell>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-claim-form-view', NebClaimFormView);
