import { reverseClaim } from '../../../../packages/neb-api-client/src/allocation-api-client';
import { updateERAReport } from '../../../../packages/neb-api-client/src/era-report-api-client';

const getClaimErrorMessage = claim => {
  if (Array.isArray(claim.errorMessage) && claim.errorMessage.length) {
    return claim.errorMessage.filter(
      message => message !== 'Claim is a reversal.',
    );
  }

  return '';
};

const removeWarningsFromReportDataByClaimId = (claimReportId, reportData) => ({
  ...reportData,
  claims: reportData.claims.map(claim => {
    if (claim.claimReportId === claimReportId) {
      return {
        ...claim,
        errorMessage: getClaimErrorMessage(claim),
      };
    }

    return claim;
  }),
});

const removeWarningsFromClaim = async (eraId, claimReportId, reportData) => {
  const newReportData = removeWarningsFromReportDataByClaimId(
    claimReportId,
    reportData,
  );

  await updateERAReport(eraId, { reportData: newReportData });
};

export default async ({
  model: { id: eraId },
  paymentId,
  selectedPaymentIds,
  remainderAmount,
  claimId,
  claimReportId,
  reportData,
}) => {
  await reverseClaim(paymentId, selectedPaymentIds, remainderAmount, claimId);

  await removeWarningsFromClaim(eraId, claimReportId, reportData);
};
