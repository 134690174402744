import ApiClient, {
  Method,
  RESPONSE_TYPE,
  getTenantShortName,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../packages/neb-utils/feature-util';

export const apiClient = new ApiClient();
export const coreApiClient = new ApiClientV2({ microservice: 'core' });

export const CACHE_KEY = 'tenant-logo';
export const PATH = 'logo';

export async function fetch() {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      cacheKey: CACHE_KEY,
      path: '/api/v1/tenants/:tenantId/logo',
      responseType: RESPONSE_TYPE.DATA_URL,
    });
  }

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    cacheKey: CACHE_KEY,
    path: PATH,
    responseType: RESPONSE_TYPE.DATA_URL,
  });
}

export async function fetchPublic() {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      cacheKey: CACHE_KEY,
      path: '/public/v1/:shortName/logo',
      replacements: { shortName: getTenantShortName() },
      responseType: RESPONSE_TYPE.DATA_URL,
    });
  }

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    cacheKey: CACHE_KEY,
    path: PATH,
    responseType: RESPONSE_TYPE.DATA_URL,
    version: 'public1',
  });
}

export async function remove() {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      cacheKey: CACHE_KEY,
      method: Method.DELETE,
      path: '/api/v1/tenants/:tenantId/logo',
      responseType: RESPONSE_TYPE.RAW,
    });
  }

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    cacheKey: CACHE_KEY,
    method: Method.DELETE,
    path: PATH,
    responseType: RESPONSE_TYPE.RAW,
  });
}

export async function update(blob) {
  if (await hasFeatureOrBeta(FEATURE_FLAGS.API_DEPRECATION)) {
    return coreApiClient.makeRequest({
      optOutLoadingIndicator: false,
      body: blob,
      cacheKey: CACHE_KEY,
      headers: {
        'Content-Type': blob.type,
      },
      method: Method.PUT,
      path: '/api/v1/tenants/:tenantId/logo',
      responseType: RESPONSE_TYPE.RAW,
    });
  }

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: blob,
    cacheKey: CACHE_KEY,
    headers: {
      'Content-Type': blob.type,
    },
    method: Method.PUT,
    path: PATH,
    responseType: RESPONSE_TYPE.RAW,
  });
}
