/* eslint-disable complexity */
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import deepEqual from 'fast-deep-equal';
import { html, css } from 'lit';

import {
  openWarning,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_SPACING_ROW_LARGE,
  CSS_WARNING_COLOR,
} from '../../../packages/neb-styles/neb-variables';
import {
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
  hasAuthorizationRemaining,
  filterAuthorizationsFromCases,
} from '../../../packages/neb-utils/patientAuthorization';
import * as selectors from '../../../packages/neb-utils/selectors';
import {
  verifyAssociatedInvoices,
  formatGradualDate,
} from '../../features/charting/neb-charting-encounter-header-util';
import { getPatientActiveCases } from '../../features/charting/neb-charting-util';
import { CSS_FONT_WEIGHT_BOLD } from '../../styles';
import { errorOccurred } from '../../utils/user-message';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  buttonConfirm: { id: 'button-confirm' },
  buttonCancel: { id: 'button-cancel' },
  addNewCase: { id: 'add-new-case' },
  caseSelect: { id: 'case-select' },
  authorization: { id: 'authorization' },
  iconAuthorizationWarning: {
    id: 'icon-authorization-warning',
  },
};

export default class NebFormUpdateAssociatedCase extends NebForm {
  static get properties() {
    return {
      __activeCases: Array,
      __cases: Array,
      __displayAuthorizations: Array,
      __selectedAuthorization: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow-y: unset;
          min-height: unset;
        }

        .spacing {
          margin-right: ${CSS_SPACING};
        }

        .textfield {
          width: 100%;
        }

        .form {
          display: flex;
          row-gap: 8px;
          flex-direction: column;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          margin: ${CSS_SPACING_ROW_LARGE} 0;
        }

        .select {
          display: flex;
          width: 100%;
          margin-bottom: 14px;
        }

        .container-select {
          display: flex;
          align-items: center;
          width: 100%;
          height: fit-content;
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        .label {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .add-case-button {
          max-width: fit-content;
        }

        .authorization-field {
          margin-top: ${CSS_SPACING};
        }

        .icon-authorization-warning {
          margin: 0 0 12px 10px;
          display: block;
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_WARNING_COLOR};
        }
      `,
    ];
  }

  static createModel() {
    return {
      caseId: selectors.ITEM_EMPTY,
      patientAuthorizationId: '',
      patientId: '',
      encounterIds: [],
      enableSave: false,
      isFromCharges: false,
    };
  }

  createSelectors() {
    return {
      children: {
        caseId: selectors.select(this.__cases, this.__getDefaultCase()),
      },
    };
  }

  initState() {
    super.initState();

    this.__activeCases = [];
    this.__cases = [];
    this.__displayAuthorizations = [];
    this.__selectedAuthorization = null;

    this.onAddNewCase = () => {};

    this.onUpdateCase = () => {};

    this.onCancel = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addNewCase: () => this.onAddNewCase(),
      save: async () => {
        if (this.model.isFromCharges) {
          return this.onUpdateCase({
            selectedCase: this.state.caseId,
            selectedPatientAuthorizationId: this.state.patientAuthorizationId,
          });
        }

        const removeCase =
          !this.state.caseId.label && this.model.encounterIds.length > 1;
        const warningMessageResult = await verifyAssociatedInvoices(
          this.model.encounterIds,
          removeCase,
        );
        if (!warningMessageResult) return false;

        return this.onUpdateCase({
          selectedCase: this.state.caseId,
          selectedPatientAuthorizationId: this.state.patientAuthorizationId,
        });
      },
      cancel: () => this.onCancel(),
      changeCase: e => {
        if (e.value !== this.state.caseId) {
          this.handlers.change(e);
          this.__changeAuthorization(e.value);
        }
      },
      authorizationWarningClick: () => this.__showAuthorizationWarning(),
      updateAuthorization: ({ value: authorization }) => {
        if (!authorization) {
          return;
        }

        if (
          this.__selectedAuthorization &&
          this.__selectedAuthorization.id === authorization.id
        ) {
          return;
        }

        const selectedCase = this.__cases.find(
          c => c.data.id === authorization.patientCaseId,
        );

        if (selectedCase.data.id !== this.state.caseId) {
          this.handlers.change({
            name: 'caseId',
            value: {
              label: selectedCase.label,
              data: { id: selectedCase.data.id },
            },
          });
        }

        this.__changeAuthorization(selectedCase, true, authorization);
      },
    };
  }

  async load() {
    try {
      this.__activeCases = await getPatientActiveCases(this.model.patientId);

      if (this.__activeCases?.length) {
        this.__cases = [
          selectors.ITEM_EMPTY,
          ...this.__activeCases.map(c => ({
            data: c,
            label: `${c.name} - ${formatGradualDate(c.onsetSymptomsDate)}`,
          })),
        ];

        let allAuthorizations;

        if (
          this.__activeCases.length &&
          deepEqual(this.model.caseId, selectors.ITEM_EMPTY)
        ) {
          this.__displayAuthorizations = filterAuthorizationsFromCases(
            this.__activeCases,
          );
        } else {
          allAuthorizations = filterAuthorizationsFromCases(this.__activeCases);

          this.__displayAuthorizations = allAuthorizations.filter(
            auth => auth.patientCaseId === this.model.caseId,
          );
        }

        if (this.model.patientAuthorizationId) {
          this.__changeAuthorization(
            this.__cases.find(c => c.data.id === this.model.caseId),
            false,
            this.__displayAuthorizations.find(
              authorization =>
                authorization.id === this.model.patientAuthorizationId,
            ),
          );
        }
      }
    } catch (e) {
      openError(errorOccurred('loading', 'active cases'));
    }
  }

  __changeAuthorization(caseInfo, showMessage = true, authorization = null) {
    if (caseInfo.data && caseInfo.data.id) {
      const allAuthorizations = filterAuthorizationsFromCases(
        this.__activeCases,
      );

      this.__displayAuthorizations = allAuthorizations.filter(
        auth => auth.patientCaseId === caseInfo.data.id,
      );

      this.__selectedAuthorization =
        authorization ||
        this.__displayAuthorizations.find(
          auth => auth.patientCaseId === caseInfo.data.id,
        );

      if (this.__selectedAuthorization) {
        this.formService.apply(
          'patientAuthorizationId',
          this.__selectedAuthorization.id,
        );
      } else {
        this.formService.apply('patientAuthorizationId', null);
      }

      if (
        !hasAuthorizationRemaining(this.__selectedAuthorization) &&
        showMessage
      ) {
        this.__showAuthorizationWarning();
      }
    } else {
      this.__selectedAuthorization = null;

      this.formService.apply('patientAuthorizationId', null);

      this.__displayAuthorizations = filterAuthorizationsFromCases(
        this.__activeCases,
      );
    }
  }

  __showAuthorizationWarning() {
    store.dispatch(openWarning(NO_REMAINING_AUTHORIZATIONS_MESSAGE));
  }

  __getDefaultCase() {
    if (!this.__cases || !this.__cases.length || !this.model.caseId) {
      return selectors.ITEM_EMPTY;
    }

    return this.__cases.find(item => item.data.id === this.model.caseId);
  }

  __disableSaveButton() {
    return !this.__dirty && !this.model.enableSave;
  }

  renderActionBar() {
    return html`
      <div class="button-container">
        <neb-button
          id="${ELEMENTS.buttonConfirm.id}"
          class="button"
          label="SAVE"
          ?disabled="${this.__disableSaveButton()}"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.save}"
        ></neb-button>

        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          class="button"
          label="CANCEL"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  __renderAuthorizationField() {
    return html`
      <div class="container-select">
        <neb-select
          id="${ELEMENTS.authorization.id}"
          class="select"
          name="patientAuthorizationId"
          label="Authorization"
          helper=" "
          .items="${this.__displayAuthorizations}"
          .value="${this.__selectedAuthorization}"
          .onChange="${this.handlers.updateAuthorization}"
          showTitle
        ></neb-select>
        ${
          hasAuthorizationRemaining(this.__selectedAuthorization)
            ? ''
            : html`
                <neb-icon
                  id="${ELEMENTS.iconAuthorizationWarning.id}"
                  class="icon-authorization-warning"
                  icon="neb:warning"
                  @click="${this.handlers.authorizationWarningClick}"
                ></neb-icon>
              `
        }
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="form">
        <neb-button-action
          id="${ELEMENTS.addNewCase.id}"
          class="add-case-button"
          label="Add Case"
          .onClick="${this.handlers.addNewCase}"
        ></neb-button-action>

        <neb-select
          id="${ELEMENTS.caseSelect.id}"
          class="textfield"
          name="caseId"
          label="Case"
          .items="${this.__cases}"
          .value="${this.state.caseId}"
          .onChange="${this.handlers.changeCase}"
          wrapText
        ></neb-select>

        ${this.__renderAuthorizationField()}
      </div>
    `;
  }
}

customElements.define(
  'neb-form-update-associated-case',
  NebFormUpdateAssociatedCase,
);
