import '../../../../../neb-material-design/src/components/neb-md-textarea';
import '../../inputs/neb-select';
import '../../inputs/neb-textfield';
import '../../neb-checkbox-old';
import '../../neb-tooltip';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import {
  PACKAGE_TYPE,
  PAYMENT_FREQUENCY_OPTIONS,
} from '../../../../../neb-utils/enums';
import { currency, number } from '../../../../../neb-utils/masks';

export const ELEMENTS = {
  totalVisitsField: {
    id: 'input-visits-total',
  },
  totalPriceField: {
    id: 'input-visits-price',
  },
  frequencyDropdown: {
    id: 'dropdown-frequency',
  },
  unlimitedCheckbox: {
    id: 'checkbox-unlimited',
  },
  unlimitedTooltip: {
    id: 'tooltip-unlimited',
  },
};
export const MODEL_KEYS = {
  FREQUENCY: 'frequency',
  UNLIMITED: 'unlimited',
  TOTAL_PRICE: 'totalPrice',
  TOTAL_VISITS: 'totalVisits',
};

const ZERO_VALUE_CURRENCY = '$0.00';

class NebPackageTemplateLimitsForm extends LitElement {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      __errors: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {
      type: 'visit',
      totalVisits: '',
      totalPrice: '',
      frequency: '',
      unlimited: false,
    };

    this.__errors = {
      frequencyDropdown: null,
      totalVisits: null,
      totalPrice: null,
    };

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      blur: ({ name, value }) => {
        if (!value) {
          this.__errors = {
            ...this.__errors,
            [name]: true,
          };
        }
      },
      changeFrequency: ({ value }) =>
        this.onChange({
          key: MODEL_KEYS.FREQUENCY,
          value,
        }),
      changeTotalVisits: ({ value }) => {
        if (value && value > 0) {
          this.onChange({
            key: MODEL_KEYS.TOTAL_VISITS,
            value,
          });

          delete this.__errors[MODEL_KEYS.TOTAL_VISITS];
        } else {
          this.__errors = {
            ...this.__errors,
            [MODEL_KEYS.TOTAL_VISITS]: true,
          };
        }
      },
      changeTotalPrice: ({ value }) => {
        if (value && value !== ZERO_VALUE_CURRENCY) {
          this.onChange({
            key: MODEL_KEYS.TOTAL_PRICE,
            value,
          });

          delete this.__errors[MODEL_KEYS.TOTAL_PRICE];
        } else {
          this.__errors = {
            ...this.__errors,
            [MODEL_KEYS.TOTAL_PRICE]: true,
          };
        }
      },
      toggleUnlimited: value => {
        this.onChange({
          key: MODEL_KEYS.TOTAL_VISITS,
          value: '',
        });

        this.onChange({
          key: MODEL_KEYS.UNLIMITED,
          value,
        });
      },
    };
  }

  __validateTotalPrice() {
    const totalPriceObj = { name: 'totalPrice', value: this.model.totalPrice };
    this.__handlers.blur(totalPriceObj);

    return !this.__errors.totalPrice;
  }

  __validateTotalVisits() {
    const totalVisitsObj = {
      name: 'totalVisits',
      value: this.model.totalVisits,
    };
    this.__handlers.blur(totalVisitsObj);

    return !this.__errors.totalVisits;
  }

  async __validateVisitFields() {
    const validPromise = [];

    if (!this.model.unlimited) {
      validPromise.push(this.__validateTotalVisits());
    }

    if (this.model.subscription) {
      this.__errors.frequencyDropdown = !this.model.frequency;
      validPromise.push(!this.__errors.frequencyDropdown);
    }

    const validValues = await Promise.all(validPromise);

    if (this.model.unlimited) {
      validValues.push(this.model.unlimited);
    }

    return validValues.every(v => !!v);
  }

  __validateServiceFields() {
    if (!this.model.subscription) return true;
    this.__errors.frequencyDropdown = !this.model.frequency;
    return !this.__errors.frequencyDropdown;
  }

  async validate() {
    const validValues = [this.__validateTotalPrice()];

    if (this.model.type === PACKAGE_TYPE.VISIT) {
      validValues.push(await this.__validateVisitFields());
    } else {
      validValues.push(await this.__validateServiceFields());
    }

    return validValues.every(v => !!v);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .section {
          display: grid;
          margin: ${CSS_SPACING};
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }

        .section[subscription][type='visit'] {
          grid-template-columns: 1fr 1fr 1fr;
          grid-row-gap: 5px;
        }

        .divider-horizontal {
          grid-template-columns: auto 1fr;
        }

        .divider {
          display: grid;
          grid-gap: ${CSS_SPACING};
          align-items: center;
        }

        .box-offset {
          position: relative;
          left: -10px;
        }

        .tooltip {
          margin-right: auto;
        }
      `,
    ];
  }

  __renderTotalVisitsField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.totalVisitsField.id}"
        name="totalVisits"
        label="${this.model.subscription ? '' : 'Total '}Visits"
        helper="Required"
        maxLength="3"
        .inputMode="${'numeric'}"
        required
        .error="${
          this.__errors.totalVisits ? 'Required, must be between 1 - 999' : ''
        }"
        .mask="${number}"
        .value="${this.model.totalVisits.toString()}"
        .onChange="${this.__handlers.changeTotalVisits}"
        .onBlur="${this.__handlers.blur}"
        ?disabled="${this.model.unlimited}"
      ></neb-textfield>
    `;
  }

  __renderTotalPriceField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.totalPriceField.id}"
        name="totalPrice"
        label="${this.model.subscription ? '' : 'Total '}Price"
        helper="Required"
        maxLength="10"
        .inputMode="${'numeric'}"
        .error="${
          this.__errors.totalPrice
            ? 'Required, must be between $0.01 and $99,999.99'
            : ''
        }"
        .mask="${currency}"
        .value="${this.model.totalPrice}"
        .onChange="${this.__handlers.changeTotalPrice}"
        .onBlur="${this.__handlers.blur}"
      ></neb-textfield>
    `;
  }

  __renderFrequencyDropDown() {
    return html`
      <neb-select
        id="${ELEMENTS.frequencyDropdown.id}"
        label="Frequency"
        helper="Required"
        .items="${PAYMENT_FREQUENCY_OPTIONS}"
        .value="${this.model.frequency}"
        .error="${this.__errors.frequencyDropdown}"
        .onChange="${this.__handlers.changeFrequency}"
      ></neb-select>
    `;
  }

  __renderVisitSubscriptionFields() {
    return html`
      ${this.__renderFrequencyDropDown()}
      <div id="unlimited" class="divider-horizontal divider box-offset">
        <neb-checkbox-old
          id="${ELEMENTS.unlimitedCheckbox.id}"
          label="Unlimited"
          .onClick="${this.__handlers.toggleUnlimited}"
          ?checked="${this.model.unlimited}"
          .value="${this.model.unlimited}"
        ></neb-checkbox-old>

        <neb-tooltip
          class="tooltip"
          defaultAnchor="right"
          id="${ELEMENTS.unlimitedTooltip.id}"
        >
          <p slot="tooltip">
            Optionally enter 'Total Visits' to limit the number of visits
            allowed, or check 'Unlimited'.
          </p>
        </neb-tooltip>
      </div>
      <div></div>
    `;
  }

  __renderServiceSubscriptionFields() {
    return html`
      ${this.__renderFrequencyDropDown()}
      <div></div>
    `;
  }

  __renderServiceBasedLimits() {
    return html`
      ${this.__renderTotalPriceField()}
      ${this.model.subscription ? this.__renderServiceSubscriptionFields() : ''}
    `;
  }

  __renderVisitBasedLimits() {
    return html`
      ${this.__renderTotalVisitsField()} ${this.__renderTotalPriceField()}
      ${this.model.subscription ? this.__renderVisitSubscriptionFields() : ''}
    `;
  }

  render() {
    return html`
      <div
        class="section"
        type="${this.model.type}"
        ?subscription="${this.model.subscription}"
      >
        ${
          this.model.type === PACKAGE_TYPE.VISIT
            ? this.__renderVisitBasedLimits()
            : this.__renderServiceBasedLimits()
        }
      </div>
    `;
  }
}

customElements.define(
  'neb-package-template-limits-form',
  NebPackageTemplateLimitsForm,
);
