import { css } from 'lit';

import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
};

class NebOverlayClaimErrorPayer extends NebOverlayClaimError {
  static get properties() {
    return {};
  }

  initState() {
    super.initState();

    this.widthRatio = '60/40';
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
    };
  }

  static get styles() {
    return [super.styles, css``];
  }
}

customElements.define(
  'neb-overlay-claim-error-payer',
  NebOverlayClaimErrorPayer,
);
