import { LitElement, html, css } from 'lit';

import '../../misc/neb-icon';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import { sort, SORT_BY, SORT_DIR } from '../../../utils/sort/sort';

export const ELEMENTS = {
  searchTextfield: { id: 'search-text-field' },
  tagSelect: { id: 'tag-select' },
};

export class NebDocumentsFilters extends LitElement {
  static get properties() {
    return {
      __filters: Object,

      tags: Array,
      model: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__filters = {
        ...this.__filters,
        ...this.model,
      };
    }

    super.updated();
  }

  __initState() {
    this.tags = [];
    this.model = {};

    this.__filters = {
      search: '',
      tags: [],
    };

    this.onChange = () => {};
  }

  __initHandlers() {
    this.handlers = {
      clearSearch: () => {
        this.__filters = {
          ...this.__filters,
          search: '',
        };

        this.onChange(this.__filters);
      },
      change: ({ name, value }) => {
        this.__filters = {
          ...this.__filters,
          [name]: value,
        };

        this.onChange(this.__filters);
      },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          width: 100%;
          padding: 10px;
        }

        .container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 10px;
        }
      `,
    ];
  }

  __buildTags() {
    const tags = this.tags.map(tag => ({
      label: tag.name,
      ...tag,
    }));

    return sort({
      array: tags,
      key: 'label',
      by: SORT_BY.NAME,
      dir: SORT_DIR.ASC,
    });
  }

  __renderSearchFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.searchTextfield.id}"
        name="search"
        label="Search"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.__filters.search ? 'neb:clear' : ''}"
        .value="${this.__filters.search}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearSearch}"
      ></neb-textfield>
    `;
  }

  __renderTags() {
    const items = this.__buildTags();

    return html`
      <neb-select
        id="${ELEMENTS.tagSelect.id}"
        name="tags"
        label="Tags"
        multiSelect="true"
        .disabled="${!items.length}"
        .items="${items}"
        .value="${this.__filters.tags}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderSearchFilter()}${this.__renderTags()}
      </div>
    `;
  }
}

customElements.define('neb-documents-filters', NebDocumentsFilters);
