import '../inputs/neb-textfield';
import './neb-modifiers';
import '../../../../../src/components/controls/inputs/neb-checkbox';

import { isRequired, hasLength } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css, LitElement, unsafeCSS } from 'lit';

import {
  getDiagnosisItemHeight,
  ITEM_MIN_WIDTH,
} from '../../../../../src/utils/diagnoses';
import { isDosageInRange } from '../../../../../src/utils/national-drug-code';
import { getChartingPermissions } from '../../../../neb-api-client/src/permissions-api-client';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import {
  getSupplementalInfoModel,
  getOrderingProviderInfoModel,
} from '../../../../neb-utils/claims';
import { parseDate } from '../../../../neb-utils/date-util';
import { getTextWidth } from '../../../../neb-utils/dom';
import { objToName } from '../../../../neb-utils/formatters';
import { number } from '../../../../neb-utils/masks';
import {
  CODE_TYPE,
  LINE_ITEM_TYPE,
} from '../../../../neb-utils/neb-ledger-util';
import * as selectors from '../../../../neb-utils/selectors';

export const CONFIG = () => [
  {
    label: 'Provider',
    width: 96,
  },
  {
    label: 'Location',
    width: 150,
  },
  {
    label: 'Dx',
    width: 150,
  },
  {
    label: 'DOS',
    width: 96,
  },
  {
    label: 'POS',
    width: 80,
  },
  {
    label: 'Proc',
    width: 150,
  },
  {
    label: 'Modifiers',
    width: 210,
  },
  {
    label: '',
    width: 80,
  },
];

const modifierSelector = {
  validateManually: true,
  validators: [
    {
      error: 'Modifiers must be two characters',
      validate: v => !v || v.match(/^([0-9a-zA-Z]){2}$/),
    },
  ],
};

export const ELEMENTS = {
  provider: { id: 'provider' },
  location: { id: 'location' },
  diagnosisDropdown: { id: 'diagnosis-dropdown' },
  dateOfService: { id: 'date-of-service' },
  posField: { id: 'pos-field' },
  procedureCode: { id: 'procedure-code' },
  modifiers: { id: 'modifiers' },
  shaded24Field: { id: 'shaded-24-field' },
  ndcCheckbox: { id: 'ndc-checkbox' },
  supplementalInfoCheckbox: { id: 'supplemental-info-checkbox' },
  textEditNationalDrugCode: { id: 'text-edit-national-drug-code' },
  textEditSupplementalInfo: { id: 'text-edit-supplemental-info' },
  textNoEncounterDx: { id: 'text-no-encounter-dx' },
  orderingProviderCheckbox: { id: 'ordering-provider-checkbox' },
  textEditOrderingProvider: {
    id: 'text-edit-ordering-provider',
  },
  attributeToDropdown: { id: 'attribute-to-dropdown' },
};

const formatDiagnoses = d => ({
  label: `${d.code} - ${d.description}`,
  item: { diagnosisCode: d.code },
});

const EMPTY_NDC_ITEM = { label: '', data: { id: null } };

const ndcFieldsSelector = [
  {
    error: 'Required',
    validate: (value, _, state) => {
      if (state.ndc) {
        return !!value;
      }

      return true;
    },
  },
];

export class NebProviderModifiersCell extends LitElement {
  static get properties() {
    return {
      lineItemType: String,
      name: String,
      attributeToItems: Array,
      diagnosisItems: Array,
      model: Object,
      errors: Object,
    };
  }

  static get styles() {
    const COLUMN_WIDTHS = unsafeCSS(
      CONFIG()
        .map(v => `${v.width}px`)
        .join(' '),
    );

    return [
      baseStyles,
      css`
        :host {
          --column-widths: ${COLUMN_WIDTHS};
        }

        .container {
          display: grid;
          grid-gap: 0 ${CSS_SPACING};
          grid-template-columns: var(--column-widths);
          align-items: center;
        }

        .span-2 {
          grid-column: span 2;
        }

        .span-3 {
          grid-column: span 3;
        }
      `,
    ];
  }

  static createModel() {
    return {
      encounterId: '',
      providerId: '',
      location: '',
      diagnosisPointers: [],
      dateOfService: '',
      pos: '',
      code: { name: '', type: '' },
      modifiers: ['', '', '', ''],
      shaded24: '',
      ndc: false,
      orderingProvider: false,
      reportTypeCode: { label: '', data: { id: null } },
      reportTransmissionCode: { label: '', data: { id: null } },
      codeQualifier: { label: '', data: { id: null } },
      identificationNumber: null,
      supplementalInformation: false,
      attributeToId: {
        data: { id: '' },
        label: '',
      },
      nationalDrugCodeQualifier: EMPTY_NDC_ITEM,
      nationalDrugCode: null,
      nationalDrugCodeDosage: null,
      nationalDrugCodeUnitOfMeasurement: EMPTY_NDC_ITEM,
      nationalDrugCodeNumberCategory: EMPTY_NDC_ITEM,
      nationalDrugCodeSequenceOrPrescription: null,
    };
  }

  static createSelectors(itemsMap) {
    const POS_ERROR =
      'Place of Service (POS) is required and must be two digits';

    return {
      providerId: selectors.select(itemsMap.users, selectors.ITEM_EMPTY, {
        validators: [],
      }),
      encounterCharge: {
        children: {
          diagnosisPointers: {
            unsafe: true,
            clipPristine: true,
            format: v => {
              const valueArray = v.length
                ? v.map(({ diagnosisCode }) =>
                    itemsMap.dx.find(dx => dx.code === diagnosisCode)
                      ? formatDiagnoses(
                          itemsMap.dx.find(dx => dx.code === diagnosisCode),
                        )
                      : '',
                  )
                : [];
              return valueArray;
            },
            unformat: v => v.map(dx => dx.item.diagnosisCode),
          },
        },
      },
      purchase: {
        children: {
          attributeToUserId: selectors.select(
            itemsMap.users,
            selectors.ITEM_EMPTY,
            {
              validators: [],
            },
          ),
        },
      },
      pos: {
        validateManually: true,
        validators: [isRequired(POS_ERROR), hasLength(2, POS_ERROR)],
        format: v => v.toString(),
      },

      reportTypeCode: [
        {
          error: 'Supplemental Info is required',
          validate: (value, keyPath, state) => {
            const index = keyPath[1];

            if (state.items[index].supplementalInformation) {
              return !!value;
            }
            return true;
          },
        },
      ],

      reportTransmissionCode: [
        {
          error: 'Supplemental Info is required',
          validate: (value, keyPath, state) => {
            const index = keyPath[1];

            if (state.items[index].supplementalInformation) {
              return !!value;
            }
            return true;
          },
        },
      ],

      orderingProviderFirstName: [
        {
          error: 'Ordering Provider Info is required',
          validate: (value, keyPath, state) => {
            const index = keyPath[1];

            if (state.items[index].orderingProvider) {
              return !!value;
            }
            return true;
          },
        },
      ],

      orderingProviderLastName: [
        {
          error: 'Ordering Provider Info is required',
          validate: (value, keyPath, state) => {
            const index = keyPath[1];

            if (state.items[index].orderingProvider) {
              return !!value;
            }
            return true;
          },
        },
      ],

      nationalDrugCodeQualifier: [
        {
          error: 'NDC Info is required',
          validate: (value, keyPath, state) => {
            const index = keyPath[1];

            if (state.items[index].ndc) {
              return !!value;
            }
            return true;
          },
        },
      ],

      nationalDrugCode: [
        {
          error: 'NDC Info is required',
          validate: (value, _, state) => {
            if (state.ndc) {
              return !!value;
            }

            return true;
          },
        },
      ],
      nationalDrugCodeDosage: [...ndcFieldsSelector, isDosageInRange()],

      nationalDrugCodeUnitOfMeasurement: [
        {
          error: 'NDC Info is required',
          validate: (value, keyPath, state) => {
            const index = keyPath[1];

            if (state.items[index].ndc) {
              return !!value;
            }
            return true;
          },
        },
      ],

      modifier_1: modifierSelector,
      modifier_2: modifierSelector,
      modifier_3: modifierSelector,
      modifier_4: modifierSelector,
    };
  }

  static buildConfig(config, providers) {
    let width = 96;

    providers.forEach(provider => {
      const providerName = provider
        ? objToName(provider.name, {
            reverse: true,
            middleInitial: true,
          })
        : '';
      const providerWidth = getTextWidth(providerName);
      width = Math.max(providerWidth, width);
    });

    const [providerConfig, ...rest] = config;

    return [{ ...providerConfig, width }, ...rest];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.lineItemType = '';
    this.name = '';
    this.attributeToItems = [];
    this.diagnosisItems = [];
    this.model = NebProviderModifiersCell.createModel();
    this.errors = NebProviderModifiersCell.createModel();

    this.onChange = () => {};

    this.onBlur = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      openNdcPopup: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.NATIONAL_DRUG_CODE,
          this.model,
        );

        if (result) {
          const [index] = this.name.split('.');

          Object.entries(result).forEach(([key, value]) => {
            if (key !== 'ndc') {
              this.onChange({
                name: `${index}.${key}`,
                value,
              });
            }
          });
        }
      },
      openOrderingProviderPopup: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.ORDERING_PROVIDER,
          getOrderingProviderInfoModel(this.model),
        );

        if (result) {
          const [index] = this.name.split('.');
          Object.entries(result).forEach(([name, value]) =>
            this.onChange({ name: `${index}.${name}`, value }),
          );
        }
      },
      openSupplementalInfoPopup: async eventModel => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.SUPPLEMENTAL_INFORMATION,
          getSupplementalInfoModel(eventModel),
        );

        if (result) {
          const [index] = this.name.split('.');
          Object.entries(result).forEach(([name, value]) =>
            this.onChange({ name: `${index}.${name}`, value }),
          );
        }
      },
      change: e => {
        const [index] = this.name.split('.');
        this.onChange({ ...e, name: `${index}.${e.name}` });
      },
      blur: e => {
        const [index] = this.name.split('.');
        this.onBlur({ ...e, name: `${index}.${e.name}` });
      },
      changeDx: e => {
        const [index] = this.name.split('.');
        const namespace = `${index}.${e.name}`;

        if (e.event === 'select') {
          this.onChange({ ...e, name: namespace });
        }

        if (e.event === 'blur') {
          this.onChange({ ...e, name: namespace });
          this.onBlur({ ...e, name: namespace });
        }
      },
      changeModifiers: e => {
        const [index] = this.name.split('.');
        const modifierIndex = Number(e.name.split('.')[1]);
        const namespace = `${index}.modifier_${modifierIndex + 1}`;

        this.onChange({ ...e, name: namespace });
      },
      changeNdcSelect: e => {
        const [index] = this.name.split('.');
        const value = { ...e.value, data: { id: e.value.data.id || null } };

        this.onChange({ ...e, name: `${index}.${e.name}`, value });
      },

      blurModifiers: e => {
        const [index] = this.name.split('.');
        const modifierIndex = Number(e.name.split('.')[1]);
        const namespace = `${index}.modifier_${modifierIndex + 1}`;

        this.onBlur({ ...e, name: namespace });
      },
    };
  }

  __getNonEditable() {
    return !getChartingPermissions() && this.model.encounterCharge.signed;
  }

  __renderLocation(model) {
    if (model.location) {
      return html`
        <span id="${ELEMENTS.location.id}">${model.location}</span>
      `;
    }

    return html`
      <span></span>
    `;
  }

  __renderNdcLinkAndCheckbox(model) {
    return html`
      <neb-checkbox
        id="${ELEMENTS.ndcCheckbox.id}"
        label="NDC"
        name="ndc"
        class="span-1"
        .onChange="${this.__handlers.change}"
        ?checked="${model.ndc}"
        ?disabled="${this.model.codeType === CODE_TYPE.CODE_CHARGE}"
      ></neb-checkbox>

      <neb-text
        id="${ELEMENTS.textEditNationalDrugCode.id}"
        link
        class="link"
        .onClick="${this.__handlers.openNdcPopup}"
        >Edit NDC Info
      </neb-text>
    `;
  }

  __renderSupplementalInfoLinkAndCheckbox(model) {
    return html`
      <neb-checkbox
        id="${ELEMENTS.supplementalInfoCheckbox.id}"
        label="Supplemental Info"
        name="supplementalInformation"
        class="span-1"
        .onChange="${this.__handlers.change}"
        ?checked="${model.supplementalInformation}"
      ></neb-checkbox>

      <neb-text
        id="${ELEMENTS.textEditSupplementalInfo.id}"
        link
        class="span-1"
        .onClick="${() => this.__handlers.openSupplementalInfoPopup(model)}"
        >Edit Supplemental Info
      </neb-text>
    `;
  }

  __renderOrderingProviderLinkAndCheckbox(model) {
    return html`
      <neb-checkbox
        id="${ELEMENTS.orderingProviderCheckbox.id}"
        label="Ordering Provider"
        name="orderingProvider"
        class="span-1"
        .onChange="${this.__handlers.change}"
        ?checked="${model.orderingProvider}"
      ></neb-checkbox>
      <neb-text
        id="${ELEMENTS.textEditOrderingProvider.id}"
        link
        class="link"
        .onClick="${this.__handlers.openOrderingProviderPopup}"
        >Edit Ordering Provider
      </neb-text>
    `;
  }

  __renderAttributeToDropdown(model, errors) {
    return html`
      <neb-select
        id="${ELEMENTS.attributeToDropdown.id}"
        label="Attribute To"
        class="span-2"
        helper=" "
        name="purchase.attributeToUserId"
        .value="${model.purchase.attributeToUserId}"
        .error="${!!errors.purchase.attributeToUserId}"
        .items="${this.attributeToItems}"
        ?disabled="${this.lineItemType !== LINE_ITEM_TYPE.PURCHASE}"
        .onChange="${this.__handlers.change}"
      ></neb-select>
    `;
  }

  __renderChargeTypeConditionalFields(model, errors) {
    return this.lineItemType === LINE_ITEM_TYPE.PURCHASE ||
      this.lineItemType === LINE_ITEM_TYPE.FEE
      ? this.__renderAttributeToDropdown(model, errors)
      : html`
          ${this.__renderSupplementalInfoLinkAndCheckbox(model)}
          ${this.__renderNdcLinkAndCheckbox(model)}
          ${this.__renderOrderingProviderLinkAndCheckbox(model)}
        `;
  }

  render() {
    const { model, errors } = this;
    const nonEditable = this.__getNonEditable();

    return html`
      <div class="container">
        <span id="${ELEMENTS.provider.id}"
          >${
            model.provider && model.provider.name
              ? objToName(model.provider.name, {
                  reverse: true,
                  middleInitial: true,
                })
              : ''
          }</span
        >

        ${this.__renderLocation(model)}
        ${
          this.lineItemType !== LINE_ITEM_TYPE.ENCOUNTER_CHARGE ||
          this.diagnosisItems.length
            ? html`
                <neb-select
                  id="${ELEMENTS.diagnosisDropdown.id}"
                  label=" "
                  helper=" "
                  name="encounterCharge.diagnosisPointers"
                  multiSelect
                  showFullText
                  maxSelection="4"
                  forceAlignMenu="right"
                  .value="${model.encounterCharge.diagnosisPointers || []}"
                  .items="${this.diagnosisItems}"
                  ?nonEditable="${nonEditable}"
                  .onChange="${this.__handlers.changeDx}"
                  ?disabled="${
                    this.lineItemType !== LINE_ITEM_TYPE.ENCOUNTER_CHARGE
                  }"
                  wrapText
                  itemHeight="${getDiagnosisItemHeight(this.diagnosisItems)}"
                  itemMinWidth="${ITEM_MIN_WIDTH}"
                  .forceDown="${true}"
                ></neb-select>
              `
            : html`
                <span id="${ELEMENTS.textNoEncounterDx.id}"
                  >No Encounter Dx</span
                >
              `
        }

        <span id="${ELEMENTS.dateOfService.id}">
          ${parseDate(model.dateOfService).format('L')}
        </span>

        <neb-textfield
          id="${ELEMENTS.posField.id}"
          label=" "
          helper=" "
          name="pos"
          maxLength="2"
          .mask="${number}"
          .inputMode="${'numeric'}"
          .value="${model.pos}"
          .error="${!!errors.pos}"
          .onChange="${this.__handlers.change}"
          .onBlur="${this.__handlers.blur}"
        ></neb-textfield>

        <span id="${ELEMENTS.procedureCode.id}">${this.model.code}</span>

        <neb-modifiers
          id="${ELEMENTS.modifiers.id}"
          helper=" "
          name="modifiers"
          .values="${
            [
              model.modifier_1,
              model.modifier_2,
              model.modifier_3,
              model.modifier_4,
            ]
          }"
          .errors="${
            [
              errors.modifier_1,
              errors.modifier_2,
              errors.modifier_3,
              errors.modifier_4,
            ]
          }"
          .onChange="${this.__handlers.changeModifiers}"
          .onBlur="${this.__handlers.blurModifiers}"
          ?disabled="${
            this.model.codeType === CODE_TYPE.CODE_CHARGE || nonEditable
          }"
        ></neb-modifiers>

        <neb-textfield
          id="${ELEMENTS.shaded24Field.id}"
          class="span-2"
          maxLength="71"
          label="24 Shaded"
          helper=" "
          name="shaded24"
          .value="${model.shaded24 ? model.shaded24 : ''}"
          .error="${errors.shaded24}"
          .onChange="${this.__handlers.change}"
          ?disabled="${this.model.codeType === CODE_TYPE.CODE_CHARGE}"
        ></neb-textfield>

        ${this.__renderChargeTypeConditionalFields(model, errors)}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-provider-modifiers-cell',
  NebProviderModifiersCell,
);
