import '../../../../neb-material-design/src/components/neb-loading-spinner';
import '../neb-text';
import '../neb-button-actions';
import '../neb-radio-button';
import '../../../../../src/components/controls/inputs/neb-checkbox';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../../../src/utils/locations/location-util';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { LocationsService } from '../../../../neb-redux/services/locations';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { CLAIM_STATUS, SUBMISSION_METHOD } from '../../../../neb-utils/claims';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../neb-utils/feature-util';
import {
  centsToCurrency,
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../neb-utils/formatters';
import {
  openWorklistItemPopup,
  WORKLIST_ITEMS_POPUP_ACTIONS,
} from '../claims/utils';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  iconBatches: { selector: '.icon-batch' },
  editClaimNoteIcon: { selector: '[id^=edit-claim-note-icon-]' },
  editInvoiceNoteIcon: { selector: '[id^=edit-invoice-note-icon-]' },
  radio: { selector: '.cell-radio' },
  batchIds: { selector: '[id^=batch-]' },
  date: { selector: '.date' },
  errorTable: { selector: '[id^=error-table-]' },
  claimStatusTable: { selector: '[id^=claim-status-table-]' },
  invoiceNumber: { selector: '[id^=invoice-]' },
  claimNumber: { selector: '[id^=claim-]' },
  status: { selector: '.status' },
  iconHourglasses: { selector: '.icon-hourglass' },
  iconHidden: { selector: '.icon-hidden' },
  claimIcons: { selector: '.icon-claim' },
  patientLinks: { selector: '[id^=patient-]' },
  primaryPayerAlias: { selector: '[id^=payer-]' },
  primaryPlanName: { selector: '[id^=plan-]' },
  provider: { selector: '.provider' },
  amount: { selector: '.amount' },
  balance: { selector: '.balance' },
  owedAmount: { selector: '.owedAmount' },
  payments: { selector: '.payments' },
  secondaryPayer: { selector: '.sec-payer' },
  location: { selector: '.location' },
};

const CLAIM_STATUS_CODES_TABLE_CONFIG = [
  { key: 'spacer1', label: '', flex: css`0 0 36px` },
  { key: 'code', label: 'Code', flex: css`0.2 0 0` },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
    formatter: v => html`
      <neb-text style="padding-right: 10px;">${v}</neb-text>
    `,
  },
  { key: 'statusCode', label: 'Claim Status Code', flex: css`1 0 0` },
  { key: 'spacer2', label: '', flex: css`1 0 0` },
];

const CLAIM_STATUS_CODES_TABLE_CONFIG_277_ENTITY = [
  { key: 'spacer1', label: '', flex: css`0 0 36px` },
  { key: 'code', label: 'Code', flex: css`0.2 0 0` },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
    formatter: v => html`
      <neb-text style="padding-right: 10px;">${v}</neb-text>
    `,
  },
  { key: 'statusCode', label: 'Claim Status Code', flex: css`1 0 0` },
  { key: 'entityIdentifierCode', label: 'Entity Code', flex: css`0.3 0 0` },
  { key: 'entityIdentifierCodeValue', label: 'Entity Value', flex: css`1 0 0` },
];

const VALIDATION_ERRORS_TABLE_CONFIG = [
  {
    key: 'field',
    label: 'Field',
    flex: css`1 0 0`,
  },
  {
    key: 'value',
    label: 'Value',
    flex: css`1 0 0`,
  },
  {
    key: 'location',
    label: 'Location',
    flex: css`1 0 0`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
  },
  {
    key: 'code',
    label: 'Code',
    flex: css`1 0 0`,
  },
];

export const BULK_ACTIONS_NO_CLAIMS_SELECTED_POPUP_TEXT =
  'Please select one or more claims before performing an action.';

const CLAIMS_ICON = {
  paper: 'neb:paperClaims',
  electronic: 'neb:electronicClaims',
  refresh: 'neb:refresh',
};

const HIDDEN_ICON = 'neb:visibilityOff';

class NebTableClaimsWorklist extends NebTable {
  static get properties() {
    return {
      __has277Entity: Boolean,

      providers: Array,
      patients: Array,
      status: String,
      locations: Array,
      bulkActionItems: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        #header {
          padding: ${CSS_SPACING};
          background-color: white;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        .cell-data {
          padding: 10px 0px;
        }

        .cell-checkbox {
          padding: 0 5px;
        }

        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .patient-text {
          margin-right: 2px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .cell-header {
          white-space: nowrap;
          display: flex;
          align-items: center;
          padding-bottom: 0px;
        }

        .provider {
          width: 105px;
        }

        .last-updated {
          width: 95px;
        }

        .payer-text {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-word;
        }

        .amount {
          max-width: 45px;
          white-space: normal;
          word-break: keep-all;
        }

        .status {
          display: flex;
          align-items: left;
          max-width: calc(100% - ${CSS_SPACING});
          flex-direction: column;
        }

        .status-text {
          display: inline-block;
          white-space: normal;
          word-break: keep-all;
          max-width: calc(100% - ${CSS_SPACING});
          width: auto;
        }

        .text {
          display: flex;
          flex-direction: column;
        }

        .container-loading-spinner {
          display: flex;
          justify-content: center;
        }

        .loading-spinner {
          margin-bottom: ${CSS_SPACING};
        }

        .button {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: 100%;
        }

        .button-invoice {
          width: max-content;
        }
        .date {
          white-space: nowrap;
          width: 101px;
        }

        .container-payer,
        .container-claims-id,
        .container-batch-id,
        .container-invoice-id {
          display: flex;
          align-items: center;
          width: max-content;
        }

        .container-batch-id {
          margin-top: 4px;
        }

        .container-payer {
          width: 100%;
          text-overflow: ellipsis;
        }

        .container-payer:hover,
        .claims-id:hover,
        .edit-note:hover,
        .container-batch-id:hover,
        .button:hover {
          opacity: 0.65;
        }

        .container-payer:focus,
        .claims-id:focus,
        .edit-note:focus,
        .container-batch-id:focus,
        .button:focus {
          opacity: 0.65;
        }

        .icon {
          width: 12px;
          height: 12px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-hidden {
          width: 20px;
          height: 20px;
          margin-top: 5px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-hourglass {
          width: 20px;
          height: 20px;
          min-width: 20px;
        }

        .icon-batch,
        .icon-claim {
          margin-right: 2px;
        }

        .edit-note {
          cursor: pointer;
          width: 15px;
          height: 15px;
          margin-left: 2px;
          margin-top: 1px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .sec-payer {
          padding-right: 4px;
        }
        @media (max-width: 1386px) {
          .payer-text,
          .owedAmount,
          .payments,
          .amount {
            max-width: 35px;
            word-break: keep-all;
          }

          .last-updated {
            width: 88px;
          }

          .patient-text {
            max-width: 40px;
          }
          .status {
            max-width: 50px;
          }
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
    this.initServices();
  }

  initState() {
    super.initState();

    this.__initialSort = false;
    this.__has277Entity = false;

    this.providers = [];
    this.locations = [];
    this.emptyMessage = '';
    this.initialSortKey = '';
    this.status = '';
    this.sortParams = [];
    this.patients = [];

    this.showBulkActionMenu = true;
    this.showSelectAll = true;

    this.onRefresh = () => {};

    this.onSaveAndSubmit = () => {};

    this.onClickPatient = () => {};

    this.onClickLink = () => {};

    this.onOpenClaimBatches = () => {};
  }

  initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.locations = locations;
    });
  }

  get __checkedRowItems() {
    return this.model.filter(row => row.checked);
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickCheckbox: e => e.stopPropagation(),
      clickLink: ({ name }) => this.onClickLink(name),
      clickPatient: e => {
        e.stopPropagation();
        this.onClickPatient(e);
      },
      clickRadio: e => this.onChange(e),
      openBillingNotesOverlay: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('rowIndex');
        const type = e.currentTarget.getAttribute('type');

        if (type === 'claim') {
          return this.onClickLink(`${rowIndex}.claimBillingNote`);
        }
        return this.onClickLink(`${rowIndex}.invoiceBillingNote`);
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();

    this.__has277Entity = await hasFeatureOrBeta(FEATURE_FLAGS.DH_277_ENTITY);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  async __openConfirmHidePopup() {
    const result = await openWorklistItemPopup(
      WORKLIST_ITEMS_POPUP_ACTIONS.HIDE,
      this.__checkedRowItems,
    );

    if (result) {
      this.onRefresh();
    }
  }

  async __openConfirmShowPopup() {
    const result = await openWorklistItemPopup(
      WORKLIST_ITEMS_POPUP_ACTIONS.SHOW,
      this.__checkedRowItems,
    );

    if (result) {
      this.onRefresh();
    }
  }

  __bulkActionClaimsSelectedCheck(handler) {
    return this.__checkedRowItems.length
      ? handler(this.__checkedRowItems)
      : openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: 'Bulk Actions',
          message: BULK_ACTIONS_NO_CLAIMS_SELECTED_POPUP_TEXT,
        });
  }

  __getPatientName(rowIndex) {
    const { patient } = this.model[rowIndex];
    return patient ? objToName(patient.name, DEFAULT_NAME_OPTS) : '';
  }

  __getProvider(rowIndex) {
    const provider = this.providers.find(
      provider => provider.id === this.model[rowIndex].providerId,
    );

    return provider ? objToName(provider.name, DEFAULT_NAME_OPTS) : '';
  }

  __getIcon(claim) {
    if (claim.submissionMethod) {
      return claim.submissionMethod === SUBMISSION_METHOD.ELECTRONIC_CLAIMS
        ? CLAIMS_ICON.electronic
        : CLAIMS_ICON.paper;
    }

    return claim.isElectronic ? CLAIMS_ICON.electronic : CLAIMS_ICON.paper;
  }

  __getClaimIconId(claim, index) {
    if (claim.submissionMethod) {
      return claim.submissionMethod === SUBMISSION_METHOD.ELECTRONIC_CLAIMS
        ? `isElectronic-${index}`
        : `isPaper-${index}`;
    }

    return claim.isElectronic ? `isElectronic-${index}` : `isPaper-${index}`;
  }

  __renderStatusCell(status, value, rowIndex) {
    return html`
      <neb-text class="status-text"
        >${value || 'Ready To Submit'}
        ${
          status === CLAIM_STATUS.VALIDATION_ERROR &&
          this.model[rowIndex].errorCount
            ? `(${this.model[rowIndex].errorCount})`
            : ''
        }
      </neb-text>
    `;
  }

  __renderNoteIcon(type, rowIndex) {
    return html`
      <neb-icon
        id="edit-${type}-note-icon-${rowIndex}"
        class="edit-note"
        icon="neb:editNote"
        rowIndex="${rowIndex}"
        type="${type}"
        @click="${this.handlers.openBillingNotesOverlay}"
      ></neb-icon>
    `;
  }

  updated(changedProps) {
    if (
      changedProps.has('model') &&
      this.sortParams &&
      this.model.length &&
      !this.__initialSort
    ) {
      this.__initialSort = true;
      this.model = [...this.model];
    }
  }

  renderHeaderCell(headerCell) {
    return html`
      <div class="cell-header">${super.renderHeaderCell(headerCell)}</div>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex, name) {
    const row = this.model[rowIndex];

    switch (columnConfig.key) {
      case 'radio':
        return html`
          <neb-radio-button
            class="cell-radio"
            .name="${rowIndex}"
            ?checked="${value}"
            .onChange="${this.handlers.change}"
            @click="${this.handlers.clickCheckbox}"
          >
          </neb-radio-button>
        `;

      case 'hidden':
        return value
          ? html`
              <neb-icon class="icon-hidden" .icon="${HIDDEN_ICON}"></neb-icon>
            `
          : '';

      case 'dateOfService':
        const dates = value.split('-');
        return dates.length > 1
          ? html`
              <div>
                <neb-text class="date" bold>${dates[0]} -</neb-text>
                <neb-text class="date" bold>${dates[1]}</neb-text>
              </div>
            `
          : html`
              <div><neb-text class="date" bold>${value}</neb-text></div>
            `;

      case 'locationName':
        return html`
          <div class="text">
            <div class="location">
              <neb-text
                >${
                  getLocationValue(
                    this.locations,
                    row.claimCharges[0].locationId,
                    LOCATION_KEYS.NAME,
                  )
                }</neb-text
              >
            </div>
          </div>
        `;

      case 'invoiceNumber':
        return value
          ? html`
              <div class="container-invoice-id">
                <neb-text
                  id="invoice-${rowIndex}"
                  class="button button-invoice"
                  bold
                  link
                  .name="${name}"
                  .onClick="${this.handlers.clickLink}"
                  >${value}</neb-text
                >

                ${
                  this.model[rowIndex].hasInvoiceBillingNote
                    ? this.__renderNoteIcon('invoice', rowIndex)
                    : ''
                }
              </div>
            `
          : html`
              <span id="invoice-${rowIndex}" class="status-text">${'-'}</span>
            `;

      case 'claimNumber':
        columnConfig.truncate = true;
        const icon = this.__getIcon(this.model[rowIndex]);
        const { batches = [] } = this.model[rowIndex];
        const batchId = batches.length ? batches[0] : '';

        return value
          ? html`
              <div class="container-claims-id">
                <div class="claims-id">
                  <neb-icon
                    id="${
                      this.__getClaimIconId(this.model[rowIndex], rowIndex)
                    }"
                    class="icon icon-claim"
                    .icon="${icon}"
                  ></neb-icon>

                  <neb-text
                    id="claim-${rowIndex}"
                    bold
                    link
                    .name="${rowIndex}.claimNumber"
                    .onClick="${this.handlers.clickLink}"
                    >${value}</neb-text
                  >
                </div>

                ${
                  this.model[rowIndex].hasClaimBillingNote
                    ? this.__renderNoteIcon('claim', rowIndex)
                    : ''
                }
              </div>

              ${
                batchId
                  ? html`
                      <div class="container-batch-id">
                        <div class="batch-id">
                          <neb-icon
                            class="icon icon-batch"
                            icon="neb:batch"
                          ></neb-icon>

                          <neb-text
                            id="batch-${rowIndex}"
                            bold
                            link
                            .name="${rowIndex}.batchNumber"
                            .onClick="${this.handlers.clickLink}"
                            >${batchId}</neb-text
                          >
                        </div>
                      </div>
                    `
                  : ''
              }
            `
          : html`
              <neb-icon class="icon icon-claim" .icon="${icon}"></neb-icon>
            `;

      case 'status':
        const { claimStatuses } = this.model[rowIndex];
        const pending =
          value && claimStatuses ? claimStatuses[0].statusPending : '';
        const status = value && claimStatuses ? claimStatuses[0].status : '';

        return html`
          <div class="status">
            ${this.__renderStatusCell(status, value, rowIndex)}
            ${
              pending
                ? html`
                    <neb-icon
                      class="icon icon-hourglass"
                      icon="neb:hourglassTop"
                    ></neb-icon>
                  `
                : ''
            }
          </div>
        `;

      case 'lastUpdated':
        return html`
          <div class="last-updated">${value}</div>
        `;

      case 'patientId':
        return html`
          <div
            id="patient-link-${rowIndex}"
            class="button"
            index="${rowIndex}"
            @click="${this.handlers.clickPatient}"
          >
            <span class="patient-text">${this.__getPatientName(rowIndex)}</span>

            <neb-icon class="icon" icon="neb:open"></neb-icon>
          </div>
        `;

      case 'primaryPayerAlias':
        return html`
          <div class="container-payer">
            ${
              this.model[rowIndex].paymentResponsibilityLevelCode ===
              'Secondary'
                ? html`
                    <span class="sec-payer">(S)</span>
                  `
                : ''
            }
            <neb-text
              id="payer-${rowIndex}"
              class="button payer-text"
              bold
              link
              .name="${name}"
              .onClick="${this.handlers.clickLink}"
              >${value}</neb-text
            >
          </div>
        `;

      case 'primaryPlanName':
        return value
          ? html`
              <neb-text
                id="plan-${rowIndex}"
                class="button payer-text"
                bold
                link
                .name="${name}"
                .onClick="${this.handlers.clickLink}"
                ?truncate="${true}"
                >${value}</neb-text
              >
            `
          : html`
              <span id="plan-${rowIndex}" class="status-text">${'-'}</span>
            `;

      case 'providerId':
        return html`
          <div class="provider">${this.__getProvider(rowIndex)}</div>
        `;

      case 'amount':
        return html`
          <div class="amount">${centsToCurrency(value)}</div>
        `;

      case 'balance':
        return html`
          <div class="amount">${centsToCurrency(value)}</div>
        `;

      case 'owedAmount':
        return html`
          <div class="owedAmount text">
            <div class="primary">
              Prim: ${centsToCurrency(value.primaryOwed)}
            </div>

            <div class="secondary">
              Sec: ${centsToCurrency(value.secondaryOwed)}
            </div>

            <div class="patient">
              Pat: ${centsToCurrency(value.patientOwed)}
            </div>
          </div>
        `;

      case 'payments':
        return html`
          <div class="payments text">
            <div class="primary">
              Prim: ${centsToCurrency(value.primaryPaid)}
            </div>

            <div class="secondary">
              Sec: ${centsToCurrency(value.secondaryPaid)}
            </div>

            <div class="patient">
              Pat: ${centsToCurrency(value.patientPaid)}
            </div>
          </div>
        `;

      default:
        return value;
    }
  }

  __getClaimStatusCodes(claimStatuses) {
    const mappedClaimStatuses = claimStatuses.map(claimStatus => {
      const statusAndValue =
        claimStatus.statusCode && claimStatus.statusCodeValue
          ? `${claimStatus.statusCode} - ${claimStatus.statusCodeValue}`
          : '';

      return {
        code: claimStatus.code,
        description: claimStatus.description,
        statusCode: statusAndValue,
        entityIdentifierCode: claimStatus.entityIdentifierCode,
        entityIdentifierCodeValue: claimStatus.entityIdentifierCodeValue,
      };
    });

    if (
      mappedClaimStatuses.length === 0 ||
      mappedClaimStatuses.every(item =>
        Object.values(item).every(value => !value),
      )
    ) {
      return [];
    }

    return mappedClaimStatuses;
  }

  renderExpandedRow(index, { claimErrors, claimStatuses }) {
    if (Array.isArray(claimErrors) && claimErrors.length) {
      return html`
        <neb-table
          id="error-table-${index}"
          class="table"
          emptyMessage="There are no Validation Errors."
          .config="${VALIDATION_ERRORS_TABLE_CONFIG}"
          .model="${claimErrors}"
        ></neb-table>
      `;
    }

    if (claimStatuses[0]) {
      const tableConfig = this.__has277Entity
        ? CLAIM_STATUS_CODES_TABLE_CONFIG_277_ENTITY
        : CLAIM_STATUS_CODES_TABLE_CONFIG;

      return html`
        <neb-table
          id="claim-status-table-${index}"
          class="table"
          emptyMessage="There are no claim status codes to display."
          .config="${tableConfig}"
          .model="${this.__getClaimStatusCodes(claimStatuses)}"
        ></neb-table>
      `;
    }

    return html`
    <div class="container-loading-spinner">
      <neb-loading-spinner class="loading-spinner" />
    </div>`;
  }
}

customElements.define('neb-table-claims-worklist', NebTableClaimsWorklist);
